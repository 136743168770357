<template>
    <div data-test="page" class="overflow-auto h-90 faileddownloads">
        <div>
            <h1 data-test="pageTitle" class="page-header border-bottom mx-2">
                Failed Downloads
                <mdb-icon far icon="meh" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row data-test="main-spinner" v-if="spinner" id="overlay" class="justify-content-center align-content-center h-100vh">
                <mdb-spinner data-test="accordion_spinner" class="justify-content-center"></mdb-spinner><h3><b>...Loading</b></h3>
            </mdb-row>
            <div v-else>
            <mdb-row class="mb-4">
                <mdb-col lg="7" sm="12" class="pr-1">
                    <mdb-row>
                        <mdb-col lg="6" class="pr-1 pl-3">
                            <mdb-select
                                data-test="companyOptionsSelect"
                                search
                                outline
                                v-model="companyOptions"
                                @change="changeCompany"
                                placeholder="Select Company"
                            />
                        </mdb-col>
                        <mdb-col lg="4">
                            <mdb-select
                                data-test="carrierOptionsSelect"
                                outline
                                multiple
                                v-model="carrierOptions"
                                @change="changeCarrier"
                                placeholder="Select Carrier"
                            ></mdb-select>
                        </mdb-col>
                        <mdb-col lg="2" class="pl-1 pr-1">
                            <mdb-btn
                                data-test="createTableBtn"
                                color="primary"
                                size="sm"
                                class="mt-4"
                                @click.native="createTable"
                                >Show<mdb-icon icon="sync" class="ml-2"></mdb-icon
                            ></mdb-btn>
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
                <mdb-col lg="5" sm="12" class="pl-1">
                    <mdb-card data-test="triageInstructionsCard" border="warning" class="mt-4">
                        <mdb-card-header data-test="triageInstructionsCardHeader" color="warning-color"
                            >Triage Instructions</mdb-card-header
                        >
                        <mdb-card-body>
                            <mdb-row>
                                <mdb-col lg="6" sm="12">
                                    <p data-test="triageInstructions_whatItIs">
                                        <b>What it is: </b>Invoices that were missed
                                    </p>
                                    <p data-test="triageInstructions_whatToDo">
                                        <b>What to do: </b>Collect missed invoices
                                    </p>
                                    <p data-test="triageInstructions_frequency"><b>Frequency: </b>Bi-Weekly</p>
                                    <br />
                                    <p data-test="triageInstructions_note">
                                        Check for Exceeds limits Exceptions Ensure freight invoices are actually freight
                                        invoices
                                    </p>
                                </mdb-col>
                                <mdb-col lg="6" sm="12">
                                    <p data-test="triageInstructions_note2">
                                        Known and assumed missed invoices ordered by volume. Assumed missed is a
                                        mathematical (Standard Deviation) difference based on what we've seen in weeks
                                        prior. # Missed Invoices are known missed invoice numbers that failed download
                                        (Typically FDX) and should always be downloaded manually.
                                    </p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col data-test="spinner" col="12" class="w-100 text-center" v-if="spinner"
                    ><mdb-spinner></mdb-spinner> Loading...</mdb-col
                >
                <mdb-col col="12">
                    <mdb-accordion data-test="accordion" table v-for="(row, index) of table" v-bind:key="index">
                        <mdb-accordion-pane
                            data-test="accordionPane"
                            type="table"
                            :isOpen="openPaneNum === index"
                            @pane-clicked="handlePaneOpened(index, row.carriers.length)"
                            class="mb-1"
                        >
                            <mdb-row slot="title" class="p-0">
                                <mdb-col data-test="company_plus" v-if="openPaneNum != index" lg="4"
                                    ><mdb-icon icon="plus-square" class="mr-2"></mdb-icon> {{ row.cmp_name }} ({{
                                        row.cmp_id
                                    }})
                                </mdb-col>
                                <mdb-col v-else lg="4"
                                    ><mdb-icon icon="minus-square" class="mr-2"></mdb-icon> {{ row.cmp_name }} ({{
                                        row.cmp_id
                                    }})
                                </mdb-col>
                                <mdb-col data-test="weekSpent" lg="2">
                                    Week Spent ~ {{ userInfo.currencySymbol }}{{ row.week_amount }}
                                </mdb-col>
                                <mdb-col data-test="missedInvoices" lg="2">
                                    #Missed Invoices: {{ row.number_missed_invoices }}
                                </mdb-col>
                                <mdb-col data-test="score" lg="1"> Score: {{ row.score }} </mdb-col>
                                <mdb-col data-test="liveTrialMock" lg="1">
                                    <span v-if="row.cmp_type == 'live'"
                                        ><mdb-icon icon="circle" class="text-success pr-2"></mdb-icon>Live</span
                                    >
                                    <span v-else-if="row.cmp_type == 'trial'"
                                        ><mdb-icon far icon="clock" class="text-success pr-2"></mdb-icon>Trial</span
                                    >
                                    <span v-else-if="row.cmp_type == 'mock'"
                                        ><mdb-icon far icon="clock" class="text-warning pr-2"></mdb-icon>Mock</span
                                    >
                                </mdb-col>
                                <mdb-col data-test="active" lg="1">
                                    <span v-if="row.cmp_is_active"
                                        ><mdb-icon icon="circle" class="text-success pr-2"></mdb-icon>Active</span
                                    >
                                </mdb-col>
                            </mdb-row>
                            <div
                                class="table-ui p-2 mb-3 mx-3 mb-4"
                                v-if="!spinner"
                                v-bind:style="{ 'min-height': 200 + row.carriers.length * 150 + 'px' }"
                            >
                                <mdb-accordion
                                    data-test="carrierAccordion"
                                    table
                                    v-for="(carrier, k) of row.carriers"
                                    v-bind:key="carrier.id"
                                >
                                    <mdb-accordion-pane
                                        type="table"
                                        :isOpen="openSubPaneNum == row.cmp_id + k"
                                        @pane-clicked="handleSubPaneOpened(row.cmp_id + k, carrier.table.length)"
                                    >
                                        <mdb-row slot="title">
                                            <mdb-col v-if="openSubPaneNum != row.cmp_id + k" lg="2"
                                                ><mdb-icon icon="plus-square" class="mr-2"></mdb-icon>
                                                <b>{{ carrier.car_name }}</b>
                                            </mdb-col>
                                            <mdb-col v-else lg="2"
                                                ><mdb-icon icon="minus-square" class="mr-2"></mdb-icon>
                                                <b>{{ carrier.car_name }}</b>
                                            </mdb-col>
                                            <mdb-col lg="2"
                                                ><b>
                                                    Week Spent: {{ userInfo.currencySymbol }}{{ carrier.week_amount }}
                                                </b></mdb-col
                                            >
                                            <mdb-col lg="2"
                                                ><b>
                                                    Average: {{ userInfo.currencySymbol }}{{ carrier.mean_spent }}
                                                </b></mdb-col
                                            >
                                            <mdb-col lg="2"
                                                ><b>
                                                    STD-DEV: {{ userInfo.currencySymbol }}{{ carrier.stddev_spent }}
                                                </b></mdb-col
                                            >
                                            <mdb-col lg="2"
                                                ><b> #Missed Invoices: {{ carrier.number_missed_invoices }}</b>
                                            </mdb-col>
                                            <mdb-col lg="2"
                                                ><b> Last Invoice: {{ carrier.oldest_inv_date }} </b></mdb-col
                                            >
                                        </mdb-row>
                                        <div class="p-2 mb-3 mx-3 mb-4 overflow-auto">
                                            <mdb-tbl bordered striped sm class="white">
                                                <mdb-tbl-head color="light">
                                                    <tr>
                                                        <th>Cred ID</th>
                                                        <th>Account</th>
                                                        <th>Status</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Note</th>
                                                        <th>Invoices</th>
                                                    </tr>
                                                </mdb-tbl-head>

                                                <mdb-tbl-body>
                                                    <tr
                                                        v-if="carrier.table"
                                                        v-for="(invoice, j) of carrier.table"
                                                        v-bind:key="invoice.dli_invoices + j"
                                                    >
                                                        <td v-if="invoice">{{ invoice.cred_id }}</td>
                                                        <td v-if="invoice">{{ invoice.dli_account_number }}</td>
                                                        <td v-if="invoice">{{ invoice.dli_status }}</td>
                                                        <td v-if="invoice">{{ invoice.dli_start_date }}</td>
                                                        <td v-if="invoice">{{ invoice.dli_end_date }}</td>
                                                        <td v-if="invoice">{{ invoice.dli_note }}</td>
                                                        <td v-if="invoice">{{ invoice.dli_invoices }}</td>
                                                    </tr>
                                                </mdb-tbl-body>
                                            </mdb-tbl>
                                        </div>
                                    </mdb-accordion-pane>
                                </mdb-accordion>
                            </div>
                        </mdb-accordion-pane>
                    </mdb-accordion>
                </mdb-col>
            </mdb-row>
                </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import {
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbScrollbar,
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbBtn,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbSpinner,
    mdbAccordion,
    mdbAccordionPane, mdbModalBody,
} from "mdbvue";

export default {
    name: "AdminFailedDownloads",
    components: {
        mdbModalBody,
        mdbTbl,
        mdbTblHead,
        mdbTblBody,
        mdbScrollbar,
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbSelect,
        mdbBtn,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbSpinner,
        mdbAccordion,
        mdbAccordionPane,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            spinner: false,
            companyList: null,
            companyOptions: [],
            table: [],
            startDate: null,
            endDate: null,
            carIds: "",
            cmpId: 0,
            openPaneNum: null,
            openSubPaneNum: null,
            accordionHeight: null,
            carrierOptions: [
                { value: "1", text: "FedEx" },
                { value: "2", text: "UPS" },
            ],
        };
    },
    methods: {
        async getInit() {
            this.spinner = true;
            this.companiesCreated = await this.getCompanies();
            this.datesCreated = await this.getDates();
            this.tableCreated = await this.createTable();
            this.spinner = false;
        },
        async getCompanies() {
            this.companyOptions = [];
            await this.$httpAdmin.get("/admincompany/light", { headers: this.headers }).then(async (response) => {
                this.companyList = response.data;
                for (let i = 0; i < this.companyList.length; i++) {
                    this.companyOptions.push({
                        text:
                            this.companyList[i].cmp_name +
                            " (ID:" +
                            this.companyList[i].cmp_id +
                            " | Type: " +
                            this.companyList[i].cmp_type.toUpperCase() +
                            " | Reseller: " +
                            this.companyList[0].cmp_reseller_id +
                            ")",
                        id: this.companyList[i].cmp_id,
                        value: i,
                        name: this.companyList[i].cmp_name,
                    });
                }
            });
        },
        getDates() {
            this.startDate = moment().startOf("week").subtract(6, "days").format("YYYY-MM-DD");
            this.endDate = moment().startOf("week").format("YYYY-MM-DD");
        },
        async createTable() {
            this.spinner = true;
            this.table = [];
            await this.$httpAdmin
                .get("/faileddownloads/", { headers: this.headers, params: this.tableParams() })
                .then(async (response) => {
                    this.table = response.data;
                    for (let i = 0; i < this.table.length; i++) {
                        this.table[i].rowHeight = 0;
                        for (let j = 0; j < this.table[i].carriers.length; j++) {
                            this.table[i].carriers[j].table = [];
                            this.table[i].carriers[j].paneNum = j;
                            this.table[i].carriers[j].week_amount = this.printNumber(
                                this.table[i].carriers[j].week_amount
                            );
                            this.table[i].carriers[j].mean_spent = this.printNumber(
                                this.table[i].carriers[j].mean_spent
                            );
                            this.table[i].carriers[j].stddev_spent = this.printNumber(
                                this.table[i].carriers[j].stddev_spent
                            );
                            this.table[i].carriers[j].oldest_inv_date = new moment(
                                this.table[i].carriers[j].oldest_inv_date
                            ).format("M/DD/YYYY");
                            for (let k = 0; k < this.table[i].carriers[j].credentials.length; k++) {
                                for (let m = 0; m < this.table[i].carriers[j].credentials[k].dlInvoices.length; m++) {
                                    /*switch(status){
		case -10: return "Difficulty";
		case -1: return "No Invoice";
		case 0: return "Unknown";
		case 1: return "No New Invoice";
		case 10: return "Downloaded";
		case 200: return "Downloaded";
		case 201: return "Extract Error";
	}

	return "Unknown";*/
                                    if (this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status == -10) {
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status =
                                            "Difficulty";
                                    } else if (
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status == -1
                                    ) {
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status =
                                            "No Invoice";
                                    } else if (this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status == 0) {
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status = "Unknown";
                                    } else if (this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status == 1) {
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status =
                                            "No New Invoice";
                                    } else if (
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status == 10
                                    ) {
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status =
                                            "Downloaded";
                                    } else if (
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status == 200
                                    ) {
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status =
                                            "Downloaded";
                                    } else if (
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status == 201
                                    ) {
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status =
                                            "Extract Error";
                                    } else {
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status = "Unknown";
                                    }
                                    this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_invoices =
                                        this.breakInvoices(
                                            this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_invoices
                                        );
                                    this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_start_date = moment(
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_start_date
                                    ).format("MM/DD/YYYY");
                                    this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_end_date = moment(
                                        this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_end_date
                                    ).format("MM/DD/YYYY");
                                    this.table[i].carriers[j].table.push({
                                        cred_id:
                                            this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_credential_id,
                                        dli_account_number:
                                            this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_account_number,
                                        dli_status: this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_status,
                                        dli_start_date:
                                            this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_start_date,
                                        dli_end_date:
                                            this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_end_date,
                                        dli_note: this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_note,
                                        dli_invoices:
                                            this.table[i].carriers[j].credentials[k].dlInvoices[m].dli_invoices,
                                    });
                                }
                            }
                        }
                        this.table[i].week_amount = this.printNumber(this.table[i].week_amount);
                    }
                });
            this.spinner = false;
        },
        tableParams() {
            return {
                startDate: this.startDate,
                endDate: this.endDate,
                carIds: this.carIds,
                cmpId: this.cmpId,
            };
        },
        printNumber(nStr) {
            if (nStr == 0) return "0";

            nStr += "";
            let x = nStr.split(".");
            let x1 = x[0];
            let x2 = x.length > 1 ? "." + (x[1].length > 2 ? x[1].substring(0, 2) : x[1]) : "";
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, "$1" + "," + "$2");
            }
            return x1 + x2;
        },
        handlePaneOpened(num, height) {
            if (this.openPaneNum == num) {
                this.accordionHeight = height;
                this.openPaneNum = null;
            } else {
                this.accordionHeight = 0;
                this.openPaneNum = num;
            }
        },
        handleSubPaneOpened(num, height) {
            if (this.openSubPaneNum == num) {
                this.accordionHeight = 0;
                this.openSubPaneNum = null;
            } else {
                this.openSubPaneNum = num;
                this.accordionHeight = height;
            }
        },
        changeCompany() {
            for (let i = 0; i < this.companyOptions.length; i++) {
                if (this.companyOptions[i].selected) {
                    this.cmpId = this.companyOptions[i].id;
                }
            }
        },
        changeCarrier() {
            this.carIds = "";
            for (let i = 0; i < this.carrierOptions.length; i++) {
                if (this.carrierOptions[i].selected) {
                    this.carIds = this.carIds.concat(this.carrierOptions[i].value);
                    if (i != this.carrierOptions.length - 1) {
                        this.carIds = this.carIds.concat(",");
                    }
                }
            }
        },
        breakInvoices(invoicesString) {
            if (!invoicesString) return "";
            let invoices = invoicesString.split(",");
            if (invoices.length < 4) return invoicesString;

            invoicesString = "";
            let len = invoices.length / 3;
            for (let i = 0; i < len; i++) {
                for (let j = 0; j < 3; j++) {
                    let index = i * 3 + j;
                    if (index < invoices.length) invoicesString += invoices[index] + ", ";
                }
                invoicesString += "<br>";
            }

            return invoicesString;
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
<style>
.faileddownloads a {
    display: none;
}
</style>
