<template>
    <div>
        <mdb-dropdown
            id="dropdown-form2"
            ref="dropdown"
            variant="white"
            class="border rounded"
            :class="sAccount ? 'bg-info text-white' : ''"
            multiLevel
        >
            <mdb-dropdown-toggle slot="toggle">{{ multiSelectOptions.defaultText }}</mdb-dropdown-toggle>
            <mdb-dropdown-menu v-if="userAccounts">
                <form>
                    <MultiSelectItem
                        :selectData="userAccounts"
                        :visibleIdx="1"
                        v-on:sendSelectedOptions="updateActiveAccounts"
                    ></MultiSelectItem>
                </form>
            </mdb-dropdown-menu>
        </mdb-dropdown>
    </div>
</template>
<script>
import MultiSelectItem from "@/components/MultiSelectItem";

export default {
    name: "MultiSelectDropdown",
    components: {
        MultiSelectItem,
    },
    data() {
        return {
            sAccount: null,
            visible: false,
            visibleLvl2: false,
            visibleIdx: 0,
            selectedAccountActive: false,
            userAccounts: null,
            counter: 0,
            fromChild: "",
        };
    },
    props: {
        userInfo: Object,
        headers: Object,
        selectData: Object,
        multiSelectOptions: Object,
    },
    methods: {
        updateActiveAccounts(selectedOptions) {
            this.$emit("sendSelectedOptionsToParent", selectedOptions);
        },
        async getUserAccounts() {
            this.userAccounts = [];
            if (this.multiSelectOptions.isTransformNeeded == "no") {
                this.userAccounts = this.selectData.keyvalues[0].value;
            } else {
                const data = this.selectData.keyvalues;
                for (const account of data) {
                    this.userAccounts.push(JSON.parse(account.value)[0]);
                }
            }
        },
    },
    created() {
        this.getUserAccounts();
    },
    watch: {
        selectedAccountActive() {
            this.$emit("update-account-selected", this.userAccounts);
            this.$emit("update-selected-active", this.selectedAccountActive);
        },
    },
};
</script>
