<template>
    <div class="w-100 systemHealth">
        <h1 class="page-header border-bottom mx-2">
            System Health
            <span class="small">Internal View</span>
            <mdb-icon icon="tachometer-alt" size="1x" class="float-right text-muted" role="button" />
        </h1>
        <mdb-row class="w-100 my-4">
            <mdb-col col="12">
                <mdb-row class="flex no-gutters">
                    <mdb-card xs="12" col="auto" class="mr-2 flex-fill">
                        <mdb-card-header color="blue lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="blue-text">
                                    <mdb-icon :icon="systemStatusIcon" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="blue-text">
                                    <p>{{ systemStatus }}</p>
                                    <p class="smaller blue-text">{{ systemStatusText }}</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/appcontroller">
                                View Details
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card xs="12" col="auto" class="mr-2 flex-fill">
                        <mdb-card-header :color="errorColor" class="lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" v-bind:class="[errors > 0 ? 'red-text' : 'green-text']">
                                    <mdb-icon icon="exclamation-triangle" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" v-bind:class="[errors > 0 ? 'red-text' : 'green-text']">
                                    <p>{{ errors }}</p>
                                    <p v-bind:class="[errors > 0 ? 'red-text' : 'green-text', 'smaller']">
                                        Errors Detected
                                    </p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/systemerrors">
                                View Errors
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card xs="12" col="auto" class="flex-fill mr-2">
                        <mdb-card-header color="red lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="font-weight-bold red-text">
                                    <mdb-icon icon="file" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="red-text">
                                    <p class="text-nowrap">{{ actualRate }}%</p>
                                    <p class="smaller red-text">Hit Rate</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/auditreport">
                                View Audit Report
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card xs="12" col="auto" class="flex-fill">
                        <mdb-card-header color="blue lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="blue-text">
                                    <mdb-icon icon="retweet" size="3x" />
                                </mdb-col>
                                <mdb-col col="3" class="blue-text">
                                    {{ thirtyDay }}%
                                    <p class="smaller blue-text">30 Days</p>
                                </mdb-col>
                                <mdb-col col="3" class="blue-text">
                                    <p>{{ actualRate }}%</p>
                                    <p class="smaller blue-text">90 Days</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/RecoveryReportMonthly">
                                View Recovery Report
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-row>
            </mdb-col>
        </mdb-row>
        <mdb-row>
            <mdb-col col="6">
                <mdb-card>
                    <mdb-card-header>
                        <mdb-icon icon="comments"></mdb-icon>
                        Latest System Activity
                        <mdb-dropdown class="float-right">
                            <mdb-dropdown-toggle slot="toggle" size="sm" color="orange">Actions</mdb-dropdown-toggle>
                            <mdb-dropdown-menu color="orange" style="margin-top: -30px">
                                <mdb-dropdown-item>
                                    <router-link to="/admin/auditreport">Audit Report</router-link>
                                </mdb-dropdown-item>
                                <mdb-dropdown-item>
                                    <router-link to="/admin/nodestatus">Node Status</router-link>
                                </mdb-dropdown-item>
                                <!--<mdb-dropdown-item>
                                    <router-link to="/admin/activitieslog">View All Activity</router-link>
                                </mdb-dropdown-item>-->
                            </mdb-dropdown-menu>
                        </mdb-dropdown>
                    </mdb-card-header>
                    <mdb-card-body>
                        <vue-good-table :columns="activityColumns" :rows="activities">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'log_desc'">
                                    <mdb-icon :icon="props.row.icon" class="mr-2"></mdb-icon>
                                    {{ props.row.log_desc }}
                                </span>
                                <span v-if="props.column.field === 'log_time'">
                                    <mdb-badge color="primary" class="p-2 rounded">{{
                                        formatBadge(props.row.log_time)
                                    }}</mdb-badge>
                                </span>
                            </template>
                        </vue-good-table>
                    </mdb-card-body>
                    <mdb-card-footer>
                        <router-link to="/admin/activitieslog">
                            <mdb-btn sm color="grey"
                                >View All Activity
                                <mdb-icon icon="arrow-right"></mdb-icon>
                            </mdb-btn>
                        </router-link>
                    </mdb-card-footer>
                </mdb-card>
            </mdb-col>
            <mdb-col col="6">
                <mdb-card>
                    <mdb-card-header>
                        <mdb-icon icon="cubes"></mdb-icon>
                        System Components
                        <router-link to="/admin/appcontroller"
                            ><mdb-btn class="float-right" size="sm" color="orange">Manage</mdb-btn></router-link
                        >
                    </mdb-card-header>
                    <mdb-card-body>
                        <vue-good-table :columns="componentColumns" :rows="components">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'name'">
                                    <mdb-icon
                                        v-if="props.row.icon && props.row.icon !== 'sync'"
                                        :icon="props.row.icon"
                                    ></mdb-icon>
                                    <mdb-icon
                                        v-else-if="props.row.icon && props.row.icon === 'sync'"
                                        spin
                                        :icon="props.row.icon"
                                    ></mdb-icon>
                                    <span class="ml-2">{{ props.row.name }}</span>
                                </span>
                                <span v-if="props.column.field === 'end_time'">
                                    <span v-if="props.row.status === 'running'">Running...</span>
                                    <span v-else-if="props.row.status === 'started'">Started...</span>
                                    <span v-else>{{ formatDate(props.row.end_time) }}</span>
                                </span>
                                <span v-else-if="props.column.field === 'start_time'">
                                    {{ formatDate(props.row[props.column.field]) }}
                                </span>
                            </template>
                        </vue-good-table>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "SystemHealthDashboard",
    components: {},
    props: {
        userInfo: Object,
        headers: Object,
    },
    data() {
        return {
            actualRate: null,
            thirtyDay: null,
            errors: null,
            systemStatus: null,
            systemStatusText: null,
            systemStatusIcon: null,
            components: null,
            errorColor: "green",
            activityColumns: [
                {
                    label: "",
                    field: "log_desc",
                },
                {
                    label: "",
                    field: "log_time",
                },
            ],
            activities: null,
            componentColumns: [
                {
                    label: "",
                    field: "name",
                },
                {
                    label: "",
                    field: "start_time",
                },
                {
                    label: "",
                    field: "end_time",
                },
            ],
            runningComponent: null,
        };
    },
    methods: {
        formatDate(date) {
            return moment(date).format("MM/DD/YY h:mm.ssa");
        },
        async getStats() {
            this.actualRate = (await this.$httpAdmin.get("Administration/actual-rate", { headers: this.headers })).data;
            this.errors = (await this.$httpAdmin.get("Administration/errors", { headers: this.headers })).data;
            this.thirtyDay = (
                await this.$httpAdmin.get("Administration/30day-recovery", { headers: this.headers })
            ).data;
            if (this.errors > 0) {
                this.errorColor = "red";
            } else {
                this.errorColor = "green";
            }
        },
        async getComponents() {
            this.runningComponent = null;
            const components = (
                await this.$httpAdmin.get("appcontroller/?action=getcomponent&clientName=web", {
                    headers: this.headers,
                })
            ).data;
            for (const row of components) {
                let status = "clock";

                if (row.status === "started") status = "play";
                else if (row.status === "running") status = "sync";
                else if (row.status === "newInvoices") status = "copy";

                if (row.disabled) status = "stop";
                row.icon = status;
                if (row.status === "running") {
                    this.runningComponent = row;
                }
            }
            this.updateSystemPanel();
            this.components = components.filter((r) => r.name && r.name !== "");
        },
        formatBadge(time) {
            return moment(time).fromNow();
        },
        async getActivities() {
            const activities = (await this.$httpAdmin.get("Administration/activities", { headers: this.headers })).data;
            this.activities = activities.map((a) => {
                switch (a.log_code) {
                    case 100: {
                        a.icon = "check-square";
                        break;
                    }
                    case 101: {
                        a.icon = "beer";
                        break;
                    }
                    case 103: {
                        a.icon = "truck";
                        break;
                    }
                    case 104: {
                        a.icon = "money-bill";
                        break;
                    }
                    case 105: {
                        a.icon = "utensils";
                        break;
                    }
                    case 200: {
                        a.icon = "sign-out-alt";
                        break;
                    }
                    case 201: {
                        a.icon = "trophy";
                        break;
                    }
                    case 202: {
                        a.icon = "exclamation-triangle";
                        break;
                    }
                    case 300: {
                        a.icon = "sitemap";
                        break;
                    }
                    case 301: {
                        a.icon = "fire-extinguisher";
                        break;
                    }
                    case 302: {
                        a.icon = "fire";
                        break;
                    }
                    case 400: {
                        a.icon = "building";
                        break;
                    }
                }
                return a;
            });
        },
        updateSystemPanel() {
            let text = "Idle";
            let det = "System is Idle";
            let ico = "clock";

            let component;
            if (this.runningComponent) {
                component = this.runningComponent;
            }
            if (component == "extraction") {
                text = "Extract";
                det = "Extracting Charges...";
                ico = "sync";
            } else if (component == "reconciliation") {
                text = "Audit";
                det = "Reconciling Refunds...";
                ico = "sync";
            } else if (component == "audit") {
                text = "Audit";
                det = "Processing Charges...";
                ico = "sync";
            } else if (component == "consolidation") {
                text = "Maint";
                det = "Consolidating Packages...";
                ico = "sync";
            } else if (component == "glcoding") {
                text = "Maint";
                det = "Applying GLCodes...";
                ico = "sync";
            } else if (component == "invoiceUploader") {
                text = "Stage";
                det = "Uploading Invoices to Extract Queue (S3)...";
                ico = "sync";
            } else if (component == "extractionInvoiceDownloader") {
                text = "Stage";
                det = "Downloading Invoices from Extract Queue (S3)...";
                ico = "sync";
            } else if (component == "copyProcessedPackages") {
                text = "Merge";
                det = "Committing Processed Data...";
                ico = "sync";
            }

            this.systemStatus = text;
            this.systemStatusText = det;
            this.systemStatusIcon = ico;
        },
    },
    mounted() {
        this.getStats();
        this.getComponents();
        this.getActivities();
    },
};
</script>
<style>
.systemHealth .vgt-table th {
    display: none !important;
}
</style>
