import Vue from "vue";
import App from "./App.vue";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import router from "./router";
import Axios from "axios";
import mapInit from "highcharts/modules/map";
import stockInit from "highcharts/modules/stock";
import hcMore from "highcharts/highcharts-more";
import exportingInit from "highcharts/modules/exporting";
import map from "./lib/mapdata/us-all";
import JsonCSV from "vue-json-csv";
import VueShepherd from "vue-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import * as mdbvue from "mdbvue";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbvue/lib/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import VueGoodTablePlugin from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import IdleVue from "idle-vue";
// import the styles
import "vue-good-table/dist/vue-good-table.css";

Vue.use(VueGoodTablePlugin);

mapInit(Highcharts);
stockInit(Highcharts);
hcMore(Highcharts);
map(Highcharts);
exportingInit(Highcharts);

import Notify from "mdbvue/lib/components/Notify";
Vue.use(Notify);

const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 28800000, // 8 hours,
});

// Styles
import "@/styles/custom.css";
// import config from "./config";

const axios = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_ROOT_API,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const axiosImpersonate = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_ROOT_API,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const axiosShipping = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_SHIPPING_API,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const axiosReports = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_REPORTS_API,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

// used when logging in as clients
const axiosReportsImpersonate = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_REPORTS_API,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const adminPortal = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_ADMIN_PORTAL,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const axiosNinja = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_NINJA_API,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

axios.interceptors.response.use(
    function (response) {
        let routes = router.getRoutes();
        let paths = [];
        let includes = false;
        routes.forEach((route) => {
            if (route.path.includes("admin")) {
                paths.push(route.path);
            }
        });
        paths.forEach((path) => {
            if (window.location.href.includes(path)) {
                includes = true;
            }
        });
        if (!includes) {
            let user = localStorage.getItem("user");
            if (user) {
                user = JSON.parse(user);
                if (user.usrLevel == "super_admin" && !user.usrLoggedInAs) {
                    window.location = "/#/admin";
                    user.verified = true;
                }
            }
        }
        return response;
    },
    function (error) {
        // handle error
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location = "/#/login";
            }
        }
    }
);

axiosImpersonate.interceptors.request.use(
    function (request) {
        let user = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user);
            request.headers.common["LAS"] = user.usrLoggedInAs;
        }
        return request;
    },
    function (error) {
        // we don't want the header to be set, so don't to anything
    }
);

axiosShipping.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // handle error
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location = "/#/login";
            } else {
                return Promise.reject(error);
            }
        }
    }
);

axiosReports.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // handle error
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location = "/#/login";
            } else {
                return Promise.reject(error);
            }
        }
    }
);

axiosReportsImpersonate.interceptors.request.use(
    function (request) {
        let user = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user);
            request.headers.common["LAS"] = user.usrLoggedInAs;
        }
        return request;
    },
    function (error) {
        // we don't want the header to be set, so don't to anything
    }
);

axiosReportsImpersonate.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // handle error
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location = "/#/login";
            }
        }
    }
);

adminPortal.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // handle error
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location = "/#/login";
            }
        }
    }
);

Vue.mixin({
    methods: {
        formatPrice(value, userInfo) {
            let code = "USD";
            if (userInfo.currencySymbol == "€") {
                code = "EUR";
            }
            return new Intl.NumberFormat("en-US", { style: "currency", currency: code }).format(value);
        },
    },
});

for (const component in mdbvue) {
    Vue.component(component, mdbvue[component]);
}
Vue.prototype.$http = axios;
Vue.prototype.$httpImpersonate = axiosImpersonate;
Vue.prototype.$httpShipping = axiosShipping;
Vue.prototype.$httpReports = axiosReports;
Vue.prototype.$httpReportsImpersonate = axiosReportsImpersonate;
Vue.prototype.$httpAdmin = adminPortal;
Vue.prototype.$httpNinja = axiosNinja;

Vue.component("downloadCsv", JsonCSV);
Vue.component("vuejs-datepicker", Datepicker);

Vue.config.productionTip = false;
Vue.use(HighchartsVue);
Vue.use(VueShepherd);

new Vue({
    router,
    render: (h) => h(App),
    onIdle() {
        if (router.currentRoute.path !== "/#/login") {
            localStorage.clear();
            window.location = "/#/login";
        }
    },
}).$mount("#app");
