<template>
    <mdb-modal
        data-test="credentialsModal"
        v-if="credentialsInfo && credsInfo"
        size="fluid"
        :show="credentialsModal"
        @close="$emit('close-credentials-modal', false)"
    >
        <mdb-modal-header>
            <mdb-modal-title data-test="credentialsModalTitle">
                <mdb-icon icon="truck" class="mr-2"></mdb-icon>
                Carrier Credentials |
                {{ selectedRow.cmp_name }}
            </mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
            <mdb-row v-if="isBusy" id="onboarding_overlay" class="justify-content-center align-content-center h-100vh">
                <mdb-spinner class="justify-content-center"></mdb-spinner>
                <h3><b>...Loading</b></h3>
            </mdb-row>
            <mdb-row :style="{ opacity: isBusy ? 0.3 : 1 }">
                <mdb-col col="8">
                    <p data-test="credentialsModalNote">
                        <b>Note: </b>UPS API key can be generated immediately on UPS.com, when FedEx API key is
                        requested an email with be generated to the user containing the key.
                    </p>
                    <br />
                    <p data-test="credentialsModalNote2">
                        If creating an alias carrier account use the suggested values on the right if possible.
                    </p>
                    <br />
                    <p data-test="credentialsModalNote3" class="text-danger">
                        <b>NEW or UPDATED CREDENTIALS NOTE:</b><br />
                        If there is an updated carrier login (new username) that has visibility to the same, or the same
                        plus more accounts
                        <u><b>UPDATE</b> the existing active credential</u> instead of adding a new one and deactivating
                        the old.
                    </p>
                </mdb-col>
                <mdb-col col="4">
                    <b data-test="credentialsModalSuggestedAliasNote">Suggested Alias Carrier Account:</b>
                    <mdb-card class="mt-1">
                        <mdb-card-body>
                            <mdb-row>
                                <mdb-col col="2">
                                    <mdb-icon icon="truck" size="lg"></mdb-icon>
                                </mdb-col>
                                <mdb-col col="10">
                                    <table data-test="credentialsModalSuggestedAliasTable">
                                        <tr data-test="credentialsModalSuggestedAliasTable_companyIDRow">
                                            <td>Company ID</td>
                                            <td>{{ selectedRow.cmp_id }}</td>
                                        </tr>
                                        <tr data-test="credentialsModalSuggestedAliasTable_internalEmailRow">
                                            <td>Internal Email</td>
                                            <td>{{ selectedRow.cmp_id }}@invoicearchive.com</td>
                                        </tr>
                                        <tr data-test="credentialsModalSuggestedAliasTable_aliasNameRow">
                                            <td>Alias Name</td>
                                            <td>
                                                {{ credsInfo.cmp_alias_first_name }}
                                                {{ credsInfo.cmp_alias_last_name }}
                                            </td>
                                        </tr>
                                        <tr data-test="credentialsModalSuggestedAliasTable_carrierUsernameRow">
                                            <td>Carrier Username</td>
                                            <td>cmp{{ selectedRow.cmp_id }}</td>
                                        </tr>
                                        <tr data-test="credentialsModalSuggestedAliasTable_hopperEmailRow">
                                            <td>Hopper Email</td>
                                            <td>data + {{ selectedRow.cmp_id }}@invoicearchive.com</td>
                                        </tr>
                                        <tr data-test="credentialsModalSuggestedAliasTable_generatePWRow">
                                            <td class="pr-4">
                                                Generate PW
                                                <a
                                                    data-test="credentialsModalSuggestedAliasTable_generatePasswordClickable"
                                                    class="btn-sm pl-1"
                                                    @click="generatePassword"
                                                >
                                                    <mdb-icon icon="key" color="primary"></mdb-icon>
                                                </a>
                                            </td>
                                            <td class="text-success">
                                                {{ generatedPassword ? generatedPassword : "" }}
                                            </td>
                                        </tr>
                                    </table>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <hr />
            <mdb-row class="pb-2">
                <mdb-col>
                    <mdb-dropdown data-test="credentialsModal_addCarrierCredentialsDropdown">
                        <mdb-dropdown-toggle
                            data-test="credentialsModal_addCarrierCredentialsDropdownToggle"
                            color="primary"
                            slot="toggle"
                        >
                            <mdb-icon icon="lock" class="pr-2"></mdb-icon>
                            Add Carrier Credentials
                        </mdb-dropdown-toggle>
                        <mdb-dropdown-menu
                            data-test="credentialsModal_addCarrierCredentialsDropdownMenu"
                            color="primary"
                        >
                            <mdb-dropdown-item
                                data-test="credentialsModal_addCarrierCredentialsDropdownItem"
                                v-for="carrier of carriersText"
                                v-bind:key="carrier.value"
                                @click.native="addCredentialsRow(carrier)"
                                >{{ carrier.text }}
                            </mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown>
                </mdb-col>
            </mdb-row>
            <div data-test="credentialsModal_credentials" v-if="credentialsInfo.length > 0">
                <div v-for="(creds, index) of credentialsInfo" v-bind:key="index">
                    <mdb-row
                        :class="{ 'sendtoback-lite': !creds.credential_is_active }"
                        :style="{
                            'border-color': creds.error ? 'red' : 'black',
                            'border-style': creds.error ? 'solid' : 'none',
                        }"
                    >
                        <div class="row col-7 float-left">
                            <div class="ml-3 mr-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialCountryLabel"
                                    :for="'credentialCountry-' + index"
                                    >Country</label
                                >
                                <select
                                    data-test="credentialsModal_credentials_credentialCountry"
                                    :id="'credentialCountry-' + index"
                                    class="browser-default custom-select"
                                    v-model="creds.credential_country"
                                    style="width: 85px"
                                >
                                    <option
                                        data-test="credentialsModal_credentials_credentialCountryOption"
                                        v-for="country of credentialsCountries"
                                        v-bind:key="country.value"
                                        :value="country.value"
                                    >
                                        {{ country.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label
                                    data-test="credentialsModal_credentials_credentialDownloadTypeLabel"
                                    :for="'credentialDownload-' + index"
                                    >Download Type</label
                                >
                                <select
                                    data-test="credentialsModal_credentials_credentialDownloadType"
                                    :id="'credentialDownload-' + index"
                                    class="browser-default custom-select"
                                    v-model="creds.credential_download_type"
                                    style="width: 100px"
                                >
                                    <option
                                        data-test="credentialsModal_credentials_credentialDownloadTypeOption"
                                        v-for="download of credDownloadType"
                                        v-bind:key="download.value"
                                        :value="download.value"
                                    >
                                        {{ download.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialTypeLabel"
                                    :for="'credentialsType-' + index"
                                    >Credential Type</label
                                >
                                <select
                                    data-test="credentialsModal_credentials_credentialType"
                                    :id="'credentialsType-' + index"
                                    class="browser-default custom-select"
                                    v-model="creds.credential_type"
                                    style="width: 125px"
                                >
                                    <option
                                        data-test="credentialsModal_credentials_credentialTypeOption"
                                        v-for="type of creds.typeOptions"
                                        v-bind:key="type.value"
                                        :value="type.value"
                                    >
                                        {{ type.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialUsernameLabel"
                                    :for="'credentialUsername-' + index"
                                    >Username</label
                                >
                                <input
                                    data-test="credentialsModal_credentials_credentialUsername"
                                    type="text"
                                    :id="'credentialUsername-' + index"
                                    class="form-control"
                                    v-model="creds.credential_username"
                                    style="width: 100px"
                                    placeholder="Username"
                                    :style="{
                                        'border-color': creds.usernameError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialPasswordLabel"
                                    :for="'credentialPassword-' + index"
                                    >Password</label
                                >
                                <input
                                    data-test="credentialsModal_credentials_credentialPassword"
                                    type="text"
                                    :id="'credentialPassword-' + index"
                                    class="form-control"
                                    v-model="creds.credential_password"
                                    style="width: 100px"
                                    :style="{
                                        'border-color': creds.passwordError ? 'red' : '#ced4da',
                                    }"
                                    placeholder="Password"
                                />
                            </div>
                            <div class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialAccesskeyLabel"
                                    :for="'credentialAccessKey-' + index"
                                    >Access Key
                                    <mdb-tooltip material trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip"
                                            >Must be 16 characters, alphanumeric, no spaces or hyphens (or can be left
                                            blank).</span
                                        >
                                        <mdb-icon
                                            slot="reference"
                                            class="pl-1"
                                            icon="info-circle"
                                            color="primary"
                                            size="sm"
                                        ></mdb-icon>
                                    </mdb-tooltip>
                                </label>
                                <input
                                    data-test="credentialsModal_credentials_credentialAccesskey"
                                    type="text"
                                    :id="'credentialAccessKey-' + index"
                                    class="form-control"
                                    v-model="creds.credential_accesskey"
                                    style="width: 120px"
                                    placeholder="Access Key"
                                    :style="{
                                        'border-color': creds.accessKeyError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div v-if="creds.type != 'ups'" class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialApiPassLabel"
                                    :for="'credentialApiPassword-' + index"
                                    >API Password
                                    <mdb-tooltip material trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip"
                                            >For FedEx, must be 25 characters, alphanumeric, no spaces or hyphens (or
                                            can be left blank).</span
                                        >
                                        <mdb-icon
                                            slot="reference"
                                            class="pl-1"
                                            icon="info-circle"
                                            color="primary"
                                            size="sm"
                                        ></mdb-icon>
                                    </mdb-tooltip>
                                </label>
                                <input
                                    data-test="credentialsModal_credentials_credentialApiPass"
                                    type="text"
                                    :id="'credentialApiPassword-' + index"
                                    class="form-control"
                                    v-model="creds.credential_api_pass"
                                    style="width: 100px"
                                    placeholder="API Password"
                                    :style="{
                                        'border-color': creds.apiPassError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div v-if="creds.type == 'fedEx'" class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialMeterLabel"
                                    :for="'credentialMeter-' + index"
                                    >Meter
                                    <mdb-tooltip material trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip"
                                            >For FedEx, must be 9 digits, no spaces or hyphens (or can be left
                                            blank).</span
                                        >
                                        <mdb-icon
                                            slot="reference"
                                            class="pl-1"
                                            icon="info-circle"
                                            color="primary"
                                            size="sm"
                                        ></mdb-icon>
                                    </mdb-tooltip>
                                </label>
                                <input
                                    data-test="credentialsModal_credentials_credentialMeter"
                                    type="text"
                                    :id="'credentialMeter-' + index"
                                    class="form-control"
                                    v-model="creds.credential_meter"
                                    style="width: 100px"
                                    placeholder="Meter"
                                    :style="{
                                        'border-color': creds.meterError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div v-if="creds.type != 'dhlex'" class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialApiAccountLabel"
                                    :for="'credentialApiAccount-' + index"
                                    >API Account #
                                    <mdb-tooltip material trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip"
                                            >For FedEx, must be 9 digits. For UPS, must be 6 characters alphanumeric. Required if 
                                            any other API fields are populated, otherwise can be left blank.</span
                                        >
                                        <mdb-icon
                                            slot="reference"
                                            class="pl-1"
                                            icon="info-circle"
                                            color="primary"
                                            size="sm"
                                        ></mdb-icon>
                                    </mdb-tooltip>
                                </label>
                                <input
                                    data-test="credentialsModal_credentials_credentialApiAccount"
                                    type="text"
                                    :id="'credentialApiAccount-' + index"
                                    class="form-control"
                                    v-model="creds.credential_api_account"
                                    style="width: 120px"
                                    placeholder="API Account #"
                                    :style="{
                                        'border-color': creds.apiAccountError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div v-if="creds.type == 'ups' || creds.type == 'fedEx'" class="form-group ml-2">
                                <label :for="'credentialApiAccount-' + index"
                                    >Client ID - Primary
                                    <mdb-tooltip material trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip">Must be 30+ characters (or can be left blank).</span>
                                        <mdb-icon
                                            slot="reference"
                                            class="pl-1"
                                            icon="info-circle"
                                            color="primary"
                                            size="sm"
                                        ></mdb-icon>
                                    </mdb-tooltip>
                                </label>
                                <input
                                    type="text"
                                    :id="'credentialClientIdPrimary-' + index"
                                    class="form-control"
                                    v-model="creds.credential_client_id_primary"
                                    style="width: 160px"
                                    placeholder="Client ID - Primary"
                                    :style="{
                                        'border-color': creds.clientIdPrimaryError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div v-if="creds.type == 'ups' || creds.type == 'fedEx'" class="form-group ml-2">
                                <label :for="'credentialApiAccount-' + index"
                                    >Client Secret - Primary
                                    <mdb-tooltip material trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip">Must be 30+ characters (or can be left blank).</span>
                                        <mdb-icon
                                            slot="reference"
                                            class="pl-1"
                                            icon="info-circle"
                                            color="primary"
                                            size="sm"
                                        ></mdb-icon>
                                    </mdb-tooltip>
                                </label>
                                <input
                                    type="text"
                                    :id="'credentialApiClientSecretPrimary-' + index"
                                    class="form-control"
                                    v-model="creds.credential_client_secret_primary"
                                    style="width: 185px"
                                    placeholder="Client Secret - Primary"
                                    :style="{
                                        'border-color': creds.clientSecretPrimaryError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div v-if="creds.type == 'fedEx'" class="form-group ml-2">
                                <label :for="'credentialApiAccount-' + index"
                                    >Client ID - Secondary
                                    <mdb-tooltip material trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip">Must be 30+ characters (or can be left blank).</span>
                                        <mdb-icon
                                            slot="reference"
                                            class="pl-1"
                                            icon="info-circle"
                                            color="primary"
                                            size="sm"
                                        ></mdb-icon>
                                    </mdb-tooltip>
                                </label>
                                <input
                                    type="text"
                                    :id="'credentialClientIdSecondary-' + index"
                                    class="form-control"
                                    v-model="creds.credential_client_id_secondary"
                                    style="width: 160px"
                                    placeholder="Client ID - Secondary"
                                    :style="{
                                        'border-color': creds.clientIdSecondaryError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div v-if="creds.type == 'fedEx'" class="form-group ml-2">
                                <label :for="'credentialApiAccount-' + index"
                                    >Client Secret - Secondary
                                    <mdb-tooltip material trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip">Must be 30+ characters (or can be left blank).</span>
                                        <mdb-icon
                                            slot="reference"
                                            class="pl-1"
                                            icon="info-circle"
                                            color="primary"
                                            size="sm"
                                        ></mdb-icon>
                                    </mdb-tooltip>
                                </label>
                                <input
                                    type="text"
                                    :id="'credentialApiClientSecretSecondary-' + index"
                                    class="form-control"
                                    v-model="creds.credential_client_secret_secondary"
                                    style="width: 185px"
                                    placeholder="Client Secret - Secondary"
                                    :style="{
                                        'border-color': creds.clientSecretSecondaryError ? 'red' : '#ced4da',
                                    }"
                                />
                            </div>
                            <div v-if="creds.credential_download_type == 'ftp'" class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialFtpTypeLabel"
                                    :for="'ftpType-' + index"
                                    >FTP Credential</label
                                >
                                <select
                                    data-test="credentialsModal_credentials_credentialType"
                                    :id="'ftpType-' + index"
                                    class="browser-default custom-select"
                                    v-model="creds.credential_cmp_ftp_cred_id"
                                    style="width: 125px"
                                >
                                    <option
                                        data-test="credentialsModal_credentials_credentialTypeOption"
                                        v-for="ftp of companyFtpCredentials"
                                        v-bind:key="ftp.cfc_id"
                                        :value="ftp.cfc_id"
                                    >
                                        {{
                                            ftp.cfc_type +
                                            "://" +
                                            ftp.cfc_username +
                                            "@" +
                                            ftp.cfc_host +
                                            " (ID: " +
                                            ftp.cfc_id +
                                            ")"
                                        }}
                                    </option>
                                </select>
                            </div>
                            <div v-if="creds.credential_download_type == 'reseller ftp'" class="form-group ml-2">
                                <label
                                    data-test="credentialsModal_credentials_credentialResellerFtpTypeLabel"
                                    :for="'ftpType-' + index"
                                    >FTP Credential</label
                                >
                                <select
                                    data-test="credentialsModal_credentials_credentialType"
                                    :id="'ftpType-' + index"
                                    class="browser-default custom-select"
                                    v-model="creds.credential_reseller_ftp_cred_id"
                                    style="width: 125px"
                                >
                                    <option
                                        data-test="credentialsModal_credentials_credentialTypeOption"
                                        v-for="ftp of resellerFtpCredentials"
                                        v-bind:key="ftp.rfc_id"
                                        :value="ftp.rfc_id"
                                    >
                                        {{
                                            ftp.rfc_type +
                                            "://" +
                                            ftp.rfc_username +
                                            "@" +
                                            ftp.rfc_host +
                                            " (ID: " +
                                            ftp.rfc_id +
                                            ")"
                                        }}
                                    </option>
                                </select>
                            </div>
                            <div
                                v-if="
                                    creds.credential_download_type == 'ftp' ||
                                    creds.credential_download_type == 'reseller ftp'
                                "
                                class="form-group ml-2"
                            >
                                <label
                                    data-test="credentialsModal_credentials_credentialFtpPathLabel"
                                    :for="'credentialFtpPath-' + index"
                                    >FTP Path</label
                                >
                                <input
                                    data-test="credentialsModal_credentials_credentialFtpPath"
                                    type="text"
                                    :id="'credentialFtpPath-' + index"
                                    class="form-control"
                                    v-model="creds.credential_ftp_path"
                                    style="width: 100px"
                                    placeholder="FTP Path"
                                />
                            </div>
                        </div>
                        <div class="row col-5 float-right">
                            <div class="pt-4 mt-2 pl-4">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                        data-test="credentialsModal_credentials_credentialIsActive"
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'credIsActive-' + index"
                                        v-model="creds.credential_is_active"
                                    />
                                    <label
                                        data-test="credentialsModal_credentials_credentialIsActiveLabel"
                                        class="custom-control-label"
                                        :for="'credIsActive-' + index"
                                        >Active
                                        <mdb-tooltip
                                            data-test="credentialsModal_credentials_credentialIsActiveTooltip"
                                            material
                                            trigger="hover"
                                            :options="{ placement: 'right' }"
                                        >
                                            <span slot="tip"
                                                >Active Credential: If checked we will download data from this
                                                credential.</span
                                            >
                                            <mdb-icon
                                                slot="reference"
                                                class="pl-1"
                                                icon="question-circle"
                                                color="primary"
                                                size="sm"
                                            ></mdb-icon>
                                        </mdb-tooltip>
                                    </label>
                                </div>
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                        data-test="credentialsModal_credentials_credentialAlias"
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'credAlias-' + index"
                                        v-model="creds.credential_alias"
                                    />
                                    <label
                                        data-test="credentialsModal_credentials_credentialAliasLabel"
                                        class="custom-control-label"
                                        :for="'credAlias-' + index"
                                        >Alias
                                        <mdb-tooltip
                                            data-test="credentialsModal_credentials_credentialAliasTooltip"
                                            material
                                            trigger="hover"
                                            :options="{ placement: 'right' }"
                                            ><span slot="tip"
                                                >Alias Credential: If checked this is a login we created and have been
                                                invited access from a customer carrier admin login</span
                                            >
                                            <mdb-icon
                                                slot="reference"
                                                class="pl-1"
                                                icon="question-circle"
                                                color="primary"
                                                size="sm"
                                            ></mdb-icon>
                                        </mdb-tooltip>
                                    </label>
                                </div>
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'credIsCorrect-' + index"
                                        v-model="creds.credential_is_not_correct"
                                    />
                                    <label class="custom-control-label" :for="'credIsCorrect-' + index"
                                        >Correct
                                        <mdb-tooltip material trigger="hover" :options="{ placement: 'right' }">
                                            <span slot="tip">{{ creds.credential_error_message }}</span>
                                            <mdb-icon
                                                slot="reference"
                                                class="pl-1"
                                                icon="question-circle"
                                                color="primary"
                                                size="sm"
                                            ></mdb-icon>
                                        </mdb-tooltip>
                                    </label>
                                </div>
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                        data-test="credentialsModal_credentials_credentialNoAudit"
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'noAudit-' + index"
                                        v-model="creds.credential_no_audit"
                                    />
                                    <label
                                        data-test="credentialsModal_credentials_credentialNoAuditLabel"
                                        class="custom-control-label"
                                        :for="'noAudit-' + index"
                                        >No Audit
                                        <mdb-tooltip
                                            data-test="credentialsModal_credentials_credentialNoAuditTooltip"
                                            material
                                            trigger="hover"
                                            :options="{ placement: 'right' }"
                                            ><span slot="tip"
                                                >Disable Audit: Select to disable audit recovery for anything downloaded
                                                from this login.</span
                                            >
                                            <mdb-icon
                                                slot="reference"
                                                class="pl-1"
                                                icon="question-circle"
                                                color="primary"
                                                size="sm"
                                            ></mdb-icon>
                                        </mdb-tooltip>
                                    </label>
                                </div>
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                        data-test="credentialsModal_credentials_credentialIsAccountDiscoveryDisabled"
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'noDiscovery-' + index"
                                        v-model="creds.credential_is_account_discovery_disabled"
                                    />
                                    <label
                                        data-test="credentialsModal_credentials_credentialIsAccountDiscoveryDisabledLabel"
                                        class="custom-control-label"
                                        :for="'noDiscovery-' + index"
                                        >No Discovery
                                        <mdb-tooltip
                                            data-test="credentialsModal_credentials_credentialIsAccountDiscoveryDisabledTooltip"
                                            material
                                            trigger="hover"
                                            :options="{ placement: 'right' }"
                                        >
                                            <span slot="tip"
                                                >Account Discovery: Select to disable automatic discovery of new
                                                accounts on this login. This is useful when more than one company share
                                                a login.</span
                                            >
                                            <mdb-icon
                                                slot="reference"
                                                class="pl-1"
                                                icon="question-circle"
                                                color="primary"
                                                size="sm"
                                            ></mdb-icon>
                                        </mdb-tooltip>
                                    </label>
                                </div>
                            </div>
                            <mdb-tooltip
                                data-test="credentialsModal_credentials_testCarrierLoginTooltip"
                                trigger="hover"
                                :options="{ placement: 'top' }"
                            >
                                <span slot="tip">Test Carrier Login</span>
                                <mdb-btn
                                    data-test="credentialsModal_credentials_testCarrierLoginBtn"
                                    :id="'testLoginCarrier-' + index"
                                    tag="a"
                                    size="sm"
                                    floating
                                    slot="reference"
                                    color="primary"
                                    @click.native="testCarrierLogin(creds)"
                                >
                                    <mdb-icon icon="truck" size="lg"></mdb-icon>
                                </mdb-btn>
                            </mdb-tooltip>
                            <mdb-tooltip
                                data-test="credentialsModal_credentials_testAPIKeyTooltip"
                                trigger="hover"
                                :options="{ placement: 'top' }"
                            >
                                <span slot="tip">Test API Key</span>
                                <mdb-btn
                                    data-test="credentialsModal_credentials_testAPIKeyBtn"
                                    :id="'textAPIkey-' + index"
                                    size="sm"
                                    tag="a"
                                    floating
                                    slot="reference"
                                    @click.native="testAPIKey(creds)"
                                >
                                    <mdb-icon icon="key"></mdb-icon>
                                </mdb-btn>
                            </mdb-tooltip>
                            <mdb-tooltip
                                data-test="credentialsModal_credentials_credentialIdTooltip"
                                material
                                trigger="hover"
                                :options="{ placement: 'right' }"
                            >
                                <span slot="tip">ID: {{ creds.credential_id }}</span>
                                <mdb-icon
                                    slot="reference"
                                    icon="info-circle"
                                    size="sm"
                                    class="pt-4 mt-3 pl-2 muted"
                                ></mdb-icon>
                            </mdb-tooltip>
                        </div>
                    </mdb-row>
                    <hr v-if="index < credentialsInfo.length - 1" />
                </div>
            </div>
            <div id="addCredentialsRow"></div>
        </mdb-modal-body>
        <mdb-modal-footer>
            <mdb-btn
                data-test="credentialsModal_closeModalBtn"
                outline="primary"
                @click.native="$emit('close-credentials-modal', false)"
                >Close
            </mdb-btn>
            <mdb-btn
                data-test="credentialsModal_saveNewCredentialsBtn"
                color="primary"
                @click.native="saveNewCredentials"
                >Save
                <mdb-icon icon="save" class="pl-2"></mdb-icon>
            </mdb-btn>
        </mdb-modal-footer>
    </mdb-modal>
</template>

<script>
import {
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbCol,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbIcon,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    mdbModalHeader,
    mdbModalTitle,
    mdbRow,
    mdbSpinner,
    mdbTooltip,
} from "mdbvue";
export default {
    name: "CarrierCredentials",
    components: {
        mdbSpinner,
        mdbModalFooter,
        mdbModalTitle,
        mdbModalBody,
        mdbModalHeader,
        mdbModal,
        mdbBtn,
        mdbCard,
        mdbCardBody,
        mdbCol,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        mdbIcon,
        mdbRow,
        mdbTooltip,
    },
    props: {
        headers: Object,
        selectedRow: Object,
        credentialsInfo: Array,
        credsInfo: Object,
        resellerFtpCredentials: Array,
        companyFtpCredentials: Array,
        credentialsModal: Boolean,
        onboardingEndpoints: Boolean,
    },
    data() {
        return {
            generatedPassword: null,
            credDownloadType: [
                { value: "website", text: "Website", selected: false },
                { value: "email", text: "Email", selected: false },
                { value: "dropbox", text: "Dropbox", selected: false },
                { value: "ftp", text: "FTP", selected: false },
                { value: "company ftp", text: "Company FTP", selected: false },
                { value: "reseller ftp", text: "Reseller FTP", selected: false },
            ],
            credentialsCountries: [
                { value: "us", text: "US", selected: false },
                { value: "ca", text: "Canada", selected: false },
                { value: "de", text: "Germany", selected: false },
            ],
            carriersText: [
                { value: 1, text: "Add FedEx Credential", selected: false },
                { value: 2, text: "Add UPS Credential", selected: false },
                { value: 3, text: "Add DHL Express Credential", selected: false },
                { value: 4, text: "Add Purolator Credential", selected: false },
                { value: 5, text: "Add DHL eCom Credential", selected: false },
            ],
            fedExType: [
                { text: "FDX Regular", value: "none" },
                { text: "FDX Credit Card", value: "cc" },
                { text: "FDX EDI", value: "edi" },
            ],
            upsType: [
                { text: "UPS Billing Center", value: "billing center" },
                { text: "UPS Supply Chain Solution", value: "billing center scs" },
                { text: "UPS EDI", value: "edi" },
                { text: "UPS Billing Data", value: "billing data" },
            ],
            dhlType: [{ text: "DHL Regular", value: "none" }],
            purolatorType: [{ text: "Purolator Regular", value: "none" }],
            dhlexType: [
                { text: "eCom FTP", value: "ftp" },
                { text: "eCom Portal", value: "portal" },
            ],
            isBusy: false,
        };
    },
    methods: {
        generatePassword() {
            let len = 10;
            let alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            let alphaLength = 52;

            let nums = "0123456789";
            let numsLength = 10;

            let symbols = "!@#$%^&*()_+=[{]};:<>|./?,-";
            let symbolsLength = 27;

            let password = "";

            // random characters, mix of upper and lowercase
            for (let i = 0; i < len - 4; i++) {
                let index = Math.floor(Math.random() * alphaLength);
                password += alpha.charAt(index);
            }

            // add one guaranteed lowercase and uppercase
            password += alpha.charAt(Math.floor(Math.random() * alphaLength)).toLowerCase();
            password += alpha.charAt(Math.floor(Math.random() * alphaLength)).toUpperCase();

            password += nums.charAt(Math.floor(Math.random() * numsLength));
            password += symbols.charAt(Math.floor(Math.random() * symbolsLength));

            this.generatedPassword = password;
        },
        async testAPIKey(cred) {
            if (cred.type != "ups" && cred.type != "fedEx" && cred.type != "dhl") {
                this.$notify.error({
                    message: "The carrier is not supported at this time.",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            if (cred.type == "ups") {
                if (!cred.credential_username) {
                    this.$notify.error({
                        message: "Username cannot be empty!",
                        title: "Error",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    return;
                }
                if (!cred.credential_password) {
                    this.$notify.error({
                        message: "Password cannot be empty!",
                        title: "Error",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    return;
                }
                if (!cred.credential_accesskey) {
                    this.$notify.error({
                        message: "API key cannot be empty!",
                        title: "Error",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    return;
                }
            } else if (cred.type == "fedEx") {
                if (!cred.credential_api_pass) {
                    this.$notify.error({
                        message: "API password cannot be empty!",
                        title: "Error",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    return;
                }
                if (!cred.credential_meter) {
                    this.$notify.error({
                        message: "API Meter cannot be empty!",
                        title: "Error",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    return;
                }
                if (!cred.credential_api_account) {
                    this.$notify.error({
                        message: "API Account cannot be empty!",
                        title: "Error",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    return;
                }
            }
            await this.$http
                .get("carriercredentialvalidator/api/?carrier=" + cred.type, {
                    headers: this.headers,
                    params: this.testKeyParams(cred),
                })
                .then(async (response) => {
                    let testResponse = response.data;
                    if (testResponse == "OK") {
                        this.$notify.success({
                            message: testResponse,
                            title: "Success",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: testResponse,
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
        },
        async testCarrierLogin(cred) {
            if (cred.type != "ups" && cred.type != "fedEx" && cred.type != "dhl") {
                this.$notify.error({
                    message: "The carrier is not supported at this time.",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            if (!cred.credential_username) {
                this.$notify.error({
                    message: "Username cannot be empty!",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            if (!cred.credential_password) {
                this.$notify.error({
                    message: "Password cannot be empty!",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            await this.$http
                .get(
                    "carriercredentialvalidator/login/?carrier=" +
                        cred.type +
                        "&username=" +
                        cred.credential_username +
                        "&password=" +
                        cred.credential_password,
                    {
                        headers: this.headers,
                    }
                )
                .then(async (response) => {
                    let testResponse = response.data;
                    if (testResponse == "OK") {
                        this.$notify.success({
                            message: testResponse,
                            title: "Success",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: testResponse,
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
        },
        addCredentialsRow(carrier) {
            let newCarrier = {};
            newCarrier.credential_country = "us";
            newCarrier.credential_download_type = "website";
            newCarrier.credential_username = "";
            newCarrier.credential_password = "";
            newCarrier.credential_accesskey = null;
            newCarrier.credential_is_active = false;
            newCarrier.credential_alias = false;
            newCarrier.credential_no_audit = false;
            newCarrier.credential_is_account_discovery_disabled = false;
            newCarrier.credential_car_id = this.credentialsInfo.length + 1;
            newCarrier.credential_cmp_id = this.selectedRow.cust_id;
            if (carrier.text == "Add FedEx Credential") {
                newCarrier.type = "fedEx";
                newCarrier.typeOptions = this.fedExType;
                newCarrier.credential_type = this.fedExType[0].value;
                newCarrier.credential_car_id = 1;
            } else if (carrier.text == "Add UPS Credential") {
                newCarrier.type = "ups";
                newCarrier.typeOptions = this.upsType;
                newCarrier.credential_type = this.upsType[0].value;
                newCarrier.credential_car_id = 2;
            } else if (carrier.text == "Add DHL Express Credential") {
                newCarrier.type = "dhl";
                newCarrier.typeOptions = this.dhlType;
                newCarrier.credential_type = this.dhlType[0].value;
                newCarrier.credential_accesskey = "eaudit";
                newCarrier.credential_api_pass = "sOYwbh8Rt7";
                newCarrier.credential_car_id = 3;
            } else if (carrier.text == "Add Purolator Credential") {
                newCarrier.type = "purolator";
                newCarrier.typeOptions = this.purolatorType;
                newCarrier.credential_type = this.purolatorType[0].value;
                newCarrier.credential_car_id = 4;
            } else {
                newCarrier.type = "dhlex";
                newCarrier.typeOptions = this.dhlexType;
                newCarrier.credential_type = this.dhlexType[0].value;
                newCarrier.credential_car_id = 5;
            }
            this.$emit("push-to-credentials-info", newCarrier);
        },
        async saveNewCredentials() {
            this.isBusy = true;
            let count = this.credentialsInfo.length;
            let endpoint = this.onboardingEndpoints ? "/newcustomercredential" : "/admincompanycredential"
            for (let i = 0; i < this.credentialsInfo.length; i++) {
                const isGood = await this.validateCredential(this.credentialsInfo[i]);
                if (isGood) {
                    await this.setCredentialErrorsToFalse(i);
                    let credentialID = this.credentialsInfo[i].credential_id;
                    // make a copy of the credential opject, and flip the
                    // truthiness of the "Correct" checkbox so the corrent state
                    // is sent to the database, but the UI doesn't show the change
                    let flippedCredentialsInfo = { ...this.credentialsInfo[i] };
                    flippedCredentialsInfo.credential_is_not_correct =
                        !flippedCredentialsInfo.credential_is_not_correct;

                    if (credentialID > 0) {
                        await this.$httpAdmin.put(
                            endpoint + "/" + credentialID,
                            this.carrierCredentialsParams(flippedCredentialsInfo),
                            { headers: this.headers }
                        );
                    } else {
                        await this.$httpAdmin.post(
                            endpoint,
                            this.carrierCredentialsParams(flippedCredentialsInfo),
                            { headers: this.headers }
                        );
                    }
                    count--;
                    if (count == 0) {
                        this.$notify.success({
                            message: "The credentials has been saved successfully.",
                            title: "Credentials Saved",
                            timeOut: 5000,
                            variant: "success",
                        });
                        this.$emit("close-credentials-modal", false); // close modal
                    }
                } else {
                    this.credentialsInfo[i].error = true;
                }
            }
            this.$forceUpdate();
            this.isBusy = false;
        },
        async setCredentialErrorsToFalse(i) {
            this.credentialsInfo[i].error = false;
            this.credentialsInfo[i].usernameError = false;
            this.credentialsInfo[i].passwordError = false;
            this.credentialsInfo[i].accessKeyError = false;
            this.credentialsInfo[i].apiPassError = false;
            this.credentialsInfo[i].meterError = false;
            this.credentialsInfo[i].apiAccountError = false;
            this.credentialsInfo[i].clientIdPrimaryError = false;
            this.credentialsInfo[i].clientSecretPrimaryError = false;
            this.credentialsInfo[i].clientIdSecondaryError = false;
            this.credentialsInfo[i].clientSecretSecondaryError = false;
        },
        carrierCredentialsParams(creds) {
            return {
                credential_car_id: creds.credential_car_id,
                credential_cmp_id: this.selectedRow.cust_id ?? this.selectedRow.cmp_id,
                credential_id: creds.credential_id,
                credential_username: creds.credential_username,
                credential_password: creds.credential_password,
                credential_accesskey: creds.credential_accesskey,
                credential_api_pass: creds.credential_api_pass,
                credential_meter: creds.credential_meter,
                credential_api_account: creds.credential_api_account,
                credential_is_active: creds.credential_is_active,
                credential_alias: creds.credential_alias,
                credential_no_audit: creds.credential_no_audit,
                credential_type: creds.credential_type,
                credential_country: creds.credential_country,
                credential_download_type: creds.credential_download_type,
                credential_is_account_discovery_disabled: creds.credential_is_account_discovery_disabled,
                credential_is_not_correct: creds.credential_is_not_correct,
                credential_client_id_primary: creds.credential_client_id_primary,
                credential_client_secret_primary: creds.credential_client_secret_primary,
                credential_client_id_secondary: creds.credential_client_id_secondary,
                credential_client_secret_secondary: creds.credential_client_secret_secondary,
            };
        },
        async validateCredential(item) {
            if (!item.credential_username || !item.credential_password) {
                if (!item.credential_username) {
                    item.usernameError = true;
                } else {
                    item.usernameError = false;
                }
                if (!item.credential_password) {
                    item.passwordError = true;
                } else {
                    item.passwordError = false;
                }
                this.$notify.error({
                    message:
                        "Please check the entered credential; username and password are required for active/inactive credentials. API information is also required if Active is selected.",
                    title: "Error",
                    timeOut: 10000,
                    variant: "danger",
                });
                return false;
            } else {
                item.usernameError = false;
                item.passwordError = false;
            }

            this.trimWhitespace(item);

            if (item.credential_is_active == false) return true;

            let message = "";
            message = await this.verifyAccessKey(item, message);
            if (item.type === "fedEx") {
                message = await this.verifyAPIPassword(item, message);
                message = await this.verifyMeter(item, message);
            }
            message = await this.verifyAPIAccountNum(item, message);
            if (
                item.credential_client_id_primary != null &&
                item.credential_client_id_primary.length > 0 &&
                item.credential_client_id_primary.length < 30
            ) {
                message += "Client ID Primary must be at least 30 characters in length.";
                item.clientIdPrimaryError = true;
            } else {
                item.clientIdPrimaryError = false;
            }
            if (
                item.credential_client_secret_primary != null &&
                item.credential_client_secret_primary.length > 0 &&
                item.credential_client_secret_primary.length < 30
            ) {
                message += "Client Secret Primary must be at least 30 characters in length.";
                item.clientSecretPrimaryError = true;
            } else {
                item.clientSecretPrimaryError = false;
            }
            if (
                item.credential_client_id_secondary != null &&
                item.credential_client_id_secondary.length > 0 &&
                item.credential_client_id_secondary.length < 30
            ) {
                message += "Client ID Secondary must be at least 30 characters in length.";
                item.clientIdSecondaryError = true;
            } else {
                item.clientIdSecondaryError = false;
            }
            if (
                item.credential_client_secret_secondary != null &&
                item.credential_client_secret_secondary.length > 0 &&
                item.credential_client_secret_secondary.length < 30
            ) {
                message += "Client Secret Secondary must be at least 30 characters in length.";
                item.clientSecretSecondaryError = true;
            } else {
                item.clientSecretSecondaryError = false;
            }

            if (message != "") {
                this.$notify.error({
                    message: "You need to enter the following values if the credential is active: " + message,
                    title: "Error",
                    timeOut: 10000,
                    variant: "danger",
                });
                return false;
            } else return true;
        },
        async verifyAccessKey(item, message) {
            let errors = false;
            if (item.credential_accesskey != "" && item.credential_accesskey != null) {
                let alphanumeric = this.isAlphanumeric(item.credential_accesskey);
                if (!alphanumeric) {
                    message += "Access Key must be alphanumeric. ";
                    errors = true;
                }
                if (item.credential_accesskey.length != 16) {
                    message += "Access Key must be 16 characters in length. ";
                    errors = true;
                }
            }
            if (errors) {
                item.accessKeyError = true;
            } else {
                item.accessKeyError = false;
            }
            return message;
        },
        async verifyAPIPassword(item, message) {
            let errors = false;
            if (item.credential_api_pass != "" && item.credential_api_pass != null) {
                let alphanumeric = this.isAlphanumeric(item.credential_api_pass);
                if (!alphanumeric) {
                    message += "API Password must be alphanumeric. ";
                    errors = true;
                }
                if (item.credential_api_pass.length != 25) {
                    message += "API Password must be 25 characters in length. ";
                    errors = true;
                }
            }
            if (errors) {
                item.apiPassError = true;
            } else {
                item.apiPassError = false;
            }
            return message;
        },
        async verifyMeter(item, message) {
            let errors = false;
            if (item.credential_meter != "" && item.credential_meter != null) {
                let numeric = this.isNumeric(item.credential_meter);
                if (!numeric) {
                    message += "Meter must contain all digits. ";
                    errors = true;
                }
                if (item.credential_meter.length != 9) {
                    message += "Meter must be 9 digits in length. ";
                    errors = true;
                }
            }
            if (errors) {
                item.meterError = true;
            } else {
                item.meterError = false;
            }
            return message;
        },
        async verifyAPIAccountNum(item, message) {
            let errors = false;
            if (item.type === "fedEx") {
                if (item.credential_api_account != "" && item.credential_api_account != null) {
                    let numeric = this.isNumeric(item.credential_api_account);
                    if (!numeric) {
                        message += "API Account must contain all digits. ";
                        errors = true;
                    }
                    if (item.credential_api_account.length != 9) {
                        message += "API Account must be 9 digits in length. ";
                        errors = true;
                    }
                }
                else {
                    if (item.credential_accesskey != "") {
                        message += "API Account # must be populated if Access Key is populated. ";
                        errors = true;
                    }
                    if (item.credential_client_id_primary != "") {
                        message += "API Account # must be populated if Client ID - Primary is populated. ";
                        errors = true;
                    }
                    if (item.credential_client_secret_primary != "") {
                        message += "API Account # must be populated if Client Secret - Primary is populated. ";
                        errors = true;
                    }
                    if (item.credential_client_id_secondary != "") {
                        message += "API Account # must be populated if Client ID - Secondary is populated. ";
                        errors = true;
                    }
                    if (item.credential_client_secret_secondary != "") {
                        message += "API Account # must be populated if Client Secret - Secondary is populated. ";
                        errors = true;
                    }
                }
            }
            if (item.type === "ups") {
                if (item.credential_api_account != "" && item.credential_api_account != null) {
                    let alphanumeric = this.isAlphanumeric(item.credential_api_account);
                    if (!alphanumeric) {
                        message += "API Account must be alphanumeric. ";
                        errors = true;
                    }
                    if (item.credential_api_account.length != 6) {
                        message += "API Account must be 6 digits in length. ";
                        errors = true;
                    }
                }
                else {
                    if (item.credential_accesskey != "") {
                        message += "API Account # must be populated if Access Key is populated. ";
                        errors = true;
                    }
                    if (item.credential_client_id_primary != "") {
                        message += "API Account # must be populated if Client ID - Primary is populated. ";
                        errors = true;
                    }
                    if (item.credential_client_secret_primary != "") {
                        message += "API Account # must be populated if Client Secret - Primary is populated. ";
                        errors = true;
                    }
                }
            }
            if (errors) {
                item.apiAccountError = true;
            } else {
                item.apiAccountError = false;
            }
            return message;
        },
        isAlphanumeric(str) {
            return /^[a-zA-Z0-9]+$/.test(str);
        },
        isNumeric(val) {
            return /^\d+$/.test(val);
        },
        testKeyParams(cred) {
            return {
                Username: cred.credential_username,
                Password: cred.credential_password,
                APIKey: cred.credential_accesskey,
                APIPass: cred.credential_api_pass,
                MeterNumber: cred.credential_meter,
                AccountNumber: cred.credential_api_account,
            };
        },
        trimWhitespace(item) {
            if (item.credential_accesskey != null && item.credential_accesskey.length > 0) {
                item.credential_accesskey = item.credential_accesskey.trim();
            }
            if (item.credential_api_account != null && item.credential_api_account.length > 0) {
                item.credential_api_account = item.credential_api_account.trim();
            }
            if (item.credential_api_pass != null && item.credential_api_pass.length > 0) {
                item.credential_api_pass = item.credential_api_pass.trim();
            }
            if (item.credential_meter != null && item.credential_meter.length > 0) {
                item.credential_meter = item.credential_meter.trim();
            }
            if (item.credential_client_id_primary != null && item.credential_client_id_primary.length > 0) {
                item.credential_client_id_primary = item.credential_client_id_primary.trim();
            }
            if (item.credential_client_secret_primary != null && item.credential_client_secret_primary.length > 0) {
                item.credential_client_secret_primary = item.credential_client_secret_primary.trim();
            }
            if (item.credential_client_id_secondary != null && item.credential_client_id_secondary.length > 0) {
                item.credential_client_id_secondary = item.credential_client_id_secondary.trim();
            }
            if (item.credential_client_secret_secondary != null && item.credential_client_secret_secondary.length > 0) {
                item.credential_client_secret_secondary = item.credential_client_secret_secondary.trim();
            }
        },
    },
};
</script>

<style scoped></style>
