<template>
    <mdb-side-nav style="display: inline-block" mask="strong" :OpenedFromOutside.sync="showSideNav" hidden waves>
        <ul style="padding-top: 70px">
            <!-- <li>{{ userInfo.usrResellerId }}</li> -->
            <li>
                <mdb-side-nav-nav v-if="userInfo.usrLevel === 'super_admin'">
                    <mdb-side-nav-item href="#">
                        <router-link to="/admin" class="pl-0" @click.native="closeSideNav"
                        >
                            <mdb-icon icon="home" class="mr-3"/>
                            Admin Dashboard
                        </router-link
                        >
                    </mdb-side-nav-item>
                    <mdb-side-nav-cat name="Manage Processes" icon="cogs" v-if="userInfo.usrRestrictType === 1">
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/appcontroller" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="cubes" class="mr-2"/>
                                App Controller
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/nodestatus" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="sitemap" class="mr-2"/>
                                Node Status
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/triagelist" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="comment" class="mr-2"/>
                                Triage List
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#"
                        >
                            <mdb-icon icon="caret-right" class="mr-2"/>
                            Process Reports
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/systemerrors" class="pl-4" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="flag" class="mr-2"/>
                                System Errors
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/activitieslog" class="pl-4" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="tasks" class="mr-2"/>
                                Activities Log
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/auditreport" class="pl-4" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="cog" class="mr-2"/>
                                Audit Report
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/wtfreport" class="pl-4" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="flag" class="mr-2"/>
                                WTF Report
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/extractfilefailed" class="pl-4" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="flag" class="mr-2"/>
                                Extract File Failed Report
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/duplicateinvoices" class="pl-4" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="flag" class="mr-2"/>
                                Duplicate Invoices
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/admin/notifications" class="pl-4" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="bell" class="mr-2"/>
                                Admin Notifications
                            </router-link
                            >
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat name="Manage Customers" icon="users">
                        <mdb-side-nav-item href="#" v-if="[1, 2, 3, 5, 7, 9].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/onboardingadmin" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="plus-square" class="mr-2"/>
                                Onboarding
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 2, 3, 5, 7, 9].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/customers" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="check-square" class="mr-2"/>
                                Customers
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 2, 5, 9].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/manageusers" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="users" class="mr-2"/>
                                Manage Users
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 5].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/loginkpis" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="meh" class="mr-2"/>
                                Login KPIs
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 2, 5].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/faileddownloads" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="meh" class="mr-2"/>
                                Failed Downloads
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <mdb-icon icon="caret-right"/>
                            Diagnostics
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 2, 5].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/missedinvoices" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="bell" class="pl-4 mr-2"/>
                                Missed Invoices
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/carrieraccounts" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="closed-captioning" class="pl-4 mr-2"/>
                                Carrier Accounts
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/auditpackageerrors" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="cube" class="pl-4 mr-2"/>
                                Audit Package Errors
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 2].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/download" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="download" class="pl-4 mr-2"/>
                                Download
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 2, 5].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/failedcredentials" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="truck" class="pl-4 mr-2"/>
                                Failed Credentials
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/attachmenterrors" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="envelope" class="pl-4 mr-2"/>
                                Attachment Errors
                            </router-link
                            >
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat
                        name="Manage Reporting"
                        icon="file-invoice"
                        v-if="[1, 3, 5, 7, 9].indexOf(userInfo.usrRestrictType) > -1"
                    >
                        <mdb-side-nav-item href="#" v-if="[1, 3, 5, 7, 9].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/myreports" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="file-invoice" class="mr-2"/>
                                My Reports
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/createreport" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="plus" class="mr-2"/>
                                Create Reports
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 9].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/schedulereports" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="calendar" class="mr-2"/>
                                Schedule Reports
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/fieldsmanager" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="columns" class="mr-2"/>
                                Fields Manager
                            </router-link
                            >
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat
                        name="Manage Accounting"
                        icon="dollar-sign"
                        v-if="[1, 2, 3, 5, 9].indexOf(userInfo.usrRestrictType) > -1"
                    >
                        <mdb-side-nav-item href="#" v-if="[1, 3, 5, 9].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/adjustments" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="money-bill" class="mr-2"/>
                                Adjustments
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 2, 3, 5, 9].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/resellerinvoices" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="file" class="mr-2"/>
                                Reseller Invoices
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 3, 5].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/resellercommission" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="trophy" class="mr-2"/>
                                Reseller Commission
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="[1, 3, 5].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/addoncommission" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="trophy" class="mr-2"/>
                                Addon Commission
                            </router-link
                            >
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat
                        name="Manual Audit"
                        icon="binoculars"
                        v-if="[1, 5].indexOf(userInfo.usrRestrictType) > -1"
                    >
                        <mdb-side-nav-item href="#" v-if="[1, 5].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/fullmanual" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="ambulance" class="mr-2"/>
                                Full Manual
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/npods" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="question-circle" class="mr-2"/>
                                NPODs
                            </router-link
                            >
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat
                        name="Admin Tools"
                        icon="wrench"
                        v-if="[1, 2, 5].indexOf(userInfo.usrRestrictType) > -1"
                    >
                        <mdb-side-nav-item href="#" v-if="[1, 2, 5].indexOf(userInfo.usrRestrictType) > -1">
                            <router-link to="/admin/packagegenie" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="magic" class="mr-2"/>
                                Package Genie +
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/applyglcode" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="code-branch" class="mr-2"/>
                                Apply GLCode
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/credentialchecker" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="truck" class="mr-2"/>
                                Credential Checker
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/downloadinvoice" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="download" class="mr-2"/>
                                Download Invoice
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" v-if="userInfo.usrRestrictType === 1">
                            <router-link to="/admin/ninjadownloader" class="pl-0" @click.native="closeSideNav"
                            >
                                <mdb-icon icon="user-ninja" class="mr-2"/>
                                Ninja Downloader
                            </router-link
                            >
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                </mdb-side-nav-nav>
            </li>
            <hr v-if="userInfo.usrLevel === 'super_admin'" class="white"/>
            <li>
                <mdb-dropdown
                    class="w-100"
                    data-test="companiesDropdown"
                    color="primary"
                    multiLevel
                    v-if="
                        userInfo.usrLevel === 'super_admin' &&
                        [1, 2, 3, 4, 7, 8, 9].indexOf(userInfo.usrRestrictType) > -1
                    "
                >
                    <mdb-dropdown-toggle data-test="companiesDropdownToggle" slot="toggle" color="primary" class="w-100"
                    >Login As
                    </mdb-dropdown-toggle
                    >
                    <mdb-dropdown-menu data-test="companiesDropdownMenu" color="primary">
                        <input
                            data-test="companiesDropdownInput"
                            type="text"
                            class="form-control"
                            v-model="searchTextComp"
                            placeholder="Company Name"
                            @keydown.enter="getCompanies"
                        />
                        <div class="text-center p-2" v-if="loadingCompanies">
                            <mdb-spinner data-test="companiesDropdownSpinner" small multicolor></mdb-spinner>
                        </div>
                        <div
                            data-test="companiesDropdown_noCompanies"
                            v-if="!loadingCompanies && !companies"
                            class="p-2"
                        >
                            No companies found.
                        </div>
                        <mdb-list-group
                            data-test="companiesDropdown_companiesListGroup"
                            v-if="companies && !loadingCompanies"
                        >
                            <mdb-list-group-item
                                data-test="companiesDropdown_companiesListGroupItem"
                                v-for="item of companies"
                                @click.native="authenticate(item)"
                                v-bind:key="item.cmp_id"
                            >
                                ({{ item.cmp_id }}) {{ item.cmp_name }}
                            </mdb-list-group-item>
                        </mdb-list-group>
                    </mdb-dropdown-menu>
                </mdb-dropdown>
            </li>
            <li v-if="userInfo.cmpID !== null">
                <p class="col-12">{{ userInfo.cmpID }} - {{ userInfo.usrCmpName }}</p>
                <mdb-side-nav-nav>
                    <mdb-side-nav-item href="#">
                        <router-link to="/" class="pl-0" @click.native="closeSideNav"
                        >
                            <mdb-icon icon="home" class="mr-2"/>
                            Dashboard
                        </router-link
                        >
                    </mdb-side-nav-item>
                    <mdb-side-nav-cat name="Analyze" icon="file-invoice">
                        <mdb-side-nav-item href="#">
                            <router-link to="/trends" class="pl-0" @click.native="closeSideNav">Trends</router-link>
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/invoices" class="pl-0" @click.native="closeSideNav">Invoices</router-link>
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat name="View" icon="chart-area">
                        <mdb-side-nav-item href="#">
                            <router-link to="/myview" class="pl-0" @click.native="closeSideNav">My View</router-link>
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/gallery" class="pl-0" @click.native="closeSideNav">Gallery</router-link>
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat name="Report" icon="file-alt">
                        <mdb-side-nav-item href="#">
                            <router-link to="/myreports" class="pl-0" @click.native="closeSideNav"
                            >My Reports
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/createreports" class="pl-0" @click.native="closeSideNav"
                            >Create Reports
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/scheduling" class="pl-0" @click.native="closeSideNav"
                            >Scheduling
                            </router-link
                            >
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat name="Tools" icon="wrench">
                        <mdb-side-nav-item v-if="userInfo.usrLevel === 'super_admin'" href="#">
                            <router-link to="/glcoding2" class="pl-0" @click.native="closeSideNav"
                            >2nd GL Coding
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item v-if="userInfo.usrLevel === 'super_admin'" href="#">
                            <router-link to="/updategl" class="pl-0" @click.native="closeSideNav"
                            >Update GL
                            </router-link
                            >
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#" @click.native="showInvoice">Invoice Genie</mdb-side-nav-item>
                        <mdb-side-nav-item href="#" @click.native="showPackage">Package Genie</mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-cat name="Add-ons" icon="plug">
                        <mdb-side-nav-item href="#">
                            <router-link to="/audit" class="pl-0" @click.native="closeSideNav">
                                <mdb-icon
                                    icon="lock"
                                    v-if="premiumServices && !premiumServices.find((c) => c === 'audit')"
                                ></mdb-icon
                                >
                                Audit
                            </router-link>
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/ratecalculator" class="pl-0" @click.native="closeSideNav">
                                <mdb-icon
                                    icon="lock"
                                    v-if="premiumServices && !premiumServices.find((c) => c === 'ratecalculator')"
                                ></mdb-icon
                                >
                                Rate Calculator
                            </router-link>
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/bulktrack" class="pl-0" @click.native="closeSideNav">
                                <mdb-icon
                                    icon="lock"
                                    v-if="premiumServices && !premiumServices.find((c) => c === 'bulktrack')"
                                ></mdb-icon
                                >
                                Bulk Track
                            </router-link>
                        </mdb-side-nav-item>
                        <mdb-side-nav-item href="#">
                            <router-link to="/glcoding" class="pl-0" @click.native="closeSideNav">
                                <mdb-icon
                                    icon="lock"
                                    v-if="premiumServices && !premiumServices.find((c) => c === 'glcode')"
                                ></mdb-icon
                                >
                                GL Coding
                            </router-link>
                        </mdb-side-nav-item>
                    </mdb-side-nav-cat>
                </mdb-side-nav-nav>
            </li>
            <hr class="white" v-if="userInfo.cmpID !== null && companyOptions !== null && !loadingCompanies && userInfo.usrLevel !== 'super_admin'">
            <li v-if="userInfo.cmpID !== null && companyOptions !== null && !loadingCompanies && userInfo.usrLevel !== 'super_admin'">
                <mdb-dropdown
                    class="w-100"
                    color="primary"
                    multiLevel
                >
                    <mdb-dropdown-toggle data-test="companiesDropdownToggle" slot="toggle" color="primary" class="w-100"
                    >Company Options
                    </mdb-dropdown-toggle
                    >
                    <mdb-dropdown-menu color="primary">
                        <mdb-list-group
                        >
                            <mdb-list-group-item
                                v-for="item of companyOptions"
                                @click.native="changeCompany(item)"
                                v-bind:key="item.cmp_id"
                            >
                                ({{ item.cmp_id }}) {{ item.cmp_name }}
                            </mdb-list-group-item>
                        </mdb-list-group>
                    </mdb-dropdown-menu>
                </mdb-dropdown>
            </li>
        </ul>
    </mdb-side-nav>
</template>

<script>
import {mdbSideNav} from "mdbvue";
import auth from "@/lib/auth";

export default {
    name: "SideNav",
    components: {
        mdbSideNav,
    },
    props: {
        userInfo: Object,
        headers: Object,
        services: Array,
        showSideNav: Boolean,
        showInvoiceGenie: Boolean,
        showPackageGenie: Boolean,
        companyOptions: Array,
        loginAs: Boolean,
    },
    data() {
        return {
            searchTextComp: null,
            loadingCompanies: false,
            companies: null,
            showProcessReportOptions: false,
        };
    },
    methods: {
        setAuthenticated(status) {
            this.isAuthenticated = status;
        },
        updateUser(user) {
            this.userInfo = user;
        },
        closeSideNav() {
            this.$emit("close-side-nav");
        },
        async showInvoice() {
            this.closeSideNav();
            this.$emit("show-invoice");
        },
        async showPackage() {
            this.closeSideNav();
            this.$emit("show-package");
        },
        async getCompanies() {
            let companies;
            const search = this.searchTextComp;
            this.companies = {};
            this.loadingCompanies = true;
            if (!search) {
                companies = await this.$httpAdmin.get(
                    `/admincompany?limit=10&size=10&page=1&sortColumn=cmp_id&sortkey=cmp_id&sortDir=desc`,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Basic ${window.btoa(this.userInfo.adminPass)}`,
                        },
                    }
                );
                this.companies = companies.data[0].Items;
            } else {
                companies = await this.$httpAdmin.get(
                    `/admincompany?cmp_name=${search}&sortColumn=cmp_id&sortkey=cmp_id&sortDir=desc`,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Basic ${window.btoa(this.userInfo.adminPass)}`,
                        },
                    }
                );
                this.companies = companies.data;
            }
            this.loadingCompanies = false;
        },
        async authenticate(item) {
            this.loadingCompanies = true;
            const user = await auth.loginAs(this.userInfo.adminPass, item.cmp_id);
            if (user) {
            const adminPass = this.userInfo.adminPass;
                user.data.usr_companies[0] = item.cmp_id;
                user.adminpass = adminPass;
                user.loginAs = true;
                const updated = await auth.setSession(user);
                this.$emit("userInfo", updated);
                this.$emit("loginAs", true);
                if (this.userInfo.usrLevel == "super_admin") {
                    if (this.$router.currentRoute.name === "Dashboard") {
                        this.$router.go(0);
                    } else {
                        this.$router.push({name: "Dashboard"});
                        this.$router.go(0);
                    }
                } else {
                    this.$router.go(0);
                }
            }
            this.loadingCompanies = false;
        },

        async changeCompany(item) {
            this.loadingCompanies = true;
            this.companyOptions.sort(function(x,y){ return x == item ? -1 : y == item ? 1 : 0; });
            this.userInfo.usrCompanies = this.companyOptions;
            const companies = [];
            for(let i = 0; i < this.userInfo.usrCompanies.length; i++) {
                companies.push(this.userInfo.usrCompanies[i].cmp_id)
            }
            const user = {
                data: {
                    usr_id: this.userInfo.usrId,
                    usr_scmp_id: this.userInfo.usrScmpId,
                    usr_cmp_name: item.cmp_name,
                    usr_reseller_id: this.userInfo.usrResellerId,
                    usr_reseller_name: this.userInfo.usrResellerName,
                    usr_reseller_logo: this.userInfo.usrResellerLogo,
                    usr_first_name: this.userInfo.usrFirstName,
                    usr_last_name: this.userInfo.usrLastName,
                    usr_phone: this.userInfo.usrPhone,
                    usr_time_zone: this.userInfo.usrTimeZone,
                    usr_has_accepted_term: this.userInfo.usrHasAcceptedTerms,
                    usr_email: this.userInfo.usrEmail,
                    usr_type: this.userInfo.usrType,
                    usr_restrict_type: this.userInfo.usrRestrictType,
                    usr_accesskey: this.userInfo.usrAccessKey,
                    usr_api_key: this.userInfo.usrApiKey,
                    usr_api_pass: this.userInfo.usrApiPass,
                    usr_last_login_date: this.userInfo.usrLastLoginDate,
                    usr_base_country: this.userInfo.usrBaseCountry,
                    usr_companies: companies,
                    usr_level: this.userInfo.usrLevel,
                    usr_is_web_registered: this.userInfo.usrIsWebRegistered,
                    usr_reseller_support_email: this.userInfo.usrResellerSupportEmail,
                    adminpass: this.userInfo.adminPass ? this.userInfo.adminPass : null,
                }
            }
            const updated = auth.setSession(user);
            this.$emit("userInfo", updated);
            this.loadingCompanies = false;
            if(this.$route.name == 'Dashboard') {
                this.$router.go(0);
            }
            else {
                await this.$router.push({name: "Dashboard"});
            }
            this.closeSideNav();
        },
    },
    computed: {
        premiumServices() {
            return this.services
                ? this.services.map((s) => {
                    if (s.cps_is_active === "1") return s.cps_name;
                })
                : null;
        },
    },
    created() {
        if (this.userInfo.usrLevel === "super_admin") this.getCompanies();
    },
};
</script>

<style scoped></style>
