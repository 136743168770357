<template>
    <div data-test="page" class="m-4 carrierAccounts">
        <h3 data-test="pageTitle" class="my-4 mx-2">Manage Carrier Accounts</h3>
        <mdb-select
            data-test="companiesSelect"
            class="flex-fill"
            placeholder="Select a company to see a list of their accounts"
            v-if="companies"
            v-model="companies"
            label="Select Company"
            search
            @change="selectCompany"
        />
        <mdb-btn data-test="spinner" v-else size="sm" outline="danger" disabled class="flex-fill"
            ><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading
            Companies...</mdb-btn
        >
        <mdb-row>
            <vue-good-table data-test="vue-good-table" :rows="records" :columns="columns">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'actions'">
                        <mdb-btn @click.native="saveAccount(props.row)" class="p-2 d-flex">
                            <mdb-icon icon="save" class="mt-1 mr-2"></mdb-icon>
                            <span>Save</span>
                        </mdb-btn>
                    </div>
                    <div v-else-if="props.column.field === 'acct_car_id'">
                        {{ carriers[String(props.row.acct_car_id)].name }}
                    </div>
                    <div v-else-if="props.column.field === 'acct_mns_delay_days'">
                        <mdb-input type="text" v-model="props.row.acct_mns_delay_days"></mdb-input>
                    </div>
                    <div v-else-if="props.column.field === 'acct_npod_delay_days'">
                        <mdb-input type="text" v-model="props.row.acct_npod_delay_days"></mdb-input>
                    </div>
                    <div v-else-if="props.column.field === 'acct_no_audit'">
                        <mdb-input
                            type="checkbox"
                            v-model="props.row.acct_no_audit"
                            :id="'acct_no_audit' + props.row.originalIndex"
                            :name="'acct_no_audit' + props.row.originalIndex"
                        ></mdb-input>
                    </div>
                    <div v-else-if="props.column.field === 'acct_is_gsr_waiver'">
                        <mdb-input
                            type="checkbox"
                            v-model="props.row.acct_is_gsr_waiver"
                            :id="'acct_is_gsr_waiver' + props.row.originalIndex"
                            :name="'acct_is_gsr_waiver' + props.row.originalIndex"
                        >
                        </mdb-input>
                    </div>
                    <div v-else-if="props.column.field === 'acct_is_address_correction_waiver'">
                        <mdb-input
                            type="checkbox"
                            v-model="props.row.acct_is_address_correction_waiver"
                            :id="'acct_is_address_correction_waiver' + props.row.originalIndex"
                            :name="'acct_is_address_correction_waiver' + props.row.originalIndex"
                        ></mdb-input>
                    </div>
                    <div v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>
            </vue-good-table>
        </mdb-row>
    </div>
</template>
<script>
import { mdbBtn, mdbRow, mdbSelect } from "mdbvue";
import { Carriers } from '../../data/carriers';

export default {
    name: "AdminCarrierAccounts",
    components: { mdbBtn, mdbRow, mdbSelect },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            companies: null,
            columns: [
                {
                    label: "Accout Number (Account Nick Name)",
                    field: "acct_number",
                },
                {
                    label: "Carrier",
                    field: "acct_car_id",
                },
                {
                    label: "Manifested not Shipped delay",
                    field: "acct_mns_delay_days",
                },
                {
                    label: "NPOD delay",
                    field: "acct_npod_delay_days",
                },
                {
                    label: "No Audit",
                    field: "acct_no_audit",
                },
                {
                    label: "GSR Waiver",
                    field: "acct_is_gsr_waiver",
                },
                {
                    label: "Address Correction Waiver",
                    field: "acct_is_address_correction_waiver",
                },
                {
                    label: "",
                    field: "actions",
                },
            ],
            records: null,
            carriers: Carriers,
        };
    },
    methods: {
        async getCompanies() {
            const companies = await this.$httpAdmin.get(`company`, {
                headers: this.headers,
            });
            if (companies) {
                this.companies = companies.data.map((c) => {
                    return {
                        text: `${c.cmp_name} (ID: ${c.cmp_id} Type: ${c.cmp_type})`,
                        value: c.cmp_id,
                    };
                });
            }
        },
        async selectCompany() {
            let found;
            for (const comp of this.companies) {
                if (comp.selected) {
                    found = await this.$httpAdmin.get(`account?acct_cmp_id=${comp.value}`, {
                        headers: this.headers,
                    });
                    if (found) {
                        this.records = found.data;
                    } else {
                        this.$notify.error({ message: "No accounts found" });
                    }
                }
            }
        },
        async saveAccount(row) {
            try {
                const account = {
                    acct_mns_delay_days: row.acct_mns_delay_days,
                    acct_npod_delay_days: row.acct_npod_delay_days,
                    acct_no_audit: row.acct_no_audit,
                    acct_is_gsr_waiver: row.acct_is_gsr_waiver,
                    acct_is_address_correction_waiver: row.acct_is_address_correction_waiver,
                };
                const updated = await this.$httpAdmin.put(
                    `account/${row.acct_id}`,
                    { account },
                    { headers: this.headers }
                );
                if (updated) {
                    this.$notify.success({ message: "Account successfully updated" });
                } else {
                    this.$notify.error({ message: "Account was NOT updated" });
                }
            } catch (e) {
                this.$notify.error({ message: `Account was NOT updated: ${e}` });
            }
        },
    },
    mounted() {
        this.getCompanies();
    },
};
</script>
