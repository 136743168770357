<template>
    <div data-test="page" class="m-4">
        <h3 data-test="pageTitle" class="my-4 mx-2">Failed Credentials</h3>
        <vue-good-table
            data-test="vue-good-table"
            styleClass="vgt-table condensed"
            :rows="records"
            :columns="columns"
            mode="remote"
            :totalRows="totalRows"
            :isLoading.sync="loading"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            @on-search="searchCreds"
            :search-options="{
                enabled: true,
                trigger: 'enter',
            }"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: this.limit,
                position: 'top',
                perPageDropdown: [10, 25, 50],
                dropdownAllowAll: false,
                setCurrentPage: this.page,
            }"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'cmp_type'">
                    <span v-if="props.row.cmp_is_deleted === '1'">Inactive</span>
                    <span v-else-if="props.row.cmp_is_active === '0'">Inactive</span>
                    <span v-else>{{ props.row.cmp_type }}</span>
                </span>
                <span v-else-if="props.column.field === 'di_error'">
                    <span v-if="!props.row.di_error || props.row.di_error === 0">{{ props.row.dc_error }}</span>
                    <span v-else>{{ props.row.di_error }}</span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
        <mdb-modal data-test="confirmModal" :show="confirmModal" @close="confirmModal = false" large>
            <mdb-modal-header data-test="confirmModalHeader">Confirm {{ status }}</mdb-modal-header>
            <mdb-modal-body>
                <p data-test="confirmModalResolved" v-if="status === 'resolved'">
                    Are you sure you want to mark this credential as resolved?
                </p>
                <p data-test="confirmModalElse" v-else>
                    Are you sure you want to deactivate this credential? It will no longer be used for weekly downloads.
                </p>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn data-test="updateRowBtn" color="success" @click.native="updateRow">Continue</mdb-btn>
                <mdb-btn data-test="confirmModalCloseBtn" color="danger" @click.native="confirmModal = false"
                    >Close</mdb-btn
                >
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>
<script>
import moment from "moment";
import { mdbModal, mdbModalHeader, mdbModalBody, mdbModalFooter, mdbBtn } from "mdbvue";
export default {
    name: "AdminFailedCredentials",
    components: {
        mdbModal,
        mdbModalHeader,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            invoices: null,
            companies: null,
            records: null,
            columns: [
                {
                    label: "CMP ID",
                    field: "credential_cmp_id",
                },
                {
                    label: "Company",
                    field: "cmp_name",
                },
                {
                    label: "Company Status",
                    field: "cmp_type",
                },
                {
                    label: "Carrier",
                    field: "car_name",
                },
                {
                    label: "Type",
                    field: "credential_type",
                },
                {
                    label: "Username",
                    field: "credential_username",
                },
                {
                    label: "Password",
                    field: "credential_password",
                },
                {
                    label: "Error",
                    field: "credential_error_message",
                },
            ],
            loading: false,
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "cmp_name",
            sortDir: "asc",
            sortkey: "cmp_name",
            searchTerm: null,
            totalRows: null,
            selectedRowDetails: null,
            confirmModal: false,
            status: null,
            selectedRow: null,
        };
    },
    computed: {
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
    },
    methods: {
        confirm(row, status) {
            this.selectedRow = row;
            this.status = status;
            this.confirmModal = true;
        },
        async updateRow() {
            try {
                await this.$httpAdmin.put(
                    `admincompanycredential/${this.selectedRow.credential_id}/${this.status}`,
                    this.statusParams(),
                    {
                        header: this.headers,
                    }
                );
                this.$notify.success({ message: "Record updated." });
                this.searchCreds();
            } catch (e) {
                this.$notify.error({ message: `Not updated: ${e}` });
            }
            this.confirmModal = false;
        },
        statusParams() {
            if (this.status == "resolved") {
                return {
                    credential_is_not_correct: false,
                    credential_last_modified_by: this.userInfo.usrId,
                    credential_last_modified_time: moment().format(),
                };
            } else {
                return {
                    credential_is_active: false,
                    credential_last_modified_by: this.userInfo.usrId,
                    credential_last_modified_time: moment().format(),
                };
            }
        },
        formatDate(val) {
            return moment(val).format("MMM DD, YYYY");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.searchCreds();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.searchCreds();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.searchCreds();
        },
        async searchCreds(params) {
            this.loading = true;
            let search;
            if (params) {
                search = `&cmp_name=${params.searchTerm}`;
            }
            try {
                const creds = await this.$httpAdmin.get(
                    `admincompanycredential/failedcredentials?${this.params}${search ? search : ""}`,
                    {
                        headers: this.headers,
                    }
                );
                if (creds) {
                    this.records = creds.data[0].Items;
                    this.totalRows = creds.data[0].TotalRecords;
                }
            } catch (e) {
                this.$notify.error({ message: "No errors found." });
            }
            this.loading = false;
        },
    },
    mounted() {
        this.searchCreds();
    },
};
</script>
