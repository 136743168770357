<template>
    <!--tours-->
    <div class="row d-none">
        <div id="step-one" class="single-step ltr-text">
            <p>This page is where you can access pre-defined or custom created reports.</p>
            <br />
            <p>Let's walk through the features together.</p>
        </div>
        <div id="step-two" class="single-step ltr-text">
            <p>
                Select from a list of pre-defined reports, or select a custom report you created previously using the
                "Create Report" Tool.
            </p>
        </div>
        <div id="step-three" class="single-step ltr-text">
            <p>
                Use this drop-down to define the date range of your report. Select a pre-defined range or customize your
                own range.
            </p>
        </div>
        <div id="step-four" class="single-step ltr-text">
            <p>
                Time to select a format for your report. Click here to select from Screen (displays on the screen), PDF,
                Excel, CSV, or XML formats.
            </p>
        </div>
        <div id="step-five" class="single-step ltr-text">
            <p>
                Select one or more accounts to display by selecting/deselecting one or more account numbers or account
                grouping numbers (ie. CPP).
            </p>
            <br />
            <p>
                Have a lot of account numbers? No problem, use the nifty Search area to find the accounts you need to
                view.
            </p>
        </div>
        <div id="step-six" class="single-step ltr-text">
            <p>Now that you've selected your criteria, click "Run Report".</p>
        </div>
        <div id="step-seven" class="single-step ltr-text">
            <p>If you selected "Screen" from the Select Format dropdown, your result will display here.</p>
            <br />
            <p>
                An additional benefit of displaying on the screen is the option of applying "Filters" to the results,
                further refining your data.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "MyReportsTour",
};
</script>
