<template>
    <div data-test="page" class="m-4">
        <h3 data-test="pageTitle" class="my-4 mx-2">Download Status</h3>
        <mdb-btn data-test="showDetailsBtn" @click.native="showDetails" v-if="selectedRow">View Details</mdb-btn>
        <vue-good-table
            data-test="vue-good-table"
            styleClass="vgt-table condensed"
            :rows="invoices"
            :columns="columns"
            mode="remote"
            :totalRows="totalRows"
            :isLoading.sync="loading"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            @on-search="searchInvoices"
            :search-options="{
                enabled: true,
                trigger: 'enter',
            }"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: this.limit,
                position: 'top',
                perPageDropdown: [10, 25, 50],
                dropdownAllowAll: false,
                setCurrentPage: this.page,
            }"
            :select-options="{
                enabled: true,
            }"
            @on-selected-rows-change="selectionChanged"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'cmp_type'">
                    <span v-if="props.row.cmp_is_deleted === '1'">Inactive</span>
                    <span v-else-if="props.row.cmp_is_active === '0'">Inactive</span>
                    <span v-else>{{ props.row.cmp_type }}</span>
                </span>
                <span v-else-if="props.column.field === 'di_error'">
                    <span v-if="!props.row.di_error || props.row.di_error === 0">{{ props.row.dc_error }}</span>
                    <span v-else>{{ props.row.di_error }}</span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
        <mdb-modal
            data-test="detailsModal"
            :show="detailsModal"
            @close="detailsModal = false"
            large
            v-if="selectedRowDetails"
        >
            <mdb-modal-header data-test="detailsModalHeader">View Credentials</mdb-modal-header>
            <mdb-modal-body>
                <div class="row">
                    <div data-test="detailsModalUsername" class="col-md-12">
                        Username:
                        <strong>
                            <span id="credential_username">{{ selectedRowDetails.credential_username }}</span>
                        </strong>
                    </div>
                </div>
                <div class="row">
                    <div data-test="detailsModalPassword" class="col-md-12">
                        Password:
                        <strong>
                            <span id="credential_password" class="blurnohover">
                                {{ selectedRowDetails.credential_password }}
                            </span>
                        </strong>
                    </div>
                </div>
                <div class="row">
                    <div data-test="detailsModalCredentialType" class="col-md-12">
                        Credential Type:
                        <strong>
                            <span id="credential_type">{{ selectedRowDetails.credential_type }}</span>
                        </strong>
                    </div>
                </div>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn data-test="detailsModalCloseBtn" color="danger" @click.native="detailsModal = false"
                    >Close</mdb-btn
                >
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>
<script>
import moment from "moment";
import { mdbBtn, mdbModal, mdbModalHeader, mdbModalBody, mdbModalFooter } from "mdbvue";
export default {
    name: "AdminDownload",
    components: { mdbBtn, mdbModal, mdbModalHeader, mdbModalBody, mdbModalFooter },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            invoices: null,
            companies: null,
            columns: [
                {
                    label: "ID",
                    field: "cmp_id",
                },
                {
                    label: "Company Name",
                    field: "cmp_name",
                },
                {
                    label: "Company Status",
                    field: "cmp_type",
                },
                {
                    label: "Carrier",
                    field: "car_name",
                },
                {
                    label: "Account",
                    field: "di_account_number",
                },
                {
                    label: "Start Date",
                    field: "di_start_date",
                    formatFn: (r) => moment(r).format("MM/DD/YYYY"),
                },
                {
                    label: "End Date",
                    field: "di_end_date",
                    formatFn: (r) => moment(r).format("MM/DD/YYYY"),
                },
                {
                    label: "Status",
                    field: "di_status",
                    formatFn: (r) => {
                        if (r === 1) return "Pending";
                        else if (r === 0) return "None";
                        else if (r === 2) return "Ready";
                        else if (r === 50) return "No Invoice";
                        else if (r === 99) return "No Invoice";
                        else if (r === 100) return "Limit Exceeded";
                    },
                },
                {
                    label: "Invoice Name",
                    field: "di_filename",
                },
                {
                    label: "Error",
                    field: "di_error",
                },
                {
                    label: "Note",
                    field: "acct_note",
                },
            ],
            loading: false,
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "cmp_is_active",
            sortDir: "desc",
            sortkey: "cmp_is_active",
            searchTerm: null,
            totalRows: null,
            selectedRow: null,
            selectedRowDetails: null,
            detailsModal: false,
        };
    },
    computed: {
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
                startDate: moment(this.startDate).format("YYYY-MM-DD"),
                endDate: moment(this.endDate).format("YYYY-MM-DD"),
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
    },
    methods: {
        selectionChanged(params) {
            this.selectedRow = params.selectedRows[0];
        },
        async showDetails() {
            this.detailsModal = true;
            const details = await this.$httpAdmin.get(`admincompanycredential/${this.selectedRow.credential_id}`, {
                header: this.headers,
            });
            if (details) {
                this.selectedRowDetails = details.data;
            } else {
                this.$notify.error({ message: "No details found" });
            }
        },
        formatDate(val) {
            return moment(val).format("MMM DD, YYYY");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.searchInvoices();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.searchInvoices();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.searchInvoices();
        },
        async searchInvoices(params) {
            this.loading = true;
            let search;
            if (params) {
                search = `&cmp_name=${params.searchTerm}`;
            }
            try {
                const invoices = await this.$httpAdmin.get(`downloadinvoice?${this.params}${search ? search : ""}`, {
                    headers: this.headers,
                });
                if (invoices) {
                    this.records = invoices.data[0].Items;
                    this.totalRows = invoices.data[0].TotalRecords;
                }
            } catch (e) {
                this.$notify.error({ message: "No errors found." });
            }
            this.loading = false;
        },
    },
    mounted() {
        this.searchInvoices();
    },
};
</script>
