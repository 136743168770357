<template>
    <div data-test="page" class="overflow-auto h-90">
        <div>
            <h1 data-test="pageHeader" class="page-header border-bottom mx-2">
                Update GLCodes
                <mdb-icon
                    data-test="tourIcon"
                    icon="upload"
                    size="1x"
                    class="float-right text-muted"
                    role="button"
                    title="Tour this page"
                />
            </h1>
            <mdb-row data-test="cards" class="mt-4" v-if="startDate && accountsOptions">
                <mdb-col col="12">
                    <mdb-card-group data-test="cardGroup">
                        <mdb-card data-test="generateFileCard" class="mx-2 mb-4">
                            <mdb-card-header data-test="generateFileCardHeader"
                                ><b>Step 1:</b> Generate File</mdb-card-header
                            >
                            <mdb-card-body data-test="generateFileCardBody">
                                <mdb-card-text data-test="generateFileCardText" class="mb-2"
                                    >Select date range and accounts to produce Missing GL Codes File.</mdb-card-text
                                >
                                <mdb-row>
                                    <mdb-col lg="6" sm="12">
                                        <DateSelector
                                            data-test="generateFileCard_dateSelector"
                                            size="sm"
                                            id="dateDropdown"
                                            :startDate="startDate"
                                            :endDate="endDate"
                                            @update-end-date="updateEndDate"
                                            @update-start-date="updateStartDate"
                                            class="border rounded mt-4"
                                        />
                                    </mdb-col>
                                    <mdb-col lg="6" sm="12">
                                        <mdb-btn-group data-test="generateFileCard_btnGroup" class="mt-4 w-100">
                                            <mdb-btn
                                                data-test="generateFileCard_changeUnlabeledBtn"
                                                :color="unlabeledSelected ? 'primary' : 'white'"
                                                @click.native="changeUnlabeled"
                                                size="sm"
                                                >Unlabeled</mdb-btn
                                            >
                                            <mdb-btn
                                                data-test="generateFileCard_changeAllBtn"
                                                :color="allSelected ? 'primary' : 'white'"
                                                @click.native="changeAll"
                                                size="sm"
                                                >All</mdb-btn
                                            >
                                            <mdb-btn
                                                data-test="generateFileCard_changeMiscBtn"
                                                :color="miscSelected ? 'primary' : 'white'"
                                                @click.native="changeMisc"
                                                size="sm"
                                                >Misc</mdb-btn
                                            >
                                        </mdb-btn-group>
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="justify-content-end">
                                    <mdb-col lg="6" sm="12">
                                        <mdb-select
                                            data-test="generateFileCardSelect_getAccountsValue"
                                            outline
                                            search
                                            multiple
                                            @getValue="getAccountsValue"
                                            :options="accountsOptions"
                                            label="Select Accounts"
                                        />
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row data-test="generateFileCard_generatedFiles" v-if="generatedFiles">
                                    <mdb-col col="12" v-if="!generatedFileSuccess">
                                        <p data-test="generateFileCard_notGeneratedFileSuccess" class="mt-2">
                                            <mdb-icon icon="check-square"></mdb-icon>{{ generatedErrorMessage }}
                                        </p>
                                    </mdb-col>
                                    <mdb-col col="12" v-else>
                                        <p data-test="generateFileCard_generatedFileSuccess" class="mt-2">
                                            <mdb-icon icon="check-square" class="mr-2" color="success"></mdb-icon
                                            >{{ generatedErrorMessage }}
                                        </p>
                                        <mdb-btn
                                            data-test="generateFileCard_downloadBtn"
                                            color="success"
                                            class="mt-2"
                                            @click.native="download"
                                        >
                                            <mdb-icon icon="file-alt" size="lg" class="mr-2"></mdb-icon>
                                            Download Missing GL Codes File<mdb-icon
                                                icon="download"
                                                size="lg"
                                                class="ml-2"
                                            ></mdb-icon
                                        ></mdb-btn>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                            <mdb-card-footer data-test="generateFileCardFooter">
                                <mdb-btn
                                    data-test="generateFileCard_generateFileBtn"
                                    v-if="!generating"
                                    color="primary"
                                    class="float-right"
                                    size="sm"
                                    @click.native="generateFile()"
                                    >Generate File <mdb-icon icon="cogs" class="ml-1"></mdb-icon>
                                </mdb-btn>
                                <mdb-btn v-if="generating" color="primary" class="float-right" size="sm" disabled
                                    >Generating... <mdb-icon icon="cogs" class="ml-1"></mdb-icon>
                                </mdb-btn>
                                <mdb-spinner v-if="generating" small class="float-right"></mdb-spinner>
                            </mdb-card-footer>
                        </mdb-card>
                        <mdb-card data-test="uploadFileCard" class="ml-2 mb-4" border="info">
                            <mdb-card-header data-test="uploadFileCardHeader" color="info-color"
                                ><b>Step 2:</b> Upload File</mdb-card-header
                            >
                            <mdb-card-body data-test="uploadFileCardBody">
                                <mdb-card-text data-test="uploadFileCardText" class="mb-4"
                                    >Upon completed Missing GL Codes File.</mdb-card-text
                                >
                                <input
                                    data-test="uploadFileCardInput_handleFileUpload"
                                    type="file"
                                    id="file"
                                    ref="file"
                                    @change="handleFileUpload"
                                />
                            </mdb-card-body>
                            <mdb-card-footer data-test="uploadFileCardFooter">
                                <mdb-btn
                                    data-test="uploadFileCard_uploadFileBtn"
                                    color="primary"
                                    class="float-right"
                                    size="sm"
                                    @click.native="uploadFile()"
                                    >Upload File <mdb-icon icon="upload" class="ml-1"></mdb-icon
                                ></mdb-btn>
                                <mdb-btn
                                    data-test="uploadFileCard_uploadMiscGLCodeFileBtn"
                                    color="primary"
                                    class="float-right"
                                    size="sm"
                                    @click.native="uploadMiscGLCodeFile()"
                                    >Upload MiscGLCode File <mdb-icon icon="upload" class="ml-1"></mdb-icon
                                ></mdb-btn>
                            </mdb-card-footer>
                        </mdb-card>
                    </mdb-card-group>
                </mdb-col>
            </mdb-row>
            <mdb-row v-if="table">
                <mdb-col col="12">
                    <mdb-card data-test="tableCard" class="ml-2 mb-4" border="success">
                        <mdb-card-header data-test="tableCardHeader" color="success-color"
                            ><b>Step 3:</b> Check Status</mdb-card-header
                        >
                        <mdb-card-body data-test="tableCardBody">
                            <mdb-tbl data-test="tableCard_tbl" striped hover>
                                <mdb-tbl-head data-test="tableCard_tblHead">
                                    <tr>
                                        <th></th>
                                        <th data-test="tableCard_tblHead_fileName"><b>File Name</b></th>
                                        <th data-test="tableCard_tblHead_time"><b>Time</b></th>
                                        <th data-test="tableCard_tblHead_status"><b>Status</b></th>
                                    </tr>
                                </mdb-tbl-head>
                                <mdb-tbl-body data-test="tableCard_tblBody">
                                    <tr v-for="(row, index) of table" v-bind:index="index">
                                        <td data-test="tableCard_tblStarted" v-if="row.ugs_status == 'started'">
                                            <mdb-icon icon="play" size="lg"></mdb-icon>
                                        </td>
                                        <td data-test="tableCard_tblRunning" v-else-if="row.ugs_status == 'running'">
                                            <mdb-icon icon="refresh" size="lg"></mdb-icon>
                                        </td>
                                        <td data-test="tableCard_tblElse" v-else>
                                            <mdb-icon icon="check" size="lg"></mdb-icon>
                                        </td>
                                        <td data-test="tableCard_tblFileName">
                                            <b>{{ row.ugs_file_name }}</b>
                                        </td>
                                        <td data-test="tableCard_tblRequestTime">{{ row.ugs_request_time }}</td>
                                        <td data-test="tableCard_tblStatus" class="text-uppercase">
                                            {{ row.ugs_status }}
                                        </td>
                                    </tr>
                                </mdb-tbl-body>
                            </mdb-tbl>
                        </mdb-card-body>
                        <mdb-card-footer data-test="tableCardFooter">
                            <mdb-btn
                                data-test="tableCard_updateGLCodeStatusBtn"
                                color="primary"
                                class="float-right"
                                size="sm"
                                @click.native="updateGLCodeStatus()"
                                >Refresh Status <mdb-icon icon="sync" class="ml-1"></mdb-icon
                            ></mdb-btn>
                        </mdb-card-footer>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
        </div>
    </div>
</template>
<script>
import DateSelector from "@/components/DateSelector";
import moment from "moment";
import {
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbCardText,
    mdbBtn,
    mdbBtnGroup,
    mdbSelect,
    mdbCardFooter,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCardGroup,
    mdbSpinner,
} from "mdbvue";
export default {
    name: "UpdateGL",
    components: {
        DateSelector,
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbCardText,
        mdbBtn,
        mdbBtnGroup,
        mdbSelect,
        mdbCardFooter,
        mdbTbl,
        mdbTblHead,
        mdbTblBody,
        mdbCardGroup,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
        downloadUniversal: Function,
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            unlabeledSelected: true,
            allSelected: false,
            miscSelected: false,
            accountsOptions: null,
            accountsResponse: null,
            accountIds: [],
            selectedAccounts: [],
            accountsChildren: [],
            sTime: null,
            eTime: null,
            chooseFile: null,
            generatedFiles: null,
            table: null,
            accountsToGenerate: null,
            generatedErrorMessage: null,
            generatedFileSuccess: false,
            generating: false,
            downloadLink: null,
            file: null,
        };
    },
    methods: {
        async getInit() {
            await this.getDates();
            await this.getUser();
            await this.updateGLCodeStatus();
            this.spinner = false;
        },
        async getDates() {
            let time = moment().subtract(1, "days");
            this.sTime = moment().subtract(14, "days").format("YYYY-MM-DD");
            this.eTime = moment().subtract(1, "days").format("YYYY-MM-DD");
            this.endDate = time;
            this.startDate = moment().subtract(14, "days");
        },
        async getUser() {
            this.accountsOptions = [];
            await this.$http.post("/user", this.userParams(), { headers: this.headers }).then(async (response) => {
                this.accountsResponse = JSON.parse(response.data.keyvalues[0].value);
                for (let i = 0; i < this.accountsResponse.length; i++) {
                    this.accountsOptions.push({
                        value: i,
                        text: this.accountsResponse[i].label,
                    });
                }
            });
            return true;
        },
        userParams() {
            let keyValues = [];
            keyValues.push({ key: "reportId", value: 565 });
            return {
                action: "getAccountTreeForReport",
                userId: this.userInfo.usrId,
                cmpId: this.userInfo.cmpID,
                keyvalues: keyValues,
            };
        },
        async updateGLCodeStatus() {
            await this.$http
                .get(
                    "/updateglcodestatus?ugs_cmp_id=" +
                        this.userInfo.cmpID +
                        "&sortColumn=ugs_request_time&sortDir=desc",
                    {
                        headers: this.headers,
                    }
                )
                .then(async (response) => {
                    this.table = response.data;
                    for (let i = 0; i < this.table.length; i++) {
                        this.table[i].ugs_request_time = moment(this.table[i].ugs_request_time).format(
                            "MM/DD h:mm.ssa"
                        );
                    }
                });
        },
        getAccountsValue(value) {
            this.accountIds = [];
            this.selectedAccounts = [];
            this.accountsChildren = [];
            for (let i = 0; i < value.length; i++) {
                let account = this.accountsResponse[value[i]];
                this.getIds(account);
                for (let j = 0; j < this.accountIds.length; j++) {
                    this.accountsChildren.push(this.accountIds[j]);
                }
                this.accountIds = [];
            }
        },
        getIds(account) {
            if (account.id != null) {
                this.accountIds.push(account.id);
            } else {
                for (let i = 0; i < account.children.length; i++) {
                    this.getIds(account.children[i]);
                }
            }
        },
        updateStartDate(val) {
            this.startDate = moment(val).format("YYYY-MM-DD");
        },
        updateEndDate(val) {
            this.endDate = moment(val).format("YYYY-MM-DD");
        },
        changeUnlabeled() {
            this.unlabeledSelected = true;
            this.allSelected = false;
            this.miscSelected = false;
        },
        changeAll() {
            this.unlabeledSelected = false;
            this.allSelected = true;
            this.miscSelected = false;
        },
        changeMisc() {
            this.unlabeledSelected = false;
            this.allSelected = false;
            this.miscSelected = true;
        },
        async generateFile() {
            this.generating = true;
            let selectedAccounts = this.accountsChildren;
            let accounts = [];
            let allPackagesChecked = null;
            if (selectedAccounts != undefined && selectedAccounts != null)
                for (let i = 0; i < selectedAccounts.length; i++) accounts.push(parseInt(selectedAccounts[i]));
            if (this.allSelected === true) {
                allPackagesChecked = 1261;
            } else if (this.unlabeledSelected === true) {
                allPackagesChecked = 565;
            } else {
                allPackagesChecked = 1614;
            }
            await this.$httpReportsImpersonate
                .post("/reportfile", this.generateFilesParams(allPackagesChecked, accounts), { headers: this.headers })
                .then(async (response) => {
                    this.generatedFiles = response.data;
                    if (this.generatedFiles.indexOf("Error") > -1) {
                        if (this.generatedFiles.indexOf("Slow Report") > -1) {
                            this.generatedErrorMessage =
                                "Could not create your report! Please decrease the date range.";
                        } else {
                            this.generatedErrorMessage = "Report Completed. No Results Found";
                        }
                        this.generatedFileSuccess = false;
                    } else {
                        this.generatedErrorMessage = "File is now ready, please click below to download.";
                        this.generatedFileSuccess = true;
                    }
                    this.generating = false;
                });
        },
        generateFilesParams(allPackagesChecked, accounts) {
            return {
                id: allPackagesChecked,
                output: "csv",
                cmpId: this.userInfo.cmpID,
                start: this.startDate,
                end: this.endDate,
                accounts: this.accountsChildren,
            };
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        async uploadFile() {
            let formData = new FormData();
            if (this.file == null || this.file.name == "") {
                this.$notify.error({
                    message: "There is no file to upload.",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            formData.append(this.file.name, this.file);
            let parts = this.file.name.split(".");
            let extension = parts[parts.length - 1];
            if (extension.toLowerCase() !== "csv") {
                alert("The file is not in correct format. The result file must be in CSV (Comma Separated) format.");
                return;
            }
            await this.$http
                .put("/updateglcode/" + this.userInfo.cmpID, formData, {
                    headers: { ...this.headers, "Content-Type": "multipart/form-data" },
                })
                .then(async (response) => {
                    let uploadFileResponse = response.data;
                    if (uploadFileResponse == "OK") {
                        this.$notify.success({
                            message:
                                "The result has been successfully uploaded. Please allow 24 Hours for new GL Codes to populate.",
                            title: "Success",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: "There is an error uploading the results.",
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                    this.file = null;
                });
            this.updateGLCodeStatus();
        },
        async uploadMiscGLCodeFile() {
            let formData = new FormData();
            if (this.file == null || this.file.name == "") {
                this.$notify.error({
                    message: "There is no file to upload.",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            formData.append(this.file.name, this.file);
            let parts = this.file.name.split(".");
            let extension = parts[parts.length - 1];
            if (extension.toLowerCase() !== "csv") {
                this.$notify.error({
                    message:
                        "The file is not in correct format. The result file must be in CSV(Comma Separated) format.",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            await this.$http
                .put("/updatemiscglcode/" + this.userInfo.cmpID, formData, {
                    headers: { ...this.headers, "Content-Type": "multipart/form-data" },
                })
                .then(async (response) => {
                    let reportFileResponse = response.data;
                    if (reportFileResponse == "OK") {
                        this.$notify.success({
                            message:
                                "The result has been successfully uploaded. Please allow 24 Hours for new GL Codes to populate.",
                            title: "Success",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: "There is an error uploading the results.",
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                    this.file = null;
                });
            this.updateGLCodeStatus();
        },
        download() {
            this.downloadUniversal(this.generatedFiles, "pdf");
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
<style></style>
