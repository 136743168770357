<template>
    <div>
        <div v-for="(account, index) of userAccounts" v-bind:key="index">
            <div class="cursor-pointer d-flex">
                <mdb-icon icon="caret-down" @click.native="setVisibility(account)" v-if="account.isParent" />
                <div style="width: auto">
                    <mdb-input
                        type="checkbox"
                        v-model="account.selected"
                        :id="account.label.replace(' ', '')"
                        :name="account.label.replace(' ', '')"
                        @input="setSelected"
                    >
                        {{ account.label }}
                    </mdb-input>
                    <div id="collapse-accounts" v-if="visible1">
                        <div v-for="(child, index) in account.children" v-bind:key="index">
                            <mdb-icon icon="caret-down" @click.native="setVisibility(child)" v-if="child.isParent" />
                            <mdb-input
                                type="checkbox"
                                :id="account.label.replace(' ', '') + child.id"
                                :name="account.label.replace(' ', '') + child.id"
                                v-model="child.selected"
                                @input="setSelected"
                                :label="child.label"
                            />
                            <div v-if="visible2">
                                <MultiSelectItem
                                    :selectData="child.children"
                                    v-on:sendSelectedOptions="updateActiveAccounts"
                                ></MultiSelectItem>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MultiSelectItem",
    props: {
        // model: Object,
        selectData: Array,
        visibleIdx: Number,
    },
    data: function () {
        return {
            open: false,
            visible1: false,
            visible2: false,
            visible3: false,
            visibleOpts: {},
            selectedOptions: String,
            selectedAccountActive: false,
            userAccounts: null,
        };
    },
    mounted() {
        this.getUserAccounts();
    },
    computed: {},
    methods: {
        setVisibility(parent) {
            if (parent.isParent) {
                // Can't get v-if="" to recognize changes in objects
                // this.visibleOpts[parent.level] = !this.visibleOpts[parent.level];
                if (parent.level == 1) {
                    this.visible1 = !this.visible1;
                }
                if (parent.level == 2) {
                    this.visible2 = !this.visible2;
                }
                if (parent.level == 3) {
                    this.visible3 = !this.visible3;
                }
            }
        },
        setSelected() {
            this.$emit("sendSelectedOptions", this.userAccounts);
        },
        updateActiveAccounts() {
            this.setSelected();
        },
        async getUserAccounts() {
            this.userAccounts = this.selectData;
            for (const account of this.userAccounts) {
                this.visibleOpts[account.label] = false;
                if (account.children) {
                    if (account.children != null && account.children.length > 0) {
                        account["isParent"] = true;
                        account["level"] = 1;
                        this.visibleOpts[account["level"]] = false;
                    }
                    for (const child of account.children) {
                        this.visibleOpts[child.label] = false;
                        if (child.children != null && child.children.length > 0) {
                            child["isParent"] = true;
                            child["level"] = 2;
                            this.visibleOpts[child["level"]] = false;
                        }
                        if (child.children) {
                            for (const grandchild of child.children) {
                                this.visibleOpts[grandchild.label] = false;
                                if (grandchild.children != null && grandchild.children.length > 0) {
                                    grandchild["isParent"] = true;
                                    grandchild["level"] = 3;
                                    this.visibleOpts[grandchild["level"]] = false;
                                }
                            }
                        }
                    }
                }
            }
        },
    },
};
</script>

<style scoped></style>
