<template>
    <mdb-container data-test="container" class="navbarComponent">
        <mdb-navbar
            data-test="navbar"
            color="elegant-color"
            tag="div"
            position="top"
            dark
            :toggler="true"
            :style="{ zIndex: '1061' }"
        >
            <mdb-navbar-toggler data-test="navbarToggler">
                <mdb-navbar-nav left style="margin-right: 20px !important">
                    <mdb-icon icon="bars" class="white-text cursor-pointer" size="lg" @click.native="showSideNav" />
                </mdb-navbar-nav>
                <mdb-navbar-nav data-test="navbarNav" left>
                    <img
                        data-test="nonAdminDropdownImg"
                        :src="userInfo.usrResellerLogo"
                        height="33"
                        class="mr-4 mb-2"
                        :title="userInfo.usrResellerName"
                    />
                    <h5 v-if="!$route.path.match('admin')" class="text-white mb-0 mt-2">| {{ userInfo.usrCmpName }}</h5>
                    <mdb-modal id="spinner" :show="searching" @close="searching = false">
                        <mdb-modal-header>
                            <mdb-modal-title class="w-full"> Searching </mdb-modal-title>
                        </mdb-modal-header>
                        <mdb-row class="justify-content-center m-4">
                            <mdb-spinner></mdb-spinner>
                        </mdb-row>
                    </mdb-modal>
                </mdb-navbar-nav>
            </mdb-navbar-toggler>
            <mdb-navbar-toggler data-test="navbarToggler2">
                <mdb-navbar-nav data-test="navbarNav2" right>
                    <mdb-input
                        class="white-text"
                        v-if="!$route.path.match('admin')"
                        icon="search"
                        data-test="navbar2FormInput"
                        type="text"
                        id="genie"
                        placeholder="Tracking or Invoice Number"
                        v-model="searchText"
                        @keydown.native.enter="searchTrck"
                    />
                    <mdb-dropdown data-test="navbar2Dropdown" tag="li" class="nav-item" multiLevel>
                        <mdb-dropdown-toggle
                            data-test="navbar2DropdownToggle"
                            slot="toggle"
                            color="elegant"
                            class="text-uppercase"
                            tag="a"
                            navLink
                            transparent
                            waves-fixed
                        >
                            <div data-test="navbar2Dropdown_usrFirstName" class="d-none d-md-inline-block">
                                <mdb-icon icon="user" />
                                <span class="px-2">{{ userInfo.usrFirstName }}</span>
                            </div>
                            <div data-test="navbar2DropdownAccountHeader" class="d-inline-block d-md-none">
                                Account
                            </div></mdb-dropdown-toggle
                        >
                        <mdb-dropdown-menu data-test="navbar2DropdownMenu" class="overflow-hidden">
                            <mdb-nav-item
                                data-test="navbar2DropdownNavItemSettings"
                                to="/settings"
                                v-if="userInfo.cmpID != null"
                            >
                                <mdb-icon icon="cog" class="black-text" />
                                <span class="px-2 black-text">Settings</span>
                            </mdb-nav-item>
                            <mdb-nav-item
                                data-test="navbar2DropdownNavItemBilling"
                                to="/billing"
                                v-if="userInfo.cmpHideBilling !== true && userInfo.cmpID != null"
                            >
                                <mdb-icon icon="file-alt" class="black-text" />
                                <span class="px-3 black-text">Billing</span>
                            </mdb-nav-item>

                            <mdb-nav-item data-test="navbar2DropdownNavItemLogout" @click.native="logout">
                                <mdb-icon icon="power-off" class="black-text" />
                                <span class="px-2 black-text">Logout</span>
                            </mdb-nav-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown>
                </mdb-navbar-nav>
            </mdb-navbar-toggler>
        </mdb-navbar></mdb-container
    >
</template>

<script>
import auth from "../lib/auth";
import {
    mdbContainer,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbDropdown,
    mdbDropdownToggle,
    mdbIcon,
    mdbDropdownMenu,
    mdbRow,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    mdbNavItem,
    mdbSpinner,
} from "mdbvue";

export default {
    name: "NavBar",
    components: {
        mdbContainer,
        mdbNavbar,
        mdbNavbarToggler,
        mdbNavbarNav,
        mdbDropdown,
        mdbDropdownToggle,
        mdbIcon,
        mdbDropdownMenu,
        mdbRow,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbInput,
        mdbNavItem,
        mdbSpinner,
    },
    props: {
        userInfo: Object,
        headers: Object,
        services: Array,
    },
    data() {
        return {
            n: 1,
            companies: null,
            searchText: null,
            searchTextComp: null,
            panel: null,
            selected: {
                id: null,
            },
            show: false,
            searching: false,
        };
    },
    methods: {
        showSideNav() {
            this.show = !this.show;
            this.$emit("change-side-nav", this.show);
        },
        async searchTrck() {
            const trknum = this.searchText;
            if (trknum) {
                this.searching = true;
                const ups =
                    /\b(1z ?[0-9A-Za-z]{3} ?[0-9A-Za-z]{3} ?[0-9A-Za-z]{2} ?[0-9A-Za-z]{4} ?[0-9A-Za-z]{3} ?[0-9A-Za-z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/i;
                const fdx1 = /(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/;
                const fdx2 = /\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/;
                const fdx3 = /^[0-9]{15}$/;
                const usps = /^\d{20}\s*$/;

                if (
                    ups.test(trknum) ||
                    fdx1.test(trknum) ||
                    fdx2.test(trknum) ||
                    fdx3.test(trknum) ||
                    usps.test(trknum)
                )
                    await this.showPackage(trknum);
                else await this.showInvoice(trknum);
                this.searching = false;
            }
        },
        async showPackage() {
            this.selected = { pld_trackingno: this.searchText, invoice_number: "0" };
            this.$emit("open-package-genie", this.selected);
        },
        async showInvoice() {
            this.selected = { inv_number: this.searchText };
            this.$emit("open-invoice-genie", this.selected);
        },
        logout() {
            auth.logout();
            this.$emit("isAuthenticated", false);
            this.$emit("userInfo", {});
            this.$emit("close-side-nav");
            this.$router.push({ name: "Login" });
        },
    },
};
</script>
<style>
.adminMenu ul li {
    list-style: none !important;
}
.navbarComponent .dropdown-menu {
    margin-top: -30px !important;
    padding-top: 4px !important;
}
.navbar-brand {
    padding-right: 0 !important;
}
.cursor-pointer {
    cursor: pointer;
}
.txthover:hover {
    text-decoration: underline;
}
#genie {
    color: white !important;
}
</style>
