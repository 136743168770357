<template>
    <div class="w-100 overflow-hidden m-4">
        <h1 class="page-header">Application Controller</h1>
        <div v-if="correctPassword" class="w-100">
            <vue-good-table :rows="appsRunning" :columns="columns">
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'name'">
                        <mdb-icon v-if="props.row.icon !== 'sync'" :icon="props.row.icon" class="mr-2"></mdb-icon>
                        {{ props.row.name }}
                        <mdb-progress
                            color="success"
                            indeterminate
                            v-if="props.row.icon === 'sync'"
                            class="mt-2 mb-0 align-text-bottom"
                        />
                    </span>
                    <span v-else-if="props.column.field === 'start_time'"> {{ filterDate(props.row.start_time) }}</span
                    ><span v-else-if="props.column.field === 'end_time'" class="text-capitalize">
                        {{
                            props.row.status === "running" || props.row.status === "started"
                                ? `${props.row.status}...`
                                : filterDate(props.row.end_time)
                        }}</span
                    >
                    <span v-else-if="props.column.field === 'actions'" class="row ml-2">
                        <mdb-btn flat v-if="props.row.disabled" class="p-0 mx-2" :id="props.row.originalIndex">
                            <mdb-icon
                                icon="toggle-off"
                                class="text-danger"
                                size="lg"
                                @click.native="confirmEnableDisable(props.row, 'enable')"
                            ></mdb-icon>
                        </mdb-btn>
                        <mdb-btn flat v-else class="p-0 mx-2" :id="props.row.originalIndex">
                            <mdb-icon
                                icon="toggle-on"
                                class="text-success"
                                size="lg"
                                @click.native="confirmEnableDisable(props.row, 'disable')"
                            ></mdb-icon>
                        </mdb-btn>
                        <mdb-btn v-if="props.row.componentRunning && props.row.canPlay" disabled flat class="p-0 mx-2">
                            <mdb-icon icon="play" size="lg"></mdb-icon>
                        </mdb-btn>
                        <mdb-btn v-if="!props.row.componentRunning && props.row.canPlay" flat class="p-0 mx-2">
                            <mdb-icon
                                icon="play"
                                size="lg"
                                v-if="props.row.canPlay"
                                @click.native="confirmAction(props.row, 'run')"
                            ></mdb-icon>
                        </mdb-btn>
                        <mdb-btn flat v-if="props.row.canStop" class="p-0 mx-2">
                            <mdb-icon icon="stop" size="lg" @click.native="confirmAction(props.row, 'stop')"></mdb-icon>
                        </mdb-btn>
                        <mdb-btn flat v-if="props.row.canEdit" class="p-0 mx-2">
                            <mdb-icon icon="wrench" size="lg" @click.native="openAddModal(props.row)"></mdb-icon>
                        </mdb-btn>
                    </span>
                    <span v-else>
                        {{ props.row[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
            <mdb-modal :show="addTime" @close="addTime = false" v-if="addingTime">
                <mdb-modal-header>
                    <mdb-modal-title>Scheduled Runs</mdb-modal-title>
                </mdb-modal-header>
                <mdb-modal-body>
                    <p>
                        <mdb-btn color="info" @click.native="addTimer()"
                            ><mdb-icon size="sm" icon="plus"></mdb-icon> Add</mdb-btn
                        >
                    </p>
                    <div class="d-flex w-100" v-for="time in timers">
                        <mdb-select v-model="time.daysOfWeek" placeholder="Select Day"></mdb-select>
                        <mdb-select class="mx-4" v-model="time.selectTimes" placeholder="Select Time"></mdb-select>
                        <span class="mt-4"
                            ><mdb-icon
                                class="align-bottom"
                                size="md"
                                color="danger"
                                icon="trash"
                                @click.native="confirmRemove(time)"
                            ></mdb-icon
                        ></span>
                    </div>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="danger" @click.native="addTime = false">Close</mdb-btn>
                    <mdb-btn color="success" @click.native="runAdd()">Save</mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal :show="showConfirm" @close="showConfirm = false" v-if="rowActions">
                <mdb-modal-header>
                    <mdb-modal-title>Confirm Run</mdb-modal-title>
                </mdb-modal-header>
                <mdb-modal-body
                    >Are you sure you would like to {{ rowActions.typeAction }} {{ rowActions.name }}?</mdb-modal-body
                >
                <mdb-modal-footer>
                    <mdb-btn color="danger" @click.native="showConfirm = false">Close</mdb-btn>
                    <mdb-btn color="success" @click.native="runProcess(rowActions)">Continue</mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal :show="showTimeRemove" @close="showTimeRemove = false" v-if="removingTime">
                <mdb-modal-header>
                    <mdb-modal-title>Confirm Removal</mdb-modal-title>
                </mdb-modal-header>
                <mdb-modal-body>Are you sure you would like to delete this scheduled run? </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="danger" @click.native="showTimeRemove = false">Close</mdb-btn>
                    <mdb-btn color="success" @click.native="removeTimer(removingTime.index)">Continue</mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal :show="showEnableDisable" @close="showEnableDisable = false" v-if="showEnableDisable">
                <mdb-modal-header>
                    <mdb-modal-title>Confirm Enable/Disable</mdb-modal-title>
                </mdb-modal-header>
                <mdb-modal-body
                    >Are you sure you would like to {{ rowActions.typeAction }} {{ rowActions.name }}?
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="danger" @click.native="showEnableDisable = false">Close</mdb-btn>
                    <mdb-btn color="success" @click.native="toggleEnableDisable(rowActions)">Continue</mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
        <div v-else>
            <mdb-row class="justify-content-center my-4">
                <mdb-card style="width: 40em">
                    <mdb-card-header>Enter Secret Key</mdb-card-header>
                    <mdb-card-body>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <mdb-icon icon="key"></mdb-icon>
                                </span>
                            </div>
                            <input
                                type="password"
                                @keydown.enter="checkPassword()"
                                v-model="passwordGiven"
                                class="form-control"
                                placeholder="Secret Key"
                                aria-label="Secret Key"
                            />
                        </div>
                    </mdb-card-body>
                    <mdb-card-footer class="text-right">
                        <mdb-btn color="primary" @click.native="checkPassword()"
                            >Continue<mdb-icon icon="arrow-alt-circle-right" class="ml-2"></mdb-icon
                        ></mdb-btn>
                    </mdb-card-footer>
                </mdb-card>
            </mdb-row>
        </div>
    </div>
</template>
<script>
import moment from "moment";
const SLACK_URL = "https://hooks.slack.com/services/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";
const USER_PASS = "khorshid";

export default {
    name: "AdminAppController",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            appsRunning: null,
            componentRunning: false,
            passwordGiven: "",
            correctPassword: false,
            addTime: false,
            timers: [],
            addingTime: null,
            showConfirm: false,
            enableDisable: null,
            showEnableDisable: false,
            removingTime: null,
            showTimeRemove: false,
            rowActions: null,
            columns: [
                {
                    text: "Component",
                    field: "name",
                },
                {
                    text: "Last Start Time",
                    field: "start_time",
                },
                {
                    text: "Last End Time",
                    field: "end_time",
                },
                {
                    text: "Options",
                    field: "actions",
                },
            ],
        };
    },
    methods: {
        checkPassword() {
            if (this.passwordGiven === USER_PASS) {
                this.correctPassword = true;
                localStorage.setItem("appcontrollerpass", USER_PASS);
            } else {
                localStorage.removeItem("appcontrollerpass");
                this.$notify.error({ message: "Incorrect secret key given." });
                this.passwordGiven = "";
            }
        },
        async postToSlack(msg, channel = "#eauditbot", icon = ":eaudit:") {
            const url = SLACK_URL;
            const payload = {
                channel: channel,
                username: "eAudit",
                text: msg,
                icon_emoji: icon,
            };
            await this.$httpAdmin(url, { data: payload });
        },
        confirmAction(row, action) {
            this.rowActions = { ...row, typeAction: action };
            this.showConfirm = true;
        },
        confirmEnableDisable(row, action) {
            this.rowActions = { ...row, typeAction: action };
            this.showEnableDisable = true;
        },
        confirmRemove(time) {
            this.removingTime = time;
            this.showTimeRemove = true;
        },
        removeTimer(index) {
            this.showTimeRemove = false;
            this.timers = this.timers.filter((t) => t.index !== index);
        },
        addTimer() {
            const val = this.timers.length;
            const times = [];
            for (let i = 0; i < 24; i++) {
                times.push({ text: `${i}:00`, value: i });
            }
            this.daysOfWeek = [
                {
                    text: "Sunday",
                    value: 0,
                },
                {
                    text: "Monday",
                    value: 1,
                },
                {
                    text: "Tuesday",
                    value: 2,
                },
                {
                    text: "Wednesday",
                    value: 3,
                },
                {
                    text: "Thursday",
                    value: 4,
                },
                {
                    text: "Friday",
                    value: 5,
                },
                {
                    text: "Saturday",
                    value: 6,
                },
            ];
            this.times = times;
            this.timers.push(
                new Object({
                    daysOfWeek: structuredClone(this.daysOfWeek),
                    selectTimes: structuredClone(this.times),
                    start_hour: -1,
                    start_day: -1,
                    index: val,
                })
            );
        },
        async openAddModal(row) {
            this.addingTime = row;
            this.timers =
                (
                    await this.$httpAdmin.get(`appcontrollerconfig/${row.component}`, {
                        headers: this.headers,
                    })
                ).data || [];
            let index = 0;
            this.timers.map((m) => {
                m.daysOfWeek = structuredClone(this.daysOfWeek);
                m.selectTimes = structuredClone(this.times);

                m.daysOfWeek.map((c) => {
                    if (c.value === m.start_day) {
                        c.selected = true;
                    }
                });
                m.selectTimes.map((c) => {
                    if (c.value === m.start_hour) {
                        c.selected = true;
                    }
                });
                m.index = index;
                index++;
                return m;
            });
            this.addTime = true;
        },
        runAdd() {
            const url = `appcontrollerconfig/${this.addingTime.component}`;
            let valid = true;
            const data = this.timers.map((m) => {
                let start_hour;
                let start_day;
                m.selectTimes.map((s) => {
                    if (s.selected) {
                        start_hour = s.value;
                    }
                });
                m.daysOfWeek.map((s) => {
                    if (s.selected) {
                        start_day = s.value;
                    }
                });
                if (start_day >= 0 && start_hour >= 0) {
                    return {
                        start_hour,
                        start_day,
                    };
                } else {
                    valid = false;
                }
            });
            if (!valid) {
                this.$notify.error({ message: "Configuration could not be saved." });
                return;
            }
            const response = this.$httpAdmin.put(url, { data: data }, { headers: this.headers });
            if (response) {
                this.$notify.success({ message: "Successfully saved configuration." });
                this.postToSlack(
                    `${this.addingTime.component} process (Core Function) config has been updated by ${this.userInfo.username}`
                );
                alert(`The configuration for ${this.addingTime.component} has been successfully saved!`);
                this.timers = [];
                this.addingTime = null;
            } else {
                this.$notify.error({ message: "Configuration could not be saved." });
            }
        },
        runProcess(row) {
            const action = row.typeAction;
            const url = "appcontroller/1";
            const actionMade = action === "run" ? "run" : "stop";
            const response = this.$httpAdmin.put(
                url,
                { action: actionMade, component: row.component },
                { headers: this.headers }
            );
            if (response) {
                this.postToSlack(
                    `${row.component} process (Core Function) has been manually ${actionMade} by ${this.userInfo.username}`
                );
                alert(`${row.component} has been successfully ${actionMade}!`);
            }
            this.getProcesses();
            this.showConfirm = false;
        },
        toggleEnableDisable(row) {
            const status = row.typeAction === "disable" ? true : false;
            const url = "appcontroller/1";
            const response = this.$httpAdmin.put(
                url,
                { action: "toggledisable", component: row.component, comp: { disabled: status } },
                { headers: this.headers }
            );
            if (response) {
                alert(`${row.component} has been successfully ${row.typeAction}d!`);
            }
            this.getProcesses();
            this.showEnableDisable = false;
        },
        filterDate(value) {
            if (value) {
                return moment(String(value)).format("MM/DD/YYYY hh:mm:ss A");
            }
        },
        async getProcesses() {
            const running = await this.$httpAdmin.get("appcontroller/?action=getcomponent&clientName=web", {
                headers: this.headers,
            });
            this.appsRunning = running.data
                .filter((r) => r.name && r.name !== "")
                .map((m) => {
                    let status = "clock";
                    m.canPlay = false;
                    m.canStop = false;
                    m.canEdit = false;
                    m.componentRunning = false;
                    if (m.status === "started") status = "play";
                    else if (m.status === "running") {
                        status = "sync";
                        m.componentRunning = true;
                    } else if (m.status === "newInvoices") status = "copy";
                    m.icon = status;

                    if (
                        (m.status === "done" || m.status === "newInvoices") &&
                        !(
                            m.component === "consolidation" ||
                            m.component === "downloader" ||
                            m.component === "extractionInvoiceDownloader"
                        )
                    ) {
                        m.canPlay = true;
                    }
                    if (m.component === "audit" && m.status === "running") {
                        m.canStop = true;
                    }
                    if (
                        m.component === "invoiceUploader" ||
                        m.component === "creditchecker" ||
                        m.component === "scheduledreport" ||
                        m.component === "reconciliation" ||
                        m.component === "downloader" ||
                        m.component === "updatepayments" ||
                        m.component === "archive" ||
                        m.component === "bulktracker" ||
                        m.component === "custominvoiceuploader"
                    ) {
                        m.canEdit = true;
                    }

                    return m;
                });
        },
    },
    mounted() {
        const pass = localStorage.getItem("appcontrollerpass");
        if (pass && pass === USER_PASS) {
            this.correctPassword = true;
        }
        this.getProcesses();
        const times = [];
        for (let i = 0; i < 24; i++) {
            times.push({ text: `${i}:00`, value: i });
        }
        this.times = times;
        this.daysOfWeek = [
            {
                text: "Sunday",
                value: 0,
            },
            {
                text: "Monday",
                value: 1,
            },
            {
                text: "Tuesday",
                value: 2,
            },
            {
                text: "Wednesday",
                value: 3,
            },
            {
                text: "Thursday",
                value: 4,
            },
            {
                text: "Friday",
                value: 5,
            },
            {
                text: "Saturday",
                value: 6,
            },
        ];
    },
};
</script>
