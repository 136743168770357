<template>
    <mdb-modal data-test="modal" id="bv-modal-setup" :show="show">
        <div class="modal-dialog">
            <div class="modal-content">
                <div data-test="modalHeader" class="modal-header text-center">
                    <h4 data-test="modalTitle" class="modal-title">
                        <img
                            data-test="modalImage"
                            class=""
                            src="userimages/long-small-cube-3b.gif"
                            width="371"
                            height="37"
                            alt=""
                        />
                    </h4>
                </div>
                <div data-test="modalBody" class="modal-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-12" style="text-align: center">
                                    <h3 data-test="modalAnnouncementTitle" style="margin-top: 0px">
                                        <span id="announcementTitle">Announcement title.</span>
                                    </h3>
                                </div>
                                <div class="col-lg-1 spacer15"></div>
                                <div class="col-lg-10 spacer15" style="margin-bottom: 10px">
                                    <p>
                                        <span data-test="modalAnnouncementText" id="announcementText">
                                            Announcement text
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-test="modalFooter" class="modal-footer" style="background-color: #f5f5f5">
                    <div class="col-lg-12 text-center">
                        <mdb-btn
                            data-test="modalDismissBtn"
                            color="success"
                            class="btn btn-success btn-lg"
                            @click.native="show = false"
                        >
                            <i class="fa fa-thumbs-up"></i>
                            Got it!
                        </mdb-btn>
                    </div>
                </div>
            </div>
        </div>
    </mdb-modal>
</template>
<script>
import { mdbModal, mdbBtn } from "mdbvue";
export default {
    name: "AnnouncementModal",
    components: { mdbModal, mdbBtn },
    props: {
        show: Boolean,
    },
};
</script>
<style>
.modal-header {
    background-color: #f5f5f5;
}
.modal-body {
    padding-bottom: 15px;
}
</style>
