import Vue from "vue";
import Router from "vue-router";
import Dashboard from "@/pages/Dashboard";
import Login from "@/pages/Login";
import Trends from "@/pages/analyze/Trends";
import Invoices from "@/pages/analyze/Invoices";
import CreateReports from "@/pages/report/CreateReports";
import MyReports from "@/pages/report/MyReports";
import Scheduling from "@/pages/report/Scheduling";
import Gallery from "@/pages/view/Gallery";
import MyView from "@/pages/view/MyView";
import GLCoding2 from "@/pages/tools/GLCoding2";
import Audit from "@/pages/addons/Audit";
import BulkTrack from "@/pages/addons/BulkTrack";
import GLCoding from "@/pages/addons/GLCoding";
import RateCalculator from "@/pages/addons/RateCalculator";
import NotFound from "@/pages/404";
import Opportunities from "@/pages/Opportunities";
import Billing from "@/pages/account/Billing";
import ForgotPassword from "@/pages/ForgotPassword";
import Settings from "@/pages/account/Settings";
import AdminDashboard from "@/admin/Dashboard";
import UpdateGL from "@/pages/tools/UpdateGL";
import AdminMyReports from "@/admin/ManageReporting/AdminMyReports";
import AdminCreateReport from "@/admin/ManageReporting/AdminCreateReport";
import AdminFieldsManager from "@/admin/ManageReporting/AdminFieldsManager";
import AdminScheduleReports from "@/admin/ManageReporting/AdminScheduleReports";
import AdminApplyGLCode from "@/admin/AdminTools/AdminApplyGLCode";
import AdminCredentialChecker from "@/admin/AdminTools/AdminCredentialChecker";
import AdminDownloadInvoice from "@/admin/AdminTools/AdminDownloadInvoice";
import AdminPackageGenie from "@/admin/AdminTools/AdminPackageGenie";
import AdminNinjaDownloader from "@/admin/AdminTools/AdminNinjaDownloader";
import AdminAddonCommission from "@/admin/ManageAccounting/AdminAddonCommission";
import AdminAdjustments from "@/admin/ManageAccounting/AdminAdjustments";
import AdminResellerCommission from "@/admin/ManageAccounting/AdminResellerCommission";
import AdminResellerInvoices from "@/admin/ManageAccounting/AdminResellerInvoices";
import AdminCustomers from "@/admin/ManageCustomers/AdminCustomers";
import AdminFailedDownloads from "@/admin/ManageCustomers/AdminFailedDownloads";
import AdminLoginKPIs from "@/admin/ManageCustomers/AdminLoginKPIs";
import AdminManageUsers from "@/admin/ManageCustomers/AdminManageUsers";
import AdminOnboardingADMIN from "@/admin/ManageCustomers/AdminOnboardingADMIN";
import AdminAppController from "@/admin/ManageProcesses/AdminAppController";
import AdminNodeStatus from "@/admin/ManageProcesses/AdminNodeStatus";
import AdminTriageList from "@/admin/ManageProcesses/AdminTriageList";
import AdminFullManual from "@/admin/ManualAudit/AdminFullManual";
import AdminNPODs from "@/admin/ManualAudit/AdminNPODs";
import AdminSystemErrors from "@/admin/ManageProcesses/AdminSystemErrors";
import AdminActivitiesLog from "@/admin/ManageProcesses/AdminActivitiesLog";
import AdminAuditReport from "@/admin/ManageProcesses/AdminAuditReport";
import AdminWtfReport from "@/admin/ManageProcesses/AdminWtfReport";
import AdminExtractFileFailed from "@/admin/ManageProcesses/AdminExtractFileFailed";
import AdminDuplicateInvoices from "@/admin/ManageProcesses/AdminDuplicateInvoices";
import AdminNotifications from "@/admin/ManageProcesses/AdminNotifications";
import AdminMissedInvoices from "@/admin/ManageCustomers/AdminMissedInvoices";
import AdminCarrierAccounts from "@/admin/ManageCustomers/AdminCarrierAccounts";
import AdminAuditPackageErrors from "@/admin/ManageCustomers/AdminAuditPackageErrors";
import AdminDownload from "@/admin/ManageCustomers/AdminDownload";
import AdminFailedCredentials from "@/admin/ManageCustomers/AdminFailedCredentials";
import AdminAttachmentErrors from "@/admin/ManageCustomers/AdminAttachmentErrors";
import RecoveryReportMonthly from "@/admin/RecoveryReportMonthly";
import Contract from "@/contract/Contract.vue";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/login",
            name: "Login",
            component: Login,
        },
        {
            path: "/password-reset",
            name: "ForgotPassword",
            component: ForgotPassword,
            props: (route) => ({ token: route.query.token, newuser: route.query.newuser === 'true' }),
        },
        {
            path: "/",
            name: "Dashboard",
            component: Dashboard,
            meta: {
                requiresAuth: true,
                title: "Dashboard",
            },
        },
        {
            path: "/trends",
            name: "Trends",
            component: Trends,
            meta: {
                requiresAuth: true,
                title: "Trends",
                parent: "Analyze",
            },
        },
        {
            path: "/contract",
            name: "Contract",
            component: Contract,
            meta: {
                requiresAuth: false,
                title: "Contract",
            },
        },
        {
            path: "/invoices",
            name: "Invoices",
            component: Invoices,
            meta: {
                requiresAuth: true,
                title: "Invoices",
                parent: "Analyze",
            },
        },
        {
            path: "/createreports",
            name: "CreateReports",
            component: CreateReports,
            meta: {
                requiresAuth: true,
                title: "Create Reports",
                parent: "Report",
            },
        },
        {
            path: "/myreports",
            name: "MyReports",
            component: MyReports,
            meta: {
                requiresAuth: true,
                title: "My Reports",
                parent: "Report",
            },
        },
        {
            path: "/scheduling",
            name: "Scheduling",
            component: Scheduling,
            meta: {
                requiresAuth: true,
                title: "Scheduling",
                parent: "Report",
            },
        },
        {
            path: "/gallery",
            name: "Gallery",
            component: Gallery,
            meta: {
                requiresAuth: true,
                title: "Gallery",
                parent: "View",
            },
        },
        {
            path: "/myview",
            name: "MyView",
            component: MyView,
            meta: {
                requiresAuth: true,
                title: "My View",
                parent: "View",
            },
        },
        {
            path: "/glcoding2",
            name: "GLCoding2",
            component: GLCoding2,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "GL Coding2",
                parent: "Tools",
            },
        },
        {
            path: "/updategl",
            name: "UpdateGL",
            component: UpdateGL,
            meta: {
                requiresAuth: true,
                title: "Update GL",
                parent: "Tools",
            },
        },
        {
            path: "/audit",
            name: "Audit",
            component: Audit,
            meta: {
                requiresAuth: true,
                title: "Audit",
                parent: "Addons",
            },
        },
        {
            path: "/bulktrack",
            name: "BulkTrack",
            component: BulkTrack,
            meta: {
                requiresAuth: true,
                title: "Bulk Track",
                parent: "Addons",
            },
        },
        {
            path: "/glcoding",
            name: "GLCoding",
            component: GLCoding,
            meta: {
                requiresAuth: true,
                title: "GL Coding",
                parent: "Addons",
            },
        },
        {
            path: "/ratecalculator",
            name: "RateCalculator",
            component: RateCalculator,
            meta: {
                requiresAuth: true,
                title: "Rate Calculator",
                parent: "Addons",
            },
        },
        {
            path: "/opportunities",
            name: "Opportunities",
            component: Opportunities,
            title: "Opportunities",
            meta: {
                requiresAuth: true,
                title: "Opportunities",
            },
        },
        {
            path: "/billing",
            name: "Billing",
            component: Billing,
            meta: {
                requiresAuth: true,
                title: "Billing",
            },
        },
        {
            path: "/settings",
            name: "Settings",
            component: Settings,
            meta: {
                requiresAuth: true,
                title: "Settings",
            },
        },
        {
            path: "/admin/",
            name: "AdminDashboard",
            component: AdminDashboard,
            meta: {
                requiresAuth: true,
                title: "Dashboard",
            },
        },
        {
            path: "/admin/myreports",
            name: "AdminMyReports",
            component: AdminMyReports,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 3, 5, 7, 9],
                title: "My Reports",
                parent: "Manage Reporting",
            },
        },
        {
            path: "/admin/createreport",
            name: "AdminCreateReport",
            component: AdminCreateReport,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Create Report",
                parent: "Manage Reporting",
            },
        },
        {
            path: "/admin/schedulereports",
            name: "AdminScheduleReports",
            component: AdminScheduleReports,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 9],
                title: "Schedule Reports",
                parent: "Manage Reporting",
            },
        },
        {
            path: "/admin/fieldsmanager",
            name: "AdminFieldsManager",
            component: AdminFieldsManager,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Fields Manager",
                parent: "Manage Reporting",
            },
        },
        {
            path: "/admin/applyglcode",
            name: "AdminApplyGLCode",
            component: AdminApplyGLCode,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Apply GL Code",
                parent: "Admin Tools",
            },
        },
        {
            path: "/admin/credentialchecker",
            name: "AdminCredentialChecker",
            component: AdminCredentialChecker,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Credential Checker",
                parent: "Admin Tools",
            },
        },
        {
            path: "/admin/downloadinvoice",
            name: "AdminDownloadInvoice",
            component: AdminDownloadInvoice,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Download Invoice",
                parent: "Admin Tools",
            },
        },
        {
            path: "/admin/packagegenie",
            name: "AdminPackageGenie",
            component: AdminPackageGenie,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 5],
                title: "Package Genie",
                parent: "Admin Tools",
            },
        },
        {
            path: "/admin/ninjadownloader",
            name: "AdminNinjaDownloader",
            component: AdminNinjaDownloader,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 5, 7],
                title: "Ninja Downloader",
                parent: "Admin Tools",
            },
        },
        {
            path: "/admin/addoncommission",
            name: "AdminAddonCommission",
            component: AdminAddonCommission,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Addon Commission",
                parent: "Manage Accounting",
            },
        },
        {
            path: "/admin/adjustments",
            name: "AdminAdjustments",
            component: AdminAdjustments,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 3, 5, 9],
                title: "Adjustments",
                parent: "Manage Accounting",
            },
        },
        {
            path: "/admin/resellercommission",
            name: "AdminResellerCommission",
            component: AdminResellerCommission,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 3, 5],
                title: "Reseller Commission",
                parent: "Manage Accounting",
            },
        },
        {
            path: "/admin/resellerinvoices",
            name: "AdminResellerInvoices",
            component: AdminResellerInvoices,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 3, 5, 9],
                title: "Reseller Invoices",
                parent: "Manage Accounting",
            },
        },
        {
            path: "/admin/customers",
            name: "AdminCustomers",
            component: AdminCustomers,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 3, 5, 7, 9],
                title: "Customers",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/faileddownloads",
            name: "AdminFailedDownloads",
            component: AdminFailedDownloads,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 5],
                title: "Failed Downloads",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/loginkpis",
            name: "AdminLoginKPIs",
            component: AdminLoginKPIs,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 5],
                title: "Login KPIs",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/manageusers",
            name: "AdminManageUsers",
            component: AdminManageUsers,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 5, 9],
                title: "Manage Users",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/onboardingadmin",
            name: "AdminOnboardingADMIN",
            component: AdminOnboardingADMIN,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 5, 9],
                title: "Onboarding",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/appcontroller",
            name: "AdminAppController",
            component: AdminAppController,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "App Controller",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/nodestatus",
            name: "AdminNodeStatus",
            component: AdminNodeStatus,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Node Status",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/triagelist",
            name: "AdminTriageList",
            component: AdminTriageList,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Triage List",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/fullmanual",
            name: "AdminFullManual",
            component: AdminFullManual,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 5],
                title: "Full Manual",
                parent: "Manual Audit",
            },
        },
        {
            path: "/admin/npods",
            name: "AdminNPODs",
            component: AdminNPODs,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "NPODs",
                parent: "Manual Audit",
            },
        },
        {
            path: "/admin/systemerrors",
            name: "AdminSystemErrors",
            component: AdminSystemErrors,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "System Errors",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/activitieslog",
            name: "AdminActivitiesLog",
            component: AdminActivitiesLog,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/auditreport",
            name: "AdminAuditReport",
            component: AdminAuditReport,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Audit Report",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/wtfreport",
            name: "AdminWtfReport",
            component: AdminWtfReport,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Invoice Errors",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/extractfilefailed",
            name: "AdminExtractFileFailed",
            component: AdminExtractFileFailed,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Extract File Errors",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/duplicateinvoices",
            name: "AdminDuplicateInvoices",
            component: AdminDuplicateInvoices,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Duplicate Invoices",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/notifications",
            name: "AdminNotifications",
            component: AdminNotifications,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Notifications",
                parent: "Manage Processes",
            },
        },
        {
            path: "/admin/missedinvoices",
            name: "AdminMissedInvoices",
            component: AdminMissedInvoices,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 5],
                title: "Missed Invoices",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/carrieraccounts",
            name: "AdminCarrierAccounts",
            component: AdminCarrierAccounts,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Carrier Accounts",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/auditpackageerrors",
            name: "AdminAuditPackageErrors",
            component: AdminAuditPackageErrors,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Audit Package Errors",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/download",
            name: "AdminDownload",
            component: AdminDownload,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2],
                title: "Download Status",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/failedcredentials",
            name: "AdminFailedCredentials",
            component: AdminFailedCredentials,
            meta: {
                requiresAuth: true,
                usrLevel: [1, 2, 5],
                title: "Failed Credentials",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/attachmenterrors",
            name: "AdminAttachmentErrors",
            component: AdminAttachmentErrors,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Attachment Errors",
                parent: "Manage Customers",
            },
        },
        {
            path: "/admin/RecoveryReportMonthly",
            name: "RecoveryReportMonthly",
            component: RecoveryReportMonthly,
            meta: {
                requiresAuth: true,
                usrLevel: [1],
                title: "Recovery Dashboard",
                parent: "SystemHealthDashboard",
            },
        },
        {
            path: "/notfound",
            name: "404",
            component: NotFound,
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (!to.matched.length) {
        next("/notfound");
    }
    let recordFound;
    const record = to.matched.some((rec) => {
        recordFound = rec;
        return rec.meta.requiresAuth;
    });
    if (record) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (localStorage.getItem("token") === null) {
            next({
                name: "Login",
                params: { nextUrl: to.fullPath },
            });
        } else {
            if (
                !recordFound.meta.usrLevel ||
                (recordFound.meta.usrLevel && recordFound.meta.usrLevel.indexOf(user.usrRestrictType) > -1)
            ) {
                next();
            } else {
                next({
                    path: "/",
                });
            }
        }
    } else {
        next();
    }
});

export default router;
