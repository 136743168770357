<template>
    <div data-test="page" class="w-100">
        <h4 data-test="image" class="w-100"><img src="img/TI-Suite.png" class="w-50 mx-auto d-block p-2" /></h4>
        <div v-if="showCompanyOptions">
            <mdb-row class="justify-content-center mt-4">
                <mdb-col col="4">
                    <mdb-card class="card-body">
                        <mdb-card-title>Please choose a company:</mdb-card-title>
                        <select class="browser-default custom-select mt-2"
                                v-model="selectedCompany">
                            <option
                                data-test="resellerOptionsOption"
                                v-for="company of companyOptions"
                                v-bind:key="company.cmp_name"
                                :value="company"
                            >
                                {{ company.cmp_name }} (ID: {{ company.cmp_id }})
                            </option>
                        </select>
                        <button class="btn btn-success btn-lg mt-4" @click="setCompanyLogin">Go</button>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
        </div>
        <div v-else data-test="loginform" id="loginform" class="pt-4 w-50 ml-auto mr-auto">
            <fieldset data-test="fieldset">
                <input data-test="loginInputHidden" type="hidden" name="a" value="login" />
                <div class="form-group">
                    <mdb-input
                        data-test="usernameInput"
                        placeholder="Username"
                        name="username"
                        type="text"
                        v-model="username"
                        @keydown.native.enter="authenticate"
                        required
                        autofocus
                    />
                </div>

                <div class="form-group">
                    <mdb-input
                        data-test="passwordInput"
                        placeholder="Password"
                        name="password"
                        type="password"
                        v-model="password"
                        @keydown.native.enter="authenticate"
                        required
                        autofocus
                    />
                </div>

                <div>
                    <p v-if="invalidLogin" class="text-danger">Incorrect username or password!</p>
                    <p v-else></p>
                </div>

                <div class="footer text-center">
                    <button
                        data-test="spinner"
                        type="submit"
                        class="btn btn-success btn-lg disabled"
                        value="Login"
                        v-if="loggingIn"
                    >
                        <mdb-spinner
                            label="Logging In"
                            class="mr-2"
                            style="width: 1.25rem; height: 1.25rem"
                        ></mdb-spinner>
                        Logging In
                    </button>

                    <button
                        data-test="authenticateBtn"
                        type="submit"
                        v-if="!loggingIn"
                        class="btn btn-success btn-lg"
                        value="Login"
                        @click="authenticate"
                    >
                        Login
                    </button>
                </div>
            </fieldset>
            <div class="text-center text-white w-100 block my-4">
                <router-link data-test="routerLink" class="text-muted" to="password-reset"
                    >Forgot Password?</router-link
                >
            </div>
        </div>
    </div>
</template>
<script>
import auth from "../lib/auth";
import * as config from "../config.js";
import { mdbInput, mdbSpinner } from "mdbvue";

export default {
    name: "Login",
    components: { mdbInput, mdbSpinner },
    props: {
        isAuthenticated: Boolean,
    },
    data: () => {
        return {
            username: "",
            password: "",
            loggingIn: false,
            invalidLogin: false,
            showCompanyOptions: false,
            selectedCompany: null,
            companyOptions: [],
            companyOptionsHeaders: {
                Authorization: "Basic " + window.btoa(process.env.VUE_APP_API_KEY),
                "Content-Type": "application/json",
            },
            userData: null
        };
    },
    methods: {
        async authenticate() {
            this.loggingIn = true;
            // TODO change to dynamic userId
            this.userData = await auth.login(this.username, this.password);
            if (this.userData) {
                if (this.userData.usrPasswordSetDate == null) {
                    // new user, needs to reset pw
                    this.$router.push("/password-reset?newuser=true");
                    return;
                }
                if (this.userData.usrLevel === "super_admin" && this.userData.usrRestrictType !== 8 && this.userData.usrRestrictType !== 4) {
                    this.$emit("isAuthenticated", true);
                    this.$emit("userInfo", this.userData);
                    await this.$router.push("/admin");
                } else {
                    const companiesArray = await this.checkCompanyLogin();
                    if(!companiesArray) {
                        this.$emit("isAuthenticated", true);
                        this.$emit("userInfo", this.userData);
                        await this.$router.push("/");
                    }
                    else {
                        this.loggingIn = false;
                    }
                }
            } else {
                this.loggingIn = false;
                this.invalidLogin = true;
            }
        },

        async getUserInfo() {
            // If this fails, then our token is bad; we are NOT authenticated and
            // should be logged out
            const resp = await this.$http.get(config.authUrl, {
                Authorization: "Basic " + window.btoa(localStorage.getItem("token")),
            });
            if (resp) {
                this.$emit("isAuthenticated", true);
                this.$emit("userInfo", resp);
                if (resp.usrLevel === "super_admin" && resp.usrRestrictType !== 8 && resp.usrRestrictType !== 4) {
                    await this.$router.push("/admin");
                } else {
                    await this.$router.push("/");
                }
            } else {
                this.loggingIn = false;
                this.logout();
            }
        },

        logout() {
            auth.logout();
            this.$emit("isAuthenticated", false);
            this.$emit("userInfo", {});
        },

        async checkCompanyLogin() {
            this.companyOptions = [];
            if (this.userData.usrCompanies.length > 1) {
                const companies = await this.$httpAdmin.get('company/light?sortColumn=cmp_name&cmp_is_active=true&cmp_is_deleted=false', {headers: this.companyOptionsHeaders});
                for (let i = 0; i < companies.data.length; i++) {
                    if (this.userData.usrCompanies.includes(companies.data[i].cmp_id)) {
                        this.companyOptions.push(companies.data[i]);
                    }
                }
                this.selectedCompany = this.companyOptions[0];
                this.showCompanyOptions = true;
                return true;
            }
                this.showCompanyOptions = false;
            return false;
        },

        async setCompanyLogin() {
            let first = this.selectedCompany;
            this.companyOptions.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0; });
            this.userData.usrCompanies = this.companyOptions;
            const companies = [];
            for(let i = 0; i < this.userData.usrCompanies.length; i++) {
                companies.push(this.userData.usrCompanies[i].cmp_id)
            }
            const user = {
                data: {
                    usr_id: this.userData.usrId,
                    usr_scmp_id: this.userData.usrScmpId,
                    usr_cmp_name: this.selectedCompany.cmp_name,
                    usr_reseller_id: this.userData.usrResellerId,
                    usr_reseller_name: this.userData.usrResellerName,
                    usr_reseller_logo: this.userData.usrResellerLogo,
                    usr_first_name: this.userData.usrFirstName,
                    usr_last_name: this.userData.usrLastName,
                    usr_phone: this.userData.usrPhone,
                    usr_time_zone: this.userData.usrTimeZone,
                    usr_has_accepted_term: this.userData.usrHasAcceptedTerms,
                    usr_email: this.userData.usrEmail,
                    usr_type: this.userData.usrType,
                    usr_restrict_type: this.userData.usrRestrictType,
                    usr_accesskey: this.userData.usrAccessKey,
                    usr_api_key: this.userData.usrApiKey,
                    usr_api_pass: this.userData.usrApiPass,
                    usr_last_login_date: this.userData.usrLastLoginDate,
                    usr_base_country: this.userData.usrBaseCountry,
                    usr_companies: companies,
                    usr_level: this.userData.usrLevel,
                    usr_is_web_registered: this.userData.usrIsWebRegistered,
                    usr_reseller_support_email: this.userData.usrResellerSupportEmail,
                    adminpass: this.userData.adminPass ? this.userData.adminPass : null,
                }
            }
            const updated = auth.setSession(user);
            this.$emit("isAuthenticated", true);
            this.$emit("userInfo", updated);
            this.$emit("companyOptions", this.companyOptions);
            this.showCompanyOptions = false;
            await this.$router.push("/");
        },
    },

    created() {
        this.getUserInfo();
    },
};
</script>
<style>
.w-50 {
    max-width: 50% !important;
}
</style>
