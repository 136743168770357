import Highcharts from "highcharts";

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

const maps = {
    drawChart(chart) {
        if (chart.type === "donut") return this.drawDonutChart(chart);
        if (chart.type === "map") return this.drawMapChart(chart);
        if (chart.type === "pie") return this.drawPieChart(chart);
        if (chart.type === "sparkline") return this.drawSparkLine(chart);
        if (chart.type === "line") return this.drawLineChart(chart);
        if (chart.type === "boxPlot") return this.drawBoxPlot(chart);
        if (chart.type === "heatMap") return this.drawHeatMap(chart);
        if (chart.type === "column") return this.drawColumnChart(chart);
    },
    drawLineChart(data) {
        let series = [];
        if(data.series && data.series.data.length > 0) {
            for(let i=0;i< data.series.data.length; i++) {
                series.push({
                    type: "spline",
                    data: data.series.data[i].series,
                    color: data.series.data[i].color[0],
                    turboThreshold: 10000,
                    name: data.series.data[i].name
                })
            }
        }
        else{
            series = [
                {
                    type: "spline",
                    data: data.series,
                    color: data.lineColor,
                    turboThreshold: 10000,
                    name: data.name
                },
            ];
        }
        return {
            heading: data.title ? data.title.text : "",
            title: {
                text: "",
            },
            chart: {
                type: "line",
            },
            series,
            chartType: "line",
            credits: {
                enabled: false,
            },
            legend: {
                layout: "vertical",
                align: "left",
                verticalAlign: "bottom",
            },
            colorAxis: {
                min: 0,
                stops: [
                    [0, "#EFEFFF"],
                    [0.5, Highcharts.getOptions().colors[0]],
                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).brighten(-0.5).get()],
                ],
            },
            buttonsRight: data.chartButtons,
            xAxis: {
                events: {
                    setExtremes: e => {
                        console.log(e)
                    }
                }
            }
        };
    },
    drawBulletChart(data) {
        let {title, min, mean, max, value, target, decimalPoint, postfix, maxheight} = data;
        const series = [];
        series.push({name:title,pointWidth:10,data:[value]});

        if (decimalPoint === null || decimalPoint === "undefined")
            decimalPoint = 2;

        if (target && target !== null && target !== undefined)
            series.push({name:'Target',type: 'scatter',data:[target]});

        if (!postfix || postfix === undefined)
            postfix = "";
        return {
            chart:{
                defaultSeriesType:'bar',
                marginTop:5,
                marginRight:15,
                marginBottom:10,
                marginLeft:100,
                borderWidth:0,
                height: maxheight || null
            },
            credits:{enabled:false},
            exporting:{enabled:false},
            title:{text:''},
            xAxis:{
                categories:[title],
                    tickLength:0,
                    lineColor:'#999',
                    lineWidth:1,
                    labels:{style:{fontWeight:'bold'}}
            },
            yAxis:{
                max:max,
                plotBands:[
                    {from:0,to:min,color: 'rgba(103,103,103,.35)'},
                    {from:min,to:mean,color: 'rgba(153,153,153,.35)'},
                    {from:mean,to:max,color: 'rgba(204,204,204,.35)'}
                ],
                min:0,
                minPadding:0,
                maxPadding:0,
                tickColor:'#ccc',
                tickWidth:1,
                tickLength:3,
                gridLineWidth:0,
                endOnTick:true,
                title:{text: ''},
                labels:{
                    y:10,
                        style:{
                        fontSize:'8px'
                    },
                    formatter:function(){
                        return this.value + postfix;
                    }
                }
            },
            legend:{enabled:false},
            tooltip:{
                enabled:true,
                backgroundColor:'rgba(255, 255, 255, .85)',
                borderWidth:0,
                shadow:true,
                style:{fontSize:'10px',padding:2},
                formatter:function() {
                    return this.series.name + ": <strong>" + Highcharts.numberFormat(this.y, decimalPoint) + " " + postfix + "</strong>";
                }
            },
            plotOptions:{
                bar:{
                    color:'#428bca',
                    shadow:false,
                    borderWidth:0,
                },
                scatter:{
                    marker:{
                        symbol:'line',
                        lineWidth:3,
                        radius:8,
                        lineColor:'#f00'
                    }
                }
            },
            series: series
        }
    },
    drawMapChart(data) {
        return {
            heading: data.title ? data.title.text : "",
            title: {
                text: "",
            },
            chart: {
                map: data.chart.map ? data.chart.map : "countries/us/us-all",
            },
            series: [
                {
                    animation: {
                        duration: 1000,
                    },
                    data: data.series,
                    joinBy: ["postal-code", "state"],
                    dataLabels: {
                        enabled: true,
                        color: "white",
                        format: "{point.postal-code}",
                    },
                    name: data.name,
                    states: {
                        hover: {
                            color: Highcharts.getOptions().colors[2],
                        },
                    },
                    tooltip: {
                        pointFormat: "{point.postal-code}: {point.value} " + data.name.fixCurrency(),
                    },
                },
            ],
            chartType: "mapChart",
            joinBy: ["postal-code", "state"],
            dataLabels: {
                enabled: true,
                color: "white",
                format: "{point.postal-code}",
            },
            states: {
                hover: {
                    color: Highcharts.getOptions().colors[2],
                },
            },
            credits: {
                enabled: false,
            },
            legend: {
                layout: "vertical",
                align: "left",
                verticalAlign: "bottom",
            },
            mapNavigation: {
                enabled: false,
            },

            colorAxis: {
                min: 0,
                stops: [
                    [0, "#EFEFFF"],
                    [0.5, Highcharts.getOptions().colors[0]],
                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).brighten(-0.5).get()],
                ],
            },
            buttonsRight: data.chartButtons,
        };
    },
    drawDonutChart(data) {
        var colors = Highcharts.getOptions().colors;
        var innerData = [];
        var outerData = [];
        for (var i = 0; i < data.series.length; i++) {
            var colr = colors[i];
            innerData.push({
                name: data.series[i].name.fixCurrency(),
                y: data.series[i].value,
                color: colr,
            });

            for (var j = 0; j < data.series[i].series.length; j++) {
                var brightness = 0.2 - j / data.series[i].series.length / data.series[i].series.length;
                outerData.push({
                    name: data.series[i].series[j].name.fixCurrency(),
                    y: data.series[i].series[j].value,
                    color: Highcharts.Color(colr).brighten(brightness).get(),
                });
            }
        }

        return {
            chart: {
                //height: 250,
                type: "pie",
                marginBottom: 20,
            },
            heading: data.title ? data.title.text : "",
            title: {
                text: "",
            },
            yAxis: {
                title: {
                    text: "",
                },
            },

            plotOptions: {
                pie: {
                    shadow: false,
                    center: ["50%", "50%"]
                },
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: data.expanded,
            },
            tooltip: {
                valueSuffix: "%",
                valueDecimals: 2,
            },
            series: [
                {
                    name: data.outerName.fixCurrency(),
                    data: innerData,
                    size: "80%",
                    dataLabels: {
                        formatter: function () {
                            return this.y > 5 ? this.point.name : null;
                        },
                        color: "white",
                    },
                },
                {
                    name: data.innerName.fixCurrency(),
                    data: outerData,
                    size: "85%",
                    innerSize: "70%",
                    dataLabels: {
                        formatter: function () {
                            return this.y > 1
                                ? "<b>" + this.point.name + "</b><br>" + parseFloat(this.y).toFixed(2) + "%"
                                : null;
                        },
                        distance: 0
                    },
                },
            ],
            buttonsRight: data.chartButtons,
        };
    },
    drawPieChart(data) {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            legend: {
                padding: -10,
                maxHeight: 80,
            },
            heading: data.title ? data.title.text : "",
            title: {
                text: "",
            },
            tooltip: {
                pointFormat: "{point.name}: <b>{point.y}</b>",
            },
            credits: {
                enabled: false,
            },
            exports: {
                enabled: data.expanded,
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                },
            },
            series: data.series,
            buttonsRight: data.chartButtons,
        };
    },
    drawSparkLine(data) {
        return {
            chart: {
                backgroundColor: null,
                borderWidth: 0,
                type: "area",
                margin: [2, 0, 2, 0],
                //width: 105,
                //width: 125,
                height: 43,
                //height: 45,
                style: {
                    overflow: "visible",
                },
                skipClone: true,
            },
            colors: [data.lineColor, data.fillColor],
            heading: data.title ? data.title.text : "",
            title: {
                text: "",
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: data.expanded,
            },
            xAxis: {
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
                minorTickLength: 0,
                tickLength: 0,
                startOnTick: false,
                endOnTick: false,
                tickPositions: [],
            },
            yAxis: {
                endOnTick: false,
                startOnTick: false,
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
                tickPositions: [0],
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                //backgroundColor: '#EFEFFF',
                //borderWidth: 1,
                shadow: false,
                useHTML: true,
                hideDelay: 0,
                shared: true,
                padding: 0,
                positioner: function (w, h, point) {
                    return { x: point.plotX - w / 2, y: point.plotY - h };
                },
                headerFormat: "{series.name}<br/>",
                pointFormat: data.valueSuffix ? data.valueSuffix.fixCurrency() + "<b>{point.y}</b><br/>" : "",

                valueDecimals: 2,
                enabled: data.enableTooltips !== undefined ? data.enableTooltips : true,
            },
            plotOptions: {
                series: {
                    animation: true,
                    lineWidth: data.lineWidth ? data.lineWidth : 2,
                    //lineColor: '#8A6D3B',
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 1,
                        },
                    },
                    marker: {
                        radius: 2,
                        states: {
                            hover: {
                                radius: 3,
                            },
                        },
                    },
                    fillOpacity: 0.25,
                },
                column: {
                    negativeColor: "#910000",
                    borderColor: "silver",
                },
            },
            selected: data.selected,
            series: data.series,
            buttonsRight: data.chartButtons,
        };
    },
    drawBoxPlot(data) {
        return {
            chart: {
                type: "boxplot",
            },
            heading: data.title ? data.title.text : "",
            title: {
                text: "",
            },

            legend: {
                enabled: false,
            },

            credits: {
                enabled: false,
            },
            exporting: {
                enabled: data.expanded,
            },
            xAxis: {
                categories: data.categories,
                title: {
                    text: data.xAxis.text.fixCurrency(),
                },
            },

            yAxis: {
                title: {
                    text: data.yAxis.text.fixCurrency(),
                },
            },

            series: [
                {
                    name: data.name,
                    data: data.series,
                    tooltip: {
                        headerFormat: "<em>" + data.tooltip.headerName.fixCurrency() + " {point.key}</em><br/>",
                        valuePrefix: data.tooltip.valuePrefix.fixCurrency(),
                    },
                },
            ],
            buttonsRight: data.chartButtons,
        };
    },
    drawHeatMap(data) {
        return {
            chart: {
                type: "heatmap",
                marginTop: 40,
                marginBottom: 40,
            },
            heading: data.title ? data.title.text : "",
            title: {
                text: "",
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: data.expanded,
            },
            xAxis: {
                categories: data.xAxis.categories,
                type: "category",
            },

            yAxis: {
                categories: data.yAxis.categories,
                title: null,
            },

            colorAxis: {
                min: 0,
                minColor: "#FFFFFF",
                maxColor: Highcharts.getOptions().colors[0],
            },

            legend: {
                align: "right",
                layout: "vertical",
                margin: 0,
                verticalAlign: "top",
                y: 25,
                symbolHeight: 320,
            },

            tooltip: {
                formatter: function () {
                    return data.tooltip
                        .fixCurrency()
                        .format(
                            this.point.value,
                            this.series.yAxis.categories[this.point.y],
                            this.series.xAxis.categories[this.point.x]
                        );
                },
            },

            series: [
                {
                    name: data.name,
                    borderWidth: 1,
                    data: data.series,
                    dataLabels: {
                        enabled: false,
                        color: "black",
                        style: {
                            textShadow: "none",
                            HcTextStroke: null,
                        },
                    },
                },
            ],
            buttonsRight: data.chartButtons,
        };
    },
    drawColumnChart(data) {
        let suffix = null;
        if (data.tooltip != null && data.tooltip.hasOwnProperty("valueSuffix")) suffix = data.tooltip.valueSuffix;

        let prefix = "";
        if (data.tooltip != null && data.tooltip.hasOwnProperty("valuePrefix") && data.tooltip.valuePrefix != null)
            prefix = data.tooltip.valuePrefix.fixCurrency();

        return {
            chart: {
                type: "column",
            },
            heading: data.title ? data.title.text : "",
            title: {
                text: "",
            },
            subtitle: {
                text: data.subtitle,
            },
            xAxis: {
                categories: data.xAxis.categories,
            },
            yAxis: {
                min: 0,
                title: {
                    text: data.yAxis.title,
                },
            },
            tooltip: {
                headerFormat:
                    '<span style="font-size:10px">' +
                    data.tooltip.headerText.fixCurrency() +
                    "{point.key}</span><table>",
                pointFormat:
                    '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                valuePrefix: prefix,
                valueSuffix: suffix,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: data.expanded,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: data.series,
            buttonsRight: data.chartButtons,
        };
    },
};

String.prototype.fixCurrency = function () {
    const currency_symbol = "$";
    var str = this;
    if (!str || str == "") return str;

    try {
        return str.replace(/\$/g, currency_symbol);
    } catch (e) {
        return str;
    }
};

String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
    });
};

export default maps;

