<template>
    <mdb-modal data-test="modal" id="bv-modal-setup" :show="show">
        <mdb-modal-header data-test="modalHeader">
            <mdb-modal-title data-test="modalTitle">
                <img class="" src="userimages/long-small-cube-3b.gif" width="371" height="37" alt="" />
            </mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body data-test="modalBody">
            <div class="text-center">
                <h3 data-test="welcome">Welcome aboard.</h3>
                We're excited to start saving you money. <br />
                Let's finish up the last details, click on any unchecked item to complete.
                <h4 data-test="setupChecklist" class="pt-4">Setup Checklist:</h4>
                <div class="list-group checked-list-box">
                    <div class="list-group-item list-group-item muted infopanel-default" id="accountStatus">
                        <h4 data-test="accountValidated" class="list-group-item-heading">
                            <mdb-icon icon="check-square" class="pr-2 text-success"></mdb-icon> Account Validated
                        </h4>
                        <p data-test="validated" class="list-group-item-text">
                            Your account has been validated, all good here.
                        </p>
                    </div>
                    <a
                        href="dashboard.php?p=settings&tour=1#credential"
                        target="_self"
                        class="list-group-item muted infopanel-default"
                        id="credentialsStatus"
                    >
                        <h4 data-test="carrierLogins" class="list-group-item-heading">
                            <mdb-icon icon="check-square" class="pr-2 text-success"></mdb-icon>
                            <span id="credentialsStatusAdd"></span> Carrier Logins
                        </h4>
                        <p data-test="carrierLoginsNote" class="list-group-item-text">
                            You've added at least one active carrier login.
                        </p>
                    </a>
                    <a
                        href="dashboard.php?p=billing&tour=1"
                        class="list-group-item muted infopanel-default"
                        id="paymentStatus"
                    >
                        <h4 data-test="paymentInfo" class="list-group-item-heading">
                            <mdb-icon icon="check-square" class="pr-2 text-success"></mdb-icon
                            ><span id="paymentStatusAdd"></span> Payment Information
                        </h4>
                        <p data-test="paymentInfoNote" class="list-group-item-text">
                            You have payment information on the file.
                        </p>
                    </a>
                </div>
                <h3 data-test="gettingReady" class="pt-4">We're getting things ready.</h3>
                <h5 data-test="gettingReadyNote">
                    If you just signed up, worry not - we're out getting your data for the first time which can take a
                    couple days.<br />
                    <br />
                    If you're an existing customer there may be a problem with your carrier login, please contact
                    support so we can get you back up and running.
                </h5>
                <a
                    data-test="chatButton"
                    href="javascript:$zopim.livechat.window.show();"
                    class="btn btn-info"
                    role="button"
                    ><mdb-icon icon="comment" class="pr-2"></mdb-icon> Chat With Us</a
                >
            </div>
        </mdb-modal-body>
    </mdb-modal>
</template>
<script>
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbIcon } from "mdbvue";
export default {
    name: "SetupModal",
    components: { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbIcon },
    props: {
        show: Boolean,
    },
};
</script>
