<template>
    <div data-test="page" class="overflow-auto h-90">
        <div>
            <h1 data-test="pageHeader" class="page-header border-bottom mx-2">
                Reseller Invoice Adjustments
                <mdb-icon icon="money-bill-alt" size="1x" class="float-right text-muted" role="button" />
            </h1>
        </div>
        <mdb-row class="mt-4 mx-2">
            <mdb-col lg="8" sm="12" class="d-flex align-items-stretch">
                <mdb-card data-test="addAdjustmentCard">
                    <mdb-card-body data-test="addAdjustmentCardBody">
                        <mdb-row>
                            <mdb-col lg="3" md="12">
                                <mdb-select
                                    data-test="typeOptionsSelect"
                                    v-model="typeOptions"
                                    @change="changeType"
                                    placeholder="Type"
                                    class="mb-0 h-25"
                                ></mdb-select>
                                <small data-test="typeOptionsSmall" class="form-text text-muted">Adjustment Type</small>
                            </mdb-col>
                            <mdb-col lg="4" md="12">
                                <mdb-input
                                    data-test="adjustmentDescriptionInput"
                                    placeholder="Description"
                                    :disabled="selectedType.value == 6 ? false : true"
                                    class="h-25"
                                    v-model="adjustmentDescription"
                                ></mdb-input>
                                <small data-test="adjustmentDescriptionSmall" class="form-text text-muted">
                                    Displayed on invoice.
                                </small>
                            </mdb-col>
                            <mdb-col lg="5" md="12">
                                <mdb-select
                                    data-test="customerOptionsSelect"
                                    search
                                    v-model="customerOptions"
                                    placeholder="Select Customer"
                                    class="position-top"
                                ></mdb-select>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col lg="3" md="12">
                                <mdb-input
                                    data-test="creditAmountInput"
                                    label="Credit Amount"
                                    icon="dollar-sign"
                                    class="h-25"
                                    v-model="creditAmount"
                                ></mdb-input>
                                <small data-test="creditAmountSmall" class="form-text text-muted">
                                    Positive value reduces customer invoice total.
                                </small>
                            </mdb-col>
                            <mdb-col lg="4" md="12">
                                <mdb-input
                                    data-test="invoiceMemoInput"
                                    v-model="invoiceMemo"
                                    placeholder="Memo"
                                    class="h-25"
                                ></mdb-input>
                                <small data-test="invoiceMemoSmall" class="form-text text-muted">
                                    Not displayed on invoice, internal only.
                                </small>
                            </mdb-col>
                            <mdb-col lg="3" md="12">
                                <mdb-select
                                    data-test="dateOptionsSelect"
                                    v-model="dateOptions"
                                    placeholder="Invoice Date"
                                    class="h-25"
                                ></mdb-select>
                                <small data-test="dateOptionsSmall" class="form-text text-muted">
                                    Apply to invoice
                                </small>
                            </mdb-col>
                            <mdb-col col="2" class="pl-0 pt-2">
                                <mdb-btn
                                    data-test="showAddModalBtn"
                                    color="primary"
                                    size="sm"
                                    class="mr-1"
                                    @click.native="showAddModal"
                                    >Add Adjustment</mdb-btn
                                >
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
            <mdb-col lg="4" sm="12" class="d-flex align-items-stretch">
                <mdb-card data-test="noteCard" border="warning">
                    <mdb-card-header data-test="noteCardHeader" color="warning-color">Please Note</mdb-card-header>
                    <mdb-card-body>
                        <p>
                            If a Late Fee needs to be deleted they should be deleted same week which the invoices go out
                            (third Friday of each month).
                        </p>
                        <br />
                        <p>Late Fees are calculated weekly so it could be recreated automatically if deleted prior.</p>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <mdb-row class="mt-4 mx-2">
            <mdb-col col="12">
                <vue-good-table
                    data-test="vue-good-table"
                    :columns="tableColumns"
                    :rows="tableRows"
                    mode="remote"
                    :isLoading.sync="loading"
                    :search-options="{ enabled: true, trigger: 'enter' }"
                    styleClass="vgt-table striped bordered"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    @on-search="searchTable"
                    :totalRows="totalRecords"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: this.limit,
                        position: 'top',
                        perPageDropdown: [10, 25, 50, 100],
                        dropdownAllowAll: false,
                        setCurrentPage: this.page,
                    }"
                >
                    <template slot="loadingContent"> <mdb-spinner></mdb-spinner> Loading data... </template>
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field == 'delete'">
                            <a class="btn-sm btn-outline-danger"
                                ><mdb-icon icon="trash" @click.native="showDeleteModal(props.row)"
                            /></a>
                        </div>
                        <span v-else-if="props.column.field === 'ria_amount'">
                            {{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                        </span>
                    </template>
                </vue-good-table>
            </mdb-col>
        </mdb-row>
        <mdb-modal data-test="deleteModal" :show="deleteModal" @close="deleteModal = false">
            <mdb-modal-header>
                <mdb-modal-title data-test="deleteModalTitle">Delete Adjustment</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body data-test="deleteModalBody">
                <p>Are you sure that you want to delete this adjustment?</p>
                <br />
                <p data-test="deleteModalSelectedRow" v-if="selectedRow">
                    {{ selectedRow.cmp_name }} {{ selectedRow.ria_number }}
                </p>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn data-test="deleteModalCloseBtn" outline="primary" @click.native="deleteModal = false"
                    >Close</mdb-btn
                >
                <mdb-btn data-test="deleteAdjustmentBtn" color="primary" @click.native="deleteAdjustment"
                    >Delete</mdb-btn
                >
            </mdb-modal-footer>
        </mdb-modal>
        <mdb-modal data-test="addModal" :show="addModal" @close="addModal = false">
            <mdb-modal-header>
                <mdb-modal-title data-test="addModalTitle">Add Adjustment</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body data-test="addModalBody">
                <p>Are you sure that you want to add this adjustment?</p>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn data-test="addModalCloseBtn" outline="primary" @click.native="addModal = false">Close</mdb-btn>
                <mdb-btn data-test="addAdjustmentBtn" color="primary" @click.native="addAdjustment">Add</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>
<script>
import moment from "moment";
import {
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbSelect,
    mdbInput,
    mdbBtn,
    mdbCardHeader,
    mdbSpinner,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
} from "mdbvue";

export default {
    name: "AdminAdjustments",
    components: {
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardBody,
        mdbSelect,
        mdbInput,
        mdbBtn,
        mdbCardHeader,
        mdbSpinner,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            loading: false,
            tableCreated: null,
            table: [],
            tableRows: null,
            tableColumns: null,
            customerList: null,
            customerOptions: [],
            customersDropdown: null,
            invoiceDates: null,
            dateOptions: [],
            invoiceMemo: null,
            deleteModal: false,
            addModal: false,
            selectedType: "",
            adjustmentDescription: null,
            selectedCustomer: null,
            creditAmount: null,
            selectedDate: null,
            selectedRow: null,
            newRiaNumber: null,
            typeOptions: [
                { value: "1", text: "Late Fee (30 Days)", selected: false },
                { value: "2", text: "Late Fee (60 Days)", selected: false },
                { value: "3", text: "Late Fee (90 Days)", selected: false },
                { value: "4", text: "Invoice Credit", selected: false },
                { value: "5", text: "Adjustment Credit", selected: false },
                { value: "6", text: "General", selected: false },
            ],
            limit: 25,
            size: 25,
            page: 1,
            totalRecords: 0,
            sortColumn: "ria_create_time",
            sortkey: "ria_create_time",
            sortDir: "desc",
            searchTerm: null,
        };
    },
    methods: {
        async getInit() {
            this.tableCreated = await this.createTable();
            this.customersDropdown = await this.getCustomers();
            this.invoiceDates = await this.getInvoiceDates();
        },

        async searchTable(params) {
            this.searchTerm = params.searchTerm;
            await this.createTable();
        },
        async createTable() {
            this.loading = true;
            await this.$httpAdmin
                .get("/resellerinvoiceadjustment", {
                    headers: this.headers,
                    params: this.tableParams(),
                })
                .then(async (response) => {
                    this.table = response.data[0].Items;
                    this.totalRecords = response.data[0].TotalRecords;
                });
            this.tableRows = this.table;
            this.loading = false;
            for (let i = 0; i < this.tableRows.length; i++) {
                this.tableRows[i].ria_create_time = new moment(this.tableRows[i].ria_create_time).format(
                    "MMM DD, YYYY"
                );
            }
            this.tableColumns = [
                { field: "cmp_name", label: "Company", sortable: true },
                { field: "ria_number", label: "Invoice Number", sortable: true },
                { field: "ria_type", label: "Type", sortable: true },
                { field: "ria_desc", label: "Description", sortable: true },
                { field: "ria_amount", label: "Amount", sortable: true, sortFn: this.sortFn },
                { field: "ria_memo", label: "Memo", sortable: true },
                { field: "ria_create_time", label: "Added Time", sortable: true },
                { field: "delete", label: "Delete", sortable: false },
            ];
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.createTable();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.createTable();
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.createTable();
        },
        tableParams() {
            return {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                cmp_name: this.searchTerm ? this.searchTerm : null,
            };
        },
        async getCustomers() {
            await this.$httpAdmin
                .get("/admincompany/light", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.customerList = response.data;
                });
            let option = "";
            for (let i = 0; i < this.customerList.length; i++) {
                option = {
                    value:
                        this.customerList[i].cmp_reseller_id.toString().padStart(2, "0") +
                        "-" +
                        this.customerList[i].cmp_id.toString().padStart(4, "0"),
                    text:
                        this.customerList[i].cmp_name +
                        " (ID: " +
                        this.customerList[i].cmp_id +
                        " Type: " +
                        this.customerList[i].cmp_type.toUpperCase() +
                        ")",
                    id: this.customerList[i].cmp_id,
                };
                this.customerOptions.push(option);
            }
        },
        changeType() {
            for (let i = 0; i < this.typeOptions.length; i++) {
                if (this.typeOptions[i].selected) {
                    this.selectedType = this.typeOptions[i];
                }
            }
            if (this.selectedType.value != 6) {
                this.adjustmentDescription = "";
            }
        },
        showDeleteModal(row) {
            this.selectedRow = row;
            this.deleteModal = true;
        },
        async deleteAdjustment() {
            this.deleteModal = false;
            await this.$httpAdmin
                .delete("/resellerinvoiceadjustment/" + this.selectedRow.ria_id, { headers: this.headers })
                .then(async (response) => {
                    this.adjustmentDeleted = response;
                    if (this.adjustmentDeleted) {
                        this.$notify.success({
                            message: "The adjustment has been deleted successfully.",
                            title: "Success",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: "There is a problem to delete this adjustment. Please contact the technical team!",
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
            this.table = [];
            this.tableRows = null;
            this.tableCreated = await this.createTable();
        },
        showAddModal() {
            if (!this.selectedType) {
                this.$notify.error({
                    message: "You should select a type for this adjustment.",
                    title: "Type Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            if (this.selectedType.value == 6 && (!this.adjustmentDescription || this.adjustmentDescription == "")) {
                this.$notify.error({
                    message: "You should add a description to this adjustment.",
                    title: "Description Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            for (let i = 0; i < this.customerOptions.length; i++) {
                if (this.customerOptions[i].selected) {
                    this.selectedCustomer = this.customerOptions[i];
                }
            }
            if (!this.selectedCustomer) {
                this.$notify.error({
                    message: "You should select a company.",
                    title: "Customer Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            if (!this.creditAmount || isNaN(this.creditAmount)) {
                this.$notify.error({
                    message: "The amount value is not correct.",
                    title: "Credit Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            } else {
                this.creditAmount = parseFloat(this.creditAmount);
            }
            for (let i = 0; i < this.dateOptions.length; i++) {
                if (this.dateOptions[i].selected) {
                    this.selectedDate = this.dateOptions[i];
                }
            }
            if (!this.selectedDate) {
                this.$notify.error({
                    message: "You should select invoice date for this adjustment.",
                    title: "Date Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            this.newRiaNumber = "E" + this.selectedCustomer.value + "-" + this.selectedDate.value;
            this.addModal = true;
        },
        async addAdjustment() {
            this.addModal = false;
            await this.$httpAdmin
                .post("/resellerinvoiceadjustment", this.addAdjustmentParams(), { headers: this.headers })
                .then(async (response) => {
                    let results = response.data;
                    if (results) {
                        this.$notify.success({
                            message: "The adjustment has been added successfully.",
                            title: "Success",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: "There is a problem to add this adjustment. Please contact the technical team!",
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
            this.table = [];
            this.tableRows = null;
            this.tableCreated = await this.createTable();
        },
        addAdjustmentParams() {
            return {
                ria_number: this.newRiaNumber,
                ria_cmp_id: this.selectedCustomer.id,
                ria_type: this.selectedType.value,
                ria_desc: this.adjustmentDescription ? this.adjustmentDescription : this.selectedType.text,
                ria_amount: this.creditAmount,
                ria_memo: this.invoiceMemo,
            };
        },
        getInvoiceDates() {
            let firstDay = new moment([new Date().getFullYear(), new Date().getMonth()]);
            let diff = 5 - firstDay.day();
            diff = diff < 0 ? diff + 21 : diff + 14;
            let thirdFriday = moment(firstDay).add(diff, "days").hour(0);
            let startDate = firstDay;
            if (new moment().isBefore(thirdFriday)) startDate = moment(firstDay).add(-5, "days");
            let date = "";
            for (let i = 0; i < 12; i++) {
                date = { value: startDate.format("YYMM") + 1, text: startDate.format("MMM, YYYY") };
                this.dateOptions.push(date);
                startDate.add(1, "M");
            }
        },
        sortFn(x, y, col) {
            return x < y ? -1 : x > y ? 1 : 0;
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
