<template>
    <div data-test="page" class="overflow-auto h-90">
        <div>
            <h1 data-test="pageTitle" class="page-header border-bottom mx-2">
                Onboarding
                <span
                    data-test="pageTitle_smaller"
                    class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block"
                    >Pending Activations</span
                >
                <mdb-icon icon="plus" size="1x" class="float-right text-muted" role="button" />
            </h1>
        </div>
        <mdb-row v-if="isBusy" id="onboarding_overlay" class="justify-content-center align-content-center h-100vh">
            <mdb-spinner class="justify-content-center"></mdb-spinner>
            <h3><b>...Loading</b></h3>
        </mdb-row>
        <mdb-row class="mt-4" :style="{ opacity: isBusy ? 0.3 : 1 }">
            <mdb-card data-test="onboardingCard" class="w-100">
                <mdb-card-header data-test="onboardingCardHeader">
                    <mdb-tab default class="card-header-tabs">
                        <mdb-tab-item
                            data-test="onboardingCardHeader_onboardingTab"
                            :active="active == 0"
                            @click.native.prevent="active = 0"
                        >
                            <mdb-icon icon="user" class="mr-2"></mdb-icon>
                            Onboarding
                        </mdb-tab-item>
                        <mdb-tab-item
                            data-test="onboardingCardHeader_wruTab"
                            v-if="userInfo.usrLevel === 'super_admin' && userInfo.usrRestrictType === 1"
                            :active="active == 1"
                            @click.native.prevent="active = 1"
                        >
                            <mdb-icon icon="building" class="mr-2"></mdb-icon>
                            Web Registered Users
                        </mdb-tab-item>
                    </mdb-tab>
                </mdb-card-header>
                <mdb-card-body>
                    <mdb-tab-content>
                        <mdb-tab-pane
                            data-test="onboardingCard_onboardingTab"
                            class="fade"
                            key="show1"
                            v-if="active == 0"
                        >
                            <mdb-row>
                                <mdb-col col="4">
                                    <mdb-select
                                        data-test="onboardingTab_changeSelectedResellerSelect"
                                        outline
                                        search
                                        @getValue="changeSelectedReseller"
                                        :options="resellerOptions"
                                    ></mdb-select>
                                </mdb-col>
                                <mdb-col>
                                    <mdb-btn
                                        data-test="onboardingTab_manualAddModalBtn"
                                        color="info"
                                        @click.native="manualAddModal = true"
                                        class="mt-3"
                                        >Manual Add
                                        <mdb-icon icon="plus"></mdb-icon>
                                    </mdb-btn>
                                </mdb-col>
                            </mdb-row>
                            <vue-good-table
                                data-test="onboardingTab_vue-good-table"
                                :columns="onboardingColumns"
                                :rows="onboardingRows"
                                :isLoading.sync="loading"
                                :search-options="{ enabled: true, trigger: 'enter' }"
                                styleClass="vgt-table striped bordered"
                                @on-row-click="onRowClick"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-per-page-change="onPerPageChange"
                                @on-search="searchTable"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    mode: 'records',
                                    perPage: this.limit,
                                    position: 'top',
                                    perPageDropdown: [10, 25, 50, 100],
                                    dropdownAllowAll: false,
                                    setCurrentPage: this.page,
                                }"
                            ></vue-good-table>
                            <mdb-modal
                                data-test="onboardingTab_rowModal"
                                size="lg"
                                :show="rowModal"
                                @close="rowModal = false"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title data-test="onboardingTab_rowModalTitle"
                                        >Choose an action
                                    </mdb-modal-title>
                                </mdb-modal-header>
                                <mdb-modal-body>
                                    <mdb-row>
                                        <mdb-btn
                                            data-test="onboardingTab_rowModal_customerInfoModalBtn"
                                            color="primary"
                                            @click.native="customerInfoModal"
                                            >1. Customer Info
                                            <mdb-icon icon="user"></mdb-icon>
                                        </mdb-btn>
                                        <mdb-btn
                                            data-test="onboardingTab_rowModal_sendContractModalBtn"
                                            color="primary"
                                            @click.native="sendContractModal"
                                            >2. Send Contract
                                            <mdb-icon icon="envelope"></mdb-icon>
                                        </mdb-btn>
                                        <mdb-btn
                                            data-test="onboardingTab_rowModal_carrierCredentialsModalBtn"
                                            color="amber"
                                            @click.native="carrierCredentialsModal"
                                            >3. Carrier Credentials
                                            <mdb-icon icon="truck"></mdb-icon>
                                        </mdb-btn>
                                        <mdb-btn
                                            data-test="onboardingTab_rowModal_carrierAccountsModalBtn"
                                            color="amber"
                                            @click.native="carrierAccountsModal"
                                            >4. Carrier Accounts
                                            <mdb-icon icon="truck"></mdb-icon>
                                        </mdb-btn>
                                        <mdb-btn
                                            data-test="onboardingTab_rowModal_activateAccountBtn"
                                            color="amber"
                                            @click.native="showActivateModal"
                                            >5. Activate
                                            <mdb-icon icon="rocket"></mdb-icon>
                                        </mdb-btn>
                                    </mdb-row>
                                </mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn
                                        data-test="onboardingTab_rowModalCloseBtn"
                                        color="primary"
                                        @click.native="rowModal = false"
                                        >Close
                                    </mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                            <mdb-modal
                                data-test="onboardingTab_custInfoModal"
                                size="fluid"
                                :show="customerModal"
                                @close="customerModal = false"
                                v-if="custInfo"
                            >
                                <mdb-modal-header data-test="onboardingTab_custInfoModalHeader">
                                    <mdb-modal-title data-test="onboardingTab_custInfoModalTitle" v-if="selectedRow">
                                        <mdb-icon icon="user" class="pr-2"></mdb-icon>
                                        <span class="pr-2"><b>NEW</b></span
                                        >Customer Information |
                                        {{ selectedRow.cmp_name }}
                                    </mdb-modal-title>
                                </mdb-modal-header>
                                <mdb-modal-body>
                                    <mdb-row data-test="onboardingTab_custInfoModal_smallTitle">
                                        <h6 class="mx-auto">
                                            <mdb-icon icon="user" class="pr-2"></mdb-icon>
                                            Customer Settings
                                        </h6>
                                    </mdb-row>
                                    <mdb-row data-test="onboardingTab_custInfoModal_auditSettingsTitle">
                                        <mdb-col col="12">
                                            <h4>
                                                Audit Settings
                                                <mdb-icon icon="cogs" class="float-right muted" size="lg"></mdb-icon>
                                            </h4>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row>
                                        <mdb-col lg="7" md="12">
                                            <mdb-row>
                                                <mdb-col lg="7" md="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <label
                                                                data-test="onboardingTab_custInfoModal_partnerLabel"
                                                                for="partner"
                                                                ><b>Partner</b></label
                                                            >
                                                            <select
                                                                data-test="onboardingTab_custInfoModal_partnerSelect"
                                                                class="browser-default custom-select"
                                                                id="partner"
                                                                v-model="selectedRow && selectedRow.cmp_reseller_name"
                                                            >
                                                                <option
                                                                    data-test="onboardingTab_custInfoModal_partnerSelectOption"
                                                                    v-for="(reseller, index) of resellerOptions"
                                                                    v-bind:key="index"
                                                                >
                                                                    {{ reseller.text }}
                                                                </option>
                                                            </select>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row class="pt-4">
                                                        <mdb-col col="6">
                                                            <label
                                                                data-test="onboardingTab_custInfoModal_customerTypeLabel"
                                                                for="customerType"
                                                                ><b>Customer Type</b></label
                                                            >
                                                            <select
                                                                data-test="onboardingTab_custInfoModal_customerTypeSelect"
                                                                class="browser-default custom-select"
                                                                id="customerType"
                                                                v-model="custInfo.cmp_type"
                                                            >
                                                                <option
                                                                    data-test="onboardingTab_custInfoModal_customerTypeOption_pleaseSelect"
                                                                    value=""
                                                                    disabled
                                                                >
                                                                    Please Select
                                                                </option>
                                                                <option
                                                                    data-test="onboardingTab_custInfoModal_customerTypeOption_customer"
                                                                    value="live"
                                                                >
                                                                    Customer
                                                                </option>
                                                                <option
                                                                    data-test="onboardingTab_custInfoModal_customerTypeOption_trial"
                                                                    value="trial"
                                                                >
                                                                    Trial (30 Day)
                                                                </option>
                                                                <option
                                                                    data-test="onboardingTab_custInfoModal_customerTypeOption_mock"
                                                                    value="mock"
                                                                >
                                                                    Trial (Mock Audit)
                                                                </option>
                                                            </select>
                                                        </mdb-col>
                                                        <mdb-col col="6">
                                                            <label
                                                                data-test="onboardingTab_custInfoModal_baseCountryLabel"
                                                                for="baseCountry"
                                                                ><b>Base Country</b>
                                                                <mdb-tooltip
                                                                    data-test="onboardingTab_custInfoModal_baseCountryTooltip"
                                                                    material
                                                                    trigger="hover"
                                                                    :options="{ placement: 'right' }"
                                                                >
                                                                    <span slot="tip"
                                                                        >Typically US. This option is set to modify the
                                                                        carrier billing country settings for
                                                                        international based customers.</span
                                                                    >
                                                                    <mdb-icon
                                                                        slot="reference"
                                                                        class="pl-1"
                                                                        icon="question-circle"
                                                                        color="primary"
                                                                        size="sm"
                                                                    ></mdb-icon>
                                                                </mdb-tooltip>
                                                            </label>
                                                            <select
                                                                data-test="onboardingTab_custInfoModal_baseCountrySelect"
                                                                class="browser-default custom-select"
                                                                id="baseCountry"
                                                                v-model="custInfo.cmp_base_country"
                                                            >
                                                                <option
                                                                    data-test="onboardingTab_custInfoModal_baseCountryOption"
                                                                    v-for="(country, index) of countries"
                                                                    v-bind:key="index"
                                                                    :value="country.value"
                                                                >
                                                                    {{ country.text }}
                                                                </option>
                                                            </select>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                                <mdb-col lg="5" sm="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <label
                                                                data-test="onboardingTab_custInfoModal_ownerSalesPersonLabel"
                                                                for="ownerSalesPerson"
                                                                ><b>Owner/Sales Person</b></label
                                                            >
                                                            <select
                                                                data-test="onboardingTab_custInfoModal_ownerSalesPersonSelect"
                                                                class="browser-default custom-select"
                                                                id="ownerSalesPerson"
                                                                v-model="custInfo.cmp_user_profile_id_sales_person"
                                                            >
                                                                <option
                                                                    data-test="onboardingTab_custInfoModal_ownerSalesPerson_pleaseSelectOption"
                                                                    value="null"
                                                                    disabled
                                                                    selected
                                                                >
                                                                    Please Select
                                                                </option>
                                                                <option value="1">Client Success</option>
                                                                <option
                                                                    data-test="onboardingTab_custInfoModal_ownerSalesPerson_salespersonOption"
                                                                    v-for="(salesperson, index) of salespersonOptions"
                                                                    v-bind:key="index"
                                                                >
                                                                    {{ salesperson.text }}
                                                                    <span v-if="salesperson.disabled"> (disabled)</span>
                                                                </option>
                                                            </select>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row class="pt-4">
                                                        <mdb-col col="6">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_startDateLabel"
                                                                    for="startDate"
                                                                    ><b>Start Date</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_startDateInput"
                                                                    disabled
                                                                    type="text"
                                                                    id="startDate"
                                                                    class="form-control"
                                                                    v-model="custInfo.cmp_start_date"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                        <mdb-col col="6">
                                                            <label
                                                                data-test="onboardingTab_custInfoModal_liveDateLabel"
                                                                for="liveDate"
                                                                ><b>Live Date</b>
                                                                <mdb-tooltip
                                                                    data-test="onboardingTab_custInfoModal_liveDateTooltip"
                                                                    material
                                                                    trigger="hover"
                                                                    :options="{ placement: 'right' }"
                                                                >
                                                                    <span slot="tip"
                                                                        >Date which customer became a paying customer
                                                                        (not trial).</span
                                                                    >
                                                                    <mdb-icon
                                                                        slot="reference"
                                                                        class="pl-1"
                                                                        icon="question-circle"
                                                                        color="primary"
                                                                        size="sm"
                                                                    ></mdb-icon>
                                                                </mdb-tooltip>
                                                            </label>
                                                            <input
                                                                data-test="onboardingTab_custInfoModal_liveDateInput"
                                                                disabled
                                                                type="text"
                                                                id="liveDate"
                                                                class="form-control"
                                                                v-model="newCustomerLiveDate"
                                                            />
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                            </mdb-row>
                                        </mdb-col>
                                        <mdb-col lg="5" sm="12">
                                            <mdb-input
                                                type="checkbox"
                                                label="Download Only"
                                                v-model="custInfo.cmp_is_download_only"
                                                @click.native="toggleCmpDownloadOnly"
                                            >
                                            </mdb-input>
                                            <mdb-card v-if="!custInfo.cmp_is_download_only">
                                                <mdb-card-body>
                                                    <mdb-row>
                                                        <mdb-col col="8">
                                                            <mdb-row>
                                                                <mdb-col>
                                                                    <div class="form-group">
                                                                        <label
                                                                            data-test="onboardingTab_custInfoModal_customerBillingTypeLabel"
                                                                            for="customerBillingType"
                                                                            ><b>Customer Billing Type</b></label
                                                                        >
                                                                        <select
                                                                            data-test="onboardingTab_custInfoModal_customerBillingTypeSelect"
                                                                            id="customerBillingType"
                                                                            class="browser-default custom-select color: yellow lighten-3"
                                                                            v-model="custInfo.cmp_bill_type"
                                                                            @change="changeBillingType"
                                                                        >
                                                                            <option
                                                                                data-test="onboardingTab_custInfoModal_customerBillingType_gainshareOption"
                                                                                value="gainShare"
                                                                            >
                                                                                Gain Share
                                                                            </option>
                                                                            <option
                                                                                data-test="onboardingTab_custInfoModal_customerBillingType_flatfeeOption"
                                                                                value="flatfee"
                                                                            >
                                                                                Flat Fee
                                                                            </option>
                                                                            <option
                                                                                data-test="onboardingTab_custInfoModal_customerBillingType_transactionalOption"
                                                                                value="transactional"
                                                                            >
                                                                                Transactional
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </mdb-col>
                                                            </mdb-row>
                                                            <mdb-row>
                                                                <mdb-col col="6">
                                                                    <div class="form-group">
                                                                        <label
                                                                            data-test="onboardingTab_custInfoModal_netTermsLabel"
                                                                            for="netTerms"
                                                                            ><b>Net Terms</b></label
                                                                        >
                                                                        <div class="input-group">
                                                                            <input
                                                                                data-test="onboardingTab_custInfoModal_netTermsInput"
                                                                                title="Accounting use only"
                                                                                id="netTerms"
                                                                                type="text"
                                                                                class="form-control color: yellow lighten-3"
                                                                                v-model="custInfo.cmp_bill_terms"
                                                                            />
                                                                            <div
                                                                                data-test="onboardingTab_custInfoModal_netTermsAppend"
                                                                                class="input-group-append"
                                                                            >
                                                                                <span class="input-group-text"
                                                                                    >Days</span
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </mdb-col>
                                                                <mdb-col col="6">
                                                                    <div class="form-group">
                                                                        <label
                                                                            data-test="onboardingTab_custInfoModal_lateFeeLabel"
                                                                            for="lateFee"
                                                                            ><b>Late Fee</b></label
                                                                        >
                                                                        <div class="input-group">
                                                                            <input
                                                                                data-test="onboardingTab_custInfoModal_lateFeeInput"
                                                                                title="Accounting use only"
                                                                                id="lateFee"
                                                                                type="text"
                                                                                class="form-control color: yellow lighten-3"
                                                                                v-model="custInfo.cmp_late_fee"
                                                                            />
                                                                            <div
                                                                                data-test="onboardingTab_custInfoModal_lateFeeAppend"
                                                                                class="input-group-append"
                                                                            >
                                                                                <span class="input-group-text">%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </mdb-col>
                                                            </mdb-row>
                                                        </mdb-col>
                                                        <mdb-col col="4">
                                                            <mdb-row class="pr-3">
                                                                <div class="form-group">
                                                                    <label
                                                                        data-test="onboardingTab_custInfoModal_valueLabel"
                                                                        for="value"
                                                                        ><b>Value</b></label
                                                                    >
                                                                    <div class="input-group">
                                                                        <input
                                                                            data-test="onboardingTab_custInfoModal_valueInput"
                                                                            title="Accounting use only"
                                                                            id="value"
                                                                            type="text"
                                                                            class="form-control color: yellow lighten-3"
                                                                            v-model="custInfo.cmp_bill_value"
                                                                        />
                                                                        <div
                                                                            data-test="onboardingTab_custInfoModal_valueAppend"
                                                                            class="input-group-append"
                                                                        >
                                                                            <span
                                                                                class="input-group-text"
                                                                                id="basic-addon"
                                                                                >{{ custBillTypeVal }}</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </mdb-row>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-card-body>
                                            </mdb-card>
                                        </mdb-col>
                                    </mdb-row>
                                    <hr />
                                    <mdb-row data-test="onboardingTab_custInfoModal_primaryContactTitle">
                                        <mdb-col col="12">
                                            <h4>
                                                Primary Contact
                                                <mdb-icon icon="user-md" class="float-right muted" size="lg"></mdb-icon>
                                            </h4>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row>
                                        <mdb-col col="12">
                                            <mdb-row>
                                                <mdb-col lg="4" sm="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_firstNameLabel"
                                                                    for="cmpContactFirstName"
                                                                    ><b>First Name</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_firstNameInput"
                                                                    placeholder="Key Contact Name"
                                                                    id="cmpContactFirstName"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_contact_first_name"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row>
                                                        <mdb-col col="8">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_primaryPhoneLabel"
                                                                    for="cmpContactPhone"
                                                                    ><b>Primary Phone</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_primaryPhoneInput"
                                                                    placeholder="(555) 555-5555"
                                                                    id="cmpContactPhone"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_contact_phone"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                        <mdb-col col="4">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_extLabel"
                                                                    for="cmpContactExt"
                                                                    ><b>Ext</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_extInput"
                                                                    placeholder="Ext"
                                                                    id="cmpContactExt"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_contact_phone_ext"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                                <mdb-col lg="4" sm="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_lastNameLabel"
                                                                    for="cmpContactLastName"
                                                                    ><b>Last Name</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_lastNameInput"
                                                                    placeholder="Key Contact Name"
                                                                    id="cmpContactLastName"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_contact_last_name"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_emailLabel"
                                                                    for="cmpContactEmail"
                                                                    ><b>Email</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_primaryContact_emailInput"
                                                                    placeholder="Key Contact Email"
                                                                    id="cmpContactEmail"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_contact_email"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                            </mdb-row>
                                        </mdb-col>
                                    </mdb-row>
                                    <hr />
                                    <mdb-row data-test="onboardingTab_custInfoModal_billingInformationTitle">
                                        <mdb-col col="12">
                                            <h4>
                                                Billing Information
                                                <span class="smaller text-muted pl-4"
                                                    >Note: Billing Contact receives Monthly Invoice via Email</span
                                                >
                                                <mdb-icon
                                                    icon="money-bill-alt"
                                                    class="float-right muted"
                                                    size="lg"
                                                ></mdb-icon>
                                            </h4>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row class="pt-4">
                                        <mdb-col col="12">
                                            <mdb-row>
                                                <mdb-col lg="4" sm="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_firstNameLabel"
                                                                    for="cmpBillingFirstName"
                                                                    ><b>First Name</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_firstNameInput"
                                                                    placeholder="Billing Contact Name"
                                                                    id="cmpBillingFirstName"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_first_name"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row class="pt-4">
                                                        <mdb-col col="8">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_primaryPhoneLabel"
                                                                    for="cmpBillingPhone"
                                                                    ><b>Primary Phone</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_primaryPhoneInput"
                                                                    placeholder="(555) 555-5555"
                                                                    id="cmpBillingPhone"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_phone"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                        <mdb-col col="4">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_extLabel"
                                                                    for="cmpBillingExt"
                                                                    ><b>Ext</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_extInput"
                                                                    placeholder="Ext"
                                                                    id="cmpBillingExt"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_phone_ext"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                                <mdb-col lg="4" sm="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_lastNameLabel"
                                                                    for="cmpBillingLastName"
                                                                    ><b>Last Name</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_lastNameInput"
                                                                    placeholder="Billing Contact Name"
                                                                    id="cmpBillingLastName"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_last_name"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row class="pt-4">
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_primaryBillingEmailLabel"
                                                                    for="cmpBillingEmail"
                                                                    ><b>Primary Billing Email</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_primaryBillingEmailInput"
                                                                    placeholder="Company Billing Email"
                                                                    id="cmpBillingEmail"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_email"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                                <mdb-col lg="4" sm="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_invoiceRefPOLabel"
                                                                    for="cmpBillingInvoiceRefPO"
                                                                    ><b>Invoice Ref/PO</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_invoiceRefPOInput"
                                                                    placeholder="ex: PO# 47383478943"
                                                                    id="cmpBillingInvoiceRefPO"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_bill_custref"
                                                                />
                                                                <span
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_invoiceRefPONote"
                                                                    class="smaller text-muted"
                                                                    >Optional: Displayed on Invoice</span
                                                                >
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_billingEmailCCLabel"
                                                                    for="cmpBillingEmailCC"
                                                                    ><b>Billing Email CC</b>
                                                                    <mdb-tooltip
                                                                        data-test="onboardingTab_custInfoModal_billingInformation_billingEmailCCTooltip"
                                                                        material
                                                                        trigger="hover"
                                                                        :options="{ placement: 'right' }"
                                                                    >
                                                                        <span slot="tip"
                                                                            >Separate multiple CC addresses with a comma
                                                                            and no spaces.<br />
                                                                            For example:<br />
                                                                            test@eaudit.com,test2@eaudit.com</span
                                                                        >
                                                                        <mdb-icon
                                                                            slot="reference"
                                                                            class="pl-1"
                                                                            icon="question-circle"
                                                                            color="primary"
                                                                            size="sm"
                                                                        ></mdb-icon>
                                                                    </mdb-tooltip>
                                                                </label>
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_billingEmailCCInput"
                                                                    placeholder="Company Billing CC Address"
                                                                    id="cmpBillingEmailCC"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_email_cc"
                                                                />
                                                                <span
                                                                    data-test="onboardingTab_custInfoModal_billingInformation_billingEmailCCNote"
                                                                    class="smaller text-muted"
                                                                    >Optional: Billing Email CC</span
                                                                >
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                            </mdb-row>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row data-test="onboardingTab_custInfoModal_billingAddressTitle">
                                        <mdb-col col="12">
                                            <h4>Billing Address</h4>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row>
                                        <mdb-col col="12">
                                            <mdb-row>
                                                <mdb-col lg="4" sm="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_addressLabel"
                                                                    for="cmpBillingAddr1"
                                                                    ><b>Address</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_addressInput"
                                                                    placeholder="Billing Address"
                                                                    id="cmpBillingAddr1"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_addr1"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_cityLabel"
                                                                    for="cmpBillingCity"
                                                                    ><b>City</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_cityInput"
                                                                    placeholder="City"
                                                                    id="cmpBillingCity"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_city"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_postalCodeLabel"
                                                                    for="cmpBillingZip"
                                                                    ><b>Postal Code</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_postalCodeInput"
                                                                    placeholder="Postal Code"
                                                                    id="cmpBillingZip"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_zip"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                                <mdb-col lg="4" sm="12">
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_address2Label"
                                                                    for="cmpBillingAddr2"
                                                                    ><b>Address 2</b></label
                                                                >
                                                                <input
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_address2Input"
                                                                    placeholder="Billing Address 2"
                                                                    id="cmpBillingAddr2"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_addr2"
                                                                />
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_stateProvidenceLabel"
                                                                    for="billingState"
                                                                    ><b>State/Providence</b></label
                                                                >
                                                                <select
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_stateProvidenceSelect"
                                                                    id="billingState"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_state"
                                                                >
                                                                    <option
                                                                        data-test="onboardingTab_custInfoModal_billingAddress_stateProvidenceSelect_pleaseSelectOption"
                                                                        value="null"
                                                                        disabled
                                                                    >
                                                                        Please Select
                                                                    </option>
                                                                    <option
                                                                        data-test="onboardingTab_custInfoModal_billingAddress_stateProvidenceSelect_statesOption"
                                                                        :value="state.value"
                                                                        v-for="(state, index) of states"
                                                                        v-bind:key="index"
                                                                    >
                                                                        {{ state.text }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                    <mdb-row>
                                                        <mdb-col col="12">
                                                            <div class="form-group">
                                                                <label
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_countryLabel"
                                                                    for="billingCountry"
                                                                    ><b>Country</b></label
                                                                >
                                                                <select
                                                                    data-test="onboardingTab_custInfoModal_billingAddress_countrySelect"
                                                                    id="billingCountry"
                                                                    class="form-control"
                                                                    type="text"
                                                                    v-model="custInfo.cmp_billing_country"
                                                                >
                                                                    <option
                                                                        data-test="onboardingTab_custInfoModal_billingAddress_countrySelect_pleaseSelectOption"
                                                                        value="null"
                                                                        disabled
                                                                    >
                                                                        Please Select
                                                                    </option>
                                                                    <option
                                                                        data-test="onboardingTab_custInfoModal_billingAddress_countrySelect_unitedStatesOption"
                                                                        value="us"
                                                                    >
                                                                        United States
                                                                    </option>
                                                                    <option
                                                                        data-test="onboardingTab_custInfoModal_billingAddress_countrySelect_canadaOption"
                                                                        value="ca"
                                                                    >
                                                                        Canada
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </mdb-col>
                                                    </mdb-row>
                                                </mdb-col>
                                            </mdb-row>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn
                                        data-test="onboardingTab_custInfoModal_closeBtn"
                                        outline="primary"
                                        @click.native="customerModal = false"
                                        >Close
                                    </mdb-btn>
                                    <mdb-btn
                                        data-test="onboardingTab_custInfoModal_saveCustomerInfoBtn"
                                        color="primary"
                                        @click.native="saveCustomerInfo"
                                        >Save
                                        <mdb-icon icon="save" class="pl-1"></mdb-icon>
                                    </mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                            <mdb-modal
                                data-test="onboardingTab_contractModal"
                                :show="contractModal"
                                @close="contractModal = false"
                                size="lg"
                                v-if="contractInfo"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title data-test="onboardingTab_contractModalTitle">
                                        <mdb-icon icon="envelope" class="pr-2"></mdb-icon>
                                        2. Send Contract
                                    </mdb-modal-title>
                                </mdb-modal-header>
                                <mdb-modal-body>
                                    <mdb-row>
                                        <mdb-col data-test="onboardingTab_contractModalNotes" lg="8">
                                            <p>
                                                Use this form to email the customer the "Audit Services Agreement." Edit
                                                the text of the email if necessary.
                                            </p>
                                            <br />
                                            <p>Note: Do <b>NOT</b> edit "Audit Services Agreement" link.</p>
                                        </mdb-col>
                                        <mdb-col lg="4">
                                            <mdb-btn
                                                data-test="onboardingTab_contractModal_uploadSignedASABtn"
                                                color="primary"
                                                disabled
                                                >Upload Signed ASA
                                                <mdb-icon icon="upload" class="pl-2"></mdb-icon>
                                            </mdb-btn>
                                        </mdb-col>
                                    </mdb-row>
                                    <hr />
                                    <mdb-row>
                                        <mdb-col lg="4">
                                            <div class="form-group">
                                                <label
                                                    data-test="onboardingTab_contractModal_fromLabel"
                                                    for="sendContractFrom"
                                                    ><b>From:</b></label
                                                >
                                                <input
                                                    data-test="onboardingTab_contractModal_fromInput"
                                                    class="form-control"
                                                    type="text"
                                                    id="sendContractFrom"
                                                    placeholder="no-reply@eaudit.com"
                                                    disabled
                                                />
                                            </div>
                                        </mdb-col>
                                        <mdb-col lg="4">
                                            <div class="form-group">
                                                <label
                                                    data-test="onboardingTab_contractModal_toLabel"
                                                    for="sendContractTo"
                                                    ><b>To:</b></label
                                                >
                                                <input
                                                    data-test="onboardingTab_contractModal_toInput"
                                                    type="email"
                                                    id="sendContractTo"
                                                    class="form-control"
                                                    v-model="contractInfo.to"
                                                />
                                            </div>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row>
                                        <mdb-col col="12">
                                            <div class="form-group">
                                                <label
                                                    data-test="onboardingTab_contractModal_bodyLabel"
                                                    for="contractBody"
                                                    >Body:
                                                </label>
                                                <div
                                                    data-test="onboardingTab_contractModal_body"
                                                    contenteditable="true"
                                                    class="form-control"
                                                    id="contractBody"
                                                    style="height: 200px"
                                                    v-html="contractInfo.body"
                                                ></div>
                                            </div>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn
                                        data-test="onboardingTab_contractModal_closeBtn"
                                        outline="primary"
                                        @click.native="contractModal = false"
                                        >Close
                                    </mdb-btn>
                                    <mdb-btn
                                        data-test="onboardingTab_contractModal_sendContractBtn"
                                        color="primary"
                                        @click.native="sendContract"
                                        >Send
                                        <mdb-icon icon="envelope" class="pl-2"></mdb-icon>
                                    </mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                            <CarrierCredentials
                                @push-to-credentials-info="pushToCredentialsInfo"
                                @close-credentials-modal="credentialsModal = false"
                                :selectedRow="selectedRow"
                                :credentialsInfo="credentialsInfo"
                                :resellerFtpCredentials="resellerFtpCredentials"
                                :companyFtpCredentials="companyFtpCredentials"
                                :credsInfo="credsInfo"
                                :credentialsModal="credentialsModal"
                                :headers="headers"
                                :onboardingEndpoints="true"
                                class="ml-1"
                            />
                            <mdb-modal
                                data-test="onboardingTab_accountsModal"
                                :show="accountsModal"
                                @close="closeAccountsModal"
                                size="lg"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title data-test="onboardingTab_accountsModalTitle">
                                        <mdb-icon icon="truck" class="pr-2"></mdb-icon>
                                        Accounts
                                    </mdb-modal-title>
                                </mdb-modal-header>
                                <mdb-modal-body>
                                    <mdb-row>
                                        <mdb-col col="4">
                                            <mdb-btn
                                                data-test="onboardingTab_accountsModal_carrierAccountsAddBtn"
                                                color="info"
                                                @click.native="carrierAccountsAdd"
                                                >Add Account
                                                <mdb-icon icon="plus" class="pl-2"></mdb-icon>
                                            </mdb-btn>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row v-if="showAccountsTable || newCustAccountInfo">
                                        <mdb-col col="12">
                                            <mdb-tbl data-test="onboardingTab_accountsModal_table">
                                                <mdb-tbl-head data-test="onboardingTab_accountsModal_tableHead">
                                                    <tr>
                                                        <th
                                                            data-test="onboardingTab_accountsModal_tableHead_accountNumber"
                                                        >
                                                            Account Number
                                                        </th>
                                                        <th
                                                            data-test="onboardingTab_accountsModal_tableHead_credential"
                                                        >
                                                            Credential
                                                        </th>
                                                        <th data-test="onboardingTab_accountsModal_tableHead_mnsDelay">
                                                            MNS Delay
                                                        </th>
                                                        <th data-test="onboardingTab_accountsModal_tableHead_npodDelay">
                                                            NPOD Delay
                                                        </th>
                                                        <th data-test="onboardingTab_accountsModal_tableHead_noAudit">
                                                            No Audit
                                                        </th>
                                                        <th data-test="onboardingTab_accountsModal_tableHead_gsrWaiver">
                                                            GSR Waiver
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                    <tr
                                                        data-test="onboardingTab_accountsModal_table_carrierAccounts"
                                                        v-for="(carrier, index) of carrierAccounts"
                                                        v-bind:key="index"
                                                    >
                                                        <td>
                                                            <div class="form-group">
                                                                <input
                                                                    data-test="onboardingTab_accountsModal_table_carrierAccountsNum"
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="Accounts Number"
                                                                    v-model="carrier.acct_number"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <select
                                                                data-test="onboardingTab_accountsModal_table_selectedAccountsCred"
                                                                :id="'carrierAccountsCred' + index"
                                                                class="browser-default custom-select"
                                                                v-model="carrier.acct_credential_id"
                                                            >
                                                                <option
                                                                    data-test="onboardingTab_accountsModal_table_selectedAccountsCred_selectCredentialOption"
                                                                    disabled
                                                                    value=""
                                                                >
                                                                    Select Credential
                                                                </option>
                                                                <option
                                                                    data-test="onboardingTab_accountsModal_table_selectedAccountsCred_carrierAccountsInfoOption"
                                                                    v-for="cred of carrierAccountsInfo"
                                                                    v-bind:key="cred.credential_id"
                                                                    :value="cred.credential_id"
                                                                >
                                                                    {{ cred.credential_username }}
                                                                    ({{
                                                                        carriers[String(cred.credential_car_id)].name
                                                                    }}
                                                                    Cred ID: {{ cred.credential_id }})
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">
                                                                <input
                                                                    data-test="onboardingTab_accountsModal_table_carrierAccountsMNS"
                                                                    :id="'carrierAccountsMNS' + index"
                                                                    type="text"
                                                                    class="form-control"
                                                                    v-model="carrier.acct_mns_delay_days"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">
                                                                <input
                                                                    data-test="onboardingTab_accountsModal_table_carrierAccountsNPOD"
                                                                    :id="'carrierAccountsNPOD' + index"
                                                                    type="text"
                                                                    class="form-control"
                                                                    v-model="carrier.acct_npod_delay_days"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    data-test="onboardingTab_accountsModal_table_carrierAccountsNoAuditInput"
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    :id="'carrierAccountsNoAudit' + index"
                                                                    v-model="carrier.acct_no_audit"
                                                                />
                                                                <label
                                                                    data-test="onboardingTab_accountsModal_table_carrierAccountsNoAuditLabel"
                                                                    class="custom-control-label"
                                                                    :for="'carrierAccountsNoAudit' + index"
                                                                ></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    data-test="onboardingTab_accountsModal_table_carrierAccountsGSRInput"
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    :id="'carrierAccountsGSR' + index"
                                                                    v-model="carrier.acct_is_gsr_waiver"
                                                                />
                                                                <label
                                                                    data-test="onboardingTab_accountsModal_table_carrierAccountsGSRLabel"
                                                                    class="custom-control-label"
                                                                    :for="'carrierAccountsGSR' + index"
                                                                ></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <mdb-btn
                                                                data-test="onboardingTab_accountsModal_table_deleteAccountBtn"
                                                                tag="a"
                                                                size="sm"
                                                                floating
                                                                slot="reference"
                                                                color="danger"
                                                                @click.native="deleteAccountModal(index)"
                                                            >
                                                                <mdb-icon icon="minus" size="sm"></mdb-icon>
                                                            </mdb-btn>
                                                        </td>
                                                    </tr>
                                                </mdb-tbl-head>
                                            </mdb-tbl>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn
                                        data-test="onboardingTab_accountsModal_closeAccountsModalBtn"
                                        outline="primary"
                                        @click.native="closeAccountsModal"
                                        >Close
                                    </mdb-btn>
                                    <mdb-btn
                                        data-test="onboardingTab_accountsModal_saveAccountsBtn"
                                        color="primary"
                                        @click.native="saveAccounts"
                                        >Save
                                        <mdb-icon icon="save" class="pl-2"></mdb-icon>
                                    </mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                            <mdb-modal
                                data-test="onboardingTab_manualAddModal"
                                :show="manualAddModal"
                                @close="manualAddModal = false"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title data-test="onboardingTab_manualAddModalTitle">
                                        <mdb-icon class="pr-2" icon="plus"></mdb-icon>
                                        Manual Add Customer
                                    </mdb-modal-title>
                                </mdb-modal-header>
                                <mdb-modal-body>
                                    <h6 data-test="onboardingTab_manualAddModal_addCustomerTitle">
                                        Add Customer
                                        <mdb-icon icon="building" class="pr-2 float-right"></mdb-icon>
                                    </h6>
                                    <mdb-row>
                                        <mdb-col col="12">
                                            <mdb-input
                                                data-test="onboardingTab_manualAddModal_manualAddCompany"
                                                label="Company Name"
                                                v-model="manualAddCompany"
                                            ></mdb-input>
                                        </mdb-col>
                                    </mdb-row>
                                    <hr />
                                    <h6>
                                        Service Level
                                        <mdb-tooltip material trigger="hover" :options="{ placement: 'right' }">
                                            <span slot="tip"
                                                >If 'Download Only' is selected, this company will not receive any
                                                auditing, reporting, or GL coding services. The only thing that will
                                                happen is their carrier invoices will be downloaded.</span
                                            >
                                            <mdb-icon
                                                slot="reference"
                                                class="pl-1"
                                                icon="question-circle"
                                                color="primary"
                                                size="sm"
                                            ></mdb-icon>
                                        </mdb-tooltip>
                                        <mdb-icon icon="user-tie" class="pr-2 float-right"></mdb-icon>
                                    </h6>
                                    <mdb-row>
                                        <mdb-input
                                            type="checkbox"
                                            label="Download Only"
                                            v-model="downloadOnly"
                                            @click.native="toggleDownloadOnly"
                                        >
                                        </mdb-input>
                                    </mdb-row>
                                    <hr />
                                    <h6 data-test="onboardingTab_manualAddModal_initialUserLoginTitle">
                                        Initial User Login
                                        <mdb-tooltip material trigger="hover" :options="{ placement: 'right' }">
                                            <span slot="tip"
                                                >Adding a user is optional. A user can always be added later.</span
                                            >
                                            <mdb-icon
                                                slot="reference"
                                                class="pl-1"
                                                icon="question-circle"
                                                color="primary"
                                                size="sm"
                                            ></mdb-icon>
                                        </mdb-tooltip>
                                        <mdb-icon icon="user" class="pr-2 float-right"></mdb-icon>
                                    </h6>
                                    <mdb-input
                                        data-test="onboardingTab_manualAddModal_manualAddFirstName"
                                        label="User First Name"
                                        v-model="manualAddFirstName"
                                    ></mdb-input>
                                    <mdb-input
                                        data-test="onboardingTab_manualAddModal_manualAddLastName"
                                        label="User Last Name"
                                        v-model="manualAddLastName"
                                    ></mdb-input>
                                    <mdb-input
                                        data-test="onboardingTab_manualAddModal_manualAddPhone"
                                        label="User Phone Number"
                                        v-model="manualAddPhone"
                                    ></mdb-input>
                                    <mdb-input
                                        data-test="onboardingTab_manualAddModal_manualAddEmail"
                                        label="User Email Address (UserID)"
                                        v-model="manualAddEmail"
                                    ></mdb-input>
                                    <mdb-row class="w-100">
                                        <mdb-col col="1">
                                            <a class="btn-lg pl-1" @click="generatePassword('manualAdd')">
                                                <mdb-icon icon="key" color="primary"></mdb-icon>
                                            </a>
                                        </mdb-col>
                                        <mdb-col col="11">
                                            <mdb-input
                                                data-test="onboardingTab_manualAddModal_manualAddPassword"
                                                class="mt-0"
                                                label="User Password"
                                                v-model="manualAddPassword"
                                            ></mdb-input>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn
                                        data-test="onboardingTab_manualAddModalCloseBtn"
                                        outline="primary"
                                        @click.native="manualAddModal = false"
                                        >Close
                                    </mdb-btn>
                                    <mdb-btn
                                        data-test="onboardingTab_manualAddModal_manualAddUserBtn"
                                        v-if="manualAddCompany.length > 0"
                                        color="primary"
                                        @click.native="manualAddUser"
                                        >Add
                                        <mdb-icon icon="plus" class="pl-2"></mdb-icon>
                                    </mdb-btn>
                                    <mdb-btn
                                        data-test="onboardingTab_manualAddModal_manualAddUserBtn_disabled"
                                        v-else
                                        disabled
                                        color="primary"
                                        >Add
                                        <mdb-icon icon="plus" class="pl-2"></mdb-icon>
                                    </mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                            <mdb-modal :show="activateModal" @close="activateModal = false">
                                <mdb-modal-header>
                                    <mdb-modal-title>
                                        <mdb-icon icon="truck" class="pr-2"></mdb-icon>
                                        Accounting Information
                                    </mdb-modal-title>
                                </mdb-modal-header>
                                <mdb-modal-body>
                                    <mdb-row>
                                        <mdb-col col="12">
                                            <div class="form-group">
                                                <label for="clientID">Common Client ID</label>
                                                <input
                                                    v-model="clientID"
                                                    type="text"
                                                    id="clientID"
                                                    class="form-control"
                                                    placeholder="T/F######"
                                                />
                                                <small class="form-text text-muted">
                                                    Required. Please make sure you type this in correctly!
                                                </small>
                                            </div>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row>
                                        <mdb-col col="12">
                                            <div class="form-group">
                                                <label for="intacctID">Intacct ID</label>
                                                <input
                                                    v-model="intacctID"
                                                    type="text"
                                                    id="intacctID"
                                                    class="form-control"
                                                    placeholder="C-######"
                                                />
                                                <small class="form-text text-muted">
                                                    Required for TransImpact companies.
                                                </small>
                                            </div>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn outline="primary" @click.native="activateModal = false">Close</mdb-btn>
                                    <mdb-btn
                                        v-if="clientID && clientID.length > 0"
                                        color="danger"
                                        @click.native="activateAccount"
                                        >Activate!
                                        <mdb-icon icon="spaceship" class="pl-2"></mdb-icon>
                                    </mdb-btn>
                                    <mdb-btn v-else color="danger" disabled
                                        >Activate!
                                        <mdb-icon icon="rocket" class="pl-2"></mdb-icon>
                                    </mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                            <mdb-modal :show="showDeleteModal" @close="showDeleteModal = false">
                                <mdb-modal-header>
                                    <mdb-modal-title>Delete Account</mdb-modal-title>
                                </mdb-modal-header>
                                <mdb-modal-body>Are you sure you want to delete this account?</mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn outline="primary" @click.native="showDeleteModal = false">Close</mdb-btn>
                                    <mdb-btn color="danger" @click.native="deleteAccount">Delete</mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                        </mdb-tab-pane>
                        <mdb-tab-pane data-test="wruTab" class="fade" key="show2" v-if="active == 1">
                            <vue-good-table
                                data-test="wruTab_vue-good-table"
                                :columns="webRegisteredUsersColumns"
                                :rows="webRegisteredUsersRows"
                                :search-options="{ enabled: true, trigger: 'enter' }"
                                styleClass="vgt-table striped bordered"
                                @on-row-click="onWebRegisteredUserRowClick"
                                mode="remote"
                                @on-page-change="onPageChangeWr"
                                @on-sort-change="onSortChangeWr"
                                @on-per-page-change="onPerPageChangeWr"
                                @on-search="searchTableWr"
                                :totalRows="totalRecordsWr"
                                :pagination-options="{
                                    enabled: true,
                                    mode: 'records',
                                    perPage: this.limitWr,
                                    position: 'top',
                                    perPageDropdown: [10, 25, 50, 100],
                                    dropdownAllowAll: false,
                                    setCurrentPage: this.pageWr,
                                }"
                            ></vue-good-table>
                            <mdb-modal
                                data-test="wruTab_viewDetailsModal"
                                v-if="selectedWebRegisteredUserRow"
                                :show="viewDetailsModal"
                                @close="viewDetailsModal = false"
                                size="lg"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title data-test="wruTab_viewDetailsModalTitle"
                                        >{{ selectedWebRegisteredUserRow.cmp_name }}
                                    </mdb-modal-title>
                                </mdb-modal-header>
                                <mdb-modal-body>
                                    <mdb-card data-test="wruTab_viewDetailsModal_userInformationCard">
                                        <mdb-card-header data-test="wruTab_viewDetailsModal_userInformationCardHeader"
                                            >User Information
                                        </mdb-card-header>
                                        <mdb-card-body>
                                            <mdb-row>
                                                <mdb-col col="6">
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_userInformationCard_firstName"
                                                        >First Name:
                                                        {{ selectedWebRegisteredUserRow.usr_first_name }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_userInformationCard_phoneNumber"
                                                        >Phone Number:
                                                        {{ selectedWebRegisteredUserRow.usr_phone }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_userInformationCard_lastLoginDate"
                                                        >Last Login Date:
                                                        {{ selectedWebRegisteredUserRow.usr_last_login_date }}
                                                    </mdb-row>
                                                </mdb-col>
                                                <mdb-col col="6">
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_userInformationCard_lastName"
                                                        >Last Name:
                                                        {{ selectedWebRegisteredUserRow.usr_last_name }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_userInformationCard_emailAddress"
                                                        >Email Address:
                                                        {{ selectedWebRegisteredUserRow.usr_email }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_userInformationCard_registerDate"
                                                        >Register Date:
                                                        {{ selectedWebRegisteredUserRow.usr_created_date }}
                                                    </mdb-row>
                                                </mdb-col>
                                            </mdb-row>
                                        </mdb-card-body>
                                    </mdb-card>
                                    <mdb-card data-test="wruTab_viewDetailsModal_billingInformationCard">
                                        <mdb-card-header
                                            data-test="wruTab_viewDetailsModal_billingInformationCardHeader"
                                            >Billing Information
                                        </mdb-card-header>
                                        <mdb-card-body>
                                            <mdb-row>
                                                <mdb-col col="6">
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_firstName"
                                                        >First Name:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_first_name }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_emailAddress"
                                                        >Email Address:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_email }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_streetAddress"
                                                        >Street Address:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_addr1 }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_city"
                                                        >City:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_city }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_zipCode"
                                                        >Zip Code:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_zip }}
                                                    </mdb-row>
                                                </mdb-col>
                                                <mdb-col col="6">
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_lastName"
                                                        >Last Name:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_last_name }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_phoneNumber"
                                                        >Phone Number:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_phone }}
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_phone_ext }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_address2"
                                                        >Address 2:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_addr2 }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_state"
                                                        >State:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_state }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_billingInformationCard_country"
                                                        >Country:
                                                        {{ selectedWebRegisteredUserRow.cmp_billing_country }}
                                                    </mdb-row>
                                                </mdb-col>
                                            </mdb-row>
                                        </mdb-card-body>
                                    </mdb-card>
                                    <mdb-card data-test="wruTab_viewDetailsModal_contactInformationCard">
                                        <mdb-card-header
                                            data-test="wruTab_viewDetailsModal_contactInformationCardHeader"
                                            >Contact Information
                                        </mdb-card-header>
                                        <mdb-card-body>
                                            <mdb-row>
                                                <mdb-col col="6">
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_contactInformationCard_firstName"
                                                        >First Name:
                                                        {{ selectedWebRegisteredUserRow.cmp_contact_first_name }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        class="ml-1"
                                                        data-test="wruTab_viewDetailsModal_contactInformationCard_phoneNumber"
                                                        >Phone Number:
                                                        {{ selectedWebRegisteredUserRow.cmp_contact_phone }}
                                                        {{ selectedWebRegisteredUserRow.cmp_contact_phone_ext }}
                                                    </mdb-row>
                                                </mdb-col>
                                                <mdb-col col="6">
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_contactInformationCard_lastName"
                                                        >Last Name:
                                                        {{ selectedWebRegisteredUserRow.cmp_contact_last_name }}
                                                    </mdb-row>
                                                    <mdb-row
                                                        data-test="wruTab_viewDetailsModal_contactInformationCard_emailAddress"
                                                        >Email Address:
                                                        {{ selectedWebRegisteredUserRow.cmp_contact_email }}
                                                    </mdb-row>
                                                </mdb-col>
                                            </mdb-row>
                                        </mdb-card-body>
                                    </mdb-card>
                                    <mdb-card data-test="wruTab_viewDetailsModal_carrierCredentialsCard">
                                        <mdb-card-header
                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCardHeader"
                                            >Carrier Credentials
                                        </mdb-card-header>
                                        <mdb-card-body>
                                            <mdb-tbl
                                                data-test="wruTab_viewDetailsModal_carrierCredentialsCard_table"
                                                striped
                                            >
                                                <mdb-tbl-head
                                                    data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableHead"
                                                >
                                                    <tr>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableCarrier"
                                                        >
                                                            Carrier
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableType"
                                                        >
                                                            Type
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableCountry"
                                                        >
                                                            Country
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableActive"
                                                        >
                                                            Active
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableUsername"
                                                        >
                                                            Username
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tablePassword"
                                                        >
                                                            Password
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableAPIKey"
                                                        >
                                                            API Key
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableAPIPass"
                                                        >
                                                            API Pass
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableMeter"
                                                        >
                                                            Meter
                                                        </th>
                                                        <th
                                                            data-test="wruTab_viewDetailsModal_carrierCredentialsCard_tableAccount"
                                                        >
                                                            Account
                                                        </th>
                                                    </tr>
                                                </mdb-tbl-head>
                                            </mdb-tbl>
                                        </mdb-card-body>
                                    </mdb-card>
                                </mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn
                                        data-test="wruTab_viewDetailsModalCloseBtn"
                                        outline="primary"
                                        @click.native="viewDetailsModal = false"
                                        >Close
                                    </mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                        </mdb-tab-pane>
                    </mdb-tab-content>
                </mdb-card-body>
            </mdb-card>
        </mdb-row>
    </div>
</template>
<script>
import moment from "moment";
import {
    mdbIcon,
    mdbRow,
    mdbCard,
    mdbCardHeader,
    mdbTab,
    mdbTabItem,
    mdbCardBody,
    mdbTabContent,
    mdbTabPane,
    mdbCol,
    mdbSelect,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbTooltip,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbSpinner,
} from "mdbvue";
import { Carriers } from "../../data/carriers";
import CarrierCredentials from "@/components/CarrierCredentials.vue";

export default {
    name: "AdminOnboardingADMIN",
    components: {
        CarrierCredentials,
        mdbSpinner,
        mdbIcon,
        mdbRow,
        mdbCard,
        mdbCardHeader,
        mdbTab,
        mdbTabItem,
        mdbCardBody,
        mdbTabContent,
        mdbTabPane,
        mdbCol,
        mdbSelect,
        mdbBtn,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbInput,
        mdbTooltip,
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbDropdownItem,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            active: 0,
            resellerListCreated: null,
            resellerList: [],
            resellerOptions: [],
            selectedReseller: null,
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "cmp_start_date",
            sortkey: "cmp_start_date",
            sortDir: "desc",
            totalRecords: null,
            totalRecordsWr: null,
            searchTermWr: null,
            searchTerm: null,
            loading: false,
            limitWr: 25,
            sizeWr: 25,
            pageWr: 1,
            sortColumnWr: "wru_company_spent",
            sortkeyWr: "wru_company_spent",
            sortDirWr: "asc",
            tablePage: 1,
            table: null,
            onboardingColumns: [],
            onboardingRows: [],
            selectedRow: null,
            rowModal: false,
            customerModal: false,
            contractModal: false,
            credentialsModal: false,
            accountsModal: false,
            salespersonInfo: null,
            salespersonOptions: [],
            selectedPartner: null,
            selectedSalesperson: null,
            newCustomerStartDate: null,
            newCustomerLiveDate: null,
            selectedBillingType: null,
            invoiceRefPO: "",
            billingEmailCC: "",
            billingState: "",
            custInfo: null,
            billingCountries: [],
            manualAddModal: false,
            credentialsInfo: [],
            credInfo: [],
            generatedPassword: null,
            showAccountsTable: false,
            carrierAccountsColumns: null,
            carrierAccountsRows: null,
            carrierAccountsInfo: null,
            newCustAccountInfo: null,
            selectedAccountsCred: null,
            carrierAccounts: [],
            carrierAccountsCreds: [],
            carriers: Carriers,
            selectedCarrierAccountsCred: null,
            manualAddFirstName: "",
            manualAddLastName: "",
            manualAddPhone: "",
            manualAddEmail: "",
            manualAddPassword: "",
            manualAddCompany: "",
            downloadOnly: false,
            custInfoPartner: null,
            custInfoType: null,
            contractInfo: null,
            webUserTable: [],
            webRegisteredUsersRows: [],
            webRegisteredUsersColumns: [],
            selectedWebRegisteredUserRow: null,
            viewDetailsModal: false,
            webRegisteredUsers: null,
            custBillTypeVal: null,
            activateModal: false,
            clientID: null,
            intacctID: null,
            isBusy: false,
            toDeleteIndex: null,
            showDeleteModal: false,
            resellerFtpCredentials: null,
            companyFtpCredentials: null,
            credsInfo: null,
            credDownloadType: [
                { value: "website", text: "Website", selected: false },
                { value: "email", text: "Email", selected: false },
                { value: "dropbox", text: "Dropbox", selected: false },
                { value: "ftp", text: "FTP", selected: false },
                { value: "company ftp", text: "Company FTP", selected: false },
                { value: "reseller ftp", text: "Reseller FTP", selected: false },
            ],
            credentialsCountries: [
                { value: "us", text: "US", selected: false },
                { value: "ca", text: "Canada", selected: false },
                { value: "de", text: "Germany", selected: false },
            ],
            carriersText: [
                { value: 1, text: "Add FedEx Credential", selected: false },
                { value: 2, text: "Add UPS Credential", selected: false },
                { value: 3, text: "Add DHL Express Credential", selected: false },
                { value: 4, text: "Add Purolator Credential", selected: false },
                { value: 5, text: "Add DHL eCom Credential", selected: false },
            ],
            countries: [
                { text: "Afghanistan", value: "AF", selected: false },
                { text: "Aland Islands", value: "AX", selected: false },
                { text: "Albania", value: "AL", selected: false },
                { text: "Algeria", value: "DZ", selected: false },
                { text: "American Samoa", value: "AS", selected: false },
                { text: "AndorrA", value: "AD", selected: false },
                { text: "Angola", value: "AO", selected: false },
                { text: "Anguilla", value: "AI", selected: false },
                { text: "Antarctica", value: "AQ", selected: false },
                { text: "Antigua and Barbuda", value: "AG", selected: false },
                { text: "Argentina", value: "AR", selected: false },
                { text: "Armenia", value: "AM", selected: false },
                { text: "Aruba", value: "AW", selected: false },
                { text: "Australia", value: "AU", selected: false },
                { text: "Austria", value: "AT", selected: false },
                { text: "Azerbaijan", value: "AZ", selected: false },
                { text: "Bahamas", value: "BS", selected: false },
                { text: "Bahrain", value: "BH", selected: false },
                { text: "Bangladesh", value: "BD", selected: false },
                { text: "Barbados", value: "BB", selected: false },
                { text: "Belarus", value: "BY", selected: false },
                { text: "Belgium", value: "BE", selected: false },
                { text: "Belize", value: "BZ", selected: false },
                { text: "Benin", value: "BJ", selected: false },
                { text: "Bermuda", value: "BM", selected: false },
                { text: "Bhutan", value: "BT", selected: false },
                { text: "Bolivia", value: "BO", selected: false },
                { text: "Bosnia and Herzegovina", value: "BA", selected: false },
                { text: "Botswana", value: "BW", selected: false },
                { text: "Bouvet Island", value: "BV", selected: false },
                { text: "Brazil", value: "BR", selected: false },
                { text: "British Indian Ocean Territory", value: "IO", selected: false },
                { text: "Brunei Darussalam", value: "BN", selected: false },
                { text: "Bulgaria", value: "BG", selected: false },
                { text: "Burkina Faso", value: "BF", selected: false },
                { text: "Burundi", value: "BI", selected: false },
                { text: "Cambodia", value: "KH", selected: false },
                { text: "Cameroon", value: "CM", selected: false },
                { text: "Canada", value: "CA", selected: false },
                { text: "Cape Verde", value: "CV", selected: false },
                { text: "Cayman Islands", value: "KY", selected: false },
                { text: "Central African Republic", value: "CF", selected: false },
                { text: "Chad", value: "TD", selected: false },
                { text: "Chile", value: "CL", selected: false },
                { text: "China", value: "CN", selected: false },
                { text: "Christmas Island", value: "CX", selected: false },
                { text: "Cocos (Keeling) Islands", value: "CC", selected: false },
                { text: "Colombia", value: "CO", selected: false },
                { text: "Comoros", value: "KM", selected: false },
                { text: "Congo", value: "CG", selected: false },
                { text: "Congo, The Democratic Republic of the", value: "CD", selected: false },
                { text: "Cook Islands", value: "CK", selected: false },
                { text: "Costa Rica", value: "CR", selected: false },
                { text: "Cote D'Ivoire", value: "CI", selected: false },
                { text: "Croatia", value: "HR", selected: false },
                { text: "Cuba", value: "CU", selected: false },
                { text: "Cyprus", value: "CY", selected: false },
                { text: "Czech Republic", value: "CZ", selected: false },
                { text: "Denmark", value: "DK", selected: false },
                { text: "Djibouti", value: "DJ", selected: false },
                { text: "Dominica", value: "DM", selected: false },
                { text: "Dominican Republic", value: "DO", selected: false },
                { text: "Ecuador", value: "EC", selected: false },
                { text: "Egypt", value: "EG", selected: false },
                { text: "El Salvador", value: "SV", selected: false },
                { text: "Equatorial Guinea", value: "GQ", selected: false },
                { text: "Eritrea", value: "ER", selected: false },
                { text: "Estonia", value: "EE", selected: false },
                { text: "Ethiopia", value: "ET", selected: false },
                { text: "Falkland Islands (Malvinas)", value: "FK", selected: false },
                { text: "Faroe Islands", value: "FO", selected: false },
                { text: "Fiji", value: "FJ", selected: false },
                { text: "Finland", value: "FI", selected: false },
                { text: "France", value: "FR", selected: false },
                { text: "French Guiana", value: "GF", selected: false },
                { text: "French Polynesia", value: "PF", selected: false },
                { text: "French Southern Territories", value: "TF", selected: false },
                { text: "Gabon", value: "GA", selected: false },
                { text: "Gambia", value: "GM", selected: false },
                { text: "Georgia", value: "GE", selected: false },
                { text: "Germany", value: "DE", selected: false },
                { text: "Ghana", value: "GH", selected: false },
                { text: "Gibraltar", value: "GI", selected: false },
                { text: "Greece", value: "GR", selected: false },
                { text: "Greenland", value: "GL", selected: false },
                { text: "Grenada", value: "GD", selected: false },
                { text: "Guadeloupe", value: "GP", selected: false },
                { text: "Guam", value: "GU", selected: false },
                { text: "Guatemala", value: "GT", selected: false },
                { text: "Guernsey", value: "GG", selected: false },
                { text: "Guinea", value: "GN", selected: false },
                { text: "Guinea-Bissau", value: "GW", selected: false },
                { text: "Guyana", value: "GY", selected: false },
                { text: "Haiti", value: "HT", selected: false },
                { text: "Heard Island and Mcdonald Islands", value: "HM", selected: false },
                { text: "Holy See (Vatican City State)", value: "VA", selected: false },
                { text: "Honduras", value: "HN", selected: false },
                { text: "Hong Kong", value: "HK", selected: false },
                { text: "Hungary", value: "HU", selected: false },
                { text: "Iceland", value: "IS", selected: false },
                { text: "India", value: "IN", selected: false },
                { text: "Indonesia", value: "ID", selected: false },
                { text: "Iran, Islamic Republic Of", value: "IR", selected: false },
                { text: "Iraq", value: "IQ", selected: false },
                { text: "Ireland", value: "IE", selected: false },
                { text: "Isle of Man", value: "IM", selected: false },
                { text: "Israel", value: "IL", selected: false },
                { text: "Italy", value: "IT", selected: false },
                { text: "Jamaica", value: "JM", selected: false },
                { text: "Japan", value: "JP", selected: false },
                { text: "Jersey", value: "JE", selected: false },
                { text: "Jordan", value: "JO", selected: false },
                { text: "Kazakhstan", value: "KZ", selected: false },
                { text: "Kenya", value: "KE", selected: false },
                { text: "Kiribati", value: "KI", selected: false },
                { text: "Korea, Democratic People'S Republic of", value: "KP", selected: false },
                { text: "Korea, Republic of", value: "KR", selected: false },
                { text: "Kuwait", value: "KW", selected: false },
                { text: "Kyrgyzstan", value: "KG", selected: false },
                { text: "Lao People'S Democratic Republic", value: "LA", selected: false },
                { text: "Latvia", value: "LV", selected: false },
                { text: "Lebanon", value: "LB", selected: false },
                { text: "Lesotho", value: "LS", selected: false },
                { text: "Liberia", value: "LR", selected: false },
                { text: "Libyan Arab Jamahiriya", value: "LY", selected: false },
                { text: "Liechtenstein", value: "LI", selected: false },
                { text: "Lithuania", value: "LT", selected: false },
                { text: "Luxembourg", value: "LU", selected: false },
                { text: "Macao", value: "MO", selected: false },
                { text: "Macedonia, The Former Yugoslav Republic of", value: "MK", selected: false },
                { text: "Madagascar", value: "MG", selected: false },
                { text: "Malawi", value: "MW", selected: false },
                { text: "Malaysia", value: "MY", selected: false },
                { text: "Maldives", value: "MV", selected: false },
                { text: "Mali", value: "ML", selected: false },
                { text: "Malta", value: "MT", selected: false },
                { text: "Marshall Islands", value: "MH", selected: false },
                { text: "Martinique", value: "MQ", selected: false },
                { text: "Mauritania", value: "MR", selected: false },
                { text: "Mauritius", value: "MU", selected: false },
                { text: "Mayotte", value: "YT", selected: false },
                { text: "Mexico", value: "MX", selected: false },
                { text: "Micronesia, Federated States of", value: "FM", selected: false },
                { text: "Moldova, Republic of", value: "MD", selected: false },
                { text: "Monaco", value: "MC", selected: false },
                { text: "Mongolia", value: "MN", selected: false },
                { text: "Montserrat", value: "MS", selected: false },
                { text: "Morocco", value: "MA", selected: false },
                { text: "Mozambique", value: "MZ", selected: false },
                { text: "Myanmar", value: "MM", selected: false },
                { text: "Namibia", value: "NA", selected: false },
                { text: "Nauru", value: "NR", selected: false },
                { text: "Nepal", value: "NP", selected: false },
                { text: "Netherlands", value: "NL", selected: false },
                { text: "Netherlands Antilles", value: "AN", selected: false },
                { text: "New Caledonia", value: "NC", selected: false },
                { text: "New Zealand", value: "NZ", selected: false },
                { text: "Nicaragua", value: "NI", selected: false },
                { text: "Niger", value: "NE", selected: false },
                { text: "Nigeria", value: "NG", selected: false },
                { text: "Niue", value: "NU", selected: false },
                { text: "Norfolk Island", value: "NF", selected: false },
                { text: "Northern Mariana Islands", value: "MP", selected: false },
                { text: "Norway", value: "NO", selected: false },
                { text: "Oman", value: "OM", selected: false },
                { text: "Pakistan", value: "PK", selected: false },
                { text: "Palau", value: "PW", selected: false },
                { text: "Palestinian Territory, Occupied", value: "PS", selected: false },
                { text: "Panama", value: "PA", selected: false },
                { text: "Papua New Guinea", value: "PG", selected: false },
                { text: "Paraguay", value: "PY", selected: false },
                { text: "Peru", value: "PE", selected: false },
                { text: "Philippines", value: "PH", selected: false },
                { text: "Pitcairn", value: "PN", selected: false },
                { text: "Poland", value: "PL", selected: false },
                { text: "Portugal", value: "PT", selected: false },
                { text: "Puerto Rico", value: "PR", selected: false },
                { text: "Qatar", value: "QA", selected: false },
                { text: "Reunion", value: "RE", selected: false },
                { text: "Romania", value: "RO", selected: false },
                { text: "Russian Federation", value: "RU", selected: false },
                { text: "RWANDA", value: "RW", selected: false },
                { text: "Saint Helena", value: "SH", selected: false },
                { text: "Saint Kitts and Nevis", value: "KN", selected: false },
                { text: "Saint Lucia", value: "LC", selected: false },
                { text: "Saint Pierre and Miquelon", value: "PM", selected: false },
                { text: "Saint Vincent and the Grenadines", value: "VC", selected: false },
                { text: "Samoa", value: "WS", selected: false },
                { text: "San Marino", value: "SM", selected: false },
                { text: "Sao Tome and Principe", value: "ST", selected: false },
                { text: "Saudi Arabia", value: "SA", selected: false },
                { text: "Senegal", value: "SN", selected: false },
                { text: "Serbia and Montenegro", value: "CS", selected: false },
                { text: "Seychelles", value: "SC", selected: false },
                { text: "Sierra Leone", value: "SL", selected: false },
                { text: "Singapore", value: "SG", selected: false },
                { text: "Slovakia", value: "SK", selected: false },
                { text: "Slovenia", value: "SI", selected: false },
                { text: "Solomon Islands", value: "SB", selected: false },
                { text: "Somalia", value: "SO", selected: false },
                { text: "South Africa", value: "ZA", selected: false },
                { text: "South Georgia and the South Sandwich Islands", value: "GS", selected: false },
                { text: "Spain", value: "ES", selected: false },
                { text: "Sri Lanka", value: "LK", selected: false },
                { text: "Sudan", value: "SD", selected: false },
                { text: "Suriname", value: "SR", selected: false },
                { text: "Svalbard and Jan Mayen", value: "SJ", selected: false },
                { text: "Swaziland", value: "SZ", selected: false },
                { text: "Sweden", value: "SE", selected: false },
                { text: "Switzerland", value: "CH", selected: false },
                { text: "Syrian Arab Republic", value: "SY", selected: false },
                { text: "Taiwan, Province of China", value: "TW", selected: false },
                { text: "Tajikistan", value: "TJ", selected: false },
                { text: "Tanzania, United Republic of", value: "TZ", selected: false },
                { text: "Thailand", value: "TH", selected: false },
                { text: "Timor-Leste", value: "TL", selected: false },
                { text: "Togo", value: "TG", selected: false },
                { text: "Tokelau", value: "TK", selected: false },
                { text: "Tonga", value: "TO", selected: false },
                { text: "Trinidad and Tobago", value: "TT", selected: false },
                { text: "Tunisia", value: "TN", selected: false },
                { text: "Turkey", value: "TR", selected: false },
                { text: "Turkmenistan", value: "TM", selected: false },
                { text: "Turks and Caicos Islands", value: "TC", selected: false },
                { text: "Tuvalu", value: "TV", selected: false },
                { text: "Uganda", value: "UG", selected: false },
                { text: "Ukraine", value: "UA", selected: false },
                { text: "United Arab Emirates", value: "AE", selected: false },
                { text: "United Kingdom", value: "GB", selected: false },
                { text: "United States", value: "US", selected: false },
                { text: "United States Minor Outlying Islands", value: "UM", selected: false },
                { text: "Uruguay", value: "UY", selected: false },
                { text: "Uzbekistan", value: "UZ", selected: false },
                { text: "Vanuatu", value: "VU", selected: false },
                { text: "Venezuela", value: "VE", selected: false },
                { text: "Viet Nam", value: "VN", selected: false },
                { text: "Virgin Islands, British", value: "VG", selected: false },
                { text: "Virgin Islands, U.S.", value: "VI", selected: false },
                { text: "Wallis and Futuna", value: "WF", selected: false },
                { text: "Western Sahara", value: "EH", selected: false },
                { text: "Yemen", value: "YE", selected: false },
                { text: "Zambia", value: "ZM", selected: false },
                { text: "Zimbabwe", value: "ZW", selected: false },
            ],
            states: [
                { value: "AL", text: "Alabama", selected: false },
                { value: "AK", text: "Alaska", selected: false },
                { value: "AB", text: "Alberta", selected: false },
                { value: "AZ", text: "Arizona", selected: false },
                { value: "AR", text: "Arkansas", selected: false },
                { value: "BC", text: "British Columbia", selected: false },
                { value: "CA", text: "California", selected: false },
                { value: "CO", text: "Colorado", selected: false },
                { value: "CT", text: "Connecticut", selected: false },
                { value: "DE", text: "Delaware", selected: false },
                { value: "DC", text: "District of Columbia", selected: false },
                { value: "FL", text: "Florida", selected: false },
                { value: "GA", text: "Georgia", selected: false },
                { value: "HI", text: "Hawaii", selected: false },
                { value: "ID", text: "Idaho", selected: false },
                { value: "IL", text: "Illinois", selected: false },
                { value: "IN", text: "Indiana", selected: false },
                { value: "IA", text: "Iowa", selected: false },
                { value: "KS", text: "Kansas", selected: false },
                { value: "KY", text: "Kentucky", selected: false },
                { value: "LA", text: "Louisiana", selected: false },
                { value: "ME", text: "Maine", selected: false },
                { value: "MB", text: "Manitoba", selected: false },
                { value: "MD", text: "Maryland", selected: false },
                { value: "MA", text: "Massachusetts", selected: false },
                { value: "MI", text: "Michigan", selected: false },
                { value: "MN", text: "Minnesota", selected: false },
                { value: "MS", text: "Mississippi", selected: false },
                { value: "MO", text: "Missouri", selected: false },
                { value: "MT", text: "Montana", selected: false },
                { value: "NE", text: "Nebraska", selected: false },
                { value: "NV", text: "Nevada", selected: false },
                { value: "NB", text: "New Brunswick", selected: false },
                { value: "NH", text: "New Hampshire", selected: false },
                { value: "NJ", text: "New Jersey", selected: false },
                { value: "NM", text: "New Mexico", selected: false },
                { value: "NY", text: "New York", selected: false },
                { value: "NL", text: "Newfoundland and Labrador", selected: false },
                { value: "NC", text: "North Carolina", selected: false },
                { value: "ND", text: "North Dakota", selected: false },
                { value: "NT", text: "Northwest Territories", selected: false },
                { value: "NS", text: "Nova Scotia", selected: false },
                { value: "NU", text: "Nunavut", selected: false },
                { value: "OH", text: "Ohio", selected: false },
                { value: "OK", text: "Oklahoma", selected: false },
                { value: "ON", text: "Ontario", selected: false },
                { value: "OR", text: "Oregon", selected: false },
                { value: "PA", text: "Pennsylvania", selected: false },
                { value: "PE", text: "Prince Edward Island", selected: false },
                { value: "PR", text: "Puerto Rico", selected: false },
                { value: "QC", text: "Quebec", selected: false },
                { value: "RI", text: "Rhode Island", selected: false },
                { value: "SK", text: "Saskatchewan", selected: false },
                { value: "SC", text: "South Carolina", selected: false },
                { value: "SD", text: "South Dakota", selected: false },
                { value: "TN", text: "Tennessee", selected: false },
                { value: "TX", text: "Texas", selected: false },
                { value: "UT", text: "Utah", selected: false },
                { value: "VT", text: "Vermont", selected: false },
                { value: "VA", text: "Virginia", selected: false },
                { value: "WA", text: "Washington", selected: false },
                { value: "WV", text: "West Virginia", selected: false },
                { value: "WI", text: "Wisconsin", selected: false },
                { value: "WY", text: "Wyoming", selected: false },
                { value: "YT", text: "Yukon", selected: false },
            ],
            fedExType: [
                { text: "FDX Regular", value: "none" },
                { text: "FDX Credit Card", value: "cc" },
                { text: "FDX EDI", value: "edi" },
            ],
            upsType: [
                { text: "UPS Billing Center", value: "billing center" },
                { text: "UPS Supply Chain Solution", value: "billing center scs" },
                { text: "UPS EDI", value: "edi" },
                { text: "UPS Billing Data", value: "billing data" },
            ],
            dhlType: [{ text: "DHL Regular", value: "none" }],
            purolatorType: [{ text: "Purolator Regular", value: "none" }],
            dhlexType: [
                { text: "eCom FTP", value: "ftp" },
                { text: "eCom Portal", value: "portal" },
            ],
        };
    },
    methods: {
        async getInit() {
            this.resellerListCreated = await this.getResellers();
            this.tableCreated = await this.createTable();
            this.webRegisteredTableCreated = await this.createWebRegisteredUserTable();
            await this.getCompanyFtpCredentials();
            await this.getResellerFtpCredentials();
        },
        async getResellers() {
            await this.$httpAdmin
                .get("/reseller", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.resellerList = response.data;
                });
            this.resellerOptions = [{ value: -1, id: "", text: "All Resellers", selected: true }];
            for (let i = 0; i < this.resellerList.length; i++) {
                this.resellerOptions.push({
                    value: this.resellerList[i].id,
                    text: this.resellerList[i].name,
                    selected: false,
                });
            }
        },
        async getCompanyFtpCredentials() {
            await this.$httpAdmin
                .get("companyftpcredential", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.companyFtpCredentials = response.data;
                });
        },
        async getResellerFtpCredentials() {
            await this.$httpAdmin
                .get("resellerftpcredential", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.resellerFtpCredentials = response.data;
                });
        },

        async searchTable(params) {
            this.searchTerm = params.searchTerm;
            await this.createTable();
        },
        async createTable() {
            this.loading = true;
            const response = await this.$httpAdmin.get("/newcustomer", {
                headers: this.headers,
                params: this.getTableParams(),
            });

            this.table = response.data[0].Items;
            this.totalRecords = response.data[0].TotalRecords;
            this.loading = false;
            for (let i = 0; i < this.table.length; i++) {
                for (let j = 0; j < this.resellerList.length; j++) {
                    if (this.table[i].cmp_reseller_id == this.resellerList[j].id) {
                        this.table[i].cmp_reseller_name = this.resellerList[j].name;
                    }
                }
            }
            for (let i = 0; i < this.table.length; i++) {
                this.table[i].cmp_start_date = new moment(this.table[i].cmp_start_date).format("MMM DD, YYYY");
                if (this.table[i].status == 1) this.table[i].status = "New";
                else if (this.table[i].status == 2) this.table[i].status = "Accept Pending";
                else if (this.table[i].status == 3) this.table[i].status = "Signed";
                else if (this.table[i].status == 4) this.table[i].status = "Accounts Needed";
                else if (this.table[i].status == 5) this.table[i].status = "Ready to go!";
            }
            this.onboardingRows = this.table;
            this.onboardingColumns = [
                { field: "cmp_name", label: "Company" },
                { field: "cmp_reseller_name", label: "Partner" },
                { field: "usr_first_name", label: "First Name" },
                { field: "usr_last_name", label: "Last Name" },
                { field: "usr_phone", label: "Phone" },
                { field: "usr_email", label: "Email Address" },
                { field: "cmp_start_date", label: "Register Date" },
                { field: "status", label: "Status" },
            ];
        },
        getTableParams() {
            return {
                cmp_reseller_id: this.selectedReseller,
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                cmp_name: this.searchTerm ? this.searchTerm : null,
            };
        },
        onRowClick(params) {
            params.selected = true;
            this.selectedRow = params.row;
            for (let i = 0; i < this.resellerOptions.length; i++) {
                if (this.resellerOptions[i].text == this.selectedRow.cmp_reseller_name) {
                    this.custInfoPartner = this.selectedRow.cmp_reseller_name;
                }
            }
            this.rowModal = true;
        },
        changeSelectedReseller(reseller) {
            if (reseller != -1) {
                this.selectedReseller = reseller;
                this.table = [];
                this.createTable();
            }
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.createTable();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.createTable();
        },
        onSortChange(params) {
            if (params[0].field == "cmp_reseller_name") {
                this.sortColumn = "cmp_reseller_id";
                this.sortkey = "cmp_reseller_id";
            } else {
                this.sortkey = params[0].field;
                this.sortColumn = params[0].field;
            }
            this.sortDir = params[0].type;
            this.createTable();
        },
        async customerInfoModal() {
            this.isBusy = true;
            this.rowModal = false;
            this.custInfo = [];
            await this.$httpAdmin
                .get("/newcustomer/" + this.selectedRow.cust_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.custInfo = response.data;
                    if (this.custInfo == null) {
                        this.isBusy = false;
                        this.$notify.error({
                            message: "Unable to load company.",
                            title: "Error",
                            timeOut: 10000,
                            variant: "danger",
                        });
                    }
                    this.custInfo.cmp_is_download_only == "1"
                        ? (this.custInfo.cmp_is_download_only = true)
                        : (this.custInfo.cmp_is_download_only = false);
                    this.custInfo.cmp_start_date = new moment(this.custInfo.cmp_start_date).format("MM/DD/YYYY");
                    if (this.custInfo.cmp_bill_type == "flatfee") {
                        this.custBillTypeVal = "$/mth";
                    } else if (this.custInfo.cmp_bill_type == "transactional") {
                        this.custBillTypeVal = "¢/pkg";
                    } else {
                        this.custBillTypeVal = "%";
                    }
                    if (this.custInfo.cmp_base_country) {
                        this.custInfo.cmp_base_country = this.custInfo.cmp_base_country.toUpperCase();
                    }
                });
            await this.$httpAdmin
                .get("/adminuserprofile/salesperson", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.salespersonInfo = response.data;
                });
            let disabled = false;
            let selected = false;
            for (let i = 0; i < this.salespersonInfo.length; i++) {
                if (this.salespersonInfo[i].usr_is_active == 0) {
                    disabled = true;
                } else {
                    disabled = false;
                }
                if (this.salespersonInfo[i].usr_id == this.custInfo.cmp_user_profile_id_sales_person) {
                    selected = true;
                } else {
                    selected = false;
                }
                this.salespersonOptions.push({
                    value: i,
                    text: this.salespersonInfo[i].usr_first_name + " " + this.salespersonInfo[i].usr_last_name,
                    disabled: disabled,
                    selected: selected,
                });
            }
            this.isBusy = false;
            this.customerModal = true;
        },
        async sendContractModal() {
            await this.$httpAdmin
                .get("newcustomercontract/" + this.selectedRow.cust_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.contractInfo = response.data;
                    if (this.contractInfo.error) {
                        this.$notify.error({
                            message: this.contractInfo.error,
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    } else {
                        this.contractModal = true;
                    }
                });
            this.rowModal = false;
        },
        async sendContract() {
            let isGood = true;
            let isEmail = this.testEmail();
            if (!isEmail) {
                this.$notify.error({
                    message: "The 'to' field is not a valid e-mail address.",
                    title: "To Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            this.contractInfo.body = document.getElementById("contractBody").innerHTML;
            if (this.contractInfo.body.length == 0) {
                this.$notify.error({
                    message: "There is no email content",
                    title: "Content Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (isGood) {
                await this.$httpAdmin
                    .post("/newcustomercontract", this.sendContractParams(), { headers: this.headers })
                    .then(async (response) => {
                        let results = response.data;
                    });
                this.contractModal = false;
            }
        },
        sendContractParams() {
            return {
                to: this.contractInfo.to,
                body: this.contractInfo.body,
                custId: this.selectedRow.cust_id,
            };
        },
        testEmail() {
            let re =
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.contractInfo.to);
        },
        async carrierCredentialsModal() {
            this.isBusy = true;
            this.rowModal = false;
            await this.$httpAdmin
                .get("newcustomercredential/?credential_cmp_id=" + this.selectedRow.cust_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.credentialsInfo = response.data;
                    for (let i = 0; i < this.credentialsInfo.length; i++) {
                        if (this.credentialsInfo[i].credential_no_audit == "0") {
                            this.credentialsInfo[i].credential_no_audit = false;
                        } else this.credentialsInfo[i].credential_no_audit = true;
                        if (this.credentialsInfo[i].credential_alias == "0") {
                            this.credentialsInfo[i].credential_alias = false;
                        } else this.credentialsInfo[i].credential_alias = true;
                        if (this.credentialsInfo[i].credential_is_account_discovery_disabled == "0") {
                            this.credentialsInfo[i].credential_is_account_discovery_disabled = false;
                        } else this.credentialsInfo[i].credential_is_account_discovery_disabled = true;
                        if (this.credentialsInfo[i].credential_is_active == "0") {
                            this.credentialsInfo[i].credential_is_active = false;
                        } else this.credentialsInfo[i].credential_is_active = true;
                        let found = false;
                        for (let j = 0; j < this.fedExType.length; j++) {
                            if (this.credentialsInfo[i].credential_car_id == 1) {
                                this.credentialsInfo[i].type = "fedEx";
                                this.credentialsInfo[i].typeOptions = this.fedExType;
                                found = true;
                            }
                        }
                        if (!found) {
                            for (let j = 0; j < this.upsType.length; j++) {
                                if (this.credentialsInfo[i].credential_car_id == 2) {
                                    this.credentialsInfo[i].type = "ups";
                                    this.credentialsInfo[i].typeOptions = this.upsType;
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (let j = 0; j < this.dhlType.length; j++) {
                                if (this.credentialsInfo[i].credential_car_id == 3) {
                                    this.credentialsInfo[i].type = "dhl";
                                    this.credentialsInfo[i].typeOptions = this.dhlType;
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (let j = 0; j < this.purolatorType.length; j++) {
                                if (this.credentialsInfo[i].credential_car_id == 4) {
                                    this.credentialsInfo[i].type = "purolator";
                                    this.credentialsInfo[i].typeOptions = this.purolatorType;
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (let j = 0; j < this.dhlexType.length; j++) {
                                if (this.credentialsInfo[i].credential_car_id == 5) {
                                    this.credentialsInfo[i].type = "dhlex";
                                    this.credentialsInfo[i].typeOptions = this.dhlexType;
                                    found = true;
                                }
                            }
                        }
                        this.credentialsInfo[i].error = false;
                    }
                });
            await this.$httpAdmin
                .get("newcustomer/" + this.selectedRow.cust_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.credsInfo = response.data;
                });
            this.isBusy = false;
            this.credentialsModal = true;
        },
        async carrierAccountsModal() {
            this.isBusy = true;
            this.rowModal = false;
            await this.$httpAdmin
                .get("newcustomercredential/?credential_is_active=true&credential_cmp_id=" + this.selectedRow.cust_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.carrierAccountsInfo = response.data;
                    if (this.carrierAccountsInfo.length > 0) {
                        for (let i = 0; i < this.carrierAccountsInfo.length; i++) {
                            this.carrierAccountsCreds.push(
                                Carriers[String(this.carrierAccountsInfo[i].credential_car_id)].name
                            );
                        }
                    }
                });
            await this.$httpAdmin
                .get("newcustomeraccount/?acct_cmp_id=" + this.selectedRow.cust_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.newCustAccountInfo = response.data;
                    for (let i = 0; i < this.newCustAccountInfo.length; i++) {
                        this.newCustAccountInfo[i].acct_no_audit == "0"
                            ? (this.newCustAccountInfo[i].acct_no_audit = false)
                            : (this.newCustAccountInfo[i].acct_no_audit = true);
                        this.newCustAccountInfo[i].acct_is_gsr_waiver == "0"
                            ? (this.newCustAccountInfo[i].acct_is_gsr_waiver = false)
                            : (this.newCustAccountInfo[i].acct_is_gsr_waiver = true);
                    }
                });
            for (let i = 0; i < this.newCustAccountInfo.length; i++) {
                this.carrierAccounts.push(this.newCustAccountInfo[i]);
            }
            this.isBusy = false;
            this.accountsModal = true;
        },
        showActivateModal() {
            if (this.selectedRow.status == "Signed" || this.selectedRow.status == "Ready to go!") {
                this.rowModal = false;
                this.activateModal = true;
            } else {
                this.$notify.error({
                    message: "The contract must be signed first",
                    title: "Not Ready",
                    timeOut: 5000,
                    variant: "danger",
                });
            }
        },
        async activateAccount() {
            this.isBusy = true;
            if (this.selectedRow.cust_id < 0) {
                return;
            }
            let intacctID = "";
            if (this.intacctID) {
                intacctID = "/" + this.intacctID;
            }
            await this.$httpAdmin
                .get("newcustomer/activate/" + this.selectedRow.cust_id + "/" + this.clientID + intacctID, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    let activateAccountResponse = response.data;
                    if (activateAccountResponse == "done") {
                        this.$notify.success({
                            message: "The customer has been successfully activated!",
                            title: "Success",
                            timeOut: 10000,
                            variant: "success",
                        });
                        await this.createTable();
                    } else {
                        let parts = activateAccountResponse.split(",");
                        let message = "We cannot activate until the following is populated: ";
                        for (let i = 0; i < parts.length; i++) message += parts[i] + " ";
                        this.$notify.error({
                            message: message,
                            title: "Error",
                            timeOut: 10000,
                            variant: "danger",
                        });
                    }
                });
            this.isBusy = false;
            this.activateModal = false;
        },
        async saveCustomerInfo() {
            this.isBusy = true;
            await this.$httpAdmin
                .put("/newcustomer/billing/" + this.custInfo.cust_id, this.custInfoParams(), { headers: this.headers })
                .then(async (response) => {
                    let results = response.data;
                    this.customerModal = false;
                    await this.createTable();
                });
            this.isBusy = false;
        },
        custInfoParams() {
            return {
                cmp_reseller_id: this.selectedRow.cmp_reseller_id,
                cmp_type: this.custInfo.cmp_type,
                cmp_base_country: this.custInfo.cmp_base_country.toLowerCase(),
                cmp_user_profile_id_sales_person: this.custInfo.cmp_user_profile_id_sales_person,
                cmp_bill_type: this.custInfo.cmp_bill_type,
                cmp_bill_value: this.custInfo.cmp_bill_value,
                cmp_bill_terms: this.custInfo.cmp_bill_terms,
                cmp_late_fee: this.custInfo.cmp_late_fee,
                cmp_contact_first_name: this.custInfo.cmp_contact_first_name,
                cmp_contact_last_name: this.custInfo.cmp_contact_last_name,
                cmp_contact_phone: this.custInfo.cmp_contact_phone,
                cmp_contact_phone_ext: this.custInfo.cmp_contact_phone_ext,
                cmp_contact_email: this.custInfo.cmp_contact_email,
                cmp_billing_first_name: this.custInfo.cmp_billing_first_name,
                cmp_billing_last_name: this.custInfo.cmp_billing_last_name,
                cmp_bill_custref: this.custInfo.cmp_bill_custref,
                cmp_billing_phone: this.custInfo.cmp_billing_phone,
                cmp_billing_phone_ext: this.custInfo.cmp_billing_phone_ext,
                cmp_billing_email: this.custInfo.cmp_billing_email,
                cmp_billing_email_cc: this.custInfo.cmp_billing_email_cc,
                cmp_billing_addr1: this.custInfo.cmp_billing_addr1,
                cmp_billing_addr2: this.custInfo.cmp_billing_addr2,
                cmp_billing_city: this.custInfo.cmp_billing_city,
                cmp_billing_state: this.custInfo.cmp_billing_state,
                cmp_billing_zip: this.custInfo.cmp_billing_zip,
                cmp_billing_country: this.custInfo.cmp_billing_country?.toLowerCase(),
                cmp_is_download_only: this.custInfo.cmp_is_download_only,
            };
        },
        async manualAddUser() {
            await this.$httpAdmin
                .post("/newcustomer", this.manualAddParams(), { headers: this.headers })
                .then(async (response) => {
                    let results = response.data;
                    if (results == 3) {
                        this.manualAddModal = false;
                        this.createTable();
                    } else if (results == 0) {
                        this.$notify.error({
                            message:
                                "Some fields are not valid. They are either missing or don't meet the format requirements.",
                            title: "Fields Error",
                            timeOut: 10000,
                            variant: "danger",
                        });
                    } else if (results == 1) {
                        this.$notify.error({
                            message: "This user already exists in the system.",
                            title: "Can't Add User",
                            timeOut: 10000,
                            variant: "danger",
                        });
                    } else if (results == 2) {
                        this.$notify.error({
                            message:
                                "The system can not store this customer. It is because of invalid data or system internal errors.",
                            title: "Error",
                            timeOut: 10000,
                            variant: "danger",
                        });
                    } else if (results == 4) {
                        this.$notify.error({
                            message: "The password you have chosen does not meet the requirements.",
                            title: "Error",
                            timeOut: 10000,
                            variant: "danger",
                        });
                    } else if (results == 5) {
                        this.$notify.error({
                            message: "If you want to create a user, please populate all user fields.",
                            title: "Error",
                            timeOut: 10000,
                            variant: "danger",
                        });
                    }
                });
        },
        manualAddParams() {
            return {
                cmp_name: this.manualAddCompany,
                usr_first_name: this.manualAddFirstName,
                usr_last_name: this.manualAddLastName,
                usr_phone: this.manualAddPhone,
                usr_email: this.manualAddEmail,
                usr_password: this.manualAddPassword,
                cmp_is_download_only: this.downloadOnly,
            };
        },
        toggleDownloadOnly() {
            this.downloadOnly = !this.downloadOnly;
        },
        toggleCmpDownloadOnly() {
            this.custInfo.cmp_is_download_only = !this.custInfo.cmp_is_download_only;
        },
        carrierAccountsAdd() {
            //add call to back end
            this.showAccountsTable = true;
            let newAccount = {};
            newAccount.acct_number = "";
            newAccount.acct_mns_delay_days = 35;
            newAccount.acct_npod_delay_days = 55;
            newAccount.acct_credential_id = "";
            newAccount.carrierAccountsCreds = "";
            newAccount.selectedAccountsCred = "";
            newAccount.acct_no_audit = false;
            newAccount.acct_is_gsr_waiver = false;
            (newAccount.selectedCredentialID = ""), this.carrierAccounts.push(newAccount);
        },
        closeCredentialsModal() {
            this.credentialsModal = false;
        },
        closeAccountsModal() {
            this.accountsModal = false;
            this.showAccountsTable = false;
            this.carrierAccounts = [];
        },
        onPageChangeWr(params) {
            this.pageWr = params.currentPage;
            this.createWebRegisteredUserTable();
        },

        onPerPageChangeWr(params) {
            this.limitWr = params.currentPerPage;
            this.sizeWr = params.currentPerPage;
            this.createWebRegisteredUserTable();
        },
        onSortChangeWr(params) {
            this.sortColumnWr = params[0].field;
            this.sortDirWr = params[0].type;
            this.createWebRegisteredUserTable();
        },

        async searchTableWr(params) {
            this.searchTermWr = params.searchTerm;
            await this.createWebRegisteredUserTable();
        },
        async createWebRegisteredUserTable() {
            await this.$httpAdmin
                .get("/adminWebRegisteredUser", {
                    headers: this.headers,
                    params: this.getWebRegisteredUserTableParams(),
                })
                .then(async (response) => {
                    this.webUserTable = response.data[0].Items;
                    this.totalRecordsWr = response.data[0].TotalRecords;
                });
            for (let i = 0; i < this.webUserTable.length; i++) {
                this.webUserTable[i].usr_created_date = new moment(this.webUserTable[i].usr_created_date).format(
                    "MMM DD, YYYY"
                );
                if (this.webUserTable[i].wru_is_email_verified == 1) {
                    this.webUserTable[i].wru_is_email_verified = "Yes";
                } else {
                    this.webUserTable[i].wru_is_email_verified = "No";
                }
                if (
                    this.webUserTable[i].cmp_payment_gateway_id &&
                    this.webUserTable[i].cmp_payment_gateway_id.length > 2
                ) {
                    this.webUserTable[i].cmp_payment_gateway_id = "Yes";
                } else {
                    this.webUserTable[i].cmp_payment_gateway_id = "No";
                }
            }
            this.webRegisteredUsersRows = this.webUserTable; //.reverse()
            this.webRegisteredUsersColumns = [
                { field: "cmp_name", label: "Company", sort: true },
                { field: "usr_first_name", label: "First Name", sort: true },
                { field: "usr_last_name", label: "Last Name", sort: true },
                { field: "usr_phone", label: "Phone", sort: true },
                { field: "usr_email", label: "Email Address", sort: true },
                { field: "wru_company_spent", label: "Spent", sort: true },
                { field: "usr_created_date", label: "Register Date", sort: true },
                { field: "nocredentials", label: "#creds", sort: true },
                { field: "cmp_payment_gateway_id", label: "Payment", sort: true },
                { field: "wru_is_email_verified", label: "Verified", sort: true },
            ];
        },
        getWebRegisteredUserTableParams() {
            return {
                limit: this.limitWr,
                size: this.sizeWr,
                page: this.pageWr,
                sortColumn: this.sortColumnWr,
                sortkey: this.sortkeyWr,
                sortDir: this.sortDirWr,
                cmp_name: this.searchTermWr ? this.searchTermWr : null,
            };
        },
        async onWebRegisteredUserRowClick(params) {
            params.selected = true;
            await this.$httpAdmin
                .get("/adminWebRegisteredUser/" + params.row.wru_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.selectedWebRegisteredUserRow = response.data.company;
                    for (let i = 0; i < this.selectedWebRegisteredUserRow.length; i++) {
                        this.selectedWebRegisteredUserRow[i].usr_created_date = new moment(
                            this.selectedWebRegisteredUserRow[i].usr_created_date
                        ).format("MMM DD, YYYY");
                        this.selectedWebRegisteredUserRow[i].usr_last_login_date = new moment(
                            this.selectedWebRegisteredUserRow[i].usr_last_login_date
                        ).format("MMM DD, YYYY");
                    }
                    this.viewDetailsModal = true;
                });
        },
        deleteAccountModal(index) {
            this.toDeleteIndex = index;
            this.showDeleteModal = true;
        },
        async deleteAccount() {
            this.isBusy = true;
            const toDelete = this.carrierAccounts[this.toDeleteIndex];
            this.carrierAccounts.splice(this.toDeleteIndex, 1);
            await this.$httpAdmin.delete("newcustomeraccount/" + toDelete.acct_id, { headers: this.headers });
            this.showDeleteModal = false;
            this.isBusy = false;
        },
        async saveAccounts() {
            this.isBusy = true;
            let isGood = true;
            for (let i = 0; i < this.carrierAccounts.length; i++) {
                if (this.carrierAccounts[i].acct_credential_id) {
                    for (let j = 0; j < this.carrierAccountsInfo.length; j++) {
                        if (this.carrierAccounts[i].acct_credential_id == this.carrierAccountsInfo[j].credential_id) {
                            this.carrierAccounts[i].credential_car_id = this.carrierAccountsInfo[j].credential_car_id;
                        }
                    }
                }
                if (
                    !this.carrierAccounts[i].acct_number ||
                    !this.carrierAccounts[i].acct_mns_delay_days ||
                    !this.carrierAccounts[i].acct_npod_delay_days ||
                    !(this.carrierAccounts[i].acct_car_id || this.carrierAccounts[i].credential_car_id)
                ) {
                    this.$notify.error({
                        message: "One of the accounts is not valid",
                        title: "Error",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    isGood = false;
                }
            }
            if (isGood) {
                let count = this.carrierAccounts.length;
                for (let i = 0; i < this.carrierAccounts.length; i++) {
                    let params = {
                        acct_id: this.carrierAccounts[i].acct_id ? this.carrierAccounts[i].acct_id : 0,
                        acct_number: this.carrierAccounts[i].acct_number,
                        acct_credential_id: this.carrierAccounts[i].credential_id
                            ? this.carrierAccounts[i].credential_id
                            : this.carrierAccounts[i].acct_credential_id,
                        acct_cmp_id: this.selectedRow.cust_id,
                        acct_car_id: this.carrierAccounts[i].credential_car_id
                            ? this.carrierAccounts[i].credential_car_id
                            : this.carrierAccounts[i].acct_car_id,
                        acct_mns_delay_days: Number(this.carrierAccounts[i].acct_mns_delay_days),
                        acct_npod_delay_days: Number(this.carrierAccounts[i].acct_npod_delay_days),
                        acct_no_audit: this.carrierAccounts[i].acct_no_audit,
                        acct_is_gsr_waiver: this.carrierAccounts[i].acct_is_gsr_waiver,
                    };
                    if (this.carrierAccounts[i].acct_id && this.carrierAccounts[i].acct_id > 0) {
                        await this.$httpAdmin
                            .put("/newcustomeraccount/" + this.carrierAccounts[i].acct_id, params, {
                                headers: this.headers,
                            })
                            .then(async (response) => {
                                let results = response.data;
                                if (results) {
                                    count--;
                                    if (count == 0) {
                                        this.$notify.success({
                                            message: "The account has been saved successfully.",
                                            title: "Success",
                                            timeOut: 5000,
                                            variant: "success",
                                        });
                                    }
                                } else {
                                    this.$notify.error({
                                        message:
                                            "There is a problem saving the accounts. Please contact technical support team.",
                                        title: "Error",
                                        timeOut: 5000,
                                        variant: "danger",
                                    });
                                }
                            });
                    } else {
                        await this.$httpAdmin
                            .post("/newcustomeraccount", params, { headers: this.headers })
                            .then(async (response) => {
                                let results = response.data;
                                if (results) {
                                    count--;
                                    if (count == 0) {
                                        this.$notify.success({
                                            message: "The account has been saved successfully.",
                                            title: "Success",
                                            timeOut: 5000,
                                            variant: "success",
                                        });
                                    }
                                }
                            });
                    }
                }
            }
            this.carrierAccounts = [];
            this.isBusy = false;
            this.accountsModal = false;
        },
        changeBillingType() {
            if (this.custInfo.cmp_bill_type == "flatfee") {
                this.custBillTypeVal = "$/mth";
            } else if (this.custInfo.cmp_bill_type == "transactional") {
                this.custBillTypeVal = "¢/pkg";
            } else {
                this.custBillTypeVal = "%";
            }
        },
        pushToCredentialsInfo(newCarrier) {
            this.credentialsInfo.push(newCarrier);
        },
        generatePassword() {
            let len = 10;
            let alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            let alphaLength = 52;

            let nums = "0123456789";
            let numsLength = 10;

            let symbols = "!@#$%^&*()_+=[{]};:<>|./?,-";
            let symbolsLength = 27;

            let password = "";

            // random characters, mix of upper and lowercase
            for (let i = 0; i < len - 4; i++) {
                let index = Math.floor(Math.random() * alphaLength);
                password += alpha.charAt(index);
            }

            // add one guaranteed lowercase and uppercase
            password += alpha.charAt(Math.floor(Math.random() * alphaLength)).toLowerCase();
            password += alpha.charAt(Math.floor(Math.random() * alphaLength)).toUpperCase();

            password += nums.charAt(Math.floor(Math.random() * numsLength));
            password += symbols.charAt(Math.floor(Math.random() * symbolsLength));

            this.manualAddPassword = password;
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
<style>
#onboarding_overlay {
    position: fixed;
    z-index: 5000;
    opacity: 0.8;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-bottom: 25%;
}
</style>
