<template>
    <mdb-dropdown data-test="dropdown" id="dropdown-form3" variant="white" multiLevel>
        <mdb-dropdown-toggle data-test="dropdownToggle" slot="toggle" class="w-100">{{
            sDate && eDate ? formatDate(sDate) + " - " + formatDate(eDate) : "Select Date Range"
        }}</mdb-dropdown-toggle>
        <mdb-dropdown-menu data-test="dropdownMenu" :class="showCustom ? 'width-600' : ''">
            <form data-test="dropdownForm">
                <mdb-row>
                    <mdb-col :col="showCustom ? '5' : '12'">
                        <form data-test="rangesForm" v-if="ranges" class="m-0 p-0">
                            <div class="col-12 py-1 mx-0 px-0">
                                <div v-for="(button, index) of ranges" v-bind:key="index" class="col-12 mx-0 px-0">
                                    <mdb-btn
                                        data-test="updateRangeBtn"
                                        variant="default"
                                        class="my-1 border col-12 text-xs"
                                        @click.native="updateRange(button)"
                                        >{{ index }}</mdb-btn
                                    >
                                </div>
                            </div>
                        </form>
                        <mdb-btn
                            data-test="showCustomChangeBtn"
                            class="my-1 border col-12 text-xs"
                            :variant="showCustom ? 'default' : 'light'"
                            @click.native="showCustomChange"
                            >Custom Range</mdb-btn
                        >
                        <p data-test="pFrom" class="bold">From:</p>
                        <mdb-input
                            data-test="startDateInput"
                            :value="formatDate(sDate)"
                            class="my-1"
                            placeholder="Start Date"
                            disabled
                        ></mdb-input>
                        <p data-test="pTo" class="bold">To:</p>
                        <mdb-input
                            data-test="endDateInput"
                            type="text"
                            :value="formatDate(eDate)"
                            class="my-1"
                            placeholder="End Date"
                            disabled
                        ></mdb-input>
                        <mdb-btn data-test="hideMenuBtn" variant="info" @click.native="hideMenu">Apply</mdb-btn>
                    </mdb-col>
                    <mdb-col data-test="showCustomCol" col="7" v-if="showCustom">
                        <mdb-row>
                            <mdb-col col="12">
                                <b data-test="showCustomStartDate" class="text-center">Start Date:</b>
                                <vuejs-datepicker
                                    data-test="showCustomStartDateDatepicker"
                                    inline
                                    ref="startCal"
                                    v-model="sDate"
                                    locale="en-US"
                                    :disabledDates="{ from: new Date(eDate) }"
                                ></vuejs-datepicker>
                            </mdb-col>
                            <mdb-col col="12">
                                <b data-test="showCustomEndDate" class="text-center">End Date:</b>
                                <vuejs-datepicker
                                    data-test="showCustomEndDateDatepicker"
                                    inline
                                    ref="endCal"
                                    v-model="eDate"
                                    locale="en-US"
                                    :disabledDates="{ to: new Date(sDate) }"
                                ></vuejs-datepicker>
                            </mdb-col>
                        </mdb-row>
                    </mdb-col>
                </mdb-row>
            </form>
        </mdb-dropdown-menu>
    </mdb-dropdown>
</template>
<script>
import moment from "moment";
import { mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbRow, mdbCol, mdbBtn, mdbInput } from "mdbvue";
const time = moment().subtract(1, "days");

export default {
    name: "DateSelector",
    components: { mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbRow, mdbCol, mdbBtn, mdbInput },
    data() {
        return {
            dateRange: null,
            eDate: null,
            sDate: null,
            ranges: {
                "Last Invoice Cycle": [
                    moment().startOf("week").subtract(7, "days"),
                    moment().startOf("week").subtract(1, "days"),
                ],
                "Last 14 Days": [moment().subtract(14, "days"), time],
                "Last 30 Days": [moment().subtract(30, "days"), time],
                "Last 60 Days": [moment().subtract(60, "days"), time],
                "Last 90 Days": [moment().subtract(90, "days"), time],
                "Last 4 Months": [moment().subtract(4, "month"), time],
                "Last 6 Months": [moment().subtract(6, "month"), time],
                "Last 9 Months": [moment().subtract(9, "month"), time],
                "Last 1 Year": [moment().subtract(1, "y"), time],
                "Last 18 Months": [moment().subtract(18, "month"), time],
                "Last 2 Years": [moment().subtract(2, "y"), time],
            },
            startDateInit: new moment(new moment().subtract(90, "days").format("YYYY-MM-DD"), "YYYY-MM-DD").format(
                "YYYY-MM-DD"
            ),
            endDateInit: new moment(new moment().format("YYYY-MM-DD"), "YYYY-MM-DD").format("YYYY-MM-DD"),
            maxDate: moment(),
            minDate: moment().subtract("y", 2),
            showCustom: false,
        };
    },
    props: {
        startDate: null,
        endDate: null,
    },
    methods: {
        showCustomChange() {
            this.showCustom = !this.showCustom;
        },
        moment: (date) => {
            return moment(date);
        },
        formatDate(date) {
            return moment(date).format("MMM DD, YYYY");
        },
        updateRange(value) {
            this.sDate = moment(value[0]).format("MMM DD, YYYY");
            this.eDate = moment(value[1]).format("MMM DD, YYYY");
        },
        hideMenu() {
            this.showCustom = false;
        },
    },
    mounted() {
        this.sDate = new moment(this.startDate).format("YYYY-MM-DD");
        this.eDate = new moment(this.endDate).format("YYYY-MM-DD");
    },
    watch: {
        sDate() {
            this.$emit("update-start-date", this.sDate);
        },
        eDate() {
            this.$emit("update-end-date", this.eDate);
        },
        currentPage() {
            this.getReportData();
        },
        limit() {
            this.getReportData();
        },
    },
};
</script>
<style>
.width-600 {
    min-width: 500px !important;
}
</style>
