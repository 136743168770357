<template>
    <div class="row d-none">
        <div id="step-one" class="single-step ltr-text">
            <span class="connectorarrow-false"><!-- custom --></span>
            <p>
                Need more control for reporting or internal accounting reconciliation? You can &quot;tag&quot; data
                meeting certain requirements by using this powerful GL coding engine. This page is where you can create
                one or many GL code rules and apply them to your data.
            </p>
            <br />
            <p>Let's walk through the features together.</p>
        </div>
        <div id="step-two" class="single-step ltr-text">
            <p>Get started by entering a name for your code here.</p>
        </div>
        <div id="step-three" class="single-step ltr-text">
            <p>Select a specific carrier, or all carriers, to apply this code.</p>
        </div>
        <div id="step-four" class="single-step ltr-text">
            <p>
                Selecting &quot;ALL&quot; implies that all rules you define below must be met. Selecting &quot;ANY&quot;
                implies any one of the rules you specify must be met.
            </p>
            <br />
            <p>You can add as many rules as you like to each GL Code.</p>
        </div>
        <div id="step-five" class="single-step ltr-text">
            <p>
                Select a field to start defining your criteria. Once selected, select an &quot;operand&quot; (equals,
                contains, starts with, etc) and a value to search. Think of it as writing a sentence, where you are
                creating rules to find specific data. Once complete, select &quot;Add Rule&quot; to add.
            </p>
            <br />
            <p>Create as many rules as you wish for one GL Code; just keep adding rows!</p>
        </div>
        <div id="step-six" class="single-step ltr-text">
            <p>
                Once you're satisfied with the rules of the code, click &quot;Save GL Code&quot; to save it. Repeat if
                you have more GL Codes to account for.
            </p>
        </div>
        <div id="step-seven" class="single-step ltr-text">
            <p>
                The GL Codes you created will be displayed here and you can delete unnecessary codes if you need. If you
                want to prioritize codes, i.e. make sure one GL Code is applied before another, just drag them into the
                proper order.
            </p>
            <br />
            <p>If you re-order your GL Codes, make sure you press "SAVE PRIORITY"!</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "GlCodingTour",
};
</script>
