<template>
    <div class="m-4 glCode">
        <h3 data-test="title" class="my-4 mx-2">Credential Checker</h3>
        <mdb-card data-test="loginCheckerCard" class="mt-2 p-4">
            <mdb-card-title data-test="loginCheckerCardTitle">Login Checker</mdb-card-title>
            <mdb-card-body data-test="loginCheckerCardBody">
                <mdb-select data-test="carriersSelect" v-model="carriers" placeholder="Select Carrier"></mdb-select>
                <mdb-input
                    data-test="loginUsernameInput"
                    type="text"
                    placeholder="Username"
                    v-model="loginUsername"
                ></mdb-input>
                <mdb-input
                    data-test="loginPasswordInput"
                    type="password"
                    placeholder="Password"
                    v-model="loginPassword"
                ></mdb-input>
                <mdb-btn
                    data-test="testBtn"
                    color="primary"
                    @click.native="test"
                    v-if="selectedCarrier && loginPassword && loginUsername"
                    >Test</mdb-btn
                >
            </mdb-card-body>
        </mdb-card>
        <mdb-card data-test="apiCheckerCard" class="mt-2 p-4">
            <mdb-card-title data-test="apiCheckerCardTitle">API Checker</mdb-card-title>
            <mdb-card-body data-test="apiCheckerCardBody">
                <mdb-select
                    data-test="carriersApiSelect"
                    v-model="carriersApi"
                    placeholder="Select Carrier"
                ></mdb-select>
                <div data-test="selectedCarrierApiUPS" v-if="selectedCarrierApi === 'UPS'">
                    <mdb-input
                        data-test="apiUsernameInput"
                        type="text"
                        placeholder="UPS Username"
                        v-model="apiUsername"
                    ></mdb-input>
                    <mdb-input
                        data-test="apiPasswordInput"
                        type="password"
                        placeholder="UPS Password"
                        v-model="apiPassword"
                    ></mdb-input>
                    <mdb-input
                        data-test="apiKeyInput"
                        type="text"
                        placeholder="UPS API Key"
                        v-model="apiKey"
                    ></mdb-input>
                </div>
                <div data-test="selectedCarrierApiFedEx" v-else-if="selectedCarrierApi === 'FedEx'">
                    <mdb-input
                        data-test="apiFdxKeyInput"
                        type="text"
                        placeholder="FedEx API Key"
                        v-model="apiFdxKey"
                    ></mdb-input>
                    <mdb-input
                        data-test="apiFdxPasswordInput"
                        type="text"
                        placeholder="FedEx API Password"
                        v-model="apiFdxPassword"
                    ></mdb-input>
                    <mdb-input
                        data-test="apiFdxMeterInput"
                        type="text"
                        placeholder="FedEx API Key"
                        v-model="apiFdxMeter"
                    ></mdb-input>
                    <mdb-input
                        data-test="apiFdxAccountInput"
                        type="text"
                        placeholder="FedEx API Key"
                        v-model="apiFdxAccount"
                    ></mdb-input>
                </div>
                <div data-test="selectedCarrierApiElse" v-else><em>**Select a carrier to continue</em></div>
                <mdb-btn
                    data-test="testApiBtn"
                    color="primary"
                    class="mt-2"
                    @click.native="testApi"
                    v-if="checkApiButton"
                    >Test</mdb-btn
                >
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import { mdbCard, mdbCardTitle, mdbCardBody, mdbSelect, mdbInput, mdbBtn } from "mdbvue";
export default {
    name: "AdminCredentialChecker",
    components: {
        mdbCard,
        mdbCardTitle,
        mdbCardBody,
        mdbSelect,
        mdbInput,
        mdbBtn,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            loginUsername: null,
            loginPassword: null,
            apiUsername: null,
            apiPassword: null,
            apiKey: null,
            apiFdxKey: null,
            apiFdxPassword: null,
            apiFdxMeter: null,
            apiFdxAccount: null,
            carriers: [
                { text: "UPS", value: "UPS", selected: true },
                { text: "FedEx", value: "FedEx" },
            ],
            carriersApi: [
                { text: "UPS", value: "UPS", selected: true },
                { text: "FedEx", value: "FedEx" },
            ],
        };
    },
    computed: {
        checkApiButton() {
            if (this.selectedCarrierApi === "UPS") {
                return this.apiKey && this.apiPassword && this.apiUsername;
            } else {
                return this.apiFdxPassword && this.apiFdxAccount && this.apiFdxMeter && this.apiFdxKey;
            }
        },
        selectedCarrierApi() {
            let records;
            this.carriersApi.map((c) => {
                if (c.selected) {
                    records = c.value;
                }
            });
            return records ? records : null;
        },
        selectedCarrier() {
            let records;
            this.carriers.map((c) => {
                if (c.selected) {
                    records = c.value;
                }
            });
            return records ? records : null;
        },
    },
    methods: {
        async test() {
            const url = `carriercredentialvalidator/login/?type=${this.selectedCarrier}&username=${this.loginUsername}&password=${this.loginPassword}`;
            try {
                const data = await this.$httpShipping.get(url, { headers: this.headers });
                if (data) {
                    this.$notify.success({ message: data.data });
                    this.apiUsername = "";
                    this.apiPassword = "";
                } else {
                    this.$notify.error({ message: data.data });
                }
            } catch (e) {
                this.$notify.error({ message: e });
            }
        },
        async testApi() {
            let url = "carriercredentialvalidator/api";
            if (this.selectedCarrierApi === "UPS") {
                url += `/ups/?username=${this.apiUsername}&password=${this.apiPassword}&apikey=${this.apiKey}`;
            } else {
                url += `/fedex/?apikey=${this.apiFdxKey}&apipass=${this.apiFdxPassword}&apimeter=${this.apiFdxMeter}&apiaccount=${this.apiFdxAccount}`;
            }
            try {
                const data = await this.$httpShipping.get(url, { headers: this.headers });
                if (data) {
                    this.$notify.success({ message: data.data });
                    this.apiFdxAccount = "";
                    this.apiFdxMeter = "";
                    this.apiFdxPassword = "";
                    this.apiFdxUsername = "";
                } else {
                    this.$notify.error({ message: data.data });
                }
            } catch (e) {
                this.$notify.error({ message: e });
            }
        },
    },
};
</script>
