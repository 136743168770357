<template>
    <div class="row d-none">
        <div id="step-one" class="single-step ltr-text">
            <p>Welcome to the Invoices page tour. Let's walk through the highlights together.</p>
            <br />
            <p>Click "Next" to learn about the features of this page.</p>
        </div>
        <div id="step-two" class="single-step ltr-text">
            <p>Finding invoices is easy by searching for account or invoice numbers in the search area.</p>
            <p></p>
        </div>
        <div id="step-three" class="single-step ltr-text">
            <p>Sort the invoices by any header value by clicking on it.</p>
        </div>
        <div id="step-four" class="single-step ltr-text">
            <p>
                Analyze any single invoice with Invoice Genie by selecting the row to view and clicking the "Analyze
                Invoice" button above.
            </p>
            <p></p>
        </div>
    </div>
</template>
<script>
export default {
    name: "InvoicesTour",
};
</script>
