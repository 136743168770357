<template>
    <mdb-container data-test="container" fluid class="ninjaDownloader">
        <h1 data-test="title" class="page-header border-bottom mx-2">
            Ninja Downloader
            <mdb-icon icon="user-ninja" size="1x" class="float-right text-muted" role="button" />
        </h1>
        <mdb-row class="pt-4">
            <mdb-col lg="12" sm="12" class="d-flex align-items-stretch">
                <mdb-card class="w-100">
                    <mdb-card-body class="p-4">
                        <mdb-row>
                            <mdb-col col="5">
                                <!-- <mdb-row> -->
                                <DateSelector
                                    data-test="DateSelector"
                                    id="dateDropdown"
                                    :startDate="startDate"
                                    :endDate="endDate"
                                    @update-end-date="updateEndDate"
                                    @update-start-date="updateStartDate"
                                    class="m-1 border rounded w-100"
                                />
                                <!-- </mdb-row> -->
                                <!-- <mdb-row> -->
                                <!-- Download file format selection not fully implemented -->
                                <!-- <mdb-dropdown
                                    id="format-dropdown"
                                    text="Select Download File Type"
                                    class="border rounded w-100 h-auto"
                                    multiLevel
                                >
                                    <mdb-dropdown-toggle :color="white" slot="toggle" class="w-100">{{
                                        activeFileFormat ? selectedFileFormatDisplay : "Select Download File Type"
                                    }}</mdb-dropdown-toggle>
                                    <mdb-dropdown-menu id="format-items">
                                        <form v-if="formats" class="w-100 mb-4">
                                            <div v-for="format of formats" v-bind:key="format.id" class="m-0 p-0">
                                                <mdb-input
                                                    type="checkbox"
                                                    :id="'format-checkbox-' + format.id"
                                                    :name="'format-checkbox-' + format.id"
                                                    :label="format.label"
                                                    v-model="format.selected"
                                                    @change="updateActiveFormats"
                                                />
                                            </div>
                                        </form>
                                    </mdb-dropdown-menu>
                                </mdb-dropdown> -->
                                <!-- </mdb-row> -->
                            </mdb-col>
                            <mdb-col col="5">
                                <!-- <mdb-row> -->
                                <mdb-dropdown data-test="clientDropdown" multiLevel class="my-1 border rounded w-100">
                                    <mdb-dropdown-toggle
                                        data-test="clientDropdownToggle"
                                        slot="toggle"
                                        class="w-100"
                                        color="white"
                                    >
                                        {{ selectedClientName ? selectedClientName : "Select Client" }}
                                    </mdb-dropdown-toggle>
                                    <mdb-dropdown-menu
                                        data-test="clientDropdownMenu"
                                        id="clients"
                                        v-if="clients"
                                        class="myDropdownScroll"
                                    >
                                        <input
                                            data-test="clientSearchTextInput"
                                            type="text"
                                            class="form-control"
                                            v-model="clientSearchText"
                                            placeholder="Client Name"
                                            @keydown.enter="updateClients"
                                        />
                                        <div class="text-center p-2" v-if="loadingClients">
                                            <mdb-spinner data-test="clientSpinner" small multicolor></mdb-spinner>
                                        </div>
                                        <div
                                            data-test="noClients"
                                            v-if="!loadingClients && !clients.length"
                                            class="p-2"
                                        >
                                            No clients found.
                                        </div>
                                        <mdb-list-group data-test="clientsListGroup" v-if="clients && !loadingClients">
                                            <mdb-list-group-item
                                                data-test="clientsListGroupItem"
                                                v-for="client of clients"
                                                v-on:click.native="setClient(client.cmp_id, client.cmp_name)"
                                                v-bind:key="client.cmp_id"
                                            >
                                                {{ client.cmp_name }} (ID: {{ client.cmp_id }})
                                            </mdb-list-group-item>
                                        </mdb-list-group>
                                    </mdb-dropdown-menu>
                                </mdb-dropdown>
                                <!-- </mdb-row> -->
                                <!-- <mdb-row> -->
                                <!-- Dropdown should be hiddun until a cmp is selected, then populate only the related accounts -->
                                <AccountDropdown
                                    data-test="AccountDropdown"
                                    v-if="selectedClientID"
                                    @update-account-selected="updateSelectedAccount"
                                    @update-selected-active="updateSelectedActive"
                                    color="white"
                                    :prepopulate="true"
                                    :userId="userInfo.usrId"
                                    :cmpId="selectedClientID"
                                    :headers="headers"
                                    class="btn-info"
                                    id="account-dropdown"
                                />
                                <!-- Known issue: unchecked top level items don't uncheck their children, making deselecting CPPs very tedious
                                    - also since all accounts from the cred that is used for the selected account(s) are downloaded with the current state of ninja, the deselection could be meaningless
                                    current:
                                    (selected account(s))->(related credential(s) retrieved)->(all accounts available to that cred checked/downloaded)
                                    desired:
                                    (selected account(s))->(related credential(s) retrieved)->(selected account(s) checked/downloaded)  -->
                                <!-- </mdb-row> -->
                            </mdb-col>
                            <mdb-col col="2">
                                <!-- Disable click unless all fields are filled -->
                                <mdb-btn
                                    data-test="submitDownloadRequestBtn"
                                    color="info"
                                    class="float-right"
                                    :disabled="!shouldButtonBeEnabled"
                                    @click.native="submitDownloadRequest"
                                >
                                    Submit Request
                                </mdb-btn>
                                <!-- Maybe add a loading indicator while it attempts to submit -->
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <mdb-row class="pt-4">
            <h3 data-test="completedDownloadsHeader" class="page-header mx-4">
                <!-- -->
                Completed Downloads (Last 2 Weeks)
            </h3>
            <mdb-col lg="12" sm="12" class="d-flex align-items-stretch">
                <mdb-card data-test="completedDownloadsCard" class="w-100">
                    <vue-good-table
                        data-test="completedDownloadsTable"
                        styleClass="vgt-table condensed"
                        :rows="downloadRecords"
                        :columns="downloadColumns"
                        :isLoading.sync="loadingDownloads"
                        :search-options="{
                            enabled: true,
                            trigger: 'enter',
                        }"
                        :sort-options="{
                            enabled: true,
                            initialSortBy: { field: 'completed', type: 'desc' },
                        }"
                        :pagination-options="{
                            enabled: true,
                            position: 'top',
                            dropdownAllowAll: true,
                            perPageDropdown: [10, 25, 50],
                        }"
                    >
                        <div slot="table-actions">
                            <mdb-btn
                                data-test="searchDownloadsBtn"
                                color="info"
                                class="float-right btn-refresh"
                                size="sm"
                                @click.native="searchDownloads"
                            >
                                <mdb-icon icon="sync-alt" class="mr-1" />Refresh
                            </mdb-btn>
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'actions'">
                                <mdb-icon
                                    v-if="props.row.status === 'Pending' || props.row.status === 'Running'"
                                    color="danger"
                                    class="ml-2 float-right"
                                    icon="ban"
                                    size="2x"
                                    @click.native="cancelTask(props.row)"
                                    >Cancel
                                </mdb-icon>
                                <mdb-icon
                                    v-if="props.row.action.includes('download') && props.row.status === 'Success'"
                                    color="info"
                                    class="float-right"
                                    icon="download"
                                    size="2x"
                                    @click.native="downloadResults(props.row)"
                                    >Download
                                </mdb-icon>
                            </span>
                        </template>
                    </vue-good-table>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <mdb-row class="pt-4 pb-5">
            <h3 data-test="requestDetailsHeader" class="page-header mx-4">
                <!-- -->
                Request Details (Last Day)
            </h3>
            <mdb-col lg="12" sm="12" class="d-flex align-items-stretch pb-5">
                <mdb-card data-test="requestDetailsCard" class="w-100">
                    <vue-good-table
                        data-test="requestDetailsTable"
                        styleClass="vgt-table condensed"
                        :rows="recentRecords"
                        :columns="recentColumns"
                        :isLoading.sync="loadingRecent"
                        :search-options="{
                            enabled: true,
                            trigger: 'enter',
                        }"
                        :sort-options="{
                            enabled: true,
                            initialSortBy: { field: 'created', type: 'desc' },
                        }"
                        :pagination-options="{
                            enabled: true,
                            position: 'top',
                            dropdownAllowAll: true,
                            perPageDropdown: [10, 25, 50],
                        }"
                    >
                        <div slot="table-actions">
                            <mdb-btn
                                data-test="searchTasksBtn"
                                color="info"
                                class="float-right btn-refresh"
                                size="sm"
                                @click.native="searchTasks"
                            >
                                <mdb-icon icon="sync-alt" class="mr-1" />Refresh
                            </mdb-btn>
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'actions'">
                                <mdb-icon
                                    v-if="props.row.status === 'Pending' || props.row.status === 'Running'"
                                    color="danger"
                                    class="ml-2 float-right"
                                    icon="ban"
                                    size="2x"
                                    @click.native="cancelTask(props.row)"
                                    >Cancel
                                </mdb-icon>
                                <mdb-icon
                                    v-if="props.row.action.includes('download') && props.row.status === 'Success'"
                                    color="info"
                                    class="float-right"
                                    icon="download"
                                    size="2x"
                                    @click.native="downloadResults(props.row)"
                                    >Download
                                </mdb-icon>
                            </span>
                        </template>
                    </vue-good-table>
                </mdb-card>
            </mdb-col>
            <!-- <div class="py-5">.</div> -->
        </mdb-row>
        <div class="overflow-auto h-90 px-3">
            <mdb-modal data-test="dlModal" class="px-3" size="fluid" :show="dlModal" @close="dlModal = false">
                <mdb-modal-header data-test="dlModalHeader">
                    <mdb-modal-title data-test="dlModalTitle">Download Options</mdb-modal-title>
                </mdb-modal-header>
                <mdb-modal-body data-test="dlModalBody">
                    <div data-test="dlResults" v-for="dlResult of dlResults" v-bind:key="dlResult.key">
                        <mdb-row data-test="dlResultsRow" v-if="dlResult.filename.includes('.txt-')">
                            <span class="mx-3">
                                Note: The default filename for FDX flat-file downloads will have some extra characters
                                attached to the .txt file-format.<br />It is safe to delete those when naming the
                                download, or renaming it afterwards to only have the ".txt" file extension.
                            </span>
                        </mdb-row>
                        <mdb-row id="dl-row">
                            <mdb-col data-test="formatFilenameCol" col="6">
                                {{ formatFilename(dlResult) }}
                            </mdb-col>
                            <mdb-col col="3">
                                <mdb-btn
                                    data-test="formatFilenameBtn"
                                    color="primary"
                                    tag="a"
                                    role="button"
                                    :href="dlResult.url"
                                    :download="formatFilename(dlResult)"
                                    >Download File <mdb-icon icon="file-download" class="ml-2"></mdb-icon>
                                </mdb-btn>
                            </mdb-col>
                            <!-- <mdb-col col="3">formatFilename(dlResult)
                                <mdb-btn color="success" @click="uploadToTI"
                                    >Send to be Standardized<mdb-icon icon="file-export" class="ml-2"></mdb-icon>
                                </mdb-btn>
                            </mdb-col> -->
                        </mdb-row>
                    </div>
                </mdb-modal-body>
                <!-- <mdb-modal-footer>
                    <mdb-btn color="white" @click.native="dlModal = false">Close</mdb-btn>
                </mdb-modal-footer> -->
            </mdb-modal>
        </div>
    </mdb-container>
</template>
<script>
import moment from "moment";
import DateSelector from "@/components/DateSelector";
import AccountDropdown from "@/components/AccountDropdown";
import {
    mdbContainer,
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbDropdown,
    mdbDropdownToggle,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbDropdownMenu,
    mdbListGroup,
    mdbListGroupItem,
} from "mdbvue";
export default {
    name: "AdminNinjaDownloader",
    components: {
        DateSelector,
        AccountDropdown,
        mdbContainer,
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardBody,
        mdbDropdown,
        mdbDropdownToggle,
        mdbBtn,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbDropdownMenu,
        mdbListGroup,
        mdbListGroupItem,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            downloadLimitDate: String(new moment().subtract("days", 14).format("YYYY-MM-DD")),
            resultsLimitDate: String(new moment().subtract("days", 1).format("YYYY-MM-DD")),
            startDate: new moment().subtract("days", 7).format("YYYY-MM-DD"),
            endDate: new moment().format("YYYY-MM-DD"),
            formats: [
                { id: "1", label: "Flat-file", selected: false },
                { id: "2", label: "PDF", selected: false },
            ],
            activeFileFormat: null,
            selectedFileFormat: null,
            selectedFileFormatDisplay: null,
            selectedClientID: null,
            selectedClientName: null,
            clients: null,
            clientSearchText: null,
            loadingClients: false,
            selectedAccounts: [],
            selectedAccountActive: null,
            downloadColumns: [
                {
                    label: "Completed",
                    field: "completed",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd HH:mm:ss.SSSSSSxxx",
                    dateOutputFormat: "yyy-MM-dd h:mmb",
                },
                {
                    label: "Format",
                    field: "action",
                    formatFn: this.formatFileType,
                },
                {
                    label: "Invoice Date Range",
                    field: "session.workflow_parameters",
                    formatFn: this.formatDateRange,
                },
                {
                    label: "Company",
                    field: "session.parameters.company_name",
                },
                {
                    label: "Carrier",
                    field: (rowObj) => {
                        return rowObj.session.carrier.toUpperCase();
                    },
                },
                {
                    label: "Account(s)",
                    field: "parameters.account",
                },
                {
                    label: "Files",
                    field: "result",
                    formatFn: this.formatResultCount,
                },
                {
                    label: "Options",
                    field: "actions",
                },
            ],
            recentColumns: [
                {
                    label: "Created",
                    field: "created",

                    type: "date",
                    dateInputFormat: "yyyy-MM-dd HH:mm:ss.SSSSSSxxx",
                    dateOutputFormat: "yyy-MM-dd h:mmb", //'MMM do, `yy h:mmb'
                },
                {
                    label: "Action",
                    field: "action",
                },
                {
                    label: "Status",
                    field: "status",
                },
                {
                    label: "Invoice Date Range",
                    field: "session.workflow_parameters",
                    formatFn: this.formatDateRange,
                },
                {
                    label: "Company",
                    field: "session.parameters.company_name",
                },
                {
                    label: "Carrier",
                    field: (rowObj) => {
                        return rowObj.session.carrier.toUpperCase();
                    },
                },
                {
                    label: "Account(s)",
                    field: "parameters.account",
                },
                // {
                //     label: "Format",
                //     field: "parameters.format",
                // },
                {
                    label: "Completed",
                    field: "completed",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd HH:mm:ss.SSSSSSxxx",
                    dateOutputFormat: "yyy-MM-dd h:mmb",
                },
                {
                    label: "Results",
                    field: "result",
                    formatFn: this.formatResultCount,
                },
                {
                    label: "Options",
                    field: "actions",
                },
            ],
            recentRecords: null,
            downloadRecords: null,
            loadingRecent: false,
            loadingDownloads: false,
            dlModal: false,
            dlResults: null,
            dlCarrier: null,
        };
    },
    computed: {
        shouldButtonBeEnabled() {
            const client = this.selectedClientID;
            const accounts = this.selectedAccounts.length;
            const fileFormats = true; // this.activeFileFormat; // Not fully implemented
            return client && accounts && fileFormats ? true : false;
        },
    },
    methods: {
        updateStartDate(val) {
            let startDate = new moment(val).format("YYYY-MM-DD");
            this.startDate = startDate;
        },
        updateEndDate(val) {
            let endDate = new moment(val).format("YYYY-MM-DD");
            this.endDate = endDate;
        },
        updateActiveFormats() {
            this.activeFileFormat = false;
            this.selectedFileFormatDisplay = "";
            let selectedFormats = [];
            for (const format of this.formats) {
                if (format.selected) {
                    this.activeFileFormat = true;
                    selectedFormats.push(format.label);
                }
            }
            this.selectedFileFormat = selectedFormats;
            this.selectedFileFormatDisplay = selectedFormats.join(", ").trim(",");
        },
        updateSelectedAccount(accountObj) {
            let selectedAccounts = [];
            for (let carrier in accountObj) {
                // let carrierChecked
                // maybe make carrierChecked work like how CPPselected is used below
                if (accountObj[carrier].children) {
                    for (let account in accountObj[carrier].children) {
                        let parentAccountObj = accountObj[carrier].children[account];
                        let isCPP = Boolean(parentAccountObj.children);
                        let CPPselected = false;
                        if (parentAccountObj.selected && isCPP) {
                            CPPselected = true;
                        } else if (parentAccountObj.selected && !isCPP) {
                            selectedAccounts.push({ account_id: parentAccountObj.id, is_CPP: isCPP });
                        }
                        if (isCPP) {
                            let CPPList = [];
                            for (let childAccount in parentAccountObj.children) {
                                let childAccountObj = parentAccountObj.children[childAccount];
                                if (childAccountObj.selected || CPPselected) {
                                    CPPList.push(childAccountObj.id);
                                }
                            }
                            selectedAccounts.push({ account_id: CPPList, is_CPP: isCPP });
                        }
                    }
                }
            }
            this.selectedAccounts = selectedAccounts;
        },
        updateSelectedActive(val) {
            this.selectedAccountActive = val;
        },
        async getClients() {
            const clients = await this.$httpAdmin.get("/company/light", { headers: this.headers });
            this.clients = clients && clients.data ? clients.data : {};
        },
        async updateClients() {
            let clients;
            const search = this.clientSearchText;
            this.clients = {};
            this.loadingClients = true;
            if (!search) {
                await this.getClients();
            } else {
                clients = await this.$httpAdmin.get(
                    `/admincompany?cmp_name=${search}&sortColumn=cmp_id&sortkey=cmp_id&sortDir=desc`,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Basic ${window.btoa(this.userInfo.adminPass)}`,
                        },
                    }
                );
                this.clients = clients.data;
            }
            this.loadingClients = false;
        },
        setClient(clientID, clientName) {
            this.selectedClientID = clientID;
            this.selectedClientName = clientName;
        },
        async createNinjaRequests(credentials) {
            let todayPlain = new moment().format("YYYYMMDD").toString();
            let download_prefix = ["eAudit", "UI", this.userInfo.cmpID, this.userInfo.usrId, todayPlain].join("/");
            for (const cred in credentials) {
                let thisCred = credentials[cred];
                for (const cType in thisCred.credential_type) {
                    let args = {
                        username: thisCred.credential_username,
                        password: thisCred.credential_password,
                        carrier: thisCred.credential_carrier,
                        download_prefix: download_prefix,
                        workflow: "standard_download",
                        workflow_parameters: {
                            start_date: this.startDate,
                            end_date: this.endDate,
                            account_type: thisCred.credential_type[cType],
                            country: thisCred.credential_country,
                        },
                        tags: {
                            eaudit_process: "downloader",
                            company: "eAudit",
                            ui_request: true,
                            date: todayPlain,
                            credential_id: thisCred.credential_id,
                            user_id: this.userInfo.usrId,
                        },
                        parameters: {
                            company_id: this.selectedClientID,
                            company_name: this.selectedClientName,
                        },
                        priority: 1234,
                    };
                    // send request
                    const response = await this.$httpNinja.post(`/sessions/session/`, args);
                    if (response) {
                        this.$notify.success({ message: "Job request successfully submitted!" });
                    } else {
                        this.$notify.error({
                            message: "1There was an issue submitting the request. Please wait a moment, and try again.",
                        });
                    }
                }
            }
        },
        async submitDownloadRequest() {
            let nCPPaccounts = [];
            let CPPaccounts = [];
            for (let account in this.selectedAccounts) {
                if (this.selectedAccounts[account].is_CPP) {
                    CPPaccounts.push(this.selectedAccounts[account].account_id[0]);
                } else {
                    nCPPaccounts.push(this.selectedAccounts[account].account_id);
                }
            }
            // console.log(nCPPaccounts);
            let credentials = await this.$httpAdmin.post(
                `account/ninja`,
                { account_ids: nCPPaccounts.concat(CPPaccounts) },
                { headers: this.headers }
            );
            credentials = credentials.data;
            // flatten the return into unique jobs
            let creds = [];
            for (const cred in credentials) {
                let thisCred = credentials[cred];
                if (cred === "$id" && thisCred == 1) {
                    continue;
                }
                let carrier = "";
                switch (thisCred.credential_car_id) {
                    case 1:
                        carrier = "fdx";
                        break;
                    case 2:
                        carrier = "ups";
                        break;
                    default:
                        continue; // Carrier not supported by Ninja
                }
                let accountType = thisCred.credential_type;
                if (CPPaccounts.includes(cred)) {
                    accountType = "billing center cpp";
                }
                // check if this cred exists in creds already
                let credIndex = creds.findIndex((obj) => obj.credential_id === thisCred.credential_id);
                if (credIndex != -1) {
                    if (!creds[credIndex].credential_type.includes(accountType)) {
                        creds[credIndex].credential_type.push(accountType);
                    }
                    continue;
                }
                creds.push({
                    credential_id: thisCred.credential_id,
                    credential_carrier: carrier,
                    credential_username: thisCred.credential_username,
                    credential_password: thisCred.credential_password,
                    credential_type: [accountType],
                    credential_country: thisCred.credential_country,
                });
            }
            // create and send the request(s)
            await this.createNinjaRequests(creds);
            // refresh the review table
            this.searchTasks();
        },
        async searchTasks() {
            this.loadingRecent = true;
            let args = {
                filter: {
                    tags__eaudit_process: "downloader",
                    tags__ui_request: true,
                    tags__user_id: this.userInfo.usrId,
                    created__gte: this.resultsLimitDate,
                    // "tags__date": "20220303",
                },
            };
            try {
                const tasks = await this.$httpNinja.post(`/tasks/search/`, args);
                if (tasks) {
                    this.recentRecords = tasks.data;
                }
            } catch (e) {
                this.$notify.error({ message: "No tasks found." });
            }
            this.loadingRecent = false;
        },
        async searchDownloads() {
            this.loadingDownloads = true;
            let args = {
                filter: {
                    tags__eaudit_process: "downloader",
                    tags__ui_request: true,
                    tags__user_id: this.userInfo.usrId,
                    action__in: ["download_all_invoices", "download_invoice_file", "download_invoice_pdfs"],
                    files_downloaded__gt: 0,
                    created__gte: this.downloadLimitDate,
                    // "tags__date": "20220303",
                },
            };
            try {
                const tasks = await this.$httpNinja.post(`/tasks/search/`, args);
                if (tasks) {
                    this.downloadRecords = tasks.data;
                }
            } catch (e) {
                this.$notify.error({ message: "No tasks found." });
            }
            this.loadingDownloads = false;
        },
        async downloadResults(tableRow) {
            this.dlCarrier = tableRow.session.carrier;
            let args = {
                filter: {
                    id: tableRow.id,
                },
            };
            try {
                const tasks = await this.$httpNinja.post(`/tasks/downloads/`, args);
                if (tasks) {
                    this.dlResults = tasks.data[0].downloads;
                    this.dlModal = true;
                }
            } catch (e) {
                this.$notify.error({ message: "No tasks found." });
            }
        },
        async cancelTask(tableRow) {
            let uri = `/tasks/${tableRow.id}/cancel/`;
            let carrier = tableRow.session.carrier.toUpperCase();
            let sTime = moment(tableRow.created).format("YYYY-MM-DD h:mmA"); //'MMM Do, `YY h:mmA'
            let cMessage = `Are you sure you want to cancel the ${carrier} ${tableRow.action} task from ${sTime}?`;
            cMessage += '\nClick "OK" to confirm.';
            if (confirm(cMessage)) {
                try {
                    const response = await this.$httpNinja.delete(uri);
                    this.$notify.success({ message: `Task successfully canceled. ${response}` });
                } catch (e) {
                    this.$notify.error({ message: `Failed to cancel task. Please try again.\nError: ${e}` });
                }
                this.searchTasks();
            }
        },
        formatFileType(val) {
            if (val === "download_invoice_pdfs") {
                return "PDF";
            } else {
                return "Flat-file";
            }
        },
        formatDateRange(val) {
            let start_date = String(new moment(val.start_date).format("YYYY-MM-DD"));
            let end_date = String(new moment(val.end_date).format("YYYY-MM-DD"));
            return start_date.concat(" - ", end_date);
        },
        formatResultCount(val) {
            const resultCount = val.length;
            if (val) {
                if (resultCount === undefined) return 1;
                else if (resultCount !== null) return resultCount;
            }
            return "N/A"; //maybe comment out so it's just blank?
        },
        formatFilename(val) {
            const keyParts = val.key.split("/");
            let fileType = keyParts[6];
            let carrier = keyParts[7];
            let pdfFile = keyParts[5];
            if (carrier === "ups" || pdfFile === 'pdf') return val.filename;
            else if (carrier === "fdx" && fileType === "invoices") {
                return val.filename.split("-")[0];
            }
        },
    },
    mounted() {
        this.getClients();
        this.searchTasks();
        this.searchDownloads();
    },
};
</script>
<style>
.myDropdownScroll {
    overflow-y: scroll;
    height: 250px;
}
.ninjaDownloader .dropdown-menu {
    margin-top: -30px !important;
    padding-top: 4px !important;
}
.btn-refresh {
    margin: 0px !important;
    margin-right: 6px !important;
}
#dl-row {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
