<template>
    <div data-test="page" class="m-4 carrierAccounts">
        <h3 data-test="pageTitle" class="my-4 mx-2">
            Audit Errors <em data-test="pageTitleSmall" class="small">Analyze Package Errors</em>
        </h3>
        <div class="w-100 m-2">
            <mdb-card data-test="triageInstructionsCard">
                <mdb-card-header data-test="cardHeader" color="warning-color" tag="h3"
                    >Triage Instructions</mdb-card-header
                >
                <mdb-card-body>
                    <p data-test="cardBodyp1">
                        <strong>What it is: </strong>
                        Packages that have errors during the audit
                        <br />
                        <strong> What to do:</strong>
                        Monitor for high percentage audit errors
                        <br />
                        <strong>Frequency: </strong>Per Audit<br />
                        <br />
                    </p>

                    <p data-test="cardBodyp2"><strong>Typical Errors: </strong></p>
                    <p data-test="cardBodyp3">
                        <strong>No TNT: </strong> We cannot get Time in Transit information for these packages.
                    </p>
                    <p data-test="cardBodyp4">
                        <strong>Tracking number not found: </strong> We may be sending mal-formed tracking numbers to
                        the carrier tracking API
                    </p>
                </mdb-card-body>
            </mdb-card>
        </div>

        <div class="w-100">
            <vue-good-table
                data-test="vue-good-table"
                :rows="records"
                :columns="columns"
                mode="remote"
                :totalRows="totalRows"
                :isLoading.sync="loading"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-search="getErrors"
                :search-options="{
                    enabled: true,
                    trigger: 'enter',
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: this.limit,
                    position: 'top',
                    perPageDropdown: [10, 25, 50],
                    dropdownAllowAll: false,
                    setCurrentPage: this.page,
                }"
            >
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'date'">
                        {{ formatDate(props.row.date) }}
                    </div>
                    <div v-else-if="props.column.field === 'cmp_type'">
                        <mdb-icon
                            color="success"
                            icon="circle"
                            class="mr-2"
                            v-if="props.row.cmp_type === 'Live'"
                        ></mdb-icon>
                        <mdb-icon
                            color="warning"
                            icon="clock"
                            class="mr-2"
                            v-if="props.row.cmp_type === 'Mock'"
                        ></mdb-icon>
                        <mdb-icon
                            color="warning"
                            icon="circle"
                            class="mr-2"
                            v-if="props.row.cmp_type === 'Load'"
                        ></mdb-icon>
                        <mdb-icon
                            color="success"
                            icon="clock"
                            class="mr-2"
                            v-if="props.row.cmp_type === 'Trial'"
                        ></mdb-icon>
                        <mdb-icon
                            color="danger"
                            icon="circle"
                            class="mr-2"
                            v-if="props.row.cmp_type === 'Inactive'"
                        ></mdb-icon>
                        <mdb-icon
                            color="warning"
                            icon="ban"
                            class="mr-2"
                            v-if="props.row.cmp_type === 'Hold'"
                        ></mdb-icon>
                        <mdb-icon
                            color="warning"
                            icon="question-circle"
                            class="mr-2"
                            v-if="props.row.cmp_type === 'Suspect'"
                        ></mdb-icon>
                        {{ props.row.cmp_type }}
                    </div>
                    <div v-else-if="props.column.field === 'cmp_is_active'">
                        <span v-if="props.row.cmp_is_active">
                            <mdb-icon color="success" icon="circle" class="mr-2"></mdb-icon> Active
                        </span>
                        <span v-else><mdb-icon icon="circle" color="danger" class="mr-2"></mdb-icon> Inactive</span>
                    </div>
                    <div v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { mdbCard, mdbCardHeader, mdbCardBody } from "mdbvue";

export default {
    name: "AdminAuditPackageErrors",
    components: {
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            records: [],
            columns: [
                {
                    label: "Cmp ID",
                    field: "cmp_id",
                },
                {
                    label: "Audit ID",
                    field: "audit_id",
                },
                {
                    label: "Audit Date",
                    field: "date",
                },
                {
                    label: "Company Name",
                    field: "cmp_name",
                },
                {
                    label: "Carrier",
                    field: "car_name",
                },
                {
                    label: "Tracking Number",
                    field: "pld_trackingno",
                },
                {
                    label: "System Message",
                    field: "errpack_error_desc",
                },
                {
                    label: "Type",
                    field: "cmp_type",
                },

                {
                    label: "Status",
                    field: "cmp_is_active",
                },
            ],
            loading: false,
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "cmp_is_active",
            sortDir: "desc",
            sortkey: "cmp_is_active",
            searchTerm: null,
            totalRows: null,
        };
    },
    computed: {
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
                startDate: moment(this.startDate).format("YYYY-MM-DD"),
                endDate: moment(this.endDate).format("YYYY-MM-DD"),
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
    },
    methods: {
        formatDate(val) {
            return moment(val).format("MMM DD, YYYY");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.getErrors();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.getErrors();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.getErrors();
        },
        async getErrors(params) {
            this.loading = true;
            let search;
            if (params) {
                search = `&cmp_name=${params.searchTerm}`;
            }
            try {
                const errors = await this.$httpAdmin.get(`errorpackage?${this.params}${search ? search : ""}`, {
                    headers: this.headers,
                });
                if (errors) {
                    this.records = errors.data[0].Items;
                }
            } catch (e) {
                this.$notify.error({ message: "No errors found." });
            }
            this.loading = false;
        },
    },
    mounted() {
        this.getErrors();
    },
};
</script>
