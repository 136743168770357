<template>
    <div style="overflow-x: hidden" class="pr-4 pb-4 vh-100">
        <div>
            <NavBar
                v-if="isAuthenticated"
                slot="nav"
                :userInfo="userInfo"
                @isAuthenticated="setAuthenticated"
                @userInfo="updateUser"
                @change-side-nav="updateShowSideNav"
                @close-side-nav="closeSideNav"
                @close-invoice-genie="closeInvoiceGenie"
                @close-package-genie="closePackageGenie"
                @open-invoice-genie="openInvoiceGenie"
                @open-package-genie="openPackageGenie"
                :headers="headers"
                :services="services"
            />
            <div style="height: 100vh" slot="main">
                <div class="w-100 mb-4 mt-10" app id="app">
                    <div tag="div" class="d-flex mx-4" v-if="isAuthenticated">
                        <mdb-breadcrumb-item tag="span">
                            <router-link
                                to="/admin"
                                v-if="breadcrumbs.path.match('admin')"
                                @click.native="showSideNav = false"
                            >
                                <mdb-icon icon="home"></mdb-icon>
                            </router-link>
                            <router-link to="/" v-else @click.native="showSideNav = false">
                                <mdb-icon icon="home"></mdb-icon>
                            </router-link>
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item v-if="breadcrumbs.meta.parent" tag="span">
                            {{ breadcrumbs.meta.parent }}
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item tag="span">
                            {{ breadcrumbs.meta.title }}
                        </mdb-breadcrumb-item>
                    </div>
                    <router-view
                        @isAuthenticated="setAuthenticated"
                        @userInfo="updateUser"
                        :downloadUniversal="downloadUniversal"
                        :userInfo="userInfo"
                        :headers="headers"
                        :services="services"
                        :activateAddon="activateAddon"
                        :deactivateAddon="deactivateAddon"
                        class="m-2"
                        :selectedCompany="selectedCompany"
                    />
                    <mdb-popover trigger="click" :options="{ placement: 'top' }" v-if="isAuthenticated">
                        <span slot="body">support-audit@transimpact.com</span>
                        <mdb-btn-fixed
                            gradient="rainy-ashville"
                            color="light-blue"
                            :bottom="30"
                            :right="30"
                            size="lg"
                            slot="reference"
                            icon="question"
                        ></mdb-btn-fixed>
                    </mdb-popover>
                </div>
            </div>
            <SideNav
                v-if="isAuthenticated"
                :userInfo="userInfo"
                :headers="headers"
                :services="services"
                :showSideNav="showSideNav"
                :companyOptions="companyOptions"
                :loginAs="loginAs"
                @show-invoice="showInvoice()"
                @show-package="showPackage()"
                @close-side-nav="closeSideNav"
            ></SideNav>
        </div>
        <mdb-modal
            data-test="showPackageGenieModal"
            id="packageGenie"
            :show="showPackageGenie"
            @close="closePackageGenie"
            title="Package Genie"
            size="fluid"
        >
            <mdb-modal-header data-test="showPackageGenieModalHeader">
                <mdb-modal-title data-test="showPackageGenieModalTitle" class="w-full">
                    <mdb-icon icon="magic" class="mr-3" />
                    <span>Package Genie</span>
                </mdb-modal-title>
            </mdb-modal-header>
            <PackageGenie
                v-if="selected"
                :invoiceNum="selected.invoice_number"
                :userInfo="userInfo"
                :headers="headers"
                :package="selected"
                @close-invoice-genie="closeInvoiceGenie"
                @close-package-genie="closePackageGenie"
                @open-invoice-genie="openInvoiceGenie"
                @open-package-genie="openPackageGenie"
            />
        </mdb-modal>
        <mdb-modal id="invoiceGenie" :show="showInvoiceGenie" @close="closeInvoiceGenie" size="fluid">
            <mdb-modal-header>
                <mdb-modal-title><mdb-icon icon="magic" class="mr-3" /> Invoice Genie</mdb-modal-title>
            </mdb-modal-header>
            <InvoiceGenie
                :invoice="selected"
                :userInfo="userInfo"
                :headers="headers"
                @close-invoice-genie="closeInvoiceGenie"
                @close-package-genie="closePackageGenie"
                @open-invoice-genie="openInvoiceGenie"
                @open-package-genie="openPackageGenie"
            />
        </mdb-modal>
    </div>
</template>
<script>
import NavBar from "./components/NavBar";
import SideNav from "@/components/SideNav";
import { mdbIcon, mdbModal, mdbModalHeader, mdbModalTitle } from "mdbvue";
import PackageGenie from "@/components/PackageGenie.vue";
import InvoiceGenie from "@/components/InvoiceGenie.vue";

export default {
    name: "app",
    components: {
        InvoiceGenie,
        mdbModalHeader,
        PackageGenie,
        mdbModalTitle,
        mdbIcon,
        mdbModal,
        NavBar,
        SideNav,
    },
    data() {
        return {
            show: true,
            currentRoute: window.location.pathname,
            isAuthenticated: false,
            userInfo: null,
            breadcrumbs: null,
            premiumServices: null,
            showSideNav: false,
            company: "Login As",
            openPaneNum: 0,
            searchTextComp: null,
            companies: null,
            loadingCompanies: false,
            showAnalyzeDropdown: false,
            toggleA: true,
            showInvoiceGenie: false,
            showPackageGenie: false,
            selected: null,
            showCompaniesOptions: false,
            selectedCompany: {},
            loading: true,
            loginAs: false,
            companyOptions: [],
            companyOptionsHeaders: {
                Authorization: "Basic " + window.btoa(process.env.VUE_APP_API_KEY),
                "Content-Type": "application/json",
            },
        };
    },
    methods: {
        async showInvoice() {
            this.showInvoiceGenie = true;
        },
        async showPackage() {
            this.showPackageGenie = true;
        },
        handlePaneOpened(num) {
            if (this.openPaneNum == num) {
                return (this.openPaneNum = null);
            }
            this.openPaneNum = num;
        },
        updateShowSideNav() {
            this.showSideNav = !this.showSideNav;
        },
        closeSideNav() {
            this.showSideNav = false;
        },
        closeInvoiceGenie() {
            this.showInvoiceGenie = false;
        },
        closePackageGenie() {
            this.showPackageGenie = false;
        },
        openInvoiceGenie(invoice) {
            this.selected = invoice;
            this.showPackageGenie = false;
            this.showInvoiceGenie = true;
        },
        openPackageGenie(trackingInfo) {
            this.selected = trackingInfo;
            this.showInvoiceGenie = false;
            this.showPackageGenie = true;
        },
        async getPaymentPlan() {
            const premiumServices = await this.$http.get(`companypremiumservice/${this.userInfo.cmpID}`, {
                headers: this.headers,
                "Cmp-ID": this.userInfo.cmpID,
            });
            this.premiumServices = premiumServices && premiumServices.data ? premiumServices.data : null;
        },
        async downloadUniversal(key, format) {
            const url = `reportfiledownload/${key}`;
            let report;
            try {
                report = await this.$httpReports.get(url, {
                    responseType: "arraybuffer",
                    headers: {
                        ...this.headers,
                        Accept: "application/octet-stream",
                        "Content-Type": "application/octet-stream",
                        "Cmp-ID": this.userInfo.cmpID,
                        "User-ID": this.userInfo.usrId,
                    },
                });
                if (!report) {
                    this.$notify.error({
                        message: "There was an error downloading your file. Please try again later.",
                    });
                    return;
                }
            } catch (e) {
                this.$notify.error({ message: "There was an error downloading your file. Please try again later." });
            }
            const content = report.data;
            const blob = new Blob([content]);
            const fileName = await this.getFileName(key);
            if ("download" in document.createElement("a")) {
                // non-IE download
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // Release the URL object
                document.body.removeChild(elink);
            } else {
                // IE10+ download
                navigator.msSaveBlob(blob, fileName);
            }
        },
        async getFileName(key) {
            const url = `reportgenerated/${key}`;
            let name;
            try {
                name = await this.$httpReports.get(url, {
                    headers: this.headers,
                });
            } catch (e) {
                this.$notify.error({ message: "Unable to determine file name." });
            }
            return name.data;
        },
        setAuthenticated(status) {
            this.isAuthenticated = status;
        },
        updateUser(user) {
            this.userInfo = user;
        },

        async activateAddon(service) {
            try {
                await this.$http.post("companypremiumservice", { cps_name: service }, { headers: this.headers });
                this.$notify.success({
                    message: `${service.charAt(0).toUpperCase() + service.slice(1)} was successfully activated.`,
                });
                this.getPaymentPlan();
            } catch (e) {
                this.$notify.error({
                    message: `${service.charAt(0).toUpperCase() + service.slice(1)} could not be activated.`,
                });
            }
        },

        async deactivateAddon(service) {
            let url = "/companypremiumservice/" + service;
            await this.$http
                .delete(url, {
                    headers: {
                        ...this.headers,
                        "Cmp-ID": this.userInfo.cmpID,
                        "User-ID": this.userInfo.usrId,
                        type: "DEL_NOTYET",
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.$notify.success({
                            message: `${
                                service.charAt(0).toUpperCase() + service.slice(1)
                            } was successfully deactivated.`,
                        });
                        this.getPaymentPlan();
                    } else {
                        this.$notify.error({
                            message: `${service.charAt(0).toUpperCase() + service.slice(1)} could not be deactivated.`,
                        });
                    }
                })
                .catch((error) => {
                    this.$notify.error({
                        message: `${service.charAt(0).toUpperCase() + service.slice(1)} could not be deactivated.`,
                    });
                });
        },
    },

    async created() {
        // TODO check if token is still valid
        this.userInfo = JSON.parse(localStorage.getItem("user"));
        if (this.userInfo && localStorage.getItem("token")) {
            this.isAuthenticated = true;
        }
        this.breadcrumbs = this.$router.currentRoute;
        this.loading = false;
    },
    mounted() {
        this.getPaymentPlan();
    },
    computed: {
        headers() {
            if (this.isAuthenticated && this.userInfo) {
                return {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Basic " + window.btoa(this.userInfo.usrAccessKey),
                    "Cmp-ID": this.userInfo.cmpID,
                    "User-ID": this.userInfo.usrId,
                };
            } else {
                return {};
            }
        },
        services() {
            return this.premiumServices;
        },
    },
    watch: {
        $route() {
            this.breadcrumbs = this.$router.currentRoute;
        },

        async userInfo() {
            if (this.isAuthenticated && this.userInfo) {
                let companyOptions = [];
                const companies = await this.$httpAdmin.get(
                    "company/light?sortColumn=cmp_name&cmp_is_active=true&cmp_is_deleted=false",
                    { headers: this.companyOptionsHeaders }
                );
                for (let i = 0; i < companies.data.length; i++) {
                    if (this.userInfo.usrCompanies.includes(companies.data[i].cmp_id)) {
                        companyOptions.push(companies.data[i]);
                    }
                }
                this.companyOptions = companyOptions;
            }
        },
    },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

.b-table-row-selected {
    background-color: #5bc0de !important;
}

.text-xs {
    font-size: 11px !important;
}

.text-sm {
    font-size: 12px !important;
}

.text-md {
    font-size: 13px !important;
}

.mt-10 {
    margin-top: 80px;
}

.vh-100 {
    height: 100vh;
    overflow-x: hidden;
}

.modal-dialog {
    margin-top: 100px !important;
}

.bluronhover {
    color: transparent;
    text-shadow: 0 0 9px rgb(0 0 0);
}

.blurnohover:hover {
    color: #000000;
    text-shadow: none;
}
</style>
