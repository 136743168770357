<template>
    <mdb-container fluid class="myReports">
        <PageHeader :pageSubTitle="``" :pageTitle="`My Reports`" :iconName="`copy`" :startTour="startTour" />
        <hr />
        <mdb-row class="justify-content-center" v-if="loading"><mdb-spinner></mdb-spinner>...Loading</mdb-row>
        <mdb-row v-else>
            <mdb-col col="7">
                <mdb-row>
                    <mdb-col col="6">
                        <mdb-dropdown
                            v-if="showReports"
                            id="report-select"
                            class="w-100 border border-gray-200 rounded"
                            block
                            boundary="window"
                        >
                            <mdb-dropdown-toggle slot="toggle" class="w-100" :color="selectedReport ? 'info' : 'white'">
                                {{ selectedReport ? selectedReport.name : "Select a Report" }}
                            </mdb-dropdown-toggle>
                            <mdb-dropdown-menu class="w-100">
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    @click.stop
                                    v-model="dropdownFilter"
                                    @keydown="filterSearch"
                                />
                            </mdb-dropdown-menu>
                            <mdb-dropdown-menu
                                v-if="generalReportsList.length > 0"
                                id="generatedReports"
                                class="myReportsScroll w-100"
                            >
                                <h6 class="dropdown-header">General Reports</h6>
                                <mdb-dropdown-item
                                    v-for="report of generalReportsList"
                                    @click="changeSelectedReport(report)"
                                    v-bind:key="report.id"
                                >
                                    {{ report.name }}
                                </mdb-dropdown-item>
                            </mdb-dropdown-menu>
                            <div class="dropdown-divider"></div>
                            <mdb-dropdown-menu id="customReports" class="myReportsScroll w-100">
                                <h6 class="dropdown-header">Custom Reports</h6>
                                <mdb-dropdown-item
                                    v-for="report of customReportsList"
                                    @click="changeSelectedReport(report)"
                                    v-bind:key="report.id"
                                >
                                    {{ report.name }}
                                </mdb-dropdown-item>
                            </mdb-dropdown-menu>
                            <mdb-dropdown-menu id="systemReports" class="myReportsScroll w-100">
                                <h6 class="dropdown-header">System Reports</h6>
                                <mdb-dropdown-item
                                    v-for="report of systemReportsList"
                                    @click="changeSelectedReport(report)"
                                    v-bind:key="report.id"
                                >
                                    {{ report.name }}
                                </mdb-dropdown-item>
                            </mdb-dropdown-menu>
                        </mdb-dropdown>
                        <DateSelector
                            id="dateDropdown"
                            :startDate="startDate"
                            :endDate="endDate"
                            @update-end-date="updateEndDate"
                            @update-start-date="updateStartDate"
                            class="m-1 border rounded w-100"
                        />
                        <div id="account-select" v-if="selectedCustomer">
                            <div class="w-100">
                                <AccountDropdown
                                    @update-account-selected="updateSelectedAccount"
                                    @update-selected-active="updateSelectedActive"
                                    :userId="userInfo.usrId"
                                    :cmpId="selectedCustomer.cmp_id"
                                    :headers="headers"
                                />
                            </div>
                        </div>
                    </mdb-col>
                    <mdb-col col="6">
                        <mdb-dropdown id="dropdown-form2" class="mb-1 border rounded w-100">
                            <mdb-dropdown-toggle slot="toggle" class="w-100" :color="tempFormat ? 'info' : 'white'">
                                {{
                                    tempFormat
                                        ? tempFormat == "html"
                                            ? "SCREEN"
                                            : tempFormat == "xls"
                                            ? "EXCEL"
                                            : tempFormat
                                        : "Select Format"
                                }}
                            </mdb-dropdown-toggle>
                            <mdb-dropdown-menu id="formats" v-if="formats">
                                <mdb-dropdown-item
                                    v-for="(type, index) of formats"
                                    v-bind:key="index"
                                    class="p-1 text-lg-left text-uppercase"
                                    @click.native="setTempFormat(type)"
                                >
                                    {{ type == "html" ? "screen" : type == "xls" ? "excel" : type }}
                                </mdb-dropdown-item>
                            </mdb-dropdown-menu>
                        </mdb-dropdown>
                        <mdb-dropdown class="mb-1 border rounded w-100">
                            <mdb-dropdown-toggle
                                slot="toggle"
                                class="w-100"
                                :color="selectedCustomer ? 'info' : 'white'"
                            >
                                {{ selectedCustomer ? selectedCustomer.cmp_name : "Choose Customer" }}
                            </mdb-dropdown-toggle>
                            <mdb-dropdown-menu class="w-100">
                                <input
                                    class="form-control w-100"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    @click.stop
                                    v-model="customerFilter"
                                    @keydown="customerSearch"
                                />
                            </mdb-dropdown-menu>
                            <mdb-dropdown-menu id="customers" v-if="customers" class="myReportsScroll w-100">
                                <mdb-dropdown-item
                                    v-for="(customer, index) of customerList"
                                    v-bind:key="index"
                                    class="p-1 text-lg-left text-uppercase"
                                    @click="updateCustomer(customer)"
                                >
                                    {{ customer.cmp_name }} (ID: {{ customer.cmp_id }})
                                </mdb-dropdown-item>
                            </mdb-dropdown-menu>
                        </mdb-dropdown>
                    </mdb-col>
                </mdb-row>
                <hr />
                <mdb-row>
                    <mdb-col col="6">
                        <mdb-btn
                            id="run-report"
                            class="w-100 ml-0"
                            variant="info"
                            @click.native="generateReport()"
                            v-if="!runningReport"
                            >Run Report</mdb-btn
                        >
                        <mdb-btn class="w-100 ml-0" variant="info" disabled v-if="runningReport">
                            <mdb-icon icon="spinner" spin />
                            Generating Report...</mdb-btn
                        >
                    </mdb-col>
                </mdb-row>
            </mdb-col>
            <mdb-col col="5">
                <mdb-tabs
                    :active="0"
                    default
                    card
                    :links="[{ icon: 'file-alt', text: 'Reports Details', slot: 'reports-details' }]"
                    :transition-duration="0.5"
                    transition-style="linear"
                    class="mr-1"
                >
                    <template :slot="'reports-details'">
                        <template slot="text"> <mdb-icon icon="file-alt" class="mr-2" /> Report Details </template>
                        <span v-if="!selectedReport">Select a report to view the details and description.</span>
                        <span v-if="selectedReport && !reportDownloadLink">{{ selectedReport.description }}</span>
                        <mdb-btn
                            v-if="reportDownloadLink && reportDownloadLink != 'no data' && selectedFormat != 'html'"
                            variant="info"
                            @click.native="downloadReport(reportDownloadLink)"
                            ><mdb-icon icon="download" /> Download Report</mdb-btn
                        >
                        <span
                            v-if="reportDownloadLink && (selectedFormat == 'html' || reportDownloadLink == 'no data')"
                        >
                            <mdb-icon color="success" icon="check" class="mr-1"></mdb-icon>Report Completed
                        </span>
                        <span v-if="reportDownloadLink && reportDownloadLink == 'no data'"
                            ><br /><br />
                            No results found
                        </span>
                    </template>
                </mdb-tabs>
            </mdb-col>
        </mdb-row>
        <mdb-row>
            <mdb-col>
                <div id="display-area" class="mt-2">
                    <div v-if="report && report.columns && selectedFormat == 'html'" class="mb-10">
                        <vue-good-table
                            :columns="report.columns"
                            :rows="report.rows"
                            :isLoading.sync="runningReport"
                            :totalRows="totalRecords"
                            @on-sort-change="onSortChange"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: limit,
                                perPageDropdown: limitOptions,
                                dropdownAllowAll: false,
                                setCurrentPage: currentPage,
                                nextLabel: '>',
                                prevLabel: '<',
                                rowsPerPageLabel: 'Rows per page',
                                ofLabel: 'of',
                                pageLabel: 'page', // for 'pages' mode
                                allLabel: 'All',
                                total: totalRecords,
                            }"
                        />
                    </div>
                </div>
                <div class="clearfix"></div>
            </mdb-col>
        </mdb-row>
        <MyReportsTour />
    </mdb-container>
</template>
<script>
import moment from "moment";
import DateSelector from "@/components/DateSelector";
import { buttons, getTourDefaults } from "@/lib/shepherd-tour";
import PageHeader from "@/components/PageHeader";
import AccountDropdown from "@/components/AccountDropdown";
import MyReportsTour from "@/components/MyReportsTour";
import {
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbTabs,
    mdbIcon,
    mdbContainer,
} from "mdbvue";

export default {
    name: "MyReports",
    components: {
        MyReportsTour,
        DateSelector,
        PageHeader,
        AccountDropdown,
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbDropdownItem,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbTabs,
        mdbIcon,
        mdbContainer,
    },
    data() {
        return {
            userAccounts: null,
            visible: false,
            startDate: new moment(new moment().subtract(90, "days").format("YYYY-MM-DD"), "YYYY-MM-DD"),
            endDate: new moment(new moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
            refundTypesOptions: {
                chart_name: "refund_type_cost",
                chart_type: "pie",
                type: "pie",
                title: {
                    text: "",
                },
                expanded: false,
            },
            refundTypes: {},
            weeklyBillingSummary: {},
            reportData: {},
            limit: 10,
            currentPage: 1,
            sortColumn: "f_1_3",
            sortkey: "f_1_3",
            sortDir: "desc",
            searchText: "",
            selectMode: "single",
            totalRecords: 0,
            totalPages: 0,
            limitOptions: [10, 25, 50, 100],
            selected: {},
            dateRangeSelected: "",
            context: null,
            isBusy: false,
            selectedAccountActive: false,
            selectedTypeActive: false,
            systemReports: null,
            selfGeneratedReports: null,
            selectedReport: null,
            selectedOperator: null,
            selectedFilter: null,
            formats: ["pdf", "xls", "csv", "xml", "html"],
            selectedFormat: null,
            showFilters: false,
            filters: null,
            selectedCustomer: null,
            customers: null,
            reportDownloadLink: null,
            runningReport: false,
            reportsArchiveItems: null,
            addedFilters: [],
            filterParams: [],
            accountValues: null,
            tempFormat: null,
            addedFilterFields: [
                { label: "Name", field: "name" },
                { label: "Op", field: "op" },
                { label: "Values", field: "values" },
                { label: "Edit", field: "edit" },
            ],
            schemaSelected: null,
            schemaOptions: null,
            reportsArchiveFields: [
                {
                    label: "Name",
                    field: "report_name",
                },
                {
                    label: "Range",
                    field: "range",
                },
                {
                    label: "Created",
                    field: "created",
                },
            ],
            report: {},
            dropdownFilter: null,
            generalReportsList: null,
            customReportsList: null,
            systemReportsList: null,
            selfGeneratedReportsList: null,
            showReports: true,
            customerFilter: null,
            loading: true,
        };
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        options: Object,
        headers: Object,
        downloadUniversal: Function,
    },
    methods: {
        startTour() {
            const tour = this.$shepherd(getTourDefaults());
            const steps = [
                {
                    text: document.getElementById("step-one").innerHTML,
                    buttons: [
                        {
                            text: "Next",
                            action: tour.next,
                        },
                    ],
                    title: "My Reports Tour",
                },
                {
                    attachTo: { element: "#report-select", on: "bottom" },
                    text: document.getElementById("step-two").innerHTML,
                    buttons,
                    title: "Select Report",
                },
                {
                    attachTo: { element: "#dateDropdown", on: "bottom" },
                    text: document.getElementById("step-three").innerHTML,
                    buttons,
                    title: "Select Date Range",
                },
                {
                    attachTo: { element: "#dropdown-form2", on: "bottom" },
                    text: document.getElementById("step-four").innerHTML,
                    buttons,
                    title: "Select Format",
                },
                {
                    attachTo: { element: "#account-select", on: "bottom" },
                    text: document.getElementById("step-five").innerHTML,
                    buttons,
                    title: "Select Accounts",
                },
                {
                    attachTo: { element: "#run-report", on: "bottom" },
                    text: document.getElementById("step-six").innerHTML,
                    buttons,
                    title: "Run Report",
                },
                {
                    attachTo: { element: "#display-area", on: "top" },
                    text: document.getElementById("step-seven").innerHTML,
                    buttons,
                    title: "Display Area",
                },
            ];

            tour.addSteps(steps);

            tour.start();
        },
        async checkSchema() {
            if (this.selectedFilter.listable) {
                const schemaOptions = await this.$httpReports.post(
                    `schema`,
                    { action: "getValues", field: this.selectedFilter.code },
                    {
                        headers: this.headers,
                    }
                );
                this.schemaOptions = schemaOptions?.data ? schemaOptions.data.values : null;
            }
        },
        async getCustomers() {
            await this.$httpAdmin
                .get("/company/light", {
                    headers: this.headers,
                })
                .then((response) => {
                    this.customers = response.data;
                    this.customerList = this.customers;
                });
        },
        customerSearch() {
            if (this.customerFilter != null && this.customerFilter != "") {
                this.customerList = this.customers.filter((item) =>
                    this.customerFilter
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.cmp_name.toLowerCase().includes(v))
                );
            } else {
                this.customerList = this.customers;
            }
        },
        changeSelectedReport(report) {
            this.selectedReport = report;
            this.reportDownloadLink = null;
        },
        updateStartDate(val) {
            this.startDate = val;
        },
        updateEndDate(val) {
            this.endDate = val;
        },
        formatDate(val, withTime) {
            return withTime ? moment(val).format("MM/DD/YYYY hh:mm a") : moment(val).format("MM/DD/YYYY");
        },
        async getReportsList() {
            const params = new URLSearchParams({
                sortColumn: "rg_create_date",
                sortDir: "desc",
                rg_cmp_id: this.userInfo.cmpID,
            }).toString();
            const reports = (
                await this.$httpReports.get(`report`, {
                    headers: this.headers,
                })
            ).data;
            this.generalReports = reports.filter((c) => c.type === 2);
            this.generalReportsList = this.generalReports;
            this.customReports = reports.filter((c) => c.type === 3);
            this.customReportsList = this.customReports;
            this.systemReports = reports.filter((c) => c.type != 2 && c.type != 3);
            this.systemReportsList = this.systemReports;
            /*this.reportsArchiveItems = (
                await this.$httpReports.get(`reportgenerated?${params}`, {
                    headers: this.headers,
                })
            ).data.map((r) => {
                return {
                    ...r,
                    range: `${this.formatDate(r.rg_start_date)} - ${this.formatDate(r.rg_end_date)}`,
                    created: this.formatDate(r.rg_create_date),
                };
            });*/
            this.loading = false;
        },
        async generateReport() {
            this.selectedFormat = this.tempFormat;
            let good = true;
            this.runningReport = true;
            if (!this.selectedReport) {
                this.runningReport = false;
                this.$notify.error({
                    message: `You must select a report before continuing`,
                    title: "Error Processing Request",
                    autoHideDelay: 5000,
                    variant: "danger",
                });
                good = false;
            }
            if (!this.selectedFormat) {
                this.runningReport = false;
                this.$notify.error({
                    message: `You must select a report format before continuing`,
                    title: "Error Processing Request",
                    timeOut: 5000,
                    variant: "danger",
                });
                good = false;
            }
            if (this.selectedCustomer != null && this.accountValues.length === 0) {
                // running report for just one client
                this.runningReport = false;
                this.$notify.error({
                    message: `You must select at least one account before continuing`,
                    title: "Error Processing Request",
                    autoHideDelay: 5000,
                    variant: "danger",
                });
                good = false;
            }
            let accounts;
            if (this.selectedAccountActive) {
                accounts = this.accountValues;
            }
            const conditions = this.filterParams ? this.filterParams : null;
            let output = {
                output: this.selectedFormat,
            };
            let url = `reportfile`;
            if (this.selectedFormat === "html") {
                output = {
                    //type: this.selectedFormat,
                    limit: this.limit,
                    page: this.currentPage,
                    size: this.limit,
                    sortColumn: this.sortColumn,
                    sortkey: this.sortkey,
                    sortDir: this.sortDir,
                };
                url = "reportdata";
            }
            const params = {
                id: Number(this.selectedReport.id),
                ...output,
                cmpId: this.selectedCustomer === null ? "" : this.selectedCustomer.cmp_id,
                start: moment(this.startDate).format("YYYY-MM-DD"),
                end: moment(this.endDate).format("YYYY-MM-DD"),
                accounts,
                conditions: this.selectedFormat === "html" ? null : conditions,
            };
            if (good) {
                try {
                    await this.$httpReports
                        .post(url, params, {
                            headers: this.headers,
                        })
                        .then((response) => {
                            this.reportDownloadLink = response;
                            if (response.data.error) {
                                this.$notify.error({
                                    message: response.data.error || "There was an error generating this report",
                                    title: "Error Processing Request",
                                    timeOut: 10000,
                                    variant: "danger",
                                });
                            }
                        })
                        .catch((err) => {
                            this.$notify.error({
                                message: err.message || "There was an error generating this report",
                                title: "Error Processing Request",
                                timeOut: 10000,
                                variant: "danger",
                            });
                        });
                } catch (error) {
                    this.$notify.error({
                        message: error || "There was an error generating this report",
                        title: "Error Processing Request",
                        autoHideDelay: 10000,
                        variant: "danger",
                    });
                }
                if (this.reportDownloadLink == null || this.reportDownloadLink.data == "Error: No data results.") {
                    this.reportDownloadLink = "no data";
                } else {
                    if (this.selectedFormat === "html") {
                        this.report = {
                            columns: null,
                            rows: null,
                        };
                        this.report.rows = this.reportDownloadLink.data.items;
                        this.totalRecords = this.reportDownloadLink.data.total_records;
                        this.totalPages = this.reportDownloadLink.data.total_pages;
                        this.report.columns = this.filters.map((m) => {
                            return {
                                label: m.display,
                                field: m.code,
                            };
                        });
                    }
                }
                this.runningReport = false;
            }
            this.loading = false;
        },
        async downloadReport(val) {
            this.downloadUniversal(val.data, this.selectedFormat);
        },
        updateSelectedAccount(userAccounts, accountValues) {
            this.userAccounts = userAccounts;
            this.accountValues = accountValues;
        },
        updateSelectedActive(val) {
            this.selectedAccountActive = val;
        },
        updateCustomer(customer) {
            this.selectedCustomer = null;
            this.selectedCustomer = customer;
        },
        onSortChange(params) {
            this.sort = params[0].field;
            this.sortDir = params[0].type;
        },
        onPageChange(params) {
            this.currentPage = params.currentPage;
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
        },
        filterSearch() {
            if (this.dropdownFilter != null && this.dropdownFilter != "") {
                this.showReports = false;
                this.generalReportsList = this.generalReportsList.filter((item) =>
                    this.dropdownFilter
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v))
                );
                this.customReportsList = this.customReportsList.filter((item) =>
                    this.dropdownFilter
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v))
                );
                this.systemReportsList = this.systemReportsList.filter((item) =>
                    this.dropdownFilter
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v))
                );
            } else {
                this.systemReportsList = this.systemReports;
                this.generalReportsList = this.generalReports;
                this.customReportsList = this.customReports;
            }
            this.showReports = true;
        },
        setTempFormat(type) {
            this.tempFormat = type;
        },
    },
    async mounted() {
        await this.getCustomers();
        await this.getReportsList();
    },
    watch: {
        currentPage() {
            this.generateReport();
        },
        limit() {
            this.generateReport();
        },
    },
};
</script>
<style>
#dateDropdown .dropdown {
    width: 100% !important;
}
.myReports .dropdown-menu {
    max-height: 400px;
    overflow: scroll;
}
#dateDropdown {
    margin-left: 0 !important;
}
#addedFilters th {
    display: none;
}
.myReports .vgt-wrap__footer {
    min-height: 125px !important;
}
.myReports .link {
    cursor: pointer;
    font-weight: bold;
}
.myReports {
    height: 100vh;
}

.myReportsScroll {
    overflow-y: scroll;
    height: 20vh;
    width: 20vw;
}
</style>
