<template>
    <div data-test="page">
        <div data-test="main" v-if="!isLoading">
            <div data-test="searchDiv" class="d-flex p-2 justify-content-center align-items-center">
                <div data-test="searchInputGroup" class="input-group m-2 flex-fill">
                    <div data-test="searchInputGroupPrepend" class="input-group-prepend">
                        <span class="input-group-text default-color">
                            <mdb-icon
                                data-test="searchIcon"
                                icon="search"
                                class="text-white"
                                @click.native="getPackageData"
                            />
                        </span>
                    </div>
                    <input
                        data-test="searchInput"
                        class="form-control border-default"
                        type="text"
                        placeholder="Tracking Number"
                        aria-label="Tracking Number"
                        v-model="packageSearch"
                        @keydown.enter="getPackageData(packageSearch)"
                    />
                </div>
                <div class="input-group m-2 flex-fill" v-if="userInfo.usrLevel === 'super_admin'">
                    <mdb-select
                        data-test="auditPointsSelect"
                        multiple
                        class="w-100"
                        v-model="auditPoints"
                        placeholder="Select Audit Points"
                    ></mdb-select>
                </div>
                <div class="flex-fill" v-if="userInfo.usrLevel === 'super_admin'">
                    <mdb-btn data-test="getPackageDataBtn" color="default" @click.native="getPackageData(packageSearch)"
                        ><mdb-icon icon="search"></mdb-icon
                    ></mdb-btn>
                </div>
            </div>
            <mdb-row data-test="cardsRow" class="ml-0 mr-0">
                <mdb-col xl="4" lg="12">
                    <mdb-card data-test="firstCard" class="p-2 h-100">
                        <mdb-row>
                            <mdb-col lg="2">
                                <mdb-icon data-test="firstCardIcon" icon="cube" size="3x" class="mt-2 text-muted" />
                            </mdb-col>
                            <mdb-col col="10">
                                <mdb-row>
                                    <mdb-col col="6">
                                        <p data-test="firstCardTrackingNumberHeader" class="text-muted">
                                            Tracking Number
                                        </p>
                                        <p
                                            data-test="firstCard_trackingno"
                                            class="font-weight-bold"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.trackingno }}
                                        </p>
                                        <p data-test="firstCardServiceTypeHeader" class="text-muted">Service Type</p>
                                        <p
                                            data-test="firstCard_service_name"
                                            class="font-weight-bold text-capitalize"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.service_name }}
                                        </p>
                                    </mdb-col>
                                    <mdb-col col="6">
                                        <p data-test="firstCardStatusHeader" class="text-muted">Status</p>
                                        <p
                                            data-test="firstCard_is_delivered"
                                            class="font-weight-bold capitalize"
                                            v-if="packagePopulated"
                                        >
                                            {{
                                                packagePopulated.is_delivered
                                                    ? "delivered"
                                                    : packagePopulated.is_shipped
                                                    ? "shipped"
                                                    : "unavailable"
                                            }}
                                        </p>
                                        <p data-test="firstCardBilledWeightHeader" class="text-muted">Billed Weight</p>
                                        <p
                                            data-test="firstCard_act_wght_amnt"
                                            class="font-weight-bold"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.act_wght_amnt }}
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card>
                </mdb-col>
                <mdb-col lg="12" xl="4">
                    <mdb-card data-test="secondCard" class="p-2 h-100">
                        <mdb-row>
                            <mdb-col col="3">
                                <mdb-icon data-test="secondCardIcon" icon="clock" size="3x" class="mt-2 text-muted" />
                            </mdb-col>
                            <mdb-col col="9">
                                <mdb-row>
                                    <mdb-col col="6">
                                        <p data-test="secondCardPickupTimeHeader" class="text-muted">Pickup Time</p>
                                        <p
                                            data-test="secondCard_ship_date"
                                            class="font-weight-bold"
                                            v-if="packagePopulated"
                                        >
                                            {{ moment(packagePopulated.ship_date, true) }}
                                        </p>
                                        <p data-test="secondCardGuaranteedTimeHeader" class="text-muted">
                                            Guaranteed Time
                                        </p>
                                        <p
                                            data-test="secondCard_transit_date"
                                            class="font-weight-bold"
                                            v-if="packagePopulated"
                                        >
                                            {{ moment(packagePopulated.transit_date, true) }}
                                        </p>
                                    </mdb-col>
                                    <mdb-col col="6">
                                        <p data-test="secondCardDeliveryTimeHeader" class="text-muted">Delivery Time</p>
                                        <p
                                            data-test="secondCard_deliver_date"
                                            class="font-weight-bold"
                                            v-if="packagePopulated"
                                        >
                                            {{ moment(packagePopulated.deliver_date, true) }}
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card>
                </mdb-col>
                <mdb-col lg="12" xl="4">
                    <mdb-card data-test="thirdCard" class="p-2 h-100">
                        <mdb-row>
                            <mdb-col col="3">
                                <mdb-icon
                                    data-test="thirdCardIcon"
                                    icon="check-square"
                                    size="3x"
                                    class="mt-2 text-muted"
                                />
                            </mdb-col>
                            <mdb-col col="9">
                                <p data-test="thirdCardAuditResultHeader" class="text-muted">Audit Result</p>
                                <p data-test="thirdCard_audit_result" class="font-weight-bold" v-if="packagePopulated">
                                    {{
                                        packagePopulated.audit_result
                                            ? packagePopulated.audit_result
                                            : "No Refund Opportunity"
                                    }}
                                </p>
                                <p data-test="thirdCardReasonHeader" class="text-muted">Reason</p>
                                <p data-test="thirdCard_reason" class="font-weight-bold" v-if="packagePopulated">
                                    {{ packagePopulated.reason }}
                                </p>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mx-3 my-4">
                <mdb-card data-test="packageDetailsCard" class="w-100">
                    <mdb-card-header data-test="packageDetailsCardHeader" class="light-grey"
                        ><mdb-icon icon="cubes" /> Package Details</mdb-card-header
                    >
                    <mdb-row class="p-3">
                        <mdb-col xl="8" lg="12">
                            <mdb-row>
                                <mdb-col>
                                    <p data-test="packageDetailsCardInvoiceNumberHeader" class="text-muted">
                                        Invoice Number
                                    </p>
                                    <p class="font-weight-bold" v-if="invoiceNum">
                                        {{ invoiceNum }}
                                        <mdb-btn
                                            data-test="packageDetailsCardSwitchGenieBtn"
                                            outline="black"
                                            size="sm"
                                            class="p-2"
                                            @click.native="switchGenie"
                                            ><mdb-icon icon="magic"></mdb-icon
                                        ></mdb-btn>
                                    </p>
                                    <p data-test="packageDetailsCardNetChargeHeader" class="text-muted">Net Charge</p>
                                    <p
                                        data-test="packageDetailsCard_net_charge"
                                        class="font-weight-bold"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.net_charge }}
                                    </p>
                                    <p data-test="packageDetailsCardNoOfPackagesHeader" class="text-muted">
                                        No. of Packages
                                    </p>
                                    <p
                                        data-test="packageDetailsCard_no_packs"
                                        class="font-weight-bold"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.no_packs }}
                                    </p>
                                </mdb-col>
                                <mdb-col>
                                    <p data-test="packageDetailsCardZoneHeader" class="text-muted">Zone</p>
                                    <p
                                        data-test="packageDetailsCard_zone_code"
                                        class="font-weight-bold"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.zone_code }}
                                    </p>
                                    <p data-test="packageDetailsCardDeclaredValueHeader" class="text-muted">
                                        Declared Value
                                    </p>
                                    <p
                                        data-test="packageDetailsCard_declared_value"
                                        class="font-weight-bold"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.declared_value }}
                                    </p>
                                    <p data-test="packageDetailsCardCustomsValueHeader" class="text-muted">
                                        Customs Value
                                    </p>
                                    <p
                                        data-test="packageDetailsCard_customs_value"
                                        class="font-weight-bold"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.customs_value }}
                                    </p>
                                </mdb-col>
                                <mdb-col>
                                    <p data-test="packageDetailsCardReference1Header" class="text-muted">Reference 1</p>
                                    <p
                                        data-test="packageDetailsCard_ref1"
                                        class="font-weight-bold text-capitalize"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.ref1 }}
                                    </p>
                                    <p data-test="packageDetailsCardReference2Header" class="text-muted">Reference 2</p>
                                    <p
                                        data-test="packageDetailsCard_ref2"
                                        class="font-weight-bold text-capitalize"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.ref2 }}
                                    </p>
                                    <p data-test="packageDetailsCardReference3Header" class="text-muted">Reference 3</p>
                                    <p
                                        data-test="packageDetailsCard_ref3"
                                        class="font-weight-bold text-capitalize"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.ref3 }}
                                    </p>
                                </mdb-col>
                                <mdb-col>
                                    <p data-test="packageDetailsCardReference4Header" class="text-muted">Reference 4</p>
                                    <p
                                        data-test="packageDetailsCard_ref4"
                                        class="font-weight-bold text-capitalize"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.ref4 }}
                                    </p>
                                    <p data-test="packageDetailsCardReference5Header" class="text-muted">Reference 5</p>
                                    <p
                                        data-test="packageDetailsCard_ref5"
                                        class="font-weight-bold text-capitalize"
                                        v-if="packagePopulated"
                                    >
                                        {{ packagePopulated.ref5 }}
                                    </p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-col>
                        <mdb-col xl="4" lg="12" v-if="packagePopulated">
                            <p data-test="packageDetailsCardChargesHeader" class="p-1">Charges</p>
                            <mdb-datatable-2
                                data-test="packageDetailsCard_tableData"
                                class="hide-thead"
                                v-model="tableData"
                                responsive
                                striped
                                :pagination="false"
                            >
                            </mdb-datatable-2>
                        </mdb-col>
                    </mdb-row>
                </mdb-card>
            </mdb-row>
            <mdb-row class="mx-1 mb-2">
                <mdb-col lg="7" sm="12">
                    <mdb-card data-test="trackingDetailsCard" v-if="trackingActivity">
                        <mdb-card-header data-test="trackingDetailsCardHeader" color="light-green"
                            >Tracking Details</mdb-card-header
                        >
                        <mdb-scrollbar data-test="trackingDetailsCardScrollbar" height="400px">
                            <mdb-tbl data-test="trackingDetailsCardTbl" striped bordered>
                                <mdb-tbl-head data-test="trackingDetailsCardTblHead">
                                    <tr>
                                        <th v-for="t in trackingFields" v-bind:key="t.key">
                                            {{ t.label }}
                                        </th>
                                    </tr>
                                </mdb-tbl-head>

                                <mdb-tbl-body data-test="trackingDetailsCardTblBody">
                                    <tr
                                        data-test="trackingDetailsCardTblRows"
                                        v-for="(t, index) of trackingActivity"
                                        v-bind:key="index"
                                    >
                                        <td data-test="trackingDetailsCardTblRows_address">
                                            <span class="text-uppercase"
                                                >{{ t.address.city }}, {{ t.address.state }}
                                                {{ t.address.country }}</span
                                            >
                                        </td>
                                        <td data-test="trackingDetailsCardTblRows_date">
                                            <span>{{ moment(t.date_time) }}</span>
                                        </td>
                                        <td data-test="trackingDetailsCardTblRows_status">
                                            {{ t.status_desc }}
                                        </td>
                                    </tr>
                                </mdb-tbl-body>
                            </mdb-tbl>
                        </mdb-scrollbar>
                    </mdb-card>
                </mdb-col>
                <mdb-col lg="5" sm="12">
                    <mdb-row>
                        <mdb-col col="12">
                            <mdb-card data-test="labelDetailsCard" class="w-100">
                                <mdb-card-header data-test="labelDetailsCardHeader" class="light-grey"
                                    >Label Details</mdb-card-header
                                >
                                <mdb-row class="ml-0 mr-0 pt-2">
                                    <mdb-col col="6">
                                        <p data-test="labelDetailsCardShipToHeader" class="text-muted">Ship To</p>
                                        <p
                                            data-test="labelDetailsCard_recipient_name"
                                            class="font-weight-bold text-uppercase devblurnohover"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.recipient_name }}
                                        </p>
                                        <p
                                            data-test="labelDetailsCard_recipient_addr1"
                                            class="font-weight-bold text-uppercase mt-2 devblurnohover"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.recipient_addr1 }}
                                        </p>
                                        <p
                                            data-test="labelDetailsCard_recipient_addr2"
                                            class="font-weight-bold text-uppercase devblurnohover"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.recipient_addr2 }}
                                        </p>
                                        <p
                                            data-test="labelDetailsCard_recipient_city/state/zip/country"
                                            class="font-weight-bold text-uppercase"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.recipient_city }},
                                            {{ packagePopulated.recipient_state }}
                                            {{ packagePopulated.recipient_zip }}
                                            {{ packagePopulated.recipient_country }}
                                        </p>
                                    </mdb-col>
                                    <mdb-col col="6">
                                        <p data-test="labelDetailsCardShipFromHeader" class="text-muted">Ship From</p>
                                        <p
                                            data-test="labelDetailsCard_shipper_name"
                                            class="font-weight-bold text-uppercase devblurnohover"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.shipper_name }}
                                        </p>
                                        <p
                                            data-test="labelDetailsCard_shipper_addr1"
                                            class="font-weight-bold text-uppercase mt-2 devblurnohover"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.shipper_addr1 }}
                                        </p>
                                        <p
                                            data-test="labelDetailsCard_shipper_addr2"
                                            class="font-weight-bold text-uppercase devblurnohover"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.shipper_addr2 }}
                                        </p>
                                        <p
                                            data-test="labelDetailsCard_shipper_city/state/zip/country"
                                            class="font-weight-bold text-uppercase"
                                            v-if="packagePopulated"
                                        >
                                            {{ packagePopulated.shipper_city }}, {{ packagePopulated.shipper_state }}
                                            {{ packagePopulated.shipper_zip }}
                                            {{ packagePopulated.shipper_country }}
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row class="mt-3">
                        <mdb-col col="12">
                            <mdb-card data-test="transitDetailsCard" v-if="transitData">
                                <mdb-card-header data-test="transitDetailsCardHeader" class="light-grey"
                                    >Time in Transit Details</mdb-card-header
                                >
                                <mdb-scrollbar data-test="transitDetailsCardScrollbar" height="200px">
                                    <mdb-tbl data-test="transitDetailsCardTbl" v-model="trackingData" responsive>
                                        <mdb-tbl-body data-test="transitDetailsCardTblBody">
                                            <tr
                                                data-test="transitDetailsCardTblRows"
                                                v-for="t of transitData"
                                                v-bind:key="t.id"
                                            >
                                                <td>
                                                    <p
                                                        data-test="transitDetailsCardTbl_service_desc"
                                                        class="text-uppercase"
                                                    >
                                                        {{ t.service_desc }}
                                                    </p>
                                                    <p data-test="transitDetailsCardTblLatestPickupTimeHeader">
                                                        Latest pickup time:
                                                    </p>
                                                    <p data-test="transitDetailsCardTbl_pickup_date">
                                                        {{ moment(t.pickup_date) }}
                                                        <br />
                                                        {{ moment(t.pickup_date) }}
                                                    </p>
                                                    <p data-test="transitDetailsCardTbl_service_code">
                                                        Service Code: {{ t.service_code }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        data-test="transitDetailsCardTbl_total_days"
                                                        class="font-weight-bold"
                                                    >
                                                        Days in Transit: {{ t.total_days }}
                                                    </p>
                                                    <p data-test="transitDetailsCardTbl_arrival">
                                                        Delivered By:
                                                        {{ moment(t.arrival) }}
                                                        <br />
                                                        {{ moment(t.arrival) }}
                                                    </p>
                                                    <p
                                                        data-test="transitDetailsCardTbl_guaranteed"
                                                        v-if="t.is_guaranteed"
                                                    >
                                                        Guaranteed
                                                    </p>
                                                </td>
                                            </tr>
                                        </mdb-tbl-body>
                                    </mdb-tbl>
                                </mdb-scrollbar>
                            </mdb-card>
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
            </mdb-row>
        </div>
        <div data-test="spinner" v-else><mdb-spinner></mdb-spinner> Loading Package Information...</div>
    </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import {
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbDatatable2,
    mdbSpinner,
    mdbSelect,
    mdbBtn,
    mdbTbl,
    mdbTblBody,
    mdbScrollbar,
} from "mdbvue";

export default {
    name: "PackageGenie",
    components: {
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardHeader,
        mdbDatatable2,
        mdbSpinner,
        mdbSelect,
        mdbBtn,
        mdbTbl,
        mdbTblBody,
        mdbScrollbar,
    },
    data() {
        return {
            mapbox_api: `https://api.mapbox.com/geocoding/v5/mapbox.places`,
            // eslint-disable-next-line no-undef
            accessToken: process.env.VUE_APP_MAPBOX_TOKEN, // your access token. Needed if you using Mapbox maps
            mapStyle: "mapbox://styles/mapbox/light-v9",
            coordinates: [-111.549668, 39.014],
            volumeServiceType: {},
            packageCharges: null,
            distributionMap: {},
            packagePopulated: null,
            packageLocations: null,
            packageSearch: "",
            invoiceCharges: {},
            trackingData: null,
            trackingActivity: null,
            selected: null,
            trackingFields: [
                {
                    key: "address",
                    label: "Location",
                },
                {
                    key: "date_time",
                    label: "Date",
                },
                {
                    key: "status_desc",
                    label: "Activity",
                },
            ],
            transitData: null,
            transitFields: [
                {
                    key: "leftpanel",
                    label: "",
                },
                {
                    key: "rightpanel",
                    label: "",
                },
            ],
            charges: null,
            sortColumn: "pld_trackingno",
            sortkey: "pld_trackingno",
            sortDir: "asc",
            chargeFields: [
                {
                    label: "Type",
                    field: "type_name",
                    format: (d) => {
                        return d.toUpperCase();
                    },
                },
                {
                    label: "Amount",
                    field: "amount",
                    format: (d) => {
                        return d && d > 0 ? `$${d}` : "-";
                    },
                },
                {
                    label: "Currency",
                    field: "currency",
                    format: (d) => {
                        return d.toUpperCase();
                    },
                },
            ],
            auditPoints: [
                { value: "2", text: "GSR" },
                { value: "3", text: "Address Correction" },
                { value: "4", text: "Residential Surcharge" },
                { value: "6", text: "Saturday Delivery" },
                { value: "7", text: "Saturday Pickup" },
                { value: "8", text: "Signature Required" },
                { value: "9", text: "Fuel" },
            ],
            isBusy: true,
            packageCurrentStop: 0,
            pkgLocationCordinatorsCounter: 0,
            tableData: null,
            isLoading: false,
        };
    },
    props: {
        invoiceNum: String,
        package: Object,
        userInfo: Object,
        headers: Object,
        showPackageGenie: Boolean,
        showInvoiceGenie: Boolean,
    },
    methods: {
        async getPackageData(pkgNum) {
            if (!pkgNum) {
                this.$notify.error({
                    message: "Please enter a tracking number.",
                    title: "Error",
                    timeOut: 10000,
                    variant: "danger",
                });
                this.trackingData = null;
                this.trackingActivity = null;
                this.tableData = null;
                this.volumeServiceType = {};
                this.packageCharges = null;
                this.distributionMap = {};
                this.packagePopulated = null;
                this.packageLocations = null;
                this.packageSearch = "";
                this.invoiceCharges = {};
                this.transitData = null;
                this.invoiceNum = null;
                this.errorMessage = null;
            } else {
                this.isLoading = true;
                let params = { cmpId: this.userInfo.cmpID, tracking_number: pkgNum };
                params.invoiceId = this.invoiceNum ? this.invoiceNum : 0;
                if (this.userInfo.usrLevel === "super_admin") {
                    if (this.auditPoints) {
                        const points = [];
                        for (const ap of this.auditPoints) {
                            if (ap.selected) {
                                points.push(ap.value);
                            }
                        }
                        params.auditPoints = points;
                    }
                }
                let url = "/pkggenie";
                let errorMessage = null;
                const response = await this.$httpShipping.post(url, params, {
                        headers: this.headers,
                    })
                    .catch(function (err) {
                        errorMessage = err.response.data;
                    });
                this.isLoading = false;
                if (response && response.data) {
                    this.packagePopulated = response.data.pkg;
                    if (this.packagePopulated.audit_hits && this.packagePopulated.audit_hits.length > 0) {
                        this.packagePopulated.audit_result = "Refund Opportunity";
                        let reason;
                        for (let i = 0, len = this.packagePopulated.audit_hits.length; i < len; i++)
                            reason += this.packagePopulated.audit_hits[i] + ", ";

                        this.packagePopulated.reason = reason.substr(0, reason.length - 2);
                    }
                    if (this.packagePopulated.invoice_number) {
                        this.invoiceNum = this.packagePopulated.invoice_number;
                    }
                    this.charges = response.data.charges;
                    this.tableData = { columns: this.chargeFields, rows: this.charges };
                    this.trackingActivity = response.data.trackActivity;
                    this.transitData = response.data.transitdata;
                    this.trackingData = { columns: this.transitFields, rows: this.transitData };
                    this.packageSearch = response.data.pkg.trackingno;
                    this.isBusy = false;
                    if (this.trackingActivity) {
                        await this.pkgDisplayMap();
                    }
                }
                else {
                    if (pkgNum != "") {
                        this.$notify.error({
                            message: errorMessage,
                            title: "Error",
                            timeOut: 10000,
                            variant: "danger",
                        });
                    }
                }
            }
            return this.packagePopulated;
        },
        moment: (date, time) => {
            return time ? moment(date).format("lll") : moment(date).format("MMM DD, YYYY, h:mm a");
        },
        async pkgDisplayMap() {
            if (this.trackingActivity.length == 0) return;

            this.packageCurrentStop = 0;
            this.pkgLocationCordinatorsCounter = 0;
            let pkgLocationsCoordinates = [];
            let searchText = "";
            let packageLocation;
            let coordinates;
            for (var i = 0; i < this.trackingActivity.length; i++) {
                packageLocation = this.trackingActivity[i];
                if (packageLocation.address) {
                    searchText = `${packageLocation.address.city}, ${packageLocation.address.state}, ${packageLocation.address.country} ${packageLocation.address.zipcode}`;
                }
                if (i === this.trackingActivity.length - 1) {
                    searchText = `${this.packagePopulated.shipper_city}, ${this.packagePopulated.shipper_state}, ${this.packagePopulated.shipper_country}`;
                }
                if (packageLocation.status === "Delivered") {
                    searchText = `${this.packagePopulated.receipient_city}, ${this.packagePopulated.receipient_city}, ${this.packagePopulated.receipient_city}`;
                }
                coordinates = await axios.get(`${this.mapbox_api}/${searchText}.json?access_token=${this.accessToken}`);
                coordinates.data.features[0].geometry.coordinates;
                pkgLocationsCoordinates.push({
                    coordinates: coordinates.data.features[0].geometry.coordinates,
                    icon: "",
                });
            }
            this.packageLocations = pkgLocationsCoordinates;
            // eslint-disable-next-line no-console
        },
        async switchGenie() {
            this.$emit("open-invoice-genie", { inv_number: this.invoiceNum });
        },
    },
    mounted() {
        if (this.invoiceNum && this.package) {
            this.getPackageData(this.package.pld_trackingno);
        } else if (!this.invoiceNum && this.package.btn_number) {
            this.getPackageData(this.package.btn_number);
        }
    },
};
</script>
<style>
.nopadding {
    padding: 1px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
}
p {
    margin: 0px !important;
}
.hide-thead thead {
    display: none;
}
.fixed-height {
    height: 240px;
    overflow: scroll;
}
.min-height-250 {
    min-height: 250px;
}
.devblurnohover {
    color: transparent;
    text-shadow: 0 0 9px rgba(0, 0, 0, 1);
}
.devblurnohover:hover {
    color: inherit;
    text-shadow: none;
}
</style>
