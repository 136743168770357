<template>
    <div class="w-100 pb-4">
        <AccountingDashboard
            v-if="userInfo.usrRestrictType === 3"
            :userInfo="userInfo"
            :headers="headers"
        ></AccountingDashboard>
        <ExecDashboard v-if="userInfo.usrRestrictType === 5" :userInfo="userInfo" :headers="headers"></ExecDashboard>
        <ServiceDashboard
            v-if="userInfo.usrRestrictType === 2"
            :userInfo="userInfo"
            :headers="headers"
        ></ServiceDashboard>
        <AnalystDashboard
            v-if="userInfo.usrRestrictType === 7"
            :userInfo="userInfo"
            :headers="headers"
        ></AnalystDashboard>
        <ResellerDashboard
            v-if="userInfo.usrRestrictType === 9"
            :userInfo="userInfo"
            :headers="headers"
        ></ResellerDashboard>
        <SystemHealthDashboard
            v-if="userInfo.usrRestrictType === 1"
            :userInfo="userInfo"
            :headers="headers"
        ></SystemHealthDashboard>
    </div>
</template>
<script>
import AccountingDashboard from "@/admin/AccountingDashboard";
import AnalystDashboard from "@/admin/AnalystDashboard";
import ExecDashboard from "@/admin/ExecDashboard";
import ServiceDashboard from "@/admin/ServiceDashboard";
import ResellerDashboard from "@/admin/ResellerDashboard";
import SystemHealthDashboard from "@/admin/SystemHealthDashboard";

export default {
    name: "AdminDashboard",
    components: {
        AccountingDashboard,
        SystemHealthDashboard,
        AnalystDashboard,
        ExecDashboard,
        ServiceDashboard,
        ResellerDashboard,
    },
    props: {
        userInfo: Object,
        headers: Object,
    },
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>
