<template>
    <div class="p-2 w-100">
        <h3>Audit Report</h3>
        <mdb-row class="row w-100 p-2" v-if="auditCharts">
            <mdb-col col="6" class="max-h-50" v-for="chart in auditCharts" :key="chart">
                <highcharts :options="chart"></highcharts>
            </mdb-col>
        </mdb-row>
        <mdb-row v-if="auditChart" class="mainChart p-2 w-100">
            <highcharts class="w-100" :constructor-type="'stockChart'" :options="auditChart"></highcharts>
        </mdb-row>
    </div>
</template>
<script>
import maps from "../../lib/charts";

export default {
    name: "AdminAuditReport",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            names: [
                "#Packages",
                "#Charges",
                "Total Spend",
                "Processed Total Spend",
                "Hit Value",
                "#Processed Packages",
                "#Processed Charges",
                "#Hit Packages",
                "#Hit Charges",
                "#Nodes",
            ],
            propNames: [
                "ap_num_packages",
                "ap_num_charges",
                "ap_charges_amount",
                "ap_processed_charges_amount",
                "ap_recovered_charges_amount",
                "ap_num_processed_packages",
                "ap_num_processed_charges",
                "ap_num_recovered_packages",
                "ap_num_recovered_charges",
                "ap_num_nodes",
            ],
            auditProcess: null,
            series: [],
            mean: [],
            max: [],
            min: [],
            times: [],
            auditCharts: [],
            goalChart: null,
            selectedIndex: 0,
        };
    },
    methods: {
        async getAuditProcess() {
            const data = await this.$httpAdmin.get(`AuditProcess`, { headers: this.headers });
            if (data) {
                this.auditProcess = data.data;
                this.preProcessData(this.auditProcess);
                for (let i = 0; i < this.names.length; i++) {
                    this.auditCharts[i] = maps.drawBulletChart({
                        title: this.names[i],
                        min: this.min[i],
                        mean: this.mean[i],
                        max: this.max[i],
                        value: this.series[i].data[this.auditProcess.length - 1][1],
                        maxheight: "50px",
                    });
                }
                this.selectedIndex = this.auditCharts.length - 1;
                const goalValue =
                    (this.series[4].data[this.auditProcess.length - 1][1] * 100) /
                    this.series[2].data[this.auditProcess.length - 1][1];
                this.auditCharts[this.names.length + 1] = maps.drawBulletChart({
                    title: "Recovery",
                    min: 0,
                    mean: 1,
                    max: 20,
                    value: goalValue,
                    target: 1.75,
                    maxheight: "50px",
                });
            }
        },
        preProcessData(data) {
            this.series = [];
            this.mean = [];
            this.max = [];
            this.min = [];
            this.times = [];
            for (let cnt = 0; cnt < this.names.length; cnt++) {
                let total = 0;
                let localMin = 1000000000;
                let localMax = -1;
                let b = [];
                for (let i = 0; i < data.length; i++) {
                    let c = [];
                    this.times[i] = c[0] = new Date(data[i].ap_start_time).getTime();
                    c[1] = data[i][this.propNames[cnt]];
                    b.push(c);

                    total += c[1];

                    if (c[1] > localMax) localMax = c[1];

                    if (c[1] < localMin) localMin = c[1];
                }

                this.series[cnt] = {
                    name: this.names[cnt],
                    data: b,
                };

                this.mean[cnt] = total / data.length;
                this.min[cnt] = localMin;
                this.max[cnt] = localMax;
            }
        },
        findTime(array, value) {
            let index = array.binarySearch(value);
            if (index >= 0) return index;

            if (value - array[~index] < array[~index + 1] - value) return ~index;

            return ~index + 1;
        },
        binarySearch(searchElement) {
            let minIndex = 0;
            let maxIndex = this.length - 1;
            let currentIndex;
            let currentElement;

            while (minIndex <= maxIndex) {
                currentElement = this[currentIndex];

                if (currentElement < searchElement) {
                    minIndex = currentIndex + 1;
                } else if (currentElement > searchElement) {
                    maxIndex = currentIndex - 1;
                } else {
                    return currentIndex;
                }
            }

            return ~maxIndex;
        },
    },
    computed: {
        auditChart() {
            return {
                title: {
                    text: "Audit Process",
                },
                chart: {
                    borderWidth: 2,
                    borderColor: "#000",
                    events: {
                        click: function (e) {
                            const time = e.xAxis[0].value;
                            const index = (this.selectedIndex = this.findTime(this.times, time));
                            for (var i = 0; i < this.names.length; i++)
                                this.auditCharts[i].series[0].setData([this.series[i].data[index][1]]);

                            this.goalChart.series[0].setData([
                                (this.series[4].data[index][1] * 100) / this.series[2].data[index][1],
                            ]);
                        },
                    },
                },

                rangeSelector: {
                    selected: 4,
                },

                credits: {
                    enabled: false,
                },

                yAxis: {
                    plotLines: [
                        {
                            value: 0,
                            width: 2,
                            color: "silver",
                        },
                    ],
                },

                tooltip: {
                    //pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                    formatter: function () {
                        let tooltip = "";
                        let refund = 0,
                            total = 0;
                        let i = 0;
                        for (const point of this.points) {
                            if (i == 4) refund = point.y;

                            if (i == 3) total = point.y;

                            tooltip +=
                                '<span style="color:' +
                                point.series.color +
                                '">' +
                                point.series.name +
                                "</span>: <b>" +
                                point.y +
                                "</b><br/>";
                            i++;
                        }
                        let hit = 0;
                        if (total != 0) hit = (refund * 100) / total;
                        tooltip += "<span>Hit Rate</span>: <b>" + parseFloat(hit).toFixed(2) + "%</b><br/>";
                        return tooltip;
                    },
                    valueDecimals: 2,
                    followPointer: true,
                },

                series: this.series,
            };
        },
    },
    mounted() {
        this.getAuditProcess();
    },
};
</script>
<style>
.max-h-50 {
    max-height: 50px !important;
}
.mainChart .highcharts-container {
    width: 100% !important;
}
</style>
