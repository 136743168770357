<template>
    <div class="m-4">
        <h3 data-test="title" class="my-4 mx-2">Download Invoice</h3>
        <div class="w-100 d-flex align-items-center m-2">
            <mdb-input
                data-test="searchTermInput"
                type="text"
                class="flex-fill"
                v-model="searchTerm"
                @keydown.native.enter="searchInvoices"
                placeholder="Search Invoices"
            ></mdb-input>
            <mdb-btn
                data-test="searchInvoicesBtn"
                color="info"
                @click.native="searchInvoices"
                icon="search"
                class="float-right"
                >Search</mdb-btn
            >
        </div>
        <vue-good-table
            data-test="vue-good-table"
            styleClass="vgt-table condensed"
            :rows="invoices"
            :columns="columns"
            mode="remote"
            :isLoading.sync="loading"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'actions'">
                    <mdb-btn icon="download" color="info" @click.native="downloadInvoice(props.row)">Download</mdb-btn>
                </span>
                <span
                    v-else-if="props.column.field === 'inv_amount'"
                >{{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>
<script>
import moment from "moment";
import { mdbInput, mdbBtn } from "mdbvue";
export default {
    name: "AdminDownloadInvoice",
    components: {
        mdbInput,
        mdbBtn,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            invoices: null,
            companies: null,
            columns: [
                {
                    label: "Company",
                    field: "cmp_name",
                },
                {
                    label: "Carrier",
                    field: "car_name",
                },
                {
                    label: "Account #",
                    field: "acct_number",
                },
                {
                    label: "Invoice #",
                    field: "inv_number",
                },
                {
                    label: "Invoice Date",
                    field: "inv_date",
                    formatFn: this.formatDate,
                },
                {
                    label: "Total Amount",
                    field: "inv_amount",
                },
                {
                    label: "",
                    field: "actions",
                },
            ],
            searchTerm: null,
            loading: false,
        };
    },
    methods: {
        async searchInvoices() {
            if (this.searchTerm.length < 6 || !this.searchTerm) {
                this.$notify.error({ message: "Invoice number must be at least 6 characters long." });
                return false;
            }
            this.loading = true;
            const found = await this.$httpAdmin.get(`downloadinvoicefile/${this.searchTerm}`, {
                headers: this.headers,
            });
            this.loading = false;
            if (found) {
                this.invoices = found.data;
            }
        },
        downloadInvoice(row) {},

        formatDate(date) {
            return moment(date).format("MM/DD/YYYY");
        },
    },
    mounted() {},
};
</script>
