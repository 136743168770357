var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-4",attrs:{"data-test":"page"}},[_c('h3',{staticClass:"my-4 mx-2",attrs:{"data-test":"pageTitle"}},[_vm._v("Attachment Errors")]),_c('vue-good-table',{attrs:{"data-test":"vue-good-table","styleClass":"vgt-table condensed","rows":_vm.records,"columns":_vm.columns,"mode":"remote","search-options":{
            enabled: true,
            trigger: 'enter',
        },"totalRows":_vm.totalRows,"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: this.limit,
            position: 'top',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: this.page,
        },"isLoading":_vm.loading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.searchErrors,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'ieae_time')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.formattedRow[props.column.field]))+" ")]):(props.column.field === 'ieae_from')?_c('span',[_vm._v(" "+_vm._s(props.row.ieae_from.replace("<", "").replace(">", ""))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }