<template>
    <div data-test="page" class="overflow-auto h-90">
        <div>
            <h1 data-test="pageHeader" class="page-header border-bottom mx-2">
                Users Stats
                <span
                    data-test="pageHeaderSmaller"
                    class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block"
                    >Login KPIs</span
                >
                <mdb-icon far icon="meh" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row data-test="accordion_spinner" v-if="spinner" id="overlay" class="justify-content-center align-content-center h-100vh">
                <mdb-spinner class="justify-content-center"></mdb-spinner><h3><b>...Loading</b></h3>
            </mdb-row>
            <mdb-row v-else data-test="stats" class="mt-3 mx-4 justify-content-center" v-else>
                <mdb-col lg="8" sm="12">
                    <mdb-card-group data-test="mdb-card-group" deck>
                        <mdb-card data-test="usersCard" color="info-color">
                            <mdb-card-body>
                                <mdb-row>
                                    <mdb-col lg="3" sm="12">
                                        <mdb-icon size="4x" far icon="clock"></mdb-icon>
                                    </mdb-col>
                                    <mdb-col lg="9" sm="12" class="text-right">
                                        <p data-test="usersLoginPercentage" class="userdashannouncement-heading">
                                            {{ usersLoginPercentage }}%
                                        </p>
                                        <p data-test="usersNote" class="userdashannouncement-text">
                                            % of Total Users Logged in Last Week
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                        <mdb-card data-test="companiesCard" color="info-color">
                            <mdb-card-body>
                                <mdb-row>
                                    <mdb-col lg="3" sm="12">
                                        <mdb-icon size="4x" far icon="clock"></mdb-icon>
                                    </mdb-col>
                                    <mdb-col lg="9" sm="12" class="text-right">
                                        <p data-test="companiesLoginPercentage" class="userdashannouncement-heading">
                                            {{ companiesLoginPercentage }}%
                                        </p>
                                        <p data-test="companiesNote" class="userdashannouncement-text">
                                            % of customers active login Last Week
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                        <mdb-card data-test="createdCard" color="info-color">
                            <mdb-card-body>
                                <mdb-row>
                                    <mdb-col lg="3" sm="12">
                                        <mdb-icon size="4x" far icon="clock"></mdb-icon>
                                    </mdb-col>
                                    <mdb-col lg="9" sm="12" class="text-right">
                                        <p data-test="usersCreatedLoginPercentage" class="userdashannouncement-heading">
                                            {{ usersCreatedLoginPercentage }}%
                                        </p>
                                        <p data-test="createdNote" class="userdashannouncement-text">
                                            % of users created last week that logged in
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-card-group>
                </mdb-col>
            </mdb-row>
        </div>
    </div>
</template>
<script>
import { mdbIcon, mdbRow, mdbCol, mdbCardGroup, mdbCard, mdbCardBody, mdbSpinner } from "mdbvue";
export default {
    name: "AdminLoginKPIs",
    components: {
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbCardGroup,
        mdbCard,
        mdbCardBody,
        mdbSpinner,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            spinner: false,
            usersLoginPercentage: null,
            companiesLoginPercentage: null,
            usersCreatedLoginPercentage: null,
        };
    },
    methods: {
        async getInit() {
            this.spinner = true;
            this.statsCreated = await this.getStats();
            this.spinner = false;
        },
        async getStats() {
            await this.$httpAdmin
                .get("/usersstats/usersloginpercentage", { headers: this.headers })
                .then(async (response) => {
                    this.usersLoginPercentage = response.data;
                    if (this.usersLoginPercentage) {
                        this.usersLoginPercentage = parseFloat(this.usersLoginPercentage).toFixed(2);
                    }
                });
            await this.$httpAdmin
                .get("/usersstats/companiesloginpercentage", { headers: this.headers })
                .then(async (response) => {
                    this.companiesLoginPercentage = response.data;
                    if (this.companiesLoginPercentage) {
                        this.companiesLoginPercentage = parseFloat(this.companiesLoginPercentage).toFixed(2);
                    }
                });
            await this.$httpAdmin
                .get("/usersstats/userscreatedloginpercentage", { headers: this.headers })
                .then(async (response) => {
                    this.usersCreatedLoginPercentage = response.data;
                    if (this.usersCreatedLoginPercentage) {
                        this.usersCreatedLoginPercentage = parseFloat(this.usersCreatedLoginPercentage).toFixed(2);
                    }
                });
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
