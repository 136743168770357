<template>
    <!--tours-->
    <div class="row d-none">
        <div id="step-one" class="single-step ltr-text">
            <p>We're proud we can offer <u>true</u> ad-hoc reporting, and here's where you do it.</p>
            <p>Let's walk through the Ad-Hoc Report Builder together.</p>
        </div>

        <div id="step-two" class="single-step ltr-text">
            <p>This is where all available fields are displayed. Click the "v" for each section to expand it.</p>
        </div>

        <div id="step-three" class="single-step ltr-text">
            <p>
                When you find a field you want to display on the report, simply drag it to the right ("Drag and drop
                fields here").
            </p>
            <p>Once you're done adding fields, let's move on to the Conditions..</p>
        </div>

        <div id="step-four" class="single-step ltr-text">
            <p>
                Using the same method of drag and drop, fields added to the "Conditions" area allow you to further
                refine the report.
            </p>
            <p>
                Depending on what type of field you select for Conditions, different conditional criteria will be
                available. Drag a few over to see how it works.
            </p>
        </div>

        <div id="step-five" class="single-step ltr-text">
            <p>
                Add a name for your report here. This is the title you'll see in "My Reports" so make it something
                you'll know when you see it.
            </p>
        </div>

        <div id="step-six" class="single-step ltr-text">
            <p>It's a good idea to check to make sure you've created the perfect report before you save it.</p>
        </div>

        <div id="step-seven" class="single-step ltr-text">
            <p>Once you're satisfied with your new report, click here to save it.</p>
            <p>Once saved, the report will be accessible in the My Reports area to run whenever you need it.</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "CreateReportsTour",
};
</script>
