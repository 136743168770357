<template>
    <div class="w-100 overflow-hidden m-4">
        <h1 class="page-header">Node Status</h1>
        <div class="nodestatus">
            <mdb-dropdown>
                <mdb-dropdown-toggle color="info" slot="toggle">Add Node(s)</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="info">
                    <mdb-dropdown-item @click="addNode(1)">1 Node</mdb-dropdown-item>
                    <mdb-dropdown-item @click="addNode(2)">2 Nodes</mdb-dropdown-item>
                    <mdb-dropdown-item @click="addNode(3)">3 Nodes</mdb-dropdown-item>
                    <mdb-dropdown-item @click="addNode(4)">4 Nodes</mdb-dropdown-item>
                    <mdb-dropdown-item @click="addNode(5)">5 Nodes</mdb-dropdown-item>
                </mdb-dropdown-menu>
            </mdb-dropdown>
            <mdb-btn color="info" @click.native="addNode(1)">Add Node</mdb-btn>
            <vue-good-table :rows="nodes" :columns="columns" :isLoading.sync="loading"></vue-good-table>
        </div>
        <mdb-modal :show="showAddModal" @close="showAddModal = false">
            <mdb-modal-header>
                <mdb-modal-title>Add Node(s)</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <p>Are you sure you want to add {{ this.numToAdd }} node(s) to the audit process?</p>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="danger" @click.native="showAddModal = false">Cancel</mdb-btn>
                <mdb-btn color="success" @click.native="confirmAdd">Add Node(s)</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>
<script>
export default {
    name: "AdminNodeStatus",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            nodes: null,
            loading: false,
            columns: [
                {
                    label: "Account",
                    field: "na_act",
                },
                {
                    label: "App ID",
                    field: "na_ap_id",
                },
                {
                    label: "Last Activity",
                    field: "ns_last_activity",
                },
                {
                    label: "Last Activity Time",
                    field: "ns_last_activity_time",
                },
                {
                    label: "Node",
                    field: "ns_node",
                },
                {
                    label: "Start Time",
                    field: "ns_start_time",
                },
                {
                    label: "Status",
                    field: "ns_status",
                },
            ],
            showAddModal: false,
            numToAdd: 0,
        };
    },
    methods: {
        async getNodes() {
            this.loading = true;
            this.nodes = null;
            const nodes = await this.$httpAdmin.get("NodeStatus", { headers: this.headers });
            if (nodes) {
                this.nodes = nodes.data;
            }
            this.loading = false;
        },
        addNode(num) {
            this.numToAdd = num;
            if (num > 0) {
                this.showAddModal = true;
            } else {
                this.$notify.error({ message: "Number nodes must be more than zero." });
            }
        },
        async confirmAdd() {
            try {
                await this.$httpAdmin.post("NodeStatus", { num_nodes: this.numToAdd }, { headers: this.headers });
                this.$notify.success({
                    message: `${this.numToAdd} Nodes has been successfully added to the current audit`,
                });
            } catch (e) {
                this.$notify.error({ message: `Could not add node(s): ${e}` });
            }
            this.getNodes();
        },
    },
    mounted() {
        this.getNodes();
    },
};
</script>
<style>
.nodestatus .dropdown-menu {
    margin-top: -20px;
}
</style>
