<template>
    <div class="w-100 overflow-auto">
        <PageHeader data-test="pageHeader" :pageSubTitle="''" :pageTitle="'Settings'" :iconName="'cog'" />
        <hr />
        <mdb-row data-test="mdb-row_if-companyProfile" v-if="companyProfile">
            <mdb-col md="8" sm="12">
                <mdb-tabs
                    data-test="mdb-tabs"
                    :active="0"
                    default
                    :links="[
                        { text: 'User Profile', slot: 'user-profile', icon: 'user' },
                        { text: 'Company Profile', slot: 'company-profile', icon: 'building' },
                        { text: 'Contacts', slot: 'contacts', icon: 'address-book' },
                        { text: 'Address Management', slot: 'address-management', icon: 'address-card' },
                    ]"
                    :transition-duration="0.5"
                    transition-style="linear"
                >
                    <template data-test="mdb-tabs_user-profile" :slot="'user-profile'">
                        <div data-test="user-profile_spinner" class="d-flex justify-content-center" v-if="spinner">
                            <mdb-spinner big color="blue"></mdb-spinner>
                        </div>
                        <div v-bind:class="{ loading: spinner }">
                            <h3 data-test="user-profile_title">User Profile</h3>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="userProfile_firstName"
                                        label="First name"
                                        type="text"
                                        v-model="user.usr_first_name"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="userProfile_lastName"
                                        label="Last name"
                                        type="text"
                                        v-model="user.usr_last_name"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="userProfile_phone"
                                        label="Phone"
                                        type="text"
                                        v-model="user.usr_phone"
                                        placeholder="(555)555-5555"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-select
                                        data-test="userProfile_timeZones"
                                        v-model="timeZones"
                                        placeholder="Select a timezone"
                                        label="Time Zone"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="mx-0 mt-4 p-0">
                                <mdb-btn
                                    data-test="userProfile_updateUserProfileBtn"
                                    v-if="user.usr_first_name && user.usr_last_name"
                                    color="success"
                                    @click.native="updateUserProfile"
                                    >Update</mdb-btn
                                >
                                <mdb-btn v-else disabled color="success" @click="updateUserProfile">Update</mdb-btn>
                            </mdb-row>
                            <hr />
                            <h3 data-test="user-profile_title2">Change Password</h3>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="userProfile_oldPassword"
                                        label="Old Password"
                                        type="password"
                                        v-model="oldPassword"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="userProfile_newPassword"
                                        label="New Password"
                                        type="password"
                                        class="mb-0"
                                        v-model="newPassword"
                                    />
                                    <span
                                        v-if="newPassword && newPassword.length > 0 && newPassword.length < 7"
                                        class="text-danger"
                                        >Minimum of 7 Characters</span
                                    ><span v-else>Minimum of 7 Characters</span>
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="userProfile_verifyPassword"
                                        label="Verify New Password"
                                        type="password"
                                        v-model="newPassVerify"
                                        class="mb-0"
                                    />
                                    <span
                                        v-if="
                                            newPassword.length > 0 &&
                                            newPassVerify.length > 0 &&
                                            newPassword != newPassVerify
                                        "
                                        class="m-0 text-danger"
                                        >Oops! Try again, these don't match</span
                                    >
                                    <span
                                        v-if="newPassword.length > 0 && newPassVerify.length == 0"
                                        class="m-0 text-danger"
                                        >Please fill out this field</span
                                    >
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="mx-0 mt-5 px-0">
                                <mdb-btn
                                    data-test="userProfile_updatePasswordBtn"
                                    v-if="newPassword.length > 7 && newPassVerify == newPassword && oldPassword.length"
                                    color="success"
                                    @click.native="updatePassword"
                                    >Update</mdb-btn
                                >
                                <mdb-btn v-else disabled color="success" @click="updatePassword">Update</mdb-btn>
                                <mdb-btn
                                    data-test="userProfile_clearPasswordsBtn"
                                    color="light"
                                    @click.native="clearPasswords"
                                    >Reset</mdb-btn
                                >
                            </mdb-row>
                        </div>
                    </template>
                    <template data-test="mdb-tabs_company-profile" :slot="'company-profile'">
                        <div data-test="companyProfile_spinner" class="d-flex justify-content-center" v-if="spinner">
                            <mdb-spinner big color="blue"></mdb-spinner>
                        </div>
                        <div v-bind:class="{ loading: spinner }">
                            <h3 data-test="companyProfile_title">Company Profile</h3>
                            <mdb-row v-if="companyProfile" class="m-0 p-0">
                                <mdb-col col="4">
                                    <mdb-input
                                        data-test="companyProfile_companyName"
                                        label="Company Name"
                                        type="text"
                                        v-model="companyProfile.cmp_name"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <hr />
                            <h3 data-test="companyProfile_title2">Contact Information</h3>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_cmpContactFirstName"
                                        label="First name"
                                        type="text"
                                        v-model="companyProfile.cmp_contact_first_name"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_cmpContactLastName"
                                        label="Last name"
                                        type="text"
                                        v-model="companyProfile.cmp_contact_last_name"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="4">
                                    <mdb-input
                                        data-test="companyProfile_cmpContactPhone"
                                        label="Phone"
                                        type="text"
                                        v-model="companyProfile.cmp_contact_phone"
                                    />
                                </mdb-col>
                                <mdb-col col="2">
                                    <mdb-input
                                        data-test="companyProfile_cmpContactExt"
                                        label="Ext"
                                        type="text"
                                        v-model="companyProfile.cmp_contact_phone_ext"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_cmpContactEmail"
                                        label="email"
                                        type="text"
                                        v-model="companyProfile.cmp_contact_email"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <hr />
                            <h3 data-test="companyProfile_title3">Billing Information</h3>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_cmpBillingFirstName"
                                        label="First name"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_first_name"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_cmpBillingLastName"
                                        label="Last name"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_last_name"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="4">
                                    <mdb-input
                                        data-test="companyProfile_cmpBillingPhone"
                                        label="Phone"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_phone"
                                    />
                                </mdb-col>
                                <mdb-col col="2">
                                    <mdb-input
                                        data-test="companyProfile_cmpBillingExt"
                                        label="Ext"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_phone_ext"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_cmpBillingEmail"
                                        label="Email"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_email"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <hr />
                            <h3 data-test="companyProfile_title4">Billing Address</h3>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_billingAddress"
                                        label="Address"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_addr1"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_billingAddress2"
                                        label="Address 2"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_addr2"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_billingCity"
                                        label="City"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_city"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-select
                                        data-test="companyProfile_state/province"
                                        label="State/Province"
                                        placeholder="Please select"
                                        v-model="states"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="m-0 p-0">
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="companyProfile_postalCode"
                                        label="Postal Code"
                                        type="text"
                                        v-model="companyProfile.cmp_billing_zip"
                                    />
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-select
                                        data-test="companyProfile_country"
                                        label="Country"
                                        placeholder="Please select"
                                        v-model="countries"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="mx-0 mt-5 px-0">
                                <mdb-btn
                                    data-test="companyProfile_updateCompanyProfileBtn"
                                    v-if="
                                        companyProfile.cmp_name.length &&
                                        companyProfile.cmp_contact_first_name.length &&
                                        companyProfile.cmp_contact_last_name.length &&
                                        companyProfile.cmp_contact_phone.length &&
                                        companyProfile.cmp_contact_email.length &&
                                        companyProfile.cmp_billing_first_name.length &&
                                        companyProfile.cmp_billing_last_name.length &&
                                        companyProfile.cmp_billing_phone.length &&
                                        companyProfile.cmp_billing_email.length &&
                                        companyProfile.cmp_billing_addr1.length &&
                                        companyProfile.cmp_billing_city.length &&
                                        companyProfile.cmp_billing_zip.length
                                    "
                                    color="success"
                                    @click.native="updateCompanyProfile"
                                    >Update</mdb-btn
                                >
                                <mdb-btn v-else disabled color="success" @click="updateCompanyProfile">Update</mdb-btn>
                                <mdb-btn
                                    data-test="companyProfile_resetCompanyProfileBtn"
                                    color="light"
                                    @click.native="resetCompanyProfile"
                                    >Reset</mdb-btn
                                >
                            </mdb-row>
                        </div>
                    </template>
                    <template data-test="mdb-tabs_user-profile" :slot="'contacts'">
                        <div data-test="contacts_spinner" class="d-flex justify-content-center" v-if="spinner">
                            <mdb-spinner big color="blue"></mdb-spinner>
                        </div>
                        <div v-bind:class="{ loading: spinner }">
                            <h3 data-test="contacts_title">Contacts</h3>
                            <p>Contacts can receive scheduled reports but not login to the dashboard.</p>
                            <mdb-row class="pt-4">
                                <mdb-col col="5" class="pl-3">
                                    <mdb-btn
                                        data-test="contacts_showContactModalBtn"
                                        color="cyan"
                                        size="sm"
                                        @click="showContactModal = true"
                                        >New<mdb-icon icon="plus" class="ml-1"></mdb-icon
                                    ></mdb-btn>
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="pt-4 pl-3">
                                <vue-good-table
                                    data-test="contacts_vue-good-table"
                                    class="w-100"
                                    :rows="contactsRows"
                                    :columns="contactsColumns"
                                    mode="remote"
                                    :isLoading.sync="loading"
                                    :search-options="{
                                        enabled: true,
                                        trigger: 'enter',
                                        placeholder: 'Search by last name',
                                    }"
                                    styleClass="vgt-table striped bordered"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-per-page-change="onPerPageChange"
                                    @on-search="searchContactTable"
                                    :totalRows="totalRecords"
                                    :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPage: this.limit,
                                        position: 'top',
                                        perPageDropdown: [10, 25, 50, 100],
                                        dropdownAllowAll: false,
                                        setCurrentPage: this.page,
                                    }"
                                >
                                    <template slot="loadingContent">
                                        <mdb-spinner></mdb-spinner> Loading data...
                                    </template>
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field == 'edit'">
                                            <a class="btn-sm btn-outline-info"
                                                ><mdb-icon icon="edit" @click="editContact(props.row)"
                                            /></a>
                                        </div>
                                        <div v-else-if="props.column.field == 'delete'">
                                            <a class="btn-sm btn-outline-danger"
                                                ><mdb-icon icon="trash" @click.native="deleteContact(props.row)"
                                            /></a>
                                        </div>
                                    </template>
                                </vue-good-table>
                            </mdb-row>
                            <mdb-modal
                                data-test="contacts_modal"
                                :show="showContactModal"
                                @close="showContactModal = false"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title data-test="contacts_modalTitle" tag="h4" bold class="w-100"
                                        ><mdb-icon icon="book" class="mr-2" />Add Contact</mdb-modal-title
                                    >
                                </mdb-modal-header>
                                <mdb-modal-body class="mx-3 grey-text">
                                    <mdb-input
                                        data-test="contacts_modalFirstName"
                                        label="First Name"
                                        type="text"
                                        v-model="cntc_first_name"
                                        placeholder="Contact's First Name"
                                    />
                                    <mdb-input
                                        data-test="contacts_modalLastName"
                                        label="Last Name"
                                        type="text"
                                        v-model="cntct_last_name"
                                        placeholder="Contact's Last Name"
                                    />
                                    <mdb-input
                                        data-test="contacts_modalEmail"
                                        label="Email"
                                        type="email"
                                        v-model="cntct_email"
                                        placeholder="Contact's Email"
                                    />
                                    <mdb-input
                                        data-test="contacts_modalTitle"
                                        label="Title"
                                        type="text"
                                        v-model="cntct_title"
                                        placeholder="Contact's Title"
                                    />
                                    <mdb-input
                                        data-test="contacts_modalPhone"
                                        label="Phone"
                                        type="number"
                                        v-model="cntct_phone"
                                        placeholder="Contact's Phone Number"
                                    />
                                    <mdb-input
                                        data-test="contacts_modalNote"
                                        label="Note"
                                        type="textarea"
                                        rows="4"
                                        v-model="cntct_note"
                                        placeholder="Additional Information"
                                    />
                                </mdb-modal-body>
                                <mdb-modal-footer right>
                                    <mdb-btn color="light" @click="showContactModal = false">Close</mdb-btn>
                                    <mdb-btn
                                        data-test="contacts_modalAddContactBtn"
                                        v-if="
                                            cntc_first_name.length &&
                                            cntct_last_name.length &&
                                            cntct_email.length &&
                                            cntct_title.length
                                        "
                                        color="primary"
                                        @click.native="addContact"
                                        >Save Changes</mdb-btn
                                    >
                                    <mdb-btn v-else disabled color="primary" @click="addContact">Save Changes</mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                            <mdb-modal
                                data-test="contact_editModal"
                                :show="editContactModal"
                                @close="editContactModal = false"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title data-test="contacts_editModalTitle" tag="h4" bold class="w-100"
                                        ><mdb-icon icon="book" class="mr-2" />Add Contact</mdb-modal-title
                                    >
                                </mdb-modal-header>
                                <mdb-modal-body class="mx-3 grey-text">
                                    <mdb-input
                                        data-test="contacts_editModalFirstName"
                                        label="First Name"
                                        type="text"
                                        v-model="editContactFirstName"
                                        placeholder="Contact's First Name"
                                    />
                                    <mdb-input
                                        data-test="contacts_editModalLastName"
                                        label="Last Name"
                                        type="text"
                                        v-model="editContactLastName"
                                        placeholder="Contact's Last Name"
                                    />
                                    <mdb-input
                                        data-test="contacts_editModalEmail"
                                        label="Email"
                                        type="email"
                                        v-model="editContactEmail"
                                        placeholder="Contact's Email"
                                    />
                                    <mdb-input
                                        data-test="contacts_editModalTitle"
                                        label="Title"
                                        type="text"
                                        v-model="editContactTitle"
                                        placeholder="Contact's Title"
                                    />
                                    <mdb-input
                                        data-test="contacts_editModalPhone"
                                        label="Phone"
                                        type="number"
                                        v-model="editContactPhone"
                                        placeholder="Contact's Phone Number"
                                    />
                                    <mdb-input
                                        data-test="contacts_editModalNote"
                                        label="Note"
                                        type="textarea"
                                        rows="4"
                                        v-model="editContactNote"
                                        placeholder="Additional Information"
                                    />
                                </mdb-modal-body>
                                <mdb-modal-footer right>
                                    <mdb-btn color="light" @click="editContactModal = false">Close</mdb-btn>
                                    <mdb-btn
                                        data-test="contacts_editModalSaveEditContactBtn"
                                        v-if="
                                            editContactFirstName.length &&
                                            editContactLastName.length &&
                                            editContactEmail.length &&
                                            editContactTitle.length
                                        "
                                        color="primary"
                                        @click.native="saveEditContact"
                                        >Save Changes</mdb-btn
                                    >
                                    <mdb-btn v-else disabled color="primary" @click="saveEditContact"
                                        >Save Changes</mdb-btn
                                    >
                                </mdb-modal-footer>
                            </mdb-modal>
                            <mdb-modal
                                data-test="contacts_deleteModal"
                                :show="confirmDeleteModal"
                                @close="confirmDeleteModal = false"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title data-test="contacts_deleteModalTitle"
                                        >Delete Contact</mdb-modal-title
                                    >
                                </mdb-modal-header>
                                <mdb-modal-body>
                                    <p>Are you sure you want to delete this contact?</p>
                                    <br />
                                    <ul style="list-style-type: none">
                                        <li>
                                            Name: {{ editContactID.cntct_first_name }}
                                            {{ editContactID.cntct_last_name }}
                                        </li>
                                        <li>Email: {{ editContactID.cntct_email }}</li>
                                        <li>Title: {{ editContactID.cntct_title }}</li>
                                        <li>Phone: {{ editContactID.cntct_phone }}</li>
                                    </ul>
                                </mdb-modal-body>
                                <mdb-modal-footer>
                                    <mdb-btn
                                        data-test="contacts_deleteModalConfirmDeleteContactBtn"
                                        color="primary"
                                        @click.native="confirmedDeleteContact"
                                        >Delete</mdb-btn
                                    >
                                    <mdb-btn
                                        data-test="contacts_deleteModalCancelDeleteBtn"
                                        color="info"
                                        @click.native="cancelDelete"
                                        >Cancel</mdb-btn
                                    >
                                </mdb-modal-footer>
                            </mdb-modal>
                        </div>
                    </template>
                    <template data-test="mdb-tabs_address-management" :slot="'address-management'">
                        <div class="d-flex justify-content-center" v-if="spinner">
                            <mdb-spinner data-test="address-management_spinner" big color="blue"></mdb-spinner>
                        </div>
                        <div v-bind:class="{ loading: spinner }">
                            <h3 data-test="address-management_title">Address Management</h3>
                            <p>Manage distribution center and important recipient addresses for various functions.</p>
                            <mdb-row class="pt-4">
                                <mdb-col col="5" class="pl-3">
                                    <mdb-btn
                                        data-test="address-management_showAddressModalBtn"
                                        color="cyan"
                                        size="sm"
                                        @click="showAddressModal = true"
                                        >New<mdb-icon icon="plus" class="ml-1"></mdb-icon
                                    ></mdb-btn>
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="pt-4 pl-3">
                                <vue-good-table
                                    data-test="address-management_vue-good-table"
                                    :rows="addressesRows"
                                    :columns="addressesColumns"
                                    :search-options="{ enabled: true }"
                                    styleClass="vgt-table striped bordered"
                                    :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPage: 10,
                                        position: 'bottom',
                                        perPageDropdown: [10, 25, 50, 100],
                                        dropdownAllowAll: false,
                                        setCurrentPage: 1,
                                        nextLabel: 'next',
                                        prevLabel: 'prev',
                                        rowsPerPageLabel: 'Rows per page',
                                        ofLabel: 'of',
                                        pageLabel: 'page', // for 'pages' mode
                                        allLabel: 'All',
                                    }"
                                >
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field == 'edit'">
                                            <a class="btn-sm btn-outline-info"
                                                ><mdb-icon icon="edit" @click.native="editAddress(props.row)"
                                            /></a>
                                        </div>
                                        <div v-else-if="props.column.field == 'delete'">
                                            <a class="btn-sm btn-outline-danger"
                                                ><mdb-icon icon="trash" @click.native="deleteAddress(props.row)"
                                            /></a>
                                        </div>
                                    </template>
                                </vue-good-table>
                            </mdb-row>
                            <mdb-modal
                                data-test="address-management_addressModal"
                                :show="showAddressModal"
                                @close="showAddressModal = false"
                            >
                                <mdb-modal-header>
                                    <mdb-modal-title
                                        data-test="address-management_addressModalTitle"
                                        tag="h4"
                                        bold
                                        class="w-100"
                                        ><mdb-icon icon="address-card" class="mr-2" />Add Address</mdb-modal-title
                                    >
                                </mdb-modal-header>
                                <mdb-modal-body class="mx-3 grey-text">
                                    <mdb-input
                                        data-test="address-management_addressModalNickname"
                                        label="Nickname"
                                        type="text"
                                        v-model="ca_nickname"
                                    />
                                    <mdb-input
                                        data-test="address-management_addressModalContact"
                                        label="Contact"
                                        type="text"
                                        v-model="ca_contact"
                                    />
                                    <mdb-input
                                        data-test="address-management_addressModalCompany"
                                        label="Company"
                                        type="email"
                                        v-model="ca_company"
                                    />
                                    <mdb-select
                                        data-test="address-management_addressModalType"
                                        label="Type"
                                        type="text"
                                        v-model="addressType"
                                        placeholder="Choose an address type"
                                    />
                                    <mdb-input
                                        data-test="address-management_addressModalAddress"
                                        label="Address Line 1"
                                        type="text"
                                        v-model="ca_addr1"
                                    />
                                    <mdb-input
                                        data-test="address-management_addressModalAddress2"
                                        label="Address Line 2"
                                        type="text"
                                        v-model="ca_addr2"
                                    />
                                    <mdb-input
                                        data-test="address-management_addressModalCity"
                                        label="City"
                                        type="text"
                                        v-model="ca_city"
                                    />
                                    <mdb-input
                                        data-test="address-management_addressModalState"
                                        label="State"
                                        type="text"
                                        v-model="ca_state"
                                    />
                                    <mdb-select
                                        data-test="address-management_addressModalCountry"
                                        label="Country"
                                        type="text"
                                        v-model="addressCountry"
                                        placeholder="Choose a country"
                                    />
                                    <mdb-input
                                        data-test="address-management_addressModalZip"
                                        label="Zip"
                                        type="text"
                                        v-model="ca_zip"
                                    />
                                </mdb-modal-body>
                                <mdb-modal-footer right>
                                    <mdb-btn color="light" @click="showAddressModal = false">Close</mdb-btn>
                                    <mdb-btn
                                        data-test="address-management_addressModalAddAddressBtn"
                                        v-if="
                                            ca_nickname.length &&
                                            ca_addr1.length &&
                                            ca_city.length &&
                                            ca_state.length &&
                                            ca_zip.length
                                        "
                                        color="primary"
                                        @click.native="addAddress"
                                        >Save Changes</mdb-btn
                                    >
                                    <mdb-btn v-else disabled color="primary" @click="addAddress">Save Changes</mdb-btn>
                                </mdb-modal-footer>
                            </mdb-modal>
                            <mdb-modal :show="editAddressModal" @close="editAddressModal = false">
                                <mdb-modal-header>
                                    <mdb-modal-title
                                        data-test="address-management_editAddressModalTitle"
                                        tag="h4"
                                        bold
                                        class="w-100"
                                        ><mdb-icon icon="address-card" class="mr-2" />Add Address</mdb-modal-title
                                    >
                                </mdb-modal-header>
                                <mdb-modal-body class="mx-3 grey-text">
                                    <mdb-input
                                        data-test="address-management_editAddressModalNickname"
                                        label="Nickname"
                                        type="text"
                                        v-model="editAddressNickname"
                                    />
                                    <mdb-input
                                        data-test="address-management_editAddressModalContact"
                                        label="Contact"
                                        type="text"
                                        v-model="editAddressContact"
                                    />
                                    <mdb-input
                                        data-test="address-management_editAddressModalCompany"
                                        label="Company"
                                        type="email"
                                        v-model="editAddressCompany"
                                    />
                                    <mdb-select
                                        data-test="address-management_editAddressModalType"
                                        label="Type"
                                        type="text"
                                        v-model="addressType"
                                        placeholder="Choose an address type"
                                        required
                                    />
                                    <mdb-input
                                        data-test="address-management_editAddressModalAddress"
                                        label="Address Line 1"
                                        type="text"
                                        v-model="editAddressLine1"
                                    />
                                    <mdb-input
                                        data-test="address-management_editAddressModalAddress2"
                                        label="Address Line 2"
                                        type="text"
                                        v-model="editAddressLine2"
                                    />
                                    <mdb-input
                                        data-test="address-management_editAddressModalCity"
                                        label="City"
                                        type="text"
                                        v-model="editAddressCity"
                                    />
                                    <mdb-input
                                        data-test="address-management_editAddressModalState"
                                        label="State"
                                        type="text"
                                        v-model="editAddressState"
                                    />
                                    <mdb-select
                                        data-test="address-management_editAddressModalCountry"
                                        label="Country"
                                        type="text"
                                        v-model="addressCountry"
                                        placeholder="Choose a country"
                                        required
                                    />
                                    <mdb-input
                                        data-test="address-management_editAddressModalZip"
                                        label="Zip"
                                        type="text"
                                        v-model="editAddressZip"
                                    />
                                </mdb-modal-body>
                                <mdb-modal-footer right>
                                    <mdb-btn color="light" @click="editAddressModal = false">Close</mdb-btn>
                                    <mdb-btn
                                        data-test="address-management_editAddressModalSaveEditAddressBtn"
                                        v-if="
                                            editAddressNickname.length &&
                                            editAddressLine1.length &&
                                            editAddressCity.length &&
                                            editAddressState.length &&
                                            editAddressZip.length
                                        "
                                        color="primary"
                                        @click.native="saveEditAddress"
                                        >Save Changes</mdb-btn
                                    >
                                    <mdb-btn v-else disabled color="primary" @click="saveEditAddress"
                                        >Save Changes</mdb-btn
                                    >
                                </mdb-modal-footer>
                            </mdb-modal>
                        </div>
                    </template>
                </mdb-tabs>
            </mdb-col>
            <mdb-col md="4" sm="12" class="pr-4">
                <mdb-card data-test="userProfileCard" class="w-100">
                    <mdb-card-header>
                        User Profile <mdb-icon icon="user" class="float-right text-muted" />
                    </mdb-card-header>
                    <mdb-card-body
                        ><mdb-row data-test="userProfileName"
                            ><mdb-col col="3" class="text-left"><b>Name</b></mdb-col
                            ><mdb-col col="9" class="text-right"
                                >{{ this.userInfo.usrFirstName }} {{ this.userInfo.usrLastName }}</mdb-col
                            >
                        </mdb-row>
                        <hr />
                        <mdb-row data-test="userProfileEmail">
                            <mdb-col col="3" class="text-left"><b>Email</b></mdb-col
                            ><mdb-col col="9" class="text-right">{{ this.userInfo.usrEmail }}</mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row data-test="userProfileRole">
                            <mdb-col col="3" class="text-left"><b>Role</b></mdb-col
                            ><mdb-col col="9" class="text-right">{{ this.userInfo.usrLevel }}</mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row data-test="userProfileLastLogin">
                            <mdb-col col="4" class="text-left"><b>Last Login</b></mdb-col
                            ><mdb-col col="8" class="text-right">{{ this.userInfo.usrLastLoginDate }}</mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                </mdb-card>
                <mdb-card data-test="companyProfileCard" class="w-100 mt-4">
                    <mdb-card-header>
                        Company Profile <mdb-icon icon="building" class="float-right text-muted" />
                    </mdb-card-header>
                    <mdb-card-body v-if="companyProfile">
                        <mdb-row data-test="companyProfileCompanyName">
                            <mdb-col col="6" class="text-left"><b>Company Name</b></mdb-col
                            ><mdb-col col="6" class="text-right">{{ this.companyProfile.cmp_name }}</mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row data-test="companyProfileBillingContact">
                            <mdb-col col="5" class="text-left"><b>Billing Contact</b></mdb-col
                            ><mdb-col col="7" class="text-right"
                                >{{ this.companyProfile.cmp_billing_first_name }}
                                {{ this.companyProfile.cmp_billing_last_name }}</mdb-col
                            >
                        </mdb-row>
                        <hr />
                        <mdb-row data-test="companyProfileBillingEmail">
                            <mdb-col col="12" class="text-left"><b>Billing Email</b></mdb-col>
                            <span class="pl-3">{{ this.companyProfile.cmp_billing_email }}</span>
                        </mdb-row>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
    </div>
</template>
<script>
import moment from "moment";
import PageHeader from "@/components/PageHeader";
import { VueGoodTable } from "vue-good-table";
import {
    mdbBtn,
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbIcon,
    mdbTabs,
    mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbAccordion,
    mdbAccordionPane,
    mdbSpinner,
    mdbInput,
    mdbSelect,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbCardHeader,
} from "mdbvue";

export default {
    name: "Settings",
    components: {
        PageHeader,
        VueGoodTable,
        mdbBtn,
        mdbContainer,
        mdbCol,
        mdbRow,
        mdbIcon,
        mdbTabs,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbAccordion,
        mdbAccordionPane,
        mdbSpinner,
        mdbInput,
        mdbSelect,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbCardHeader,
    },
    data() {
        return {
            companyProfile: null,
            timeZones: [
                { value: "-12.0", text: "(GMT -12:00) Eniwetok, Kwajalein", selected: false },
                { value: "-11.0", text: "(GMT -11:00) Midway Island, Samoa", selected: false },
                { value: "-10.0", text: "(GMT -10:00) Hawaii", selected: false },
                { value: "-9.0", text: "(GMT -9:00) Alaska", selected: false },
                { value: "-8.0", text: "(GMT -8:00) Pacific Time (US &amp; Canada)", selected: false },
                { value: "-7.0", text: "(GMT -7:00) Mountain Time (US &amp; Canada)", selected: false },
                { value: "-6.0", text: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City", selected: false },
                { value: "-5.0", text: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima", selected: false },
                { value: "-4.0", text: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", selected: false },
                { value: "-3.5", text: "(GMT -3:30) Newfoundland", selected: false },
                { value: "-3.0", text: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", selected: false },
                { value: "-2.0", text: "(GMT -2:00) Mid-Atlantic", selected: false },
                { value: "-1.0", text: "(GMT -1:00 hour) Azores, Cape Verde Islands", selected: false },
                { value: "0.0", text: "(GMT) Western Europe Time, London, Lisbon, Casablanca", selected: false },
                { value: "1.0", text: "(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris", selected: false },
                { value: "2.0", text: "(GMT +2:00) Kaliningrad, South Africa", selected: false },
                { value: "3.0", text: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", selected: false },
                { value: "3.5", text: "(GMT +3:30) Tehran", selected: false },
                { value: "4.0", text: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", selected: false },
                { value: "4.5", text: "(GMT +4:30) Kabul", selected: false },
                { value: "5.0", text: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", selected: false },
                { value: "5.5", text: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", selected: false },
                { value: "5.75", text: "(GMT +5:45) Kathmandu", selected: false },
                { value: "6.0", text: "(GMT +6:00) Almaty, Dhaka, Colombo", selected: false },
                { value: "7.0", text: "(GMT +7:00) Bangkok, Hanoi, Jakarta", selected: false },
                { value: "8.0", text: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", selected: false },
                { value: "9.0", text: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", selected: false },
                { value: "9.5", text: "(GMT +9:30) Adelaide, Darwin", selected: false },
                { value: "10.0", text: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", selected: false },
                { value: "11.0", text: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", selected: false },
                { value: "12.0", text: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", selected: false },
            ],
            states: [
                { value: "AL", text: "Alabama", selected: false },
                { value: "AK", text: "Alaska", selected: false },
                { value: "AB", text: "Alberta", selected: false },
                { value: "AZ", text: "Arizona", selected: false },
                { value: "AR", text: "Arkansas", selected: false },
                { value: "BC", text: "British Columbia", selected: false },
                { value: "CA", text: "California", selected: false },
                { value: "CO", text: "Colorado", selected: false },
                { value: "CT", text: "Connecticut", selected: false },
                { value: "DE", text: "Delaware", selected: false },
                { value: "DC", text: "District of Columbia", selected: false },
                { value: "FL", text: "Florida", selected: false },
                { value: "GA", text: "Georgia", selected: false },
                { value: "HI", text: "Hawaii", selected: false },
                { value: "ID", text: "Idaho", selected: false },
                { value: "IL", text: "Illinois", selected: false },
                { value: "IN", text: "Indiana", selected: false },
                { value: "IA", text: "Iowa", selected: false },
                { value: "KS", text: "Kansas", selected: false },
                { value: "KY", text: "Kentucky", selected: false },
                { value: "LA", text: "Louisiana", selected: false },
                { value: "ME", text: "Maine", selected: false },
                { value: "MB", text: "Manitoba", selected: false },
                { value: "MD", text: "Maryland", selected: false },
                { value: "MA", text: "Massachusetts", selected: false },
                { value: "MI", text: "Michigan", selected: false },
                { value: "MN", text: "Minnesota", selected: false },
                { value: "MS", text: "Mississippi", selected: false },
                { value: "MO", text: "Missouri", selected: false },
                { value: "MT", text: "Montana", selected: false },
                { value: "NE", text: "Nebraska", selected: false },
                { value: "NV", text: "Nevada", selected: false },
                { value: "NB", text: "New Brunswick", selected: false },
                { value: "NH", text: "New Hampshire", selected: false },
                { value: "NJ", text: "New Jersey", selected: false },
                { value: "NM", text: "New Mexico", selected: false },
                { value: "NY", text: "New York", selected: false },
                { value: "NL", text: "Newfoundland and Labrador", selected: false },
                { value: "NC", text: "North Carolina", selected: false },
                { value: "ND", text: "North Dakota", selected: false },
                { value: "NT", text: "Northwest Territories", selected: false },
                { value: "NS", text: "Nova Scotia", selected: false },
                { value: "NU", text: "Nunavut", selected: false },
                { value: "OH", text: "Ohio", selected: false },
                { value: "OK", text: "Oklahoma", selected: false },
                { value: "ON", text: "Ontario", selected: false },
                { value: "OR", text: "Oregon", selected: false },
                { value: "PA", text: "Pennsylvania", selected: false },
                { value: "PE", text: "Prince Edward Island", selected: false },
                { value: "PR", text: "Puerto Rico", selected: false },
                { value: "QC", text: "Quebec", selected: false },
                { value: "RI", text: "Rhode Island", selected: false },
                { value: "SK", text: "Saskatchewan", selected: false },
                { value: "SC", text: "South Carolina", selected: false },
                { value: "SD", text: "South Dakota", selected: false },
                { value: "TN", text: "Tennessee", selected: false },
                { value: "TX", text: "Texas", selected: false },
                { value: "UT", text: "Utah", selected: false },
                { value: "VT", text: "Vermont", selected: false },
                { value: "VA", text: "Virginia", selected: false },
                { value: "WA", text: "Washington", selected: false },
                { value: "WV", text: "West Virginia", selected: false },
                { value: "WI", text: "Wisconsin", selected: false },
                { value: "WY", text: "Wyoming", selected: false },
                { value: "YT", text: "Yukon", selected: false },
            ],
            countries: [
                { value: "ca", text: "Canada", selected: false },
                { value: "us", text: "United States", selected: false },
            ],
            addressType: [
                { value: "general", text: "General", selected: false },
                { value: "shiptolocation", text: "Ship To Location", selected: false },
                { value: "shipfromlocation", text: "Ship From Locations", selected: false },
            ],
            addressCountry: [
                { value: "us", text: "United States", selected: false },
                { value: "ca", text: "Canada", selected: false },
                { value: "mx", text: "Mexico", selected: false },
            ],
            contactTableData: [],
            addressTableData: [],
            contactSearch: "",
            addressSearch: "",
            showContactModal: false,
            showAddressModal: false,
            userProfileUpdate: "",
            selectedTimeZone: "",
            newPassword: "",
            oldPassword: "",
            newPassVerify: "",
            cmpProfile: "",
            cntc_first_name: "",
            cntct_last_name: "",
            cntct_email: "",
            cntct_title: "",
            cntct_phone: "",
            cntct_note: "",
            addContactResponse: "",
            contactsColumns: [],
            contactsRows: [],
            editContactModal: false,
            editContactFirstName: "",
            editContactLastName: "",
            editContactEmail: "",
            editContactTitle: "",
            editContactPhone: "",
            editContactNote: "",
            editContactID: "",
            savedEditedContact: "",
            deleteContactResponse: "",
            addressesRows: [],
            addressesColumns: [],
            ca_nickname: "",
            ca_contact: "",
            ca_company: "",
            ca_addr1: "",
            ca_addr2: "",
            ca_city: "",
            ca_state: "",
            ca_zip: "",
            addAddressResponse: [],
            editAddressID: "",
            deleteAddressResponse: "",
            editAddressNickname: "",
            editAddressContact: "",
            editAddressCompany: "",
            editAddressType: "",
            editAddressLine1: "",
            editAddressLine2: "",
            editAddressCity: "",
            editAddressState: "",
            editAddressCountry: "",
            editAddressZip: "",
            editAddressModal: false,
            savedEditedAddress: [],
            spinner: false,
            selectedState: "",
            selectedCountry: "",
            passwordUpdate: "",
            user: [],
            totalRecords: null,
            limit: 10,
            page: 1,
            loading: false,
            size: 10,
            confirmDeleteModal: false,
            searchTerm: "",
        };
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        options: Object,
        headers: Object,
    },
    methods: {
        async getCompanyProfile() {
            //TODO figure out company logo display
            // eslint-disable-next-line no-undef
            try {
                const response = await this.$http.get(`/company/${this.userInfo.cmpID}`, {
                    headers: this.headers,
                });
                this.companyProfile = response.data;
                for (let i = 0; i < this.states.length; i++) {
                    if (this.states[i].value == this.companyProfile.cmp_billing_state) {
                        this.states[i].selected = true;
                    }
                }
                for (let i = 0; i < this.countries.length; i++) {
                    if (this.countries[i].value == this.companyProfile.cmp_billing_country) {
                        this.countries[i].selected = true;
                    }
                }
            } catch (e) {
                this.$notify.error({ message: e });
            }
        },
        async getContacts() {
            this.loading = true;
            try {
                await this.$http
                    .get(`/contact`, { headers: this.headers, params: this.contactParams() })
                    .then(async (response) => {
                        this.contacts = response.data[0].Items;
                        this.totalRecords = response.data[0].TotalRecords;
                        this.contactsRows = this.contacts;
                        const columns = [
                            { field: "cntct_first_name", label: "First Name" },
                            { field: "cntct_last_name", label: "Last Name" },
                            { field: "cntct_email", label: "Email" },
                            { field: "cntct_title", label: "Title" },
                            { field: "cntct_phone", label: "Phone" },
                            { field: "cntct_note", label: "Note" },
                            { field: "edit", label: "", sortable: false },
                            { field: "delete", label: "", sortable: false },
                        ];
                        this.contactsColumns = columns;
                        this.contactTableData = {
                            columns,
                            rows: this.contacts,
                        };
                    });
            } catch (e) {
                this.$notify.error({ message: e });
            }
            this.loading = false;
        },
        async getAddresses() {
            this.spinner - true;
            try {
                await this.$http
                    .get(`/address`, { headers: this.headers, params: this.addressParams() })
                    .then(async (response) => {
                        this.addresses = response.data[0].Items;
                        this.addressesRows = this.addresses;
                        const columns = [
                            { field: "ca_code", label: "Nickname", sort: true },
                            { field: "ca_type", label: "Type", sort: true },
                            { field: "ca_name", label: "Contact", sort: true },
                            { field: "ca_company", label: "Company", sort: true },
                            { field: "ca_addr1", label: "Address Line 1", sort: true },
                            { field: "ca_addr2", label: "Address Line 2", sort: true },
                            { field: "ca_city", label: "City", sort: true },
                            { field: "ca_state", label: "State", sort: true },
                            { field: "ca_zip", label: "Zipcode", sort: true },
                            { field: "ca_country", label: "Country", sort: true },
                            { field: "edit", label: "", sort: false },
                            { field: "delete", label: "", sort: false },
                        ];
                        this.addressesColumns = columns;
                        this.addressTableData = {
                            columns,
                            rows: this.addresses,
                        };
                    });
            } catch (e) {
                this.$notify.error({ message: e });
            }
            this.spinner = false;
        },
        async getUserProfile() {
            try {
                this.$http
                    .get("/userprofile/" + this.userInfo.usrId, { headers: this.headers })
                    .then(async (response) => {
                        this.user = response.data;
                        for (let i = 0; i < this.timeZones.length; i++) {
                            if (this.timeZones[i].value == this.user.usr_time_zone) {
                                this.timeZones[i].selected = true;
                            }
                        }
                    });
            } catch (e) {
                this.$notify.error({ message: e });
            }
        },
        contactParams() {
            return {
                cntct_cmp_id: this.userInfo.cmpID,
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: "cntct_last_name",
                sortkey: "cntct_last_name",
                sortDir: "asc",
                cntct_last_name: this.searchTerm ? this.searchTerm : null,
            };
        },
        async searchContactTable(params) {
            this.searchTerm = params.searchTerm;
            await this.getContacts();
        },
        addressParams() {
            return {
                ca_cmp_id: this.userInfo.cmpID,
                limit: 10,
                size: 10,
                page: 1,
                sortColumn: "ca_type",
                sortkey: "ca_type",
                sortDir: "asc",
            };
        },
        userProfileParams() {
            return {
                usr_first_name: this.user.usr_first_name,
                usr_last_name: this.user.usr_last_name,
                usr_phone: this.user.usr_phone,
                usr_time_zone: this.selectedTimeZone,
            };
        },
        updateUserProfile() {
            this.spinner = true;
            for (let i = 0; i < this.timeZones.length; i++) {
                if (this.timeZones[i].selected) {
                    this.selectedTimeZone = this.timeZones[i].value;
                }
            }
            this.$http
                .put("/userprofile/" + this.userInfo.usrId, this.userProfileParams(), { headers: this.headers })
                .then(async (response) => {
                    if (response.data == true) {
                        this.$notify.success({
                            message: "Your profile has been successfully changed!",
                            title: "Profile Updated",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: "There was a problem updating your profile. Please try again later.",
                            title: "Profile Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                    this.spinner = false;
                });
        },
        passwordParams() {
            return {
                old: this.oldPassword,
                pass: this.newPassword,
            };
        },
        updatePassword() {
            this.spinner = true;
            this.$http
                .put("/aaa/" + this.userInfo.usrId, this.passwordParams(), { headers: this.headers })
                .then(async (response) => {
                    if (response.data == true) {
                        this.$notify.success({
                            message: "Your password has been successfully changed!",
                            title: "Password Updated",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: "There was a problem updating your password. Please try again later.",
                            title: "Password Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                    this.spinner = false;
                    this.passwordUpdate = response;
                    this.clearPasswords();
                });
        },
        clearPasswords() {
            this.oldPassword = "";
            this.newPassword = "";
            this.newPassVerify = "";
        },
        companyProfileParams() {
            for (let i = 0; i < this.states.length; i++) {
                if (this.states[i].selected) {
                    this.selectedState = this.states[i].value;
                }
            }
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].selected) {
                    this.selectedCountry = this.countries[i].value;
                }
            }
            return {
                cmp_name: this.companyProfile.cmp_name,
                cmp_contact_first_name: this.companyProfile.cmp_contact_first_name,
                cmp_contact_last_name: this.companyProfile.cmp_contact_last_name,
                cmp_contact_phone: this.companyProfile.cmp_contact_phone,
                cmp_contact_phone_ext: this.companyProfile.cmp_contact_phone_ext,
                cmp_contact_email: this.companyProfile.cmp_contact_email,
                cmp_billing_first_name: this.companyProfile.cmp_billing_first_name,
                cmp_billing_last_name: this.companyProfile.cmp_billing_last_name,
                cmp_billing_phone: this.companyProfile.cmp_billing_phone,
                cmp_billing_phone_ext: this.companyProfile.cmp_billing_phone_ext,
                cmp_billing_email: this.companyProfile.cmp_billing_email,
                cmp_billing_addr1: this.companyProfile.cmp_billing_addr1,
                cmp_billing_addr2: this.companyProfile.cmp_billing_addr2,
                cmp_billing_city: this.companyProfile.cmp_billing_city,
                cmp_billing_state: this.selectedState,
                cmp_billing_zip: this.companyProfile.cmp_billing_zip,
                cmp_billing_country: this.selectedCountry,
            };
        },
        updateCompanyProfile() {
            this.spinner = true;
            this.$http
                .put("/company/" + this.userInfo.cmpID, this.companyProfileParams(), { headers: this.headers })
                .then(async (response) => {
                    this.cmpProfile = response;
                    this.clearPasswords();
                    if (response.data == true) {
                        this.$notify.success({
                            message: "Your company profile has been successfully updated!",
                            title: "Company Profile Updated",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: "There was a problem updating your company profile. Please try again later.",
                            title: "Company Profile Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
            this.spinner = false;
        },
        resetCompanyProfile() {
            for (let i = 0; i < this.states.length; i++) {
                this.states[i].selected = false;
            }
            for (let i = 0; i < this.countries.length; i++) {
                this.countries[i].selected = false;
            }
            this.companyProfile.cmp_name = "";
            this.companyProfile.cmp_contact_first_name = "";
            this.companyProfile.cmp_contact_last_name = "";
            this.companyProfile.cmp_contact_phone = "";
            this.companyProfile.cmp_contact_phone_ext = "";
            this.companyProfile.cmp_contact_email = "";
            this.companyProfile.cmp_billing_first_name = "";
            this.companyProfile.cmp_billing_last_name = "";
            this.companyProfile.cmp_billing_phone = "";
            this.companyProfile.cmp_billing_phone_ext = "";
            this.companyProfile.cmp_billing_email = "";
            this.companyProfile.cmp_billing_addr1 = "";
            this.companyProfile.cmp_billing_addr2 = "";
            this.companyProfile.cmp_billing_city = "";
            this.companyProfile.cmp_billing_zip = "";
            /*for (let i = 0; i < this.states.length; i++) {
                    if (this.states[i].selected == true) {
                        console.log(this.states[i].text + " this.states[i].text in reset company profile");
                    }
                }
                for (let i = 0; i < this.countries.length; i++) {
                    if (this.countries[i].selected == true) {
                        console.log(this.countries[i].text + " this.countries[i].text in reset company profile");
                    }
                }*/
        },
        addContactParams() {
            return {
                cntct_first_name: this.cntc_first_name,
                cntct_last_name: this.cntct_last_name,
                cntct_email: this.cntct_email,
                cntct_title: this.cntct_title,
                cntct_phone: this.cntct_phone,
                cntct_note: this.cntct_note,
                cntct_cmp_id: this.userInfo.cmpID,
            };
        },
        addContact() {
            this.spinner = true;
            this.showContactModal = false;
            this.$http.post("/contact", this.addContactParams(), { headers: this.headers }).then(async (response) => {
                this.addContactResponse = response;
                this.getContacts();
                this.cntc_first_name = "";
                this.cntct_last_name = "";
                this.cntct_email = "";
                this.cntct_title = "";
                this.cntct_phone = "";
                this.cntct_note = "";
                this.spinner = false;
            });
        },
        addAddress() {
            this.spinner = true;
            this.showAddressModal = false;
            this.$http.post("/address", this.addAddressParams(), { headers: this.headers }).then(async (response) => {
                this.addAddressResponse = response;
                this.getAddresses();
                this.spinner = false;
            });
        },
        addAddressParams() {
            for (let i = 0; i < this.addressType.length; i++) {
                if (this.addressType[i].selected) {
                    this.addressType = this.addressType[i].value;
                }
            }
            for (let i = 0; i < this.addressCountry.length; i++) {
                if (this.addressCountry[i].selected) {
                    this.addressCountry = this.addressCountry[i].value;
                }
            }
            return {
                ca_code: this.ca_nickname,
                ca_name: this.ca_contact,
                ca_company: this.ca_company,
                ca_type: this.addressType,
                ca_addr1: this.ca_addr1,
                ca_addr2: this.ca_addr2,
                ca_city: this.ca_city,
                ca_state: this.ca_state,
                ca_country: this.addressCountry,
                ca_zip: this.ca_zip,
                ca_cmp_id: this.ca_cmp_id,
            };
        },
        deleteAddress(row) {
            this.spinner = true;
            this.editAddressID = row.ca_id;
            this.$http.delete("/address/" + this.editAddressID, { headers: this.headers }).then((response) => {
                this.deleteAddressResponse = response;
                this.getAddresses();
                this.spinner = false;
            });
        },
        editAddress(row) {
            for (let i = 0; i < this.addressType.length; i++) {
                if (this.addressType[i].value == row.ca_type) {
                    this.addressType[i].selected = true;
                }
            }
            for (let i = 0; i < this.addressCountry.length; i++) {
                if (this.addressCountry[i].value == row.ca_country) {
                    this.addressCountry[i].selected = true;
                }
            }
            this.editAddressNickname = row.ca_code;
            this.editAddressContact = row.ca_name;
            this.editAddressCompany = row.ca_company;
            this.editAddressLine1 = row.ca_addr1;
            this.editAddressLine2 = row.ca_addr2;
            this.editAddressCity = row.ca_city;
            this.editAddressState = row.ca_state;
            this.editAddressZip = row.ca_zip;
            this.editAddressID = row.ca_id;
            this.editAddressModal = true;
        },
        saveEditAddress() {
            this.editAddressModal = false;
            this.spinner = true;
            this.$http
                .put("/address/" + this.editAddressID, this.saveEditAddressParams(), { headers: this.headers })
                .then(async (response) => {
                    this.savedEditedAddress = response;
                    this.getAddresses();
                    this.spinner = false;
                });
        },
        saveEditAddressParams() {
            for (let i = 0; i < this.addressType.length; i++) {
                if (this.addressType[i].selected) {
                    this.editAddressType = this.addressType[i].value;
                }
            }
            for (let i = 0; i < this.addressCountry.length; i++) {
                if (this.addressCountry[i].selected) {
                    this.editAddressCountry = this.addressCountry[i].value;
                }
            }
            return {
                ca_code: this.editAddressNickname,
                ca_name: this.editAddressContact,
                ca_company: this.editAddressCompany,
                ca_type: this.editAddressType,
                ca_addr1: this.editAddressLine1,
                ca_addr2: this.editAddressLine2,
                ca_city: this.editAddressCity,
                ca_state: this.editAddressState,
                ca_country: this.editAddressCountry,
                ca_zip: this.editAddressZip,
                ca_cmp_id: this.ca_cmp_id,
            };
        },
        editContact(row) {
            this.editContactFirstName = row.cntct_first_name;
            this.editContactLastName = row.cntct_last_name;
            this.editContactEmail = row.cntct_email;
            this.editContactTitle = row.cntct_title;
            this.editContactPhone = row.cntct_phone;
            this.editContactNote = row.cntct_note;
            this.editContactID = row.cntct_id;
            this.editContactModal = true;
        },
        saveEditContactParams() {
            return {
                cntct_first_name: this.editContactFirstName,
                cntct_last_name: this.editContactLastName,
                cntct_email: this.editContactEmail,
                cntct_title: this.editContactTitle,
                cntct_phone: this.editContactPhone,
                cntct_note: this.editContactNote,
            };
        },
        saveEditContact() {
            this.editContactModal = false;
            this.spinner = true;
            this.$http
                .put("/contact/" + this.editContactID, this.saveEditContactParams(), { headers: this.headers })
                .then(async (response) => {
                    this.savedEditedContact = response;
                    this.getContacts();
                    this.spinner = false;
                });
        },
        deleteContact(row) {
            this.spinner = true;
            this.confirmDeleteModal = true;
            this.editContactID = row;
        },
        cancelDelete() {
            this.confirmDeleteModal = false;
            this.spinner = false;
        },
        confirmedDeleteContact() {
            this.$http.delete("/contact/" + this.editContactID.cntct_id, { headers: this.headers }).then((response) => {
                this.deleteContactResponse = response;
                this.getContacts();
                this.spinner = false;
            });
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.getContacts();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.getContacts();
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.getContacts();
        },
    },
    mounted() {
        this.spinner = true;
        this.getCompanyProfile();
        this.getContacts();
        this.getAddresses();
        this.getUserProfile();
        this.spinner = false;
    },
};
</script>
<style>
.cursor-pointer {
    cursor: pointer;
}
.b-dropdown-form {
    padding: 0 !important;
}
/*.dropdown-menu {
    width: 100%;
    transform: translate3d(0px, 34px, 0px) !important;
}*/
.optionHover:hover {
    background-color: gray !important;
    color: white !important;
}
#dropdown-form.width-600 ul {
    min-width: 600px;
    padding: 0px !important;
}
.form-group {
    padding: 0px !important;
}
.tab-content {
    height: 100% !important;
}
</style>
