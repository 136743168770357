<template>
    <div class="overflow-auto h-90">
        <div class="d-flex justify-content-center" v-if="spinner">
            <mdb-spinner big color="blue"></mdb-spinner>
        </div>
        <div v-bind:class="{ loading: spinner }">
            <h1 class="page-header border-bottom mx-2">
                Fields Manager
                <span class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block">Tech View</span>
                <mdb-icon icon="columns" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row class="py-4">
                <mdb-col class="d-flex">
                    <mdb-card class="w-100">
                        <mdb-card-header>Report Editor</mdb-card-header>
                        <mdb-card-body>
                            <mdb-row>
                                <mdb-col>
                                    <mdb-select
                                        class="w-50"
                                        search
                                        @getValue="getReportValue"
                                        :options="reportOptions"
                                        label="Select Report"
                                    />
                                </mdb-col>
                            </mdb-row>
                            <hr />
                            <mdb-row>
                                <mdb-col>
                                    <codemirror
                                        class="CodeMirror-wrap"
                                        v-model="sqlEditor"
                                        :options="sqlOptions"
                                    ></codemirror>
                                </mdb-col>
                            </mdb-row>
                            <hr />
                            <mdb-row>
                                <mdb-col>
                                    <codemirror
                                        class="CodeMirror-wrap"
                                        v-model="fieldsEditor"
                                        :options="jsOptions"
                                    ></codemirror>
                                </mdb-col>
                            </mdb-row>
                            <hr />
                            <mdb-row>
                                <mdb-col>
                                    <mdb-btn color="primary" @click="updateVerify">Update</mdb-btn>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="pt-4">
                <mdb-col>
                    <mdb-card class="w-100">
                        <mdb-card-header>Fields Manager</mdb-card-header>
                        <mdb-card-body>
                            <mdb-row>
                                <mdb-btn color="primary" v-model="shipperFields" @click.native="addShipperFields"
                                    >Add Shipper Fields</mdb-btn
                                >
                                <mdb-btn color="primary" v-model="recipientFields" @click.native="addRecipientFields"
                                    >Add Recipient Fields</mdb-btn
                                >
                            </mdb-row>
                            <mdb-row>
                                <mdb-col>
                                    <mdb-input
                                        type="textarea"
                                        :rows="4"
                                        cols="50"
                                        label="Comma separated values"
                                        v-model="fieldsManagerText"
                                    ></mdb-input>
                                </mdb-col>
                            </mdb-row>
                            <mdb-row>
                                <mdb-btn color="primary" @click.native="getFieldIds">Get Fields IDs</mdb-btn>
                                <mdb-btn color="primary" @click.native="getFieldDisplayNames"
                                    >Get Field Display Names</mdb-btn
                                >
                                <mdb-btn color="primary" @click.native="getFieldNames">Get Field Names</mdb-btn>
                            </mdb-row>
                            <mdb-row>
                                <mdb-col>
                                    <mdb-input
                                        type="textarea"
                                        :rows="4"
                                        cols="50"
                                        label="Results"
                                        v-model="fieldResults"
                                    ></mdb-input>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
        </div>
        <div>
            <mdb-modal :show="modal" @close="modal = false">
                <mdb-modal-header>
                    <mdb-modal-title>Update Report</mdb-modal-title>
                </mdb-modal-header>
                <mdb-modal-body>
                    <p>Are you sure you want to update this report?</p>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn size="sm" outline="primary" @click.native="modal = false">Cancel</mdb-btn>
                    <mdb-btn size="sm" color="primary" @click.native="updateReport">OK</mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
    </div>
</template>
<script>
import { codemirror } from "vue-codemirror";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/sql/sql";
import "codemirror/lib/codemirror.css";

export default {
    name: "AdminFieldsManager",
    components: {
        codemirror,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            spinner: true,
            selectedReport: null,
            reports: null,
            reportsCreated: null,
            reportOptions: [],
            sqlEditor: "",
            fieldsEditor: "",
            modal: false,
            updatedReport: null,
            updatedReportMessage: null,
            shipperFields: "",
            fieldsManagerText: "",
            recipientFields: "",
            fieldResults: "",
            submittedResults: null,
            jsOptions: {
                tabSize: 4,
                mode: "text/javascript",
                lineNumbers: true,
                line: true,
            },
            sqlOptions: {
                tabSize: 4,
                mode: "text/x-sql",
                lineNumbers: true,
                line: true,
            },
        };
    },
    methods: {
        async getInit() {
            this.reportsCreated = this.getReports();
            this.spinner = false;
        },
        async getReports() {
            this.spinner = true;
            await this.$httpReports
                .get("/reporteditor", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.reports = response.data;
                    for (let i = 0; i < this.reports.length; i++) {
                        this.reportOptions.push({
                            value: i,
                            text: this.reports[i].report_name,
                        });
                    }
                });
        },
        getReportValue(value) {
            if (value != null) {
                this.selectedReport = this.reports[value];
                this.sqlEditor = this.reports[value].report_sql;
                this.fieldsEditor = this.reports[value].report_fields;
            }
        },
        updateVerify() {
            let isGood = true;
            if (this.selectedReport == null) {
                this.$notify.error({
                    message: "Please select a report.",
                    title: "Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (this.sqlEditor == "") {
                this.$notify.error({
                    message: "SQL is empty!",
                    title: "Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (this.fieldsEditor == "") {
                this.$notify.error({
                    message: "Fields is empty!",
                    title: "Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (isGood == true) {
                this.modal = true;
            }
        },
        updateReport() {
            this.$httpReports
                .put("/reporteditor/" + this.selectedReport.report_id, this.getParams(), { headers: this.headers })
                .then(async (response) => {
                    this.updatedReport = response.data;
                    this.updatedReportMessage = JSON.parse(this.updatedReport);
                    if (this.updatedReport.includes("message")) {
                        this.$notify.success({
                            message: this.updatedReportMessage.message,
                            title: "Report Updated",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: this.updatedReportMessage.error,
                            title: "Report Not Updated",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
            this.modal = false;
        },
        getParams() {
            return {
                report_sql: this.sqlEditor,
                report_fields: this.fieldsEditor,
            };
        },
        addShipperFields() {
            this.shipperFields =
                "pld_shipper_name, pld_shipper_company, pld_shipper_addr1, pld_shipper_addr2, pld_shipper_city, " +
                "pld_shipper_state, pld_shipper_zipcode, pld_shipper_country";
            if (this.fieldsManagerText != "") {
                this.shipperFields = ", " + this.shipperFields;
            }
            this.fieldsManagerText = this.fieldsManagerText + this.shipperFields;
        },
        addRecipientFields() {
            this.recipientFields =
                "pld_recipient_name, pld_recipient_company, pld_recipient_addr1, pld_recipient_addr2, " +
                "pld_recipient_city, pld_recipient_state, pld_recipient_zipcode, pld_recipient_country";
            if (this.fieldsManagerText != "") {
                this.recipientFields = ", " + this.recipientFields;
            }
            this.fieldsManagerText = this.fieldsManagerText + this.recipientFields;
        },
        getFieldIds() {
            let items = this.getFieldArray();
            if (items.length == 0) {
                this.$notify.error({
                    message: "There should be at least one entry",
                    title: "Get Field IDs",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            this.submitFieldRequest("getFieldIds", items);
        },
        getFieldDisplayNames() {
            let items = this.getFieldArray();
            if (items.length == 0) {
                this.$notify.error({
                    message: "There should be at least one entry",
                    title: "Get Field Display Names",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }

            this.submitFieldRequest("getFieldDisplayNames", items);
        },
        getFieldNames() {
            let items = this.getFieldArray();
            if (items.length == 0) {
                this.$notify.error({
                    message: "There should be at least one entry",
                    title: "Get Field Names",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            let calcItems = [];
            for (let i = 0; i < items.length; i++) {
                let p = items[i].split("_");
                if (p.length < 2 || p.length > 3) {
                    this.$notify.error({
                        message:
                            "The format of some entries are not correct. it should be f_X_Y or X_Y where X and Y must be integer.",
                        title: "Get Field Names",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    return;
                }
                let t = parseInt(p[p.length - 2]);
                let f = parseInt(p[p.length - 1]);

                if (!Number.isInteger(t) || !Number.isInteger(f)) {
                    this.$notify.error({
                        message:
                            "The format of some entries are not correct. it should be f_X_Y or X_Y where X and Y must be integer.",
                        title: "Get Field Names",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    return;
                }

                calcItems.push(t + "_" + f);
            }

            this.submitFieldRequest("getFieldNames", calcItems);
        },
        getFieldArray() {
            let text = this.fieldsManagerText;
            if (!text || text == "") {
                return [];
            }
            let items = [];
            let lines = text.split("\n");
            for (let i = 0; i < lines.length; i++) {
                let words = lines[i].split(",");
                for (let j = 0; j < words.length; j++) {
                    let w = words[j].trim().toLowerCase();
                    if (w && w.length > 1) items.push(w);
                }
            }
            return items;
        },
        submitFieldRequest(requestType, items) {
            this.$httpReports
                .post("/adminschema", { action: requestType, items: items }, { headers: this.headers })
                .then(async (response) => {
                    this.submittedResults = response.data;
                    if (this.submittedResults) {
                        this.fieldResults = this.submittedResults;
                    } else {
                        this.$notify.error({
                            message: "Error.",
                            title: "Field Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
