<template>
    <mdb-container fluid>
        <div data-test="showAudit" class="w-full audit" v-if="premiumServices && auditService && !isLoading">
            <h1 data-test="pageHeader" class="page-header">
                Audit <mdb-icon icon="thumbs-up" color="grey" size="xl3" class="float-right" />
            </h1>
            <hr />
            <mdb-btn
                data-test="mdb-btn_deactivateBtn"
                v-if="auditService && auditService.cps_is_deactivable === '1'"
                @click="deactivateAddonObject.isModalVisible = true"
                color="danger"
                class="ml-auto"
            >
                <mdb-icon icon="trash" />
                Deactivate Add-on
            </mdb-btn>
            <div v-if="deactivateAddonObject">
                <mdb-modal
                    data-test="deactivateModal"
                    :show="deactivateAddonObject.isModalVisible"
                    @close="deactivateAddonObject.isModalVisible = false"
                    id="delGroup"
                >
                    <mdb-modal-header data-test="deactivateModalHeader">
                        {{ deactivateAddonObject.title }}
                    </mdb-modal-header>
                    <mdb-modal-body>
                        <div class="row">
                            <div class="col-lg-12">
                                <p data-test="deactivateModalBody">{{ deactivateAddonObject.body }}</p>
                                <p>
                                    <strong>Note:</strong> Add-ons are billed monthly so you will be charged for the
                                    full month.
                                </p>
                            </div>
                        </div>
                    </mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn @click="deactivateAddonObject.isModalVisible = false" class="btn btn-info" size="sm"
                            >Cancel</mdb-btn
                        >
                        <mdb-btn
                            data-test="deactivateModal_deactiveServiceBtn"
                            @click.native="deactivateService"
                            size="sm"
                            color="danger"
                            v-if="!loadingDeactivate"
                        >
                            Deactivate
                        </mdb-btn>

                        <mdb-btn v-else disabled color="danger" class="ml-auto">
                            <span
                                data-test="deactivateModalSpinner"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Deactivating...
                        </mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>
            </div>
            <div class="col-md-12 col-lg-9 float-left">
                <div class="panel panel-default p-2">
                    <mdb-tabs
                        data-test="tabs"
                        :active="0"
                        default
                        :links="[
                            { text: 'Verified Credits', slot: 'credits-slot' },
                            { slot: 'weekly-summary', text: 'Weekly Summary' },
                        ]"
                        :transition-duration="0.5"
                        transition-style="linear"
                    >
                        <template data-test="mdb-tabs_credit-slots" :slot="'credits-slot'">
                            <template slot="text"> <mdb-icon icon="check-square" /> Verified Credits </template>
                            <mdb-row class="w-100 col-12 p-0 m-0">
                                <mdb-col col="4">
                                    <AccountDropdown
                                        data-test="credit-slots_AccountDropdown"
                                        v-if="!resetting"
                                        @update-account-selected="updateSelectedAccount"
                                        @update-selected-active="updateSelectedActive"
                                        :userId="userInfo.usrId"
                                        :cmpId="userInfo.cmpID"
                                        :headers="headers"
                                    />
                                </mdb-col>
                                <mdb-col col="4">
                                    <DateSelector
                                        data-test="credit-slots_DateSelector"
                                        v-if="!resetting"
                                        id="dateDropdown"
                                        :startDate="startDate"
                                        :endDate="endDate"
                                        @update-end-date="updateEndDate"
                                        @update-start-date="updateStartDate"
                                        class="my-0 w-100 border rounded"
                                    />
                                    <mdb-spinner data-test="credit-slots_spinner" v-else></mdb-spinner>
                                </mdb-col>
                                <mdb-col col="4">
                                    <mdb-dropdown
                                        data-test="credit-slots_dropdown"
                                        v-if="!resetting"
                                        id="dropdown-form3"
                                        ref="dropdown"
                                        variant="white"
                                        class="border rounded w-100"
                                        :class="selectedTypeActive ? 'bg-info text-white' : ''"
                                    >
                                        <mdb-dropdown-toggle
                                            data-test="credit-slots_dropdown-toggle"
                                            class="w-100"
                                            slot="toggle"
                                            >{{
                                                selectedTypeActive
                                                    ? selectedRefundTypes.length + " Types Selected"
                                                    : "Select Refund Type"
                                            }}</mdb-dropdown-toggle
                                        >
                                        <mdb-dropdown-menu data-test="credit-slots_dropdown-menu" v-if="!isBusy">
                                            <form data-test="credit-slots_form" v-if="optionsRefundTypes">
                                                <div
                                                    data-test="credit-slots_optionRefundTypes"
                                                    v-for="(option, index) of optionsRefundTypes"
                                                    v-bind:key="index"
                                                    class="m-0 p-0"
                                                >
                                                    <div
                                                        data-test="credit-slots_changeSelection"
                                                        class="optionHover cursor-pointer w-full p-2"
                                                        @click="changeSelection(option)"
                                                    >
                                                        {{ option.text }}
                                                        <mdb-icon
                                                            class="float-right"
                                                            icon="check-square"
                                                            v-if="option.selected"
                                                        />
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                            </form>
                                        </mdb-dropdown-menu>
                                    </mdb-dropdown>
                                </mdb-col>
                            </mdb-row>

                            <div class="row">
                                <div class="col-12 w-full">
                                    <mdb-btn
                                        data-test="credit-slots_resetBtn"
                                        class="btn btn-warning text-white ml-2 float-right"
                                        @click.native="reset"
                                        ><mdb-icon class="mr-2" icon="times" />Clear</mdb-btn
                                    >
                                    <mdb-btn
                                        data-test="credit-slots_getReportDataBtn"
                                        class="btn btn-info float-right"
                                        @click.native="getReportData"
                                        ><mdb-icon class="mr-2" icon="search" />Search</mdb-btn
                                    >
                                </div>
                            </div>
                            <hr />
                            <div class="row" style="min-height: 325px">
                                <mdb-card data-test="credit-slots_mdb-card" class="col-12 w-full d-flex">
                                    <div class="btn-group action-buttons" role="group">
                                        <mdb-btn
                                            data-test="credit-slots_showPackageGenieBtn"
                                            @click="showPackageGenie = true"
                                            class="btn btn-info"
                                            id="analyze"
                                            >Analyze Package&nbsp;
                                            <mdb-icon icon="magic" />
                                        </mdb-btn>
                                    </div>
                                    <mdb-input
                                        data-test="credit-slots_searchPackageTextInput"
                                        type="text"
                                        placeholder="Search"
                                        v-model="searchPackageText"
                                        @keydown.enter.native="getReportData"
                                        id="search"
                                    >
                                        <span
                                            data-test="credit-slots_deleteSearchPackageText"
                                            slot="prepend"
                                            v-if="searchPackageText"
                                            @click="searchPackageText = ''"
                                        >
                                            <mdb-btn variant="default" class="bg-white border-top border-bottom"
                                                ><mdb-icon icon="times"
                                            /></mdb-btn>
                                        </span>
                                        <mdb-btn
                                            data-test="credit-slots_mdb-card_getReportDataBtn"
                                            slot="append"
                                            variant="default"
                                            class="border bg-white"
                                            @click.native="getReportData"
                                            ><mdb-icon icon="search"
                                        /></mdb-btn>
                                    </mdb-input>
                                    <vue-good-table
                                        data-test="credit-slots_vue-good-table"
                                        v-if="reportData.rows"
                                        striped
                                        hover
                                        :columns="reportData.columns"
                                        :rows="reportData.rows"
                                        mode="remote"
                                        :isLoading.sync="isBusy"
                                        :totalRows="totalRecords"
                                        @on-row-click="onRowClick"
                                        @on-sort-change="onSortChange"
                                        @on-page-change="onPageChange"
                                        @on-per-page-change="onPerPageChange"
                                        :pagination-options="{
                                            enabled: true,
                                            mode: 'records',
                                            perPage: limit,
                                            position: 'bottom',
                                            perPageDropdown: limitOptions,
                                            dropdownAllowAll: false,
                                            setCurrentPage: currentPage,
                                            nextLabel: '>',
                                            prevLabel: '<',
                                            rowsPerPageLabel: 'Rows per page',
                                            ofLabel: 'of',
                                            pageLabel: 'page', // for 'pages' mode
                                            allLabel: 'All',
                                            total: totalRecords,
                                        }"
                                    >
                                        <template slot="table-row" slot-scope="props">
                                            <span v-if="props.column.field === 'f_7_3'">
                                                {{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                                            </span>
                                            <span v-else-if="props.column.field == 'before'">
                                                <mdb-input
                                                    type="radio"
                                                    :id="'radio-group-selected-row-' + props.row.originalIndex"
                                                    name="radio-group-selected-row"
                                                    label=" "
                                                    :radioValue="props.row.originalIndex.toString()"
                                                    v-model="isSelected"
                                                    style="width: 40px"
                                                /> </span
                                            ><span v-else>
                                                {{ props.formattedRow[props.column.field] }}
                                            </span>
                                        </template>
                                    </vue-good-table>
                                </mdb-card>
                            </div>
                        </template>

                        <template data-test="weekly-summary" :slot="'weekly-summary'">
                            <p data-test="weekly-summary_weeklyBillingSummary" class="p-3" v-if="weeklyBillingSummary">
                                <vue-good-table
                                    data-test="weekly-summary_vue-good-table"
                                    v-if="weeklyBillingSummary.rows"
                                    striped
                                    hover
                                    :columns="weeklyBillingSummary.columns"
                                    :rows="weeklyBillingSummary.rows"
                                >
                                    <template v-slot:cell(report_name)="row">
                                        <span class="cursor-pointer" @click="download(row.item.rg_filekey)"
                                            ><u>{{ row.item.report_name }}</u></span
                                        >
                                    </template>
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field === 'report_name'">
                                            <span class="cursor-pointer" @click="download(props.row.rg_filekey)"
                                                ><u>{{ props.row.report_name }}</u></span
                                            >
                                        </span>
                                    </template>
                                </vue-good-table>
                            </p>
                        </template>
                    </mdb-tabs>
                </div>
            </div>
            <div data-test="sidebar" class="col-md-12 col-lg-3 float-left" id="sidebar">
                <mdb-row class="mb-4">
                    <mdb-col>
                        <mdb-card data-test="sidebar_card">
                            <mdb-card-header
                                data-test="sidebar_card-header"
                                color="grey lighten-4"
                                class="small grey-text"
                                tag="h3"
                            >
                                <span class="grey-text">Summary</span>
                                <div class="float-right text-muted"><small>Last 90 Days</small></div>
                            </mdb-card-header>
                            <mdb-card-body data-test="sidebar_card-body" class="p-1 mt-2">
                                <div class="row pl-1">
                                    <div class="col-12 row">
                                        <div class="col-6 text-left">Recovered</div>
                                        <div class="col-6">
                                            <h4 class="text-lg-right py-1">
                                                {{ formatPrice(recovered, userInfo) }}
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="col-12 row">
                                        <div class="col-6 text-left">Approvals</div>
                                        <div class="col-6">
                                            <h4 class="text-lg-right py-1">{{ approvals }}</h4>
                                        </div>
                                    </div>
                                    <div class="col-12 row">
                                        <div class="col-6 text-left">Average</div>
                                        <div class="col-6">
                                            <h4 class="text-lg-right py-1">
                                                {{ formatPrice(average, userInfo) }}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col>
                        <mdb-card data-test="sidecar_highchartsCard">
                            <mdb-card-header
                                data-test="sidecar_highchartsCardHeader"
                                color="grey lighten-4"
                                class="small grey-text"
                                tag="h3"
                            >
                                <span class="grey-text">Refund Types</span>
                                <div class="float-right text-muted"><small>Last 90 Days</small></div>
                            </mdb-card-header>
                            <mdb-card-body class="p-0 mt-2">
                                <div id="index_carrier_spend">
                                    <highcharts data-test="sidebar_highcharts" :options="refundTypes"></highcharts>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
            </div>
            <mdb-modal
                data-test="mdb-modal_packageGenie"
                id="packageGenie"
                :show="showPackageGenie"
                @close="showPackageGenie = false"
                title="Package Genie"
                size="fluid"
            >
                <mdb-modal-header>
                    <mdb-modal-title class="w-full">
                        <mdb-icon icon="magic" class="mr-3" />
                        <span>Package Genie</span>
                    </mdb-modal-title>
                </mdb-modal-header>
                <PackageGenie
                    data-test="PackageGenie"
                    :invoiceNum="selected.f_2_1"
                    :userInfo="userInfo"
                    :headers="headers"
                    :package="selected"
                />
            </mdb-modal>
        </div>
        <div data-test="noAudit" v-else>
            <mdb-card data-test="noAudit_card" v-if="!isLoading">
                <mdb-card-header>
                    <h3>
                        Add-on Description
                        <mdb-btn
                            data-test="noAudit_activateService"
                            @click.native="activateService()"
                            icon="plus"
                            class="float-right"
                            v-if="!loadingActivate"
                        >
                            Activate Audit
                        </mdb-btn>
                        <mdb-btn icon="plus" class="float-right" v-else disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Activating Audit...
                        </mdb-btn>
                    </h3>
                </mdb-card-header>
                <mdb-card-body>
                    <p>Get Refunds, Automatically.</p>

                    <p><b>Up to 5% of your packages are eligble for refunds.</b></p>

                    <p>
                        Carriers make the process difficult. We make it easy. We audit every charge on every invoice to
                        make sure you get every credit, every time.
                    </p>
                </mdb-card-body>
            </mdb-card>
        </div>
    </mdb-container>
</template>
<script>
import moment from "moment";
import maps from "../../lib/charts";
import PackageGenie from "@/components/PackageGenie";
import DateSelector from "@/components/DateSelector";
import AccountDropdown from "@/components/AccountDropdown";
import {
    mdbBtn,
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbIcon,
    mdbTabs,
    mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbAccordion,
    mdbAccordionPane,
    mdbSpinner,
    mdbInput,
    mdbSelect,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbCardHeader,
    mdbDropdownMenu,
    mdbDropdown,
    mdbDropdownToggle,
} from "mdbvue";

export default {
    name: "Audit",
    components: {
        PackageGenie,
        DateSelector,
        AccountDropdown,
        mdbBtn,
        mdbContainer,
        mdbCol,
        mdbRow,
        mdbIcon,
        mdbTabs,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbAccordion,
        mdbAccordionPane,
        mdbSpinner,
        mdbInput,
        mdbSelect,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbCardHeader,
        mdbDropdownMenu,
        mdbDropdown,
        mdbDropdownToggle,
    },
    data() {
        return {
            isSelected: "0",
            average: 0,
            approvals: 0,
            recovered: 0,
            selectedRefundTypes: [],
            searchPackageText: "",
            optionsRefundTypes: [
                { value: "2", text: "Service Failure", selected: false },
                { value: "3", text: "Invalid Address Correction", selected: false },
                { value: "4", text: "Invalid Residential Surcharge", selected: false },
                { value: "5", text: "Manifested not Shipped (MNS)", selected: false },
                { value: "6", text: "Invalid Saturday Delivery", selected: false },
                { value: "7", text: "Invalid Saturday Pickup", selected: false },
                { value: "8", text: "No Signature Obtained", selected: false },
                { value: "9", text: "Invalid Fuel Surcharge", selected: false },
                { value: "15", text: "Invalid DAS", selected: false },
                { value: "17", text: "Invalid Extended DAS", selected: false },
                { value: "20", text: "Duplicate Shipments", selected: false },
            ],
            visible: false,
            refundTypesOptions: {
                chart_name: "refund_type_cost",
                chart_type: "pie",
                type: "pie",
                title: {
                    text: "",
                },
                expanded: false,
            },
            refundTypes: {},
            weeklyBillingSummary: {},
            reportData: {},
            limit: 10,
            currentPage: 1,
            sortColumn: "f_2_4",
            sortkey: "f_2_4",
            sortDir: "desc",
            searchText: "",
            selectMode: "single",
            totalRecords: 0,
            totalPages: 0,
            limitOptions: [10, 25, 50, 100],
            selected: {},
            dateRangeSelected: "",
            context: null,
            isBusy: false,
            selectedAccountActive: false,
            selectedTypeActive: false,
            userAccounts: null,
            startDate: new moment(new moment().subtract(90, "days").format("YYYY-MM-DD"), "YYYY-MM-DD"),
            endDate: new moment(new moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
            sDate: this.$route.query.startDate,
            eDate: this.$route.query.endDate,
            showPackageGenie: false,
            accountValues: null,
            deactivateAddonObject: {
                isModalVisible: false,
                title: "Deactivate Addon",
                icon: "magic",
                body: "Are you sure you would like to deactivate this addon?",
            },
            loadingActivate: false,
            loadingDeactivate: false,
            resetting: false,
            isLoading: true,
        };
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        options: Object,
        headers: Object,
        downloadUniversal: Function,
        services: Array,
        activateAddon: Function,
        deactivateAddon: Function,
    },
    methods: {
        onSortChange(params) {
            this.sortkey = params[0].field;
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
        },
        onPageChange(params) {
            this.currentPage = params.currentPage;
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
        },
        onContext(ctx) {
            this.context = ctx;
        },
        moment: (date) => {
            return moment(date);
        },
        formatDate(date) {
            return moment(date).format("MMM DD, YYYY");
        },
        changeSelection(value) {
            value.selected = !value.selected;
            if (value.selected === true) {
                this.selectedRefundTypes.push(value.value);
            } else {
                const index = this.selectedRefundTypes.indexOf(value.value);
                this.selectedRefundTypes.splice(index);
            }
            this.selectedTypeActive = false;
            for (const type of this.optionsRefundTypes) {
                if (type.selected) {
                    this.selectedTypeActive = true;
                    return;
                }
            }
        },
        async download(key) {
            this.downloadUniversal(key, "pdf");
        },
        updateRange(value) {
            this.startDate = value[0];
            this.endDate = value[1];
        },
        onRowClick(params) {
            this.selected = params.row;
            this.isSelected = params.row.originalIndex;
            this.selected.pld_trackingno = params.row.f_4_4;
        },
        onRowSelected(items) {
            this.selected = items[0];
            this.selected.pld_trackingno = items[0].f_4_4;
        },
        async getSummaryData() {
            try {
                await this.$http
                    .get(`index?request=totalApproved&cmpId=${this.userInfo.cmpID}`, { headers: this.headers })
                    .then(async (response) => {
                        this.recovered = response.data;
                    });
            } catch (e) {
                this.$notify.error({ message: e });
            }
            try {
                await this.$http
                    .get(`index?request=numApproved&cmpId=${this.userInfo.cmpID}`, { headers: this.headers })
                    .then(async (response) => {
                        this.approvals = response.data;
                    });
            } catch (e) {
                this.$notify.error({ message: e });
            }
            if (this.recovered != 0 && this.approvals != 0) {
                this.average = this.recovered / this.approvals;
            } else {
                this.average = 0;
            }
        },
        async getChartData(data) {
            const param = { cmpId: this.userInfo.cmpID, chartName: data.chart_name, chartType: data.chart_type };
            let url = "/chartdata";
            try {
                return await this.$http.post(url, param, { headers: this.headers });
            } catch (e) {
                this.$notify.error({ message: e });
            }
        },
        async getWeeklyBilling() {
            const columns = [
                { field: "report_name", label: "Name" },
                {
                    field: "rg_start_date",
                    label: "Start Date",
                    formatFn: (d) => {
                        return moment(d).format("MMM DD, YYYY");
                    },
                },
                {
                    field: "rg_end_date",
                    label: "End Date",
                    formatFn: (d) => {
                        return moment(d).format("MMM DD, YYYY");
                    },
                },
                {
                    field: "rg_create_date",
                    label: "Created",
                    formatFn: (d) => {
                        return moment(d).format("MMM DD, YYYY");
                    },
                },
            ];
            try {
                await this.$httpReportsImpersonate
                    .get(`/reportgenerated/weeklybillingsummary/?cmpId=${this.userInfo.cmpID}`, {
                        headers: this.headers,
                    })
                    .then(async (response) => {
                        this.weeklyBillingSummary = {
                            columns,
                            rows: response.data,
                        };
                    });
            } catch (e) {
                this.$notify.error({ message: e });
            }
        },
        async reset() {
            this.resetting = true;
            this.startDate = new moment(new moment().subtract(90, "days").format("YYYY-MM-DD"), "YYYY-MM-DD");
            this.endDate = new moment(new moment().format("YYYY-MM-DD"), "YYYY-MM-DD");
            for (const account of this.userAccounts) {
                account.selected = false;
                for (const child of account.children) {
                    child.selected = false;
                }
            }
            for (const type of this.optionsRefundTypes) {
                type.selected = false;
            }
            this.selectedAccountActive = false;
            this.selectedTypeActive = false;
            this.getSummaryData();
            this.getReportData();
            this.getWeeklyBilling();

            let chart = (await this.getChartData(this.refundTypesOptions)).data;
            this.refundTypes = maps.drawChart({ ...chart, ...this.refundTypesOptions });
            this.resetting = false;
        },
        buildQueryParams() {
            let accounts;
            let conditions;
            if (this.selectedAccountActive) {
                accounts = this.accountsValues;
            }
            if (this.selectedTypeActive) {
                conditions = [
                    {
                        id: "f_7_7",
                        op: "()",
                        values: this.selectedRefundTypes,
                    },
                ];
            }
            return {
                id: 40,
                limit: this.limit,
                size: this.limit,
                page: this.currentPage,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                start: moment(this.startDate).format("YYYY-MM-DD"),
                end: moment(this.endDate).format("YYYY-MM-DD"),
                cmpId: this.userInfo.cmpID,
                conditions,
                accounts: accounts,
                f_4_4: this.searchPackageText ? this.searchPackageText : null,
            };
        },
        async getReportData() {
            // TODO serialize conditions and accounts (not filtering properly)
            this.isBusy = true;
            try {
                await this.$httpReportsImpersonate
                    .post(`/reportdata/`, this.buildQueryParams(), { headers: this.headers })
                    .then(async (response) => {
                        const columns = [
                            { field: "before", label: "" },
                            { field: "f_2_1", label: "Invoice Number", sortable: true },
                            {
                                field: "f_2_4",
                                label: "Invoice Date",
                                sortable: true,
                                formatFn: (d) => {
                                    return moment(d).format("MMM DD, YYYY");
                                },
                            },
                            { field: "f_4_4", label: "Tracking Number", sortable: true },
                            {
                                field: "f_4_28",
                                label: "Ship Date",
                                sortable: true,
                                formatFn: (d) => {
                                    return moment(d).format("MMM DD, YYYY");
                                },
                            },
                            { field: "f_12_1", label: "Refund Type", sortable: true },
                            { field: "f_7_3", label: "Refund Amount", sortable: true },
                        ];
                        this.reportData = {
                            columns,
                            rows: response.data.items,
                        };
                        this.totalRecords = response.data.total_records;
                        this.isBusy = false;
                    });
            } catch (e) {
                this.$notify.error({ message: e });
            }
        },
        updateStartDate(val) {
            this.startDate = val;
        },
        updateEndDate(val) {
            this.endDate = val;
        },
        updateSelectedAccount(userAccounts, accountValues) {
            this.userAccounts = userAccounts;
            this.accountsValues = accountValues;
        },
        updateSelectedActive(val) {
            this.selectedAccountActive = val;
        },
        async activateService() {
            this.loadingActivate = true;
            await this.activateAddon("audit");
            this.getSummaryData();
            this.getReportData();
            this.getWeeklyBilling();

            let chart = (await this.getChartData(this.refundTypesOptions)).data;
            this.refundTypes = maps.drawChart({ ...chart, ...this.refundTypesOptions });
            this.loadingActivate = false;
        },
        async deactivateService() {
            this.loadingDeactivate = true;
            await this.deactivateAddon("audit");
            this.loadingDeactivate = false;
            this.deactivateAddonObject.isModalVisible = false;
        },
    },
    computed: {
        premiumServices() {
            return this.services
                ? this.services.map((s) => {
                      if (s.cps_is_active === "1") return s.cps_name;
                  })
                : null;
        },
        async auditService() {
            const audit = this.services.find((c) => c.cps_name === "audit");
            if (audit) {
                this.getSummaryData();
                this.getReportData();
                this.getWeeklyBilling();

                let chart = (await this.getChartData(this.refundTypesOptions)).data;
                this.refundTypes = maps.drawChart({ ...chart, ...this.refundTypesOptions });
            }
            this.isLoading = false;
            return audit;
        },
    },
    watch: {
        currentPage() {
            this.getReportData();
        },
        limit() {
            this.getReportData();
        },
    },
};
</script>
<style>
#dateDropdown .dropdown {
    width: 100% !important;
}
.audit .dropdown-menu {
    max-height: 400px;
    overflow: scroll;
    width: 100%;
    transform: translate3d(0px, 0px, 0px) !important;
}
#dateDropdown {
    margin-left: 0 !important;
}
.cursor-pointer {
    cursor: pointer;
}
.b-dropdown-form {
    padding: 0 !important;
}
.optionHover:hover {
    background-color: gray !important;
    color: white !important;
}
#dropdown-form.width-600 ul {
    min-width: 600px;
    padding: 0px !important;
}
.text-xs {
    font-size: 11px !important;
}
.form-group {
    padding: 0px !important;
}
</style>
