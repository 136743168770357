<template>
    <div class="m-4">
        <h3 class="my-4 mx-2">Admin Notification</h3>
        <div v-if="notifications">
            <vue-good-table
                styleClass="vgt-table condensed"
                :rows="notifications"
                :columns="columns"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-search="searchNotifications"
                :search-options="{
                    enabled: true,
                    trigger: 'enter',
                }"
                :totalRows="totalRows"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: this.limit,
                    position: 'top',
                    perPageDropdown: [10, 25, 50],
                    dropdownAllowAll: false,
                    setCurrentPage: this.page,
                }"
                :isLoading.sync="isLoading"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'an_datetime'">
                        {{ formatDate(props.formattedRow[props.column.field]) }}
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
        <div v-else class="text-center align-content-center"><mdb-spinner></mdb-spinner>Loading Notifications...</div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "AdminNotifications",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            notifications: null,
            isLoading: false,
            columns: [
                {
                    label: "Date Time",
                    field: "an_datetime",
                },
                {
                    label: "Component",
                    field: "an_component",
                },
                {
                    label: "Priority",
                    field: "an_priority",
                },
                {
                    label: "Notification",
                    field: "an_message",
                },
            ],
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "an_message",
            sortDir: "desc",
            sortkey: "an_message",
            searchTerm: null,
            totalRows: null,
        };
    },
    computed: {
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
    },
    methods: {
        formatDate(date) {
            return moment(date).format("MMM DD, YYYY HH:mm:ss");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.getNotifications();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.getNotifications();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.getNotifications();
        },
        async searchNotifications(params) {
            this.isLoading = true;
            const found = await this.$httpAdmin.get(
                `adminnotification?${this.params}&an_message=${params.searchTerm}`,
                {
                    headers: this.headers,
                }
            );
            this.isLoading = false;
            if (found) {
                this.notifications = found.data[0].Items;
                this.totalRows = found.data[0].TotalRecords;
            }
        },
        async getNotifications() {
            this.isLoading = true;
            try {
                const errors = await this.$httpAdmin.get(`adminnotification?${this.params}`, { headers: this.headers });
                this.notifications = errors.data[0].Items;
                this.totalRows = errors.data[0].TotalRecords;
            } catch (e) {
                this.$notify.error({ message: `Error retrieving notifications ${e}` });
            }
            this.isLoading = false;
            this.setNotificationsAsSeen();
        },
        async setNotificationsAsSeen() {
            await this.$httpAdmin.put(`adminnotification/1`, {}, { headers: this.headers });
        },
    },
    mounted() {
        this.getNotifications();
    },
};
</script>
