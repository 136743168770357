import { render, staticRenderFns } from "./MultiSelectItem.vue?vue&type=template&id=22a30272&scoped=true"
import script from "./MultiSelectItem.vue?vue&type=script&lang=js"
export * from "./MultiSelectItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a30272",
  null
  
)

export default component.exports