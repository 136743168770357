var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-4 carrierAccounts",attrs:{"data-test":"page"}},[_vm._m(0),_c('div',{staticClass:"w-100 m-2"},[_c('mdb-card',{attrs:{"data-test":"triageInstructionsCard"}},[_c('mdb-card-header',{attrs:{"data-test":"cardHeader","color":"warning-color","tag":"h3"}},[_vm._v("Triage Instructions")]),_c('mdb-card-body',[_c('p',{attrs:{"data-test":"cardBodyp1"}},[_c('strong',[_vm._v("What it is: ")]),_vm._v(" Packages that have errors during the audit "),_c('br'),_c('strong',[_vm._v(" What to do:")]),_vm._v(" Monitor for high percentage audit errors "),_c('br'),_c('strong',[_vm._v("Frequency: ")]),_vm._v("Per Audit"),_c('br'),_c('br')]),_c('p',{attrs:{"data-test":"cardBodyp2"}},[_c('strong',[_vm._v("Typical Errors: ")])]),_c('p',{attrs:{"data-test":"cardBodyp3"}},[_c('strong',[_vm._v("No TNT: ")]),_vm._v(" We cannot get Time in Transit information for these packages. ")]),_c('p',{attrs:{"data-test":"cardBodyp4"}},[_c('strong',[_vm._v("Tracking number not found: ")]),_vm._v(" We may be sending mal-formed tracking numbers to the carrier tracking API ")])])],1)],1),_c('div',{staticClass:"w-100"},[_c('vue-good-table',{attrs:{"data-test":"vue-good-table","rows":_vm.records,"columns":_vm.columns,"mode":"remote","totalRows":_vm.totalRows,"isLoading":_vm.loading,"search-options":{
                enabled: true,
                trigger: 'enter',
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: this.limit,
                position: 'top',
                perPageDropdown: [10, 25, 50],
                dropdownAllowAll: false,
                setCurrentPage: this.page,
            }},on:{"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.getErrors},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'date')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.date))+" ")]):(props.column.field === 'cmp_type')?_c('div',[(props.row.cmp_type === 'Live')?_c('mdb-icon',{staticClass:"mr-2",attrs:{"color":"success","icon":"circle"}}):_vm._e(),(props.row.cmp_type === 'Mock')?_c('mdb-icon',{staticClass:"mr-2",attrs:{"color":"warning","icon":"clock"}}):_vm._e(),(props.row.cmp_type === 'Load')?_c('mdb-icon',{staticClass:"mr-2",attrs:{"color":"warning","icon":"circle"}}):_vm._e(),(props.row.cmp_type === 'Trial')?_c('mdb-icon',{staticClass:"mr-2",attrs:{"color":"success","icon":"clock"}}):_vm._e(),(props.row.cmp_type === 'Inactive')?_c('mdb-icon',{staticClass:"mr-2",attrs:{"color":"danger","icon":"circle"}}):_vm._e(),(props.row.cmp_type === 'Hold')?_c('mdb-icon',{staticClass:"mr-2",attrs:{"color":"warning","icon":"ban"}}):_vm._e(),(props.row.cmp_type === 'Suspect')?_c('mdb-icon',{staticClass:"mr-2",attrs:{"color":"warning","icon":"question-circle"}}):_vm._e(),_vm._v(" "+_vm._s(props.row.cmp_type)+" ")],1):(props.column.field === 'cmp_is_active')?_c('div',[(props.row.cmp_is_active)?_c('span',[_c('mdb-icon',{staticClass:"mr-2",attrs:{"color":"success","icon":"circle"}}),_vm._v(" Active ")],1):_c('span',[_c('mdb-icon',{staticClass:"mr-2",attrs:{"icon":"circle","color":"danger"}}),_vm._v(" Inactive")],1)]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"my-4 mx-2",attrs:{"data-test":"pageTitle"}},[_vm._v(" Audit Errors "),_c('em',{staticClass:"small",attrs:{"data-test":"pageTitleSmall"}},[_vm._v("Analyze Package Errors")])])
}]

export { render, staticRenderFns }