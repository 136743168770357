<template>
    <div data-test="page" class="overflow-auto h-90">
        <div>
            <h1 data-test="pageHeader" class="page-header border-bottom mx-2">
                Manage Users
                <mdb-icon icon="users" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row class="mt-4">
                <mdb-col col="2">
                    <mdb-btn data-test="addNewUserBtn" size="sm" color="primary" @click.native="addNewUser('new user')"
                        >Add New User<mdb-icon icon="plus" class="ml-2"></mdb-icon
                    ></mdb-btn>
                </mdb-col>
                <mdb-col col="2">
                    <mdb-btn
                        data-test="exportCSVBtn"
                        color="primary"
                        size="sm"
                        title="export as csv"
                        @click.native="exportCSV"
                        ><mdb-icon icon="download"></mdb-icon
                    ></mdb-btn>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mt-2">
                <mdb-col col="12">
                    <vue-good-table
                        data-test="vue-good-table"
                        :columns="tableColumns"
                        :rows="tableRows"
                        :search-options="{ enabled: true, trigger: 'enter' }"
                        :isLoading.sync="loading"
                        styleClass="vgt-table striped bordered"
                        @on-row-click="onRowClick"
                        mode="remote"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-per-page-change="onPerPageChange"
                        @on-search="searchTable"
                        :totalRows="totalRecords"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: this.limit,
                            position: 'top',
                            perPageDropdown: [10, 25, 50, 100],
                            dropdownAllowAll: false,
                            setCurrentPage: this.page,
                        }"
                    >
                        <template slot="loadingContent"> <mdb-spinner></mdb-spinner> Loading data... </template>
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'fullName'">
                                <span
                                    ><mdb-icon icon="user" color="primary" class="mr-2"></mdb-icon
                                    >{{ props.row.fullName }}</span
                                >
                            </span>
                            <span v-else-if="props.column.field == 'usr_is_active'">
                                <span v-if="props.row.usr_is_active == 1"
                                    ><mdb-icon icon="circle" color="primary" class="mr-2 text-success"></mdb-icon
                                    ><strong>Active</strong></span
                                >
                                <span v-else
                                    ><mdb-icon icon="circle" class="mr-2 text-danger"></mdb-icon
                                    ><strong>Inactive</strong></span
                                >
                            </span>
                            <span v-else-if="props.column.field == 'usr_is_deleted'">
                                <span v-if="props.row.usr_is_deleted == 1"
                                    ><mdb-icon icon="check-circle" class="mr-2 text-danger"></mdb-icon
                                    ><strong>Deleted</strong></span
                                >
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </mdb-col>
            </mdb-row>
            <mdb-modal data-test="rowModal" size="lg" :show="rowModal" @close="rowModal = false" v-if="selectedRow">
                <mdb-modal-header>
                    <mdb-modal-title data-test="rowModalHeader">Choose an Option</mdb-modal-title>
                </mdb-modal-header>
                <mdb-modal-body>
                    <mdb-btn data-test="impersonateUserBtn" color="primary" @click.native="impersonateUser"
                        >Impersonate <mdb-icon icon="lock" class="ml-2"></mdb-icon
                    ></mdb-btn>
                    <mdb-btn data-test="editUserBtn" color="primary" @click.native="editUser"
                        >Edit User<mdb-icon icon="edit" class="ml-2"></mdb-icon
                    ></mdb-btn>
                    <mdb-btn data-test="activeDeactiveUserBtn" color="warning" @click.native="activeDeactiveUser"
                        >Active/Deactive User<mdb-icon icon="recycle" class="ml-2"></mdb-icon
                    ></mdb-btn>
                    <mdb-btn data-test="deleteUserBtn" color="danger" @click.native="deleteUser"
                        >Delete User<mdb-icon icon="minus" class="ml-2"></mdb-icon
                    ></mdb-btn>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn data-test="rowModalCloseBtn" outline="primary" @click.native="rowModal = false"
                        >Close</mdb-btn
                    >
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal data-test="newUserModal" size="lg" :show="newUserModal" @close="newUserModal = false">
                <mdb-modal-header
                    ><mdb-modal-title data-test="newUserModalTitle">Add New User</mdb-modal-title></mdb-modal-header
                >
                <mdb-modal-body>
                    <mdb-row>
                        <mdb-col col="4">
                            <div class="form-group">
                                <label data-test="newUserFirstNameLabel" for="newUserFirstName"
                                    ><b>First Name</b></label
                                >
                                <input
                                    data-test="newUserFirstNameInput"
                                    type="text"
                                    id="NewUserFirstName"
                                    class="form-control"
                                    v-model="newUserFirstName"
                                    placeholder="User First Name"
                                />
                            </div>
                            <div class="form-group">
                                <label data-test="newUserLastNameLabel" for="newUserLastName"><b>Last Name</b></label>
                                <input
                                    data-test="newUserLastNameInput"
                                    type="text"
                                    id="newUserLastName"
                                    class="form-control"
                                    v-model="newUserLastName"
                                    placeholder="User Last Name"
                                />
                            </div>
                            <div class="form-group">
                                <label data-test="newUserEmailLabel" for="newUserEmail"><b>Email</b></label>
                                <input
                                    data-test="newUserEmailInput"
                                    type="text"
                                    id="newUserEmail"
                                    class="form-control"
                                    v-model="newUserEmail"
                                    placeholder="User Email"
                                />
                            </div>
                            <div class="form-group">
                                <label data-test="newUserPasswordLabel" for="newUserPassword"><b>Password</b></label>
                                <mdb-input
                                    data-test="newUserPasswordInput"
                                    placeholder="User Password"
                                    basic
                                    id="newUserPassword"
                                    v-model="generatedPassword"
                                    class="mt-0"
                                >
                                    <span
                                        data-test="generatePasswordClickable"
                                        class="input-group-text"
                                        slot="prepend"
                                        @click="generatePassword"
                                        ><mdb-icon icon="key" color="primary"></mdb-icon
                                    ></span>
                                </mdb-input>
                                <small data-test="passwordWarning" v-if="passwordWarning" class="text-danger"
                                    >Copy generated password now if providing to a customer, this is the only time
                                    you'll see it!</small
                                >
                            </div>
                            <mdb-select
                                data-test="companyOptionsSelect"
                                id="newUserCompany"
                                search
                                outline
                                v-model="companyOptions"
                                @change="changeCompany"
                                placeholder="Choose Company"
                                class="mt-3"
                                label="Company"
                            />
                        </mdb-col>
                        <mdb-col col="8">
                            <p data-test="addUserNotes"><b>Add User Notes:</b></p>
                            <ul data-test="userNotes">
                                <li>
                                    Verify user is an employee of the company you are creating an account for (Is it a
                                    company email address?)
                                </li>
                                <li>Do not use personal email accounts (Gmail, Hotmail, etc)</li>
                                <li>Email address will always be username</li>
                            </ul>
                        </mdb-col>
                    </mdb-row>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn data-test="newUserModalCloseBtn" outline="primary" @click.native="newUserModal = false"
                        >Close</mdb-btn
                    >
                    <mdb-btn
                        data-test="addUserBtn"
                        v-if="
                            newUserFirstName.length > 0 &&
                            newUserLastName.length > 0 &&
                            newUserEmail.length > 0 &&
                            generatedPassword.length > 0 &&
                            this.selectedCompany
                        "
                        color="primary"
                        @click.native="addUser"
                        ><mdb-icon icon="plus" class="mr-2"></mdb-icon>Add User</mdb-btn
                    >
                    <mdb-btn data-test="addUserBtnElse" v-else color="primary" disabled
                        ><mdb-icon icon="plus" class="mr-2"></mdb-icon>Add User</mdb-btn
                    >
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal
                data-test="editUserModal"
                v-if="editUserInfo"
                size="lg"
                :show="editUserModal"
                @close="editUserModal = false"
            >
                <mdb-modal-header
                    ><mdb-modal-title data-test="editUserModalTitle">Edit User</mdb-modal-title></mdb-modal-header
                >
                <mdb-modal-body>
                    <mdb-row>
                        <mdb-col col="4">
                            <div class="form-group">
                                <label data-test="editUserModalFirstNameLabel" for="editUserFirstName"
                                    ><b>First Name</b></label
                                >
                                <input
                                    data-test="editUserModalFirstNameInput"
                                    type="text"
                                    id="editUserFirstName"
                                    class="form-control"
                                    v-model="editUserInfo[0].usr_first_name"
                                    placeholder="User First Name"
                                />
                            </div>
                            <div class="form-group">
                                <label data-test="editUserModalLastNameLabel" for="editUserLastName"
                                    ><b>Last Name</b></label
                                >
                                <input
                                    data-test="editUserModalLastNameInput"
                                    type="text"
                                    id="editUserLastName"
                                    class="form-control"
                                    v-model="editUserInfo[0].usr_last_name"
                                    placeholder="User Last Name"
                                />
                            </div>
                            <div class="form-group">
                                <label data-test="editUserModalEmailLabel" for="editUserEmail"><b>Email</b></label>
                                <input
                                    data-test="editUserModalEmailInput"
                                    disabled
                                    type="text"
                                    id="editUserEmail"
                                    class="form-control"
                                    v-model="editUserInfo[0].usr_email"
                                    placeholder="User Email"
                                />
                            </div>
                        </mdb-col>
                        <mdb-col col="8">
                            <label data-test="editUserModalApiKeyLabel" for="editUserAPIKey"><b>API Key</b></label>
                            <mdb-input
                                data-test="editUserModalApiKeyInput"
                                id="editUserAPIKey"
                                placeholder="API Key"
                                basic
                                v-model="editUserInfo[0].usr_api_key"
                                class="mb-3"
                            >
                                <mdb-btn
                                    data-test="apiKeyCopyBtn"
                                    v-mdb-clipboard="editUserInfo[0].usr_api_key"
                                    group
                                    slot="append"
                                    color="primary"
                                    title="copy to clipboard"
                                    ><mdb-icon icon="copy"></mdb-icon
                                ></mdb-btn>
                            </mdb-input>
                            <label data-test="editUserModalApiPassLabel" for="editUserAPIPassword"
                                ><b>API Password</b></label
                            >
                            <mdb-input
                                data-test="editUserModalApiPassInput"
                                id="editUserAPIPassword"
                                placeholder="API Password"
                                basic
                                v-model="editUserInfo[0].usr_api_pass"
                                class="mb-3"
                            >
                                <mdb-btn
                                    data-test="apiPassCopyBtn"
                                    v-mdb-clipboard="editUserInfo[0].usr_api_pass"
                                    group
                                    slot="append"
                                    color="primary"
                                    title="copy to clipboard"
                                    ><mdb-icon icon="copy"></mdb-icon
                                ></mdb-btn>
                            </mdb-input>
                            <mdb-card data-test="multiCompanyAccessCard">
                                <mdb-card-body>
                                    <p data-test="multiCompanyAccessCardTitle"><b>Multi-Company Access</b></p>
                                    <p data-test="multiCompanyAccessCardCaution">
                                        Caution: Verify before adding companies to a user login.
                                    </p>
                                    <mdb-row>
                                        <mdb-col col="9">
                                            <mdb-select
                                                data-test="multiCompanyAccess_companyOptionsSelect"
                                                id="editUserCompany"
                                                search
                                                outline
                                                v-model="companyOptions"
                                                placeholder="Choose Company"
                                                label="Company"
                                            />
                                        </mdb-col>
                                        <mdb-col col="3">
                                            <mdb-btn
                                                data-test="multiCompanyAccess_addCompanyBtn"
                                                color="primary"
                                                size="sm"
                                                class="mt-4"
                                                title="Add company"
                                                @click.native="addCompany"
                                                ><mdb-icon icon="plus"></mdb-icon
                                            ></mdb-btn>
                                        </mdb-col>
                                    </mdb-row>
                                    <hr />
                                    <table data-test="editUserInfoTable" style="width: 100%">
                                        <tr
                                            data-test="editUserInfoTableRows"
                                            v-for="(user, index) of editUserInfo"
                                            v-bind:key="index"
                                        >
                                            <td data-test="editUserInfoTable_cmp_id">{{ user.cmp_id }}</td>
                                            <td data-test="editUserInfoTable_cmp_name">{{ user.cmp_name }}</td>
                                            <td
                                                data-test="editUserInfoTable_removeRowBtn"
                                                tag="a"
                                                @click="removeRow(index)"
                                            >
                                                <mdb-icon icon="trash"></mdb-icon>
                                            </td>
                                        </tr>
                                    </table>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-col>
                    </mdb-row>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn data-test="editUserModalCloseBtn" outline="primary" @click.native="editUserModal = false"
                        >Close</mdb-btn
                    >
                    <mdb-btn
                        data-test="updateUserBtn"
                        v-if="editUserInfo[0].usr_first_name.length > 0 && editUserInfo[0].usr_last_name.length > 0"
                        color="primary"
                        @click.native="updateUser"
                        ><mdb-icon icon="save" class="mr-2"></mdb-icon>Update User</mdb-btn
                    >
                    <mdb-btn data-test="updateUserBtnElse" v-else color="primary" disabled
                        ><mdb-icon icon="save" class="mr-2"></mdb-icon>Update User</mdb-btn
                    >
                </mdb-modal-footer>
            </mdb-modal>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import {
    mdbClipboard,
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbInput,
    mdbSelect,
    mdbModalFooter,
    mdbCard,
    mdbCardBody,
} from "mdbvue";
import Papa from "papaparse";

export default {
    name: "AdminManageUsers",
    components: {
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbBtn,
        mdbSpinner,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbInput,
        mdbSelect,
        mdbModalFooter,
        mdbCard,
        mdbCardBody,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    directives: {
        mdbClipboard,
    },
    data() {
        return {
            loading: false,
            table: [],
            tableRows: [],
            tableColumns: [],
            selectedRow: null,
            rowModal: false,
            newUserModal: false,
            newUserFirstName: "",
            newUserLastName: "",
            newUserEmail: "",
            newUserPassword: "",
            newUserCompany: "",
            companyList: [],
            generatedPassword: "",
            passwordWarning: false,
            companyOptions: [],
            selectedCompany: null,
            editUserModal: false,
            editUserInfo: null,
            limit: 25,
            size: 25,
            page: 1,
            totalRecords: 0,
            sortColumn: "usr_last_login_date",
            sortkey: "usr_last_login_date",
            sortDir: "desc",
            searchTerm: null,
        };
    },
    methods: {
        async getInit() {
            this.tableCreated = await this.createTable();
        },
        async searchTable(params) {
            this.searchTerm = params.searchTerm;
            await this.createTable();
        },
        async createTable() {
            this.table = null;
            this.loading = true;
            await this.$httpAdmin
                .get("/adminuserprofile/", {
                    headers: this.headers,
                    params: this.getTableParams(),
                })
                .then(async (response) => {
                    this.table = response.data[0].Items;
                    this.totalRecords = response.data[0].TotalRecords;
                });
            this.loading = false;
            for (let i = 0; i < this.table.length; i++) {
                this.table[i].fullName = this.table[i].usr_first_name + " " + this.table[i].usr_last_name;
                if (!this.table[i].usr_last_login_date) {
                    this.table[i].usr_last_login_date = "";
                } else {
                    this.table[i].usr_last_login_date = new moment(this.table[i].usr_last_login_date).format(
                        "MM/DD/YYYY HH:mm"
                    );
                }
            }
            this.tableRows = this.table; //this.table.reverse()
            this.tableColumns = [
                { field: "fullName", label: "Full Name", sortable: true },
                { field: "usr_email", label: "Email Address", sortable: true },
                { field: "usr_last_login_date", label: "Last Login Date", sortable: true },
                { field: "usr_is_active", label: "Active", sortable: true },
                { field: "usr_is_deleted", label: "Deleted", sortable: true },
            ];
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.createTable();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.createTable();
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.createTable();
        },
        getTableParams() {
            return {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sorDir: this.sortDir,
                usr_email: this.searchTerm ? this.searchTerm : null,
            };
        },
        onRowClick(params) {
            params.selected = true;
            this.selectedRow = params.row;
            this.rowModal = true;
        },
        async addNewUser(action) {
            this.companyOptions = [];
            await this.$httpAdmin
                .get("/admincompany/light", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.companyList = response.data;
                    for (let i = 0; i < this.companyList.length; i++) {
                        this.companyOptions.push({
                            text: this.companyList[i].cmp_name + " (ID:" + this.companyList[i].cmp_id + ")",
                            id: this.companyList[i].cmp_id,
                            value: i,
                            name: this.companyList[i].cmp_name,
                        });
                    }
                });
            if (action == "new user") {
                this.newUserModal = true;
            }
        },
        changeCompany() {
            for (let i = 0; i < this.companyOptions.length; i++) {
                if (this.companyOptions[i].selected == true) {
                    this.selectedCompany = this.companyOptions[i].id;
                }
            }
        },
        impersonateUser() {
            if (this.selectedRow.usr_id < 1) {
                return;
            }
            if (!this.selectedRow.usr_api_key || !this.selectedRow.usr_api_pass) {
                return;
            }
            let url =
                "/dashboard?a=login&isImpersonated=y&apiKey=" +
                this.selectedRow.usr_api_key +
                "&apiPass=" +
                this.selectedRow.usr_api_pass;
            window.open(url, "companyPage");
        },
        async editUser() {
            this.rowModal = false;
            await this.addNewUser("update");
            await this.$httpAdmin
                .get("/adminuserprofile/" + this.selectedRow.usr_id, { headers: this.headers })
                .then(async (response) => {
                    this.editUserInfo = response.data;
                });
            this.editUserModal = true;
        },
        async activeDeactiveUser() {
            this.rowModal = false;
            let action = "";
            if (this.selectedRow.usr_is_active == 1) {
                action = "deactivated";
            } else {
                action = "activated";
            }
            await this.$httpAdmin
                .delete("/adminuserprofile/" + this.selectedRow.usr_id + "/activedeactive", { headers: this.headers })
                .then(async (response) => {
                    let results = response.data;
                    if (results == "OK") {
                        this.$notify.success({
                            message: "User has been successfully " + action,
                            title: "User Activated/Deactivated",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: results,
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
            this.createTable();
        },
        async deleteUser() {
            this.rowModal = false;
            await this.$httpAdmin
                .delete("/adminuserprofile/" + this.selectedRow.usr_id, { headers: this.headers })
                .then(async (response) => {
                    let results = response.data;
                    if (results == "OK") {
                        this.$notify.success({
                            message: "User has been successfully deleted",
                            title: "User Deleted",
                            timeOut: 5000,
                            variant: "success",
                        });
                    } else {
                        this.$notify.error({
                            message: results,
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
            this.createTable();
        },
        async addUser() {
            let goodEmail = this.validateEmail(this.newUserEmail);
            if (goodEmail) {
                await this.$httpAdmin
                    .post("/adminuserprofile", this.addUserParams(), { headers: this.headers })
                    .then(async (response) => {
                        let results = response.data;
                        if (results == "OK") {
                            this.$notify.success({
                                message: "User has been successfully added",
                                title: "User Added",
                                timeOut: 5000,
                                variant: "success",
                            });
                        } else {
                            this.$notify.error({
                                message: results,
                                title: "Error",
                                timeOut: 5000,
                                variant: "danger",
                            });
                        }
                    });
            } else {
                this.$notify.error({
                    message: "Please enter a valid email",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
            }
        },
        validateEmail(email) {
            let re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        addUserParams() {
            return {
                usr_first_name: this.newUserFirstName,
                usr_last_name: this.newUserLastName,
                usr_email: this.newUserEmail,
                usr_password: this.generatedPassword,
                usr_cmp_id: this.selectedCompany,
            };
        },
        generatePassword() {
            let len = 10;
            let alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            let alphaLength = 52;
        
            let nums = "0123456789";
            let numsLength = 10;
        
            let symbols = "!@#$%^&*()_+=\[{\]};:<>|./?,-";
            let symbolsLength = 27;
        
            let password = "";
            
            // random characters, mix of upper and lowercase
            for (let i = 0; i < len - 4; i++) {
                let index = Math.floor((Math.random() * alphaLength)); 
                password += alpha.charAt(index);
            }

            // add one guaranteed lowercase and uppercase
            password += alpha.charAt(Math.floor((Math.random() * alphaLength))).toLowerCase();
            password += alpha.charAt(Math.floor((Math.random() * alphaLength))).toUpperCase();

            password += nums.charAt(Math.floor((Math.random() * numsLength)));
            password += symbols.charAt(Math.floor((Math.random() * symbolsLength)));

            this.generatedPassword = password;
            this.passwordWarning = true;
        },
        addCompany() {
            for (let i = 0; i < this.companyOptions.length; i++) {
                if (this.companyOptions[i].selected) {
                    this.editUserInfo.push({
                        usr_first_name: this.editUserInfo[0].usr_first_name,
                        usr_last_name: this.editUserInfo[0].usr_last_name,
                        usr_email: this.editUserInfo[0].usr_email,
                        usr_api_key: this.editUserInfo[0].usr_api_key,
                        usr_api_pass: this.editUserInfo[0].usr_api_pass,
                        cmp_id: this.companyOptions[i].id,
                        cmp_name: this.companyOptions[i].name,
                    });
                }
            }
        },
        async updateUser() {
            await this.$httpAdmin
                .put("/adminuserprofile/" + this.selectedRow.usr_id, this.updateUserParams(), { headers: this.headers })
                .then(async (response) => {
                    let results = response.data;
                    if (results == "OK") {
                        this.$notify.success({
                            message: "User has been successfully updated.",
                            title: "User Updated",
                            timeOut: 5000,
                            variant: "success",
                        });
                        this.editUserModal = false;
                        this.createTable();
                    } else {
                        this.$notify.error({
                            message: results,
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
        },
        updateUserParams() {
            let companies = [];
            for (let i = 0; i < this.editUserInfo.length; i++) {
                companies.push(this.editUserInfo[i].cmp_id);
            }
            if (!companies || companies.length == 0) {
                this.$notify.error({
                    message: "There must be at least one company assigned to the user.",
                    title: "Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                return;
            }
            return {
                first_name: this.editUserInfo[0].usr_first_name,
                last_name: this.editUserInfo[0].usr_last_name,
                companies: companies,
            };
        },
        removeRow(index) {
            if (this.editUserInfo.length > 1) {
                this.editUserInfo.splice(index, 1);
            }
        },
        async exportCSV() {
            await this.$httpAdmin
                .get("/adminuserprofile", {
                    headers: this.headers,
                    params: this.csvParams(),
                })
                .then(async (response) => {
                    let pageData = response.data;
                    let blob = new Blob([Papa.unparse(pageData)], { type: "text/csv;charset=utf-8;" });

                    let link = document.createElement("a");

                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", "Export.csv");
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        },
        csvParams() {
            return {
                limit: 0,
                size: 0,
                page: 1,
                sortColumn: "usr_last_login_date",
                sortkey: "usr_last_login_date",
                sortDir: "desc",
            };
        },
    },
    async mounted() {
      const key = this.$route.query.usr_email;
      if (key) {
        let params = {searchTerm: key};
        await this.searchTable(params);
      }
      else await this.getInit();
    },
};
</script>
