<template>
    <div data-test="page" class="m-4">
        <h3 data-test="pageTitle" class="my-4 mx-2">Download Invoice</h3>
        <div class="d-flex justify-content-center align-items-center">
            <mdb-select
                data-test="companiesSelect"
                class="flex-fill"
                multiple
                selectAll
                v-if="companies"
                v-model="companies"
                label="Select Company"
                search
            />
            <mdb-btn data-test="companiesSelect_spinner" v-else size="sm" outline="danger" disabled class="flex-fill"
                ><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading
                Companies...</mdb-btn
            >
            <mdb-select data-test="carriersSelect" v-model="carriers" label="Select Carrier" class="flex-fill" />
            <DateSelector
                data-test="DateSelector"
                :startDate="startDate"
                :endDate="endDate"
                @update-end-date="updateEndDate"
                @update-start-date="updateStartDate"
                class="flex-fill"
            />
            <mdb-btn
                data-test="searchInvoicesBtn"
                @click.native="searchInvoices"
                color="info"
                icon="search"
                class="flex-fill"
                >Search</mdb-btn
            >
        </div>
        <div class="d-flex">
            <mdb-btn
                data-test="downloadInvoicesBtn"
                class="float-right"
                @click.native="downloadInvoices"
                v-if="!downloadingCsv"
                ><mdb-icon icon="download"></mdb-icon
            ></mdb-btn>
            <mdb-btn data-test="downloadInvoicesBtn_spinner" v-else color="default"
                ><mdb-spinner size="sm"></mdb-spinner> Downloading...</mdb-btn
            >
        </div>
        <vue-good-table
            data-test="vue-good-table"
            styleClass="vgt-table condensed"
            :rows="invoices"
            :columns="columns"
            mode="remote"
            :totalRows="totalRows"
            :isLoading.sync="loading"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            @on-search="searchInvoices"
            :search-options="{
                enabled: true,
                trigger: 'enter',
            }"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: this.limit,
                position: 'top',
                perPageDropdown: [10, 25, 50],
                dropdownAllowAll: false,
                setCurrentPage: this.page,
            }"
        >
            <template slot="table-row" slot-scope="props">
                <span
                    v-if="
                        props.column.field === 'week_amount' ||
                        props.column.field === 'mean' ||
                        props.column.field === 'stddev'
                    "
                >
                    {{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>
<script>
import DateSelector from "@/components/DateSelector";
import moment from "moment";
import Papa from "papaparse";
import { mdbBtn, mdbSelect, mdbIcon, mdbSpinner } from "mdbvue";

export default {
    name: "AdminMissedInvoices",
    components: {
        DateSelector,
        mdbBtn,
        mdbSelect,
        mdbIcon,
        mdbSpinner,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            invoices: null,
            companies: null,
            carriers: [
                { text: "FedEx", value: "1" },
                { text: "UPS", value: "2" },
            ],
            startDate: new moment(new moment().startOf("week").subtract(7, "days").format("YYYY-MM-DD"), "YYYY-MM-DD"),
            endDate: new moment(new moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
            columns: [
                {
                    label: "CMP ID",
                    field: "cmp_id",
                },
                {
                    label: "Company Name",
                    field: "cmp_name",
                },
                {
                    label: "Company Status",
                    field: "cmp_type",
                },
                {
                    label: "Carrier",
                    field: "car_name",
                },
                {
                    label: "Week Date",
                    field: "date",
                    formatFn: (r) => moment(r).format("MM/DD/YYYY"),
                },
                {
                    label: "Total Spend",
                    field: "week_amount",
                },
                {
                    label: "Mean Spend",
                    field: "mean",
                },
                {
                    label: "STD-DEV Spend",
                    field: "stddev",
                },
            ],
            loading: false,
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "cmp_id",
            sortDir: "desc",
            sortkey: "cmp_id",
            searchTerm: null,
            totalRows: null,
            downloadingCsv: false,
        };
    },
    computed: {
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                startDate: moment(this.startDate).format("YYYY-MM-DD"),
                endDate: moment(this.endDate).format("YYYY-MM-DD"),
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
        selectedCarrier() {
            return this.calculateSelected(this.carriers);
        },
        selectedCompany() {
            return this.calculateSelected(this.companies);
        },
    },
    methods: {
        updateStartDate(val) {
            this.startDate = val;
        },
        updateEndDate(val) {
            this.endDate = val;
        },
        calculateSelected(array) {
            if (!array) {
                return "";
            }
            let records;
            array.map((c) => {
                if (c.selected) {
                    records = c.value;
                }
            });
            return records ? records : null;
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.searchInvoices();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.searchInvoices();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.searchInvoices();
        },
        async searchInvoices(params) {
            let search;
            let compParms;
            let carParms;
            this.searchTerm = "";
            if (params) {
                search = `&cmp_name=${params.searchTerm}`;
                this.searchTerm = params.searchTerm;
            }
            if (this.selectedCarrier) {
                carParms = `&car_id=${this.selectedCarrier}`;
            }
            if (this.selectedCompany) {
                compParms = `&cmp_id=${this.selectedCompany}`;
            }
            this.loading = true;
            const found = await this.$httpAdmin.get(
                `missedinvoices/?${this.params}${search ? search : ""}${carParms ? carParms : ""}${
                    compParms ? compParms : ""
                }`,
                {
                    headers: this.headers,
                }
            );
            this.loading = false;
            if (found) {
                this.invoices = found.data[0].Items;
                this.totalRows = found.data[0].TotalRecords;
            }
        },
        async downloadInvoices() {
            let search;
            let compParms;
            let carParms;
            this.downloadingCsv = true;
            if (this.searchTerm) {
                search = `&cmp_name=${this.searchTerm}`;
            }
            if (this.selectedCarrier) {
                carParms = `&car_id=${this.selectedCarrier}`;
            }
            if (this.selectedCompany) {
                compParms = `&cmp_id=${this.selectedCompany}`;
            }
            const dates = `startDate=${this.startDate}&endDate=${this.endDate}`;
            const response = await this.$httpAdmin.get(
                `missedinvoices?${dates}${search ? search : ""}${carParms ? carParms : ""}${
                    compParms ? compParms : ""
                }`,
                {
                    headers: this.headers,
                }
            );
            if (response) {
                let pageData = response.data;
                let blob = new Blob([Papa.unparse(pageData)], { type: "text/csv;charset=utf-8;" });

                let link = document.createElement("a");

                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "Export.csv");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            this.downloadingCsv = false;
        },
        formatDate(date) {
            return moment(date).format("MM/DD/YYYY");
        },
        async getCompanies() {
            const companies = await this.$httpAdmin.get(`admincompany/light`, {
                headers: this.headers,
            });
            if (companies) {
                this.companies = companies.data.map((c) => {
                    return {
                        text: `${c.cmp_name} (ID: ${c.cmp_id} Type: ${c.cmp_type})`,
                        value: c.cmp_id,
                    };
                });
            }
        },
    },
    mounted() {
        this.searchInvoices();
        this.getCompanies();
    },
};
</script>
