<template>
    <div data-test="page" class="h-90" style="overflow-x: hidden; overflow-y: auto">
        <div>
            <h1 data-test="pageHeader" class="page-header border-bottom mx-2">
                Customers
                <span
                    data-test="pageHeaderSmall"
                    class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block"
                    >Existing Customers</span
                >
                <mdb-icon icon="check-square" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row v-if="isBusy" id="onboarding_overlay" class="justify-content-center align-content-center h-100vh">
                <mdb-spinner class="justify-content-center"></mdb-spinner>
                <h3><b>...Loading</b></h3>
            </mdb-row>
            <div :style="{ opacity: isBusy ? 0.3 : 1 }">
                <mdb-row class="mt-4">
                    <mdb-col col="4">
                        <select
                            data-test="selectedResellerSelect"
                            class="browser-default custom-select mt-1"
                            v-model="selectedReseller"
                            @change="resellerChange"
                        >
                            <option data-test="allResellersOption" value="all resellers" selected>All Resellers</option>
                            <option
                                data-test="resellerOptionsOption"
                                v-for="(reseller, index) of resellerOptions"
                                v-bind:key="index"
                                :value="reseller"
                            >
                                {{ reseller.text }}
                            </option>
                        </select>
                    </mdb-col>
                    <mdb-col col="3">
                        <mdb-btn-group data-test="liveMockTrialBtnGroup">
                            <mdb-btn
                                data-test="changeLiveBtn"
                                :color="liveSelected ? 'primary' : 'white'"
                                @click.native="changeLive"
                                size="sm"
                                >Live
                            </mdb-btn>
                            <mdb-btn
                                data-test="changeTrialBtn"
                                :color="trialSelected ? 'primary' : 'white'"
                                @click.native="changeTrial"
                                size="sm"
                                >Trial
                            </mdb-btn>
                            <mdb-btn
                                data-test="changeMockBtn"
                                :color="mockSelected ? 'primary' : 'white'"
                                @click.native="changeMock"
                                size="sm"
                                >Mock
                            </mdb-btn>
                            <mdb-btn
                                data-test="changeDownloadOnlyBtn"
                                :color="downloadOnlySelected ? 'primary' : 'white'"
                                @click.native="changeDownloadOnly"
                                size="sm"
                                >Download Only
                            </mdb-btn>
                        </mdb-btn-group>
                    </mdb-col>
                    <mdb-col col="3">
                        <mdb-btn-group data-test="activeInactiveBtnGroup">
                            <mdb-btn
                                data-test="changeActiveBtn"
                                :color="activeSelected ? 'primary' : 'white'"
                                @click.native="changeActive"
                                size="sm"
                                >Active
                            </mdb-btn>
                            <mdb-btn
                                data-test="changeInactiveBtn"
                                :color="inactiveSelected ? 'primary' : 'white'"
                                @click.native="changeInactive"
                                size="sm"
                                >Inactive
                            </mdb-btn>
                        </mdb-btn-group>
                    </mdb-col>
                </mdb-row>
                <mdb-row class="mt-2 mx-0">
                    <mdb-col col="12" v-if="customersRows">
                        <vue-good-table
                            data-test="customersRows_vue-good-table"
                            :columns="customersColumns"
                            :rows="customersRows"
                            :search-options="{ enabled: true, trigger: 'enter' }"
                            :isLoading.sync="loading"
                            mode="remote"
                            styleClass="vgt-table striped bordered"
                            @on-row-click="onRowClick"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-per-page-change="onPerPageChange"
                            @on-search="searchTable"
                            :totalRows="totalRecords"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: this.limit,
                                position: 'top',
                                perPageDropdown: [10, 25, 50, 100],
                                dropdownAllowAll: false,
                                setCurrentPage: this.page,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'cmp_type'">
                                    <span v-if="props.row.cmp_type == 'live'"
                                        ><mdb-icon icon="circle" class="text-success pr-2"></mdb-icon
                                        ><strong>Live</strong></span
                                    >
                                    <span v-else-if="props.row.cmp_type == 'mock'"
                                        ><mdb-icon icon="clock" class="text-warning pr-2"></mdb-icon
                                        ><strong>Mock</strong></span
                                    >
                                    <span v-else-if="props.row.cmp_type == 'trial'"
                                        ><mdb-icon icon="clock" class="text-success pr-2"></mdb-icon
                                        ><strong>Trial</strong></span
                                    >
                                    <span v-else-if="props.row.cmp_type == 'hold'"
                                        ><mdb-icon icon="ban" class="text-warning pr-2"></mdb-icon
                                        ><strong>Hold</strong></span
                                    >
                                    <span v-else-if="props.row.cmp_type == 'suspect'"
                                        ><mdb-icon icon="circle-question" class="text-warning pr-2"></mdb-icon
                                        ><strong>Suspect</strong></span
                                    >
                                    <span v-else-if="props.row.cmp_type == 'download'"
                                        ><mdb-icon icon="download" class="text-success pr-2"></mdb-icon
                                        ><strong>Download Only</strong></span
                                    >
                                </span>
                                <span v-else-if="props.column.field == 'cmp_is_active'">
                                    <span v-if="props.row.cmp_is_active == '1'"
                                        ><mdb-icon icon="circle" class="text-success pr-2"></mdb-icon
                                        ><strong>Active</strong></span
                                    >
                                    <span v-else-if="props.row.cmp_is_active == '0'"
                                        ><mdb-icon icon="clock" class="text-danger pr-2"></mdb-icon
                                        ><strong>Inactive</strong></span
                                    >
                                </span>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </mdb-col>
                </mdb-row>
                <mdb-modal data-test="rowModal" size="lg" :show="rowModal" @close="rowModal = false">
                    <mdb-modal-header>
                        <mdb-modal-title data-test="rowModalTitle">Choose an action</mdb-modal-title>
                    </mdb-modal-header>
                    <mdb-modal-body>
                        <mdb-row>
                            <mdb-btn data-test="editCustomerModalBtn" color="primary" @click.native="editCustomerModal"
                                >Edit Customers
                                <mdb-icon icon="user" class="ml-2"></mdb-icon>
                            </mdb-btn>
                            <mdb-btn
                                data-test="carrierCredentialsModalBtn"
                                color="warning"
                                @click.native="carrierCredentialsModal"
                                >Edit Carrier Credentials
                                <mdb-icon icon="truck" class="ml-2"></mdb-icon>
                            </mdb-btn>
                            <mdb-btn
                                data-test="carrierAccountsModalBtn"
                                color="warning"
                                @click.native="carrierAccountsModal"
                                >Edit Carrier Accounts
                                <mdb-icon icon="truck" class="ml-2"></mdb-icon>
                            </mdb-btn>
                            <mdb-btn data-test="activateModalBtn" color="danger" @click.native="activateModal"
                                >Activate/Deactivate
                                <mdb-icon icon="toggle-on" class="ml-2"></mdb-icon>
                            </mdb-btn>
                            <mdb-btn data-test="loginAsModalBtn" color="success" @click.native="loginAsModal"
                                >Login As
                                <mdb-icon icon="paper-plane" class="ml-2"></mdb-icon>
                            </mdb-btn>
                        </mdb-row>
                    </mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn data-test="rowModalCloseBtn" color="primary" @click.native="rowModal = false"
                            >Close
                        </mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>
                <mdb-modal
                    data-test="customerEditModal"
                    size="fluid"
                    scrollable
                    :show="customerEditModal"
                    @close="customerEditModal = false"
                    v-if="selectedRow"
                >
                    <mdb-modal-header>
                        <mdb-modal-title data-test="customerEditModalTitle">
                            <mdb-icon icon="user" class="mr-2"></mdb-icon>
                            Customer Information |
                            {{ selectedRow.cmp_name }}
                        </mdb-modal-title>
                    </mdb-modal-header>
                    <mdb-modal-body>
                        <mdb-tab data-test="customerEditModalTab" default>
                            <mdb-tab-item
                                data-test="customerEditModalTab0"
                                :active="active == 0"
                                @click.native="showCustEditTab"
                            >
                                <mdb-icon icon="user" class="mr-2"></mdb-icon>
                                Customer Settings
                            </mdb-tab-item>
                            <mdb-tab-item
                                data-test="customerEditModalTab1"
                                :active="active == 1"
                                @click.native="showCustAddonTab"
                            >
                                <mdb-icon icon="plug" class="mr-2"></mdb-icon>
                                Add-ons Settings
                            </mdb-tab-item>
                            <mdb-tab-item
                                data-test="customerEditModalTab2"
                                :active="active == 2"
                                @click.native="showCustSnapshotTab"
                            >
                                <mdb-icon icon="chart-line" class="mr-2"></mdb-icon>
                                Snapshot
                            </mdb-tab-item>
                            <mdb-tab-item
                                data-test="customerEditModalTab3"
                                :active="active == 3"
                                @click.native="showCustUsersTab"
                            >
                                <mdb-icon icon="users" class="mr-2"></mdb-icon>
                                Users
                            </mdb-tab-item>
                        </mdb-tab>
                        <mdb-tab-content data-test="customerEditModalTabContent" v-if="custInfo">
                            <mdb-tab-pane
                                data-test="customerEditModalTabPane0"
                                class="fade"
                                key="show1"
                                v-if="active == 0"
                            >
                                <mdb-row>
                                    <mdb-col col="12">
                                        <h4 data-test="customerEditModalTabPane0_generalSettings">General Settings</h4>
                                        <mdb-icon icon="cogs" class="float-right" size="lg"></mdb-icon>
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row>
                                    <mdb-col col="7">
                                        <mdb-row>
                                            <mdb-col col="6">
                                                <label data-test="customerEditModalTabPane0_partnerLabel" for="partner"
                                                    ><b>Partner</b></label
                                                >
                                                <select
                                                    data-test="customerEditModalTabPane0_custInfoResellerNameSelect"
                                                    id="partner"
                                                    class="browser-default custom-select"
                                                    v-model="custInfo.reseller_name"
                                                >
                                                    <option
                                                        data-test="customerEditModalTabPane0_resellerOptionsOption"
                                                        v-for="(reseller, index) of resellerOptions"
                                                        v-bind:key="index"
                                                    >
                                                        {{ reseller.text }}
                                                    </option>
                                                </select>
                                            </mdb-col>
                                            <mdb-col col="6">
                                                <label
                                                    data-test="customerEditModalTabPane0_ownerSalespersonLabel"
                                                    for="owner/salesperson"
                                                    ><b>Owner/Salesperson</b></label
                                                >
                                                <select
                                                    data-test="customerEditModalTabPane0_custInfoCmpSalesPersonSelect"
                                                    id="owner/salesperson"
                                                    class="browser-default custom-select"
                                                    v-model="custInfo.cmp_user_profile_id_sales_person"
                                                >
                                                    <option
                                                        data-test="customerEditModalTabPane0_custInfoCmpSalesPersonNAResellerOption"
                                                        value="1"
                                                    >
                                                        N/A: RESELLER
                                                    </option>
                                                    <option
                                                        data-test="customerEditModalTabPane0_custInfoCmpSalesPersonSalesPersonOptionsOption"
                                                        v-for="(salesperson, index) of salespersonOptions"
                                                        v-bind:key="index"
                                                    >
                                                        {{ salesperson.text }}
                                                    </option>
                                                </select>
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="mt-4">
                                            <mdb-col col="6">
                                                <label
                                                    data-test="customerEditModalTabPane0_baseCountryLabel"
                                                    for="baseCountry"
                                                    ><b>Base Country</b>
                                                    <mdb-tooltip
                                                        material
                                                        trigger="hover"
                                                        :options="{ placement: 'right' }"
                                                    >
                                                        <span slot="tip"
                                                            >Typically US. This option is set to modify the carrier
                                                            billing country settings for international based
                                                            customers.</span
                                                        >
                                                        <mdb-icon
                                                            slot="reference"
                                                            class="pl-1"
                                                            icon="question-circle"
                                                            color="primary"
                                                            size="sm"
                                                        ></mdb-icon>
                                                    </mdb-tooltip>
                                                </label>
                                                <select
                                                    data-test="customerEditModalTabPane0_baseCountrySelect"
                                                    class="browser-default custom-select"
                                                    id="baseCountry"
                                                    v-model="custInfo.cmp_base_country.toUpperCase()"
                                                >
                                                    <option
                                                        data-test="customerEditModalTabPane0_countriesOption"
                                                        v-for="(country, index) of countries"
                                                        v-bind:key="index"
                                                        :value="country.value"
                                                    >
                                                        {{ country.text }}
                                                    </option>
                                                </select>
                                            </mdb-col>
                                            <mdb-col col="6">
                                                <label
                                                    data-test="customerEditModalTabPane0_startDateLabel"
                                                    for="startDate"
                                                    ><b>Start Date</b></label
                                                >
                                                <input
                                                    data-test="customerEditModalTabPane0_startDateInput"
                                                    id="startDate"
                                                    class="form-control"
                                                    v-model="custInfo.cmp_start_date"
                                                    disabled
                                                />
                                            </mdb-col>
                                        </mdb-row>
                                        <hr />
                                        <mdb-row>
                                            <mdb-col col="12">
                                                <p data-test="customerEditModalTabPane0_internalNotes">
                                                    Internal Notes
                                                </p>
                                                <mdb-input
                                                    data-test="customerEditModalTabPane0_internalNotesInput"
                                                    outline
                                                    type="textarea"
                                                    rows="5"
                                                    id="internalNotes"
                                                    v-model="custInfo.cmp_note"
                                                />
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-col>
                                    <mdb-col col="5">
                                        <mdb-row class="mt-4">
                                            <mdb-col col="12">
                                                <img
                                                    data-test="customerEditModalTabPane0_resellerLogo"
                                                    :src="custInfo.reseller_inv_logo"
                                                    class="rounded mx-auto d-block"
                                                />
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="mt-4">
                                            <mdb-col col="12">
                                                <mdb-card data-test="customerEditModalTabPane0_rhCard" class="mr-1">
                                                    <mdb-card-body>
                                                        <mdb-row>
                                                            <mdb-col col="4">
                                                                <label
                                                                    data-test="customerEditModalTabPane0_rhCard_accountingIDLabel"
                                                                    for="accountingID"
                                                                    ><b>Accounting ID</b>
                                                                    <mdb-tooltip
                                                                        material
                                                                        trigger="hover"
                                                                        :options="{ placement: 'right' }"
                                                                    >
                                                                        <span slot="tip"
                                                                            >TI/FFS/eAudit Customers Only: Entered by
                                                                            Accounting Team and required for
                                                                            Activation.</span
                                                                        >
                                                                        <mdb-icon
                                                                            slot="reference"
                                                                            class="pl-1"
                                                                            icon="question-circle"
                                                                            color="primary"
                                                                            size="sm"
                                                                        ></mdb-icon>
                                                                    </mdb-tooltip>
                                                                </label>
                                                                <input
                                                                    data-test="customerEditModalTabPane0_rhCard_accountingIDInput"
                                                                    class="form-control"
                                                                    id="accountingID"
                                                                    v-model="custInfo.cmp_accounting_id"
                                                                    :disabled="
                                                                        userInfo.usrLevel == 'super_admin' &&
                                                                        (userInfo.usrRestrictType == 1 ||
                                                                            userInfo.usrRestrictType == 3)
                                                                            ? false
                                                                            : true
                                                                    "
                                                                />
                                                            </mdb-col>
                                                            <mdb-col col="4">
                                                                <label
                                                                    data-test="customerEditModalTabPane0_rhCard_netTermLabel"
                                                                    for="netTerm"
                                                                    ><b>Net Term</b></label
                                                                >
                                                                <div class="input-group">
                                                                    <input
                                                                        data-test="customerEditModalTabPane0_rhCard_netTermInput"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="netTerm"
                                                                        v-model="custInfo.cmp_bill_terms"
                                                                    />
                                                                    <div
                                                                        data-test="customerEditModalTabPane0_rhCard_netTermInputAppend"
                                                                        class="input-group-append"
                                                                    >
                                                                        <span class="input-group-text"> Day </span>
                                                                    </div>
                                                                </div>
                                                            </mdb-col>
                                                            <mdb-col col="4">
                                                                <label
                                                                    data-test="customerEditModalTabPane0_rhCard_lateFeeLabel"
                                                                    for="lateFee"
                                                                    ><b>Late Fee</b></label
                                                                >
                                                                <div class="input-group">
                                                                    <input
                                                                        data-test="customerEditModalTabPane0_rhCard_lateFeeInput"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="lateFee"
                                                                        v-model="custInfo.cmp_late_fee"
                                                                    />
                                                                    <div
                                                                        data-test="customerEditModalTabPane0_rhCard_lateFeeInputAppend"
                                                                        class="input-group-append"
                                                                    >
                                                                        <span class="input-group-text"> % </span>
                                                                    </div>
                                                                </div>
                                                            </mdb-col>
                                                        </mdb-row>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-col>
                                </mdb-row>
                                <hr />
                                <mdb-row>
                                    <mdb-col col="12">
                                        <h4 data-test="customerEditModalTabPane0_primaryContactTitle">
                                            Primary Contact
                                        </h4>
                                        <mdb-icon icon="user-md" class="float-right" size="lg"></mdb-icon>
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row>
                                    <mdb-col col="4">
                                        <label
                                            data-test="customerEditModalTabPane0_firstNameLabel"
                                            for="contactFirstName"
                                            ><b>First Name</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_firstNameInput"
                                            type="text"
                                            class="form-control"
                                            id="contactFirstName"
                                            v-model="custInfo.cmp_contact_first_name"
                                        />
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <label data-test="customerEditModalTabPane0_lastNameLabel" for="contactLastName"
                                            ><b>Last Name</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_lastNameInput"
                                            type="text"
                                            class="form-control"
                                            id="contactLastName"
                                            v-model="custInfo.cmp_contact_last_name"
                                        />
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="mt-4">
                                    <mdb-col col="3">
                                        <label
                                            data-test="customerEditModalTabPane0_primaryPhoneLabel"
                                            for="contactPrimaryPhone"
                                            ><b>Primary Phone</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_primaryPhoneInput"
                                            type="text"
                                            class="form-control"
                                            id="contactPrimaryPhone"
                                            v-model="custInfo.cmp_contact_phone"
                                        />
                                    </mdb-col>
                                    <mdb-col col="1">
                                        <label data-test="customerEditModalTabPane0_extLabel" for="contactExt"
                                            ><b>Ext</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_extInput"
                                            type="text"
                                            class="form-control"
                                            id="contactExt"
                                            v-model="custInfo.cmp_contact_phone_ext"
                                        />
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <label data-test="customerEditModalTabPane0_emailLabel" for="contactEmail"
                                            ><b>Email</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_emailInput"
                                            type="text"
                                            class="form-control"
                                            id="contactEmail"
                                            v-model="custInfo.cmp_contact_email"
                                        />
                                    </mdb-col>
                                </mdb-row>
                                <hr />
                                <mdb-row>
                                    <mdb-col col="12">
                                        <h4 data-test="customerEditModalTabPane0_billingInformationTitle">
                                            Billing Information
                                        </h4>
                                        <small data-test="customerEditModalTabPane0_billingInformationTitleSmall"
                                            ><b>Note: </b>Billing Contact receives Monthly Invoice and Summaries via
                                            Email (and if enabled below).</small
                                        >
                                        <mdb-icon icon="money-bill" class="float-right" size="lg"></mdb-icon>
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="mt-4">
                                    <mdb-col col="8">
                                        <mdb-card
                                            data-test="customerEditModalTabPane0_billingInformationCard"
                                            class="ml-1"
                                        >
                                            <mdb-card-body>
                                                <mdb-row>
                                                    <mdb-col col="6">
                                                        <mdb-row class="mt-2">
                                                            <mdb-col col="12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        data-test="customerEditModalTabPane0_sendWeeklySummariesInput"
                                                                        type="checkbox"
                                                                        class="custom-control-input"
                                                                        id="summaries"
                                                                        v-model="custInfo.cmp_send_summary"
                                                                    />
                                                                    <label
                                                                        data-test="customerEditModalTabPane0_sendWeeklySummariesLabel"
                                                                        class="custom-control-label"
                                                                        for="summaries"
                                                                        >Send Weekly Summaries</label
                                                                    >
                                                                </div>
                                                            </mdb-col>
                                                        </mdb-row>
                                                        <mdb-row class="mt-2">
                                                            <mdb-col col="12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        data-test="customerEditModalTabPane0_sendCustomerInvoicesInput"
                                                                        type="checkbox"
                                                                        class="custom-control-input"
                                                                        id="invoices"
                                                                        v-model="custInfo.cmp_send_invoice"
                                                                    />
                                                                    <label
                                                                        data-test="customerEditModalTabPane0_sendCustomerInvoicesLabel"
                                                                        class="custom-control-label"
                                                                        for="invoices"
                                                                        >Send Customer Invoices</label
                                                                    >
                                                                </div>
                                                            </mdb-col>
                                                        </mdb-row>
                                                    </mdb-col>
                                                    <mdb-col col="6">
                                                        <label
                                                            data-test="customerEditModalTabPane0_fdxRemittanceDelayLabel"
                                                            for="fdxRemittance"
                                                            ><b>FDX Remittance Delay</b>
                                                            <mdb-tooltip
                                                                material
                                                                trigger="hover"
                                                                :options="{ placement: 'right' }"
                                                            >
                                                                <span slot="tip"
                                                                    >By default FedEx remittance (sent weekly) contains
                                                                    invoice totals for three week old invoices.</span
                                                                >
                                                                <mdb-icon
                                                                    slot="reference"
                                                                    class="pl-1"
                                                                    icon="question-circle"
                                                                    color="primary"
                                                                    size="sm"
                                                                ></mdb-icon>
                                                            </mdb-tooltip>
                                                        </label>
                                                        <select
                                                            data-test="customerEditModalTabPane0_fdxRemittanceDelaySelect"
                                                            class="browser-default custom-select"
                                                            id="fdxRemittance"
                                                            v-model="fdxRemittanceDelay"
                                                        >
                                                            <option
                                                                data-test="customerEditModalTabPane0_3weekDelayOption"
                                                                value="3"
                                                            >
                                                                Three Week Delay
                                                            </option>
                                                            <option
                                                                data-test="customerEditModalTabPane0_2weekDelayOption"
                                                                value="2"
                                                                disabled
                                                            >
                                                                Two Week Delay
                                                            </option>
                                                            <option
                                                                data-test="customerEditModalTabPane0_1weekDelayOption"
                                                                value="1"
                                                                disabled
                                                            >
                                                                One Week Delay
                                                            </option>
                                                        </select>
                                                    </mdb-col>
                                                </mdb-row>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="mt-4">
                                    <mdb-col col="4">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillingFirstNameLabel"
                                            for="billingFirstName"
                                            ><b>First Name</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingFirstName"
                                            type="text"
                                            class="form-control"
                                            id="billingFirstName"
                                            v-model="custInfo.cmp_billing_first_name"
                                        />
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillingLastNameLabel"
                                            for="billingLastName"
                                            ><b>Last Name</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingLastName"
                                            type="text"
                                            class="form-control"
                                            id="billingLastName"
                                            v-model="custInfo.cmp_billing_last_name"
                                        />
                                    </mdb-col>
                                    <mdb-col col="3">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillCustrefLabel"
                                            for="invoiceRef"
                                            ><b>Invoice Ref/PO</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillCustref"
                                            type="text"
                                            class="form-control"
                                            id="invoiceRef"
                                            placeholder="ex: PO# 47383478943"
                                            v-model="custInfo.cmp_bill_custref"
                                        />
                                        <small data-test="customerEditModalTabPane0_cmpBillCustrefSmall"
                                            >Optional: Displayed on Invoice</small
                                        >
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="mt-4">
                                    <mdb-col col="3">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillingPhoneLabel"
                                            for="billingPhone"
                                            ><b>Billing Phone</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingPhone"
                                            type="text"
                                            class="form-control"
                                            id="billingPhone"
                                            v-model="custInfo.cmp_billing_phone"
                                        />
                                    </mdb-col>
                                    <mdb-col col="1">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillingPhoneExtLabel"
                                            for="billingExt"
                                            ><b>Ext</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingPhoneExt"
                                            type="text"
                                            class="form-control"
                                            id="billingExt"
                                            v-model="custInfo.cmp_billing_phone_ext"
                                        />
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillingEmailLabel"
                                            for="billingEmail"
                                            ><b>Primary Billing Email</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingEmail"
                                            type="text"
                                            class="form-control"
                                            id="billingEmail"
                                            v-model="custInfo.cmp_billing_email"
                                        />
                                    </mdb-col>
                                    <mdb-col col="3">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillingEmailccLabel"
                                            for="billingEmailCC"
                                            ><b>Billing Email CC</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingEmailcc"
                                            type="text"
                                            class="form-control"
                                            id="billingEmailCC"
                                            placeholder="Company Billing CC Address"
                                            v-model="custInfo.cmp_billing_email_cc"
                                        />
                                        <small data-test="customerEditModalTabPane0_cmpBillingEmailccNote"
                                            >Optional: Billing email CC</small
                                        >
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="mt-4">
                                    <mdb-col col="12">
                                        <h4 data-test="customerEditModalTabPane0_billingAddressHeader">
                                            Billing Address
                                        </h4>
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="mt-4">
                                    <mdb-col col="4">
                                        <label data-test="customerEditModalTabPane0_cmpBillingAddr1Label" for="address1"
                                            ><b>Address</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingAddr1"
                                            type="text"
                                            class="form-control"
                                            id="address1"
                                            v-model="custInfo.cmp_billing_addr1"
                                        />
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <label data-test="customerEditModalTabPane0_cmpBillingAddr2Label" for="address2"
                                            ><b>Address 2</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingAddr2"
                                            type="text"
                                            class="form-control"
                                            id="address2"
                                            v-model="custInfo.cmp_billing_addr2"
                                        />
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="mt-4">
                                    <mdb-col col="4">
                                        <label data-test="customerEditModalTabPane0_cmpBillingCityLabel" for="city"
                                            ><b>City</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingCity"
                                            type="text"
                                            class="form-control"
                                            id="city"
                                            v-model="custInfo.cmp_billing_city"
                                        />
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillingStateLabel"
                                            for="state/providence"
                                            ><b>State/Providence</b></label
                                        >
                                        <select
                                            data-test="customerEditModalTabPane0_cmpBillingState"
                                            class="browser-default custom-select"
                                            id="state/providence"
                                            v-model="custInfo.cmp_billing_state"
                                        >
                                            <option
                                                data-test="customerEditModalTabPane0_cmpBillingStateOptions"
                                                v-for="(state, index) of states"
                                                v-bind:key="index"
                                                :value="state.value"
                                            >
                                                {{ state.text }}
                                            </option>
                                        </select>
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="my-4">
                                    <mdb-col col="4">
                                        <label data-test="customerEditModalTabPane0_cmpBillingZipLabel" for="postalCode"
                                            ><b>Postal Code</b></label
                                        >
                                        <input
                                            data-test="customerEditModalTabPane0_cmpBillingZip"
                                            type="text"
                                            class="form-control"
                                            id="postalCode"
                                            v-model="custInfo.cmp_billing_zip"
                                        />
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <label
                                            data-test="customerEditModalTabPane0_cmpBillingCountryLabel"
                                            for="country"
                                            ><b>Country</b></label
                                        >
                                        <select
                                            data-test="customerEditModalTabPane0_cmpBillingCountry"
                                            id="country"
                                            class="browser-default custom-select"
                                            v-model="custInfo.cmp_billing_country"
                                        >
                                            <option
                                                data-test="customerEditModalTabPane0_cmpBillingCountry_unitedStatesOption"
                                                value="us"
                                            >
                                                United States
                                            </option>
                                            <option
                                                data-test="customerEditModalTabPane0_cmpBillingCountry_canadaOption"
                                                value="ca"
                                            >
                                                Canada
                                            </option>
                                        </select>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-tab-pane>
                            <mdb-tab-pane
                                data-test="customerEditModalTabPane1"
                                class="fade"
                                key="show2"
                                v-if="active == 1"
                            >
                                <mdb-accordion
                                    data-test="customerEditModalTabPane1_accordion"
                                    table
                                    v-if="custAddons"
                                    class="mx-2"
                                    :style="{ height: custAddons.length * 100 + 'px' }"
                                >
                                    <mdb-accordion-pane
                                        data-test="customerEditModalTabPane1_accordionPane"
                                        v-for="(addon, index) of custAddons"
                                        v-bind:key="index"
                                        :title="addon.cps_description"
                                        type="table"
                                        :isOpen="openPaneNum == index"
                                        @pane-clicked="handlePaneOpened(index)"
                                        class="mb-0"
                                    >
                                        <div class="mx-2">
                                            <mdb-row class="mt-4">
                                                <mdb-col col="12">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            data-test="customerEditModalTabPane1_cpsIsActive"
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            :id="'addon' + index"
                                                            v-model="addon.cps_is_active"
                                                        />
                                                        <label
                                                            data-test="customerEditModalTabPane1_cpsIsActiveLabel"
                                                            class="custom-control-label"
                                                            :for="'addon' + index"
                                                            >Activate</label
                                                        >
                                                    </div>
                                                </mdb-col>
                                            </mdb-row>
                                            <mdb-row class="mt-4">
                                                <mdb-col col="8">
                                                    <label
                                                        data-test="customerEditModalTabPane1_cpsBillingTypeLabel"
                                                        :for="'addonsCustBilling' + index"
                                                        ><b>Customer Billing Type</b></label
                                                    >
                                                    <select
                                                        data-test="customerEditModalTabPane1_cpsBillingType"
                                                        :id="'addonsCustBilling' + index"
                                                        class="browser-default custom-select"
                                                        v-model="addon.cps_billing_type"
                                                        :disabled="!addon.cps_is_active"
                                                        @change="changeAddonBillingType(addon)"
                                                    >
                                                        <option
                                                            data-test="customerEditModalTabPane1_cpsBillingType_flatfeeOption"
                                                            value="flatfee"
                                                        >
                                                            Flat Fee
                                                        </option>
                                                        <option
                                                            data-test="customerEditModalTabPane1_cpsBillingType_transactionalOption"
                                                            value="transactional"
                                                        >
                                                            Transactional
                                                        </option>
                                                        <option
                                                            data-test="customerEditModalTabPane1_cpsBillingType_gainshareOption"
                                                            value="gainshare"
                                                        >
                                                            Gain Share
                                                        </option>
                                                    </select>
                                                </mdb-col>
                                                <mdb-col col="4">
                                                    <label
                                                        data-test="customerEditModalTabPane1_cpsAmountLabel"
                                                        :for="'addonsCustValue' + index"
                                                        ><b>Customer Value</b>
                                                        <mdb-tooltip
                                                            data-test="customerEditModalTabPane1_cpsAmountLabel_tooltip"
                                                            material
                                                            trigger="hover"
                                                            :options="{ placement: 'right' }"
                                                        >
                                                            <span slot="tip"
                                                                >Percentage of total audit credits customer will be
                                                                invoiced for.</span
                                                            >
                                                            <mdb-icon
                                                                slot="reference"
                                                                class="pl-1"
                                                                icon="question-circle"
                                                                color="primary"
                                                                size="sm"
                                                            ></mdb-icon>
                                                        </mdb-tooltip>
                                                    </label>
                                                    <div class="input-group">
                                                        <input
                                                            data-test="customerEditModalTabPane1_cpsAmount"
                                                            type="text"
                                                            class="form-control"
                                                            :id="'addonsCustValue' + index"
                                                            v-model="addon.cps_amount"
                                                            :disabled="!addon.cps_is_active"
                                                        />
                                                        <div
                                                            data-test="customerEditModalTabPane1_cpsAmountAppend"
                                                            class="input-group-append"
                                                        >
                                                            <span class="input-group-text">{{ addon.valueType }} </span>
                                                        </div>
                                                    </div>
                                                </mdb-col>
                                            </mdb-row>
                                            <mdb-row class="my-4">
                                                <mdb-col col="8">
                                                    <label
                                                        data-test="customerEditModalTabPane1_cpsWholeSaleBillingTypeLabel"
                                                        :for="'addonsWholesaleBilling' + index"
                                                        ><b>Wholesale Billing Type</b></label
                                                    >
                                                    <select
                                                        data-test="customerEditModalTabPane1_cpsWholeSaleBillingType"
                                                        :id="'addonsWholesaleBilling' + index"
                                                        class="browser-default custom-select"
                                                        v-model="addon.cps_whole_sale_billing_type"
                                                        :disabled="!addon.cps_is_active"
                                                        @change="changeAddonBillingType(addon)"
                                                    >
                                                        <option
                                                            data-test="customerEditModalTabPane1_cpsWholeSaleBillingType_flatfeeOption"
                                                            value="flatfee"
                                                        >
                                                            Flat Fee
                                                        </option>
                                                        <option
                                                            data-test="customerEditModalTabPane1_cpsWholeSaleBillingType_transactionalOption"
                                                            value="transactional"
                                                        >
                                                            Transactional
                                                        </option>
                                                        <option
                                                            data-test="customerEditModalTabPane1_cpsWholeSaleBillingType_gainshareOption"
                                                            value="gainshare"
                                                        >
                                                            Gain Share
                                                        </option>
                                                    </select>
                                                </mdb-col>
                                                <mdb-col col="4">
                                                    <label
                                                        data-test="customerEditModalTabPane1_cpsWholeSaleAmountLabel"
                                                        :for="'addonsWholesaleValue' + index"
                                                        ><b>Customer Value</b>
                                                        <mdb-tooltip
                                                            data-test="customerEditModalTabPane1_cpsWholeSaleAmount_tooltip"
                                                            material
                                                            trigger="hover"
                                                            :options="{ placement: 'right' }"
                                                        >
                                                            <span slot="tip"
                                                                >Percentage of total audit credits that is eAudit
                                                                share.</span
                                                            >
                                                            <mdb-icon
                                                                slot="reference"
                                                                class="pl-1"
                                                                icon="question-circle"
                                                                color="primary"
                                                                size="sm"
                                                            ></mdb-icon>
                                                        </mdb-tooltip>
                                                    </label>
                                                    <div class="input-group">
                                                        <input
                                                            data-test="customerEditModalTabPane1_cpsWholeSaleAmount_input"
                                                            type="text"
                                                            class="form-control"
                                                            :id="'addonsWholesaleValue' + index"
                                                            v-model="addon.cps_whole_sale_amount"
                                                            :disabled="!addon.cps_is_active"
                                                        />
                                                        <div
                                                            data-test="customerEditModalTabPane1_cpsWholeSaleAmount_append"
                                                            class="input-group-append"
                                                        >
                                                            <span class="input-group-text">
                                                                {{ addon.valueType }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </mdb-col>
                                            </mdb-row>
                                        </div>
                                    </mdb-accordion-pane>
                                </mdb-accordion>
                            </mdb-tab-pane>
                            <mdb-tab-pane
                                data-test="customerEditModalTabPane2"
                                class="fade"
                                key="show3"
                                v-if="active == 2"
                            >
                                <mdb-card data-test="customerEditModalTabPane2_spentStatsCard">
                                    <mdb-card-header
                                        data-test="customerEditModalTabPane2_spentStatsCardHeader"
                                        class="text-success"
                                        color="green lighten-4"
                                        >Spent Stats
                                    </mdb-card-header>
                                    <mdb-card-body>
                                        <vue-good-table
                                            data-test="customerEditModalTabPane2_spentStats_vue-good-table"
                                            :columns="spentColumns"
                                            :rows="spentRows"
                                            max-height="200px"
                                            :fixed-header="true"
                                            styleClass="vgt-table striped"
                                        >
                                        </vue-good-table>
                                    </mdb-card-body>
                                </mdb-card>
                                <mdb-card data-test="customerEditModalTabPane2_scheduledReportCard">
                                    <mdb-card-header
                                        data-test="customerEditModalTabPane2_scheduledReportCardHeader"
                                        class="text-success"
                                        color="green lighten-4"
                                        >Scheduled Report
                                    </mdb-card-header>
                                    <mdb-card-body>
                                        <vue-good-table
                                            data-test="customerEditModalTabPane2_scheduledReportCard_vue-good-table"
                                            :columns="scheduledColumns"
                                            :rows="scheduledRows"
                                            max-height="200px"
                                            :fixed-header="true"
                                            styleClass="vgt-table striped"
                                        >
                                        </vue-good-table>
                                    </mdb-card-body>
                                </mdb-card>
                                <mdb-card data-test="customerEditModalTabPane2_loginActivitiesCard">
                                    <mdb-card-header
                                        data-test="customerEditModalTabPane2_loginActivitiesCardHeader"
                                        class="text-success"
                                        color="green lighten-4"
                                        >Login Activities
                                    </mdb-card-header>
                                    <mdb-card-body>
                                        <vue-good-table
                                            data-test="customerEditModalTabPane2_loginActivitiesCard_vue-good-table"
                                            :columns="loginColumns"
                                            :rows="loginRows"
                                            max-height="200px"
                                            :fixed-header="true"
                                            styleClass="vgt-table striped"
                                        >
                                        </vue-good-table>
                                    </mdb-card-body>
                                </mdb-card>
                            </mdb-tab-pane>
                            <mdb-tab-pane
                                data-test="customerEditModalTabPane3"
                                class="fade"
                                key="show3"
                                v-if="active == 3"
                            >
                                <mdb-card data-test="customerEditModalTabPane3_usersCard">
                                    <mdb-card-header
                                        data-test="customerEditModalTabPane3_usersCardHeader"
                                        class="text-success"
                                        color="green lighten-4"
                                        >Users
                                    </mdb-card-header>
                                    <mdb-card-body>
                                        <vue-good-table
                                            data-test="customerEditModalTabPane3_usersCard_vue-good-table"
                                            :columns="adminUserProfileColumns"
                                            :rows="adminUserProfileRows"
                                            :fixed-header="true"
                                            :search-options="{
                                                enabled: true,
                                            }"
                                            styleClass="vgt-table striped"
                                        >
                                            <template slot="table-row" slot-scope="props">
                                                <span v-if="props.column.field == 'usr_name'">
                                                    <mdb-icon icon="user" color="primary" class="mr-2"></mdb-icon
                                                    >{{ props.row.usr_name }}
                                                </span>
                                                <span v-else-if="props.column.field == 'viewDetails'">
                                                    <router-link
                                                        :to="'/admin/manageusers?usr_email=' + props.row.usr_email"
                                                        class="text-muted"
                                                        >View Details</router-link
                                                    >
                                                </span>
                                                <span v-else>
                                                    {{ props.formattedRow[props.column.field] }}
                                                </span>
                                            </template>
                                        </vue-good-table>
                                    </mdb-card-body>
                                </mdb-card>
                            </mdb-tab-pane>
                        </mdb-tab-content>
                    </mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn
                            data-test="customerEditModal_closeBtn"
                            outline="primary"
                            @click.native="customerEditModal = false"
                            >Close
                        </mdb-btn>
                        <mdb-btn
                            data-test="customerEditModal_saveChangesBtn"
                            v-if="active == 0 || active == 1"
                            color="primary"
                            @click.native="saveChanges"
                            >Save
                            <mdb-icon icon="save" class="ml-2"></mdb-icon>
                        </mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>
                <CarrierCredentials
                    @push-to-credentials-info="pushToCredentialsInfo"
                    @close-credentials-modal="credentialsModal = false"
                    :selectedRow="selectedRow"
                    :credentialsInfo="credentialsInfo"
                    :resellerFtpCredentials="resellerFtpCredentials"
                    :companyFtpCredentials="companyFtpCredentials"
                    :credsInfo="credsInfo"
                    :credentialsModal="credentialsModal"
                    :headers="headers"
                    :onboardingEndpoints="false"
                    class="ml-1"
                />
                <mdb-modal
                    data-test="accountsModal"
                    v-if="selectedRow"
                    size="fluid"
                    :show="accountsModal"
                    @close="accountsModal = false"
                >
                    <mdb-modal-header>
                        <mdb-modal-title data-test="accountsModalTitle">
                            <mdb-icon icon="truck" class="mr-2" size="lg"></mdb-icon>
                            Accounts |
                            {{ selectedRow.cmp_name }}
                        </mdb-modal-title>
                    </mdb-modal-header>
                    <mdb-modal-body>
                        <mdb-row>
                            <mdb-col col="4">
                                <mdb-btn
                                    data-test="accountsModal_carrierAccountsAddBtn"
                                    color="info"
                                    @click.native="carrierAccountsAdd"
                                    >Add Account
                                    <mdb-icon icon="plus" class="pl-2"></mdb-icon>
                                </mdb-btn>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row v-if="carrierAccounts.length">
                            <mdb-col col="12">
                                <mdb-tbl data-test="accountsModal_carrierAccountsTable">
                                    <mdb-tbl-head>
                                        <tr data-test="accountsModal_carrierAccountsTableHead">
                                            <th data-test="accountsModal_carrierAccountsTableHead_accountNumber">
                                                Account Number
                                            </th>
                                            <th data-test="accountsModal_carrierAccountsTableHead_credential">
                                                Credential
                                            </th>
                                            <th data-test="accountsModal_carrierAccountsTableHead_mnsDelay">
                                                MNS Delay
                                            </th>
                                            <th data-test="accountsModal_carrierAccountsTableHead_npodDelay">
                                                NPOD Delay
                                            </th>
                                            <th data-test="accountsModal_carrierAccountsTableHead_noAudit">No Audit</th>
                                            <th data-test="accountsModal_carrierAccountsTableHead_gsrWaiver">
                                                GSR Waiver
                                            </th>
                                            <th data-test="accountsModal_carrierAccountsTableHead_nickname">
                                                Nickname
                                            </th>
                                            <th data-test="accountsModal_carrierAccountsTableHead_GLCode">GLCode</th>
                                            <th data-test="accountsModal_carrierAccountsTableHead_GLCode2">GLCode2</th>
                                            <th></th>
                                        </tr>
                                        <tr
                                            data-test="accountsModal_carrierAccountsTable_carrierRow"
                                            v-for="(carrier, index) of carrierAccounts"
                                            v-bind:key="index"
                                        >
                                            <td data-test="accountsModal_carrierAccountsTable_acctNumberRow">
                                                <div class="form-group">
                                                    <input
                                                        data-test="accountsModal_carrierAccountsTable_acctNumberInput"
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Accounts Number"
                                                        v-model="carrier.acct_number"
                                                    />
                                                </div>
                                            </td>
                                            <td data-test="accountsModal_carrierAccountsTable_acctCredentialIdRow">
                                                <select
                                                    data-test="accountsModal_carrierAccountsTable_acctCredentialIdSelect"
                                                    :id="'carrierAccountsCred' + index"
                                                    class="browser-default custom-select"
                                                    v-model="carrier.acct_credential_id"
                                                >
                                                    <option
                                                        data-test="accountsModal_carrierAccountsTable_acctCredentialIdSelect_selectCredentialOption"
                                                        disabled
                                                        value="0"
                                                    >
                                                        Select Credential
                                                    </option>
                                                    <option
                                                        data-test="accountsModal_carrierAccountsTable_acctCredentialIdSelectOption"
                                                        v-for="cred of carrierAccountsInfo"
                                                        v-bind:key="cred.credential_id"
                                                        :value="cred.credential_id"
                                                    >
                                                        {{ cred.credential_username }} (
                                                        {{ cred.credential_car_id }} Cred ID: {{ cred.credential_id }})
                                                    </option>
                                                </select>
                                            </td>
                                            <td data-test="accountsModal_carrierAccountsTable_acctMnsDelayDaysRow">
                                                <div class="form-group">
                                                    <input
                                                        data-test="accountsModal_carrierAccountsTable_acctMnsDelayDaysInput"
                                                        :id="'carrierAccountsMNS' + index"
                                                        type="text"
                                                        class="form-control"
                                                        v-model="carrier.acct_mns_delay_days"
                                                    />
                                                </div>
                                            </td>
                                            <td data-test="accountsModal_carrierAccountsTable_acctNpodDelayDaysRow">
                                                <div class="form-group">
                                                    <input
                                                        data-test="accountsModal_carrierAccountsTable_acctNpodDelayDaysInput"
                                                        :id="'carrierAccountsNPOD' + index"
                                                        type="text"
                                                        class="form-control"
                                                        v-model="carrier.acct_npod_delay_days"
                                                    />
                                                </div>
                                            </td>
                                            <td data-test="accountsModal_carrierAccountsTable_acctNoAuditRow">
                                                <div class="custom-control custom-checkbox checkbox-xl">
                                                    <input
                                                        data-test="accountsModal_carrierAccountsTable_acctNoAuditInput"
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        :id="'carrierAccountsNoAudit' + index"
                                                        v-model="carrier.acct_no_audit"
                                                    />
                                                    <label
                                                        data-test="accountsModal_carrierAccountsTable_acctNoAuditLabel"
                                                        class="custom-control-label"
                                                        :for="'carrierAccountsNoAudit' + index"
                                                    ></label>
                                                </div>
                                            </td>
                                            <td data-test="accountsModal_carrierAccountsTable_acctIsGsrWaiverRow">
                                                <div class="custom-control custom-checkbox checkbox-xl">
                                                    <input
                                                        data-test="accountsModal_carrierAccountsTable_acctIsGsrWaiverInput"
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        :id="'carrierAccountsGSR' + index"
                                                        v-model="carrier.acct_is_gsr_waiver"
                                                    />
                                                    <label
                                                        data-test="accountsModal_carrierAccountsTable_acctIsGsrWaiverLabel"
                                                        class="custom-control-label"
                                                        :for="'carrierAccountsGSR' + index"
                                                    ></label>
                                                </div>
                                            </td>
                                            <td data-test="accountsModal_carrierAccountsTable_acctNameRow">
                                                <div class="form-group">
                                                    <input
                                                        data-test="accountsModal_carrierAccountsTable_acctNameInput"
                                                        :id="'carrierAccountsNickname' + index"
                                                        type="text"
                                                        class="form-control"
                                                        v-model="carrier.acct_name"
                                                        placeholder="Account Nickname"
                                                    />
                                                </div>
                                            </td>
                                            <td data-test="accountsModal_carrierAccountsTable_acctGlcodeRow">
                                                <div class="form-group">
                                                    <input
                                                        data-test="accountsModal_carrierAccountsTable_acctGlcodeInput"
                                                        :id="'carrierAccountsGLCode' + index"
                                                        type="text"
                                                        class="form-control"
                                                        v-model="carrier.acct_glcode"
                                                        placeholder="GLCode"
                                                    />
                                                </div>
                                            </td>
                                            <td data-test="accountsModal_carrierAccountsTable_acctGlcode2Row">
                                                <div class="form-group">
                                                    <input
                                                        data-test="accountsModal_carrierAccountsTable_acctGlcode2Input"
                                                        :id="'carrierAccountsGLCode2' + index"
                                                        type="text"
                                                        class="form-control"
                                                        v-model="carrier.acct_glcode2"
                                                        placeholder="GLCode2"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </mdb-tbl-head>
                                </mdb-tbl>
                            </mdb-col>
                        </mdb-row>
                    </mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn
                            data-test="accountsModal_closeBtn"
                            outline="primary"
                            @click.native="accountsModal = false"
                            >Close
                        </mdb-btn>
                        <mdb-btn data-test="accountsModal_saveAccountsBtn" color="primary" @click.native="saveAccounts"
                            >Save
                            <mdb-icon icon="save" class="pl-2"></mdb-icon>
                        </mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>
                <mdb-modal
                    data-test="showActivateModal"
                    size="lg"
                    :show="showActivateModal"
                    @close="showActivateModal = false"
                >
                    <mdb-modal-header>
                        <mdb-modal-title data-test="showActivateModalTitle">Activate/Deactivate</mdb-modal-title>
                    </mdb-modal-header>
                    <mdb-modal-body data-test="showActivateModalBody">
                        <p>Are you sure that you want to enable (or disable) this customer?</p>
                    </mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn
                            data-test="showActivateModalCancelBtn"
                            outline="primary"
                            @click.native="showActivateModal = false"
                            >Cancel
                        </mdb-btn>
                        <mdb-btn
                            data-test="showActivateModalActivateAccountBtn"
                            color="primary"
                            @click.native="activateAccount"
                            >OK
                        </mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>
            </div>
        </div>
    </div>
</template>
<script>
//TODO test View Details in Edit Customers -> Users and Edit Carrier Accounts delete functionality
import moment from "moment";
import auth from "../../lib/auth";
import {
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbBtnGroup,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbSpinner,
    mdbCardBody,
    mdbTooltip,
    mdbCard,
    mdbTabItem,
    mdbTbl,
    mdbTblHead,
    mdbTab,
    mdbCardHeader,
    mdbTabContent,
    mdbTabPane,
    mdbInput,
    mdbAccordion,
    mdbAccordionPane,
} from "mdbvue";
import { Carriers } from "../../data/carriers";
import CarrierCredentials from "@/components/CarrierCredentials.vue";

export default {
    name: "AdminCustomers",
    components: {
        CarrierCredentials,
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbBtnGroup,
        mdbBtn,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbSpinner,
        mdbCardBody,
        mdbTooltip,
        mdbCard,
        mdbTabItem,
        mdbTbl,
        mdbTblHead,
        mdbTab,
        mdbCardHeader,
        mdbTabContent,
        mdbTabPane,
        mdbInput,
        mdbAccordion,
        mdbAccordionPane,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            limit: 10,
            size: 10,
            page: 1,
            sortColumn: "cmp_id",
            sortkey: "cmp_id",
            sortDir: "desc",
            searchTerm: null,
            totalRecords: null,
            loading: false,
            resellerListCreated: null,
            tableCreated: null,
            customersRows: null,
            customersColumns: null,
            table: null,
            resellerList: null,
            resellerOptions: [],
            selectedRow: null,
            selectedReseller: "all resellers",
            liveSelected: false,
            trialSelected: false,
            mockSelected: false,
            downloadOnlySelected: false,
            activeSelected: false,
            inactiveSelected: false,
            rowModal: false,
            customerEditModal: false,
            active: 0,
            salespersonList: null,
            custInfo: null,
            salespersonOption: [],
            fdxRemittanceDelay: "3",
            custAddons: null,
            openPaneNum: 0,
            custScheduledReports: null,
            custLoginActivity: null,
            custSpentStats: null,
            loginRows: [],
            loginColumns: [],
            spentRows: [],
            spentColumns: [],
            scheduledRows: [],
            scheduledColumns: [],
            adminUserProfile: [],
            adminUserProfileRows: [],
            adminUserProfileColumns: [],
            panes: [],
            carrierCredentials: null,
            credentialsModal: false,
            credsInfo: null,
            generatedPassword: null,
            credentialsInfo: null,
            accountsModal: false,
            carrierAccounts: [],
            carrierAccountsInfo: null,
            showActivateModal: false,
            salespersonOptions: null,
            companyFtpCredentials: null,
            resellerFtpCredentials: null,
            isBusy: false,
            fedExType: [
                { text: "FDX Regular", value: "none" },
                { text: "FDX Credit Card", value: "cc" },
                { text: "FDX EDI", value: "edi" },
            ],
            upsType: [
                { text: "UPS Billing Center", value: "billing center" },
                { text: "UPS Supply Chain Solution", value: "billing center scs" },
                { text: "UPS EDI", value: "edi" },
                { text: "UPS Billing Data", value: "billing data" },
            ],
            dhlType: [{ text: "DHL Regular", value: "none" }],
            purolatorType: [{ text: "Purolator Regular", value: "none" }],
            dhlexType: [
                { text: "eCom FTP", value: "ftp" },
                { text: "eCom Portal", value: "portal" },
            ],
            credDownloadType: [
                { value: "website", text: "Website", selected: false },
                { value: "email", text: "Email", selected: false },
                { value: "dropbox", text: "Dropbox", selected: false },
                { value: "ftp", text: "FTP", selected: false },
                { value: "company ftp", text: "Company FTP", selected: false },
                { value: "reseller ftp", text: "Reseller FTP", selected: false },
            ],
            credentialsCountries: [
                { value: "us", text: "US", selected: false },
                { value: "ca", text: "Canada", selected: false },
                { value: "de", text: "Germany", selected: false },
            ],
            carriersText: [
                { value: 1, text: "Add FedEx Credential", selected: false },
                { value: 2, text: "Add UPS Credential", selected: false },
                { value: 3, text: "Add DHL Express Credential", selected: false },
                { value: 4, text: "Add Purolator Credential", selected: false },
                { value: 5, text: "Add DHL eCom Credential", selected: false },
            ],
            countries: [
                { text: "Afghanistan", value: "AF", selected: false },
                { text: "Aland Islands", value: "AX", selected: false },
                { text: "Albania", value: "AL", selected: false },
                { text: "Algeria", value: "DZ", selected: false },
                { text: "American Samoa", value: "AS", selected: false },
                { text: "AndorrA", value: "AD", selected: false },
                { text: "Angola", value: "AO", selected: false },
                { text: "Anguilla", value: "AI", selected: false },
                { text: "Antarctica", value: "AQ", selected: false },
                { text: "Antigua and Barbuda", value: "AG", selected: false },
                { text: "Argentina", value: "AR", selected: false },
                { text: "Armenia", value: "AM", selected: false },
                { text: "Aruba", value: "AW", selected: false },
                { text: "Australia", value: "AU", selected: false },
                { text: "Austria", value: "AT", selected: false },
                { text: "Azerbaijan", value: "AZ", selected: false },
                { text: "Bahamas", value: "BS", selected: false },
                { text: "Bahrain", value: "BH", selected: false },
                { text: "Bangladesh", value: "BD", selected: false },
                { text: "Barbados", value: "BB", selected: false },
                { text: "Belarus", value: "BY", selected: false },
                { text: "Belgium", value: "BE", selected: false },
                { text: "Belize", value: "BZ", selected: false },
                { text: "Benin", value: "BJ", selected: false },
                { text: "Bermuda", value: "BM", selected: false },
                { text: "Bhutan", value: "BT", selected: false },
                { text: "Bolivia", value: "BO", selected: false },
                { text: "Bosnia and Herzegovina", value: "BA", selected: false },
                { text: "Botswana", value: "BW", selected: false },
                { text: "Bouvet Island", value: "BV", selected: false },
                { text: "Brazil", value: "BR", selected: false },
                { text: "British Indian Ocean Territory", value: "IO", selected: false },
                { text: "Brunei Darussalam", value: "BN", selected: false },
                { text: "Bulgaria", value: "BG", selected: false },
                { text: "Burkina Faso", value: "BF", selected: false },
                { text: "Burundi", value: "BI", selected: false },
                { text: "Cambodia", value: "KH", selected: false },
                { text: "Cameroon", value: "CM", selected: false },
                { text: "Canada", value: "CA", selected: false },
                { text: "Cape Verde", value: "CV", selected: false },
                { text: "Cayman Islands", value: "KY", selected: false },
                { text: "Central African Republic", value: "CF", selected: false },
                { text: "Chad", value: "TD", selected: false },
                { text: "Chile", value: "CL", selected: false },
                { text: "China", value: "CN", selected: false },
                { text: "Christmas Island", value: "CX", selected: false },
                { text: "Cocos (Keeling) Islands", value: "CC", selected: false },
                { text: "Colombia", value: "CO", selected: false },
                { text: "Comoros", value: "KM", selected: false },
                { text: "Congo", value: "CG", selected: false },
                { text: "Congo, The Democratic Republic of the", value: "CD", selected: false },
                { text: "Cook Islands", value: "CK", selected: false },
                { text: "Costa Rica", value: "CR", selected: false },
                { text: "Cote D'Ivoire", value: "CI", selected: false },
                { text: "Croatia", value: "HR", selected: false },
                { text: "Cuba", value: "CU", selected: false },
                { text: "Cyprus", value: "CY", selected: false },
                { text: "Czech Republic", value: "CZ", selected: false },
                { text: "Denmark", value: "DK", selected: false },
                { text: "Djibouti", value: "DJ", selected: false },
                { text: "Dominica", value: "DM", selected: false },
                { text: "Dominican Republic", value: "DO", selected: false },
                { text: "Ecuador", value: "EC", selected: false },
                { text: "Egypt", value: "EG", selected: false },
                { text: "El Salvador", value: "SV", selected: false },
                { text: "Equatorial Guinea", value: "GQ", selected: false },
                { text: "Eritrea", value: "ER", selected: false },
                { text: "Estonia", value: "EE", selected: false },
                { text: "Ethiopia", value: "ET", selected: false },
                { text: "Falkland Islands (Malvinas)", value: "FK", selected: false },
                { text: "Faroe Islands", value: "FO", selected: false },
                { text: "Fiji", value: "FJ", selected: false },
                { text: "Finland", value: "FI", selected: false },
                { text: "France", value: "FR", selected: false },
                { text: "French Guiana", value: "GF", selected: false },
                { text: "French Polynesia", value: "PF", selected: false },
                { text: "French Southern Territories", value: "TF", selected: false },
                { text: "Gabon", value: "GA", selected: false },
                { text: "Gambia", value: "GM", selected: false },
                { text: "Georgia", value: "GE", selected: false },
                { text: "Germany", value: "DE", selected: false },
                { text: "Ghana", value: "GH", selected: false },
                { text: "Gibraltar", value: "GI", selected: false },
                { text: "Greece", value: "GR", selected: false },
                { text: "Greenland", value: "GL", selected: false },
                { text: "Grenada", value: "GD", selected: false },
                { text: "Guadeloupe", value: "GP", selected: false },
                { text: "Guam", value: "GU", selected: false },
                { text: "Guatemala", value: "GT", selected: false },
                { text: "Guernsey", value: "GG", selected: false },
                { text: "Guinea", value: "GN", selected: false },
                { text: "Guinea-Bissau", value: "GW", selected: false },
                { text: "Guyana", value: "GY", selected: false },
                { text: "Haiti", value: "HT", selected: false },
                { text: "Heard Island and Mcdonald Islands", value: "HM", selected: false },
                { text: "Holy See (Vatican City State)", value: "VA", selected: false },
                { text: "Honduras", value: "HN", selected: false },
                { text: "Hong Kong", value: "HK", selected: false },
                { text: "Hungary", value: "HU", selected: false },
                { text: "Iceland", value: "IS", selected: false },
                { text: "India", value: "IN", selected: false },
                { text: "Indonesia", value: "ID", selected: false },
                { text: "Iran, Islamic Republic Of", value: "IR", selected: false },
                { text: "Iraq", value: "IQ", selected: false },
                { text: "Ireland", value: "IE", selected: false },
                { text: "Isle of Man", value: "IM", selected: false },
                { text: "Israel", value: "IL", selected: false },
                { text: "Italy", value: "IT", selected: false },
                { text: "Jamaica", value: "JM", selected: false },
                { text: "Japan", value: "JP", selected: false },
                { text: "Jersey", value: "JE", selected: false },
                { text: "Jordan", value: "JO", selected: false },
                { text: "Kazakhstan", value: "KZ", selected: false },
                { text: "Kenya", value: "KE", selected: false },
                { text: "Kiribati", value: "KI", selected: false },
                { text: "Korea, Democratic People'S Republic of", value: "KP", selected: false },
                { text: "Korea, Republic of", value: "KR", selected: false },
                { text: "Kuwait", value: "KW", selected: false },
                { text: "Kyrgyzstan", value: "KG", selected: false },
                { text: "Lao People'S Democratic Republic", value: "LA", selected: false },
                { text: "Latvia", value: "LV", selected: false },
                { text: "Lebanon", value: "LB", selected: false },
                { text: "Lesotho", value: "LS", selected: false },
                { text: "Liberia", value: "LR", selected: false },
                { text: "Libyan Arab Jamahiriya", value: "LY", selected: false },
                { text: "Liechtenstein", value: "LI", selected: false },
                { text: "Lithuania", value: "LT", selected: false },
                { text: "Luxembourg", value: "LU", selected: false },
                { text: "Macao", value: "MO", selected: false },
                { text: "Macedonia, The Former Yugoslav Republic of", value: "MK", selected: false },
                { text: "Madagascar", value: "MG", selected: false },
                { text: "Malawi", value: "MW", selected: false },
                { text: "Malaysia", value: "MY", selected: false },
                { text: "Maldives", value: "MV", selected: false },
                { text: "Mali", value: "ML", selected: false },
                { text: "Malta", value: "MT", selected: false },
                { text: "Marshall Islands", value: "MH", selected: false },
                { text: "Martinique", value: "MQ", selected: false },
                { text: "Mauritania", value: "MR", selected: false },
                { text: "Mauritius", value: "MU", selected: false },
                { text: "Mayotte", value: "YT", selected: false },
                { text: "Mexico", value: "MX", selected: false },
                { text: "Micronesia, Federated States of", value: "FM", selected: false },
                { text: "Moldova, Republic of", value: "MD", selected: false },
                { text: "Monaco", value: "MC", selected: false },
                { text: "Mongolia", value: "MN", selected: false },
                { text: "Montserrat", value: "MS", selected: false },
                { text: "Morocco", value: "MA", selected: false },
                { text: "Mozambique", value: "MZ", selected: false },
                { text: "Myanmar", value: "MM", selected: false },
                { text: "Namibia", value: "NA", selected: false },
                { text: "Nauru", value: "NR", selected: false },
                { text: "Nepal", value: "NP", selected: false },
                { text: "Netherlands", value: "NL", selected: false },
                { text: "Netherlands Antilles", value: "AN", selected: false },
                { text: "New Caledonia", value: "NC", selected: false },
                { text: "New Zealand", value: "NZ", selected: false },
                { text: "Nicaragua", value: "NI", selected: false },
                { text: "Niger", value: "NE", selected: false },
                { text: "Nigeria", value: "NG", selected: false },
                { text: "Niue", value: "NU", selected: false },
                { text: "Norfolk Island", value: "NF", selected: false },
                { text: "Northern Mariana Islands", value: "MP", selected: false },
                { text: "Norway", value: "NO", selected: false },
                { text: "Oman", value: "OM", selected: false },
                { text: "Pakistan", value: "PK", selected: false },
                { text: "Palau", value: "PW", selected: false },
                { text: "Palestinian Territory, Occupied", value: "PS", selected: false },
                { text: "Panama", value: "PA", selected: false },
                { text: "Papua New Guinea", value: "PG", selected: false },
                { text: "Paraguay", value: "PY", selected: false },
                { text: "Peru", value: "PE", selected: false },
                { text: "Philippines", value: "PH", selected: false },
                { text: "Pitcairn", value: "PN", selected: false },
                { text: "Poland", value: "PL", selected: false },
                { text: "Portugal", value: "PT", selected: false },
                { text: "Puerto Rico", value: "PR", selected: false },
                { text: "Qatar", value: "QA", selected: false },
                { text: "Reunion", value: "RE", selected: false },
                { text: "Romania", value: "RO", selected: false },
                { text: "Russian Federation", value: "RU", selected: false },
                { text: "RWANDA", value: "RW", selected: false },
                { text: "Saint Helena", value: "SH", selected: false },
                { text: "Saint Kitts and Nevis", value: "KN", selected: false },
                { text: "Saint Lucia", value: "LC", selected: false },
                { text: "Saint Pierre and Miquelon", value: "PM", selected: false },
                { text: "Saint Vincent and the Grenadines", value: "VC", selected: false },
                { text: "Samoa", value: "WS", selected: false },
                { text: "San Marino", value: "SM", selected: false },
                { text: "Sao Tome and Principe", value: "ST", selected: false },
                { text: "Saudi Arabia", value: "SA", selected: false },
                { text: "Senegal", value: "SN", selected: false },
                { text: "Serbia and Montenegro", value: "CS", selected: false },
                { text: "Seychelles", value: "SC", selected: false },
                { text: "Sierra Leone", value: "SL", selected: false },
                { text: "Singapore", value: "SG", selected: false },
                { text: "Slovakia", value: "SK", selected: false },
                { text: "Slovenia", value: "SI", selected: false },
                { text: "Solomon Islands", value: "SB", selected: false },
                { text: "Somalia", value: "SO", selected: false },
                { text: "South Africa", value: "ZA", selected: false },
                { text: "South Georgia and the South Sandwich Islands", value: "GS", selected: false },
                { text: "Spain", value: "ES", selected: false },
                { text: "Sri Lanka", value: "LK", selected: false },
                { text: "Sudan", value: "SD", selected: false },
                { text: "Suriname", value: "SR", selected: false },
                { text: "Svalbard and Jan Mayen", value: "SJ", selected: false },
                { text: "Swaziland", value: "SZ", selected: false },
                { text: "Sweden", value: "SE", selected: false },
                { text: "Switzerland", value: "CH", selected: false },
                { text: "Syrian Arab Republic", value: "SY", selected: false },
                { text: "Taiwan, Province of China", value: "TW", selected: false },
                { text: "Tajikistan", value: "TJ", selected: false },
                { text: "Tanzania, United Republic of", value: "TZ", selected: false },
                { text: "Thailand", value: "TH", selected: false },
                { text: "Timor-Leste", value: "TL", selected: false },
                { text: "Togo", value: "TG", selected: false },
                { text: "Tokelau", value: "TK", selected: false },
                { text: "Tonga", value: "TO", selected: false },
                { text: "Trinidad and Tobago", value: "TT", selected: false },
                { text: "Tunisia", value: "TN", selected: false },
                { text: "Turkey", value: "TR", selected: false },
                { text: "Turkmenistan", value: "TM", selected: false },
                { text: "Turks and Caicos Islands", value: "TC", selected: false },
                { text: "Tuvalu", value: "TV", selected: false },
                { text: "Uganda", value: "UG", selected: false },
                { text: "Ukraine", value: "UA", selected: false },
                { text: "United Arab Emirates", value: "AE", selected: false },
                { text: "United Kingdom", value: "GB", selected: false },
                { text: "United States", value: "US", selected: false },
                { text: "United States Minor Outlying Islands", value: "UM", selected: false },
                { text: "Uruguay", value: "UY", selected: false },
                { text: "Uzbekistan", value: "UZ", selected: false },
                { text: "Vanuatu", value: "VU", selected: false },
                { text: "Venezuela", value: "VE", selected: false },
                { text: "Viet Nam", value: "VN", selected: false },
                { text: "Virgin Islands, British", value: "VG", selected: false },
                { text: "Virgin Islands, U.S.", value: "VI", selected: false },
                { text: "Wallis and Futuna", value: "WF", selected: false },
                { text: "Western Sahara", value: "EH", selected: false },
                { text: "Yemen", value: "YE", selected: false },
                { text: "Zambia", value: "ZM", selected: false },
                { text: "Zimbabwe", value: "ZW", selected: false },
            ],
            states: [
                { value: "AL", text: "Alabama", selected: false },
                { value: "AK", text: "Alaska", selected: false },
                { value: "AB", text: "Alberta", selected: false },
                { value: "AZ", text: "Arizona", selected: false },
                { value: "AR", text: "Arkansas", selected: false },
                { value: "BC", text: "British Columbia", selected: false },
                { value: "CA", text: "California", selected: false },
                { value: "CO", text: "Colorado", selected: false },
                { value: "CT", text: "Connecticut", selected: false },
                { value: "DE", text: "Delaware", selected: false },
                { value: "DC", text: "District of Columbia", selected: false },
                { value: "FL", text: "Florida", selected: false },
                { value: "GA", text: "Georgia", selected: false },
                { value: "HI", text: "Hawaii", selected: false },
                { value: "ID", text: "Idaho", selected: false },
                { value: "IL", text: "Illinois", selected: false },
                { value: "IN", text: "Indiana", selected: false },
                { value: "IA", text: "Iowa", selected: false },
                { value: "KS", text: "Kansas", selected: false },
                { value: "KY", text: "Kentucky", selected: false },
                { value: "LA", text: "Louisiana", selected: false },
                { value: "ME", text: "Maine", selected: false },
                { value: "MB", text: "Manitoba", selected: false },
                { value: "MD", text: "Maryland", selected: false },
                { value: "MA", text: "Massachusetts", selected: false },
                { value: "MI", text: "Michigan", selected: false },
                { value: "MN", text: "Minnesota", selected: false },
                { value: "MS", text: "Mississippi", selected: false },
                { value: "MO", text: "Missouri", selected: false },
                { value: "MT", text: "Montana", selected: false },
                { value: "NE", text: "Nebraska", selected: false },
                { value: "NV", text: "Nevada", selected: false },
                { value: "NB", text: "New Brunswick", selected: false },
                { value: "NH", text: "New Hampshire", selected: false },
                { value: "NJ", text: "New Jersey", selected: false },
                { value: "NM", text: "New Mexico", selected: false },
                { value: "NY", text: "New York", selected: false },
                { value: "NL", text: "Newfoundland and Labrador", selected: false },
                { value: "NC", text: "North Carolina", selected: false },
                { value: "ND", text: "North Dakota", selected: false },
                { value: "NT", text: "Northwest Territories", selected: false },
                { value: "NS", text: "Nova Scotia", selected: false },
                { value: "NU", text: "Nunavut", selected: false },
                { value: "OH", text: "Ohio", selected: false },
                { value: "OK", text: "Oklahoma", selected: false },
                { value: "ON", text: "Ontario", selected: false },
                { value: "OR", text: "Oregon", selected: false },
                { value: "PA", text: "Pennsylvania", selected: false },
                { value: "PE", text: "Prince Edward Island", selected: false },
                { value: "PR", text: "Puerto Rico", selected: false },
                { value: "QC", text: "Quebec", selected: false },
                { value: "RI", text: "Rhode Island", selected: false },
                { value: "SK", text: "Saskatchewan", selected: false },
                { value: "SC", text: "South Carolina", selected: false },
                { value: "SD", text: "South Dakota", selected: false },
                { value: "TN", text: "Tennessee", selected: false },
                { value: "TX", text: "Texas", selected: false },
                { value: "UT", text: "Utah", selected: false },
                { value: "VT", text: "Vermont", selected: false },
                { value: "VA", text: "Virginia", selected: false },
                { value: "WA", text: "Washington", selected: false },
                { value: "WV", text: "West Virginia", selected: false },
                { value: "WI", text: "Wisconsin", selected: false },
                { value: "WY", text: "Wyoming", selected: false },
                { value: "YT", text: "Yukon", selected: false },
            ],
        };
    },
    methods: {
        async getInit() {
            await this.getResellers();
            await this.createTable("create");
            await this.getCompanyFtpCredentials();
            await this.getResellerFtpCredentials();
        },
        async getResellers() {
            this.resellerOptions = [];
            await this.$httpAdmin
                .get("/reseller", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.resellerList = response.data;
                });
            for (let i = 0; i < this.resellerList.length; i++) {
                this.resellerOptions.push({
                    value: this.resellerList[i].id,
                    text: this.resellerList[i].name,
                    selected: false,
                });
            }
        },
        async searchTable(params) {
            this.searchTerm = params.searchTerm;
            await this.createTable("update");
        },
        async createTable(action) {
            this.isBusy = true;
            this.table = [];
            this.loading = true;
            await this.$httpAdmin
                .get("/admincompany", {
                    headers: this.headers,
                    params: this.getTableParams(action),
                })
                .then(async (response) => {
                    this.table = response.data[0].Items;
                    this.totalRecords = response.data[0].TotalRecords;
                });
            this.loading = false;
            for (let i = 0; i < this.table.length; i++) {
                let s = "0000" + this.table[i].cmp_id;
                this.table[i].cmp_id = s.substr(s.length - 4);
                this.table[i].cmp_billing_first_name =
                    this.table[i].cmp_billing_first_name + " " + this.table[i].cmp_billing_last_name;
                this.table[i].cmp_contact_first_name =
                    this.table[i].cmp_contact_first_name + " " + this.table[i].cmp_contact_last_name;
                this.table[i].usr_first_name =
                    (this.table[i].usr_first_name ? this.table[i].usr_first_name : "") +
                    " " +
                    (this.table[i].usr_last_name ? this.table[i].usr_last_name : "");
                if (!this.table[i].cmp_live_date) {
                    this.table[i].cmp_live_date = "";
                } else {
                    this.table[i].cmp_live_date = new moment(this.table[i].cmp_live_date).format("MMM DD, YYYY");
                }
            }
            this.customersRows = this.table;
            this.customersColumns = [
                { field: "cmp_id", label: "ID" },
                { field: "cmp_name", label: "Company" },
                { field: "reseller_name", label: "Partner" },
                { field: "cmp_billing_first_name", label: "Billing Contact" },
                { field: "cmp_contact_first_name", label: "Contact" },
                { field: "usr_first_name", label: "Sales Person" },
                { field: "cmp_live_date", label: "Live Date" },
                { field: "cmp_type", label: "Type" },
                { field: "cmp_is_active", label: "Status" },
            ];
            this.isBusy = false;
        },
        getTableParams(action) {
            let selectedType;
            let isActive;
            let cmp_name;
            if (this.searchTerm) {
                cmp_name = this.searchTerm;
            }
            if (action == "update") {
                if (this.liveSelected) {
                    selectedType = "live";
                }
                if (this.trialSelected) {
                    selectedType = "trial";
                }
                if (this.mockSelected) {
                    selectedType = "mock";
                }
                if (this.downloadOnlySelected) {
                    selectedType = "download";
                }
                if (this.activeSelected) {
                    isActive = "true";
                }
                if (this.inactiveSelected) {
                    isActive = "false";
                }
            }
            return {
                cmp_type: action == "update" ? (selectedType ? selectedType : "") : null,
                cmp_is_active: action == "update" ? (isActive ? isActive : "") : null,
                cmp_reseller_id:
                    action == "update"
                        ? this.selectedReseller == "all resellers"
                            ? 0
                            : this.selectedReseller.value
                        : null,
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                cmp_name: cmp_name ? cmp_name : null,
            };
        },
        onRowClick(params) {
            params.selected = true;
            this.selectedRow = params.row;
            this.active = 0;
            this.rowModal = true;
        },
        async changeLive() {
            this.liveSelected = !this.liveSelected;
            this.trialSelected = false;
            this.mockSelected = false;
            this.downloadOnlySelected = false;
            await this.createTable("update");
        },
        async changeTrial() {
            this.trialSelected = !this.trialSelected;
            this.liveSelected = false;
            this.mockSelected = false;
            this.downloadOnlySelected = false;
            await this.createTable("update");
        },
        async changeMock() {
            this.mockSelected = !this.mockSelected;
            this.liveSelected = false;
            this.trialSelected = false;
            this.downloadOnlySelected = false;
            await this.createTable("update");
        },
        async changeDownloadOnly() {
            this.downloadOnlySelected = !this.downloadOnlySelected;
            this.liveSelected = false;
            this.trialSelected = false;
            this.mockSelected = false;
            await this.createTable("update");
        },
        async changeActive() {
            this.activeSelected = !this.activeSelected;
            this.inactiveSelected = false;
            await this.createTable("update");
        },
        async changeInactive() {
            this.inactiveSelected = !this.inactiveSelected;
            this.activeSelected = false;
            await this.createTable("update");
        },
        async resellerChange() {
            await this.createTable("update");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.createTable("update");
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.createTable("update");
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.createTable("update");
        },
        async editCustomerModal() {
            this.isBusy = true;
            this.salespersonOptions = [];
            await this.$httpAdmin
                .get("/adminuserprofile/salesperson", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.salespersonList = response.data;
                });
            for (let i = 0; i < this.salespersonList.length; i++) {
                this.salespersonOptions.push({
                    value: this.salespersonList[i].usr_id,
                    text: this.salespersonList[i].usr_first_name + " " + this.salespersonList[i].usr_last_name,
                    selected: false,
                });
            }
            await this.$httpAdmin
                .get("/admincompany/" + this.selectedRow.cmp_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.custInfo = response.data;
                    this.custInfo.cmp_send_invoice == "0"
                        ? (this.custInfo.cmp_send_invoice = false)
                        : (this.custInfo.cmp_send_invoice = true);
                    this.custInfo.cmp_send_summary == "0"
                        ? (this.custInfo.cmp_send_summary = false)
                        : (this.custInfo.cmp_send_summary = true);
                });
            for (let i = 0; i < this.custInfo.length; i++) {
                this.custInfo[i].cmp_start_date = new moment(this.custInfo.cmp_start_date).format("MM/DD/YYYY");
            }
            this.isBusy = false;
            this.rowModal = false;
            this.customerEditModal = true;
        },
        async carrierCredentialsModal() {
            this.isBusy = true;
            this.rowModal = false;
            await this.$httpAdmin
                .get("/admincompanycredential", {
                    headers: this.headers,
                    params: {
                        sortColumn: "credential_is_active",
                        sortDir: "desc",
                        credential_cmp_id: this.selectedRow.cmp_id,
                    },
                })
                .then(async (response) => {
                    this.credentialsInfo = response.data;
                    for (let i = 0; i < this.credentialsInfo.length; i++) {
                        if (this.credentialsInfo[i].credential_no_audit == "0") {
                            this.credentialsInfo[i].credential_no_audit = false;
                        } else this.credentialsInfo[i].credential_no_audit = true;
                        if (this.credentialsInfo[i].credential_alias == "0") {
                            this.credentialsInfo[i].credential_alias = false;
                        } else this.credentialsInfo[i].credential_alias = true;
                        if (this.credentialsInfo[i].credential_is_account_discovery_disabled == "0") {
                            this.credentialsInfo[i].credential_is_account_discovery_disabled = false;
                        } else this.credentialsInfo[i].credential_is_account_discovery_disabled = true;
                        if (this.credentialsInfo[i].credential_is_active == "0") {
                            this.credentialsInfo[i].credential_is_active = false;
                        } else this.credentialsInfo[i].credential_is_active = true;
                        // the front end naming is opposite of the database so flip the truthiness
                        if (this.credentialsInfo[i].credential_is_not_correct == "1") {
                            this.credentialsInfo[i].credential_is_not_correct = false;
                        } else this.credentialsInfo[i].credential_is_not_correct = true;
                        let found = false;
                        for (let j = 0; j < this.fedExType.length; j++) {
                            if (this.credentialsInfo[i].credential_car_id == 1) {
                                this.credentialsInfo[i].type = "fedEx";
                                this.credentialsInfo[i].typeOptions = this.fedExType;
                                found = true;
                            }
                        }
                        if (!found) {
                            for (let j = 0; j < this.upsType.length; j++) {
                                if (this.credentialsInfo[i].credential_car_id == 2) {
                                    this.credentialsInfo[i].type = "ups";
                                    this.credentialsInfo[i].typeOptions = this.upsType;
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (let j = 0; j < this.dhlType.length; j++) {
                                if (this.credentialsInfo[i].credential_car_id == 3) {
                                    this.credentialsInfo[i].type = "dhl";
                                    this.credentialsInfo[i].typeOptions = this.dhlType;
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (let j = 0; j < this.purolatorType.length; j++) {
                                if (this.credentialsInfo[i].credential_car_id == 4) {
                                    this.credentialsInfo[i].type = "purolator";
                                    this.credentialsInfo[i].typeOptions = this.purolatorType;
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (let j = 0; j < this.dhlexType.length; j++) {
                                if (this.credentialsInfo[i].credential_car_id == 5) {
                                    this.credentialsInfo[i].type = "dhlex";
                                    this.credentialsInfo[i].typeOptions = this.dhlexType;
                                    found = true;
                                }
                            }
                        }
                    }
                });
            await this.$httpAdmin
                .get("/admincompany/" + this.selectedRow.cmp_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.credsInfo = response.data;
                });
            this.isBusy = false;
            this.credentialsModal = true;
        },
        async carrierAccountsModal() {
            this.isBusy = true;
            this.carrierAccountsInfo = [];
            this.rowModal = false;
            await this.$httpAdmin.get("/admincompany/" + this.selectedRow.cmp_id, {
                headers: this.headers,
            });
            await this.$httpAdmin
                .get(
                    "/admincompanycredential/?credential_is_active=true&credential_cmp_id=" + this.selectedRow.cmp_id,
                    {
                        headers: this.headers,
                    }
                )
                .then(async (response) => {
                    this.carrierAccountsInfo = response.data;
                    if (this.carrierAccountsInfo.length > 0) {
                        for (let i = 0; i < this.carrierAccountsInfo.length; i++) {
                            this.carrierAccountsInfo[i].credential_car_id =
                                Carriers[String(this.carrierAccountsInfo[i].credential_car_id)].name;
                        }
                    }
                });
            await this.$httpAdmin
                .get("/adminaccount/?acct_cmp_id=" + this.selectedRow.cmp_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.carrierAccounts = response.data;
                    for (let i = 0; i < this.carrierAccounts.length; i++) {
                        if (this.carrierAccounts[i].acct_is_gsr_waiver == 0) {
                            this.carrierAccounts[i].acct_is_gsr_waiver = false;
                        } else {
                            this.carrierAccounts[i].acct_is_gsr_waiver = true;
                        }
                        if (this.carrierAccounts[i].acct_no_audit == 0) {
                            this.carrierAccounts[i].acct_no_audit = false;
                        } else {
                            this.carrierAccounts[i].acct_no_audit = true;
                        }
                    }
                });
            this.isBusy = false;
            this.accountsModal = true;
        },
        activateModal() {
            this.rowModal = false;
            this.showActivateModal = true;
        },
        async activateAccount() {
            this.showActivateModal = false;
            let action = "";
            if (this.selectedRow.cmp_is_active == 1) {
                action = "deactivate";
            } else {
                action = "activate";
            }
            await this.$httpAdmin
                .put("/admincompany/" + this.selectedRow.cmp_id + "/" + action, "", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    let results = response.data;
                    if (!results) {
                        this.$notify.error({
                            message: "There was a problem while updating the company.",
                            title: "Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                    }
                });
            await this.createTable("update");
        },
        async loginAsModal() {
            if (parseInt(this.selectedRow.cmp_id)) {
                const user = await auth.loginAs(this.userInfo.adminPass, this.selectedRow.cmp_id);
                if (user) {
                    const adminPass = this.userInfo.adminPass;
                    user.data.usr_companies[0] = this.selectedRow.cmp_id;
                    user.adminpass = adminPass;
                    user.loginAs = true;
                    const updated = await auth.setSession(user);
                    this.$emit("userInfo", updated);
                    this.$emit("loginAs", true);
                    if (this.userInfo.usrLevel == "super_admin") {
                        if (this.$router.currentRoute.name === "Dashboard") {
                            this.$router.go(0);
                        } else {
                            this.$router.push({ name: "Dashboard" });
                            this.$router.go(1);
                        }
                    } else {
                        this.$router.go(0);
                    }
                }
            }
        },
        async saveCustomerChanges() {
            this.customerEditModal = false;
            await this.$httpAdmin.put("/admincompany/" + this.custInfo.cmp_id, await this.saveCustomerChangesParams(), {
                headers: this.headers,
            });
            this.table = [];
            this.customersRows = null;
            this.customersColumns = null;
            await this.createTable();
        },
        async saveCustomerChangesParams() {
            return {
                cmp_accounting_id: this.custInfo.cmp_accounting_id,
                cmp_reseller_id: this.custInfo.cmp_reseller_id,
                cmp_base_country: this.custInfo.cmp_base_country,
                cmp_user_profile_id_sales_person: this.custInfo.cmp_user_profile_id_sales_person,
                cmp_note: this.custInfo.cmp_note,
                cmp_bill_terms: this.custInfo.cmp_bill_terms,
                cmp_late_fee: this.custInfo.cmp_late_fee,
                cmp_contact_first_name: this.custInfo.cmp_contact_first_name,
                cmp_contact_last_name: this.custInfo.cmp_contact_last_name,
                cmp_contact_phone: this.custInfo.cmp_contact_phone,
                cmp_contact_phone_ext: this.custInfo.cmp_contact_phone_ext,
                cmp_contact_email: this.custInfo.cmp_contact_email,
                cmp_send_summary: this.custInfo.cmp_send_summary,
                cmp_send_invoice: this.custInfo.cmp_send_invoice,
                cmp_remit_range: this.custInfo.cmp_remit_range,
                cmp_billing_first_name: this.custInfo.cmp_billing_first_name,
                cmp_billing_last_name: this.custInfo.cmp_billing_last_name,
                cmp_bill_custref: this.custInfo.cmp_bill_custref,
                cmp_billing_phone: this.custInfo.cmp_billing_phone,
                cmp_billing_phone_ext: this.custInfo.cmp_billing_phone_ext,
                cmp_billing_email: this.custInfo.cmp_billing_email,
                cmp_billing_email_cc: this.custInfo.cmp_billing_email_cc,
                cmp_billing_addr1: this.custInfo.cmp_billing_addr1,
                cmp_billing_addr2: this.custInfo.cmp_billing_addr2,
                cmp_billing_city: this.custInfo.cmp_billing_city,
                cmp_billing_state: this.custInfo.cmp_billing_state,
                cmp_billing_zip: this.custInfo.cmp_billing_zip,
                cmp_billing_country: this.custInfo.cmp_billing_country,
            };
        },
        handlePaneOpened(num) {
            if (this.openPaneNum == num) {
                return (this.openPaneNum = null);
            }
            this.openPaneNum = num;
        },
        async saveAddons() {
            let addons = [];
            let bothInitInactive = true;
            let optPlatInactive = true;
            let downloadInactive = true;
            for (let i = 0; i < this.custAddons.length; i++) {
                if (this.custAddons[i].cps_name === "optimizationplatform") {
                    if (this.custAddons[i].optimizationPlatformActiveInit) {
                        bothInitInactive = false;
                    }
                    if (this.custAddons[i].cps_is_active) {
                        optPlatInactive = false;
                    }
                }
                if (this.custAddons[i].cps_name === "download") {
                    if (this.custAddons[i].downloadActiveInit) {
                        bothInitInactive = false;
                    }
                    if (this.custAddons[i].cps_is_active) {
                        downloadInactive = false;
                    }
                }
                let addon = {
                    cps_cmp_id: this.selectedRow.cmp_id,
                    cps_id: this.custAddons[i].cps_id,
                    cps_name: this.custAddons[i].cps_name,
                    cps_amount: this.custAddons[i].cps_amount,
                    cps_billing_type: this.custAddons[i].cps_billing_type,
                    cps_whole_sale_amount: this.custAddons[i].cps_whole_sale_amount,
                    cps_whole_sale_billing_type: this.custAddons[i].cps_whole_sale_billing_type,
                    cps_is_active: this.custAddons[i].cps_is_active,
                };
                addons.push(addon);
            }
            if (!bothInitInactive && optPlatInactive && downloadInactive) {
                if (
                    !confirm(
                        "You haven't selected either 'Optimization Platform' or 'Download Only', this means we will not download any files for this client. Do you wish to continue?"
                    )
                ) {
                    return;
                }
            }
            if (!optPlatInactive && !downloadInactive) {
                alert("Sorry, you cannot have both 'Optimization Platform' and 'Download Only' active at the same time.");
                return;
            }
            await this.$httpAdmin.put(
                "/admincompanyaddons/" + this.selectedRow.cmp_id,
                { Addons: addons },
                { headers: this.headers }
            );
            await this.createTable("update");
            await this.showCustAddonTab();
            await this.editCustomerModal();
        },
        saveChanges() {
            if (this.active == 0) {
                this.saveCustomerChanges();
            }
            if (this.active == 1) {
                this.saveAddons();
            }
        },
        async saveAccounts() {
            this.isBusy = true;
            let isGood = true;
            for (let i = 0; i < this.carrierAccounts.length; i++) {
                if (this.carrierAccounts[i].acct_credential_id) {
                    for (let j = 0; j < this.carrierAccountsInfo.length; j++) {
                        if (this.carrierAccounts[i].acct_credential_id == this.carrierAccountsInfo[j].credential_id) {
                            this.carrierAccounts[i].credential_car_id = this.carrierAccountsInfo[j].car_id;
                        }
                    }
                }
            }
            for (let i = 0; i < this.carrierAccounts.length; i++) {
                if (
                    !this.carrierAccounts[i].acct_number ||
                    !this.carrierAccounts[i].acct_mns_delay_days ||
                    !this.carrierAccounts[i].acct_npod_delay_days ||
                    !(this.carrierAccounts[i].acct_car_id || this.carrierAccounts[i].credential_car_id)
                ) {
                    this.$notify.error({
                        message: "One of the accounts is not valid",
                        title: "Error",
                        timeOut: 5000,
                        variant: "danger",
                    });
                    isGood = false;
                }
            }
            if (isGood) {
                let count = this.carrierAccounts.length;
                for (let i = 0; i < this.carrierAccounts.length; i++) {
                    if (this.carrierAccounts[i].acct_id > 0) {
                        await this.$httpAdmin
                            .put(
                                "/adminaccount/" + this.carrierAccounts[i].acct_id,
                                this.saveAccountsParams(this.carrierAccounts[i]),
                                {
                                    headers: this.headers,
                                }
                            )
                            .then(async (response) => {
                                let results = response.data;
                                if (results) {
                                    count--;
                                    if (count == 0) {
                                        this.$notify.success({
                                            message: "The account has been saved successfully.",
                                            title: "Success",
                                            timeOut: 5000,
                                            variant: "success",
                                        });
                                    }
                                } else {
                                    this.$notify.error({
                                        message:
                                            "There is a problem saving the accounts. Please contact technical support team.",
                                        title: "Error",
                                        timeOut: 5000,
                                        variant: "danger",
                                    });
                                }
                            });
                    } else {
                        await this.$httpAdmin
                            .post("/adminaccount", this.saveAccountsParams(this.carrierAccounts[i]), {
                                headers: this.headers,
                            })
                            .then(async (response) => {
                                let results = response.data;
                                if (results) {
                                    count--;
                                    if (count == 0) {
                                        this.$notify.success({
                                            message: "The account has been saved successfully.",
                                            title: "Success",
                                            timeOut: 5000,
                                            variant: "success",
                                        });
                                    }
                                }
                            });
                    }
                }
                this.accountsModal = false;
            }
            this.isBusy = false;
        },
        saveAccountsParams(account) {
            return {
                acct_id: account.acct_id,
                acct_number: account.acct_number,
                acct_credential_id: account.acct_credential_id,
                acct_cmp_id: this.selectedRow.cmp_id,
                acct_car_id: account.credential_car_id ? account.credential_car_id : account.acct_car_id,
                acct_mns_delay_days: account.acct_mns_delay_days,
                acct_npod_delay_days: account.acct_npod_delay_days,
                acct_no_audit: account.acct_no_audit,
                acct_is_gsr_waiver: account.acct_is_gsr_waiver,
                acct_name: account.acct_name,
                acct_glcode: account.acct_glcode,
                acct_glcode2: account.acct_glcode2,
            };
        },
        carrierAccountsAdd() {
            let newAccount = {
                acct_id: 0,
                acct_number: "",
                acct_credential_id: 0,
                acct_mns_delay_days: 35,
                acct_npod_delay_days: 55,
                acct_no_audit: false,
                acct_is_gsr_waiver: false,
                acct_name: "",
                acct_glcode: "",
                acct_glcode2: "",
            };
            this.carrierAccounts.push(newAccount);
        },
        changeAddonBillingType(addon) {
            if (addon.cps_billing_type == "flatfee") {
                addon.cps_whole_sale_billing_type = "flatfee";
                addon.valueType = "$/mth";
            } else if (addon.cps_billing_type == "transactional") {
                addon.cps_whole_sale_billing_type = "transactional";
                addon.valueType = "¢/pkg";
            } else {
                addon.cps_whole_sale_billing_type = "gainshare";
                addon.valueType = "%";
            }
        },
        async getCompanyFtpCredentials() {
            await this.$httpAdmin
                .get("companyftpcredential", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.companyFtpCredentials = response.data;
                });
        },
        async getResellerFtpCredentials() {
            await this.$httpAdmin
                .get("resellerftpcredential", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.resellerFtpCredentials = response.data;
                });
        },
        showCustEditTab() {
            this.active = 0;
            this.editCustomerModal();
        },
        async showCustAddonTab() {
            this.isBusy = true;
            await this.$httpAdmin
                .get("/adminCompanyAddons", {
                    headers: this.headers,
                    params: { cmpID: this.selectedRow.cmp_id },
                })
                .then(async (response) => {
                    this.custAddons = response.data;
                    for (let i = 0; i < this.custAddons.length; i++) {
                        if (this.custAddons[i].cps_billing_type == "flatfee") {
                            this.custAddons[i].valueType = "$/mth";
                        } else if (this.custAddons[i].cps_billing_type == "transactional") {
                            this.custAddons[i].valueType = "¢/pkg";
                        } else {
                            this.custAddons[i].valueType = "%";
                        }
                        if (this.custAddons[i].cps_activate_date) {
                            this.custAddons[i].cps_activate_date = new moment(
                                this.custAddons[i].cps_activate_date
                            ).format("MM/DD/YYYY");
                            if (this.custAddons[i].cps_deactivate_date) {
                                this.custAddons[i].cps_deactivate_date = new moment(
                                    this.custAddons[i].cps_deactivate_date
                                ).format("MM/DD/YYYY");
                            } else this.custAddons[i].cps_deactivate_date = "present";
                            this.custAddons[i].cps_description =
                                this.custAddons[i].cps_description +
                                "<span class='ml-4'>(from: " +
                                this.custAddons[i].cps_activate_date +
                                " - " +
                                this.custAddons[i].cps_deactivate_date +
                                ")</span>";
                        }
                        this.custAddons[i].cps_is_active == "1"
                            ? (this.custAddons[i].cps_is_active = true)
                            : (this.custAddons[i].cps_is_active = false);
                        if (this.custAddons[i].cps_name === "optimizationplatform") {
                            this.custAddons[i].optimizationPlatformActiveInit = this.custAddons[i].cps_is_active;
                        }
                        if (this.custAddons[i].cps_name === "download") {
                            this.custAddons[i].downloadActiveInit = this.custAddons[i].cps_is_active;
                        }
                    }
                });
            this.active = 1;
            this.isBusy = false;
        },
        async showCustSnapshotTab() {
            this.isBusy = true;
            await this.$httpAdmin
                .get("/admincompanysnapshot/scheduledreport", {
                    headers: this.headers,
                    params: { cmpID: this.selectedRow.cmp_id },
                })
                .then(async (response) => {
                    this.custScheduledReports = response.data;
                });
            this.scheduledRows = this.custScheduledReports;
            this.scheduledColumns = [
                { field: "report_name", label: "Report Name" },
                { field: "sch_name", label: "Scheduled Report" },
                { field: "srfw_desc", label: "Delivery Week" },
                { field: "srfd_desc", label: "Delivery Day" },
                { field: "srt_desc", label: "Date Range" },
                { field: "sch_to", label: "Recipients" },
            ];
            await this.$httpAdmin
                .get("/admincompanysnapshot/loginactivity", {
                    headers: this.headers,
                    params: { cmpID: this.selectedRow.cmp_id },
                })
                .then(async (response) => {
                    this.custLoginActivity = response.data;
                });
            for (let i = 0; i < this.custLoginActivity.length; i++) {
                this.custLoginActivity[i].ull_datetime = new moment(this.custLoginActivity[i].ull_datetime).format(
                    "MM/DD/YYYY"
                );
            }
            this.loginRows = this.custLoginActivity;
            this.loginColumns = [
                { field: "ull_username", label: "Email" },
                { field: "ull_datetime", label: "Login Time" },
            ];
            await this.$httpAdmin
                .get("/admincompanysnapshot/spentstat", {
                    headers: this.headers,
                    params: { cmpID: this.selectedRow.cmp_id },
                })
                .then(async (response) => {
                    this.custSpentStats = response.data;
                });
            for (let i = 0; i < this.custSpentStats.length; i++) {
                this.custSpentStats[i].spent =
                    "$" +
                    parseFloat(this.custSpentStats[i].spent)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.custSpentStats[i].refund =
                    "$" +
                    parseFloat(this.custSpentStats[i].refund)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.custSpentStats[i].packages = this.custSpentStats[i].packages
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            this.spentRows = this.custSpentStats;
            this.spentColumns = [
                { field: "carrierId", label: "Carrier" },
                { field: "dateSpan", label: "Time Span" },
                { field: "spent", label: "Carrier Net Spend" },
                { field: "refund", label: "Refund" },
                { field: "packages", label: "#Packages" },
            ];
            this.active = 2;
            this.isBusy = false;
        },
        async showCustUsersTab() {
            this.isBusy = true;
            await this.$httpAdmin
                .get("/adminuserprofile/company", {
                    headers: this.headers,
                    params: { cmpID: this.selectedRow.cmp_id },
                })
                .then(async (response) => {
                    this.adminUserProfile = response.data;
                });
            for (let i = 0; i < this.adminUserProfile.length; i++) {
                this.adminUserProfile[i].usr_name =
                    this.adminUserProfile[i].usr_first_name + " " + this.adminUserProfile[i].usr_last_name;
                if (
                    !this.adminUserProfile[i].usr_last_login_date ||
                    this.adminUserProfile[i].usr_last_login_date == "null"
                ) {
                    this.adminUserProfile[i].usr_last_login_date = "";
                } else {
                    this.adminUserProfile[i].usr_last_login_date = new moment(
                        this.adminUserProfile[i].usr_last_login_date
                    ).format("MM/DD/YYYY HH:mm");
                }
                if (this.adminUserProfile[i].usr_is_active == "1") {
                    this.adminUserProfile[i].usr_is_active = "Active";
                } else {
                    this.adminUserProfile[i].usr_is_active = "Inactive";
                }
                if (this.adminUserProfile[i].usr_is_deleted == "1") {
                    this.adminUserProfile[i].usr_is_deleted = "Deleted";
                } else {
                    this.adminUserProfile[i].usr_is_deleted = "";
                }
                this.adminUserProfile[i].viewDetails =
                    "adminmanageusers&usr_email=" + this.adminUserProfile[i].usr_email;
            }
            this.adminUserProfileRows = this.adminUserProfile;
            this.adminUserProfileColumns = [
                { field: "usr_name", label: "Full Name" },
                { field: "usr_email", label: "Email" },
                { field: "usr_last_login_date", label: "Last Login Date" },
                { field: "usr_is_active", label: "Active" },
                { field: "usr_is_deleted", label: "Deleted" },
                { field: "viewDetails", label: "View Detail" },
            ];
            this.active = 3;
            this.isBusy = false;
        },
        pushToCredentialsInfo(newCarrier) {
            this.credentialsInfo.push(newCarrier);
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
<style>
.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
    width: 1.85rem;
    height: 1.85rem;
}
</style>
