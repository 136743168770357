<template>
    <div class="w-100 systemHealth m-2">
        <h1 class="page-header border-bottom mx-2">
            Service Dashboard
            <span class="small">Internal View</span>
            <mdb-icon icon="tachometer-alt" size="1x" class="float-right text-muted" role="button" />
        </h1>
        <div class="w-100 d-flex mt-4 p-4">
            <mdb-card class="flex-fill mr-4">
                <mdb-card-header>
                    <mdb-icon icon="bullhorn"></mdb-icon>
                    Triage Checklist
                </mdb-card-header>
                <mdb-card-body>
                    <mdb-badge>{{ failedDownloads }} D/L Fails</mdb-badge>
                    <mdb-badge class="ml-2">{{ failedCredentials }} Cred Fails</mdb-badge>
                </mdb-card-body>
            </mdb-card>
            <mdb-card class="flex-fill">
                <mdb-card-header>
                    <mdb-icon icon="info"></mdb-icon>
                    Informational
                </mdb-card-header>
                <mdb-card-body>
                    <mdb-badge>{{ onboarding }} Onboarding</mdb-badge>
                </mdb-card-body>
            </mdb-card>
        </div>
        <mdb-row class="p-4">
            <mdb-col col="8">
                <mdb-row class="w-100 my-4">
                    <mdb-col col="12">
                        <mdb-row class="d-flex no-gutters">
                            <mdb-card xs="12" col="auto" class="mr-2 flex-fill">
                                <mdb-card-header color="green lighten-4" tag="h3">
                                    <mdb-row class="no-gutters">
                                        <mdb-col col="6" class="green-text">
                                            <mdb-icon icon="check" size="3x" />
                                        </mdb-col>
                                        <mdb-col col="6" class="green-text">
                                            <p>Onboarding</p>
                                            <p class="smaller green-text">Onboarding</p>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-card-header>
                                <mdb-card-body>
                                    <router-link to="/admin/onboardingadmin">
                                        Access
                                        <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                                    </router-link>
                                </mdb-card-body>
                            </mdb-card>
                            <mdb-card xs="12" col="auto" class="flex-fill">
                                <mdb-card-header color="red lighten-4" tag="h3">
                                    <mdb-row class="no-gutters">
                                        <mdb-col col="6" class="font-weight-bold red-text">
                                            <mdb-icon icon="user" size="3x" />
                                        </mdb-col>
                                        <mdb-col col="6" class="red-text">
                                            <p class="text-nowrap">Users</p>
                                            <p class="smaller red-text">Add Additional Users</p>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-card-header>
                                <mdb-card-body>
                                    <router-link to="/admin/manageusers">
                                        Access
                                        <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                                    </router-link>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-row>
                        <mdb-row class="d-flex no-gutters mt-4">
                            <mdb-card xs="12" col="auto" class="mr-2 flex-fill">
                                <mdb-card-header color="blue lighten-4" tag="h3">
                                    <mdb-row class="no-gutters">
                                        <mdb-col col="6" class="blue-text">
                                            <mdb-icon icon="download" size="3x" />
                                        </mdb-col>
                                        <mdb-col col="6" class="blue-text">
                                            <p>Downloads</p>
                                            <p class="smaller blue-text">Download Failures</p>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-card-header>
                                <mdb-card-body>
                                    <router-link to="/admin/faileddownloads">
                                        View Details
                                        <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                                    </router-link>
                                </mdb-card-body>
                            </mdb-card>
                            <mdb-card xs="12" col="auto" class="mr-2 flex-fill">
                                <mdb-card-header color="blue lighten-4" tag="h3">
                                    <mdb-row class="no-gutters">
                                        <mdb-col col="6" class="blue-text">
                                            <mdb-icon icon="lock" size="3x" />
                                        </mdb-col>
                                        <mdb-col col="6" class="blue-text">
                                            <p>Credentials</p>
                                            <p class="smaller blue-text">Credential Failures</p>
                                        </mdb-col>
                                    </mdb-row>
                                </mdb-card-header>
                                <mdb-card-body>
                                    <router-link to="/admin/failedcredentials">
                                        View Details
                                        <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                                    </router-link>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-row>
                    </mdb-col>
                </mdb-row>
            </mdb-col>
            <mdb-col col="4">
                <mdb-card xs="12" col="auto" class="flex-fill mt-4 mr-4">
                    <mdb-card-header color="blue lighten-4" tag="h3">
                        <mdb-row class="no-gutters">
                            <mdb-col col="6" class="blue-text">
                                <mdb-icon :icon="systemStatusIcon" size="3x" />
                            </mdb-col>
                            <mdb-col col="6" class="blue-text">
                                <p>{{ systemStatus }}</p>
                                <p class="smaller blue-text">{{ systemStatusText }}</p>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-header>
                    <mdb-card-body>
                        <router-link to="/admin/appcontroller">
                            View Details
                            <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                        </router-link>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
    </div>
</template>
<script>
export default {
    name: "ServiceDashboard",
    components: {},
    props: {
        userInfo: Object,
        headers: Object,
    },
    data() {
        return {
            onboarding: null,
            failedDownloads: null,
            failedCredentials: null,
            systemStatus: null,
            systemStatusText: null,
            systemStatusIcon: null,
            runningComponent: null,
        };
    },
    methods: {
        async getStats() {
            this.onboarding = (
                await this.$httpAdmin.get("Administration/onboard-customers", { headers: this.headers })
            ).data;
            this.failedDownloads = (
                await this.$httpAdmin.get("Administration/failed-downloads", { headers: this.headers })
            ).data;
            this.failedCredentials = (
                await this.$httpAdmin.get("Administration/failed-credentials", { headers: this.headers })
            ).data;
        },
        async getComponents() {
            this.runningComponent = null;
            const components = (
                await this.$httpAdmin.get("appcontroller/?action=getcomponent&clientName=web", {
                    headers: this.headers,
                })
            ).data;
            for (const row of components) {
                let status = "clock";

                if (row.status === "started") status = "play";
                else if (row.status === "running") status = "sync";
                else if (row.status === "newInvoices") status = "copy";

                if (row.disabled) status = "stop";
                row.icon = status;
                if (row.status === "running") {
                    this.runningComponent = row;
                }
            }
            this.updateSystemPanel();
        },

        updateSystemPanel() {
            let text = "Idle";
            let det = "System is Idle";
            let ico = "clock";

            let component;
            if (this.runningComponent) {
                component = this.runningComponent;
            }
            if (component === "extraction") {
                text = "Extract";
                det = "Extracting Charges...";
                ico = "sync";
            } else if (component === "reconciliation") {
                text = "Audit";
                det = "Reconciling Refunds...";
                ico = "sync";
            } else if (component === "audit") {
                text = "Audit";
                det = "Processing Charges...";
                ico = "sync";
            } else if (component === "consolidation") {
                text = "Maint";
                det = "Consolidating Packages...";
                ico = "sync";
            } else if (component === "glcoding") {
                text = "Maint";
                det = "Applying GLCodes...";
                ico = "sync";
            } else if (component === "invoiceUploader") {
                text = "Stage";
                det = "Uploading Invoices to Extract Queue (S3)...";
                ico = "sync";
            } else if (component === "extractionInvoiceDownloader") {
                text = "Stage";
                det = "Downloading Invoices from Extract Queue (S3)...";
                ico = "sync";
            } else if (component === "copyProcessedPackages") {
                text = "Merge";
                det = "Committing Processed Data...";
                ico = "sync";
            }

            this.systemStatus = text;
            this.systemStatusText = det;
            this.systemStatusIcon = ico;
        },
    },
    mounted() {
        this.getStats();
        this.getComponents();
    },
};
</script>
