<template>
    <div data-test="showRateCalc" class="w-100 h-100 overflow-auto" v-if="premiumServices && rateCalcService">
        <h1 data-test="pageHeader" class="page-header">
            Rate Calculator
            <span class="text-muted text-sm-center">Rate Calculator Tour</span>
            <mdb-icon icon="cubes" color="grey" size="xl3" class="float-right" />
        </h1>
        <hr />
        <mdb-btn
            data-test="deactivteAddonObjectBtn"
            @click="deactivateAddonObject.isModalVisible = true"
            color="danger"
            class="ml-auto"
        >
            <mdb-icon icon="trash" />
            Deactivate Add-on
        </mdb-btn>
        <div data-test="deactivateAddonObjectModalDiv" v-if="deactivateAddonObject">
            <mdb-modal
                data-test="deactivateAddonObjectModal"
                :show="deactivateAddonObject.isModalVisible"
                @close="deactivateAddonObject.isModalVisible = false"
                id="delGroup"
            >
                <mdb-modal-header data-test="deactivateAddonObjectModalHeader">
                    {{ deactivateAddonObject.title }}
                </mdb-modal-header>
                <mdb-modal-body data-test="deactivateAddonObjectModalBody">
                    <div class="row">
                        <div class="col-lg-12">
                            <p>{{ deactivateAddonObject.body }}</p>
                            <p>
                                <strong>Note:</strong> Add-ons are billed monthly so you will be charged for the full
                                month.
                            </p>
                        </div>
                    </div>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn @click="deactivateAddonObject.isModalVisible = false" class="btn btn-info" size="sm"
                        >Cancel</mdb-btn
                    >
                    <mdb-btn
                        data-test="deactivateAddonObjectModal_deactivateServiceBtn"
                        @click.native="deactivateService"
                        size="sm"
                        color="danger"
                        v-if="!loadingDeactivate"
                    >
                        Deactivate
                    </mdb-btn>

                    <mdb-btn
                        data-test="deactivateAddonObjectModal_spinner"
                        v-else
                        disabled
                        color="danger"
                        class="ml-auto"
                    >
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Deactivating...
                    </mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
        <div data-test="shipFromDiv" class="m-2">
            <div data-test="error" class="alert alert-danger" v-if="error">
                {{ error }}
            </div>
            <mdb-card-group deck>
            <mdb-card data-test="shipFromCard">
                <mdb-card-header data-test="shipFromCardHeader">Ship From</mdb-card-header>
                <mdb-card-body data-test="shipFromCardBody">
                    <form data-test="shipFromForm" class="p-2" autocomplete="on">
                        <div class="form-group">
                            <label data-test="shipFromFormLabel_shipperCountry"
                                >Shipper Country <span class="text-danger">*</span></label
                            >
                            <select
                                data-test="shipFromFormSelectCountry"
                                autocomplete="off"
                                class="form-control"
                                v-model="shipperCountry"
                                :class="!shipperCountry && error ? 'is-invalid' : ''"
                            >
                                <option
                                    data-test="shipFromFormCountryOptions"
                                    v-for="(country, index) in countries"
                                    :value="country"
                                    v-bind:key="index"
                                >
                                    {{ country.name.split(/(?=[A-Z])/).join(" ") }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label data-test="shipFromFormLabel_shipperState"
                                >Shipper State <span class="text-danger">*</span></label
                            >
                            <select
                                data-test="shipFromFormSelectState"
                                v-model="shipperState"
                                placeholder="Select a state"
                                class="form-control"
                                :class="!shipperState && error ? 'is-invalid' : ''"
                            >
                                <option
                                    data-test="shipFromFormStateOptions"
                                    v-for="state of shipperCountry.states"
                                    :value="state.abbr"
                                >
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label data-test="shipFromFormLabel_shipperStreet" for="shipperStreet"
                                >Shipper Street Address</label
                            >
                            <input
                                data-test="shipFromFormInput_shipperStreet"
                                type="text"
                                class="form-control"
                                v-model="shipperStreet"
                                id="shipperStreet"
                            />
                        </div>
                        <div class="form-group">
                            <label data-test="shipFromFormLabel_shipperCity" for="shipperCity"
                                >Shipper City <span class="text-danger">*</span></label
                            >
                            <input
                                data-test="shipFromFormInput_shipperCity"
                                type="text"
                                class="form-control"
                                v-model="shipperCity"
                                id="shipperCity"
                                :class="!shipperCity && error ? 'is-invalid' : ''"
                            />
                        </div>
                        <div class="form-group">
                            <label data-test="shipFromFormLabel_shipperZipCode" for="shipperZipCode"
                                >Shipper Zip Code <span class="text-danger">*</span></label
                            >
                            <input
                                data-test="shipFromFormInput_shipperZipCode"
                                type="text"
                                class="form-control"
                                v-model="shipperZipCode"
                                id="shipperZipCode"
                                :class="!shipperZipCode && error ? 'is-invalid' : ''"
                            />
                        </div>
                    </form>
                </mdb-card-body>
            </mdb-card>
            <mdb-card data-test="shipToCard">
                <mdb-card-header data-test="shipToCardHeader">Ship To</mdb-card-header>
                <mdb-card-body data-test="shipToCardBody">
                    <form data-test="shipToForm" class="p-2" autocomplete="on">
                        <div class="form-group">
                            <label data-test="shipToFormLabel_recipientCountry"
                                >Recipient Country <span class="text-danger">*</span></label
                            >
                            <select
                                data-test="shipToFormSelect_recipientCountry"
                                autocomplete="off"
                                class="form-control"
                                v-model="recipientCountry"
                                :class="!recipientCountry && error ? 'is-invalid' : ''"
                            >
                                <option
                                    data-test="shipToFormOptions_recipientCountry"
                                    v-for="(country, index) in countries"
                                    :value="country"
                                    v-bind:key="index"
                                >
                                    {{ country.name.split(/(?=[A-Z])/).join(" ") }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label data-test="shipToFormLabel_recipientState"
                                >Recipient State <span class="text-danger">*</span></label
                            >
                            <select
                                data-test="shipToFormSelect_recipientState"
                                v-model="recipientState"
                                placeholder="Select a state"
                                class="form-control"
                                :class="!recipientState && error ? 'is-invalid' : ''"
                            >
                                <option
                                    data-test="shipToFormOptions_recipientState"
                                    v-for="state in recipientCountry.states"
                                    :value="state.abbr"
                                >
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label data-test="shipToFormLabel_recipientStreet" for="recipientStreet"
                                >Recipient Street Address</label
                            >
                            <input
                                data-test="shipToFormInput_recipientStreet"
                                type="text"
                                class="form-control"
                                v-model="recipientStreet"
                                id="recipientStreet"
                            />
                        </div>
                        <div class="form-group">
                            <label data-test="shipToFormLabel_recipientCity" for="recipientCity"
                                >Recipient City <span class="text-danger">*</span></label
                            >
                            <input
                                data-test="shipToFormInput_recipientCity"
                                type="text"
                                class="form-control"
                                v-model="recipientCity"
                                id="recipientCity"
                                :class="!recipientCity && error ? 'is-invalid' : ''"
                            />
                        </div>
                        <div class="form-group">
                            <label data-test="shipToFormLabel_recipientZipCode" for="recipientZipCode"
                                >Recipient Zip Code <span class="text-danger">*</span></label
                            >
                            <input
                                data-test="shipToFormInput_recipientZipCode"
                                type="text"
                                class="form-control"
                                v-model="recipientZipCode"
                                id="recipientZipCode"
                                :class="!recipientZipCode && error ? 'is-invalid' : ''"
                            />
                        </div>
                    </form>
                </mdb-card-body>
            </mdb-card>
            <mdb-card data-test="characteristicsCard">
                <mdb-card-header data-test="characteristicsCardHeader">Characteristics</mdb-card-header>
                <mdb-card-body data-test="characteristicsCardBody">
                    <form data-test="characteristicsForm" class="p-2" autocomplete="on">
                        <div class="form-group">
                            <label data-test="characteristicsFormLabel_pickupDate">Pickup Date</label>
                            <mdb-date-picker-2
                                data-test="characteristicsFormDatePicker_pickupDate"
                                id="pickupDate"
                                v-model="pickupDate"
                                class="mb-2"
                            ></mdb-date-picker-2>
                        </div>
                        <div class="form-group">
                            <label data-test="characteristicsFormLabel_insurance" for="insurance">Insurance</label>
                            <input
                                data-test="characteristicsFormInput_insurance"
                                type="text"
                                class="form-control"
                                v-model="insurance"
                                id="insurance"
                                placeholder="Insurance"
                            />
                        </div>
                        <div class="form-group">
                            <label data-test="characteristicsFormLabel_weight" for="weight"
                                >Weight <span class="text-danger">*</span></label
                            >
                            <input
                                data-test="characteristicsFormInput_weight"
                                type="text"
                                class="form-control"
                                v-model="weight"
                                id="weight"
                                :class="!weight && error ? 'is-invalid' : ''"
                            />
                        </div>
                        <div class="form-group">
                            <p data-test="characteristicsForm_units">Units</p>
                            <mdb-form-inline>
                                <mdb-input
                                    data-test="characteristicsFormRadio1_units"
                                    type="radio"
                                    id="option-1"
                                    name="unitRadios"
                                    radioValue="lb-in"
                                    v-model="units"
                                    label="LB/IN"
                                />
                                <mdb-input
                                    data-test="characteristicsFormRadio2_units"
                                    type="radio"
                                    id="option-2"
                                    name="unitRadios"
                                    radioValue="kg-cm"
                                    v-model="units"
                                    label="KG/CM"
                                />
                            </mdb-form-inline>
                        </div>
                        <div class="form-group">
                            <label data-test="characteristicsFormLabel_dimensions">Dimensions</label>
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <input
                                        data-test="characteristicsFormRadio_length"
                                        type="text"
                                        class="form-control form-inline"
                                        v-model="length"
                                        id="length"
                                        placeholder="Length"
                                    />
                                    <span class="p-1">X</span>
                                    <input
                                        data-test="characteristicsFormRadio_width"
                                        type="text"
                                        class="form-control"
                                        v-model="width"
                                        id="width"
                                        placeholder="Width"
                                    />
                                    <span class="p-1">X</span>
                                    <input
                                        data-test="characteristicsFormRadio_height"
                                        type="text"
                                        class="form-control"
                                        v-model="height"
                                        id="height"
                                        placeholder="Height"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </mdb-card-body>
            </mdb-card>
            </mdb-card-group>
        </div>
        <div data-test="calculateDiv" class="row">
            <div class="col-12">
                <div class="float-right mr-4">
                    <mdb-btn data-test="getRateBtn" variant="info" size="lg" v-if="!isBusy" @click.native="getRate"
                        >Calculate <mdb-icon icon="arrow-circle-right" class="ml-2"
                    /></mdb-btn>
                    <mdb-btn data-test="calculatingSpinner" variant="light" disabled size="lg" v-if="isBusy"
                        >Calculating <mdb-icon icon="spinner" spin class="ml-2"
                    /></mdb-btn>
                    <div data-test="calculatingError" class="alert alert-danger" v-if="error">
                        {{ error }}
                    </div>
                </div>
            </div>
        </div>
        <div data-test="ratesDiv" class="col-12 clearfix mt-4" v-if="rates">
            <vue-good-table
                data-test="ratesTable"
                id="tableData"
                :rows="rates.items"
                :columns="rates.columns"
                show-empty
                responsive
                striped
                hover
                :busy="isBusy"
            >
                <template v-slot:table-busy>
                    <div class="text-center text-info my-2">
                        <mdb-spinner class="align-middle mr-2"></mdb-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template v-slot:cell(Arrival)="row">
                    <span>{{ moment(row.item.Arrival).format("MMM DD, YYYY") }}</span>
                </template>
            </vue-good-table>
            <highcharts data-test="highcharts" :options="chargeChart"></highcharts>
        </div>
    </div>
    <div data-test="noRateCalculator" v-else>
        <mdb-card data-test="noRateCalculator_card">
            <mdb-card-header data-test="noRateCalculator_cardHeader">
                <h3>
                    Add-on Description
                    <mdb-btn
                        data-test="activateServiceBtn"
                        @click.native="activateService()"
                        icon="plus"
                        class="float-right"
                        v-if="!loadingActivate"
                    >
                        Activate Rate Calculator
                    </mdb-btn>
                    <mdb-btn data-test="activateServiceSpinner" icon="plus" class="float-right" v-else disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Activating Rate Calculator...
                    </mdb-btn>
                </h3>
            </mdb-card-header>
            <mdb-card-body data-test="noRateCalculator_cardBody">
                <p>
                    The Rate Calculator tool estimates the time in transit and cost of delivery based on the details you
                    enter to compare all of your available carriers on one screen.
                </p>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import * as countries from "../../data/countries_states";
import moment from "moment";
import maps from "../../lib/charts";
import {
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbDatePicker2,
    mdbFormInline,
    mdbInput,
    mdbCardGroup
} from "mdbvue";

export default {
    name: "RateCalculator",
    components: {
        mdbIcon,
        mdbBtn,
        mdbModal,
        mdbModalHeader,
        mdbModalBody,
        mdbModalFooter,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbDatePicker2,
        mdbFormInline,
        mdbInput,
        mdbCardGroup
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        options: Object,
        headers: Object,
        services: Array,
        activateAddon: Function,
        deactivateAddon: Function,
    },
    data() {
        return {
            shipperCountry: "",
            shipperState: "",
            shipperCity: "",
            shipperStreet: "",
            shipperZipCode: "",
            recipientCountry: "",
            recipientState: "",
            recipientCity: "",
            recipientStreet: "",
            recipientZipCode: "",
            length: null,
            width: null,
            height: null,
            units: null,
            weight: null,
            insurance: null,
            pickupDate: null,
            countries: null,
            rates: null,
            isBusy: false,
            error: null,
            chargeChart: {
                type: "column",
                instance: null,
                title: {
                    text: "Cost Versus Expected Delivery Date",
                },
                //subtitle: 'Cost Versus Expected Delivery Date',
                xAxis: {
                    title: "Expected Delivery Date",
                    categories: [],
                },
                yAxis: {
                    title: "Minimum Charge",
                },
                tooltip: {
                    headerText: "Delivered By: ",
                    valuePrefix: this.userInfo.currencySymbol,
                },
                series: [
                    {
                        name: "Cost",
                        data: [],
                    },
                ],
            },
            deactivateAddonObject: {
                isModalVisible: false,
                title: "Deactivate Addon",
                icon: "magic",
                body: "Are you sure you would like to deactivate this addon?",
            },
            loadingActivate: false,
            loadingDeactivate: false,
        };
    },
    computed: {
        premiumServices() {
            return this.services
                ? this.services.map((s) => {
                      if (s.cps_is_active === "1") return s.cps_name;
                  })
                : null;
        },
        rateCalcService() {
            const rateCalc = this.services.find((c) => c.cps_name === "ratecalculator");
            if (rateCalc) {
                this.countries = countries.default;
                this.shipperCountry = this.countries.filter(
                    (c) => c.abbr === this.userInfo.usrBaseCountry.toUpperCase()
                )[0];
                this.recipientCountry = this.countries.filter(
                    (c) => c.abbr === this.userInfo.usrBaseCountry.toUpperCase()
                )[0];
                this.pickupDate = moment(new Date()).format("YYYY-MM-DD");
            }
            return rateCalc;
        },
    },
    methods: {
        moment: (date) => {
            return moment(date);
        },
        async getRate() {
            this.error = null;
            if (
                !this.shipperCountry.abbr ||
                !this.shipperState ||
                !this.shipperCity ||
                !this.shipperZipCode ||
                !this.recipientCountry.abbr ||
                !this.recipientState ||
                !this.recipientCity ||
                !this.recipientZipCode ||
                !this.weight
            ) {
                this.error = "Please fill out all required fields";
                window.scrollTo(0, 0);
                return false;
            }
            this.isBusy = true;
            const param = {
                ShipperCountry: this.shipperCountry.abbr,
                ShipperState: this.shipperState,
                ShipperCity: this.shipperCity,
                ShipperAddr1: this.shipperStreet,
                ShipperZipcode: this.shipperZipCode,
                RecipientCountry: this.recipientCountry.abbr,
                RecipientState: this.recipientState,
                RecipientCity: this.recipientCity,
                RecipientAddr1: this.recipientStreet,
                RecipientZipcode: this.recipientZipCode,
                Length: this.length ? Number(this.length) : undefined,
                Width: this.width ? Number(this.width) : undefined,
                Height: this.height ? Number(this.height) : undefined,
                Metric: this.units,
                Weight: Number(this.weight),
                DeclaredValue: this.insurance ? Number(this.insurance) : undefined,
                PickupDate: this.pickupDate.format("YYYY-MM-DD"),
                CmpId: this.userInfo.cmpID,
            };
            await this.$http.post("ratecalculator", param, { headers: this.headers }).then((response) => {
                this.rates = {};
                const columns = [
                    { key: "Service", label: "Service Name" },
                    { key: "Rate", label: "Total Charge" },
                    { key: "Arrival", label: "Arrival Time" },
                ];
                this.rates.columns = columns;
                this.rates.items = response.data;
                this.isBusy = false;
                const options = response.data;
                options.sort(this.compareRate);
                var lastDate = null;
                for (var i = 0; i < options.length; i++) {
                    var date = moment(options[i].Arrival).format("MM/DD/YYYY");
                    if (lastDate == date) continue;

                    lastDate = date;
                    this.chargeChart.xAxis.categories.push(date);
                    this.chargeChart.series[0].data.push(options[i].Rate);
                }
                this.chargeChart = maps.drawChart({ ...this.chargeChart });
            });
        },
        compareRate(a, b) {
            if (moment(a.Arrival).startOf("day").isBefore(moment(b.Arrival).startOf("day"))) return -1;
            else if (moment(a.Arrival).startOf("day").isAfter(moment(b.Arrival).startOf("day"))) return 1;
            else {
                if (a.Rate < b.Rate) return -1;
                else if (a.Rate > b.Rate) return 1;
                else return 0;
            }
        },
        async activateService() {
            this.loadingActivate = true;
            await this.activateAddon("ratecalculator");
            this.countries = countries.default;
            this.shipperCountry = this.countries.filter(
                (c) => c.abbr === this.userInfo.usrBaseCountry.toUpperCase()
            )[0];
            this.recipientCountry = this.countries.filter(
                (c) => c.abbr === this.userInfo.usrBaseCountry.toUpperCase()
            )[0];
            this.pickupDate = moment(new Date()).format("YYYY-MM-DD");
            this.loadingActivate = false;
        },
        async deactivateService() {
            this.loadingDeactivate = true;
            await this.deactivateAddon("ratecalculator");
            this.loadingDeactivate = false;
            this.deactivateAddonObject.isModalVisible = false;
        },
    },
};
</script>
<style>
.text-sm-center {
    font-size: 18px;
}
</style>
