export const Carriers = {
    1: {
        id: 1,
        abbr: "FDX",
        name: "FedEx",
    },
    2: {
        id: 2,
        abbr: "UPS",
        name: "UPS",
    },
    3: {
        id: 3,
        abbr: "DHL Ex",
        name: "DHL Express",
    },
    4: {
        id: 4,
        abbr: "Pur",
        name: "Purolator",
    },
    5: {
        id: 5,
        abbr: "DHL eCom",
        name: "DHL eCommerce",
    },
}
