var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-4",attrs:{"data-test":"page"}},[_c('h3',{staticClass:"my-4 mx-2",attrs:{"data-test":"pageTitle"}},[_vm._v("Failed Credentials")]),_c('vue-good-table',{attrs:{"data-test":"vue-good-table","styleClass":"vgt-table condensed","rows":_vm.records,"columns":_vm.columns,"mode":"remote","totalRows":_vm.totalRows,"isLoading":_vm.loading,"search-options":{
            enabled: true,
            trigger: 'enter',
        },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: this.limit,
            position: 'top',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: this.page,
        }},on:{"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.searchCreds},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'cmp_type')?_c('span',[(props.row.cmp_is_deleted === '1')?_c('span',[_vm._v("Inactive")]):(props.row.cmp_is_active === '0')?_c('span',[_vm._v("Inactive")]):_c('span',[_vm._v(_vm._s(props.row.cmp_type))])]):(props.column.field === 'di_error')?_c('span',[(!props.row.di_error || props.row.di_error === 0)?_c('span',[_vm._v(_vm._s(props.row.dc_error))]):_c('span',[_vm._v(_vm._s(props.row.di_error))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('mdb-modal',{attrs:{"data-test":"confirmModal","show":_vm.confirmModal,"large":""},on:{"close":function($event){_vm.confirmModal = false}}},[_c('mdb-modal-header',{attrs:{"data-test":"confirmModalHeader"}},[_vm._v("Confirm "+_vm._s(_vm.status))]),_c('mdb-modal-body',[(_vm.status === 'resolved')?_c('p',{attrs:{"data-test":"confirmModalResolved"}},[_vm._v(" Are you sure you want to mark this credential as resolved? ")]):_c('p',{attrs:{"data-test":"confirmModalElse"}},[_vm._v(" Are you sure you want to deactivate this credential? It will no longer be used for weekly downloads. ")])]),_c('mdb-modal-footer',[_c('mdb-btn',{attrs:{"data-test":"updateRowBtn","color":"success"},nativeOn:{"click":function($event){return _vm.updateRow.apply(null, arguments)}}},[_vm._v("Continue")]),_c('mdb-btn',{attrs:{"data-test":"confirmModalCloseBtn","color":"danger"},nativeOn:{"click":function($event){_vm.confirmModal = false}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }