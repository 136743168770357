<template>
    <div class="w-100 d-flex">
        <mdb-card class="flex-fill">
            <mdb-card-header class="panel-heading">
                <mdb-icon icon="bullhorn"></mdb-icon> Triage Checklist
            </mdb-card-header>
            <mdb-card-body class="panel-body">
                <router-link class="btn btn-default" to="adminfaileddownloads" role="button">
                    <mdb-spinner small v-if="dashboardInfo['failed-downloads'] === null"></mdb-spinner
                    ><b>{{ dashboardInfo["failed-downloads"] }}</b> D/L Fails
                </router-link>
                <router-link class="btn btn-default" to="adminfailedcredentials" role="button">
                    <mdb-spinner small v-if="dashboardInfo['failed-credentials'] === null"></mdb-spinner>
                    <b>{{ dashboardInfo["failed-credentials"] }}</b> Cred Fails
                </router-link>
                <router-link class="btn btn-default" to="adminemailattachmentserror" role="button">
                    <mdb-spinner small v-if="dashboardInfo['failed-attachment-errors'] === null"></mdb-spinner
                    ><b>{{ dashboardInfo["email-attachment-errors"] }}</b> Attach Errors
                </router-link>
                <router-link class="btn btn-default" to="adminauditpackageerrors" role="button">
                    <mdb-spinner small v-if="dashboardInfo['error-packages'] === null"></mdb-spinner
                    ><b>{{ dashboardInfo["error-packages"] }}</b> Audit Errors
                </router-link>
                <router-link class="btn btn-default" to="adminfullmanual" role="button">
                    <mdb-spinner small v-if="dashboardInfo['failed-reconciliated'] === null"></mdb-spinner
                    ><b>{{ dashboardInfo["failed-reconciliated"] }}</b> Unreconcilable
                </router-link>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="flex-fill">
            <mdb-card-header> <mdb-icon icon="info-circle"></mdb-icon> Informational </mdb-card-header>
            <mdb-card-body>
                <router-link class="btn btn-default" to="adminwtfreport" role="button">
                    <mdb-spinner small v-if="dashboardInfo['unknown-invoice'] === null"></mdb-spinner
                    ><b>{{ dashboardInfo["unknown-invoice"] }}</b> Unk Invoices
                </router-link>
                <router-link class="btn btn-default" to="adminextractfilefailed" role="button">
                    <mdb-spinner small v-if="dashboardInfo['failed-extraction'] === null"></mdb-spinner
                    ><b>{{ dashboardInfo["failed-extraction"] }}</b> Extract Fails
                </router-link>
                <router-link class="btn btn-default" to="adminduplicateinvoices" role="button">
                    <mdb-spinner small v-if="dashboardInfo['duplicate-invoices'] === null"></mdb-spinner
                    ><b>{{ dashboardInfo["duplicate-invoices"] }}</b> Inv Duplicates
                </router-link>
                <router-link class="btn btn-default" to="adminonboardingadmin" role="button">
                    <mdb-spinner small v-if="dashboardInfo['onboard-customers'] === null"></mdb-spinner
                    ><b>{{ dashboardInfo["onboard-customers"] }}</b> Onboarding
                </router-link>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
export default {
    name: "AdminTriageList",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            dashboardInfo: {
                "unknown-invoice": null,
                "email-attachment-errors": null,
                "onboard-customers": null,
                "failed-downloads": null,
                "failed-extraction": null,
                "duplicate-invoices": null,
                "failed-credentials": null,
                "failed-reconciliated": null,
                "error-packages": null,
            },
        };
    },
    methods: {
        async getDashboardInfo() {
            const params = [
                "unknown-invoice",
                "email-attachment-errors",
                "onboard-customers",
                "failed-downloads",
                "failed-extraction",
                "duplicate-invoices",
                "failed-credentials",
                "failed-reconciliated",
                "pending-credits",
                "error-packages",
            ];
            let parm;
            for (var i = 0; i < params.length; i++) {
                parm = (await this.$httpAdmin.get(`Administration/${params[i]}`, { headers: this.headers })).data;
                console.log(this.dashboardInfo);
                this.dashboardInfo[params[i]] = parm;
            }
        },
    },
    mounted() {
        this.getDashboardInfo();
    },
};
</script>
