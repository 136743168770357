<template>
    <div data-test="page" class="overflow-auto h-90">
        <div>
            <h1 data-test="pageHeader" class="page-header border-bottom mx-2">
                Optimization Advisor
                <span
                    data-test="pageHeaderSmall"
                    class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block"
                    >Detected Opportunities</span
                >
                <mdb-icon icon="binoculars" size="1x" class="float-right text-muted" role="button" />
            </h1>
        </div>
        <mdb-row v-if="isBusy" id="overlay" class="justify-content-center align-content-center h-100vh">
            <mdb-spinner data-test="accordion_spinner" class="justify-content-center"></mdb-spinner><h3><b>...Loading</b></h3>
        </mdb-row>
        <mdb-row id="pageView" class="w-100 p-0 m-0 mt-4" :style="{'opacity': isBusy ? .3 : 1}">
            <mdb-col col="9">
                <mdb-accordion data-test="accordion" default style="cursor: pointer">
                    <mdb-accordion-pane
                        data-test="accordionPane"
                        v-for="(item, index) of oppItems"
                        v-bind:key="index"
                        @pane-clicked="handlePaneOpened(index, item, 'open')"
                        :isOpen="openPaneNum === index"
                    >
                        <template slot="title">
                            <mdb-row class="d-flex align-items-center">
                                <mdb-col col="1" class="vgt-center-align"
                                    ><mdb-icon
                                    :fab="item.icon == 'grav' ? true : false"
                                        data-test="accordion_oppItemsIcon"
                                        :icon="item.icon"
                                        size="2x"
                                        class="mr-3"
                                /></mdb-col>
                                <mdb-col col="10">
                                    <div>
                                        <h4 data-test="accordion_oppItemsTitle">{{ item.opt_title }}</h4>
                                        <span
                                            data-test="accordion_oppItemsText"
                                            v-html="item.opt_text"
                                            class="keep-spaces"
                                        ></span>
                                    </div>
                                </mdb-col>
                                <mdb-col col="1" class="vgt-center-align">
                                    <mdb-spinner v-if="item.isLoading"></mdb-spinner>
                                    <mdb-icon v-else icon="angle-down" size="2x" />
                                </mdb-col>
                            </mdb-row>
                        </template>
                        <div data-test="accordionData" class="p-2" v-if="item.reportData && item.isActive">
                            <mdb-row>
                                <mdb-col col="6">
                                    <div class="btn-group action-buttons" role="group">
                                        <mdb-btn
                                            data-test="accordion_showPackageGenieBtn"
                                            @click.native="showPackageGenie = true"
                                            class="btn btn-default"
                                            id="analyze"
                                            >Analyze Package&nbsp;
                                            <mdb-icon icon="magic" />
                                        </mdb-btn>
                                    </div>
                                </mdb-col>
                                <mdb-col col="6">
                                    <mdb-input
                                        data-test="accordion_searchTextInput"
                                        placeholder="Search"
                                        basic
                                        class="mb-3"
                                        v-model="searchText"
                                        @keydown.enter.native="searchData"
                                    >
                                        <span
                                            data-test="accordion_searchTextEmpty"
                                            class="input-group-text"
                                            slot="append"
                                            v-if="searchText"
                                            @click="searchText = ''"
                                            ><mdb-icon icon="times"
                                        /></span>
                                        <span
                                            data-test="accordion_searchDataIcon"
                                            class="input-group-text"
                                            slot="append"
                                            @click="searchData"
                                            ><mdb-icon icon="search"
                                        /></span>
                                    </mdb-input>
                                </mdb-col>
                            </mdb-row>
                            <vue-good-table
                                data-test="accordion_vue-good-table"
                                :rows="item.reportData"
                                :columns="columns"
                                mode="remote"
                                :isLoading.sync="item.isLoading"
                                :totalRows="totalRecords"
                                max-height="610px"
                                @on-row-click="onRowClick"
                                @on-sort-change="onSortChange"
                                @on-page-change="onPageChange"
                                @on-per-page-change="onPerPageChange"
                                :pagination-options="{
                                    enabled: true,
                                    mode: 'records',
                                    perPage: limit,
                                    position: 'bottom',
                                    perPageDropdown: limitOptions,
                                    dropdownAllowAll: false,
                                    setCurrentPage: currentPage,
                                    nextLabel: '>',
                                    prevLabel: '<',
                                    rowsPerPageLabel: 'Rows per page',
                                    ofLabel: 'of',
                                    pageLabel: 'page', // for 'pages' mode
                                    allLabel: 'All',
                                    total: totalRecords,
                                }"
                            >
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field == 'before'">
                                        <mdb-input
                                            type="radio"
                                            :id="'radio-group-selected-row-' + props.row.originalIndex"
                                            name="radio-group-selected-row"
                                            label=" "
                                            :radioValue="props.row.originalIndex.toString()"
                                            v-model="isSelected"
                                        /> </span
                                    >
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>
                                <div slot="emptystate">No records were found.</div>
                            </vue-good-table>
                        </div>
                    </mdb-accordion-pane>
                </mdb-accordion>
            </mdb-col>
            <mdb-col col="3">
                <mdb-card data-test="card" xs="12" col="auto" class="flex-fill" id="shipping-optimization-diag">
                    <mdb-card-header data-test="cardHeader" color="yellow lighten-4" tag="h3">
                        <mdb-row class="no-gutters">
                            <mdb-col col="4" class="font-weight-bold orange-text">
                                <mdb-icon icon="exclamation-triangle" size="2x" />
                            </mdb-col>
                            <mdb-col col="8" class="orange-text">
                                <p
                                    data-test="card_optimizationAmount"
                                    class="userdashannouncement-heading text-nowrap"
                                    v-if="!spinnerOptimizationAmounts"
                                >
                                    {{ userInfo.currencySymbol }}{{ optimizationAmounts }}
                                </p>
                                <mdb-spinner
                                    data-test="card_spinner"
                                    v-if="spinnerOptimizationAmounts"
                                    variant="warning"
                                    label="Loading"
                                ></mdb-spinner>
                                <p data-test="card_title" class="smaller orange-text">90 Day Opportunities</p>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-header>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <mdb-modal
            data-test="packageGenieModal"
            id="packageGenie"
            :show="showPackageGenie"
            @close="showPackageGenie = false"
            title="Package Genie"
            size="fluid"
        >
            <mdb-modal-header data-test="packageGenieModalHeader">
                <mdb-modal-title data-test="packageGenieModalTitle" class="w-full">
                    <mdb-icon icon="magic" class="mr-3" />
                    <span>Package Genie</span>
                </mdb-modal-title>
            </mdb-modal-header>
            <PackageGenie
                data-test="PackageGenie"
                :invoiceNum="selected.invoiceNum"
                :userInfo="userInfo"
                :headers="headers"
                :package="selected"
            />
        </mdb-modal>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PackageGenie from "@/components/PackageGenie";
import moment from "moment";
import {
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbAccordion,
    mdbCard,
    mdbCardHeader,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbSpinner,
    mdbAccordionPane,
    mdbBtn,
    mdbInput,
} from "mdbvue";
export default {
    name: "Opportunities",
    components: {
        PageHeader,
        PackageGenie,
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbAccordion,
        mdbCard,
        mdbCardHeader,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbSpinner,
        mdbAccordionPane,
        mdbBtn,
        mdbInput,
    },
    props: {
        userInfo: Object,
        headers: Object,
    },
    data() {
        return {
            optimizationAmounts: 0,
            spinnerOptimizationAmounts: false,
            oppItems: [],
            openPaneNum: null,
            limit: 10,
            currentPage: 1,
            totalRecords: 0,
            totalPages: 0,
            limitOptions: [10, 25, 50, 100],
            reportId: null,
            focusedPane: null,
            columns: {},
            isBusy: true,
            sortDir: "desc",
            showAnalyzer: false,
            showPackageGenie: false,
            searchText: null,
            selected: {
                invoiceNum: '0',
            },
            opportunityViewing: null,
            sortkey: "f_2_1",
            sortColumn: "f_2_2",
            isSelected: "0",
        };
    },
    methods: {
        async updateOptimizationAmounts() {
            return await this.$http.get(`/optimization`, { headers: this.headers }).then(async (response) => {
                let totalOptimizationAmount = 0;
                this.spinnerOptimizationAmounts = true;
                for (const item of response.data) {
                    await this.$http
                        .get(`/optimization/${item.opt_id}?cmpId=${this.userInfo.cmpID}`, { headers: this.headers })
                        .then((resp) => {
                            if (resp.data) {
                                let data = resp.data;
                                if (
                                    data &&
                                    data.length > 0 &&
                                    data[0].total !== 0 &&
                                    data[0].total != null &&
                                    data[0].total !== "null"
                                ) {
                                    item.data = data[0];
                                    item.opt_text = item.opt_text
                                        .replace("{count}", item.data.count)
                                        .replace("{_currency}", '')
                                        .replace("{total}", this.formatPrice(item.data.total, this.userInfo))
                                        .replace("lightbulb-o", "lightbulb");
                                    item.icon = item.opt_icon
                                        .replace("fa-", "")
                                        .replace("chain-broken", "unlink")
                                        .replace("trash-o", "trash-alt")
                                        .replace("share-square-o", "share-square")
                                        .replace("calendar-times-o", "calendar-alt")
                                        .replace("file-text-o", "file-alt")
                                        .replace("cloud-upload", "cloud-upload-alt")
                                    item.isLoading = false;
                                    totalOptimizationAmount += item.data.total;
                                    this.oppItems.push(item);
                                }
                            }
                        });
                }
                this.spinnerOptimizationAmounts = false;
                this.optimizationAmounts = parseFloat(totalOptimizationAmount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.isBusy = false;
            });
        },
        async getReportData(id, data) {
            const columns = await this.$httpReportsImpersonate.get(`/report/${id}?type=html&cmpId=${this.userInfo.cmpID}`, {
                headers: this.headers,
            });
            this.columns = [{ field: "before", label: "" }];
            for (let i = 0; i < columns.data.fields.length; i++) {
                this.columns.push({ field: columns.data.fields[i].code, label: columns.data.fields[i].display });
            }
            const packages = await this.$httpReportsImpersonate.post("/reportdata", data, {
                headers: this.headers,
            });
            if (packages.data) {
                this.totalRecords = packages.data.total_records;
                this.totalPages = packages.data.total_pages;
                return packages.data.items;
            }
        },
        async handlePaneOpened(num, item, action) {
            item.isLoading = true;
            if(action === 'open') {
                this.limit = 10;
                this.size = 10;
                this.currentPage = 1;
                this.sortDir = 'desc';
            }
            else {
                this.openPaneNum = null;
            }
            this.isSelected = "0";
            for (let i = 0; i < this.oppItems.length; i++) {
                this.oppItems[i].isActive = false;
            }
            item.isActive = true;
            const report = JSON.parse(item.opt_grid_columns);
            this.sortColumn = report.columns ? report.columns[0] : report.model;
            const data = {
                id: item.opt_report_id,
                cmpId: this.userInfo.cmpID,
                start: item.data.startDate,
                end: item.data.endDate,
                limit: this.limit,
                size: this.limit,
                page: this.currentPage,
                sortkey: this.sortColumn.id,
                sortDir: this.sortDir,
                sortColumn: this.sortColumn,
            };
            if (report.id === "f_4_4") {
                this.showAnalyzer = true;
            }
            this.reportId = item.opt_report_id;
            this.focusedPane = data;
            this.opportunityViewing = item;
            item.reportData = await this.getReportData(item.opt_report_id, data);
            for (let i = 0; i < item.reportData.length; i++) {
                item.reportData[i].f_22_2 = new moment(item.reportData[i].f_22_2).format("MMM DD, YYYY HH:mm");
                item.reportData[i].f_2_4 = new moment(item.reportData[i].f_2_4).format("MMM DD, YYYY HH:mm");
                item.reportData[i].f_22_11 = this.formatPrice(item.reportData[i].f_22_11, this.userInfo);
                item.reportData[i].f_4_37 = this.formatPrice(item.reportData[i].f_4_37, this.userInfo);
                item.reportData[i].f_2_25 = this.formatPrice(item.reportData[i].f_2_25, this.userInfo);
                item.reportData[i].f_4_34 = this.formatPrice(item.reportData[i].f_4_34, this.userInfo);
                item.reportData[i].f_7_3 = this.formatPrice(item.reportData[i].f_7_3, this.userInfo);
                item.reportData[i].f_4_48 = this.formatPrice(item.reportData[i].f_4_48, this.userInfo);
            }
            this.togglePane(num);
            item.isLoading = false;
        },
        togglePane(num) {
            if (this.openPaneNum === num) {
                return (this.openPaneNum = null);
            }
            this.openPaneNum = num;
        },
        async searchData() {
            const item = this.opportunityViewing;
            const report = JSON.parse(item.opt_grid_columns);
            const data = {
                id: item.opt_report_id,
                cmpId: this.userInfo.cmpID,
                start: item.data.startDate,
                end: item.data.endDate,
                limit: this.limit,
                size: this.limit,
                page: this.currentPage,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                f_4_4: this.searchText ? this.searchText : null,
            };
            this.reportId = item.opt_report_id;
            item.reportData = await this.getReportData(item.opt_report_id, data);
            this.$forceUpdate();
        },
        onSortChange(params) {
            this.sortkey = params[0].field;
            this.sortDir =
                params[0].type != "none"
                    ? params[0].type
                    : this.sortDir == "asc"
                    ? (this.sortDir = "desc")
                    : (this.sortDir = "asc");
            this.sortColumn = params[0].field;
            this.searchData();
        },
        async onPageChange(params) {
            this.focusedPane.currentPage = params.currentPage;
            this.focusedPane.page = params.currentPage;
            this.opportunityViewing.reportData = await this.getReportData(this.reportId, this.focusedPane);
            this.$forceUpdate();
        },
        async onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.focusedPane.page = 1;
            this.focusedPane.limit = this.limit;
            this.focusedPane.size = this.size;
            this.focusedPane.currentPage = 1;
            this.opportunityViewing.reportData = await this.getReportData(this.reportId, this.focusedPane);
            this.$forceUpdate();
        },
        onRowClick(params) {
            this.selected = params.row;
            this.isSelected = params.row.originalIndex.toString();
            this.selected.pld_trackingno = params.row.f_4_4;
            this.selected.invoiceNum = '0';
        },
    },
    mounted() {
        this.updateOptimizationAmounts();
    },
};
</script>

<style>
.keep-spaces {
    white-space: pre-wrap;
}
#overlay {
    position: fixed;
    z-index: 5000;
    opacity: .80;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-bottom: 25%;
}
</style>
