<template>
    <div class="m-4">
        <h3 class="my-4 mx-2">Activities Log</h3>
        <div v-if="activitiesLog">
            <vue-good-table
                styleClass="vgt-table condensed"
                :rows="activitiesLog"
                :columns="columns"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-search="searchErrors"
                :search-options="{
                    enabled: true,
                    trigger: 'enter',
                }"
                :totalRows="totalRows"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: this.limit,
                    position: 'top',
                    perPageDropdown: [10, 25, 50],
                    dropdownAllowAll: false,
                    setCurrentPage: this.page,
                }"
                :isLoading.sync="isLoading"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'se_resolved'">
                        {{
                            props.row.se_resolved === 1 ||
                            props.row.se_resolved === "1" ||
                            props.row.se_resolved === true
                                ? "YES"
                                : "NO"
                        }}
                    </span>
                    <span v-else-if="props.column.field === 'log_time'">
                        {{ formatDate(props.row.log_time) }}
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
        <div v-else class="text-center align-content-center"><mdb-spinner></mdb-spinner>Loading system errors...</div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "AdminActivitiesLog",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            activitiesLog: null,
            isLoading: false,
            columns: [
                {
                    label: "System Message",
                    field: "log_desc",
                },
                {
                    label: "Type",
                    field: "log_type",
                },
                {
                    label: "Time",
                    field: "log_time",
                },
            ],
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "log_time",
            sortDir: "desc",
            sortkey: "log_time",
            searchTerm: null,
            totalRows: null,
        };
    },
    computed: {
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
    },
    methods: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.getActivities();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.getActivities();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.getActivities();
        },
        async searchErrors(params) {
            this.isLoading = true;
            const found = await this.$httpAdmin.get(`activitylog?${this.params}&log_type=${params.searchTerm}`, {
                headers: this.headers,
            });
            this.isLoading = false;
            if (found) {
                this.activitiesLog = found.data[0].Items;
                this.totalRows = found.data[0].TotalRecords;
            }
        },
        async getActivities() {
            this.isLoading = true;
            try {
                const errors = await this.$httpAdmin.get(`activitylog?${this.params}`, { headers: this.headers });
                this.activitiesLog = errors.data[0].Items;
                this.totalRows = errors.data[0].TotalRecords;
            } catch (e) {
                this.$notify.error({ message: `Error retrieving records ${e}` });
            }
            this.isLoading = false;
        },
    },
    mounted() {
        this.getActivities();
    },
};
</script>
