var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-4"},[_vm._m(0),(_vm.fileErrors)?_c('div',[_c('vue-good-table',{attrs:{"styleClass":"vgt-table condensed","rows":_vm.fileErrors,"columns":_vm.columns,"mode":"remote","search-options":{
                enabled: true,
                trigger: 'enter',
            },"totalRows":_vm.totalRows,"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: this.limit,
                position: 'top',
                perPageDropdown: [10, 25, 50],
                dropdownAllowAll: false,
                setCurrentPage: this.page,
            },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.searchErrors,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'ep_start_time')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.ep_start_time))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3734244095)})],1):_c('div',{staticClass:"text-center align-content-center"},[_c('mdb-spinner'),_vm._v("Loading Extract File Errors... ")],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"my-4 mx-2"},[_vm._v("Extract File Errors "),_c('em',{staticClass:"text-md ml-4"},[_vm._v("Analyze Failed Extract Files")])])
}]

export { render, staticRenderFns }