<template>
    <div data-test="page" class="w-100">
        <h1 data-test="pageHeader" class="page-header border-bottom mx-2">
            Dashboard
            <span
                data-test="pageHeaderSmaller"
                class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block"
                >Shipping Optimization</span
            >
            <mdb-icon
                data-test="tour"
                icon="tachometer-alt"
                size="1x"
                @click.native="startTour"
                class="float-right text-muted"
                role="button"
                title="Tour this page"
                style="cursor: pointer"
            />
        </h1>

        <SummaryView data-test="SummaryView" :userInfo="userInfo" :headers="headers" :selectedCompany="selectedCompany" />
    </div>
</template>
<script>
import SummaryView from "@/components/SummaryView";
import { buttons, getTourDefaults } from "@/lib/shepherd-tour";
import { mdbIcon } from "mdbvue";

export default {
    name: "Dashboard",
    components: {
        SummaryView,
        mdbIcon,
    },
    props: {
        userInfo: Object,
        headers: Object,
      selectedCompany: Object,
    },
    data() {
        return {};
    },
    methods: {
        startTour() {
            const tour = this.$shepherd(getTourDefaults());
            const steps = [
                {
                    text: document.getElementById("step-one").innerHTML,
                    buttons: [
                        {
                            action() {
                                return this.next();
                            },
                            text: "Next",
                        },
                    ],
                    title: "Dashboard Tour",
                },
                {
                    attachTo: { element: "#shipping-claims-diag", on: "bottom" },
                    text: document.getElementById("step-two").innerHTML,
                    buttons,
                    title: "Claims Filed Panel",
                },
                {
                    attachTo: { element: "#shipping-recovered-diag", on: "bottom" },
                    text: document.getElementById("step-three").innerHTML,
                    buttons,
                    title: "Recovered to Date Panel",
                },
                {
                    attachTo: { element: "#shipping-optimization-diag", on: "bottom" },
                    text: document.getElementById("step-four").innerHTML,
                    buttons,
                    title: "Optimization Advisor Panel",
                },
                {
                    attachTo: { element: "#chart-container", on: "right" },
                    text: document.getElementById("step-five").innerHTML,
                    buttons,
                    title: "Charts Area",
                },
                {
                    attachTo: { element: "#sidebar", on: "left" },
                    text: document.getElementById("step-six").innerHTML,
                    buttons,
                    title: "Summary Panel",
                },
            ];

            tour.addSteps(steps);

            tour.start();
        },
    },
    created() {},
};
</script>
<style>
.smaller {
    font-weight: 400;
    line-height: 1;
    color: #999 !important;
    font-size: 65%;
}
</style>
