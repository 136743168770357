<template>
    <div class="overflow-auto h-90">
        <div>
            <h1 class="page-header border-bottom mx-2">
                Recovery Dashboard
                <span class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block">ADM/FIN View</span>
                <mdb-icon icon="chart-line" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row class="m-4" v-if="!isLoading">
                <mdb-col col="3">
                    <highcharts :options="refundTrendChart"></highcharts>
                </mdb-col>
                <mdb-col col="3">
                    <highcharts :options="rateTrendChart"></highcharts>
                </mdb-col>
                <mdb-col col="3">
                    <highcharts :options="spendTrendChart"></highcharts>
                </mdb-col>
                <mdb-col col="3">
                    <highcharts :options="volumeTrendChart"></highcharts>
                </mdb-col>
            </mdb-row>
            <mdb-row v-else class="d-flex align-items-center justify-content-center text-center text-primary mt-4">
                <mdb-col col="2">
                    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    <strong> ... Loading Charts</strong>
                </mdb-col>
            </mdb-row>
            <mdb-row class="m-4">
                <mdb-col lg="9">
                    <mdb-row v-if="resellerList" class="mb-2">
                        <mdb-col col="4">
                            <select
                                class="browser-default custom-select"
                                v-model="selectedReseller"
                                @change="createTable('update')"
                            >
                                <option value="" selected disabled>Select Reseller</option>
                                <option value="all resellers">All Resellers</option>
                                <option v-for="(reseller, index) of resellerList" v-bind:key="index" :value="reseller">
                                    {{ reseller.name }}
                                </option>
                            </select>
                        </mdb-col>
                        <mdb-col col="4">
                            <select
                                class="browser-default custom-select"
                                v-model="selectedCarrier"
                                @change="createTable('update')"
                            >
                                <option value="" selected disabled>Select Carrier</option>
                                <option value="all carriers">All Carriers</option>
                                <option v-for="(carrier, index) of carriers" v-bind:key="index" :value="carrier">
                                    {{ carrier.text }}
                                </option>
                            </select>
                        </mdb-col>
                        <mdb-col col="2">
                            <mdb-btn color="primary" size="sm" title="export as csv" @click.native="exportCSV"
                                ><mdb-icon icon="download"></mdb-icon
                            ></mdb-btn>
                        </mdb-col>
                    </mdb-row>
                    <vue-good-table
                        v-if="tableRows"
                        :columns="tableColumns"
                        :rows="tableRows"
                        :search-options="{ enabled: true, trigger: 'enter' }"
                        :isLoading.sync="isLoading"
                        mode="remote"
                        styleClass="vgt-table striped bordered"
                        @on-row-click="onRowClick"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-per-page-change="onPerPageChange"
                        @on-search="searchTable"
                        :totalRows="totalRecords"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: this.limit,
                            position: 'top',
                            perPageDropdown: [10, 25, 50, 100],
                            dropdownAllowAll: false,
                            setCurrentPage: this.page,
                        }"
                    >
                        <template slot="loadingContent"> <mdb-spinner></mdb-spinner> Loading data... </template>
                        <template slot="table-row" slot-scope="props">
                            <span
                                v-if="
                                    props.column.field === 'total' ||
                                    props.column.field === 'refund' ||
                                    props.column.field === 'manual_refund' ||
                                    props.column.field === 'customer_gross' ||
                                    props.column.field === 'eaudit_share' ||
                                    props.column.field === 'eaudit_paid'
                                "
                            >
                                {{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                            </span>
                            <span v-else-if="props.column.field === 'rate'">
                                {{ props.formattedRow[props.column.field] }}%
                            </span>
                            <span v-else-if="props.column.field === 'netWholesale'">
                                {{ props.formattedRow[props.column.field] }}%{{
                                    props.formattedRow[props.column.field] < 20 ? "**" : ""
                                }}
                            </span>
                            <span v-else-if="props.column.field === 'bookedWholesale'">
                                {{ props.formattedRow[props.column.field] }}%{{
                                    props.formattedRow[props.column.field] < 20 ? "**" : ""
                                }}
                            </span>
                            <span v-else-if="props.column.field === 'invWeeks'">
                                {{ props.formattedRow[props.column.field] }} weeks
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </mdb-col>
                <mdb-col lg="3" v-if="!isLoading">
                    <mdb-row class="ml-0 pr-3">
                        <mdb-card style="width: 100%">
                            <mdb-card-header
                                >Overall Summary<mdb-btn
                                    flat
                                    class="float-right p-0"
                                    @click.native="calculatorModal = true"
                                    size="lg"
                                    ><mdb-icon icon="calculator"></mdb-icon></mdb-btn
                            ></mdb-card-header>
                            <mdb-card-body>
                                <mdb-row>
                                    <mdb-col col="6">Last 30 Days</mdb-col>
                                    <mdb-col col="6">Total Invoiced Refunds</mdb-col>
                                </mdb-row>
                                <mdb-row>
                                    <mdb-col col="6"
                                        ><b>{{ thirtyDay }}%</b></mdb-col
                                    >
                                    <mdb-col col="6"
                                        ><b
                                            >{{ totalInvoiced ? formatPrice(totalInvoiced, userInfo) : "" }}</b
                                        ></mdb-col
                                    >
                                </mdb-row>
                                <hr />
                                <mdb-row>
                                    <mdb-col col="6">Last 90 Days</mdb-col>
                                    <mdb-col col="6">Last Checker Run</mdb-col>
                                </mdb-row>
                                <mdb-row>
                                    <mdb-col col="6"
                                        ><b>{{ actualRate }}%</b></mdb-col
                                    >
                                    <mdb-col col="6">{{ lastCheckerRun }}</mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-row>
                    <mdb-row class="mt-4">
                        <mdb-col col="12">
                            <highcharts :options="MTeamTrendChart"></highcharts>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row class="mt-4">
                        <mdb-col col="12">
                            <highcharts :options="netBilledRevenueChart"></highcharts>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row class="mt-4">
                        <mdb-col col="12">
                            <highcharts :options="bookedRevenueChart"></highcharts>
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
            </mdb-row>
        </div>
        <mdb-modal :show="calculatorModal" @close="calculatorModal = false" size="lg">
            <mdb-modal-header>
                <mdb-modal-title>Percentage Calculator</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-row>
                    <mdb-col col="12">
                        <section class="preview">
                            <mdb-input v-model="calcOneA" class="mb-2 mt-0" outline>
                                <span class="input-group-text md-addon" slot="prepend">What is</span>
                                <span class="input-group-text md-addon" slot="append">% of</span>
                                <mdb-input v-model="calcOneB" group slot="append" class="m-0 p-0" outline></mdb-input>
                                <span class="input-group-text md-addon" slot="append">?</span>
                                <mdb-btn outline="default" size="sm" group slot="append" @click.native="calcOne()"
                                    >Calculate</mdb-btn
                                >
                            </mdb-input>
                            <mdb-input v-model="calcOneC" class="mb-2 mt-0" outline>
                                <span class="input-group-text md-addon" slot="prepend">Answer:</span>
                            </mdb-input>
                        </section>
                    </mdb-col>
                </mdb-row>
                <hr />
                <mdb-row>
                    <mdb-col col="12">
                        <section class="preview">
                            <mdb-input v-model="calcTwoA" class="mb-2 mt-0" outline>
                                <span class="input-group-text md-addon" slot="append">is what percent of</span>
                                <mdb-input v-model="calcTwoB" group slot="append" class="m-0 p-0" outline></mdb-input>
                                <span class="input-group-text md-addon" slot="append">?</span>
                                <mdb-btn outline="default" size="sm" group slot="append" @click.native="calcTwo()"
                                    >Calculate</mdb-btn
                                >
                            </mdb-input>
                            <mdb-input v-model="calcTwoC" class="mb-2 mt-0" outline>
                                <span class="input-group-text md-addon" slot="prepend">Answer:</span>
                            </mdb-input>
                        </section>
                    </mdb-col>
                </mdb-row>
                <hr />
                <mdb-row>
                    <mdb-col col="12">
                        <section class="preview">
                            <mdb-input v-model="calcThreeA" class="mb-2 mt-0" outline>
                                <span class="input-group-text md-addon" slot="append">is</span>
                                <mdb-input v-model="calcThreeB" group slot="append" class="m-0 p-0" outline></mdb-input>
                                <span class="input-group-text md-addon" slot="append">% of what?</span>
                                <mdb-btn outline="default" size="sm" group slot="append" @click.native="calcThree()"
                                    >Calculate</mdb-btn
                                >
                            </mdb-input>
                            <mdb-input v-model="calcThreeC" class="mb-2 mt-0" outline>
                                <span class="input-group-text md-addon" slot="prepend">Answer:</span>
                            </mdb-input>
                        </section>
                    </mdb-col>
                </mdb-row>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn outline="info" @click.native="calculatorModal = false">Close</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        <mdb-modal v-if="selectedRow" :show="auditModal" @close="auditModal = false" size="fluid">
            <mdb-modal-header>
                <mdb-modal-title>{{ selectedRow.datetime }}</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-row>
                    <mdb-col class="px-1">
                        <mdb-select
                            outline
                            search
                            multiple
                            v-model="selectedRefundList"
                            @change="changeSelectedRefund"
                            placeholder="Select Refund"
                            style="min-width: 200px"
                        >
                        </mdb-select>
                    </mdb-col>
                    <mdb-col class="px-1">
                        <mdb-select
                            outline
                            multiple
                            search
                            v-model="analyzeCarriersList"
                            @change="changeSelectedCarrier"
                            placeholder="Select Carrier"
                        >
                        </mdb-select>
                    </mdb-col>
                    <mdb-col class="px-1">
                        <mdb-select
                            outline
                            search
                            multiple
                            v-model="analyzeResellerList"
                            @change="changeSelectedReseller"
                            placeholder="Select Reseller"
                        >
                        </mdb-select>
                    </mdb-col>
                    <mdb-col class="px-1">
                        <mdb-select
                            outline
                            search
                            multiple
                            v-model="analyzeStatusList"
                            @change="changeSelectedStatus"
                            placeholder="Select Status"
                        >
                        </mdb-select>
                    </mdb-col>
                    <mdb-col class="px-1">
                        <mdb-dropdown
                            id="dropdown-form2"
                            text="Select Services"
                            class="border rounded w-100 h-auto mt-3"
                            multiLevel
                        >
                            <mdb-dropdown-toggle
                                :color="selectedServicesActive ? 'info' : 'white'"
                                slot="toggle"
                                class="w-100"
                                >{{
                                    selectedServicesActive ? "Services Selected" : "Select Services"
                                }}</mdb-dropdown-toggle
                            >
                            <mdb-dropdown-menu class="myReportsScroll" style="min-width: 400px">
                                <form v-if="analyzeServicesList" class="w-100 mb-4">
                                    <div class="mb-1">
                                        <input
                                            class="form-control"
                                            type="text"
                                            placeholder="Search"
                                            aria-label="Search"
                                            @click.stop
                                            @keydown="filterSearch"
                                            v-model="dropdownFilter"
                                        />
                                    </div>
                                    <div v-if="dropdownFilter.length">
                                        <div v-for="(item, index) of filteredList" v-bind:key="index" class="m-0 p-0">
                                            <div class="w-100" v-if="showList">
                                                <mdb-input
                                                    type="checkbox"
                                                    v-bind:id="'checkbox-' + index"
                                                    v-bind:name="'checkbox-' + index"
                                                    :label="item.text"
                                                    value="true"
                                                    v-model="item.selected"
                                                    unchecked-value="false"
                                                    @change="updateByFilters"
                                                >
                                                </mdb-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div
                                            v-for="(service, index) of analyzeServicesList"
                                            v-bind:key="index"
                                            class="m-0 p-0"
                                        >
                                            <div class="w-100" v-if="showList">
                                                <mdb-input
                                                    type="checkbox"
                                                    v-bind:id="'checkbox-' + index"
                                                    v-bind:name="'checkbox-' + index"
                                                    :label="service.text"
                                                    value="true"
                                                    v-model="service.selected"
                                                    unchecked-value="false"
                                                    @change="updateActiveServices"
                                                >
                                                    <mdb-icon
                                                        v-if="showCaret"
                                                        icon="caret-down"
                                                        @click.native="service.visible = !service.visible"
                                                        group
                                                        slot="append"
                                                        class="w-10"
                                                    />
                                                </mdb-input>

                                                <div
                                                    id="collapse-accounts"
                                                    v-if="service.visible && dropdownFilter == ''"
                                                    class="form-inline"
                                                >
                                                    <div
                                                        class="cursor-pointer w-full ml-2"
                                                        v-for="child in service.children"
                                                        v-bind:key="child.text"
                                                    >
                                                        <mdb-row class="ml-4">
                                                            <mdb-col col="8">
                                                                <mdb-input
                                                                    :disabled="service.selected"
                                                                    type="checkbox"
                                                                    v-bind:id="'childCheckbox-' + index + child.text"
                                                                    v-bind:name="'childCheckbox-' + index + child.text"
                                                                    :label="child.text"
                                                                    value="true"
                                                                    v-model="child.selected"
                                                                    unchecked-value="false"
                                                                    @change="updateActiveServices"
                                                                    style="justify-content: start"
                                                                />
                                                            </mdb-col>
                                                            <mdb-col col="4">
                                                                <mdb-icon
                                                                    v-if="child.children.length"
                                                                    icon="caret-down"
                                                                    @click.native="childVisibility(child)"
                                                                />
                                                            </mdb-col>
                                                        </mdb-row>
                                                        <div
                                                            id="collapse-accounts2"
                                                            v-if="showChildren && child.visible && dropdownFilter == ''"
                                                            class="form-inline"
                                                        >
                                                            <div
                                                                class="cursor-pointer w-full ml-4"
                                                                v-for="child2 in child.children"
                                                                v-bind:key="child2.id"
                                                            >
                                                                <mdb-row class="ml-4">
                                                                    <mdb-col>
                                                                        <mdb-input
                                                                            :disabled="child.selected"
                                                                            type="checkbox"
                                                                            v-bind:id="'child2Checkbox-' + child2.id"
                                                                            v-bind:name="
                                                                                'child2Checkbox-' + child2.text
                                                                            "
                                                                            :label="child2.text"
                                                                            value="true"
                                                                            v-model="child2.selected"
                                                                            unchecked-value="false"
                                                                            @change="updateActiveServices"
                                                                            style="justify-content: start"
                                                                            class="text-nowrap"
                                                                        />
                                                                    </mdb-col>
                                                                </mdb-row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </mdb-dropdown-menu>
                        </mdb-dropdown>
                    </mdb-col>
                    <mdb-col class="px-1">
                        <DateSelector
                            id="dateDropdown"
                            :startDate="startDate"
                            :endDate="endDate"
                            @update-end-date="updateEndDate"
                            @update-start-date="updateStartDate"
                            class="m-1 mt-3 border rounded w-100"
                        />
                    </mdb-col>
                    <mdb-col>
                        <mdb-btn color="primary" class="mt-3" @click.native="createAnalyzeCharts"
                            ><mdb-icon icon="recycle" class="mr-2"></mdb-icon>Update</mdb-btn
                        >
                    </mdb-col>
                </mdb-row>
                <mdb-row v-if="!isLoading">
                    <mdb-col col="12">
                        <mdb-row>
                            <mdb-col col="12">
                                <highcharts v-if="trendsChart" :options="trendsChart"></highcharts>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row class="mt-4">
                            <highcharts v-if="analyzeRefundPieChart" :options="analyzeRefundPieChart"></highcharts>
                        </mdb-row>
                        <mdb-row class="mt-4">
                            <mdb-col>
                                <highcharts v-if="numOfAttemptsChart" :options="numOfAttemptsChart"></highcharts>
                            </mdb-col>
                            <mdb-col>
                                <highcharts v-if="statusChart" :options="statusChart"></highcharts>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col col="12">
                                <highcharts v-if="servicesChart" :options="servicesChart"></highcharts>
                            </mdb-col>
                        </mdb-row>
                    </mdb-col>
                </mdb-row>
                <mdb-row v-else class="d-flex align-items-center justify-content-center text-center text-primary mt-4">
                    <mdb-col col="2">
                        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                        <strong> ... Loading Charts</strong>
                    </mdb-col>
                </mdb-row>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn outline="primary" @click.native="auditModal = false">Close</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>

<script>
import moment from "moment";
import DateSelector from "@/components/DateSelector";
import AccountDropdown from "@/components/AccountDropdown";
import Papa from "papaparse";

export default {
    name: "RecoveryReportMonthly",
    components: {
        DateSelector,
        AccountDropdown,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            isLoading: true,
            tableCreated: null,
            tableRows: null,
            tableColumns: null,
            totalRecords: null,
            selectedRow: null,
            rowModal: false,
            table: null,
            resellerList: null,
            selectedReseller: "",
            selectedCarrier: "",
            serviceList: null,
            limit: 10,
            size: 10,
            page: 1,
            sortColumn: "datetime",
            sortkey: "datetime",
            sortDir: "desc",
            sparkValues: null,
            rangeSparks: [],
            actualRate: null,
            thirtyDay: null,
            totalInvoiced: null,
            calculatorModal: false,
            lastCheckerRun: null,
            carriers: [
                { value: 1, text: "FedEx", selected: false },
                { value: 2, text: "UPS", selected: false },
                { value: 3, text: "DHL Express", selected: false },
                { value: 4, text: "Purolator", selected: false },
                { value: 5, text: "DHL eCom", selected: false },
            ],
            sideCharts: null,
            refundTrendChart: null,
            rateTrendChart: null,
            spendTrendChart: null,
            volumeTrendChart: null,
            MTeamTrendChart: null,
            netBilledRevenueChart: null,
            bookedRevenueChart: null,
            calcOneA: null,
            calcOneB: null,
            calcOneC: null,
            calcTwoA: null,
            calcTwoB: null,
            calcTwoC: null,
            calcThreeA: null,
            calcThreeB: null,
            calcThreeC: null,
            auditModal: false,
            selectedRefundType: null,
            selectedAnalyzeCarrier: null,
            selectedAnalyzeReseller: null,
            selectedStatus: null,
            selectedService: null,
            startDate: null,
            endDate: null,
            serviceListOptions: null,
            serviceListNames: null,
            analyzeRefundData: null,
            analyzeRefundPieChart: null,
            analyzeRefundPieData: null,
            numOfAttemptsChart: null,
            servicesChart: null,
            statusChart: null,
            trendsChart: null,
            carrierTypeFedEx: false,
            fedExRegular: false,
            fedExCreditCard: false,
            fedExCustomerSubmits: false,
            fedExEDI: false,
            userAccounts: null,
            selectedAccountsActive: null,
            selectedServicesActive: false,
            dropdownFilter: "",
            filteredList: [],
            showList: true,
            filterSelected: [],
            showCaret: true,
            showChildren: false,
            serviceValues: [],
            searchList: [],
            selectedRefundList: [
                { value: "2", text: "Service Failure" },
                { value: "3", text: "Invalid Address Correction" },
                { value: "4", text: "Invalid Residential Surcharge" },
                { value: "5", text: "Manifested not Shipped (MNS)" },
                { value: "6", text: "Invalid Saturday Delivery" },
                { value: "7", text: "Invalid Saturday Pickup" },
                { value: "8", text: "No Signiture Obtained" },
                { value: "9", text: "Invalid Fuel Surcharge" },
                { value: "15", text: "Invalid DAS" },
                { value: "17", text: "Invalid Extended DAS" },
            ],
            analyzeResellerList: [
                { value: "1", text: "Transportation Impact" },
                { value: "2", text: "First Flight Solution" },
                { value: "3", text: "eAudit" },
                { value: "4", text: "Jarrett Logistics" },
                { value: "5", text: "Nolan and Cunnings" },
                { value: "6", text: "CMS Globalsoft" },
                { value: "7", text: "SIB" },
            ],
            analyzeCarriersList: [
                {
                    text: "FedEx",
                    value: null,
                    disabled: true,
                    optgroup: true,
                },
                { text: "Regular", value: "1_none" },
                { text: "FDX Credit Card", value: "1_cc" },
                { text: "Customer Submits", value: "1_manual" },
                { text: "EDI", value: "1_edi" },
                {
                    text: "UPS",
                    value: null,
                    disabled: true,
                    optgroup: true,
                },
                { text: "Billing Center", value: "2_billing center" },
                { text: "Billing Data", value: "2_billing data" },
                { text: "UPS Billing Center Canada", value: "2_billing center ca" },
                { text: "Customer Submits", value: "2_manual" },
                { text: "EDI", value: "2_edi" },
            ],
            analyzeStatusList: [
                { value: "-1", text: "Waiting to get additional information" },
                { value: "0", text: "Queued to be reconciled" },
                { value: "1", text: "Queued again to be reconciled" },
                { value: "100", text: "Had bot error during reconciliation" },
                { value: "101", text: "Had website error during reconciliation" },
                { value: "2", text: "Reconciled, waiting to be checked" },
                { value: "102", text: "In checking process" },
                { value: "3", text: "Accepted" },
                { value: "4", text: "Rejected" },
                { value: "5", text: "Could not get additional information" },
                { value: "50", text: "The status is not known (FedEx)" },
                { value: "1000", text: "No dispute reason" },
                { value: "1001", text: "GSR Waiver" },
                { value: "2000", text: "Already refunded" },
                { value: "500", text: "Account mismatch/ Voided shipment (UPS)" },
                { value: "700", text: "Unable to process the request (FedEx Captcha)" },
                { value: "303", text: "Unknown" },
            ],
            analyzeServicesList: [
                {
                    text: "FedEx",
                    visible: false,
                    children: [
                        { text: "Ground", value: "fedExGround", visible: false, children: [] },
                        { text: "Air", value: "fedExAir", visible: false, children: [] },
                        { text: "International", visible: false, value: "fedExInternational", children: [] },
                    ],
                },
                {
                    text: "UPS",
                    visible: false,
                    children: [
                        { text: "Ground", visible: false, value: "upsGround", children: [] },
                        { text: "Air", visible: false, value: "upsAir", children: [] },
                        { text: "International", visible: false, value: "upsInternational", children: [] },
                    ],
                },
                {
                    text: "DHL Express",
                    visible: false,
                    children: [
                        { text: "Ground", visible: false, value: "dhlGround", children: [] },
                        { text: "Air", visible: false, value: "dhlAir", children: [] },
                        { text: "International", visible: false, value: "dhlInternational", children: [] },
                    ],
                },
                {
                    text: "Purolator",
                    visible: false,
                    children: [
                        { text: "Ground", visible: false, value: "purolatorGround", children: [] },
                        { text: "Air", visible: false, value: "purolatorExAir", children: [] },
                        { text: "International", visible: false, value: "purolatorInternational", children: [] },
                    ],
                },
                {
                    text: "DHL eCom",
                    visible: false,
                    children: [
                        { text: "Ground", visible: false, value: "dhlecomGround", children: [] },
                        { text: "Air", visible: false, value: "dhlecomAir", children: [] },
                        { text: "International", visible: false, value: "dhlecomInternational", children: [] },
                    ],
                },
            ],
        };
    },
    methods: {
        async getInit() {
            this.tableCreated = await this.createTable("create");
            this.getComponents();
            this.getResellers();
            this.getServices();
            this.getStats();
        },
        async createTable(action) {
            this.table = [];
            this.isLoading = true;
            if (this.selectedReseller) {
                this.selectedAnalyzeReseller = [];
                for (let i = 0; i < this.analyzeResellerList.length; i++) {
                    if (this.analyzeResellerList[i].value == this.selectedReseller.id) {
                        this.analyzeResellerList[i].selected = true;
                        this.selectedAnalyzeReseller.push(this.analyzeResellerList[i].value);
                    }
                }
            }
            await this.$httpAdmin
                .get("/recoveryreport/monthly", {
                    headers: this.headers,
                    params: this.getTableParams(action),
                })
                .then(async (response) => {
                    this.table = response.data[0].Items;
                    this.totalRecords = response.data[0].TotalRecords;
                });
            for (let i = 0; i < this.table.length; i++) {
                if (this.table[i].datetime) {
                    this.table[i].datetime = new moment(this.table[i].datetime);
                    let billMonth = new moment(this.table[i].datetime).format("MMM YYYY");
                    this.table[i].invWeeks = this.saturdaysInMonth(billMonth);
                }
                this.table[i].rate
                    ? (this.table[i].rate = parseFloat(this.table[i].rate).toFixed(2))
                    : (this.table[i].rate = 0);
                this.table[i].manual_refund
                    ? (this.table[i].manual_refund = parseFloat(this.table[i].manual_refund).toFixed(2))
                    : (this.table[i].manual_refund = 0);
                if (!this.table[i].customer_gross) {
                    this.table[i].customer_gross = 0;
                }
                if (this.table[i].eaudit_share) {
                    this.table[i].netPPkg = (
                        (this.table[i].eaudit_share / this.table[i].packages).toFixed(3) * 100
                    ).toFixed(1);
                    this.table[i].netWholesale = ((this.table[i].eaudit_share / this.table[i].refund) * 100).toFixed(2);
                } else {
                    this.table[i].eaudit_share = 0;
                    this.table[i].netPPkg = 0;
                    this.table[i].netWholesale = 0;
                }
                this.table[i].packages
                    ? (this.table[i].packages = this.table[i].packages.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                    : (this.table[i].packages = 0);
                if (this.table[i].eaudit_paid) {
                    this.table[i].bookedWholesale = ((this.table[i].eaudit_paid / this.table[i].refund) * 100).toFixed(
                        2
                    );
                } else {
                    this.table[i].eaudit_paid = 0;
                    this.table[i].bookedWholesale = 0;
                }
            }
            this.tableRows = this.table;
            this.tableColumns = [
                {
                    field: "datetime",
                    label: "Date",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                    dateOutputFormat: "MMM yyyy",
                },
                { field: "invWeeks", label: "Inv Weeks" },
                { field: "total", label: "Total Spend" },
                { field: "packages", label: "Packages" },
                { field: "refund", label: "Refunds" },
                { field: "manual_refund", label: "MTeam" },
                { field: "rate", label: "Recovery Rate" },
                { field: "customer_gross", label: "Gross Billed" },
                { field: "eaudit_share", label: "Net Billed Rev" },
                { field: "netPPkg", label: "Net PPkg" },
                { field: "netWholesale", label: "Net Wholesale" },
                { field: "eaudit_paid", label: "Booked Rev" },
                { field: "bookedWholesale", label: "Booked Wholesale" },
            ];
            let time = [];
            let refund = [];
            let rate = [];
            let total = [];
            let volume = [];
            let mteam = [];
            let netBilled = [];
            let booked = [];
            for (let i = 0; i < this.table.length; i++) {
                time.push(new moment(this.table[i].datetime).format("MMM"));
                refund.push(this.table[i].refund);
                rate.push(parseFloat(this.table[i].rate));
                total.push(this.table[i].total);
                volume.push(parseFloat(this.table[i].packages.replace(/,/g, "")));
                mteam.push(parseFloat(this.table[i].manual_refund));
                netBilled.push(this.table[i].eaudit_share);
                booked.push(this.table[i].eaudit_paid);
            }
            (this.refundTrendChart = {
                chart: {
                    height: 300,
                    type: "line",
                    borderColor: "black",
                    borderWidth: 1,
                },
                title: {
                    text: "Refund Trend",
                },
                legend: {
                    enabled: false,
                },
                yAxis: {
                    title: {
                        enabled: true,
                        text: "Refund Amount",
                        style: {
                            fontWeight: "normal",
                        },
                    },
                },
                xAxis: {
                    categories: time.reverse(),
                },
                series: [
                    {
                        color: "green",
                        data: refund.reverse(),
                    },
                ],
            }),
                (this.rateTrendChart = {
                    chart: {
                        height: 300,
                        type: "line",
                        borderColor: "black",
                        borderWidth: 1,
                    },
                    title: {
                        text: "Rate Trend",
                    },
                    legend: {
                        enabled: false,
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: "Rate %",
                            style: {
                                fontWeight: "normal",
                            },
                        },
                    },
                    xAxis: {
                        categories: time.reverse(),
                    },
                    series: [
                        {
                            color: "blue",
                            data: rate.reverse(),
                        },
                    ],
                }),
                (this.spendTrendChart = {
                    chart: {
                        height: 300,
                        type: "line",
                        borderColor: "black",
                        borderWidth: 1,
                    },
                    title: {
                        text: "Spend Trend",
                    },
                    legend: {
                        enabled: false,
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: "Carrier Spend",
                            style: {
                                fontWeight: "normal",
                            },
                        },
                    },
                    xAxis: {
                        categories: time.reverse(),
                    },
                    series: [
                        {
                            color: "purple",
                            data: total.reverse(),
                        },
                    ],
                }),
                (this.volumeTrendChart = {
                    chart: {
                        height: 300,
                        type: "line",
                        borderColor: "black",
                        borderWidth: 1,
                    },
                    title: {
                        text: "Volume Trend",
                    },
                    legend: {
                        enabled: false,
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: "Package Volume",
                            style: {
                                fontWeight: "normal",
                            },
                        },
                    },
                    xAxis: {
                        categories: time.reverse(),
                    },
                    series: [
                        {
                            color: "red",
                            data: volume.reverse(),
                        },
                    ],
                }),
                (this.MTeamTrendChart = {
                    chart: {
                        height: 300,
                        type: "line",
                        borderColor: "black",
                        borderWidth: 1,
                    },
                    title: {
                        text: "MTeam Trend",
                    },
                    legend: {
                        enabled: false,
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: "Refund Amount",
                            style: {
                                fontWeight: "normal",
                            },
                        },
                    },
                    xAxis: {
                        categories: time.reverse(),
                    },
                    series: [
                        {
                            color: "orange",
                            data: mteam.reverse(),
                        },
                    ],
                }),
                (this.netBilledRevenueChart = {
                    chart: {
                        height: 300,
                        type: "line",
                        borderColor: "black",
                        borderWidth: 1,
                    },
                    title: {
                        text: "Net Billed Revenue",
                    },
                    legend: {
                        enabled: false,
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: "Net Billed Revenue",
                            style: {
                                fontWeight: "normal",
                            },
                        },
                    },
                    xAxis: {
                        categories: time.reverse(),
                    },
                    series: [
                        {
                            color: "violet",
                            data: netBilled.reverse(),
                        },
                    ],
                }),
                (this.bookedRevenueChart = {
                    chart: {
                        height: 300,
                        type: "line",
                        borderColor: "black",
                        borderWidth: 1,
                    },
                    title: {
                        text: "Booked Revenue",
                    },
                    legend: {
                        enabled: false,
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: "Booked Revenue",
                            style: {
                                fontWeight: "normal",
                            },
                        },
                    },
                    xAxis: {
                        categories: time.reverse(),
                    },
                    series: [
                        {
                            color: "green",
                            data: booked.reverse(),
                        },
                    ],
                }),
                (this.isLoading = false);
        },
        getTableParams(action) {
            return {
                reseller_id: action == "update" ? (this.selectedReseller ? this.selectedReseller.id : 0) : null,
                car_id: action == "update" ? (this.selectedCarrier ? this.selectedCarrier.value : 0) : null,
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
            };
        },
        async getComponents() {
            await this.$httpAdmin
                .get("/appcontroller/?action=getcomponent&clientName=web", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    let data = response.data[0];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].name === "Credit Check") {
                            this.lastCheckerRun = data[i].text(moment(data[i].end_time).format("MM/DD h:mm.ssa"));
                        }
                    }
                });
        },
        async getStats() {
            this.actualRate = (await this.$httpAdmin.get("Administration/actual-rate", { headers: this.headers })).data;
            this.thirtyDay = (
                await this.$httpAdmin.get("Administration/30day-recovery", { headers: this.headers })
            ).data;
            this.totalInvoiced = (
                await this.$httpAdmin.get("Administration/total-invoiced", { headers: this.headers })
            ).data;
        },
        async getResellers() {
            this.isLoading = true;
            await this.$httpAdmin
                .get("/reseller", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.resellerList = response.data;
                });
            this.isLoading = false;
        },
        async getServices() {
            this.isLoading = true;
            this.serviceListOptions = [];
            this.serviceListNames = [];
            await this.$httpAdmin
                .get("/service", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.serviceList = response.data;
                });
            for (let i = 0; i < this.serviceList.length; i++) {
                if (this.serviceList[i].srv_car_id == 1) {
                    if (this.serviceList[i].srv_type == "ground") {
                        this.analyzeServicesList[0].children[0].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "air") {
                        this.analyzeServicesList[0].children[1].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "international") {
                        this.analyzeServicesList[0].children[2].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    }
                } else if (this.serviceList[i].srv_car_id == 2) {
                    if (this.serviceList[i].srv_type == "ground") {
                        this.analyzeServicesList[1].children[0].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "air") {
                        this.analyzeServicesList[1].children[1].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "international") {
                        this.analyzeServicesList[1].children[2].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    }
                } else if (this.serviceList[i].srv_car_id == 3) {
                    if (this.serviceList[i].srv_type == "ground") {
                        this.analyzeServicesList[2].children[0].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "air") {
                        this.analyzeServicesList[2].children[1].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "international") {
                        this.analyzeServicesList[2].children[2].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    }
                }
                if (this.serviceList[i].srv_car_id == 4) {
                    if (this.serviceList[i].srv_type == "ground") {
                        this.analyzeServicesList[3].children[0].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "air") {
                        this.analyzeServicesList[3].children[1].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "international") {
                        this.analyzeServicesList[3].children[2].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    }
                }
                if (this.serviceList[i].srv_car_id == 5) {
                    if (this.serviceList[i].srv_type == "ground") {
                        this.analyzeServicesList[4].children[0].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "air") {
                        this.analyzeServicesList[4].children[1].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    } else if (this.serviceList[i].srv_type == "international") {
                        this.analyzeServicesList[4].children[2].children.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                        this.searchList.push({
                            text: this.serviceList[i].srv_name.toUpperCase(),
                            id: this.serviceList[i].srv_id,
                        });
                    }
                }
            }
            this.isLoading = false;
        },
        getDateRange() {
            this.startDate = new moment(this.selectedRow.datetime);
            this.endDate = new moment(this.startDate).endOf("month");
        },
        saturdaysInMonth(start) {
            let d = new Date("1 " + start);
            let ndays = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
            return Math.floor((ndays + ((d.getDay() + 7) % 7)) / 7);
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.createTable("update");
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.createTable("update");
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.createTable("update");
        },
        async searchTable(params) {
            this.searchTerm = params.searchTerm;
            await this.createTable("update");
        },
        onRowClick(params) {
            params.selected = true;
            this.selectedRow = params.row;
            this.getDateRange();
            this.createAnalyzeCharts();
            this.auditModal = true;
        },
        async createAnalyzeCharts() {
            this.isLoading = true;
            let trendsAccepted = [];
            let trendsRejected = [];
            let trendsProcessing = [];
            let trendsCategories = [];
            let trendsUnknown = [];
            await this.$httpAdmin
                .post("/recoveryreport/monthly", this.analyzeParams("trends"), {
                    headers: this.headers,
                })
                .then(async (response) => {
                    let trendsData = response.data;
                    for (let i = 0; i < trendsData.length; i++) {
                        if (!trendsCategories.includes(trendsData[i].date)) {
                            trendsCategories.push(trendsData[i].date);
                        }
                    }
                    for (let i = 0; i < trendsData.length; i++) {
                        if (trendsData[i].type == "accepted") {
                            trendsAccepted.push(trendsData[i].count);
                        } else if (trendsData[i].type == "rejected") {
                            trendsRejected.push(trendsData[i].count);
                        } else if (trendsData[i].type == "processing") {
                            trendsProcessing.push(trendsData[i].count);
                        } else {
                            trendsUnknown.push(trendsData[i].count);
                        }
                    }
                    for (let i = 0; i < trendsProcessing.length; i++) {
                        trendsProcessing[i] = trendsProcessing[i] + trendsUnknown[i];
                    }
                    if (trendsAccepted.length != trendsCategories.length) {
                        trendsAccepted.push(0);
                    }
                    if (trendsRejected.length != trendsCategories.length) {
                        trendsRejected.push(0);
                    }
                    if (trendsProcessing.length != trendsCategories.length) {
                        trendsProcessing.push(0);
                    }
                });
            this.trendsChart = {
                chart: {
                    type: "area",
                },
                title: {
                    text: "",
                },
                xAxis: {
                    allowDecimals: false,
                    type: "datetime",
                    categories: trendsCategories,
                },
                yAxis: {
                    title: {
                        text: "#Requests",
                    },
                },
                tooltip: {
                    shared: true,
                },
                plotOptions: {
                    area: {
                        marker: {
                            enabled: false,
                            symbol: "circle",
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: true,
                                },
                            },
                        },
                    },
                },
                series: [
                    {
                        name: "Accepted",
                        color: "#39A81D",
                        data: trendsAccepted,
                    },
                    {
                        name: "Rejected",
                        color: "#C43623",
                        data: trendsRejected,
                    },
                    {
                        name: "Processing",
                        color: "#38A2CF",
                        data: trendsProcessing,
                    },
                ],
            };
            this.analyzeRefundPieData = [];
            await this.$httpAdmin
                .post("/recoveryreport/monthly", this.analyzeParams("refunds-count"), {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.analyzeRefundData = response.data;
                });
            for (let i = 0; i < this.analyzeRefundData.length; i++) {
                this.analyzeRefundPieData.push({
                    name: this.analyzeRefundData[i].refund,
                    y: this.analyzeRefundData[i].count,
                });
            }
            this.analyzeRefundPieChart = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: "Requested Refund Types",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.percentage:.1f}",
                        },
                        showInLegend: true,
                    },
                },
                series: [
                    {
                        colorByPoint: true,
                        data: this.analyzeRefundPieData,
                    },
                ],
            };
            let attemptColumnData = [];
            await this.$httpAdmin
                .post("/recoveryreport/monthly", this.analyzeParams("attempts"), {
                    headers: this.headers,
                })
                .then(async (response) => {
                    let attemptData = response.data;
                    for (let i = 0; i < attemptData.length; i++) {
                        attemptColumnData.push(attemptData[i].count);
                    }
                });
            this.numOfAttemptsChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: "Number of Attempts",
                },
                subtitle: {
                    text: "#Requests/Attempts",
                },
                xAxis: {
                    categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8"],
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "#Requests",
                    },
                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "Attempts",
                        data: attemptColumnData,
                    },
                ],
            };
            let servicesCategories = [];
            let servicesTotal = [];
            let servicesProcessing = [];
            let servicesAccepted = [];
            let servicesRejected = [];
            await this.$httpAdmin
                .post("/recoveryreport/monthly", this.analyzeParams("services"), {
                    headers: this.headers,
                })
                .then(async (response) => {
                    let servicesData = response.data;
                    for (let i = 0; i < servicesData.length; i++) {
                        let index = servicesCategories.indexOf(servicesData[i].service);
                        if (index < 0) {
                            index = servicesCategories.length;
                            servicesCategories.push(servicesData[i].service);
                            servicesTotal[index] = 0;
                            servicesProcessing[index] = 0;
                            servicesAccepted[index] = 0;
                            servicesRejected[index] = 0;
                        }
                        if (servicesData[i].type == "accepted") {
                            servicesAccepted[index] += servicesData[i].count;
                        } else if (servicesData[i].type == "rejected") {
                            servicesRejected[index] += servicesData[i].count;
                        } else {
                            servicesProcessing[index] += servicesData[i].count;
                        }
                        servicesTotal[index] += servicesData[i].count;
                    }
                });
            this.servicesChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: "Candidates Processing Per Service",
                },
                xAxis: {
                    categories: servicesCategories,
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "#Candidates",
                    },
                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "Total",
                        color: "#000",
                        data: servicesTotal,
                    },
                    {
                        name: "Processing",
                        color: "#38A2CF",
                        data: servicesProcessing,
                    },
                    {
                        name: "Accepted",
                        color: "#39A81D",
                        data: servicesAccepted,
                    },
                    {
                        name: "Rejected",
                        color: "#C43623",
                        data: servicesRejected,
                    },
                ],
            };
            let statusChartData = [];
            await this.$httpAdmin
                .post("/recoveryreport/monthly", this.analyzeParams("status"), {
                    headers: this.headers,
                })
                .then(async (response) => {
                    let statusData = response.data;
                    for (let i = 0; i < statusData.length; i++) {
                        statusChartData.push({ name: statusData[i].status, y: statusData[i].count });
                    }
                });
            this.statusChart = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: "Credit Status",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.percentage:.1f}",
                        },
                        showInLegend: true,
                    },
                },
                series: [
                    {
                        colorByPoint: true,
                        data: statusChartData,
                    },
                ],
            };
            this.isLoading = false;
        },
        analyzeParams(type) {
            return {
                start: moment(this.startDate).format("YYYY-MM-DD"),
                end: moment(this.endDate).format("YYYY-MM-DD"),
                type: type,
                refIds: this.selectedRefund ? this.selectedRefund : null,
                prStatus: this.selectedStatus ? this.selectedStatus : null,
                resellerIds: this.selectedAnalyzeReseller ? this.selectedAnalyzeReseller : null,
                carIds: this.selectedAnalyzeCarrier ? this.selectedAnalyzeCarrier : null,
                services: this.serviceValues ? this.serviceValues : null,
            };
        },
        async exportCSV() {
            await this.$httpAdmin
                .get("/recoveryreport/monthly", {
                    headers: this.headers,
                    params: this.csvParams(),
                })
                .then(async (response) => {
                    let pageData = response.data;
                    let blob = new Blob([Papa.unparse(pageData)], { type: "text/csv;charset=utf-8;" });

                    let link = document.createElement("a");

                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", "Export.csv");
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        },
        csvParams() {
            return {
                reseller_id: this.selectedReseller ? this.selectedReseller.id : null,
                car_id: this.selectedCarrier ? this.selectedCarrier.value : null,
                limit: 0,
                size: 0,
                page: 1,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
            };
        },
        calcOne() {
            this.calcOneC = (parseFloat(this.calcOneA) / 100) * parseFloat(this.calcOneB);
        },
        calcTwo() {
            this.calcTwoC = (parseFloat(this.calcTwoA) / parseFloat(this.calcTwoB)) * 100;
        },
        calcThree() {
            this.calcThreeC = parseFloat(this.calcThreeA) / (parseFloat(this.calcThreeB) / 100);
        },
        updateStartDate(val) {
            this.startDate = val;
        },
        updateEndDate(val) {
            this.endDate = val;
        },
        changeSelectedRefund(val) {
            this.selectedRefund = val;
        },
        changeSelectedCarrier(val) {
            this.selectedAnalyzeCarrier = val;
        },
        changeSelectedReseller(val) {
            this.selectedAnalyzeReseller = val;
        },
        changeSelectedStatus(val) {
            this.selectedStatus = val;
        },
        changeSelectedService(val) {
            this.selectedService = [];
            for (let i = 0; i < val.length; i++) {
                for (let k = 0; k < this.analyzeServicesList.length; k++) {
                    if (this.analyzeServicesList[k].value == val[i]) {
                        for (let j = 0; j < this.analyzeServicesList[k].children.length; j++) {
                            this.selectedService.push(this.analyzeServicesList[k].children[j].id);
                        }
                    }
                }
            }
        },
        updateSelectedAccount(val) {
            this.userAccounts = val;
        },
        updateSelectedActive(val) {
            this.selectedAccountActive = val;
        },
        filterSearch() {
            if (this.dropdownFilter != "") {
                this.showList = false;
                this.filteredList = [];
                this.filteredList = this.searchList.filter((item) =>
                    this.dropdownFilter
                        .toUpperCase()
                        .split(" ")
                        .every((v) => item.text.includes(v))
                );
            }
            this.showList = true;
        },
        updateByFilters() {
            this.filterSelected = [];
            for (let i = 0; i < this.filteredList.length; i++) {
                if (this.filteredList[i].selected) {
                    this.filterSelected.push(this.filteredList[i].text);
                }
            }
            this.updateActiveServices();
        },
        updateActiveServices() {
            this.selectedServicesActive = false;
            this.serviceValues = [];
            for (const service of this.analyzeServicesList) {
                if (service.selected || this.filterSelected.includes(service.text)) {
                    service.selected = true;
                    this.selectedServicesActive = true;
                    for (const child1 of service.children) {
                        child1.selected = true;
                        for (const child2 of child1.children) {
                            child2.selected = true;
                            this.serviceValues.push(child2.id);
                        }
                    }
                } else {
                    for (const child of service.children) {
                        if (child.selected || this.filterSelected.includes(service.text)) {
                            child.selected = true;
                            this.selectedServicesActive = true;
                            for (const child2 of child.children) {
                                child2.selected = true;
                                this.serviceValues.push(child2.id);
                            }
                        } else {
                            for (const child2 of child.children) {
                                if (child2.selected || this.filterSelected.includes(child2.text)) {
                                    child2.selected = true;
                                    this.selectedServicesActive = true;
                                    this.serviceValues.push(child2.id);
                                }
                            }
                        }
                    }
                }
            }
        },
        childVisibility(child) {
            this.showChildren = false;
            child.visible = !child.visible;
            this.showChildren = true;
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>

<style scoped></style>
