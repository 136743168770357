<template>
    <div data-test="page" class="h-100 pb-2">
        <mdb-row class="spacer15 mb-4">
            <mdb-col col="9">
                <mdb-row class="flex no-gutters">
                    <mdb-card
                        data-test="approvalsCard"
                        xs="12"
                        col="auto"
                        class="mr-2 flex-fill"
                        id="shipping-claims-diag"
                    >
                        <mdb-card-header data-test="approvalsCardHeader" color="light-blue lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="blue-text">
                                    <mdb-icon icon="retweet" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="blue-text">
                                    <p
                                        data-test="approvalsCard_refundNum"
                                        class="userdashannouncement-heading"
                                        v-if="!spinnerRefundNum"
                                    >
                                        {{ refundNum }}
                                    </p>
                                    <mdb-spinner
                                        data-test="approvalsCard_spinnerRefundNum"
                                        v-if="spinnerRefundNum"
                                        variant="primary"
                                        label="Loading"
                                    ></mdb-spinner>
                                    <p data-test="approvalsCard_title" class="smaller blue-text">90 Day Approvals</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link data-test="approvalsCard_viewApproved" to="audit">
                                View Approved
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card
                        data-test="recoveriesCard"
                        xs="12"
                        col="auto"
                        class="mr-2 flex-fill"
                        id="shipping-recovered-diag"
                    >
                        <mdb-card-header data-test="recoveriesCardHeader" color="green lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="green-text">
                                    <mdb-icon icon="thumbs-up" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="green-text">
                                    <p
                                        data-test="recoveriesCardHeader_refundAmount"
                                        class="userdashannouncement-heading"
                                        v-if="!spinnerRefundAmount"
                                    >
                                        {{ userInfo.currencySymbol }}{{ refundAmount }}
                                    </p>
                                    <mdb-spinner
                                        data-test="recoveriesCardHeader_spinnerRefundAmount"
                                        v-if="spinnerRefundAmount"
                                        variant="success"
                                        label="Loading"
                                    ></mdb-spinner>
                                    <p data-test="recoveriesCardHeader_title" class="smaller green-text">
                                        90 Day Recoveries
                                    </p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link data-test="recoveriesCardHeader_viewRecovered" to="audit">
                                View Recovered
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card
                        data-test="opportunitiesCard"
                        xs="12"
                        col="auto"
                        class="flex-fill"
                        id="shipping-optimization-diag"
                    >
                        <mdb-card-header data-test="opportunitiesCardHeader" color="yellow lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="font-weight-bold orange-text">
                                    <mdb-icon icon="exclamation-triangle" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="orange-text">
                                    <p
                                        data-test="opportunitiesCardHeader_optimizationAmounts"
                                        class="userdashannouncement-heading text-nowrap"
                                        v-if="!spinnerOptimizationAmounts"
                                    >
                                        {{ userInfo.currencySymbol }}{{ optimizationAmounts }}
                                    </p>
                                    <mdb-spinner
                                        data-test="opportunitiesCardHeader_spinnerOptimizationAmounts"
                                        v-if="spinnerOptimizationAmounts"
                                        variant="warning"
                                        label="Loading"
                                    ></mdb-spinner>
                                    <p data-test="opportunitiesCardHeader_title" class="smaller orange-text">
                                        90 Day Opportunities
                                    </p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link data-test="opportunitiesCardHeader_opportunities" to="opportunities">
                                View Opportunities
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span> </router-link
                            >>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-row>
                <mdb-row id="chart-container" class="mt-2">
                    <mdb-col
                        data-test="charts"
                        col="6"
                        class="mt-2"
                        v-for="(chart, index) in charts"
                        v-bind:key="index"
                    >
                        <mdb-card data-test="charts_card">
                            <mdb-card-header data-test="charts_cardHeader" color="grey lighten-4" tag="h3">
                                <mdb-row>
                                    <mdb-col data-test="charts_cardHeader_heading" col="9" class="grey-text small">{{
                                        chart.heading
                                    }}</mdb-col>
                                    <mdb-col
                                        data-test="charts_cardHeader_buttonsRight"
                                        col="3"
                                        class="btn-group text-sm-center text-right"
                                        v-if="chart.buttonsRight"
                                    >
                                        <span
                                            data-test="charts_cardHeader_buttonsRight_dateTime"
                                            class="text-muted smaller mr-2 mt-2"
                                            v-if="chart.dateTime && chart.dateTime !== 'c00'"
                                            >Last
                                            {{ chart.dateTime ? chart.dateTime.replace("d", "") : "90" }} days</span
                                        >
                                        <span
                                            class="text-muted smaller mr-2 mt-2"
                                            v-if="chart.dateTime && chart.dateTime === 'c00'"
                                            >{{ chart.extraParams.startTime }} <br />
                                            {{ chart.extraParams.endTime }}</span
                                        >
                                        <mdb-btn-group data-test="charts_cardHeader_buttonsRight_btnGroup" size="sm">
                                            <mdb-popover
                                                data-test="charts_cardHeader_buttonsRight_btnGroup_popover"
                                                trigger="click"
                                                :options="{ placement: 'top' }"
                                            >
                                                <span
                                                    data-test="charts_cardHeader_buttonsRight_btnGroup_popover_buttonsRight"
                                                    slot="body"
                                                    >{{ chart.buttonsRight }}</span
                                                >
                                                <mdb-btn
                                                    slot="reference"
                                                    color="white"
                                                    class="btn-xs border text-muted p-2"
                                                >
                                                    <mdb-icon icon="info" size="xs" />
                                                </mdb-btn>
                                            </mdb-popover>
                                            <mdb-btn
                                                data-test="charts_cardHeader_buttonsRight_btnGroup_extraParamsBtn"
                                                v-if="chart.extraParams"
                                                @click.native="
                                                    showUnpinModal = true;
                                                    unpinningChart = chart;
                                                "
                                                class="btn-xs border text-muted p-1"
                                                color="white"
                                            >
                                                <mdb-icon icon="thumbtack" size="xs" />
                                            </mdb-btn>
                                        </mdb-btn-group>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-header>
                            <mdb-card-body class="mt-0 py-0 h-100 mb-2">
                                <highcharts
                                    data-test="charts_highcharts"
                                    :constructor-type="chart.chartType"
                                    :options="chart"
                                ></highcharts>
                                <div class="clear"></div>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
            </mdb-col>

            <mdb-col col="3">
                <mdb-card data-test="shippingSummaryCard" id="sidebar">
                    <mdb-card-header
                        data-test="shippingSummaryCardHeader"
                        color="grey lighten-4"
                        class="small grey-text"
                        tag="h3"
                    >
                        <span class="grey-text">Shipping Summary</span>
                        <div class="float-right text-muted"><small>Last 90 Days</small></div>
                    </mdb-card-header>
                    <mdb-card-body data-test="shippingSummaryCardBody" class="p-0 m-2">
                        <mdb-row>
                            <mdb-col data-test="shippingSummaryCardBody_totalSpendWeekly" col="5" class="text-right">
                                <div class="text-right">Total Spend</div>
                                <h4 class="text-lg-right py-1">
                                    {{ userInfo.currencySymbol }}{{ totalSpendWeekly.toLocaleString() }}
                                </h4>
                            </mdb-col>
                            <mdb-col col="7" class="p-0 m-0">
                                <div class="text-right">
                                    <mdb-spinner
                                        data-test="shippingSummaryCardBody_showSpinnerTotalSpendWeekly"
                                        v-if="showSpinnerTotalSpendWeekly"
                                        variant="primary"
                                        label="Loading"
                                    ></mdb-spinner>
                                    <div id="index_total_spend_weekly">
                                        <highcharts
                                            data-test="shippingSummaryCardBody_totalSpendWeekly_highcharts"
                                            :options="sideCharts[1]"
                                        ></highcharts>
                                    </div>
                                </div>
                            </mdb-col>
                        </mdb-row>
                        <hr class="m-0 py-1" />
                        <mdb-row>
                            <mdb-col data-test="shippingSummaryCardBody_shipmentsWeekly" col="5" class="text-right">
                                <div class="text-right">Shipments</div>
                                <h4 class="text-lg-right py-1">{{ shipmentsWeekly.toLocaleString() }}</h4>
                            </mdb-col>
                            <mdb-col col="7" class="p-0 m-0">
                                <div class="text-right">
                                    <mdb-spinner
                                        data-test="shippingSummaryCardBody_showSpinnerShipmentsWeekly"
                                        v-if="showSpinnerShipmentsWeekly"
                                        variant="primary"
                                        label="Loading"
                                    ></mdb-spinner>
                                    <div id="index_total_shipments_weekly">
                                        <highcharts
                                            data-test="shippingSummaryCardBody_shipmentsWeekly_highcharts"
                                            :options="sideCharts[2]"
                                        ></highcharts>
                                    </div>
                                </div>
                            </mdb-col>
                        </mdb-row>
                        <hr class="m-0 py-1" />
                        <mdb-row class="row">
                            <mdb-col data-test="shippingSummaryCardBody_avgCostWeekly" col="5" class="text-right">
                                <div class="text-right">Avg Cost</div>
                                <h4 class="text-lg-right py-1">
                                    {{ userInfo.currencySymbol }}{{ avgCostWeekly.toLocaleString() }}
                                </h4>
                            </mdb-col>
                            <mdb-col col="7" class="p-0 m-0">
                                <div class="text-right">
                                    <mdb-spinner
                                        data-test="shippingSummaryCardBody_showSpinnerAvgCostWeekly"
                                        v-if="showSpinnerAvgCostWeekly"
                                        variant="primary"
                                        label="Loading"
                                    ></mdb-spinner>
                                    <div id="index_shipment_avg_cost_weekly">
                                        <highcharts
                                            data-test="shippingSummaryCardBody_avgCostWeekly_highcharts"
                                            :options="sideCharts[3]"
                                        ></highcharts>
                                    </div>
                                </div>
                            </mdb-col>
                        </mdb-row>
                        <hr class="m-0" />
                        <div class="p-2 mb-3">
                            <router-link data-test="shippingSummaryCard_viewMore" to="invoices">
                                <div class="inline-block float-left col-xs-10 text-left">View More</div>
                                <div class="col-xs-2 float-right text-right inline-block">
                                    <mdb-icon icon="arrow-circle-right" />
                                </div>
                            </router-link>
                        </div>
                    </mdb-card-body>
                </mdb-card>
                <mdb-card data-test="carrierVolumeCard" class="my-4">
                    <mdb-card-header
                        data-test="carrierVolumeCardHeader"
                        color="grey lighten-4"
                        class="small grey-text"
                        tag="h3"
                    >
                        <span class="grey-text">Carrier Volume</span>
                        <div class="float-right text-muted"><small>Last 90 Days</small></div>
                    </mdb-card-header>
                    <mdb-card-body class="p-0">
                        <div id="index_carrier_service_type">
                            <highcharts
                                data-test="carrierVolumeCard_highcharts"
                                class="w-80"
                                :options="sideCharts[0]"
                            ></highcharts>
                        </div>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <mdb-modal
            data-test="unpinModal"
            v-if="showUnpinModal"
            @close="showUnpinModal = false"
            hide-footer
            hide-header
            size="xl"
        >
            <mdb-modal-header data-test="unpinModalHeader">
                <h3>Unpin Chart</h3>
            </mdb-modal-header>
            <mdb-modal-body data-test="unpinModalBody" class="d-block text-center">
                <p>Are you sure you want to unpin this chart?</p>
            </mdb-modal-body>
            <mdb-modal-footer
                ><mdb-btn
                    data-test="unpinModal_cancelBtn"
                    class="mt-3 float-right w-25"
                    color="danger"
                    block
                    @click.native="showUnpinModal = false"
                    >Cancel</mdb-btn
                >
                <mdb-btn
                    data-test="unpinModal_continueBtn"
                    class="mt-3 float-right w-25"
                    color="success"
                    block
                    @click.native="unpinChart()"
                    >Continue</mdb-btn
                ></mdb-modal-footer
            >
        </mdb-modal>
        <SetupModal data-test="SetupModal" :show="showSetupModal" />
        <AnnouncementModal data-test="AnnouncementModal" :show="showAnnouncementModal" />
        <DashboardTour data-test="DashboardTour" />
        <div class="clearfix mb-5"></div>
    </div>
</template>

<script>
import SetupModal from "./SetupModal";
import AnnouncementModal from "./AnnouncementModal";
import DashboardTour from "./DashboardTour";
import maps from "../lib/charts";
import {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbIcon,
    mdbSpinner,
    mdbCardBody,
    mdbBtnGroup,
    mdbPopover,
    mdbBtn,
} from "mdbvue";

export default {
    name: "SummaryView",
    components: {
        SetupModal,
        AnnouncementModal,
        DashboardTour,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardHeader,
        mdbIcon,
        mdbSpinner,
        mdbCardBody,
        mdbBtnGroup,
        mdbPopover,
        mdbBtn,
    },
    props: {
        userInfo: Object,
        headers: Object,
    },
    data() {
        return {
            optimizationAmounts: 0,
            refundAmount: 0,
            refundNum: 0,
            spinnerRefundNum: true,
            spinnerOptimizationAmounts: true,
            spinnerRefundAmount: true,
            totalShipments90: "",
            totalSpent90: null,
            showChartExpanded: false,
            showSpinnerTotalSpendWeekly: true,
            showSpinnerAvgCostWeekly: true,
            showSpinnerShipmentsWeekly: true,
            numOfOptimizationRequests: 0,
            totalOptimizationAmount: 0,
            totalSpendWeekly: "",
            shipmentsWeekly: "",
            shipmentsAvgCost: 0,
            avgCostWeekly: 0,
            expandedChart: {},
            charts: [],
            showSetupModal: false,
            showAnnouncementModal: false,
            showExpChart: false,
            sideCharts: [
                {
                    chartOptions: {
                        name: "carrier_service_type",
                    },
                    chart_name: "carrier_service_type",
                    chart_type: "donut",
                    type: "donut",
                    title: {
                        text: "",
                    },
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "total_spend_weekly",
                    },
                    chart_name: "total_spend_weekly",
                    chart_type: "sparkline",
                    type: "sparkline",
                    title: {
                        text: "",
                    },
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "total_shipments_weekly",
                    },
                    chart_name: "total_shipments_weekly",
                    chart_type: "sparkline",
                    type: "sparkline",
                    title: {
                        text: "",
                    },
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "shipment_avg_cost_weekly",
                    },
                    chart_name: "shipment_avg_cost_weekly",
                    chart_type: "sparkline",
                    type: "sparkline",
                    title: {
                        text: "",
                    },
                    expanded: false,
                },
            ],
            showUnpinModal: false,
            unpinningChart: null,
        };
    },
    methods: {
        async unpinChart() {
            const url = `/chartuser/${this.unpinningChart.chart_id}`;
            await this.$http.delete(url, { headers: this.headers });
            this.$notify.success({ message: "Chart was successfully unpinned." });
            this.showUnpinModal = false;
            await this.initCharts();
        },
        async updateOptimizationAmounts() {
            return await this.$http.get(`/optimization`, { headers: this.headers }).then(async (response) => {
                let totalOptimizationAmount = 0;
                for (const item of response.data) {
                    await this.$http
                        .get(`/optimization/${item.opt_id}?cmpId=${this.userInfo.cmpID}`, { headers: this.headers })
                        .then((resp) => {
                            if (resp && resp.data) {
                                let data = resp.data;
                                if (
                                    data &&
                                    data.length > 0 &&
                                    data[0].total !== 0 &&
                                    data[0].total != null &&
                                    data[0].total !== "null"
                                ) {
                                    data = data[0];
                                    totalOptimizationAmount += data.total;
                                }
                            }
                        });
                }
                this.spinnerOptimizationAmounts = false;
                return parseFloat(totalOptimizationAmount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
        },
        async getTotalRefund() {
            return await this.$http
                // eslint-disable-next-line no-undef
                .get(`/index?cmpId=${this.userInfo.cmpID}&request=totalApproved`, {
                    headers: this.headers,
                })
                .then(async (resp) => {
                    this.spinnerRefundAmount = false;
                    if (!resp || !resp.data) {
                        return 0;
                    }
                    const data = resp.data;
                    const totalRefundAmount = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return totalRefundAmount;
                });
        },
        async getNumRefund() {
            return await this.$http
                // eslint-disable-next-line no-undef
                .get(`/index?cmpId=${this.userInfo.cmpID}&request=numApproved`, {
                    headers: this.headers,
                })
                .then(async (resp) => {
                    this.spinnerRefundNum = false;
                    return resp.data;
                });
        },
        async populateAVGCost() {
            this.sideCharts[3].tooltip = {
                formatter: function () {
                    return this.series.name + ": $" + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                outside: true,
            };
            if (this.totalSpent90 / this.totalShipments90 > 0) {
                this.avgCostWeekly = parseFloat(this.totalSpent90 / this.totalShipments90).toFixed(2);
            } else {
                this.avgCostWeekly = "Pending...";
            }
            this.showSpinnerAvgCostWeekly = false;
        },
        async getChartUserInfo() {
            const params = {
                userPage: "dashboard",
                limit: 4,
                page: 1,
                callback: null,
                sortCol: "chart_order",
                sortDir: "asc",
            };
            if (params.sortCol && params.sortCol != undefined) {
                if (
                    !params.sortDir ||
                    params.sortDir == undefined ||
                    params.sortDir.toLowerCase() != "asc" ||
                    params.sortDir.toLowerCase() != "desc"
                )
                    params.sortDir = "asc";

                params.orderBy = "&sortColumn=" + params.sortCol + "&sortDir=" + params.sortDir;
            }
            return await this.$http.get(
                "/chartuser?page=" +
                    params.page +
                    "&limit=" +
                    params.limit +
                    "&chart_user_page=" +
                    params.userPage +
                    params.orderBy,
                { headers: this.headers }
            );
        },
        async getChartData(data) {
            let params;
            if (data.chart_user_param) {
                params = JSON.parse(data.chart_user_param);
            }
            const param = {
                cmpId: this.userInfo.cmpID,
                chartName: data.chart_name,
                chartType: data.chart_type,
                ...params,
            };
            let url = "/chartdata";
            // if (data.chart_user_category == "superuser") url = "/superuser" + url;

            return await this.$http.post(url, param, { headers: this.headers });
        },
        calculateTotalSpendWeekly(data) {
            this.sideCharts[1].tooltip = {
                formatter: function () {
                    return this.series.name + ": $" + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                outside: true,
            };
            this.totalSpent90 = 0;
            for (var i = 0; i < data.series[0].data.length; i++) this.totalSpent90 += data.series[0].data[i].y;

            this.populateAVGCost();
            if (this.totalSpent90 > 0) {
                this.totalSpendWeekly = Math.round(this.totalSpent90);
            } else {
                // //hide checklist if all are complete (webregisteredusers only for now)
                // $('#setupContainerChecklist').hide();
                // //$('#setupContainerGettingReady').hide();
                // $('#setupModal').modal('show');
                //
                // //messageBox("<br><h4>Your initial data download has not completed yet.  This can take several days.<br><br>Please contact support with any questions while we finish up here.</h4><br>", "<H1>We\'re still getting ready!</H1>", "warning");
                // $("#index_total_spend_weekly").parent().parent().prev().find("span").html("<i>Pending...</i>");
            }
            this.showSpinnerTotalSpendWeekly = false;
        },
        calculateTotalShipmentsWeekly(data) {
            this.sideCharts[2].tooltip = {
                formatter: function () {
                    return this.series.name + ":" + this.y.toLocaleString();
                },
                outside: true,
            };
            this.totalShipments90 = 0;
            for (var i = 0; i < data.series[0].data.length; i++) this.totalShipments90 += data.series[0].data[i].y;
            this.populateAVGCost();
            if (this.totalShipments90 > 0) {
                this.shipmentsWeekly = this.totalShipments90;
            } else {
                this.shipmentsWeekly = "Pending...";
            }
            this.showSpinnerShipmentsWeekly = false;
        },
        async initCharts() {
            const charts = await this.getChartUserInfo();
            this.charts = [];
            for (const user of charts.data[0].Items) {
                let type = null;
                if (user.chart_type === "map") {
                    type = {
                        map: "countries/us/us-all",
                    };
                } else {
                    type = { type: user.chart_type };
                }
                const newChart = {
                    type: user.chart_type,
                    title: {
                        text: user.chart_user_title,
                    },
                    chartType: "chart",
                    chart: {
                        ...type,
                    },
                    chartButtons: user.chart_user_description,
                    dateTime: user.chart_user_param ? JSON.parse(user.chart_user_param).dateTime : "90",
                };
                const data = (await this.getChartData(user)).data;
                if (data.name == "Service Type") {
                    for (let i = 0; i < data.series[0].data.length; i++) {
                        if (data.series[0].data[i][0] == "" || data.series[0].data[i][0] == null) {
                            data.series[0].data[i][0] = "Other";
                        }
                    }
                }
                let series = maps.drawChart({ ...newChart, ...data });
                this.charts.push({
                    ...newChart,
                    ...series,
                    heading: user.chart_user_title,
                    extraParams: JSON.parse(user.chart_user_param),
                    chart_id: user.chart_user_id,
                });
            }

            for (let i = 0; i < this.sideCharts.length; i++) {
                let chart = this.sideCharts[i];
                const data = (await this.getChartData(chart)).data;
                this.sideCharts[i] = maps.drawChart({ ...chart, ...data });
                if (chart.chart_name === "shipment_avg_cost_weekly") {
                    this.populateAVGCost();
                }
                if (chart.chart_name === "total_shipments_weekly") {
                    this.calculateTotalShipmentsWeekly(data);
                }
                if (chart.chart_name === "total_spend_weekly") {
                    this.calculateTotalSpendWeekly(data);
                }
            }

            this.refundNum = await this.getNumRefund();
            this.refundAmount = await this.getTotalRefund();
            this.optimizationAmounts = await this.updateOptimizationAmounts();
        },
    },
    async mounted() {
        this.initCharts();
    },
};
</script>
<style>
.middle {
    vertical-align: center;
}
.popover {
    min-width: 300px;
}
</style>
