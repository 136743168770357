<template>
    <div data-test="page" class="overflow-auto h-90">
        <div>
            <h1 data-test="pageHeader" class="page-header border-bottom mx-2">
                Reseller Invoice Analysis
                <mdb-icon icon="file" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row class="mt-4 mx-2">
                <mdb-col col="3">
                    <select
                        data-test="createTableSelect"
                        class="browser-default custom-select"
                        v-model="selectedReseller"
                        @change="createTable('update')"
                    >
                        <option data-test="selectResellerOption" value="" selected disabled>Select Reseller</option>
                        <option data-test="allResellersOption" value="all resellers">All Resellers</option>
                        <option
                            data-test="resellerListOption"
                            v-for="(reseller, index) of resellerList"
                            v-bind:key="index"
                            :value="reseller"
                        >
                            {{ reseller.name }}
                        </option>
                    </select>
                </mdb-col>
                <mdb-col col="3">
                    <select
                        data-test="selectedMonthSelect"
                        class="browser-default custom-select"
                        v-model="selectedMonth"
                        @change="createTable('update')"
                    >
                        <option data-test="selectMonthOption" value="" selected disabled>Select Month</option>
                        <option data-test="allMonthsOption" value="all months">All Months</option>
                        <option
                            data-test="monthsOptionsOption"
                            v-for="(month, index) of monthsOptions"
                            v-bind:key="index"
                            :value="month"
                        >
                            {{ month.text }}
                        </option>
                    </select>
                </mdb-col>
                <mdb-col col="2">
                    <mdb-btn
                        data-test="exportCSVBtn"
                        color="primary"
                        size="sm"
                        title="export as csv"
                        @click.native="exportCSV"
                        ><mdb-icon icon="download"></mdb-icon
                    ></mdb-btn>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mt-4 mx-2" v-if="tableRows">
                <mdb-col col="12">
                    <vue-good-table
                        data-test="vue-good-table"
                        :columns="tableColumns"
                        :rows="tableRows"
                        :search-options="{ enabled: true, trigger: 'enter' }"
                        styleClass="vgt-table striped bordered"
                        @on-row-click="onRowClick"
                        :sort-options="{ enabled: true }"
                        mode="remote"
                        :isLoading.sync="loading"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-per-page-change="onPerPageChange"
                        @on-search="searchTable"
                        :totalRows="totalRecords"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: this.limit,
                            position: 'top',
                            perPageDropdown: [10, 25, 50, 100],
                            dropdownAllowAll: false,
                            setCurrentPage: this.page,
                        }"
                    >
                        <template slot="loadingContent"> <mdb-spinner></mdb-spinner> Loading data... </template>
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'ri_audit_amount' || props.column.field === 'ri_total'">
                                {{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                            </span>
                            <span v-else-if="props.column.field === 'ri_no_pkgs'">
                                {{ props.formattedRow[props.column.field].toLocaleString() }}
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </mdb-col>
            </mdb-row>
            <mdb-modal data-test="rowModal" :show="rowModal" @close="rowModal = false" v-if="selectedRow">
                <mdb-modal-body>
                    <mdb-btn data-test="downloadInvoiceBtn" color="primary" @click.native="downloadInvoice"
                        >Download Invoice <mdb-icon icon="download" class="ml-2"></mdb-icon
                    ></mdb-btn>
                    <mdb-btn data-test="previouslyNotBilledBtn" color="primary" @click.native="previouslyNotBilled"
                        >View Previously not Billed<mdb-icon icon="eye" class="ml-2"></mdb-icon
                    ></mdb-btn>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn data-test="rowModalCloseBtn" outline="primary" @click.native="rowModal = false"
                        >Close</mdb-btn
                    >
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal
                data-test="downloadModal"
                :show="downloadModal"
                @close="downloadModal = false"
                v-if="selectedRow"
            >
                <mdb-modal-header>
                    <mdb-modal-title data-test="downloadModalTitle"
                        ><mdb-icon icon="file-pdf" class="mr-2"></mdb-icon>Invoice Ready</mdb-modal-title
                    >
                </mdb-modal-header>
                <mdb-modal-body data-test="downloadModalBody" v-if="invoiceDownloaded">
                    <mdb-btn data-test="downloadBtn" color="primary" @click.native="download"
                        ><mdb-icon icon="download" class="mr-2"></mdb-icon>Download Invoice
                        {{ selectedRow.ri_number }}</mdb-btn
                    >
                </mdb-modal-body>
                <mdb-modal-body data-test="downloadModalBodyElse" v-else>
                    <p>
                        Sorry! We could not find your invoice file! please go to My Reports and run the "Invoice"
                        report.
                    </p>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn data-test="downloadModalCloseBtn" outline="primary" @click.native="downloadModal = false"
                        >Close</mdb-btn
                    >
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal
                data-test="previouslyNotBilledModal"
                :show="previouslyNotBilledModal"
                @close="previouslyNotBilledModal = false"
                v-if="previouslyNotBilledData"
                size="fluid"
            >
                <mdb-modal-header>
                    <mdb-tab default>
                        <mdb-tab-item
                            data-test="previouslyNotBilledModalTab1"
                            :active="active == 0"
                            @click.native.prevent="active = 0"
                            ><mdb-icon icon="user" class="mr-2"></mdb-icon>Previously not billed</mdb-tab-item
                        >
                        <mdb-tab-item
                            data-test="previouslyNotBilledModalTab2"
                            :active="active == 1"
                            @click.native.prevent="active = 1"
                            ><mdb-icon icon="chart-area" class="mr-2"></mdb-icon>Previously not billed -
                            NPOD</mdb-tab-item
                        >
                    </mdb-tab>
                </mdb-modal-header>
                <mdb-modal-body>
                    <mdb-tab-content>
                        <mdb-tab-pane
                            data-test="previouslyNotBilledModalPane1"
                            class="fade"
                            key="show1"
                            v-if="active == 0"
                        >
                            <mdb-tbl
                                data-test="previouslyNotBilledModalPane1Table"
                                cellpadding="0"
                                cellspacing="0"
                                border="0"
                                striped
                            >
                                <mdb-tbl-head>
                                    <tr>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_description">
                                            Description
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_invoiceBilledDate">
                                            Invoice Billed Date
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_trackingNumber">
                                            Tracking Number
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_accountNumber">
                                            Account Number
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_invoiceNumber">
                                            Invoice Number
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_invoiceDate">
                                            Invoice Date
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_refundDate">
                                            Refund Date
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_refundType">
                                            Refund Type
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane1Table_th_refundAmount">
                                            Refund Amount
                                        </th>
                                    </tr>
                                    <tr v-for="(row, index) of previouslyNotBilledData" v-bind:key="index">
                                        <td data-test="previouslyNotBilledModalPane1Table_td_prevmonth">
                                            {{ row.prevmonth }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane1Table_td_bill_date">
                                            {{ row.bill_date }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane1Table_td_pr_pld_trackingno">
                                            {{ row.pr_pld_trackingno }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane1Table_td_pr_acct_number">
                                            {{ row.pr_acct_number }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane1Table_td_pr_inv_number">
                                            {{ row.pr_inv_number }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane1Table_td_pr_inv_date">
                                            {{ row.pr_inv_date }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane1Table_td_pr_last_check_date">
                                            {{ row.pr_last_check_date }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane1Table_td_ref_name">
                                            {{ row.ref_name }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane1Table_td_pr_refund_amount">
                                            ${{ row.pr_refund_amount }}
                                        </td>
                                    </tr>
                                </mdb-tbl-head>
                            </mdb-tbl>
                        </mdb-tab-pane>
                        <mdb-tab-pane
                            data-test="previouslyNotBilledModalPane2"
                            class="fade"
                            key="show2"
                            v-if="active == 1"
                        >
                            <mdb-tbl
                                data-test="previouslyNotBilledModalPane2Table"
                                cellpadding="0"
                                cellspacing="0"
                                border="0"
                                striped
                            >
                                <mdb-tbl-head>
                                    <tr>
                                        <th data-test="previouslyNotBilledModalPane2Table_th_trackingNumber">
                                            Tracking Number
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane2Table_th_accountNumber">
                                            Account Number
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane2Table_th_invoiceNumber">
                                            Invoice Number
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane2Table_th_invoiceDate">
                                            Invoice Date
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane2Table_th_refundDate">
                                            Refund Date
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane2Table_th_refundAmount">
                                            Refund Amount
                                        </th>
                                        <th data-test="previouslyNotBilledModalPane2Table_th_claimNumber">
                                            Claim Number
                                        </th>
                                    </tr>
                                    <tr v-for="(npods, index) of previouslyNotBilledDataNPODS" v-bind:key="index">
                                        <td data-test="previouslyNotBilledModalPane2Table_td_npr_pld_trackingno">
                                            {{ npods.npr_pld_trackingno }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane2Table_td_acct_number">
                                            {{ npods.acct_number }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane2Table_td_inv_number">
                                            {{ npods.inv_number }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane2Table_td_inv_date">
                                            {{ npods.inv_date }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane2Table_td_npr_payment_date">
                                            {{ npods.npr_payment_date }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane2Table_td_npr_refund_amount">
                                            ${{ npods.npr_refund_amount }}
                                        </td>
                                        <td data-test="previouslyNotBilledModalPane2Table_td_npr_claim_number">
                                            {{ npods.npr_claim_number }}
                                        </td>
                                    </tr>
                                </mdb-tbl-head>
                            </mdb-tbl>
                        </mdb-tab-pane>
                    </mdb-tab-content>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn
                        data-test="previouslyNotBilledModalCloseBtn"
                        outline="primary"
                        @click.native="previouslyNotBilledModal = false"
                        >Close</mdb-btn
                    >
                </mdb-modal-footer>
            </mdb-modal>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Papa from "papaparse";
import {
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbModalFooter,
    mdbModalHeader,
    mdbModalBody,
    mdbModal,
    mdbTabItem,
    mdbTabPane,
    mdbTbl,
    mdbTabContent,
    mdbTblHead,
    mdbTab,
    mdbModalTitle,
} from "mdbvue";

export default {
    name: "AdminResellerInvoices",
    components: {
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbBtn,
        mdbSpinner,
        mdbModalFooter,
        mdbModalHeader,
        mdbModalBody,
        mdbModal,
        mdbTabItem,
        mdbTabPane,
        mdbTbl,
        mdbTabContent,
        mdbTblHead,
        mdbTab,
        mdbModalTitle,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
        downloadUniversal: Function,
    },
    data() {
        return {
            loading: false,
            active: 0,
            table: [],
            tableRows: null,
            tableColumns: null,
            rowModal: false,
            selectedRow: null,
            downloadModal: false,
            resellers: null,
            resellerList: null,
            selectedReseller: "",
            monthsOptions: [],
            selectedMonth: "",
            filteredResellers: null,
            previouslyNotBilledModal: false,
            previouslyNotBilledData: null,
            previouslyNotBilledDataNPODS: null,
            invoiceToDownload: null,
            invoiceDownloaded: false,
            limit: 25,
            size: 25,
            page: 1,
            totalRecords: 0,
            sortColumn: "ri_number",
            sortkey: "ri_number",
            sortDir: "desc",
            searchTerm: null,
        };
    },
    methods: {
        async getInit() {
            this.tableCreated = await this.createTable("create");
            this.resellers = await this.getResellers();
            this.months = await this.getMonths();
        },
        async createTable(action) {
            this.loading = true;
            this.table = {};
            this.tableRows = {};
            this.tableColumns = {};
            await this.$httpAdmin
                .get("/resellerinvoice", {
                    headers: this.headers,
                    params: this.tableParams(action),
                })
                .then(async (response) => {
                    this.table = response.data[0].Items;
                    this.totalRecords = response.data[0].TotalRecords;
                });

            for (let i = 0; i < this.table.length; i++) {
                if (this.table[i].ri_number == "E01-0798-18071") {
                }
                if (this.table[i].ri_audit_amount) {
                    this.table[i].ri_audit_amount = this.table[i].ri_audit_amount * -1;
                } else {
                    this.table[i].ri_audit_amount = 0;
                }
                this.table[i].ri_one_month_adj =
                    parseFloat(this.table[i].ri_one_month_adj) +
                    parseFloat(this.table[i].ri_two_month_adj) +
                    parseFloat(this.table[i].ri_three_month_adj);
                if (this.table[i].ri_one_month_adj) {
                    this.table[i].ri_one_month_adj = this.table[i].ri_one_month_adj * -1;
                } else {
                    this.table[i].ri_one_month_adj = 0;
                }
                if (!this.table[i].ri_bill_type) {
                    this.table[i].ri_bill_type = "";
                } else if (this.table[i].ri_bill_type.toLowerCase() == "gainshare") {
                    this.table[i].ri_bill_type = "Gain Share";
                } else if (this.table[i].ri_bill_type.toLowerCase() == "flatfee") {
                    this.table[i].ri_bill_type = "Flat Fee";
                }
                if (this.table[i].ri_bill_type.toLowerCase() == "transactional") {
                    this.table[i].ri_bill_type = "Transactional";
                }
                if (!this.table[i].ri_total) {
                    this.table[i].ri_total = 0;
                }
                this.table[i].ri_start_date = new moment(this.table[i].ri_start_date).format("YYYY MMMM");
                this.table[i].eq_sent_date = new moment(this.table[i].eq_sent_date).format("MMM DD, YYYY");
            }
            this.tableRows = this.table;
            this.tableColumns = [
                { field: "ri_cmp_id", label: "Cmp ID", sortable: true },
                { field: "ri_number", label: "Invoice", sortable: true },
                { field: "reseller_name", label: "Reseller", sortable: true },
                { field: "cmp_name", label: "Company", sortable: true },
                { field: "ri_no_pkgs", label: "Packages", sortable: true },
                { field: "ri_audit_amount", label: "Audit Credits", sortable: true, sortFn: this.sortFn },
                { field: "ri_one_month_adj", label: "Previous", sortable: true, sortFn: this.sortFn },
                { field: "ri_bill_type", label: "Bill Type", sortable: true },
                { field: "ri_bill_value", label: "Bill Value", sortable: true },
                { field: "ri_adjustments", label: "Adj", sortable: true },
                { field: "ri_total", label: "Invoice Total", sortable: true, sortFn: this.sortFn },
                { field: "ri_start_date", label: "Bill Period", sortable: true },
                { field: "ri_qb_invnum", label: "ACCT ID", sortable: true },
                { field: "eq_sent_date", label: "Sent Date", sortable: true },
            ];
            this.loading = false;
        },
        tableParams(action) {
            return {
                ri_start_date: action == "update" ? (this.selectedMonth ? this.selectedMonth.value : "") : null,
                reseller_id: action == "update" ? (this.selectedReseller ? this.selectedReseller.id : 0) : null,
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                cmp_name: this.searchTerm ? this.searchTerm : null,
            };
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.createTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.createTable();
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.createTable();
        },
        async searchTable(params) {
            this.searchTerm = params.searchTerm;
            await this.createTable();
        },
        async getResellers() {
            await this.$httpAdmin
                .get("/reseller", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.resellerList = response.data;
                });
        },
        async previouslyNotBilled() {
            await this.$httpAdmin
                .get("/resellerinvoice/" + this.selectedRow.ri_number, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.previouslyNotBilledData = response.data;
                    if (this.previouslyNotBilledData) {
                        for (let i = 0; i < this.previouslyNotBilledData.length; i++) {
                            this.previouslyNotBilledData[i].bill_date = new moment(
                                this.previouslyNotBilledData[i].bill_date
                            ).format("MM/DD/YYYY");
                            this.previouslyNotBilledData[i].pr_inv_date = new moment(
                                this.previouslyNotBilledData[i].pr_inv_date
                            ).format("MM/DD/YYYY");
                            this.previouslyNotBilledData[i].pr_last_check_date = new moment(
                                this.previouslyNotBilledData[i].pr_last_check_date
                            ).format("MM/DD/YYYY");
                        }
                    }
                });
            await this.$httpAdmin
                .get("/resellerinvoice/" + this.selectedRow.ri_number + "/npods", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.previouslyNotBilledDataNPODS = response.data;
                    if (this.previouslyNotBilledDataNPODS) {
                        for (let i = 0; i < this.previouslyNotBilledDataNPODS.length; i++) {
                            this.previouslyNotBilledDataNPODS[i].inv_date = new moment(
                                this.previouslyNotBilledDataNPODS[i].inv_date
                            ).format("MM/DD/YYYY");
                            this.previouslyNotBilledDataNPODS[i].npr_payment_date = new moment(
                                this.previouslyNotBilledDataNPODS[i].npr_payment_date
                            ).format("MM/DD/YYYY");
                        }
                    }
                });
            this.rowModal = false;
            this.previouslyNotBilledModal = true;
        },
        getMonths() {
            this.monthsOptions = [];
            let firstDay = new moment([new Date().getFullYear(), new Date().getMonth()]);
            let diff = 5 - firstDay.day();
            diff = diff < 0 ? diff + 21 : diff + 14;
            let thirdFriday = moment(firstDay).add(diff, "days").hour(0);
            let startDate = firstDay;
            if (new moment().isBefore(thirdFriday)) startDate = moment(firstDay).add(-5, "days");
            startDate.add(-1, "M");
            let eAduditStartMonth = new moment([2014, 11, 1]);
            while (startDate.isAfter(eAduditStartMonth)) {
                this.monthsOptions.push({
                    text: startDate.format("YYYY MMMM"),
                    value: startDate.format("YYYY-MM") + "-01",
                });
                startDate.add(-1, "M");
            }
        },
        sortFn(x, y, col) {
            return x < y ? -1 : x > y ? 1 : 0;
        },
        onRowClick(params) {
            params.selected = true;
            this.selectedRow = params.row;
            this.rowModal = true;
        },
        async downloadInvoice() {
            await this.$http
                .get("/resellerinvoice/file/" + this.selectedRow.ri_number + "?cmpId=" + this.selectedRow.ri_cmp_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.invoiceToDownload = response.data;
                    if (this.invoiceToDownload && this.invoiceToDownload != "not found") {
                        this.invoiceDownloaded = true;
                    } else {
                        this.invoiceDownloaded = false;
                    }
                });
            this.downloadModal = true;
        },
        download() {
            this.downloadUniversal(this.invoiceToDownload.ri_number, "pdf");
        },
        async exportCSV() {
            await this.$httpAdmin
                .get("/resellerinvoice", {
                    headers: this.headers,
                    params: this.csvParams(),
                })
                .then(async (response) => {
                    let pageData = response.data;
                    let blob = new Blob([Papa.unparse(pageData)], { type: "text/csv;charset=utf-8;" });

                    let link = document.createElement("a");

                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", "Export.csv");
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        },
        csvParams() {
            return {
                ri_start_date: this.selectedMonth ? this.selectedMonth.value : "",
                reseller_id: this.selectedReseller ? this.selectedReseller.id : 0,
                limit: 0,
                size: 0,
                page: 1,
                sortColumn: "ri_number",
                sortkey: "ri_number",
                sortDir: "desc",
            };
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
