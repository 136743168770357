// Define a new component called PageHeader
<template>
    <h1 class="page-header">
        {{ pageTitle }}
        <small class="muted" style="font-size: 70%">{{ pageSubTitle }}</small>
        <span class="userpageicon" style="font-size: 43px; float: right">
            <mdb-icon :icon="iconName" @click.native="startTour" />
        </span>
    </h1>
</template>
<script>
export default {
    name: "PageHeader",
    props: {
        pageTitle: String,
        pageSubTitle: String,
        iconName: String,
        startTour: Function,
    },
};
</script>
