<template>
    <div>
        <mdb-row v-if="isBusy" id="overlay" class="justify-content-center align-content-center h-100vh">
            <mdb-spinner data-test="accordion_spinner" class="justify-content-center"></mdb-spinner>
            <h3><b>...Loading</b></h3>
        </mdb-row>
        <mdb-modal-body data-test="modal" :style="{ opacity: isBusy ? 0.3 : 1 }">
            <mdb-row class="col-6">
                <mdb-col col="8" class="input-group md-form text-right py-1 my-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text default-color">
                            <mdb-icon
                                data-test="getInvoiceDataIcon"
                                icon="search"
                                class="text-white"
                                @click.native="getInvoiceData(invoiceSearch)"
                            />
                        </span>
                    </div>
                    <input
                        data-test="getInvoiceDataInput"
                        class="form-control border-default"
                        type="text"
                        placeholder="Search Invoices"
                        aria-label="Search Invoices"
                        v-model="invoiceSearch"
                        @keydown.enter="getInvoiceData(invoiceSearch)"
                    />
                    <div class="input-group-append" v-if="invoiceSearch">
                        <mdb-btn data-test="clearInvoiceSearchBtn" variant="info" @click.native="invoiceSearch = ''"
                            ><mdb-icon icon="times"
                        /></mdb-btn>
                    </div>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col xl="5" md="12" class="p-1">
                    <mdb-card data-test="fileCard" class="p-2">
                        <mdb-row>
                            <mdb-col col="2">
                                <mdb-icon
                                    data-test="fileCardIcon"
                                    icon="file-alt"
                                    size="3x"
                                    class="mt-4 text-muted ml-2"
                                />
                            </mdb-col>
                            <mdb-col col="10">
                                <mdb-row>
                                    <mdb-col col="4">
                                        <p data-test="fileCardInvoice" class="text-muted">Invoice</p>
                                        <p
                                            data-test="fileCardInvoice_invoicePopulatedNumber"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{ invoicePopulated.number }}
                                        </p>
                                        <p data-test="fileCardCarrier" class="text-muted">Carrier</p>
                                        <p
                                            data-test="fileCardCarrier_invoicePopulatedCarrier"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{ invoicePopulated.carrier }}
                                        </p>
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <p data-test="fileCardAccount" class="text-muted">Account</p>
                                        <p
                                            data-test="fileCardAccount_invoicePopulatedAccount_number"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{ invoicePopulated.account_number }}
                                        </p>
                                        <p data-test="fileCardInvoiceType" class="text-muted">Invoice Type</p>
                                        <p
                                            data-test="fileCardInvoiceType_invoicePopulatedType"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{ invoicePopulated.type }}
                                        </p>
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <p data-test="fileCardInvoiceDate" class="text-muted">Invoice Date</p>
                                        <p
                                            data-test="fileCardInvoiceDate_invoicePopulatedDate"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{ invoicePopulated.date }}
                                        </p>
                                        <p data-test="fileCardStatus" class="text-muted">Status</p>
                                        <p
                                            data-test="fileCardStatus_invoicePopulatedStatus"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{ invoicePopulated.status }}
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card>
                </mdb-col>
                <mdb-col xl="4" md="12" class="p-1">
                    <mdb-card data-test="moneyCard" class="p-2">
                        <mdb-row>
                            <mdb-col col="4">
                                <mdb-icon
                                    data-test="moneyCardIcon"
                                    icon="money-bill"
                                    size="3x"
                                    class="mt-4 text-muted ml-2"
                                />
                            </mdb-col>
                            <mdb-col col="8">
                                <mdb-row>
                                    <mdb-col col="6">
                                        <p data-test="moneyCardTotalSpend" class="text-muted">Total Spend</p>
                                        <p
                                            data-test="moneyCardTotalSpend_invoicePopulatedAmount"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{
                                                invoicePopulated.amount != null
                                                    ? formatPrice(invoicePopulated.amount, userInfo)
                                                    : ""
                                            }}
                                        </p>
                                        <p data-test="moneyCardMisc" class="text-muted">Misc</p>
                                        <p
                                            data-test="moneyCardMisc_invoicePopulatedMiscAmount"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{
                                                invoicePopulated.misc_amount != null
                                                    ? formatPrice(invoicePopulated.misc_amount, userInfo)
                                                    : ""
                                            }}
                                        </p>
                                    </mdb-col>
                                    <mdb-col col="6">
                                        <p data-test="moneyCardAccesorial" class="text-muted">Accesorial</p>
                                        <p
                                            data-test="moneyCardAccesorial_invoicePopulatedAccesorialAmount"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{
                                                invoicePopulated.accesorial_amount != null
                                                    ? formatPrice(invoicePopulated.accesorial_amount, userInfo)
                                                    : ""
                                            }}
                                        </p>
                                        <p data-test="moneyCardFuel" class="text-muted">Fuel</p>
                                        <p
                                            data-test="moneyCardFuel_invoicePopulatedFuelAmount"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{
                                                invoicePopulated.fuel_amount != null
                                                    ? formatPrice(invoicePopulated.fuel_amount, userInfo)
                                                    : ""
                                            }}
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card>
                </mdb-col>
                <mdb-col xl="3" md="12" class="p-1">
                    <mdb-card data-test="cubesCard" class="p-2">
                        <mdb-row>
                            <mdb-col col="4">
                                <mdb-icon
                                    data-test="cubesCardIcon"
                                    icon="cubes"
                                    size="3x"
                                    class="mt-4 text-muted ml-2"
                                />
                            </mdb-col>
                            <mdb-col col="8">
                                <mdb-row>
                                    <mdb-col col="6">
                                        <p data-test="cubesCardPackages" class="text-muted">Packages</p>
                                        <p
                                            data-test="cubesCardPackages_invoicePopulatedNoPacks"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{
                                                invoicePopulated.no_packs
                                                    ? invoicePopulated.no_packs.toLocaleString()
                                                    : ""
                                            }}
                                        </p>
                                        <p data-test="cubesCardShipments" class="text-muted">Shipments</p>
                                        <p
                                            data-test="cubesCardShipments_invoicePopulatedNoShipments"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{
                                                invoicePopulated.no_shipments
                                                    ? invoicePopulated.no_shipments.toLocaleString()
                                                    : ""
                                            }}
                                        </p>
                                    </mdb-col>
                                    <mdb-col col="6">
                                        <p data-test="cubesCardAvgCostPackages" class="text-muted">Avg Cost</p>
                                        <p
                                            data-test="cubesCardAvgCostPackages_invoicePopulatedAvgCostPackages"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{ userInfo.currencySymbol
                                            }}{{
                                                invoicePopulated.avgCostPackages != null
                                                    ? parseFloat(invoicePopulated.avgCostPackages).toFixed(2)
                                                    : ""
                                            }}
                                        </p>
                                        <p data-test="cubesCardAvgCostShipments" class="text-muted">Avg Cost</p>
                                        <p
                                            data-test="cubesCardAvgCostShipments_invoicePopulatedAvgCostShipments"
                                            class="font-weight-bold"
                                            v-if="invoicePopulated"
                                        >
                                            {{ userInfo.currencySymbol
                                            }}{{
                                                invoicePopulated.avgCostShipments != null
                                                    ? parseFloat(invoicePopulated.avgCostShipments).toFixed(2)
                                                    : ""
                                            }}
                                        </p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card>
                </mdb-col>
            </mdb-row>

            <mdb-row>
                <mdb-col col="7" class="mt-2">
                    <mdb-card data-test="packageChargesCard">
                        <mdb-card-header data-test="packageChargesCardHeader">
                            <mdb-row>
                                <mdb-col data-test="packageChargesCard_analyzePackageCol" col="6">
                                    <mdb-icon data-test="packageChargesCardCubesIcon" icon="cubes" /> Package Charges
                                    <div class="btn-group action-buttons" role="group">
                                        <mdb-btn
                                            data-test="packageChargesCard_showPackageGenieBtn"
                                            @click.native="openPackageGenie"
                                            class="btn btn-default"
                                            id="analyze"
                                            >Analyze Package&nbsp;
                                            <mdb-icon icon="magic" />
                                        </mdb-btn>
                                    </div>
                                </mdb-col>

                                <mdb-col col="8" class="input-group md-form text-right py-1 my-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text default-color">
                                            <mdb-icon
                                                data-test="packageChargesCard_searchIcon"
                                                icon="search"
                                                class="text-white"
                                                @click.native="getCharges"
                                            />
                                        </span>
                                    </div>
                                    <input
                                        data-test="packageChargesCard_getChargesInput"
                                        class="form-control border-default"
                                        type="text"
                                        placeholder="Search Tracking Number"
                                        v-model="searchPackageText"
                                        @keydown.enter="getCharges"
                                    />
                                    <div class="input-group-append" v-if="searchPackageText">
                                        <mdb-btn
                                            data-test="packageChargesCard_searchPackageText"
                                            variant="info"
                                            @click.native="searchPackageText = ''"
                                            ><mdb-icon icon="times"
                                        /></mdb-btn>
                                    </div>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body data-test="packageChargesCardBody">
                            <div class="mb-2" v-if="invoicePopulated">
                                <vue-good-table
                                    data-test="packageChargesCardTable"
                                    :rows="invoiceCharges.Items"
                                    :columns="fields"
                                    mode="remote"
                                    :isLoading.sync="isLoading"
                                    :totalRows="totalRecords"
                                    @on-row-click="onRowClick"
                                    @on-page-change="onPageChange"
                                    @on-per-page-change="onPerPageChange"
                                    @on-sort-change="onSortChange"
                                    :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPage: limit,
                                        position: 'bottom',
                                        perPageDropdown: limitOptions,
                                        dropdownAllowAll: false,
                                        setCurrentPage: currentPage,
                                        nextLabel: '>',
                                        prevLabel: '<',
                                        rowsPerPageLabel: 'Rows per page',
                                        ofLabel: 'of',
                                        pageLabel: 'page', // for 'pages' mode
                                        allLabel: 'All',
                                        total: totalRecords,
                                    }"
                                    :sort-options="{
                                        enabled: true,
                                    }"
                                >
                                    <div slot="selected-row-actions">Invoice # {{ selected.inv_number }}</div>
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'before'">
                                            <mdb-input
                                                type="radio"
                                                :id="'radio-group-selected-row-' + props.row.originalIndex"
                                                name="radio-group-selected-row"
                                                label=" "
                                                :radioValue="props.row.originalIndex.toString()"
                                                v-model="isSelected"
                                                style="width: 40px"
                                            />
                                        </span>
                                        <span v-if="props.column.field === 'chg_amount'">
                                            {{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                                        </span>
                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>
                                </vue-good-table>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col md="5" sm="6">
                    <mdb-card data-test="chartsCard">
                        <mdb-card-header data-test="chartsCardHeader">
                            Volume By Service Type
                            <div data-test="chartsCardHeaderSmall" class="float-right text-muted">
                                <small>Last 90 Days</small>
                            </div>
                        </mdb-card-header>
                        <mdb-card-body data-test="chartsCardBody" class="m-0 p-0">
                            <ul class="list-group list-group-flush border mt-2 rounded">
                                <li class="list-group-item p-0">
                                    <div>
                                        <div id="index_carrier_service_type" v-if="invoicePopulated">
                                            <highcharts
                                                data-test="chartsCardBodyHighcharts_volumeServiceType"
                                                :options="volumeServiceType"
                                            ></highcharts>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul class="list-group list-group-flush border mt-2 rounded">
                                <li data-test="chartsCardBodyDistributionMap" class="list-group-item bg-light">
                                    Distribution Map
                                    <div data-test="chartsCardBodyDistributionMapSmall" class="float-right text-muted">
                                        <small>Last 90 Days</small>
                                    </div>
                                </li>
                                <li class="list-group-item p-0">
                                    <div>
                                        <highcharts
                                            data-test="chartsCardBody_highchartsDistributionMap"
                                            :constructor-type="'mapChart'"
                                            :options="distributionMap"
                                        ></highcharts>
                                    </div>
                                </li>
                            </ul>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <div class="clearfix mb-5"></div>
            </mdb-row>
        </mdb-modal-body>
    </div>
</template>
<script>
import moment from "moment";
import maps from "../lib/charts";
import Highcharts from "highcharts";
import {
    mdbModalBody,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbCard,
    mdbCardHeader,
    mdbBtn,
    mdbCardBody,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbSpinner,
} from "mdbvue";

export default {
    name: "InvoiceGenie",
    components: {
        mdbSpinner,
        mdbModalBody,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCard,
        mdbCardHeader,
        mdbBtn,
        mdbCardBody,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
    },
    data() {
        return {
            isSelected: "0",
            volumeServiceType: {},
            packageCharges: {},
            distributionMap: {},
            invoicePopulated: {
                id: null,
            },
            invoiceSearch: "",
            invoiceCharges: {},
            volumeServiceTypeOptions: {
                chart_name: "service_volume",
                name: "service_volume",
                chart_type: "pie",
                type: "pie",
                title: {
                    text: "",
                },
                expanded: false,
            },
            distributionOptions: {
                chart_name: "service_state_volume",
                name: "service_state_volume",
                type: "map",
                chart_type: "map",
                title: {
                    text: "",
                },
                options: {
                    country: "us",
                },
                expanded: false,
                uid: "inv_1",
                chart: {
                    map: "countries/us/us-all",
                },
                mapData: Highcharts.map["countries/us/us-all"],
            },
            limit: 10,
            page: 1,
            sortColumn: "pld_trackingno",
            sortkey: "pld_trackingno",
            sortDir: "asc",
            fields: [
                {
                    label: "",
                    field: "before",
                    sortable: false,
                },
                {
                    label: "Tracking Number",
                    field: "pld_trackingno",
                },
                {
                    label: "Charge Type",
                    field: "chtype_name",
                },
                {
                    label: "Service Type",
                    field: "srv_name",
                },
                {
                    label: "Amount",
                    field: "chg_amount",
                },
            ],
            isLoading: true,
            isBusy: false,
            currentPage: 1,
            totalRecords: 0,
            totalPages: 0,
            selected: {},
            selectMode: "single",
            limitOptions: [10, 25, 50, 100],
            searchPackageText: "",
        };
    },
    props: {
        invoice: Object || undefined,
        userInfo: Object,
        headers: Object,
        showInvoiceGenie: Boolean,
        showPackageGenie: Boolean,
    },
    methods: {
        openPackageGenie() {
            let selected = {
                pld_trackingno: this.selected.pld_trackingno,
                invoice_number: this.invoicePopulated.number,
            };
            this.$emit("open-package-genie", selected);
        },
        async getInvoiceData(invNum) {
            this.isBusy = true;
            const params = { cmpId: this.userInfo.cmpID, invoice_number: invNum };
            let url = "/invgenie";
            // eslint-disable-next-line no-undef
            const invoice = await this.$httpShipping.post(url, params, {
                headers: this.headers,
            });
            if (invoice.data.invoice != null) {
                this.invoicePopulated = invoice.data.invoice;
                this.invoicePopulated.date = moment(this.invoicePopulated.date).format("MMM DD, YYYY");
                this.invoicePopulated.avgCostPackages = this.invoicePopulated.amount / this.invoicePopulated.no_packs;
                this.invoicePopulated.avgCostShipments =
                    this.invoicePopulated.amount / this.invoicePopulated.no_shipments;

                await this.getCharges();
            } else {
                this.invoicePopulated = {
                    id: null,
                };
                this.invoiceCharges = {};
            }
            await this.updateCharts();
            this.isBusy = false;
            return this.invoicePopulated;
        },
        async getCharges() {
            let pld_trackingno;
            if (this.searchPackageText) {
                pld_trackingno = this.searchPackageText;
            }

            const params = {
                cmpId: this.userInfo.cmpID,
                invoiceId: this.invoicePopulated.id,
                limit: this.limit,
                size: this.limit,
                page: this.currentPage,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                pld_trackingno,
            };
            let url = "/invoicecharge";
            // eslint-disable-next-line no-undef
            const response = await this.$httpShipping.get(url, {
                headers: this.headers,
                params,
            });
            this.invoiceCharges = response.data;
            this.selected = this.invoiceCharges.Items[0];
            this.isLoading = false;
            this.totalPages = this.invoiceCharges.TotalPages;
            this.totalRecords = this.invoiceCharges.TotalRecords;
            return response.data;
        },
        async getChartData(data) {
            const param = {
                cmpId: this.userInfo.cmpID,
                chartName: data.chart_name,
                chartType: data.chart_type,
                invoiceId: [this.invoicePopulated.id],
            };
            let url = "/chartdata";

            return await this.$http.post(url, param, { headers: this.headers });
        },
        async updateCharts() {
            if (this.invoicePopulated.id != null) {
                let data = (await this.getChartData(this.distributionOptions)).data;
                let chart = maps.drawChart({ ...this.distributionOptions, ...data });
                this.distributionMap = { ...chart, ...this.distributionOptions };
                chart = (await this.getChartData(this.volumeServiceTypeOptions)).data;
                chart.series[0].name = chart.name;
                this.volumeServiceType = maps.drawChart({ ...chart, ...this.volumeServiceTypeOptions });
            }
        },
        onRowClick(params) {
            this.selected = params.row;
            this.isSelected = params.row.originalIndex.toString();
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortkey = params[0].field;
            this.sortDir = params[0].type;
            this.getCharges();
        },
        onPageChange(params) {
            this.currentPage = params.currentPage;
            this.getCharges();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.getCharges();
        },
    },
    mounted() {
        if (this.invoice && this.invoice.inv_number) {
            this.getInvoiceData(this.invoice.inv_number);
        }
    },
    watch: {
        currentPage() {
            this.getCharges();
        },
        limit() {
            this.getCharges();
        },
    },
};
</script>
<style>
.nopadding {
    padding: 1px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
}
p {
    margin: 0px !important;
}
.vgt-checkbox-col {
    padding: 0.75em 1.5em 0.75em 0.75em !important;
}
.vgt-checkbox-col input[type="checkbox"] {
    opacity: 1 !important;
    padding-right: 2px;
}
</style>
