<template>
    <div class="row d-none">
        <div id="step-one" class="single-step ltr-text">
            <p>
                We're all in search of different things, right? With My Views, you can "pin" the custom charts you need
                here for quick reference from the many added regularly in the Chart Gallery.
            </p>
            <br />
            <p>Click "Next" to learn about the features of this page.</p>
        </div>
        <div id="step-two" class="single-step ltr-text">
            <p>
                Pinning charts to My Views is easy. Simply select a chart(s) you want to pin here (or pin to the
                Dashboard) from the Chart Gallery. Click the &quot;pin&quot; icon on any of the available charts and
                configure the accounts/date ranges you want to see.
            </p>
            <p align="center">
                <router-link to="gallery"
                    ><mdb-btn color="info"
                        ><mdb-icon icon="bar-chart"></mdb-icon> Visit Chart Gallery</mdb-btn
                    ></router-link
                >
            </p>
            <p>You can add as many custom charts as you like to this page.</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "MyViewTour",
};
</script>
