<template>
    <div class="w-full">
        <PageHeader
            data-test="pageHeader"
            :pageSubTitle="titleObject.pageSubTitle"
            :pageTitle="titleObject.pageTitle"
            :iconName="titleObject.iconName"
            :startTour="startTour"
        />
        <hr />
        <div class="px-2">
            <hr />
            <mdb-row>
                <mdb-col>
                    <mdb-dropdown data-test="chartsDropdown" id="categories">
                        <mdb-dropdown-toggle slot="toggle">{{
                            chart_category ? chart_category.toUpperCase() : "Charts By Category"
                        }}</mdb-dropdown-toggle>
                        <mdb-dropdown-menu>
                            <mdb-dropdown-item data-test="allItem" @click.native="chart_category = 'all'"
                                >All</mdb-dropdown-item
                            >
                            <mdb-dropdown-item data-test="generalItem" @click.native="chart_category = 'general'"
                                >General</mdb-dropdown-item
                            >
                            <mdb-dropdown-item data-test="heatmapItem" @click.native="chart_category = 'heatmap'"
                                >Heatmap</mdb-dropdown-item
                            >
                            <mdb-dropdown-item data-test="geoItem" @click.native="chart_category = 'geo'"
                                >Geographical</mdb-dropdown-item
                            >
                        </mdb-dropdown-menu>
                    </mdb-dropdown>
                </mdb-col>
            </mdb-row>
            <hr />
            <mdb-row class="mt-4">
                <mdb-pagination data-test="mdb-pagination" id="pagination" class="mt-2 ml-auto mr-auto" align="center">
                    <mdb-page-nav
                        data-test="pageNavPrev"
                        prev
                        :disabled="currentPage === 1"
                        class="border"
                        @click.native="currentPage--"
                    ></mdb-page-nav>
                    <mdb-page-item
                        data-test="pageItem"
                        class="border"
                        v-for="i of totalPages"
                        v-bind:key="i"
                        @click.native="currentPage = i"
                        :active="currentPage === i"
                        >{{ i }}</mdb-page-item
                    >
                    <mdb-page-nav
                        data-test="pageNavNext"
                        class="border"
                        next
                        :disabled="currentPage === totalPages"
                        @click.native="currentPage++"
                    ></mdb-page-nav>
                </mdb-pagination>
            </mdb-row>
            <mdb-row data-test="mdb-row_charts" class="mt-2" v-if="!isLoading" id="charts">
                <mdb-col data-test="mdb-col_charts" sm="6" xs="12" v-for="(chart, index) of charts" v-bind:key="index">
                    <mdb-card class="m-2">
                        <mdb-card-header class="row mx-0">
                            <mdb-col col="9" class="small"
                                ><h4 data-test="chartHeading">{{ chart.heading }}</h4></mdb-col
                            >
                            <mdb-col
                                data-test="mdb-col_buttonsRight"
                                col="3"
                                class="btn-group text-sm-center text-right"
                                v-if="chart.buttonsRight"
                            >
                                <span class="text-muted smaller mr-2 mt-2"
                                    >Last {{ chart.dateTime ? chart.dateTime.replace("d", "") : "90" }} days</span
                                >
                                <mdb-btn-group data-test="mdb-btn-group_buttonsRight" size="sm">
                                    <mdb-popover trigger="click" :options="{ placement: 'top' }">
                                        <span data-test="chartButtonsRight" slot="body">{{ chart.buttonsRight }}</span>
                                        <mdb-btn slot="reference" color="white" class="btn-xs border text-muted p-2">
                                            <mdb-icon icon="info" size="xs" />
                                        </mdb-btn>
                                    </mdb-popover>
                                    <mdb-btn
                                        data-test="mdb-btn_showExpandedChart"
                                        @click.native="showExpandedChart(chart, true)"
                                        color="default"
                                        ><mdb-icon icon="thumbtack" class="float-right"
                                    /></mdb-btn>
                                </mdb-btn-group>
                            </mdb-col>
                        </mdb-card-header>
                        <mdb-card-body
                            ><highcharts
                                data-test="cardHighcharts"
                                :constructor-type="chart.chartType"
                                :options="chart"
                        /></mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row data-test="spinner" v-else>
                <mdb-col col="12" class="text-center">
                    <h3><mdb-spinner size="3x" /> Loading Charts...</h3>
                </mdb-col>
            </mdb-row>
            <mdb-row class="my-4 min-h-200">
                <mdb-col col="3" class="text-center ml-auto mr-auto">
                    <mdb-pagination data-test="mdb-paginationAll" align="center">
                        <mdb-page-nav
                            data-test="mdb-page-nav_prevAll"
                            prev
                            :disabled="currentPage === 1"
                            class="border"
                            @click.native="currentPage--"
                        ></mdb-page-nav>
                        <mdb-page-item
                            data-test="mdb-page-item_all"
                            class="border"
                            v-for="i of totalPages"
                            v-bind:key="i"
                            @click.native="currentPage = i"
                            :active="currentPage === i"
                            >{{ i }}</mdb-page-item
                        >
                        <mdb-page-nav
                            data-test="mdb-page-nav_nextAll"
                            class="border"
                            next
                            :disabled="currentPage === totalPages"
                            @click.native="currentPage++"
                        ></mdb-page-nav>
                    </mdb-pagination>
                </mdb-col>
            </mdb-row>
            <div class="clear"></div>
        </div>
        <mdb-modal
            data-test="modal"
            v-if="showExpChart"
            @close="showExpChart = false"
            hide-footer
            hide-header
            size="xl"
        >
            <mdb-modal-header data-test="mdb-modal-header"
                ><h3><mdb-icon icon="thumbtack" class="mr-2" /> Pin Chart</h3>
            </mdb-modal-header>
            <mdb-row class="p-4">
                <mdb-col data-test="showPinModal" v-if="showPinModal" class="p-4">
                    <p>Customize and Pin this chart to your main Dashboard or My Views page.</p>
                    <input
                        data-test="modalInputTitle"
                        class="form-control my-1"
                        type="text"
                        v-model="expandedChart.heading"
                        placeholder="Title"
                    />
                    <mdb-input
                        data-test="mdb-input_chartDescription"
                        outline
                        type="textarea"
                        class="my-2"
                        rows="5"
                        v-model="expandedChart.chart_description"
                        placeholder="Description"
                    />
                    <AccountDropdown
                        data-test="accountDropdown"
                        @update-account-selected="updateSelectedAccount"
                        @update-selected-active="updateSelectedActive"
                        :userId="userInfo.usrId"
                        :cmpId="userInfo.cmpID"
                        :headers="headers"
                        class="my-1"
                    />
                    <select data-test="select_dateTime" v-model="expandedChart.dateTime" class="form-control my-1">
                        <option value="" disabled>Select Date Range</option>
                        <option v-for="option of dateOptions" :value="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                    <div data-test="expandedChart.dateTime" v-if="expandedChart.dateTime === 'c00'">
                        <DateSelector
                            data-test="DateSelector"
                            :startDate="startDate"
                            :endDate="endDate"
                            @update-end-date="updateEndDate"
                            @update-start-date="updateStartDate"
                            class="mx-2 my-0 border rounded"
                        />
                    </div>
                    <select data-test="select_userPage" v-model="expandedChart.user_page" class="form-control my-1">
                        <option value="" disabled>Select Page</option>
                        <option v-for="option of pageOptions" :value="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </mdb-col>
                <mdb-col class="d-block text-center">
                    <h3 data-test="expandedChartTitle" v-if="expandedChart.title">{{ expandedChart.title.text }}</h3>
                    <highcharts
                        data-test="modalHighcharts"
                        :constructor-type="expandedChart.chartType"
                        :options="expandedChart"
                    ></highcharts>
                </mdb-col>
            </mdb-row>
            <mdb-modal-footer>
                <mdb-btn
                    data-test="mdb-btn_hideExpandedChart"
                    class="mt-3 float-right w-25"
                    color="danger"
                    inline-block
                    @click.native="hideExpandedChart"
                    >Close</mdb-btn
                >
                <mdb-btn
                    data-test="mdb-btn_createPinnedChart"
                    class="mt-3 float-right w-25"
                    color="success"
                    inline-block
                    @click.native="createPinnedChart"
                    >Create Chart</mdb-btn
                >
            </mdb-modal-footer>
        </mdb-modal>
        <GalleryTour />
    </div>
</template>
<script>
import maps from "@/lib/charts";
import moment from "moment";
import AccountDropdown from "@/components/AccountDropdown";
import DateSelector from "@/components/DateSelector";
import PageHeader from "@/components/PageHeader";
import GalleryTour from "@/components/GalleryTour";
import { buttons, getTourDefaults } from "@/lib/shepherd-tour";
import {
    mdbBtn,
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbIcon,
    mdbTabs,
    mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbAccordion,
    mdbAccordionPane,
    mdbSpinner,
    mdbInput,
    mdbSelect,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbCardHeader,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbPagination,
    mdbPageNav,
    mdbPageItem,
    mdbBtnGroup,
    mdbPopover,
} from "mdbvue";

export default {
    name: "Gallery",
    components: {
        DateSelector,
        AccountDropdown,
        PageHeader,
        GalleryTour,
        mdbBtn,
        mdbContainer,
        mdbCol,
        mdbRow,
        mdbIcon,
        mdbTabs,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbAccordion,
        mdbAccordionPane,
        mdbSpinner,
        mdbInput,
        mdbSelect,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbCardHeader,
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbDropdownItem,
        mdbPagination,
        mdbPageNav,
        mdbPageItem,
        mdbBtnGroup,
        mdbPopover,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            titleObject: {
                pageSubTitle: "",
                pageTitle: "Chart Gallery",
                iconName: "chart-bar",
            },
            charts: [],
            limit: 4,
            currentPage: 1,
            sortColumn: "chart_order",
            sortDir: "asc",
            totalPages: 0,
            totalRecords: 0,
            chart_category: null,
            isLoading: false,
            accountValues: null,
            expandedChart: {
                dateTime: "",
                user_page: "",
            },
            showExpChart: false,
            showPinModal: false,
            startDate: new moment(new moment().subtract(90, "days").format("YYYY-MM-DD"), "YYYY-MM-DD"),
            endDate: new moment(new moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
            dateOptions: [
                {
                    text: "Default",
                    value: "d0",
                },
                {
                    text: "Last 7 Days",
                    value: "d7",
                },
                {
                    text: "Last 14 Days",
                    value: "d14",
                },
                {
                    text: "Last 30 Days",
                    value: "d30",
                },
                {
                    text: "Last 60 Days",
                    value: "d60",
                },
                {
                    text: "Last 90 Days",
                    value: "d90",
                },
                {
                    text: "Custom",
                    value: "c00",
                },
            ],
            pageOptions: [
                {
                    text: "Dashboard",
                    value: "dashboard",
                },
                {
                    text: "My View",
                    value: "mycharts",
                },
            ],
        };
    },
    methods: {
        startTour() {
            const tour = this.$shepherd(getTourDefaults());
            const steps = [
                {
                    text: document.getElementById("step-one").innerHTML,
                    buttons: [
                        {
                            text: "Next",
                            action: tour.next,
                        },
                    ],
                    title: "Gallery Tour",
                },
                {
                    attachTo: { element: "#categories", on: "bottom" },
                    text: document.getElementById("step-two").innerHTML,
                    buttons,
                    title: "Chart Categories",
                },
                {
                    attachTo: { element: "#pagination", on: "bottom" },
                    text: document.getElementById("step-three").innerHTML,
                    buttons,
                    title: "Pagination",
                },
                {
                    attachTo: { element: "#charts", on: "top" },
                    text: document.getElementById("step-four").innerHTML,
                    buttons,
                    title: "Charts",
                },
                {
                    attachTo: { element: ".fa-thumbtack" },
                    text: document.getElementById("step-five").innerHTML,
                    buttons,
                    title: "Pin",
                },
            ];

            tour.addSteps(steps);

            tour.start();
        },
        updateSelectedAccount(userAccounts, accountValues) {
            this.expandedChart.selectedAccounts = userAccounts;
            this.accountValues = accountValues;
        },
        updateSelectedActive(val) {
            this.expandedChart.selectedAccountActive = val;
        },
        updateStartDate(val) {
            this.startDate = val;
        },
        updateEndDate(val) {
            this.endDate = val;
        },
        async createPinnedChart() {
            let accounts = this.accountValues;
            let dateParams;
            if (this.expandedChart.dateTime === "c00") {
                dateParams = {
                    startTime: moment(this.startDate).format("YYYY-MM-DD"),
                    endTime: moment(this.endDate).format("YYYY-MM-DD"),
                };
            }
            const params = {
                chart_user_chart_id: this.expandedChart.chart_id,
                chart_user_title: this.expandedChart.heading,
                chart_user_description: this.expandedChart.chart_description,
                chart_user_param: JSON.stringify({
                    accountId: accounts,
                    dateTime: this.expandedChart.dateTime,
                    ...dateParams,
                }),
                chart_user_page: this.expandedChart.user_page,
            };
            let url = "/chartuser";
            // if (data.chart_user_category == "superuser") url = "/superuser" + url;

            try {
                await this.$http.post(url, params, { headers: this.headers });
                this.$notify.success({ message: "Chart successfully pinned" });
                this.showExpChart = false;
            } catch (e) {
                this.$notify.error({ message: "There was an error processing your request." });
            }
        },
        showExpandedChart(chart, pin) {
            this.showPinModal = pin;
            this.expandedChart = chart;
            this.expandedChart.dateTime = "";
            this.expandedChart.user_page = "";
            this.showExpChart = true;
        },
        hideExpandedChart() {
            this.showExpChart = false;
        },
        async getCharts() {
            this.charts = [];
            this.isLoading = true;
            let chart_category = "";
            if (this.chart_category) {
                if (this.chart_category != "all") {
                    chart_category = "&chart_category=" + this.chart_category;
                } else {
                    chart_category = "";
                }
            }
            const charts = await this.$http.get(
                "/chart?limit=" +
                    this.limit +
                    "&page=" +
                    this.currentPage +
                    "&sortColumn=" +
                    this.sortColumn +
                    "&sortDir=" +
                    this.sortDir +
                    chart_category,
                { headers: this.headers }
            );
            if (charts.data) {
                this.totalPages = charts.data[0].TotalPages;
                this.totalRecords = charts.data[0].TotalRecords;
                for (const user of charts.data[0].Items) {
                    let type = null;
                    if (user.chart_type === "map") {
                        type = {
                            map: "countries/us/us-all",
                        };
                    } else {
                        type = { type: user.chart_type };
                    }
                    const newChart = {
                        type: user.chart_type,
                        title: {
                            text: user.chart_title,
                        },
                        chartType: "chart",
                        chart: {
                            ...type,
                        },
                        chartButtons: user.chart_description,
                        dateTime: user.chart_param ? JSON.parse(user.chart_param).dateTime : "90",
                    };
                    const data = (await this.getChartData(user)).data;
                    let series = maps.drawChart({ ...newChart, ...data });
                    this.charts.push({
                        ...newChart,
                        ...series,
                        heading: user.chart_title,
                        chart_id: user.chart_id,
                        chart_description: user.chart_description,
                    });
                }
            }
            this.isLoading = false;
        },
        async getChartData(data) {
            let params;
            if (data.chart_user_param) {
                params = JSON.parse(data.chart_user_param);
            }
            const param = {
                cmpId: this.userInfo.cmpID,
                chartName: data.chart_name,
                chartType: data.chart_type,
                ...params,
            };
            let url = "/chartdata";
            // if (data.chart_user_category == "superuser") url = "/superuser" + url;

            return await this.$http.post(url, param, { headers: this.headers });
        },
    },
    mounted() {
        this.getCharts();
    },
    watch: {
        currentPage() {
            this.getCharts();
        },
        chart_category() {
            this.getCharts();
        },
    },
};
</script>
<style>
.scroll {
    height: 100% !important;
    overflow: scroll;
}
.min-h-200 {
    min-height: 200px;
}
</style>
