export const buttons = [
    {
        action() {
            return this.back();
        },
        classes: "shepherd-button-secondary",
        text: "Back",
    },
    {
        action() {
            return this.next();
        },
        text: "Next",
    },
];

export function getTourDefaults() {
    return {
        useModalOverlay: true,
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
            classes: "class-1 class-2",
            scrollTo: false,
        },
    };
}