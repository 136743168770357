<template>
    <div class="w-100 systemHealth m-2">
        <h1 class="page-header border-bottom mx-2">
            Accounting Dashboard
            <span class="small">Internal View</span>
            <mdb-icon icon="tachometer-alt" size="1x" class="float-right text-muted" role="button" />
        </h1>
        <mdb-row class="p-4">
            <mdb-col col="9">
                <mdb-row class="w-100 my-4">
                    <mdb-card xs="12" col="auto" class="mr-2 flex-fill">
                        <mdb-card-header color="green lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="green-text">
                                    <mdb-icon icon="check" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="green-text">
                                    <p>Customers</p>
                                    <p class="smaller green-text">{{ onboarding }} Onboarding</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/customers">
                                Access
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card xs="12" col="auto" class="flex-fill">
                        <mdb-card-header color="green lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="font-weight-bold green-text">
                                    <mdb-icon icon="file" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="green-text">
                                    <p class="text-nowrap">Invoices</p>
                                    <p class="smaller green-text">Reseller Invoices</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/resellerinvoices">
                                Access
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card xs="12" col="auto" class="ml-2 flex-fill">
                        <mdb-card-header color="orange lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="orange-text">
                                    <mdb-icon icon="dollar-sign" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="orange-text">
                                    <p>Adjustments</p>
                                    <p class="smaller orange-text">Adjust Reseller Invoices</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/adjustments">
                                View Details
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-row>
            </mdb-col>
            <mdb-col col="3">
                <mdb-card xs="12" col="auto" class="flex-fill mt-4 mr-4">
                    <mdb-card-header color="blue lighten-4" tag="h3">
                        <mdb-row class="no-gutters">
                            <mdb-col col="6" class="blue-text">
                                <mdb-icon :icon="systemStatusIcon" size="3x" />
                            </mdb-col>
                            <mdb-col col="6" class="blue-text">
                                <p>{{ systemStatus }}</p>
                                <p class="smaller blue-text">{{ systemStatusText }}</p>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-header>
                    <mdb-card-body>
                        <router-link to="/admin/appcontroller">
                            View Details
                            <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                        </router-link>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
    </div>
</template>
<script>
export default {
    name: "AccountingDashboard",
    components: {},
    props: {
        userInfo: Object,
        headers: Object,
    },
    data() {
        return {
            onboarding: null,
            systemStatus: null,
            systemStatusText: null,
            systemStatusIcon: null,
            runningComponent: null,
        };
    },
    methods: {
        async getStats() {
            this.onboarding = (
                await this.$httpAdmin.get("Administration/onboard-customers", { headers: this.headers })
            ).data;
        },
        async getComponents() {
            this.runningComponent = null;
            const components = (
                await this.$httpAdmin.get("appcontroller/?action=getcomponent&clientName=web", {
                    headers: this.headers,
                })
            ).data;
            for (const row of components) {
                let status = "clock";

                if (row.status === "started") status = "play";
                else if (row.status === "running") status = "sync";
                else if (row.status === "newInvoices") status = "copy";

                if (row.disabled) status = "stop";
                row.icon = status;
                if (row.status === "running") {
                    this.runningComponent = row;
                }
            }
            this.updateSystemPanel();
        },

        updateSystemPanel() {
            let text = "Idle";
            let det = "System is Idle";
            let ico = "clock";

            let component;
            if (this.runningComponent) {
                component = this.runningComponent;
            }
            if (component === "extraction") {
                text = "Extract";
                det = "Extracting Charges...";
                ico = "sync";
            } else if (component === "reconciliation") {
                text = "Audit";
                det = "Reconciling Refunds...";
                ico = "sync";
            } else if (component === "audit") {
                text = "Audit";
                det = "Processing Charges...";
                ico = "sync";
            } else if (component === "consolidation") {
                text = "Maint";
                det = "Consolidating Packages...";
                ico = "sync";
            } else if (component === "glcoding") {
                text = "Maint";
                det = "Applying GLCodes...";
                ico = "sync";
            } else if (component === "invoiceUploader") {
                text = "Stage";
                det = "Uploading Invoices to Extract Queue (S3)...";
                ico = "sync";
            } else if (component === "extractionInvoiceDownloader") {
                text = "Stage";
                det = "Downloading Invoices from Extract Queue (S3)...";
                ico = "sync";
            } else if (component === "copyProcessedPackages") {
                text = "Merge";
                det = "Committing Processed Data...";
                ico = "sync";
            }

            this.systemStatus = text;
            this.systemStatusText = det;
            this.systemStatusIcon = ico;
        },
    },
    mounted() {
        this.getStats();
        this.getComponents();
    },
};
</script>
