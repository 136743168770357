<template>
    <mdb-container data-test="page" fluid class="myReports">
        <PageHeader
            data-test="pageHeader"
            :pageSubTitle="``"
            :pageTitle="`My Reports`"
            :iconName="`copy`"
            :startTour="startTour"
        />
        <hr />
        <div v-if="isBusy" id="overlay" class="justify-content-center align-content-center h-100vh">
            <mdb-spinner data-test="accordion_spinner" class="justify-content-center"></mdb-spinner>
            <h3><b>...Loading</b></h3>
        </div>
        <div :style="{ opacity: isBusy ? 0.3 : 1 }">
            <mdb-row>
                <mdb-col col="7">
                    <mdb-row>
                        <mdb-col col="6">
                            <mdb-dropdown
                                data-test="selectedReportDropdown"
                                id="report-select"
                                class="w-100 border border-gray-200 rounded"
                                block
                                boundary="window"
                            >
                                <mdb-dropdown-toggle
                                    data-test="selectedReportDropdownToggle"
                                    slot="toggle"
                                    class="w-100"
                                    :color="selectedReport ? 'info' : 'white'"
                                >
                                    {{ selectedReport ? selectedReport.name : "Select a Report" }}
                                </mdb-dropdown-toggle>
                                <mdb-dropdown-menu data-test="selectedReportDropdownMenu">
                                    <input
                                        data-test="selectedReportDropdownInput"
                                        class="form-control"
                                        type="text"
                                        placeholder="Search"
                                        aria-label="Search"
                                        @click.stop
                                        v-model="dropdownFilter"
                                        @keydown="filterSearch"
                                    />
                                </mdb-dropdown-menu>
                                <mdb-dropdown-menu
                                    data-test="selectedReportDropdownMenu_systemReportList"
                                    id="generatedReports"
                                    class="myReportsScroll"
                                >
                                    <h6
                                        data-test="selectedReportDropdown_systemReportList_title"
                                        class="dropdown-header"
                                    >
                                        Generated Reports
                                    </h6>
                                    <mdb-dropdown-item
                                        data-test="selectedReportDropdownItem_systemReportList"
                                        v-for="report in systemReportsList"
                                        @click.native="changeSelectedReport(report)"
                                        v-bind:key="report.id"
                                    >
                                        {{ report.name }}
                                    </mdb-dropdown-item>
                                </mdb-dropdown-menu>
                                <div data-test="selectedReportDropdownDivider" class="dropdown-divider"></div>
                                <mdb-dropdown-menu
                                    data-test="selectedReportDropdown_selfGeneratedReportsList"
                                    id="customReports"
                                    class="myReportsScroll"
                                >
                                    <h6
                                        data-test="selectedReportDropdown_selfGeneratedReportsListTitle"
                                        class="dropdown-header"
                                    >
                                        Custom Reports
                                    </h6>
                                    <mdb-dropdown-item
                                        data-test="selectedReportDropdownItem_selfGeneratedReportsList"
                                        v-for="report in selfGeneratedReportsList"
                                        @click.native="changeSelectedReport(report)"
                                        v-bind:key="report.id"
                                    >
                                        {{ report.name }}
                                    </mdb-dropdown-item>
                                </mdb-dropdown-menu>
                            </mdb-dropdown>
                        </mdb-col>
                        <mdb-col col="6">
                            <mdb-dropdown data-test="selectedFormatDropdown" id="dropdown-form2" class="w-100">
                                <mdb-dropdown-toggle
                                    data-test="selectedFormatDropdownToggle"
                                    slot="toggle"
                                    class="w-100"
                                    :color="tempFormat ? 'info' : 'white'"
                                >
                                    {{
                                        tempFormat
                                            ? tempFormat == "html"
                                                ? "SCREEN"
                                                : tempFormat == "xls"
                                                ? "EXCEL"
                                                : tempFormat
                                            : "Select Format"
                                    }}
                                </mdb-dropdown-toggle>
                                <mdb-dropdown-menu data-test="selectedFormatDropdownMenu" id="formats" v-if="formats">
                                    <mdb-dropdown-item
                                        data-test="selectedFormatDropdownItem"
                                        v-for="(type, index) of formats"
                                        v-bind:key="index"
                                        class="p-1 text-lg-left text-uppercase"
                                        @click.native="setTempFormat(type)"
                                    >
                                        {{ type == "html" ? "screen" : type == "xls" ? "excel" : type }}
                                    </mdb-dropdown-item>
                                </mdb-dropdown-menu>
                            </mdb-dropdown>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="6">
                            <DateSelector
                                data-test="DateSelector"
                                id="dateDropdown"
                                :startDate="startDate"
                                :endDate="endDate"
                                @update-end-date="updateEndDate"
                                @update-start-date="updateStartDate"
                                class="m-1 border rounded w-100"
                            />
                        </mdb-col>
                        <mdb-col col="6">
                            <AccountDropdown
                                data-test="AccountDropdown"
                                id="account-select"
                                @update-account-selected="updateSelectedAccount"
                                @update-selected-active="updateSelectedActive"
                                :userId="userInfo.usrId"
                                :cmpId="userInfo.cmpID"
                                :headers="headers"
                                class="mt-1 w-100"
                            />
                        </mdb-col>
                    </mdb-row>
                    <hr />
                    <mdb-row>
                        <mdb-col col="6">
                            <mdb-btn
                                data-test="generateReportBtn"
                                id="run-report"
                                class="w-100 ml-0"
                                variant="info"
                                @click.native="generateReport()"
                                v-if="!runningReport && !showFilters"
                                >Run Report</mdb-btn
                            >
                            <mdb-btn
                                data-test="generateReportBtnDisabled"
                                class="w-100 ml-0"
                                variant="info"
                                disabled
                                v-if="runningReport"
                            >
                                <mdb-icon icon="spinner" spin />
                                Generating Report...</mdb-btn
                            >
                        </mdb-col>
                        <mdb-col col="6">
                            <mdb-btn
                                data-test="advancedFiltersBtn"
                                class="w-100 ml-0"
                                @click.native="advancedFilters"
                                color="default"
                                >[Advanced Filters]</mdb-btn
                            >
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
                <mdb-col col="5">
                    <mdb-tabs
                        data-test="tabs"
                        :active="0"
                        default
                        card
                        :links="[
                            { icon: 'file-alt', text: 'Reports Details', slot: 'reports-details' },
                            { icon: 'archive', text: 'Reports Archive', slot: 'reports-archive' },
                        ]"
                        :transition-duration="0.5"
                        transition-style="linear"
                        class="mr-1"
                    >
                        <template data-test="tabs_report-details" :slot="'reports-details'">
                            <template data-test="tabs_report-detailsTitle" slot="text">
                                <mdb-icon icon="file-alt" class="mr-2" /> Report Details
                            </template>
                            <span data-test="tabs_report_detailsDefault" v-if="!selectedReport"
                                >Select a report to view the details and description.</span
                            >
                            <span
                                data-test="tabs_report_detailsSelectedReport"
                                v-if="selectedReport && !reportDownloadLink"
                                >{{ selectedReport.description }}</span
                            >
                            <mdb-btn
                                data-test="tabs_report-details_downloadReportBtn"
                                v-if="reportDownloadLink && reportDownloadLink != 'no data' && selectedFormat != 'html'"
                                variant="info"
                                @click.native="downloadReport(reportDownloadLink)"
                                ><mdb-icon icon="download" /> Download Report</mdb-btn
                            >
                            <span
                                data-test="tabs_report-details_reportCompleted"
                                v-if="
                                    reportDownloadLink && (selectedFormat == 'html' || reportDownloadLink == 'no data')
                                "
                            >
                                <mdb-icon color="success" icon="check" class="mr-1"></mdb-icon>Report Completed
                            </span>
                            <span
                                data-test="tabs_report-details_noResults"
                                v-if="reportDownloadLink && reportDownloadLink == 'no data'"
                                ><br /><br />
                                No results found
                            </span>
                        </template>
                        <template data-test="tabs_reports-archive" :slot="'reports-archive'">
                            <template data-test="tabs_reports-archiveTitle" slot="text">
                                <mdb-icon icon="archive" class="mr-2" /> Report Archive
                            </template>
                            <div v-if="reportsArchiveItems">
                                <vue-good-table
                                    data-test="tabs_reports-archiveTable"
                                    :rows="reportsArchiveItems"
                                    :columns="reportsArchiveFields"
                                >
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field === 'report_name'">
                                            <span
                                                @click="
                                                    downloadUniversal(props.row.rg_filekey, props.row.rg_output_type)
                                                "
                                                class="link"
                                            >
                                                <mdb-icon icon="file-pdf"></mdb-icon>
                                                {{ props.row.report_name }}
                                            </span>
                                        </span>
                                        <span v-else-if="props.column.field === 'rg_create_date'">
                                            {{ formatDate(props.formattedRow[props.column.field], true) }}
                                        </span>
                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>
                                </vue-good-table>
                            </div>
                        </template>
                    </mdb-tabs>
                </mdb-col>
            </mdb-row>
            <mdb-row data-test="showFilters" v-if="showFilters" class="mt-2">
                <mdb-col col="12">
                    <mdb-card data-test="showFiltersCard" class="card-body mr-1">
                        <mdb-card-title data-test="showFiltersCardTitle"
                            >Advanced Filters<a class="float-right" @click="deleteFilters">X</a></mdb-card-title
                        >
                        <mdb-row>
                            <mdb-col>
                                <select
                                    data-test="showFiltersSelect_selectedFilter"
                                    v-model="selectedFilter"
                                    class="text-capitalize form-control mt-4"
                                    @change="checkSchema"
                                >
                                    <option data-test="showFiltersSelect_selectedFilterOptionNull" :value="null">
                                        Please select an option
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedFilterOptions"
                                        v-for="(filter, index) in filters"
                                        :value="filter"
                                        v-bind:key="index"
                                        :disabled="filter.added"
                                    >
                                        {{ filter.display }}
                                    </option>
                                </select>
                            </mdb-col>
                            <mdb-col v-if="selectedFilter && selectedFilter.listable">
                                <select
                                    data-test="showFiltersSelect_schemaSelected"
                                    v-model="schemaSelected"
                                    class="form-control text-capitalize mt-4"
                                >
                                    <option data-test="showFiltersSelect_schemaSelectedOptionNull" :value="null">
                                        Please select an option
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_schemaSelectedOptions"
                                        v-for="(option, index) in schemaOptions"
                                        :value="option"
                                        v-bind:key="index"
                                    >
                                        {{ option.name }}
                                    </option>
                                </select>
                            </mdb-col>
                            <mdb-col v-if="selectedFilter && !selectedFilter.listable" class="text-capitalize">
                                <select
                                    data-test="showFiltersSelect_selectedOperator"
                                    v-model="selectedOperator"
                                    class="w-100 form-control mt-4"
                                >
                                    <option data-test="showFiltersSelect_selectedOperatorOptionNull" :value="null">
                                        Please select an option
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_contains"
                                        value="% Contains"
                                        v-if="selectedFilter.type === 'String'"
                                    >
                                        contains
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_doesNotContain"
                                        value="!% Does Not Contain"
                                        v-if="selectedFilter.type === 'String'"
                                    >
                                        does not contain
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_startsWith"
                                        value="%< Starts With"
                                        v-if="selectedFilter.type === 'String'"
                                    >
                                        starts with
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_endsWith"
                                        value="%> Ends With"
                                        v-if="selectedFilter.type === 'String'"
                                    >
                                        ends with
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_equals"
                                        value="= Equals"
                                    >
                                        equals
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_notEqualTo"
                                        value="<> Not Equal To"
                                    >
                                        not equal to
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_between"
                                        value="[] Between"
                                        v-if="selectedFilter.type === 'Float'"
                                    >
                                        between
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_notBetween"
                                        value="![] Not Between"
                                        v-if="selectedFilter.type === 'Float'"
                                    >
                                        not between
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_before"
                                        value="< Before or Less Than"
                                        v-if="selectedFilter.type !== 'String'"
                                    >
                                        {{ selectedFilter.type === "DateTime" ? "before" : "less than" }}
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_ltet"
                                        value="<= Less Than or Equal To"
                                        v-if="selectedFilter.type === 'Float'"
                                    >
                                        less or equal to
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_after"
                                        value="> After or Greater Than"
                                        v-if="selectedFilter.type !== 'String'"
                                    >
                                        {{ selectedFilter.type === "DateTime" ? "after" : "greater than" }}
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_gtet"
                                        value=">= Greater Than or Equal To"
                                        v-if="selectedFilter.type === 'Float'"
                                    >
                                        greater or equal to
                                    </option>
                                    <option data-test="showFiltersSelect_selectedOperatorOption_isIn" value="() Is In">
                                        is in
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_isNotIn"
                                        value="!() Is Not In"
                                    >
                                        is not in
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_isEmpty"
                                        value="$ Is Empty"
                                        v-if="selectedFilter.type !== 'Float'"
                                    >
                                        is empty
                                    </option>
                                    <option
                                        data-test="showFiltersSelect_selectedOperatorOption_isNotEmpty"
                                        value="!$ Is Not Empty"
                                        v-if="selectedFilter.type !== 'Float'"
                                    >
                                        is not empty
                                    </option>
                                </select>
                            </mdb-col>
                            <mdb-col
                                data-test="showFiltersSelect_inputsAndDatePicker"
                                v-if="selectedFilter && !selectedFilter.listable"
                            >
                                <div v-if="selectedFilter.type === 'String'" class="col-12 p-1 text-lg-left">
                                    <input
                                        data-test="showFiltersSelectInput_selectedFilterInputValue"
                                        type="text"
                                        v-model="selectedFilter['input_value']"
                                        class="form-control mt-4"
                                    />
                                </div>
                                <div v-if="selectedFilter.type === 'DateTime'" class="col-12">
                                    <mdb-date-picker-2
                                        data-test="showFilersSelectDatePicker"
                                        v-model="selectedFilter['date_value']"
                                        class="w-100"
                                    ></mdb-date-picker-2>
                                </div>
                                <div v-if="selectedFilter.type === 'Float'" class="col-12">
                                    <mdb-row>
                                        <mdb-col col="6">
                                            <input
                                                data-test="showFiltersSelectInput_selectedFilterFloatValue"
                                                type="text"
                                                v-model="selectedFilter['float_value']"
                                                class="form-control mt-4"
                                            />
                                        </mdb-col>
                                        <mdb-col col="6">
                                            <input
                                                data-test="showFiltersSelectedInput_selectedFilterFloatTwoValue"
                                                type="text"
                                                class="form-control mt-4"
                                                v-model="selectedFilter['float_two_value']"
                                                v-if="
                                                    selectedOperator === '[] Between' ||
                                                    selectedOperator === '![] Not Between'
                                                "
                                            />
                                        </mdb-col>
                                    </mdb-row>
                                </div>
                            </mdb-col>
                            <mdb-col>
                                <mdb-btn
                                    data-test="showFiltersSelect_addFilterBtn"
                                    class="mt-4"
                                    color="info"
                                    @click.native="addFilter"
                                    >Add Filter</mdb-btn
                                >
                            </mdb-col>
                        </mdb-row>
                        <mdb-row
                            data-test="showFilters_addedFilters"
                            class="mt-4"
                            v-if="showFilters && addedFilters.length"
                        >
                            <mdb-col col="12" class="w-100">
                                <h4 data-test="showFilters_addedFiltersTitle">Filters</h4>
                                <vue-good-table
                                    data-test="showFilters_addedFiltersTable"
                                    mode="remote"
                                    :rows="addedFilters"
                                    :columns="addedFilterFields"
                                    id="addedFilters"
                                >
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'edit'">
                                            <mdb-btn
                                                color="outline-danger"
                                                size="sm"
                                                @click.native="removeFilter(props.row)"
                                                ><mdb-icon icon="trash"></mdb-icon
                                            ></mdb-btn>
                                        </span>
                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>
                                </vue-good-table>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col col="12" v-if="showFilters && addedFilters.length">
                                <mdb-btn
                                    data-test="showFilters_addedFilters_generateReportBtn"
                                    color="info"
                                    class="ml-0"
                                    @click.native="generateReport"
                                    v-if="!runningReport"
                                    >Run Report With Addl Filters</mdb-btn
                                >
                                <mdb-btn
                                    data-test="showFilters_addedFilters_generateReportBtnSpinner"
                                    color="info"
                                    class="ml-0"
                                    disabled
                                    v-if="runningReport"
                                >
                                    <mdb-icon icon="spinner" spin />
                                    Generating Report...</mdb-btn
                                >
                            </mdb-col>
                        </mdb-row>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col>
                    <div id="display-area" class="mt-2">
                        <div v-if="report && report.columns && selectedFormat == 'html'" class="mb-10">
                            <vue-good-table
                                data-test="reportTable"
                                :columns="report.columns"
                                :rows="report.rows"
                                :isLoading.sync="runningReport"
                                :totalRows="totalRecords"
                                @on-sort-change="onSortChange"
                                @on-page-change="onPageChange"
                                @on-per-page-change="onPerPageChange"
                                :pagination-options="{
                                    enabled: true,
                                    mode: 'records',
                                    perPage: limit,
                                    perPageDropdown: limitOptions,
                                    dropdownAllowAll: false,
                                    setCurrentPage: currentPage,
                                    nextLabel: '>',
                                    prevLabel: '<',
                                    rowsPerPageLabel: 'Rows per page',
                                    ofLabel: 'of',
                                    pageLabel: 'page', // for 'pages' mode
                                    allLabel: 'All',
                                    total: totalRecords,
                                }"
                            />
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </mdb-col>
            </mdb-row>
        </div>
        <MyReportsTour data-test="MyReportsTour" />
    </mdb-container>
</template>
<script>
import moment from "moment";
import DateSelector from "@/components/DateSelector";
import { buttons, getTourDefaults } from "@/lib/shepherd-tour";
import PageHeader from "@/components/PageHeader";
import AccountDropdown from "@/components/AccountDropdown";
import MyReportsTour from "@/components/MyReportsTour";
import {
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbTabs,
    mdbIcon,
    mdbContainer,
    mdbCard,
    mdbCardTitle,
    mdbDatePicker2,
    mdbSpinner,
} from "mdbvue";
import qs from "qs";

export default {
    name: "MyReports",
    components: {
        mdbSpinner,
        MyReportsTour,
        DateSelector,
        PageHeader,
        AccountDropdown,
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbDropdownItem,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbTabs,
        mdbIcon,
        mdbContainer,
        mdbCard,
        mdbCardTitle,
        mdbDatePicker2,
    },
    data() {
        return {
            userAccounts: null,
            visible: false,
            startDate: new moment(new moment().subtract(90, "days").format("YYYY-MM-DD"), "YYYY-MM-DD"),
            endDate: new moment(new moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
            refundTypesOptions: {
                chart_name: "refund_type_cost",
                chart_type: "pie",
                type: "pie",
                title: {
                    text: "",
                },
                expanded: false,
            },
            refundTypes: {},
            weeklyBillingSummary: {},
            reportData: {},
            limit: 10,
            currentPage: 1,
            sortColumn: "f_1_3",
            sortkey: "f_1_3",
            sortDir: "desc",
            searchText: "",
            selectMode: "single",
            totalRecords: 0,
            totalPages: 0,
            limitOptions: [10, 25, 50, 100],
            selected: {},
            dateRangeSelected: "",
            context: null,
            isBusy: true,
            selectedAccountActive: false,
            selectedTypeActive: false,
            systemReports: null,
            selfGeneratedReports: null,
            selectedReport: null,
            selectedOperator: null,
            selectedFilter: null,
            formats: ["pdf", "xls", "csv", "xml", "html"],
            selectedFormat: null,
            showFilters: false,
            filters: null,
            reportDownloadLink: null,
            runningReport: false,
            reportsArchiveItems: null,
            addedFilters: [],
            filterParams: [],
            accountValues: null,
            tempFormat: null,
            addedFilterFields: [
                { label: "Name", field: "name" },
                { label: "Op", field: "op" },
                { label: "Values", field: "values" },
                { label: "Edit", field: "edit" },
            ],
            schemaSelected: null,
            schemaOptions: null,
            reportsArchiveFields: [
                {
                    label: "Name",
                    field: "report_name",
                },
                {
                    label: "Range",
                    field: "range",
                },
                {
                    label: "Created",
                    field: "created",
                },
            ],
            report: {},
            dropdownFilter: null,
            systemReportsList: null,
            selfGeneratedReportsList: null,
            loading: true,
        };
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        options: Object,
        headers: Object,
        downloadUniversal: Function,
    },
    methods: {
        startTour() {
            const tour = this.$shepherd(getTourDefaults());
            const steps = [
                {
                    text: document.getElementById("step-one").innerHTML,
                    buttons: [
                        {
                            text: "Next",
                            action: tour.next,
                        },
                    ],
                    title: "My Reports Tour",
                },
                {
                    attachTo: { element: "#report-select", on: "bottom" },
                    text: document.getElementById("step-two").innerHTML,
                    buttons,
                    title: "Select Report",
                },
                {
                    attachTo: { element: "#dateDropdown", on: "bottom" },
                    text: document.getElementById("step-three").innerHTML,
                    buttons,
                    title: "Select Date Range",
                },
                {
                    attachTo: { element: "#dropdown-form2", on: "bottom" },
                    text: document.getElementById("step-four").innerHTML,
                    buttons,
                    title: "Select Format",
                },
                {
                    attachTo: { element: "#account-select", on: "bottom" },
                    text: document.getElementById("step-five").innerHTML,
                    buttons,
                    title: "Select Accounts",
                },
                {
                    attachTo: { element: "#run-report", on: "bottom" },
                    text: document.getElementById("step-six").innerHTML,
                    buttons,
                    title: "Run Report",
                },
                {
                    attachTo: { element: "#display-area", on: "top" },
                    text: document.getElementById("step-seven").innerHTML,
                    buttons,
                    title: "Display Area",
                },
            ];

            tour.addSteps(steps);

            tour.start();
        },
        async getFilters() {
            const filters = await this.$httpReportsImpersonate.get(
                `report/${this.selectedReport.id}?type=html&cmpId=${this.userInfo.cmpID}`,
                {
                    headers: this.headers,
                }
            );
            this.filters = filters && filters.data ? filters.data.fields : null;
        },
        async advancedFilters() {
            if (this.selectedReport) {
                await this.getFilters();
                this.showFilters = !this.showFilters;
            } else {
                this.$notify.error({
                    message: `Please select a report first.`,
                    title: "Report not selected",
                    timeOut: 5000,
                    variant: "warning",
                });
            }
        },
        async checkSchema() {
            if (this.selectedFilter.listable) {
                const schemaOptions = await this.$httpReportsImpersonate.post(
                    `schema`,
                    { action: "getValues", field: this.selectedFilter.code },
                    {
                        headers: this.headers,
                    }
                );
                this.schemaOptions = schemaOptions && schemaOptions.data ? schemaOptions.data.values : null;
            }
        },
        addFilter() {
            let rowValues;
            let values = [];
            switch (this.selectedFilter.type) {
                case "Float":
                    values.push(this.selectedFilter["float_value"]);
                    if (this.selectedFilter["float_two_value"]) {
                        values.push(this.selectedFilter["float_two_value"]);
                    }
                    rowValues =
                        this.selectedFilter["float_value"] +
                        (this.selectedFilter["float_two_value"]
                            ? " and " + this.selectedFilter["float_two_value"]
                            : "");
                    break;
                case "DateTime":
                    values = this.selectedFilter["date_value"];
                    rowValues = values;
                    break;
                default:
                    values = this.selectedFilter["input_value"];
                    rowValues = values;
            }
            if (this.selectedFilter.listable) {
                values = this.schemaSelected.values;
                rowValues = this.schemaSelected.name;
            }
            let tempOperator = "";
            let selectedOperator = "";
            if (this.selectedOperator) {
                tempOperator = this.selectedOperator.split(" ");
                this.selectedOperator = this.selectedOperator[0];
                for (let i = 1; i < tempOperator.length; i++) {
                    selectedOperator += tempOperator[i] + " ";
                }
            }
            const rowFilter = {
                id: this.selectedFilter.code,
                aggr: this.selectedFilter.aggr,
                name: this.selectedFilter.display,
                values: rowValues,
                op: selectedOperator,
                index: this.addedFilters ? this.addedFilters.length + 1 : 0,
            };
            const filter = {
                id: this.selectedFilter.code,
                aggr: this.selectedFilter.aggr,
                name: this.selectedFilter.display,
                values: values,
                op: this.selectedOperator,
                index: this.addedFilters ? this.addedFilters.length + 1 : 0,
            };
            this.addedFilters.push(rowFilter);
            this.filterParams.push(filter);
            this.selectedFilter.added = true;
            this.selectedFilter = null;
            this.selectedOperator = null;
        },
        removeFilter(row) {
            this.filters.map((f) => {
                if (f.code === row.id) {
                    f.added = false;
                }
            });
            this.addedFilters = this.addedFilters.filter((f) => f.index !== row.index);
            this.filterParams = this.filterParams.filter((f) => f.index !== row.index);
        },
        deleteFilters() {
            this.showFilters = false;
            this.addedFilters = [];
            this.filterParams = [];
        },
        changeSelectedReport(report) {
            this.selectedReport = report;
        },
        updateStartDate(val) {
            this.startDate = val;
        },
        updateEndDate(val) {
            this.endDate = val;
        },
        formatDate(val, withTime) {
            return withTime ? moment(val).format("MM/DD/YYYY hh:mm a") : moment(val).format("MM/DD/YYYY");
        },
        async getReportsList() {
            this.isBusy = true;
            const params = new URLSearchParams({
                sortColumn: "rg_create_date",
                sortDir: "desc",
                rg_cmp_id: this.userInfo.cmpID,
            }).toString();
            const reports = (
                await this.$httpReportsImpersonate.get(`report?cmpId=${this.userInfo.cmpID}`, {
                    headers: this.headers,
                })
            ).data;
            this.systemReports = reports.filter((c) => c.type === 2);
            this.systemReportsList = this.systemReports;
            this.selfGeneratedReports = reports.filter((c) => c.type === 3);
            this.selfGeneratedReportsList = this.selfGeneratedReports;
            this.reportsArchiveItems = (
                await this.$httpReportsImpersonate.get(`reportgenerated?${params}`, {
                    headers: this.headers,
                })
            ).data.map((r) => {
                return {
                    ...r,
                    range: `${this.formatDate(r.rg_start_date)} - ${this.formatDate(r.rg_end_date)}`,
                    created: this.formatDate(r.rg_create_date),
                };
            });
            this.loading = false;
            this.isBusy = false;
        },
        async generateReport() {
            this.isBusy = true;
            this.selectedFormat = this.tempFormat;
            let good = true;
            this.runningReport = true;
            if (!this.selectedReport) {
                this.runningReport = false;
                this.$notify.error({
                    message: `You must select a report before continuing`,
                    title: "Error Processing Request",
                    autoHideDelay: 5000,
                    variant: "danger",
                });
                good = false;
                this.isBusy = false;
            }
            if (!this.selectedFormat) {
                this.runningReport = false;
                this.$notify.error({
                    message: `You must select a report format before continuing`,
                    title: "Error Processing Request",
                    timeOut: 5000,
                    variant: "danger",
                });
                good = false;
                this.isBusy = false;
            }
            if (!this.accountValues.length > 0) {
                this.runningReport = false;
                this.$notify.error({
                    message: `You must select at least one account before continuing`,
                    title: "Error Processing Request",
                    autoHideDelay: 5000,
                    variant: "danger",
                });
                good = false;
                this.isBusy = false;
            }
            if (good) {
                let accounts = [];
                if (this.selectedAccountActive) {
                    for (let i = 0; i < this.accountValues.length; i++) {
                        accounts.push(parseInt(this.accountValues[i]));
                    }
                }
                for (let i = 0; i < this.filterParams.length; i++) {
                    delete this.filterParams[i].name;
                }
                let url = `reportfile`;
                const data = {
                    id: Number(this.selectedReport.id),
                    cmpId: this.userInfo.cmpID,
                    start: moment(this.startDate).format("YYYY-MM-DD"),
                    end: moment(this.endDate).format("YYYY-MM-DD"),
                    accounts: accounts,
                };
                if (this.selectedFormat === "html") {
                    data.limit = this.limit;
                    data.page = this.currentPage;
                    data.size = this.limit;
                    data.sortColumn = this.filters ? this.filters[0].code : this.sortColumn;
                    data.sortkey = this.filters ? this.filters[0].code : this.sortkey;
                    data.sortDir = this.sortDir;
                    url = "reportdata";
                } else {
                    data.output = this.selectedFormat;
                }
                if (this.filterParams) {
                    data.conditions = this.filterParams;
                }
                this.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
                if (this.selectedFormat != "html") {
                    data.conditions = this.filterParams;
                }
                try {
                    this.reportDownloadLink = await this.$httpReportsImpersonate.post(url, qs.stringify(data), {
                        headers: this.headers,
                    });
                } catch (error) {
                    this.$notify.error({
                        message: error || "There was an error generating this report",
                        title: "Error Processing Request",
                        autoHideDelay: 5000,
                        variant: "danger",
                    });
                }
                if (this.reportDownloadLink.data == "Error: No data results.") {
                    this.reportDownloadLink = "no data";
                } else {
                    if (this.selectedFormat === "html") {
                        this.report = {
                            columns: null,
                            rows: null,
                        };
                        this.report.rows = this.reportDownloadLink.data.items;
                        this.totalRecords = this.reportDownloadLink.data.total_records;
                        this.totalPages = this.reportDownloadLink.data.total_pages;
                        this.report.columns = this.filters.map((m) => {
                            return {
                                label: m.display,
                                field: m.code,
                            };
                        });
                    }
                }
                this.runningReport = false;
            }
            this.isBusy = false;
        },
        async downloadReport(val) {
            this.downloadUniversal(val.data, this.selectedFormat);
        },
        updateSelectedAccount(userAccounts, accountValues) {
            this.userAccounts = userAccounts;
            this.accountValues = accountValues;
        },
        updateSelectedActive(val) {
            this.selectedAccountActive = val;
        },
        onSortChange(params) {
            this.sort = params[0].field;
            this.sortDir = params[0].type;
        },
        onPageChange(params) {
            this.currentPage = params.currentPage;
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
        },
        filterSearch() {
            if (this.dropdownFilter != null && this.dropdownFilter != "") {
                this.showReports = false;
                this.systemReportsList = this.systemReports.filter((item) =>
                    this.dropdownFilter
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v))
                );
                this.selfGeneratedReportsList = this.selfGeneratedReports.filter((item) =>
                    this.dropdownFilter
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v))
                );
            } else {
                this.systemReportsList = this.systemReports;
                this.selfGeneratedReportsList = this.selfGeneratedReports;
            }
            this.showReports = true;
        },
        customerSearch() {
            if (this.customerFilter != null && this.customerFilter != "") {
                this.customerList = this.customers.filter((item) =>
                    this.customerFilter
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.cmp_name.toLowerCase().includes(v))
                );
            } else {
                this.customerList = this.customers;
            }
        },
        setTempFormat(type) {
            this.tempFormat = type;
        },
    },
    async mounted() {
        this.getReportsList();
    },
    watch: {
        currentPage() {
            this.generateReport();
        },
        limit() {
            this.generateReport();
        },
        selectedReport() {
            this.getFilters();
        },
    },
};
</script>
<style>
#dateDropdown .dropdown {
    width: 100% !important;
}
.myReports .dropdown-menu {
    max-height: 400px;
    overflow: scroll;
}
#dateDropdown {
    margin-left: 0 !important;
}
#addedFilters th {
    display: none;
}
.myReports .vgt-wrap__footer {
    min-height: 125px !important;
}
.myReports .link {
    cursor: pointer;
    font-weight: bold;
}
.myReports {
    height: 100vh;
}
</style>
