<template>
    <mdb-container fluid>
        <h1 class="page-header border-bottom mx-4">
            Invoices
            <mdb-icon
                style="cursor: pointer"
                icon="file-invoice"
                size="1x"
                @click.native="startTour"
                class="float-right text-muted"
                role="button"
                data-test="invoicesTour"
            />
        </h1>
        <mdb-row>
            <mdb-col col="9">
                <mdb-card>
                    <mdb-row>
                        <mdb-col col="4" class="mdb-btn-group action-buttons" role="group">
                            <mdb-btn
                                data-test="mdb-btn_showInvoiceGenie"
                                @click.native="showInvoiceGenie = true"
                                color="default"
                                id="analyze"
                                >Analyze Invoice&nbsp;
                                <mdb-icon icon="magic" />
                            </mdb-btn>
                        </mdb-col>

                        <mdb-col col="8" class="input-group md-form text-right py-1 my-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text default-color">
                                    <mdbIcon icon="search" class="text-white" />
                                </span>
                            </div>
                            <input
                                data-test="searchBar"
                                id="search"
                                class="form-control border-default"
                                type="text"
                                placeholder="Search"
                                aria-label="Search"
                                v-model="searchText"
                                @keydown.enter="performQuery"
                            />
                            <mdb-btn
                                data-test="mdb-btn_searchText"
                                @click.native="searchText = ''"
                                v-if="searchText"
                                variant="info"
                            >
                                <mdb-icon icon="times"></mdb-icon>
                            </mdb-btn>
                        </mdb-col>
                    </mdb-row>
                    <div v-if="tableData">
                        <vue-good-table
                            data-test="vue-good-table"
                            id="tableData"
                            :columns="tableData.columns"
                            :rows="tableData.rows"
                            mode="remote"
                            :isLoading.sync="isBusy"
                            :totalRows="totalRecords"
                            @on-row-click="onRowClick"
                            @on-sort-change="onSortChange"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: limit,
                                position: 'bottom',
                                perPageDropdown: limitOptions,
                                dropdownAllowAll: false,
                                setCurrentPage: currentPage,
                                nextLabel: '>',
                                prevLabel: '<',
                                rowsPerPageLabel: 'Rows per page',
                                ofLabel: 'of',
                                pageLabel: 'page', // for 'pages' mode
                                allLabel: 'All',
                                total: totalRecords,
                            }"
                        >
                            <div
                                data-test="div_selected_inv_number"
                                slot="selected-row-actions"
                                v-if="selected && selected.inv_number"
                            >
                                Invoice # {{ selected.inv_number }}
                            </div>
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'before'">
                                    <mdb-input
                                        type="radio"
                                        :id="'radio-group-selected-row-' + props.row.originalIndex"
                                        name="radio-group-selected-row"
                                        label=" "
                                        :radioValue="props.row.originalIndex.toString()"
                                        v-model="isSelected"
                                        style="width: 40px"
                                    />
                                </span>
                                <span v-else-if="props.column.field === 'inv_amount'"
                                    >{{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                                </span>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                            <div slot="emptystate">No records were found.</div>
                        </vue-good-table>
                        <div class="w-100 mb-5">
                            <div class="flex-1 float-left col-3">
                                <mdb-pagination
                                    data-test="mdb-pagination"
                                    class="flex-1 col-6 float-left"
                                    v-model="currentPage"
                                    :total-rows="totalRecords"
                                    :per-page="limit"
                                    align="center"
                                >
                                </mdb-pagination>
                                <mdb-btn
                                    data-test="button_download"
                                    class="btn btn-default"
                                    @click.native="download"
                                    title="Download CSV"
                                >
                                    <mdb-icon icon="download" />
                                </mdb-btn>
                                <div class="hide">
                                    <download-csv
                                        data-test="download-csv"
                                        ref="downloader"
                                        id="downloader"
                                        name="invoice_download.csv"
                                        :data="downloadData"
                                    >
                                    </download-csv>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </mdb-card>
            </mdb-col>
            <mdb-col col="3">
                <mdb-card data-test="mdb-card_sidebar" id="sidebar">
                    <ul class="list-group list-group-flush border rounded">
                        <li class="list-group-item bg-light">
                            Summary
                            <div class="float-right text-muted"><small>90 Days</small></div>
                        </li>
                        <li class="list-group-item">
                            <div class="row">
                                <div class="col-xs-6 col-md-6">
                                    <div class="text-right">Total Spend</div>
                                    <div class="">
                                        <div>
                                            <h4 class="text-lg-right py-1">
                                                {{ userInfo.currencySymbol }}{{ totalSpendWeekly.toLocaleString() }}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-md-6">
                                    <div class="w-75 text-right">
                                        <mdb-spinner
                                            data-test="mdb-spinner"
                                            v-if="showSpinnerTotalSpend"
                                            variant="primary"
                                            label="Loading"
                                        ></mdb-spinner>
                                        <div id="index_shipment_avg_cost_weekly">
                                            <highcharts
                                                data-test="highcharts_totalSpendChart"
                                                :options="totalSpendChart"
                                            ></highcharts>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <router-link data-test="router-link_trends" to="trends">
                                <div class="inline-block float-left col-xs-10 text-left">View More</div>
                                <div class="col-xs-2 float-right text-right inline-block">
                                    <mdb-icon icon="arrow-circle-right" />
                                </div>
                            </router-link>
                        </li>
                    </ul>

                    <ul class="list-group list-group-flush border mt-2 rounded mb-5">
                        <li class="list-group-item bg-light">Carrier Spend</li>
                        <li class="list-group-item p-0">
                            <div>
                                <div id="index_carrier_spend">
                                    <highcharts
                                        data-test="highcharts_carrierSpend"
                                        :options="carrierSpend"
                                    ></highcharts>
                                </div>
                            </div>
                        </li>
                    </ul>
                </mdb-card>
            </mdb-col>
        </mdb-row>

        <mdb-modal
            data-test="showPackageGenieModal"
            id="packageGenie"
            :show="showPackageGenie"
            @close="closePackageGenie"
            title="Package Genie"
            size="fluid"
        >
            <mdb-modal-header data-test="showPackageGenieModalHeader">
                <mdb-modal-title data-test="showPackageGenieModalTitle" class="w-full">
                    <mdb-icon icon="magic" class="mr-3" />
                    <span>Package Genie</span>
                </mdb-modal-title>
            </mdb-modal-header>
            <PackageGenie
                v-if="selected"
                :invoiceNum="selected.invoice_number"
                :userInfo="userInfo"
                :headers="headers"
                :package="selected"
                @close-invoice-genie="closeInvoiceGenie"
                @close-package-genie="closePackageGenie"
                @open-invoice-genie="openInvoiceGenie"
                @open-package-genie="openPackageGenie"
            />
        </mdb-modal>
        <mdb-modal id="invoiceGenie" :show="showInvoiceGenie" @close="closeInvoiceGenie" size="fluid">
            <mdb-modal-header>
                <mdb-modal-title><mdb-icon icon="magic" class="mr-3" /> Invoice Genie</mdb-modal-title>
            </mdb-modal-header>
            <InvoiceGenie
                :invoice="selected"
                :userInfo="userInfo"
                :headers="headers"
                @close-invoice-genie="closeInvoiceGenie"
                @close-package-genie="closePackageGenie"
                @open-invoice-genie="openInvoiceGenie"
                @open-package-genie="openPackageGenie"
            />
        </mdb-modal>
        <InvoicesTour />
    </mdb-container>
</template>
<script>
import InvoicesTour from "@/components/InvoicesTour";
import InvoiceGenie from "@/components/InvoiceGenie";
import moment from "moment";
import maps from "../../lib/charts";
import { buttons, getTourDefaults } from "../../lib/shepherd-tour";
import { VueGoodTable } from "vue-good-table";
import {
    mdbBtn,
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbIcon,
    mdbCard,
    mdbSpinner,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    mdbPagination,
} from "mdbvue";
import PackageGenie from "@/components/PackageGenie.vue";

export default {
    name: "Invoices",
    components: {
        PackageGenie,
        InvoicesTour,
        InvoiceGenie,
        VueGoodTable,
        mdbBtn,
        mdbContainer,
        mdbCol,
        mdbRow,
        mdbIcon,
        mdbCard,
        mdbSpinner,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbInput,
        mdbPagination,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            showInvoiceGenie: false,
            showPackageGenie: false,
            isSelected: "0",
            totalSpendWeekly: 0,
            carrierSpend: {},
            showSpinnerTotalSpend: true,
            isBusy: false,
            totalSpendChart: {},
            tableData: null,
            limit: 10,
            maxPage: 5,
            currentPage: 1,
            sortColumn: "inv_date",
            sortkey: "inv_date",
            sortDir: "desc",
            searchText: "",
            totalRecords: 0,
            totalPages: 0,
            selected: {},
            selectMode: "single",
            limitOptions: [10, 25, 50, 100, 150],
            carrierSpendOptions: {
                chart_name: "carrier_spend",
                chart_type: "pie",
                type: "pie",
                title: {
                    text: "",
                },
                expanded: false,
            },
            totalSpendOptions: {
                chart_name: "total_spend_weekly",
                type: "sparkline",
                chart_type: "sparkline",
                title: {
                    text: "",
                },
                expanded: false,
            },
            downloadData: [],
            responseData: null,
        };
    },
    methods: {
        startTour() {
            const tour = this.$shepherd(getTourDefaults());
            const steps = [
                {
                    text: document.getElementById("step-one").innerHTML,
                    buttons: [
                        {
                            action() {
                                return this.next();
                            },
                            text: "Next",
                        },
                    ],
                    title: "Invoices Tour",
                },
                {
                    attachTo: { element: "#search", on: "bottom" },
                    text: document.getElementById("step-two").innerHTML,
                    buttons,
                    title: "Invoice Search",
                },
                {
                    target: "#tableData thead",
                    attachTo: { element: "#tableData thead", on: "bottom" },
                    text: document.getElementById("step-three").innerHTML,
                    buttons,
                    title: "Sort Invoices",
                },
                {
                    attachTo: { element: "#analyze", on: "bottom" },
                    text: document.getElementById("step-four").innerHTML,
                    buttons,
                    title: "Invoice Genie",
                },
            ];

            tour.addSteps(steps);

            tour.start();
        },
        moment: (date) => {
            return moment(date).format("YYYY-MM-DD");
        },
        onRowClick(params) {
            this.selected = params.row;
            this.isSelected = params.row.originalIndex.toString();
        },
        closeInvoiceGenie() {
            this.showInvoiceGenie = false;
        },
        closePackageGenie() {
            this.showPackageGenie = false;
        },
        openInvoiceGenie(invoice) {
            this.selected = invoice;
            this.showPackageGenie = false;
            this.showInvoiceGenie = true;
        },
        openPackageGenie(trackingInfo) {
            this.selected = trackingInfo;
            this.showInvoiceGenie = false;
            this.showPackageGenie = true;
        },
        buildQueryParams() {
            return {
                inv_cmp_id: this.userInfo.cmpId,
                limit: this.limit,
                size: this.limit,
                page: this.currentPage,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                inv_number: this.searchText != "" ? this.searchText : null,
            };
        },
        async performQuery() {
            this.isBusy = true;
            await this.$http
                .get(`/invoice`, { headers: this.headers, params: this.buildQueryParams() })
                .then(async (response) => {
                    this.isBusy = false;
                    const columns = [
                        {
                            label: "",
                            field: "before",
                            sortable: false,
                        },
                        { field: "car_name", label: "Carrier" },
                        { field: "acct_number", label: "Account Number" },
                        { field: "inv_number", label: "Invoice Number" },
                        { field: "inv_type", label: "Type" },
                        {
                            field: "inv_date",
                            label: "Invoice Date",
                            formatFn: this.moment,
                        },
                        { field: "inv_no_shipments", label: "Shipments" },
                        {
                            field: "inv_amount",
                            label: "Total Spend",
                            sort: true,
                        },
                        { field: "inv_credit", label: "Carrier Credit" },
                        { field: "inv_refund", label: "Audit Refund" },
                    ];
                    this.tableData = {
                        columns,
                        rows: response.data[0].Items,
                    };
                    this.selected = this.tableData.rows[0];
                    this.totalRecords = response.data[0].TotalRecords;
                    this.totalPages = response.data[0].TotalPages;
                    this.isBusy = false;
                });
        },
        async getChartData(data) {
            const param = { cmpId: this.userInfo.cmpID, chartName: data.chart_name, chartType: data.chart_type };
            let url = "/chartdata";

            return await this.$http.post(url, param, { headers: this.headers });
        },
        async getTotalWeekly(data) {
            let totalSpent90 = 0;
            for (var i = 0; i < data.series[0].data.length; i++) totalSpent90 += data.series[0].data[i].y;
            this.totalSpendWeekly = Math.round(totalSpent90);
            this.showSpinnerTotalSpend = false;
        },
        async download() {
            let searchTerm = null;
            if (this.searchText) {
                searchTerm = { inv_number: this.searchText };
            }
            await this.$http
                .get(`/invoice`, {
                    headers: this.headers,
                    params: {
                        inv_cmp_id: this.userInfo.cmpId,
                        limit: 0,
                        size: 0,
                        page: 1,
                        sortColumn: this.sortColumn,
                        sortkey: this.sortkey,
                        sortDir: this.sortDir,
                        ...searchTerm,
                    },
                })
                .then(async (response) => {
                    this.downloadData = response.data;
                    const el = document.getElementById("downloader");
                    setTimeout(() => {
                        el.click();
                        this.downloadData = null;
                    }, 200);
                });
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortkey = params[0].field;
            this.sortDir = params[0].type;
            this.performQuery();
        },
        onPageChange(params) {
            this.currentPage = params.currentPage;
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
        },
    },
    async mounted() {
        await this.performQuery();
        let chart = (await this.getChartData(this.carrierSpendOptions)).data;
        this.carrierSpend = maps.drawChart({ ...chart, ...this.carrierSpendOptions });
        chart = (await this.getChartData(this.totalSpendOptions)).data;
        await this.getTotalWeekly(chart);
        this.totalSpendChart = maps.drawChart({ ...chart, ...this.totalSpendOptions });
        this.totalSpendChart.tooltip = { outside: true };
    },
    watch: {
        currentPage() {
            this.performQuery();
        },
        limit() {
            this.performQuery();
        },
        sort() {
            this.performQuery();
        },
    },
};
</script>
<style>
.modal-xl {
    max-width: 1000px !important;
}
.hide {
    display: none !important;
}
</style>
