<template>
    <mdb-container fluid>
        <h1 class="page-header border-bottom mx-2">
            Trend Analyzer
            <span class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block">Overview</span>
            <mdb-icon
                style="cursor: pointer"
                icon="chart-line"
                size="1x"
                class="float-right text-muted"
                role="button"
                @click.native="startTour"
                title="Tour this page"
            />
        </h1>
        <mdb-row class="mb-4">
            <mdb-col col="3" class="parameterbar dropdown"
                ><!--col-lg-12-->
                <AccountDropdown
                    data-test="accountsDropdown"
                    id="selectAccounts"
                    @update-account-selected="updateSelectedAccount"
                    @update-selected-active="updateSelectedActive"
                    :prepopulate="true"
                    :userId="userInfo.usrId"
                    :cmpId="userInfo.cmpID"
                    :headers="headers"
                    class="ml-1"
                />
            </mdb-col>
        </mdb-row>
        <mdb-row class="mb-2">
            <mdb-col v-if="loadingData" class="text-center"> <mdb-spinner size="2xl" /></mdb-col>
            <mdb-col col="12" v-else>
                <highcharts
                    data-test="highcharts_mainChart"
                    :constructor-type="'stockChart'"
                    :options="mainChart"
                    id="graph"
                ></highcharts>
            </mdb-col>
        </mdb-row>
        <mdb-row class="spacer10">
            <mdb-col lg="8" class="mt-3">
                <mdb-card data-test="mdb-card_rangeSummary" id="rangeSummary">
                    <mdb-card-header>Range Summary</mdb-card-header>
                    <mdb-card-body v-if="loadingData" class="text-center"> <mdb-spinner size="2xl" /></mdb-card-body>
                    <mdb-card-body v-else>
                        <mdb-row>
                            <mdb-col sm="6" md="3" v-for="(sparkChart, index) of rangeSparks" v-bind:key="index">
                                <div class="mt-2">
                                    <mdb-row class="mb-2">
                                        <mdb-input
                                            data-test="rangeSparks"
                                            type="checkbox"
                                            class="form-inline pr-0 pl-2"
                                            :id="'spark' + index"
                                            :name="'spark' + index"
                                            v-model="rangeSparks[index].selected"
                                            @click.native="redrawChart(sparkChart, index)"
                                        />
                                        <span col="10">{{ sparkChart.heading }}</span>
                                    </mdb-row>
                                    <h5>
                                        {{ sparkChart.dataType !== "number" ? userInfo.currencySymbol : ""
                                        }}{{ sparkChart.value }}
                                    </h5>
                                    <div :id="index">
                                        <highcharts
                                            data-test="highcharts_sparkChart"
                                            :options="sparkChart"
                                        ></highcharts>
                                    </div>
                                </div>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
            <mdb-col lg="4" class="mt-3">
                <mdb-card data-test="mdb-card_pointSummary">
                    <mdb-card-header color="light-blue lighten-2">
                        <mdb-row>
                            <mdb-col col="4">Point Summary</mdb-col>
                            <mdb-col col="8" v-if="items.length" class="pl-0">
                                <mdb-row>
                                    <mdb-col col="6">
                                        <mdb-dropdown
                                            data-test="mdb-dropdown_selectedInvoice"
                                            class="float-right mt-1"
                                            v-if="selectedInvoice"
                                            id="invoicesBtn"
                                        >
                                            <mdb-dropdown-toggle slot="toggle" outline="white" size="sm" class="p-2">
                                                <mdb-icon icon="file-alt" class="mr-2"></mdb-icon>
                                                <span v-if="!selectedInvoice.inv_number"> Invoices </span>
                                                <span v-else>
                                                    {{ selectedInvoice.inv_number }}
                                                </span>
                                            </mdb-dropdown-toggle>
                                            <mdb-dropdown-menu>
                                                <mdb-dropdown-item
                                                    data-test="selectInvoiceBtn"
                                                    v-for="(item, index) of items"
                                                    v-bind:key="index"
                                                    @click.native="selectInvoice(item)"
                                                >
                                                    {{ item.invoice }} -
                                                    {{ userInfo.currencySymbol + item.data.toFixed(2) }}
                                                </mdb-dropdown-item>
                                            </mdb-dropdown-menu>
                                        </mdb-dropdown>
                                    </mdb-col>
                                    <mdb-col col="6" class="pr-0">
                                        <mdb-btn
                                            data-test="mdb-btn_analyzeInvoice"
                                            id="analyzeInvoiceBtn"
                                            @click.native="showInvGenie"
                                            outline="white"
                                            class="border p-2 mt-1 analyzeInvoiceBtn"
                                            size="sm"
                                            >Analyze Invoice
                                            <mdb-icon icon="magic" class="ml-2" />
                                        </mdb-btn>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-header>
                    <mdb-card-body>
                        <mdb-row v-if="loadingData"
                            ><mdb-col class="text-center"><mdb-spinner size="2xl" /></mdb-col
                        ></mdb-row>
                        <mdb-row v-else data-test="rangeSparksHeadingsAndValues">
                            <mdb-col col="6" v-for="(sparkChart, index) of rangeSparks" v-bind:key="index">
                                <p>{{ sparkChart.heading }}</p>
                                <p>{{ values[index] }}</p>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <mdb-modal
            data-test="modal_invoiceGenie"
            id="invoiceGenie"
            :show="showInvoiceGenie"
            @close="showInvoiceGenie = false"
            title="Invoice Genie"
            size="fluid"
        >
            <mdb-modal-header>
                <mdb-modal-title><mdb-icon icon="magic" class="mr-3" /> Invoice Genie</mdb-modal-title>
            </mdb-modal-header>
            <InvoiceGenie data-test="invoiceGenie" :invoice="selectedInvoice" :userInfo="userInfo" :headers="headers" />
        </mdb-modal>
        <TrendsTour />
    </mdb-container>
</template>
<script>
import TrendsTour from "@/components/TrendsTour";
import InvoiceGenie from "@/components/InvoiceGenie";
import AccountDropdown from "@/components/AccountDropdown";
import { buttons, getTourDefaults } from "@/lib/shepherd-tour";
import maps from "@/lib/charts";
import {
    mdbBtn,
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbIcon,
    mdbTabs,
    mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbCardHeader,
    mdbAccordion,
    mdbAccordionPane,
    mdbSpinner,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
} from "mdbvue";
import { VueGoodTable } from "vue-good-table";

export default {
    name: "Trends",
    components: {
        TrendsTour,
        InvoiceGenie,
        AccountDropdown,
        VueGoodTable,
        mdbBtn,
        mdbContainer,
        mdbCol,
        mdbRow,
        mdbIcon,
        mdbTabs,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbCardHeader,
        mdbAccordion,
        mdbAccordionPane,
        mdbSpinner,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbInput,
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbDropdownItem,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
        callMe: {
            type: Function,
        },
    },
    data() {
        return {
            info: [],
            chartData: null,
            userAccounts: null,
            showInvoiceGenie: false,
            selectedAccountActive: null,
            sparkValues: null,
            rangeSparks: [],
            invoiceAccount: null,
            loadingData: false,
            invoiceData: {
                ids: null,
                numbers: null,
            },
            selected: null,
            invoicesForDate: null,
            colors: ["#89A54E", "#DB843D", "#AA4643", "#A47D7C", "#3D96AE", "#4572A7", "#92A8CD", "#80699B"],
            colors15: ["#edf2e5", "#FAEDE2", "#F2E3E3", "#F1ECEB", "#E2EFF3", "#E3EAF2", "#eff2f8", "#ece9f0"],
            mainChart: {},
            dataToParse: {},
            accountValues: null,
            sideCharts: [
                {
                    chartOptions: {
                        name: "total_spend",
                    },
                    chart_name: "total_spend",
                    chart_type: "sparkline",
                    type: "sparkline",
                    title: {
                        text: "Total Spend",
                    },
                    order: 0,
                    arrayNum: 3,
                    selected: true,
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "accesorial_cost",
                    },
                    chart_name: "accesorial_cost",
                    chart_type: "sparkline",
                    type: "sparkline",
                    arrayNum: 4,
                    title: {
                        text: "Accessorial Cost",
                    },
                    order: 1,
                    selected: false,
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "fuel_cost",
                    },
                    chart_name: "fuel_cost",
                    chart_type: "sparkline",
                    type: "sparkline",
                    arrayNum: 5,
                    title: {
                        text: "Fuel Cost",
                    },
                    order: 2,
                    selected: false,
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "mis_cost",
                    },
                    chart_name: "mis_cost",
                    chart_type: "sparkline",
                    type: "sparkline",
                    arrayNum: 6,
                    title: {
                        text: "Misc Cost",
                    },
                    order: 3,
                    selected: false,
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "nopackages",
                    },
                    chart_name: "nopackages",
                    chart_type: "sparkline",
                    type: "sparkline",
                    dataType: "number",
                    arrayNum: 7,
                    title: {
                        text: "Packages",
                    },
                    order: 4,
                    selected: false,
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "noshipments",
                    },
                    chart_name: "noshipments",
                    chart_type: "sparkline",
                    type: "sparkline",
                    dataType: "number",
                    arrayNum: 8,
                    title: {
                        text: "Shipments",
                    },
                    order: 5,
                    selected: false,
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "avg_packages",
                    },
                    chart_name: "avg_packages",
                    chart_type: "sparkline",
                    type: "sparkline",
                    arrayNum: 9,
                    title: {
                        text: "Avg Packages",
                    },
                    order: 6,
                    selected: false,
                    expanded: false,
                },
                {
                    chartOptions: {
                        name: "avg_shipments",
                    },
                    chart_name: "avg_shipments",
                    chart_type: "sparkline",
                    type: "sparkline",
                    arrayNum: 10,
                    title: {
                        text: "Avg Shipment",
                    },
                    order: 7,
                    selected: false,
                    expanded: false,
                },
            ],
            currentMinDate: 0,
            currentMaxDate: 2371873600000,
            pointInfo: null,
            ts: null,
            data: null,
            values: null,
            items: [],
            selectedInvoice: {},
        };
    },
    methods: {
        startTour() {
            const tour = this.$shepherd(getTourDefaults());
            const steps = [
                {
                    text: document.getElementById("step-one").innerHTML,
                    buttons: [
                        {
                            text: "Next",
                            action: tour.next,
                        },
                    ],
                    title: "Trend Analyzer Tour",
                },
                {
                    attachTo: { element: "#selectAccounts", on: "bottom" },
                    text: document.getElementById("step-two").innerHTML,
                    buttons,
                    title: "Select Accounts",
                },
                {
                    attachTo: { element: "#graph", on: "top" },
                    text: document.getElementById("step-three").innerHTML,
                    buttons,
                    title: "Invoice Visualization",
                },
                {
                    attachTo: { element: "#graph", on: "bottom" },
                    text: document.getElementById("step-four").innerHTML,
                    buttons,
                    title: "Date Range Selection",
                },
                {
                    attachTo: { element: "#rangeSummary", on: "top" },
                    text: document.getElementById("step-five").innerHTML,
                    buttons,
                    title: "Range Summary",
                },
                {
                    attachTo: { element: "#spark3", on: "left" },
                    text: document.getElementById("step-six").innerHTML,
                    buttons,
                    title: "Select/Deselect Metrics",
                },
                {
                    attachTo: { element: "#invoicesBtn", on: "left" },
                    text: document.getElementById("step-seven").innerHTML,
                    buttons,
                    title: "Select Individual Invoice",
                },
                {
                    attachTo: { element: "#analyzeInvoiceBtn", on: "left" },
                    text: document.getElementById("step-eight").innerHTML,
                    buttons,
                    title: "Dive Deeper",
                },
            ];

            tour.addSteps(steps);

            tour.start();
        },
        redrawChart(chart, index) {
            // need time for checkbox value to change/render
            setTimeout(() => {
                let chartsSelected = false;
                for (let i = 0; i < this.rangeSparks.length; i++) {
                    if (this.rangeSparks[i].selected) {
                        chartsSelected = true;
                    }
                }
                if (!chartsSelected) {
                    this.rangeSparks[index].selected = true;
                }
                this.buildInvoices();
            }, 500);
        },
        selectInvoice(inv) {
            this.selectedInvoice = {
                inv_number: inv.invoice,
            };
        },
        getParams() {
            return {
                action: "getInvoiceAnalyzerData",
                userId: this.userInfo.usrId,
                cmpId: this.userInfo.cmpID,
            };
        },
        async performQuery() {
            this.loadingData = true;
            return await this.$http
                .post("/user", this.getParams(), { headers: this.headers })
                .then(async (response) => {
                    this.chartData = response.data;
                    await this.loadDataValues(this.chartData);
                });
        },
        findAllInvoices(value, isTime) {
            const invoices = [];
            this.selectedInvoice = {};
            this.items = [];
            const ts = this.ts;
            if (!isTime || isTime == "undefined" || value < 0) {
                let time = 0;
                for (let i = ts.length - 1; i > -1 && ts[i] >= time; i--) {
                    if (ts[i] < this.currentMinDate || ts[i] > this.currentMaxDate) continue;
                    if (this.userAccounts && !this.userAccounts.includes(this.invoiceAccount[i])) continue;
                    if (time === 0) time = ts[i];

                    if (ts[i] === time) invoices.push(i);
                }
            } else {
                var tms = [];
                for (let i = 0; i < ts.length; i++) {
                    if (ts[i] < this.currentMinDate || ts[i] > this.currentMaxDate) continue;

                    if (this.userAccounts && !this.userAccounts.includes(this.invoiceAccount[i])) continue;

                    if (tms.length == 0) tms.push(ts[i]);
                    else if (tms[tms.length - 1] != ts[i]) tms.push(ts[i]);
                }

                if (tms.length == 0) return invoices;

                let time = 2371873600000;
                for (let i = 0; i < tms.length; i++) {
                    if (tms[i] == value) time = tms[i];

                    if (tms[i] > value && time == 2371873600000) {
                        if (i == 0) time = this.ts[i];
                        else {
                            if (value - tms[i - 1] < tms[i] - value) time = tms[i - 1];
                            else time = tms[i];
                        }
                        break;
                    }
                }

                if (time == 2371873600000) time = tms[tms.length - 1];

                for (let i = 0; i < ts.length && ts[i] <= time; i++) {
                    if (this.userAccounts && !this.userAccounts.includes(this.invoiceAccount[i])) continue;

                    if (ts[i] == time) invoices.push(i);
                }
            }

            for (let cnt = 0; cnt < invoices.length; cnt++) {
                let i = invoices[cnt];
                this.items.push({ invoice: this.invoiceData.numbers[i], data: this.data[0][i] });
            }
            this.updateInvoicePointData(invoices);
        },
        updateInvoicePointData(invoices) {
            this.values = [0, 0, 0, 0, 0, 0, 0, 0];
            for (let cnt = 0; cnt < invoices.length; cnt++) {
                let i = invoices[cnt];
                for (let j = 0; j < 6; j++) {
                    this.values[j] += this.data[j][i];
                }
            }
            this.values[6] = (this.values[0] / this.values[4]).toFixed(2);
            this.values[7] = (this.values[0] / this.values[5]).toFixed(2);
            this.values[0] = this.values[0]
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.values[1] = this.values[1]
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            for (let j = 0; j < 8; j++) {
                let dollar = j !== 4 && j !== 5 ? this.userInfo.currencySymbol : "";
                this.values[j] = dollar + this.values[j].toLocaleString();
            }
        },
        buildInvoices() {
            this.data = [];
            const names = [];
            const vals = this.dataToParse.keyvalues;
            // parse array from 3-11 values for lines on main chart
            for (let i = 3; i < 11; i++) {
                this.data[i - 3] = JSON.parse(vals[i].value);
                names[i - 3] = vals[i].key;
            }
            // find out which checkboxes are selected
            const selectedData = [];
            for (let i = 0; i < this.rangeSparks.length; i++) {
                if (this.rangeSparks[i].selected) {
                    selectedData.push({
                        data: this.data[i],
                        color: [this.rangeSparks[i].colors[0], this.rangeSparks[i].colors[1]],
                        name: this.rangeSparks[i].heading,
                    });
                }
            }
            let newMapping = {
                data: [],
            };
            // only show selected checkbox values on main chart
            for (let s = 0; s < selectedData.length; s++) {
                let points = [];
                let lastTime = 0;
                let lastIndex = -1;
                selectedData[s].data.forEach((k, i) => {
                    // this invoice number should be included in the checked accounts, or leave it out
                    if (!this.userAccounts || this.userAccounts.includes(this.invoiceAccount[i])) {
                        // if this is the same date as the last point
                        // just add to last point instead of creating a new point
                        if (lastTime === this.ts[i]) {
                            points[lastIndex].y += k;
                        } else {
                            lastTime = this.ts[i];
                            lastIndex++;
                            points.push({
                                y: k,
                                x: this.ts[i],
                            });
                        }
                    }
                });
                newMapping.data.push({
                    series: points,
                    color: selectedData[s].color,
                    name: selectedData[s].name,
                });
            }
            const chartMain = maps.drawChart({
                series: newMapping,
                chartOptions: {
                    name: "invoices",
                },
                chart_name: "invoices",
                chart_type: "line",
                type: "line",
                title: {
                    text: "",
                },
                currentMaxDate: this.currentMaxDate,
                currentMinDate: this.currentMinDate,
            });
            chartMain.chart.events = {
                click: (e) => {
                    const time = e.xAxis[0].value;
                    this.findAllInvoices(time, true);
                },
            };
            chartMain.plotOptions = {
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: (e) => {
                                const time = e.point.category;
                                this.findAllInvoices(time, true);
                            },
                        },
                    },
                },
            };
            chartMain.xAxis = {
                events: {
                    setExtremes: (e) => {
                        this.currentMinDate = e.min;
                        this.currentMaxDate = e.max;
                        this.updateData();
                    },
                },
            };
            chartMain.tooltip = {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                valueDecimals: 2,
                followPointer: true,
            };
            this.mainChart = chartMain;
            this.findAllInvoices(-1);
            this.loadingData = false;
        },
        calculateSparkLine(tms, seq) {
            if (!seq || seq == null || seq == "undefined") return [];

            if (seq.length < 4) return seq;

            var mid = Math.floor(seq.length / 2);
            var slp1 = this.calculateSlope(tms, seq, 0, mid);
            var slp2 = this.calculateSlope(tms, seq, mid, seq.length);

            return [0, slp1, slp1 + slp2];
        },
        calculateSlope(xs, ys, start, end) {
            var count = end - start;
            if (count == 2) return (ys[start + 1] - ys[start]) / (xs[start + 1] - xs[start]);

            var sumx = 0;
            var sumy = 0;
            var sumxx = 0;
            var sumxy = 0;

            for (var i = start; i < end; i++) {
                sumx += xs[i];
                sumy += ys[i];
                sumxx += xs[i] * xs[i];
                sumxy += xs[i] * ys[i];
            }

            return (count * sumxy - sumx * sumy) / (count * sumxx - sumx * sumx);
        },
        updateData() {
            this.rangeSparks = [];
            let sparkLine = [];
            let data;
            for (var i = 0; i < 8; i++) {
                let value = 0;
                var tms = [];
                var selectedData = [];
                var result = 0;
                data = JSON.parse(this.dataToParse.keyvalues[this.sideCharts[i].arrayNum].value);
                for (var j = 0; j < this.ts.length; j++) {
                    if (this.ts[j] < this.currentMinDate || this.ts[j] > this.currentMaxDate) continue;

                    if (this.userAccounts && !this.userAccounts.includes(this.invoiceAccount[j])) continue;

                    result += data[j];
                    tms.push(this.ts[j]);
                    selectedData.push(data[j]);
                }

                if (i < 6) {
                    value = result;
                } else if (i === 6) {
                    value = sparkLine[4].value == 0 ? 0 : sparkLine[0].value / sparkLine[4].value;
                } else if (i === 7) {
                    value = sparkLine[5].value == 0 ? 0 : sparkLine[0].value / sparkLine[5].value;
                }

                sparkLine[i] = this.calculateSparkLine(tms, selectedData);
                sparkLine[i].value = i != 4 && i != 5 ? value.toFixed(2) : value;
            }
            for (let i = 0; i < sparkLine.length; i++) {
                let chartDraw;
                // this gets the options values from shared function
                chartDraw = maps.drawChart({
                    series: [
                        {
                            data: sparkLine[i],
                        },
                    ],
                    lineColor: this.colors[i],
                    lineWidth: 2.5,
                    fillColor: this.colors15[i],
                    ...this.sideCharts[i],
                    enableTooltips: false,
                    disableHighlight: true,
                });
                // small line charts at the bottom of the page
                this.rangeSparks.push({
                    ...chartDraw,
                    value:
                        i < 4 || i > 5
                            ? parseFloat(sparkLine[i].value)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                            : sparkLine[i].value.toLocaleString(),
                    selected: this.sideCharts[i].selected,
                    dataType: this.sideCharts[i].dataType,
                });
            }
        },
        async loadDataValues(values) {
            this.dataToParse = values;
            this.invoiceAccount = JSON.parse(this.dataToParse.keyvalues[1].value);
            this.invoiceData.ids = JSON.parse(this.dataToParse.keyvalues[19].value);
            this.invoiceData.numbers = JSON.parse(this.dataToParse.keyvalues[20].value);
            let minDate = 2371873600000;
            let maxDate = 0;
            this.ts = JSON.parse(this.dataToParse.keyvalues[2].value).map((s) => {
                let t = parseInt(s.replace("/Date(", "").replace(")/"));
                if (t > maxDate) maxDate = t;

                if (t < minDate) minDate = t;
                return t;
            });

            this.currentMaxDate = maxDate == 0 ? new Date().getTime() : maxDate;
            this.currentMinDate = minDate == 2371873600000 ? new Date().getTime() : minDate;
            this.updateData();
            this.buildInvoices();
        },
        updateSelectedAccount(userAccounts, accountValues) {
            for (let i = 0; i < accountValues.length; i++) {
                accountValues[i] = parseInt(accountValues[i]);
            }
            this.userAccounts = accountValues;
            if (!this.userAccounts.length) {
                this.userAccounts = null;
            }
            if (this.dataToParse.keyvalues) {
                this.buildInvoices();
                this.updateData();
            }
        },
        updateSelectedActive(val) {
            this.selectedAccountActive = val;
        },
        showInvGenie() {
            if (this.selectedInvoice.inv_number) {
                this.showInvoiceGenie = true;
            } else {
                this.$notify.error({
                    message: "Please select an invoice.",
                    title: "Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
            }
        },
    },
    mounted() {
        this.performQuery();
    },
};
</script>
<style>
#pointSummaryHeader {
    background-color: #d9edf7;
    border-color: #bce8f1;
}
</style>
