<template>
    <div class="m-4">
        <h3 class="my-4 mx-2">NPODs</h3>
        <div class="d-flex justify-content-center">
            <mdb-select v-model="carriers" label="Select Carrier" class="flex-fill" />
            <mdb-select
                multiple
                selectAll
                v-if="companies"
                v-model="companies"
                label="Select Company"
                class="flex-fill"
                search
            />
            <mdb-btn v-else size="sm" outline="danger" disabled class="flex-fill"
                ><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading
                Companies...</mdb-btn
            >
        </div>
        <div class="row mb-2">
            <mdb-col col="8"></mdb-col>
            <mdb-col col="4">
                <mdb-btn color="info" @click.native="searchRecords" icon="search" class="float-right">Search</mdb-btn>
                <mdb-btn outline="danger" @click.native="clearFilters" icon="times" class="float-right">Clear</mdb-btn>
            </mdb-col>
        </div>
        <div v-if="packages">
            <mdb-btn color="info" @click.native="getRowInfo" v-if="selectedRow"
                >View Detail <mdb-icon icon="search"></mdb-icon
            ></mdb-btn>
            <mdb-btn color="info" @click.native="approveModal = true" v-if="selectedRow"
                >Approve <mdb-icon icon="plus-sign"></mdb-icon
            ></mdb-btn>
            <vue-good-table
                styleClass="vgt-table condensed"
                :rows="packages"
                :columns="columns"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-search="searchPackages"
                @on-selected-rows-change="selectionChanged"
                :select-options="{
                    enabled: true,
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
                :search-options="{
                    enabled: true,
                    trigger: 'enter',
                }"
                :totalRows="totalRows"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: this.limit,
                    position: 'top',
                    perPageDropdown: [10, 25, 50],
                    dropdownAllowAll: false,
                    setCurrentPage: this.page,
                }"
                :isLoading.sync="isLoading"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'pr_pld_transaction_date'">
                        {{ formatDate(props.row.pr_pld_transaction_date) }}
                    </span>
                    <span v-else-if="props.column.field === 'car_name'">
                        <span v-if="props.row.car_name === 'UPS'" class="brown-text">
                            <mdb-icon icon="cube" class="mr-2"></mdb-icon>UPS
                        </span>
                        <span v-else-if="props.row.car_name === 'FedEx'" class="purple-text">
                            <mdb-icon icon="cube" class="mr-2"></mdb-icon>FDX
                        </span>
                        <span v-else>{{ props.row.car_name }}</span>
                    </span>
                    <span
                        v-else-if="
                            props.column.field === 'pr_chg_amount' || props.column.field === 'pld_declared_value'
                        "
                    >
                        {{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
            <mdb-modal :show="approveModal" size="fluid" @close="approveModal = false">
                <mdb-modal-header
                    ><h4><mdb-icon icon="cube" class="mr-2"></mdb-icon> Approve NPOD</h4></mdb-modal-header
                >
                <mdb-modal-body>
                    <p>
                        <label for="claimNumber" class="grey-text">Claim Number</label>
                        <input type="text" id="claimNumber" class="form-control" v-model="claimNumber" />
                    </p>
                    <p>
                        <label for="refundAmount" class="grey-text"
                            >Refund Amount <em class="ml-2">Must be a positive number</em></label
                        >
                        <input type="text" id="refundAmount" class="form-control" v-model="refundAmount" />
                    </p>
                    <p>
                        <label for="paymentMethod" class="grey-text">Payment Method</label>
                        <input type="text" id="paymentMethod" class="form-control" v-model="paymentMethod" />
                    </p>
                    <p>
                        <label for="paymentDate" class="grey-text">Payment Date</label>
                        <input
                            type="text"
                            id="paymentDate"
                            class="form-control"
                            v-model="paymentDate"
                            placeholder="YYYY-MM-DD"
                        />
                    </p>
                    <p>
                        <label for="checkNumber" class="grey-text">Check Number</label>
                        <input type="text" id="checkNumber" class="form-control" v-model="checkNumber" />
                    </p>
                    <p>
                        <label for="notes" class="grey-text">Notes</label>
                        <textarea id="notes" class="form-control" v-model="notes" />
                    </p>
                </mdb-modal-body>
                <mdb-modal-footer
                    ><mdb-btn color="success" @click.native="approveNpod"
                        >Accepted <mdb-icon class="ml-2" icon="thumbs-up"></mdb-icon></mdb-btn
                ></mdb-modal-footer>
            </mdb-modal>
            <mdb-modal :show="auditModal" size="fluid" @close="updatePackageInfo(0)">
                <mdb-modal-header>
                    <h4><mdb-icon icon="cube" class="float-right muted"></mdb-icon> Package Details</h4>
                </mdb-modal-header>
                <mdb-modal-body v-if="loadingPackageInfo"
                    ><mdb-spinner color="green"></mdb-spinner> Loading data...</mdb-modal-body
                >
                <mdb-modal-body v-else>
                    <form class="form p-4" data-toggle="validator" method="post" id="manual_form" v-if="selectedRow">
                        <h4>Opportunity Information</h4>
                        <mdb-row>
                            <mdb-col col="3">
                                <strong>Company</strong><br />
                                {{ selectedRow.cmp_name }}
                            </mdb-col>
                            <mdb-col col="1">
                                <strong>ID</strong><br />
                                {{ selectedRow.cmp_id }}
                            </mdb-col>
                            <mdb-col col="2">
                                <strong>Carrier</strong><br />
                                {{ selectedRow.car_name }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Type</strong><br />
                                {{ selectedRow.ref_name }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Tracking</strong><br />
                                <a
                                    v-if="selectedRow.car_name === 'UPS'"
                                    :href="
                                        'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' +
                                        selectedRow.pr_pld_trackingno
                                    "
                                    >{{ selectedRow.pr_pld_trackingno }}
                                </a>
                                <a
                                    v-if="selectedRow.car_name === 'FedEx'"
                                    :href="
                                        'https://www.fedex.com/apps/fedextrack/?tracknumbers=' +
                                        selectedRow.pr_pld_trackingno
                                    "
                                    >{{ selectedRow.pr_pld_trackingno }}
                                </a>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row class="spacer10">
                            <mdb-col col="3">
                                <strong>Carrier Account:</strong><br />
                                {{ selectedRow.pr_acct_number }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Invoice Number:</strong><br />
                                {{ selectedRow.pr_inv_number }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Carrier User:</strong><br />
                                {{ selectedRow.credential_username }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Carrier Pass:</strong><br />
                                <span class="blurnohover">{{ selectedRow.credential_password }}</span>
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row class="spacer10">
                            <mdb-col col="3">
                                <strong>Transaction Date:</strong><br />
                                {{ selectedRow.pr_pld_transaction_date }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Delivery Date:</strong><br />
                                {{ selectedRow.pr_pld_deliver_date }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Recipient Country:</strong><br />
                                {{ selectedRow.pr_pld_recipient_country }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Charge Amount:</strong><br />
                                {{ selectedRow.pr_chg_amount }}
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col>
                                <strong>Last GSR:</strong><br />
                                {{ lastGsr }}
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <h4><mdb-icon icon="cube" class="float-right muted"></mdb-icon>Useful Info:</h4>
                        </mdb-row>
                        <mdb-row v-if="selectedRow.car_name === 'FedEx'">
                            <mdb-col>
                                <strong>FedEx Note:</strong> Invoices starting with with: <strong>4</strong> billed to
                                CC. <strong>5</strong> Paid by check. Starts with 4 and Ground Service should only
                                submit via Billing Online only to prevent invalid Invoice Number message.
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <h4><mdb-icon icon="cube" class="float-right muted"></mdb-icon>Package Information</h4>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col col="6">
                                <p><b>Ship From:</b></p>
                                <p>{{ selectedRow.package_info.pld_recipient_addr1 }}</p>
                                <p v-if="selectedRow.package_info.pld_recipient_addr2">
                                    {{ selectedRow.package_info.pld_recipient_addr2 }}
                                </p>
                                <p>
                                    {{ selectedRow.package_info.pld_recipient_city }},
                                    {{ selectedRow.package_info.pld_recipient_state }},
                                    {{ selectedRow.package_info.pld_recipient_country }}
                                    {{ selectedRow.package_info.pld_recipient_zipcode }}
                                </p>
                            </mdb-col>
                            <mdb-col col="6">
                                <p><b>Ship To:</b></p>
                                <p>{{ selectedRow.package_info.pld_shipper_addr1 }}</p>
                                <p v-if="selectedRow.package_info.pld_shipper_addr2">
                                    {{ selectedRow.package_info.pld_shipper_addr2 }}
                                </p>
                                <p>
                                    {{ selectedRow.package_info.pld_shipper_city }},
                                    {{ selectedRow.package_info.pld_shipper_state }},
                                    {{ selectedRow.package_info.pld_shipper_country }}
                                    {{ selectedRow.package_info.pld_shipper_zipcode }}
                                </p>
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <mdb-col col="6">
                                <p><b>Pieces:</b></p>
                                <p>{{ selectedRow.package_info.pld_no_packs }}</p>
                            </mdb-col>
                            <mdb-col col="6">
                                <p><b>Service:</b></p>
                                <p>{{ selectedRow.srv_name }}</p>
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <h4><mdb-icon icon="cogs" class="float-right muted"></mdb-icon> Automation Information</h4>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col col="2">
                                <strong>Status:</strong><br />
                                {{ selectedRow.pr_status }}
                            </mdb-col>
                            <mdb-col col="4">
                                <strong>Attempts:</strong><br />
                                {{ selectedRow.pr_attempts }}
                            </mdb-col>
                            <mdb-col col="12" class="spacer10">
                                <strong>Bot Message:</strong><br />
                                {{ selectedRow.pr_bot_message }}
                            </mdb-col>
                            <mdb-col col="12" class="spacer10">
                                <strong>Website Message:</strong><br />
                                {{ selectedRow.pr_website_message }}
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <div class="form-group">
                                <h4>
                                    <mdb-icon icon="edit" class="float-right muted"></mdb-icon>
                                    Auditor Notes
                                </h4>
                                <textarea
                                    cols="40"
                                    rows="5"
                                    class="form-control"
                                    name="pr_note"
                                    id="pr_note"
                                    v-model="selectedRow.pr_note"
                                ></textarea>
                            </div>
                        </mdb-row>
                    </form>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="primary" class="float-left" @click.native="updatePackageInfo(1)">
                        Save and Close <mdb-icon icon="save"></mdb-icon>
                    </mdb-btn>
                    <mdb-btn color="success" @click.native="updatePackageInfo(2)">
                        Dispute Accepted <mdb-icon icon="thumbs-up"></mdb-icon>
                    </mdb-btn>
                    <mdb-btn color="danger" @click.native="updatePackageInfo(3)">
                        Dispute Denied <mdb-icon icon="thumbs-down"></mdb-icon>
                    </mdb-btn>
                    <mdb-btn color="danger" @click.native="updatePackageInfo(4)">
                        Not a POD <mdb-icon icon="thumbs-down"></mdb-icon>
                    </mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
        <div v-else class="text-center align-content-center"><mdb-spinner></mdb-spinner>Loading packages...</div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "AdminNPODs",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            claimNumber: null,
            refundAmount: null,
            paymentMethod: null,
            paymentDate: null,
            checkNumber: null,
            notes: null,
            packages: null,
            carriers: [
                { text: "FedEx", value: "1" },
                { text: "UPS", value: "2" },
            ],
            companies: null,
            isLoading: false,
            auditModal: false,
            approveModal: false,
            selectedRow: null,
            lastGsr: moment(new Date()).subtract(22, "days").format("MMM Do YYYY"),
            columns: [
                {
                    label: "CMP ID",
                    field: "cmp_id",
                },
                {
                    label: "Company Name",
                    field: "cmp_name",
                },
                {
                    label: "Carrier",
                    field: "car_name",
                },
                {
                    label: "Account",
                    field: "pr_acct_number",
                },
                {
                    label: "Tracking NO",
                    field: "pr_pld_trackingno",
                },
                {
                    label: "Amount",
                    field: "pr_chg_amount",
                },
                {
                    label: "Declare Value",
                    field: "pld_declared_value",
                },
                {
                    label: "Transaction Date",
                    field: "pr_pld_transaction_date",
                },
            ],
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "pr_chg_amount",
            sortDir: "desc",
            sortkey: "pr_chg_amount",
            searchTerm: null,
            totalRows: null,
            loadingPackageInfo: false,
        };
    },
    computed: {
        selectedCarrier() {
            return this.calculateSelected(this.carriers);
        },
        selectedCompany() {
            return this.calculateSelected(this.companies);
        },
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
    },
    methods: {
        async approveNpod() {
            const data = {
                npr_pr_id: this.selectedRow.pr_id,
                npr_claim_number: this.claimNumber,
                npr_refund_amount: this.refundAmount,
                npr_payment_method: this.paymentMethod,
                npr_payment_date: this.paymentDate + "T00:00:00",
                npr_check_number: this.checkNumber,
                npr_note: this.notes,
            };
            try {
                const approved = await this.$httpAdmin.post("npod", data, { headers: this.headers });
                if (approved) {
                    this.$notify.success({ message: "Successfully Approved" });
                } else {
                    this.$notify.error({ message: "OOPS!  There was a problem, please contact the technical team." });
                }
                this.approveModal = false;
                this.claimNumber = null;
                this.refundAmount = null;
                this.paymentMethod = null;
                this.paymentDate = null;
                this.checkNumber = null;
            } catch (e) {
                this.approveModal = false;
                this.$notify.error({ message: "OOPS!  There was a problem, please contact the technical team." });
            }
        },
        async updatePackageInfo(type) {
            this.auditModal = false;
            let prNote = this.selectedRow.pr_note;
            let prStatus = 482;

            if (type === 0) {
                prNote = null;
            }
            if (type === 2) prStatus = 21937;

            if (type === 3) prStatus = 25948;

            if (type === 4) prStatus = 74582;

            const data = {
                pr_note: prNote,
                pr_status: prStatus,
            };
            try {
                await this.$httpAdmin.put(`npod/${this.selectedRow.pr_id}`, data, {
                    headers: this.headers,
                });
                this.$notify.success({ message: "Successfully updated." });
            } catch (e) {
                this.$notify.error({ message: `There was a problem processing your request: ${e}` });
            }
        },
        calculateSelected(array) {
            const records = [];
            array.map((c) => {
                if (c.selected) {
                    records.push(c.value);
                }
            });
            return records.length ? records : null;
        },
        selectionChanged(row) {
            this.selectedRow = row.selectedRows[0];
            this.selectedRow.package_info = {};
        },
        clearFilters() {
            if (this.companies) {
                this.companies = this.companies.map((s) => {
                    s.selected = false;
                    return s;
                });
            }
            if (this.carriers) {
                this.carriers = this.carriers.map((s) => {
                    s.selected = false;
                    return s;
                });
            }
        },
        async searchRecords() {
            const data = {
                cmp_ids: this.selectedCompany ? this.selectedCompany : null,
                car_id: this.selectedCarrier ? this.selectedCarrier : null,
            };
            this.queryParams = data;
            this.getPackages();
        },
        async getRowInfo() {
            this.auditModal = true;
            const info = await this.$httpAdmin.get(`packagereconciliated/${this.selectedRow.pr_id}`, {
                headers: this.headers,
            });
            if (info && info.data) {
                this.loadingPackageInfo = true;
                this.selectedRow = {
                    ...this.selectedRow,
                    ...info.data,
                };
                console.log(this.selectedRow);
                const packageInfo = await this.$httpAdmin.get(
                    `packagecommitted/${this.selectedRow.pr_pld_id}?cmpId=${this.selectedRow.cmp_id}`,
                    { headers: this.headers }
                );
                this.loadingPackageInfo = false;
                this.selectedRow.package_info = packageInfo.data;
            } else {
                this.$notify.error({
                    message: "It looks like someone else is working on this package! Please select another package.",
                });
            }
        },
        formatDate(date) {
            return moment(date).format("MMM DD, YYYY");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.getPackages();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.getPackages();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.getPackages();
        },
        async searchPackages(params) {
            this.isLoading = true;
            const found = await this.$httpAdmin.get(`npod?${this.params}&search_keyword=${params.searchTerm}`, {
                headers: this.headers,
            });
            this.isLoading = false;
            if (found) {
                this.packages = found.data[0].Items;
                this.totalRows = found.data[0].TotalRecords;
            }
        },
        async getPackages() {
            this.isLoading = true;
            let params = "";
            try {
                if (this.queryParams) {
                    if (this.selectedCarrier) {
                        params = `${params}&car_id=${this.selectedCarrier}`;
                    }
                    if (this.selectedCompany) {
                        for (const ref of this.selectedCompany) {
                            params = `${params}&cmp_ids[]=${ref}`;
                        }
                        params = `${params}&cmp_ids[]=${this.selectedCompany}`;
                    }
                }
                const errors = await this.$httpAdmin.get(`npod?${this.params}${params}`, {
                    headers: this.headers,
                });
                this.packages = errors.data[0].Items;
                this.totalRows = errors.data[0].TotalRecords;
                this.queryParams = null;
                this.clearFilters();
            } catch (e) {
                this.$notify.error({ message: `Error retrieving records ${e}` });
            }
            this.isLoading = false;
        },
        async getCompanies() {
            const companies = await this.$httpAdmin.get(`company/light`, {
                headers: this.headers,
            });
            if (companies) {
                this.companies = companies.data.map((c) => {
                    return {
                        text: `${c.cmp_name} (ID: ${c.cmp_id} Type: ${c.cmp_type}`,
                        value: c.cmp_id,
                    };
                });
            }
        },
    },
    mounted() {
        this.getPackages();
        this.getCompanies();
    },
};
</script>
