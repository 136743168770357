<template>
    <div data-test="page" class="m-4">
        <h3 data-test="pageTitle" class="my-4 mx-2">
            Package Genie+ <em class="small">Admin Package Analyzer</em>
            <mdb-icon icon="magic" class="float-right" color="gray" />
        </h3>
        <div class="d-flex justify-content-center">
            <PackageGenie data-test="PackageGenie" :userInfo="userInfo" :headers="headers"></PackageGenie>
        </div>
    </div>
</template>
<script>
import PackageGenie from "@/components/PackageGenie";
import { mdbIcon } from "mdbvue";
export default {
    name: "AdminPackageGenie",
    components: { PackageGenie, mdbIcon },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
