<template>
    <div class="w-100">
        <h1 class="page-header border-bottom mx-2">
            Reseller Dashboard
            <mdb-icon icon="tachometer-alt" size="1x" class="float-right text-muted" role="button" />
        </h1>
        <mdb-row class="w-100 my-4">
            <mdb-col col="12">
                <mdb-row class="flex no-gutters">
                    <mdb-card xs="12" col="auto" class="mr-2 flex-fill">
                        <mdb-card-header color="green lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="green-text">
                                    <mdb-icon icon="user" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="green-text">
                                    <p>Users</p>
                                    <p class="smaller green-text">Manage Users</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/manageusers">
                                Access
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card xs="12" col="auto" class="mr-2 flex-fill">
                        <mdb-card-header color="green lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="green-text">
                                    <mdb-icon icon="dollar-sign" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="green-text">
                                    <p>Adjustments</p>
                                    <p class="smaller green-text">Adjust Customer Invoices</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/adjustments">
                                Access
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span>
                            </router-link>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card xs="12" col="auto" class="flex-fill">
                        <mdb-card-header color="green lighten-4" tag="h3">
                            <mdb-row class="no-gutters">
                                <mdb-col col="6" class="font-weight-bold green-text">
                                    <mdb-icon icon="file" size="3x" />
                                </mdb-col>
                                <mdb-col col="6" class="green-text">
                                    <p class="text-nowrap">Invoices</p>
                                    <p class="smaller green-text">Customer Invoices</p>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-header>
                        <mdb-card-body>
                            <router-link to="/admin/resellerinvoices">
                                Access
                                <span class="float-right"><mdb-icon icon="arrow-circle-right" /></span> </router-link
                            >
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card xs="12" col="auto" class="ml-2 flex-fill d-flex flex-column justify-content-center">
                        <div class="p-3 border-bottom">
                            <router-link to="/admin/onboardingadmin">
                                Onboarding
                                <mdb-badge pill color="grey">{{ onboarding }}</mdb-badge>
                            </router-link>
                        </div>
                        <div class="py-4 px-3">
                            <router-link to="/admin/failedcredentials">
                                Failed Credentials
                                <mdb-badge pill color="grey">{{ failedCredentials }}</mdb-badge>
                            </router-link>
                        </div>
                        <div class="p-3 border-top">
                            <router-link to="/admin/faileddownloads">
                                Failed Downloads
                                <mdb-badge pill color="grey">{{ failedDownloads }}</mdb-badge>
                            </router-link>
                        </div>
                    </mdb-card>
                </mdb-row>
            </mdb-col>
        </mdb-row>
    </div>
</template>
<script>
export default {
    name: "ResellerDashboard",
    components: {},
    props: {
        userInfo: Object,
        headers: Object,
    },
    data() {
        return {
            onboarding: null,
            failedDownloads: null,
            failedCredentials: null,
        };
    },
    methods: {
        async getStats() {
            this.onboarding = (
                await this.$httpAdmin.get("Administration/onboard-customers", { headers: this.headers })
            ).data;
            this.failedDownloads = (
                await this.$httpAdmin.get("Administration/failed-downloads", { headers: this.headers })
            ).data;
            this.failedCredentials = (
                await this.$httpAdmin.get("Administration/failed-credentials", { headers: this.headers })
            ).data;
        },
    },
    mounted() {
        this.getStats();
    },
};
</script>
