<template>
    <mdb-dropdown
        data-test="dropdown"
        id="dropdown-form2"
        text="Select Accounts"
        class="border rounded w-100 h-auto"
        multiLevel
    >
        <mdb-dropdown-toggle
            data-test="dropdownToggle"
            :color="selectedAccountActive ? 'info' : 'white'"
            slot="toggle"
            class="w-100"
            >{{ selectedAccountActive ? "Accounts Selected" : "Select Accounts" }}</mdb-dropdown-toggle
        >
        <mdb-dropdown-menu data-test="dropdownMenu" class="myReportsScroll w-100">
            <form data-test="form" v-if="accountsList" class="w-100 mb-4">
                <div data-test="inputGroupSearch" class="input-group md-form form-sm form-2 pl-0">
                    <input
                        data-test="filterSearchInput"
                        class="form-control border rounded"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        v-model="dropdownFilter"
                    />
                    <div class="input-group-append">
                        <span
                            data-test="resetFilterSearch"
                            class="input-group-text red lighten-3"
                            @click="dropdownFilter = ''"
                            style="cursor: pointer"
                        >
                            <mdb-icon icon="times" />
                        </span>
                    </div>
                </div>
                <div data-test="dropdownFilter" v-if="dropdownFilter.length">
                    <div
                        data-test="filteredList"
                        v-for="(item, index) of filteredList"
                        v-bind:key="index"
                        class="m-0 p-0"
                    >
                        <div class="w-100" v-if="showList">
                            <div class="custom-control custom-checkbox">
                                <input
                                    data-test="filteredListInput"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :checked="item.selected"
                                    :id="'checkbox ' + index"
                                    @click="updateByFilters(item)"
                                />
                                <label
                                    data-test="filteredListLabel"
                                    class="custom-control-label"
                                    :for="'checkbox ' + index"
                                    >{{ item.label }}</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div
                        data-test="accountsList"
                        v-for="(account, index) of accountsList"
                        v-bind:key="index"
                        class="mt-2 p-0"
                    >
                        <div class="w-full" v-if="showList">
                            <div class="custom-control custom-checkbox">
                                <input
                                    data-test="accountsListInput"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :checked="account.selected"
                                    :id="'checkbox ' + account.label"
                                    @click="updateFirstTier(account)"
                                />
                                <label
                                    data-test="accountsListLabel"
                                    class="custom-control-label"
                                    :for="'checkbox ' + account.label"
                                    >{{ account.label }}</label
                                >
                                <mdb-icon
                                    data-test="accountsListInputCaret"
                                    v-if="showCaret"
                                    icon="caret-down"
                                    @click.native.stop="account.visible = !account.visible"
                                    group
                                    slot="append"
                                    class="w-10 ml-2"
                                />
                            </div>
                            <div id="collapse-accounts" v-if="account.visible && dropdownFilter == ''">
                                <div
                                    data-test="accountsListChildren"
                                    class="cursor-pointer p-2 ml-4"
                                    v-for="child in account.children"
                                    v-bind:key="child.id"
                                >
                                    <div class="w-full">
                                        <div class="custom-control custom-checkbox w-100">
                                            <input
                                                data-test="accountsListChildrenInput"
                                                type="checkbox"
                                                class="custom-control-input"
                                                :checked="child.selected"
                                                :id="'childCheckbox-' + child.label"
                                                @click="updateSecondTier(child)"
                                            />
                                            <label
                                                data-test="accountsListChildrenLabel"
                                                class="custom-control-label"
                                                :for="'childCheckbox-' + child.label"
                                                >{{ child.label }}</label
                                            >
                                            <mdb-icon
                                                data-test="accountsListChildrenCaret"
                                                v-if="child.children"
                                                icon="caret-down"
                                                @click.native="childVisibility(child)"
                                                class="ml-2"
                                            />
                                        </div>
                                        <div
                                            id="collapse-accounts2"
                                            v-if="showChildren && child.visible && dropdownFilter == ''"
                                        >
                                            <div
                                                data-test="accountsListChildrensChildren"
                                                class="cursor-pointer w-full p-2 ml-4"
                                                v-for="child2 in child.children"
                                                v-bind:key="child2.label"
                                            >
                                                <div class="w-full">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            data-test="accountsListChildrensChildrenInput"
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            :checked="child2.selected"
                                                            :id="'child2Checkbox-' + child2.label"
                                                            @click="updateThirdTier(child2)"
                                                        />
                                                        <label
                                                            data-test="accountsListChildrensChildrenLabel"
                                                            class="custom-control-label"
                                                            :for="'child2Checkbox-' + child2.label"
                                                            >{{ child2.label }}</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mdb-dropdown-menu>
    </mdb-dropdown>
</template>
<script>
import { mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbIcon } from "mdbvue";
export default {
    name: "AccountDropdown",
    components: {
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbIcon,
    },
    data() {
        return {
            sAccount: null,
            visible: false,
            visible2: false,
            selectedAccountActive: false,
            userAccounts: null,
            accountValues: [],
            showChildren: false,
            dropdownFilter: "",
            accountsList: null,
            searchList: null,
            showList: false,
            showCaret: true,
            filterSelected: [],
            filteredList: [],
            selectedLabels: [],
        };
    },
    props: {
        headers: Object,
        prepopulate: Boolean,
        userId: Number,
        cmpId: Number,
        selectedAccounts: Array,
    },
    methods: {
        updateFirstTier(account) {
            account.selected = !account.selected;
            this.showList = false;
            if (account.children) {
                for (let a of account.children) {
                    a.selected = account.selected ? true : false;
                    if (a.children) {
                        for (let children of a.children) {
                            children.selected = account.selected ? true : false;
                        }
                    }
                }
            }
            this.checkParentSelected();
            this.createFiltersList();
            this.showList = true;
        },
        updateSecondTier(child) {
            child.selected = !child.selected;
            this.showList = false;
            if (child.children) {
                for (let children of child.children) {
                    children.selected = child.selected ? true : false;
                }
            }
            this.checkParentSelected();
            this.createFiltersList();
            this.showList = true;
        },
        updateThirdTier(child2) {
            child2.selected = !child2.selected;
            this.showList = false;
            this.checkParentSelected();
            this.createFiltersList();
            this.showList = true;
        },
        checkParentSelected() {
            this.accountValues = [];
            this.selectedAccountActive = false;
            for (let account of this.accountsList) {
                if (account.children) {
                    for (let children of account.children) {
                        let allChildrenSelected = true;
                        if (children.children) {
                            for (let child of children.children) {
                                if (!child.selected) {
                                    allChildrenSelected = false;
                                    children.selected = false;
                                } else {
                                    this.selectedAccountActive = true;
                                    if (child.id) {
                                        this.accountValues.push(child.id);
                                    }
                                }
                            }
                        } else {
                            allChildrenSelected = false;
                        }
                        if (allChildrenSelected) {
                            children.selected = true;
                            this.selectedAccountActive = true;
                        }
                    }
                }
            }
            for (let account of this.accountsList) {
                let allChildrenSelected = true;
                if (account.children) {
                    for (let children of account.children) {
                        if (!children.selected) {
                            allChildrenSelected = false;
                            account.selected = false;
                        } else {
                            this.selectedAccountActive = true;
                            if (children.id) {
                                this.accountValues.push(children.id);
                            }
                        }
                    }
                } else {
                    allChildrenSelected = false;
                }
                if (allChildrenSelected) {
                    this.selectedAccountActive = true;
                    account.selected = true;
                    if (account.id) {
                        this.accountValues.push(account.id);
                    }
                }
            }
        },
        async getUserAccounts() {
            this.userAccounts = [];
            this.searchList = [];
            this.showList = false;
            await this.$http
                .post(
                    `/user`,
                    {
                        action: "getAccountTree",
                        userId: this.userId,
                        cmpId: this.cmpId,
                    },
                    { headers: this.headers }
                )
                .then(async (response) => {
                    const data = response.data.keyvalues;
                    for (const account of data) {
                        const accts = JSON.parse(account.value);
                        for (const a of accts) {
                            this.userAccounts.push({
                                ...a,
                                selected: false,
                                visible: false,
                            });
                        }
                    }
                    if (this.prepopulate) {
                        this.selectedAccountActive = true;
                        for (let account of this.userAccounts) {
                            account.selected = true;
                            if (account.children) {
                                for (let children of account.children) {
                                    children.selected = true;
                                    if (children.children) {
                                        for (let child of children.children) {
                                            child.selected = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (this.selectedAccounts) {
                        this.userAccounts.map((c) => {
                            for (let children of c.children) {
                                if (this.selectedAccounts.includes(children.id)) {
                                    children.selected = true;
                                }
                                if (children.children) {
                                    for (let child of children.children) {
                                        if (this.selectedAccounts.includes(child.id)) {
                                            child.selected = true;
                                        }
                                    }
                                }
                            }
                            return c;
                        });
                    }
                    this.accountsList = this.userAccounts;
                    this.createFiltersList();
                    this.checkParentSelected();
                    this.$emit("update-account-selected", this.userAccounts, this.accountValues);
                });
            this.showChildren = true;
            this.showList = true;
        },
        childVisibility(child) {
            this.showChildren = false;
            child.visible = !child.visible;
            this.showChildren = true;
        },
        createFiltersList() {
            this.searchList = [];
            for (let account of this.accountsList) {
                this.searchList.push({
                    label: account.label,
                    id: account.id,
                    selected: account.selected ? true : false,
                });
                if (account.children) {
                    for (let children of account.children) {
                        this.searchList.push({
                            label: children.label,
                            id: children.id,
                            selected: children.selected ? true : false,
                        });
                        if (children.children) {
                            for (let child of children.children) {
                                this.searchList.push({
                                    label: child.label,
                                    id: child.id,
                                    selected: child.selected ? true : false,
                                });
                            }
                        }
                    }
                }
            }
        },
        filterSearch() {
            this.showList = false;
            this.filteredList = [];
            if (this.dropdownFilter != "") {
                for (let item of this.searchList) {
                    if (item.label.includes(this.dropdownFilter)) {
                        this.filteredList.push(item);
                    }
                }
                this.showCaret = false;
            } else {
                this.accountsList = this.userAccounts;
                this.showCaret = true;
            }
            this.showList = true;
        },
        updateByFilters(filter) {
            filter.selected = !filter.selected;
            for (let account of this.accountsList) {
                if (account.label == filter.label) {
                    account.selected = filter.selected;
                } else {
                    if (account.children) {
                        for (let children of account.children) {
                            if (children.label == filter.label) {
                                children.selected = filter.selected;
                            } else {
                                if (children.children) {
                                    for (let child of children.children) {
                                        if (child.label == filter.label) {
                                            child.selected = filter.selected;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.checkParentSelected();
        },
    },
    mounted() {
        this.getUserAccounts(this.prepopulate);
    },
    watch: {
        accountValues() {
            this.$emit("update-account-selected", this.userAccounts, this.accountValues);
        },
        selectedAccountActive() {
            this.$emit("update-selected-active", this.selectedAccountActive);
        },
        cmpId() {
            this.getUserAccounts();
        },
        dropdownFilter() {
            this.filterSearch();
        },
    },
};
</script>
<style>
#dropdown-form2 .input-group-append {
    width: 10px !important;
}
#dropdown-form2 div {
    width: 100%;
}
</style>
