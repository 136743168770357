<template>
    <div data-test="page" class="overflow-auto h-90">
        <div data-test="pageHeader">
            <h1 class="page-header border-bottom mx-2">
                Reseller Commission
                <mdb-icon icon="trophy" size="1x" class="float-right text-muted" role="button" />
            </h1>
        </div>
        <mdb-row class="mt-4 mx-2">
            <mdb-col col="3">
                <select
                    data-test="selectedResellerSelect"
                    class="browser-default custom-select"
                    v-model="selectedReseller"
                    @change="createTable('update')"
                >
                    <option data-test="selectResellerOption" value="" selected disabled>Select Reseller</option>
                    <option data-test="allResellersOption" value="all resellers">All Resellers</option>
                    <option
                        data-test="resellerListOption"
                        v-for="(reseller, index) of resellerList"
                        v-bind:key="index"
                        :value="reseller"
                    >
                        {{ reseller.name }}
                    </option>
                </select>
            </mdb-col>
            <mdb-col col="3">
                <select
                    data-test="selectedMonthSelect"
                    class="browser-default custom-select"
                    v-model="selectedMonth"
                    @change="createTable('update')"
                >
                    <option data-test="selectPaymentMonthOption" value="" selected disabled>
                        Select Payment Month
                    </option>
                    <option data-test="allMonthsOption" value="all months">All Months</option>
                    <option
                        data-test="monthsOptionsOption"
                        v-for="(month, index) of monthsOptions"
                        v-bind:key="index"
                        :value="month"
                    >
                        {{ month.text }}
                    </option>
                </select>
            </mdb-col>
            <mdb-col col="2">
                <mdb-btn
                    data-test="exportCSVBtn"
                    color="primary"
                    size="sm"
                    title="export as csv"
                    @click.native="exportCSV"
                    ><mdb-icon icon="download"></mdb-icon
                ></mdb-btn>
            </mdb-col>
        </mdb-row>
        <mdb-row class="mt-4 mx-2">
            <mdb-col col="12">
                <vue-good-table
                    data-test="vue-good-table"
                    :columns="tableColumns"
                    :rows="tableRows"
                    :search-options="{ enabled: true, trigger: 'enter' }"
                    styleClass="vgt-table striped bordered"
                    @on-row-click="onRowClick"
                    mode="remote"
                    :isLoading.sync="isLoading"
                    :sort-options="{ enabled: true }"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    @on-search="searchTable"
                    :totalRows="totalRecords"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: this.limit,
                        position: 'top',
                        perPageDropdown: [10, 25, 50, 100],
                        dropdownAllowAll: false,
                        setCurrentPage: this.page,
                    }"
                >
                    <template slot="loadingContent"> <mdb-spinner></mdb-spinner> Loading data... </template>
                    <template slot="table-row" slot-scope="props">
                        <span
                            v-if="
                                props.column.field === 'ri_total' ||
                                props.column.field === 'payment' ||
                                props.column.field === 'eaudit_share' ||
                                props.column.field === 'ri_cmp_last_six_month_spent'
                            "
                        >
                            {{ formatPrice(props.formattedRow[props.column.field], userInfo) }}
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                </vue-good-table>
            </mdb-col>
        </mdb-row>
        <mdb-modal data-test="rowModal" :show="rowModal" @close="rowModal = false" v-if="selectedRow">
            <mdb-modal-body>
                <mdb-btn data-test="downloadInvoiceBtn" color="primary" @click.native="downloadInvoice"
                    >Download Invoice <mdb-icon icon="download" class="ml-2"></mdb-icon
                ></mdb-btn>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn data-test="rowModalCloseBtn" outline="primary" @click.native="rowModal = false">Close</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        <mdb-modal data-test="downloadModal" :show="downloadModal" @close="downloadModal = false" v-if="selectedRow">
            <mdb-modal-header>
                <mdb-modal-title data-test="downloadModalTitle"
                    ><mdb-icon icon="file-pdf" class="mr-2"></mdb-icon>Invoice Ready</mdb-modal-title
                >
            </mdb-modal-header>
            <mdb-modal-body data-test="downloadModalBody" v-if="invoiceDownloaded">
                <mdb-btn
                    data-test="downloadInvoiceBtn"
                    color="primary"
                    tag="a"
                    :href="'/download.php?file=' + invoiceToDownload"
                    download
                    ><mdb-icon icon="download" class="mr-2"></mdb-icon>Download Invoice
                    {{ selectedRow.ri_number }}</mdb-btn
                >
            </mdb-modal-body>
            <mdb-modal-body data-test="downloadModalBodyElse" v-else>
                <p>Sorry! We could not find your invoice file! please go to My Reports and run the "Invoice" report.</p>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn data-test="downloadModalCloseBtn" outline="primary" @click.native="downloadModal = false"
                    >Close</mdb-btn
                >
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>
<script>
import moment from "moment";
import Papa from "papaparse";
import {
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbModalFooter,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
} from "mdbvue";

export default {
    name: "AdminResellerCommission",
    components: {
        mdbIcon,
        mdbRow,
        mdbCol,
        mdbBtn,
        mdbSpinner,
        mdbModalFooter,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            isLoading: false,
            active: 0,
            table: [],
            tableRows: null,
            tableColumns: null,
            rowModal: false,
            selectedRow: null,
            downloadModal: false,
            resellers: null,
            resellerList: null,
            selectedReseller: "",
            monthsOptions: [],
            selectedMonth: "",
            filteredResellers: null,
            previouslyNotBilledModal: false,
            previouslyNotBilledData: null,
            previouslyNotBilledDataNPODS: null,
            invoiceToDownload: null,
            invoiceDownloaded: false,
            limit: 25,
            size: 25,
            page: 1,
            totalRecords: 0,
            sortColumn: "reseller_name",
            sortkey: "reseller_name",
            sortDir: "asc",
            searchTerm: null,
        };
    },
    methods: {
        async getInit() {
            this.tableCreated = await this.createTable("create");
            this.resellers = await this.getResellers();
            this.months = await this.getMonths();
        },
        async createTable(action) {
            this.table = {};
            this.tableRows = {};
            this.tableColumns = {};
            this.isLoading = true;
            await this.$httpAdmin
                .get("/commission/payments", {
                    headers: this.headers,
                    params: this.tableParams(action),
                })
                .then(async (response) => {
                    this.table = response.data[0].Items;
                    this.totalRecords = response.data[0].TotalRecords;
                });
            for (let i = 0; i < this.table.length; i++) {
                if (!this.table[i].ri_total) {
                    this.table[i].ri_total = 0;
                }
                if (this.table[i].ri_cmp_wholesale_type) {
                    this.table[i].ri_cmp_wholesale_type.toLowerCase();
                    if (this.table[i].ri_cmp_wholesale_type == "gainshare") {
                        this.table[i].ri_cmp_wholesale_type = "Gain Share";
                    } else if (this.table[i].ri_cmp_wholesale_type == "flatfee") {
                        this.table[i].ri_cmp_wholesale_type = "Flat Fee";
                    } else if (this.table[i].ri_cmp_wholesale_type == "transactional") {
                        this.table[i].ri_cmp_wholesale_type = "Transactional";
                    } else {
                        this.table[i].ri_cmp_wholesale_type = "";
                    }
                }
                if (!this.table[i].ri_cmp_last_six_month_spent) {
                    this.table[i].ri_cmp_last_six_month_spent = 0;
                }
                if (!this.table[i].payment) {
                    this.table[i].payment = 0;
                }
                if (!this.table[i].rate) {
                    this.table[i].rate = 0;
                } else {
                    this.table[i].rate = parseFloat(this.table[i].rate).toFixed(2);
                }
                if (!this.table[i].eaudit_share) {
                    this.table[i].eaudit_share = 0;
                }
                this.table[i].ri_start_date = new moment(this.table[i].ri_start_date).format("MMMM");
                this.table[i].payment_date = new moment(this.table[i].payment_date).format("MMMM");
            }
            this.tableRows = this.table;
            this.tableColumns = [
                { field: "reseller_name", label: "Reseller", sortable: true },
                { field: "cmp_name", label: "Company", sortable: true },
                { field: "ri_number", label: "Invoice", sortable: true },
                { field: "ri_start_date", label: "Billed Month", sortable: true },
                { field: "payment_date", label: "Payment Month", sortable: true },
                { field: "ri_total", label: "Total Invoiced", sortable: true, sortFn: this.sortFn() },
                { field: "ri_cmp_wholesale_type", label: "Commission Type", sortable: true },
                { field: "ri_cmp_wholesale_value", label: "Commission Value", sortable: true },
                {
                    field: "ri_cmp_last_six_month_spent",
                    label: "6 Months Spent",
                    sortable: true,
                    sortFn: this.sortFn(),
                },
                { field: "payment", label: "Received Payment", sortable: true },
                { field: "rate", label: "Effective Rate", sortable: true },
                { field: "eaudit_share", label: "eAudit Share", sortable: true, sortFn: this.sortFn() },
            ];
            this.isLoading = false;
        },
        tableParams(page, action) {
            return {
                ri_start_date: action == "update" ? (this.selectedMonth ? this.selectedMonth.value : "") : null,
                reseller_id: action == "update" ? (this.selectedReseller ? this.selectedReseller.id : 0) : null,
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
                ri_number: this.searchTerm ? this.searchTerm : null,
            };
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.createTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.createTable();
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.createTable();
        },
        async searchTable(params) {
            this.searchTerm = params.searchTerm;
            await this.createTable();
        },
        async getResellers() {
            await this.$httpAdmin
                .get("/reseller", {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.resellerList = response.data;
                });
        },
        getMonths() {
            let monthsItems = [];
            let startDate = new moment();
            let eAduditStartMonth = new moment([2015, 8, 1]);
            while (startDate.isAfter(eAduditStartMonth)) {
                monthsItems.push({
                    text: startDate.format("YYYY MMMM"),
                    value: startDate.format("YYYY-MM") + "-01",
                });
                startDate.add(-1, "M");
            }
            this.monthsOptions = monthsItems;
        },
        sortFn(x, y, col) {
            return x < y ? -1 : x > y ? 1 : 0;
        },
        onRowClick(params) {
            params.selected = true;
            this.selectedRow = params.row;
            this.rowModal = true;
        },
        async downloadInvoice() {
            this.rowModal = false;
            let id = this.selectedRow.ri_number;
            let parts = id.split("-");
            let d = {
                ria_number: id,
                ria_cmp_id: parseInt(parts[1]),
            };
            await this.$http
                .get("/resellerinvoice/file/" + d.ria_number + "?cmpId=" + d.ria_cmp_id, {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.invoiceToDownload = response.data;
                    if (this.invoiceToDownload && this.invoiceToDownload != "not found") {
                        this.invoiceDownloaded = true;
                    } else {
                        this.invoiceDownloaded = false;
                    }
                });
            this.downloadModal = true;
        },
        async exportCSV() {
            await this.$httpAdmin
                .get("/commission/payments", {
                    headers: this.headers,
                    params: this.csvParams(),
                })
                .then(async (response) => {
                    let pageData = response.data;
                    let blob = new Blob([Papa.unparse(pageData)], { type: "text/csv;charset=utf-8;" });

                    let link = document.createElement("a");

                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", "Export.csv");
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        },
        csvParams() {
            return {
                ri_start_date: this.selectedMonth ? this.selectedMonth.value : "",
                reseller_id: this.selectedReseller ? this.selectedReseller.id : 0,
                limit: 0,
                size: 0,
                page: 1,
                sortColumn: "reseller_name",
                sortkey: "reseller_name",
                sortDir: "asc",
            };
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
