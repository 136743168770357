<template>
    <div data-test="page" class="w-100">
        <h4 data-test="image" class="w-100"><img src="img/TI-Suite.png" class="w-50 mx-auto d-block p-2" /></h4>
        <div data-test="passwordUpdate" v-if="enterPass">
            <div
                data-test="passwordUpdateRequest"
                id="newPass"
                class="pt-4 w-50 ml-auto mr-auto"
                v-if="!submittedRequest"
            >
                <h4 data-test="passwordUpdateHeader" class="text-center">Password Update</h4>
                <div class="form-group mt-4">
                    <input
                        data-test="passwordUpdateInput_password1"
                        class="form-control"
                        placeholder="New Password"
                        name="password"
                        type="password"
                        v-model="password1"
                        required
                        autofocus
                    />
                </div>
                <div class="form-group mt-4">
                    <input
                        data-test="passwordUpdateInput_password2"
                        class="form-control"
                        placeholder="Verify Password"
                        name="password2"
                        type="password"
                        v-model="password2"
                        required
                        autofocus
                    />
                </div>
                <div>
                    <p class="text-danger" id="reqNumChars">Must contain at least 8 characters</p>
                    <p class="text-danger" id="reqLower">Must contain at least 1 lowercase letter</p>
                    <p class="text-danger" id="reqUpper">Must contain at least 1 uppercase letter</p>
                    <p class="text-danger" id="reqNumber">Must contain at least 1 number</p>
                    <p class="text-danger" id="reqSymbol">Must contain at least 1 symbol</p>
                </div>
                <div
                    data-test="passwordUpdateMatch"
                    class="text-danger"
                    v-if="password2.length > 4 && password1 !== password2"
                >
                    ** Passwords do not match
                </div>
                <div
                    data-test="passwordUpdateButton"
                    class="footer text-center"
                    v-if="password2.length && password1.length && password1 === password2"
                >
                    <mdb-btn
                        data-test="passwordUpdateSpinner"
                        class="btn btn-success btn-lg disabled"
                        value="Login"
                        v-if="submitting"
                    >
                        <mdb-spinner variant="black" label="Submitting Request" class="mr-2"></mdb-spinner>
                        Submitting change...
                    </mdb-btn>

                    <mdb-btn
                        data-test="passwordUpdate_submitNewPasswordBtn"
                        color="success"
                        v-if="!submitting"
                        class="btn btn-success btn-lg"
                        value="submitRequest"
                        @click.native="submitNewPassword"
                    >
                        Submit
                    </mdb-btn>
                    <h5 data-test="passwordUpdateError" class="text-danger text-center mt-4" v-if="error">
                        ** {{ error }}
                    </h5>
                </div>
                <div class="footer text-center" v-else>
                    <mdb-btn
                        data-test="passwordUpdateButtonDisabled"
                        color="success"
                        class="btn btn-success btn-lg"
                        value="submitRequest"
                        disabled
                    >
                        Submit
                    </mdb-btn>
                </div>
            </div>
        </div>
        <div data-test="loadingTokenSpinner" v-if="loadingToken">
            <h3 class="text-center"><mdb-spinner /> Loading...</h3>
        </div>
        <div data-test="passwordReset" v-if="!enterPass && !loadingToken">
            <div
                data-test="passwordResetForm"
                id="loginform"
                class="pt-4 w-50 ml-auto mr-auto"
                v-if="!submittedRequest"
            >
                <h4 data-test="passwordResetHeader" class="text-center">Password Reset</h4>
                <p data-test="passwordResetEmailNote" class="text-center">{{ message }}</p>
                <div class="form-group mt-4">
                    <input
                        data-test="passwordResetInput_email"
                        class="form-control"
                        placeholder="Email Address"
                        name="email"
                        type="text"
                        v-model="email"
                        required
                        autofocus
                    />
                </div>
                <div class="footer text-center">
                    <mdb-btn
                        data-test="passwordResetButtonSpinner"
                        class="btn btn-success btn-lg disabled"
                        value="Login"
                        v-if="submitting"
                    >
                        <mdb-spinner variant="black" label="Submitting Request" class="mr-2"></mdb-spinner>
                        Submitting Request
                    </mdb-btn>

                    <mdb-btn
                        data-test="passwordReset_sendInstructionBtn"
                        color="success"
                        v-if="!submitting"
                        class="btn btn-success btn-lg"
                        value="submitRequest"
                        @click.native="sendInstructions"
                    >
                        Send Instructions
                    </mdb-btn>
                    <h5 data-test="passwordResetError" class="text-danger text-center mt-4" v-if="error">
                        ** {{ error }}
                    </h5>
                </div>
            </div>
            <div id="confirmation" class="pt-4 w-50 ml-auto mr-auto" v-if="submittedRequest">
                <h4 data-test="passwordResetConfirmation" class="text-center">Email sent!</h4>
                <p data-test="passwordResetConfirmationNote" class="text-center">
                    Please follow the instructions in the email to login.
                </p>
                <div class="text-center mt-4"><mdb-icon icon="paper-plane" size="3x" /></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mdbBtn, mdbSpinner, mdbIcon } from "mdbvue";
export default {
    name: "ForgotPassword",
    components: { mdbBtn, mdbSpinner, mdbIcon },
    data: () => {
        return {
            email: "",
            submitting: false,
            error: null,
            submittedRequest: false,
            enterPass: false,
            password1: "",
            password2: "",
            loadingToken: false,
            message: "Please enter your email address.",
        };
    },
    props: {
        token: String,
        newuser: Boolean,
    },
    methods: {
        async sendInstructions() {
            this.submitting = true;
            this.error = null;
            let url = "/passwordrecovery";
            let returned = 2;
            try {
                returned = await this.$http.post(
                    url,
                    { email: this.email },
                    {
                        headers: {
                            // eslint-disable-next-line no-undef
                            Authorization: `Basic ${btoa(process.env.VUE_APP_API_KEY)}`,
                        },
                    }
                );
            } catch (e) {
                this.error = "There was a problem with the server. Please try later!";
            }
            this.submitting = false;
            if (returned.data === 1) {
                this.error = "Email was not found in our records.";
            } else if (returned.data === 0) {
                this.error = "There was a problem with the server. Please try later!";
            } else {
                this.submittedRequest = true;
            }
        },
        async submitNewPassword() {
            let returned;
            try {
                const url = `/passwordrecovery?token=${this.token}`;
                returned = await this.$http.put(
                    url,
                    { password: this.password1 },
                    {
                        headers: {
                            // eslint-disable-next-line no-undef
                            Authorization: `Basic ${btoa(process.env.VUE_APP_API_KEY)}`,
                        },
                    }
                );
                if (returned.data === 3) {
                    this.$notify.success({
                        message: "Password was successfully changed. Re-directing you to the login page..."
                    });
                    setTimeout(() => {
                        this.$router.push("/");
                    }, 8000);
                } else if (returned.data === 2) {
                    this.error = "Your request has expired. Please go to reset password page.";
                } else {
                    this.error = "Your request is not valid. Please go to reset password page.";
                }
            } catch (e) {
                this.error = "There was a problem with the server. Please try later!";
            }
        },
        async checkPass(token) {
            const url = `/passwordrecovery?token=${token}`;
            return await this.$http.get(url, {
                headers: {
                    // eslint-disable-next-line no-undef
                    Authorization: `Basic ${btoa(process.env.VUE_APP_API_KEY)}`,
                },
            });
        },
    },
    async created() {
        if (this.token) {
            this.loadingToken = true;
            const validated = await this.checkPass(this.token);
            this.loadingToken = false;
            if (validated.data === 3) this.enterPass = true;
            else if (validated.data === 1)
                this.$notify.error({
                    message: "Your token has expired. Please request a new password.",
                    timeOut: 15000,
                    position: "top center",
                });
        } else if (this.newuser) {
            localStorage.clear();
            this.message = "It looks like you're trying to sign in for the first time. Please go through our password reset process to confirm your email and reset your temporary password. "
                + this.message;
        }
    },
};
</script>
<style>
.w-50 {
    max-width: 50% !important;
}
</style>
