<template>
    <div class="w-full overflow-auto">
        <h1 class="page-header">Billing</h1>
        <div class="row">
            <div class="col-md-8 col-sm-12">
                <mdb-tabs
                    data-test="mdb-tabs"
                    :active="0"
                    default
                    :links="[{ text: 'Invoices', slot: 'custom-slot' }]"
                    :transition-duration="0.5"
                    transition-style="linear"
                >
                    <template :slot="'custom-slot'">
                        <mdb-card v-if="!invoices" class="text-center" data-test="mdb-card">
                            <mdb-icon spin icon="spinner" size="4x" class="mb-4 text-info" />
                            <h5>Loading Invoices</h5>
                        </mdb-card>
                        <mdb-accordion data-test="mdb-accordion">
                            <mdb-accordion-pane
                                type="table"
                                v-for="(invoice, index) in invoices"
                                @pane-clicked="handlePaneOpened(index)"
                                :isOpen="openPaneNum == index"
                                v-bind:key="index"
                            >
                                <template slot="title">
                                    <div class="row">
                                        <div class="col-2">
                                            {{ invoice.ri_number }}
                                        </div>
                                        <div class="col-2 text-muted text-sm">
                                            {{ getMonth(invoice.ri_end_date) }}
                                        </div>
                                        <div
                                            class="col-2 text-muted text-sm"
                                            v-if="invoice.ri_bill_type === 'gainshare'"
                                        >
                                            Recovered: ${{ Math.abs(invoice.ri_audit_amount).toFixed(2) }}
                                        </div>
                                        <div
                                            class="col-2 text-muted text-sm"
                                            v-if="invoice.ri_bill_type !== 'gainshare'"
                                        >
                                            Packages: {{ invoice.ri_no_pkgs }}
                                        </div>
                                        <div class="col-2 text-muted text-sm">
                                            Add-ons: ${{ Math.abs(invoice.addons).toFixed(2) }}
                                        </div>
                                        <div class="col-2 text-muted text-sm">
                                            Adjustments: ${{ Math.abs(invoice.ri_adjustments).toFixed(2) }}
                                        </div>
                                        <div class="col-2 font-weight-bold">
                                            Due: ${{ Math.abs(invoice.due).toFixed(2) }}
                                        </div>
                                    </div>
                                </template>
                                <mdb-card-body>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-12">
                                                    <mdb-btn
                                                        data-test="downloadButton"
                                                        variant="primary"
                                                        size="sm"
                                                        class="float-left inline-block mr-2"
                                                        @click.native="download(invoice)"
                                                        v-if="!invoice.loadingDownload"
                                                    >
                                                        <mdb-icon icon="download" />
                                                        Download Invoice
                                                    </mdb-btn>
                                                    <mdb-btn
                                                        v-else
                                                        disabled
                                                        color="info"
                                                        data-test="loadingDownloadButton"
                                                        ><mdb-spinner size="sm"></mdb-spinner>Downloading...</mdb-btn
                                                    >
                                                    <router-link
                                                        :to="
                                                            '/audit?startDate=' +
                                                            formatDate(invoice.ri_start_date) +
                                                            '&endDate=' +
                                                            formatDate(invoice.ri_end_date)
                                                        "
                                                    >
                                                        <mdb-btn
                                                            data-test="recoveriesButton"
                                                            class="float-left inline-block"
                                                            variant="info"
                                                            size="sm"
                                                        >
                                                            View Recoveries
                                                            <mdb-icon icon="arrow-circle-right"
                                                        /></mdb-btn>
                                                    </router-link>
                                                </div>

                                                <div class="col-12 mt-4">
                                                    <h5>Payment History</h5>
                                                    <vue-good-table
                                                        data-test="paymentHistoryTable"
                                                        hover
                                                        :columns="paymentColumns"
                                                        :rows="invoice.payments"
                                                        show-empty
                                                    >
                                                    </vue-good-table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="text-center">
                                                <p>
                                                    <b>Payment Due:</b>
                                                    {{ formatDate(invoice.ri_due_date) }}
                                                </p>
                                                <p>
                                                    <b>Packages:</b>
                                                    {{ invoice.ri_no_pkgs }}
                                                </p>
                                            </div>

                                            <vue-good-table
                                                data-test="detailsTable"
                                                striped
                                                hover
                                                responsive
                                                :columns="detailsColumns"
                                                :rows="invoice.rips"
                                                foot-clone
                                            >
                                                <template slot="table-row" slot-scope="props">
                                                    <span v-if="props.column.field === 'rips_cps_name'">
                                                        <span class="cursor-pointer">
                                                            <u>{{ fullName[props.row.rips_cps_name] }}</u>
                                                        </span>
                                                    </span>
                                                    <span v-if="props.column.field === 'rips_usage'">
                                                        <span v-if="props.row.rips_cps_billing_type === 'flatfee'">
                                                            {{ props.row.rips_usage.toFixed() }}
                                                        </span>
                                                        <span v-if="props.row.rips_cps_billing_type === 'gainshare'">
                                                            ${{ props.row.rips_usage.toFixed(2) }}
                                                        </span>
                                                    </span>
                                                    <span v-if="props.column.field === 'rips_cps_amount'">
                                                        <span v-if="props.row.rips_cps_billing_type === 'flatfee'">
                                                            ${{ props.row.rips_cps_amount.toFixed(2) }}
                                                        </span>
                                                        <span v-if="props.row.rips_cps_billing_type === 'gainshare'">
                                                            {{ props.row.rips_cps_amount.toFixed() }}%
                                                        </span>
                                                    </span>
                                                    <span v-if="props.column.field === 'rips_total'">
                                                        ${{ props.row.rips_total.toFixed(2) }}
                                                    </span>
                                                </template>
                                                <template v-slot:foot(rips_cps_name)>
                                                    <b>Total</b>
                                                </template>
                                                <template v-slot:foot(rips_usage)> <div></div></template>
                                                <template v-slot:foot(rips_cps_amount)> <div></div></template>
                                                <template v-slot:foot(rips_total)>
                                                    ${{ invoice.totalInvoice.toFixed(2) }}</template
                                                >
                                            </vue-good-table>
                                        </div>
                                    </div>
                                </mdb-card-body>
                            </mdb-accordion-pane>
                        </mdb-accordion>
                    </template>
                </mdb-tabs>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Billing Info <mdb-icon icon="money-check" class="float-right text-muted" />
                    </div>
                    <div class="panel-body p-2" v-if="companyInfo" data-test="billingInfo">
                        <div class="w-full p-2">
                            Amount Due: <b v-if="totalDue">${{ totalDue.toFixed(2) }}</b
                            ><b v-if="!totalDue"><mdb-spinner class="text-info ml-2" size="2x" /></b>
                        </div>
                        <hr />
                        <div class="w-full p-2">
                            <p>Remit Payments To:</p>
                            <p>
                                <b>{{ companyInfo.name }}</b>
                            </p>
                            <p>{{ companyInfo.addr1 }}</p>
                            <p>{{ companyInfo.addr2 }}</p>
                            <p>{{ companyInfo.city }}, {{ companyInfo.state }} {{ companyInfo.zip }}</p>
                        </div>
                        <hr />
                        <div class="w-full p-2">
                            <p>Billing Contacts:</p>
                            <p><mdb-icon icon="phone" class="mr-2" /> {{ companyInfo.phone }}</p>
                            <p>
                                <mdb-icon icon="envelope" class="mr-2" />
                                <a :href="'mailto:' + companyInfo.billing_email">{{ companyInfo.billing_email }}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mdb-modal :show="notFoundModal" @close="notFoundModal = false">
            <mdb-modal-header>
                <mdb-modal-title>Invoice Ready</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <p>Sorry! We could not find your invoice file! please go to My Reports and run the "Invoice" report.</p>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="info" @click.native="notFoundModal = false">Close</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>
<script>
import moment from "moment";
import PaymentModal from "@/components/PaymentModal";
import {
    mdbAccordionPane,
    mdbCardBody,
    mdbBtn,
    mdbIcon,
    mdbTabs,
    mdbAccordion,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbSpinner,
    mdbCard,
} from "mdbvue";
import { VueGoodTable } from "vue-good-table";
import VueRouter from "vue-router";
export default {
    name: "Billing",
    components: {
        PaymentModal,
        VueRouter,
        mdbAccordionPane,
        mdbCardBody,
        mdbBtn,
        mdbIcon,
        mdbTabs,
        mdbAccordion,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbSpinner,
        mdbCard,
    },
    data() {
        return {
            invoices: null,
            openPaneNum: 0,
            detailsColumns: [
                {
                    label: "Service",
                    field: "rips_cps_name",
                    formatFn: (d) => {
                        return this.fullName[d];
                    },
                },
                { label: "Billable/QTY", field: "rips_usage" },
                {
                    label: "Unit Price",
                    field: "rips_cps_amount",
                    formatFn: (data) => {
                        return data.rips_cps_billing_type === "flatfee" ? `$${data.toFixed(2)}` : `$${data}`;
                    },
                },
                {
                    label: "Total",
                    field: "rips_total",
                    formatFn: (data) => {
                        return `$${data.toFixed(2)}`;
                    },
                },
            ],
            fullName: {
                advreporting: "Advance Reporting",
                audit: "Audit",
                bulktrack: "Bulk Track",
                contractcompliance: "Contract Compliance",
                glcode: "GL Coding",
                optimizationplatform: "Optimization Platform",
                ratecalculator: "Rate Calculator",
            },
            paymentColumns: [
                {
                    label: "Received",
                    field: "rip_received_date",
                    formatFn: (date) => {
                        return this.formatDate(date);
                    },
                },
                {
                    label: "Amount",
                    field: "rip_amount",
                    formatFn: (data) => {
                        return `$${data.toFixed(2)}`;
                    },
                },
            ],
            totalDue: 0,
            companyInfo: null,
            selectedInvoice: null,
            showPaymentModal: false,
            showPaymentButton: false,
            notFoundModal: false,
        };
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        options: Object,
        headers: Object,
        downloadUniversal: Function,
    },
    methods: {
        closeShowPaymentModal() {
            this.showPaymentModal = false;
        },
        handlePaneOpened(num) {
            if (this.openPaneNum == num) {
                return (this.openPaneNum = null);
            }
            this.openPaneNum = num;
        },
        async getInvoices() {
            const invoices = await this.$http.get(`resellerinvoice?ri_cmp_id=${this.userInfo.cmpID}`, {
                headers: this.headers,
            });
            this.invoices = invoices
                ? invoices.data.map((i) => {
                      i.showDetails = false;
                      let totalInvoice = 0;
                      let total = 0;
                      let due = 0;
                      let addonsTotal = 0;
                      i.rips.forEach((rip) => {
                          if (rip.rips_cps_name === "audit") {
                              if (rip.rips_cps_billing_type === "gainshare") {
                                  total =
                                      i.ri_audit_amount +
                                      i.ri_one_month_adj +
                                      i.ri_two_month_adj +
                                      i.ri_three_month_adj;
                                  totalInvoice += parseFloat((-1 * total * i.ri_bill_value) / 100 + i.ri_adjustments);
                                  due = totalInvoice - i.total_payments;
                              } else {
                                  totalInvoice += rip.rips_total + i.ri_adjustments;
                                  due = totalInvoice - i.total_payments;
                              }
                          } else {
                              totalInvoice += rip.rips_total;
                              addonsTotal += rip.rips_total;
                          }
                          if (due < 0.1) due = 0;
                      });
                      i.totalInvoice = totalInvoice;
                      i.due = due;
                      i.addons = addonsTotal;
                      this.totalDue += due;
                      return i;
                  })
                : null;
        },
        getMonth(date) {
            return moment(date).format("MMMM YYYY");
        },
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        async download(invoice) {
            invoice.loadingDownload = true;
            const url = `/resellerinvoice/file/${invoice.ri_number}?cmpId=${this.userInfo.cmpID}`;
            const invoiceCreated = await this.$http.get(url, { headers: this.headers });
            if (invoiceCreated) {
                if (invoiceCreated.data === "not found") {
                    this.notFoundModal = true;
                } else {
                    this.downloadUniversal(invoiceCreated.data, "pdf");
                }
            }
            invoice.loadingDownload = false;
        },
    },
    async mounted() {
        this.companyInfo = (
            await this.$http.get(`company/rs/${this.userInfo.cmpID}`, {
                headers: this.headers,
            })
        ).data;
        await this.getInvoices();
        // eslint-disable-next-line no-undef
    },
};
</script>
<style>
.cursor-pointer {
    cursor: pointer;
}
.b-dropdown-form {
    padding: 0 !important;
}
/*.dropdown-menu {
    width: 100%;
    transform: translate3d(0px, 34px, 0px) !important;
}*/
.optionHover:hover {
    background-color: gray !important;
    color: white !important;
}
#dropdown-form.width-600 ul {
    min-width: 600px;
    padding: 0px !important;
}
.form-group {
    padding: 0px !important;
}
.tab-content {
    height: 100% !important;
}
</style>
