<template>
    <div class="row d-none">
        <div id="step-one" class="single-step ltr-text">
            <p>
                The Trend Analyzer allows you to see a large series of invoices over time. Let's drill into how to use
                it now.
            </p>
            <br />
            <p>Click "Next" to learn about the features of this page.</p>
        </div>
        <div id="step-two" class="single-step ltr-text">
            <p>
                Select one or more accounts to display by selecting/deselecting one or more account numbers or account
                grouping numbers (ie. CPP).
            </p>
            <br />
            <p>
                Have a lot of account numbers? No problem, use the nifty search area to find the accounts you need to
                view.
            </p>
        </div>
        <div id="step-three" class="single-step ltr-text">
            <p>
                This is where the magic happens. In this area each point is an invoice date. You can click on each
                individual invoice date (point) to see a summary of that date.
            </p>
            <br />
            <p>
                Add more metrics to the chart by selecting/deselecting them from the Range Summary below (more on that
                in a sec).
            </p>
        </div>
        <div id="step-four" class="single-step ltr-text">
            <p>
                Want to drill down to a specific date range? No problem. Simply drag the date range selector left and
                right to change the displayed range.
            </p>
        </div>
        <div id="step-five" class="single-step ltr-text">
            <p>The range summary displays key metric summaries for the date range selected above.</p>
            <p>
                <br />
                Uptrend, downtrend? Which way are you going? Easy. The charts below each metric are trends allowing you
                to easily see what trajectory each metric has across the selected date range.
            </p>
        </div>
        <div id="step-six" class="single-step ltr-text">
            <p>Check/uncheck one or more metrics to display it on the chart above.</p>
        </div>
        <div id="step-seven" class="single-step ltr-text">
            <p>
                Select any single point on the chart above and the invoice data for that invoice date is displayed here.
            </p>
            <br />
            <p>If there is more than one invoice on the selected date you will have a drop down list here.</p>
        </div>
        <div id="step-eight" class="single-step ltr-text">
            <p>
                Want to see more? No problem. Selecting the "Analyze Invoice" button fires up the Invoice Genie so you
                can see the individual invoice.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "TrendsTour",
};
</script>
