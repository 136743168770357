<template>
    <div class="m-4 glCode">
        <h3 data-test="title" class="my-4 mx-2">Apply GL Codes</h3>
        <mdb-card data-test="card" class="mt-2 p-4">
            <div class="d-flex justify-content-center align-items-center">
                <mdb-select
                    data-test="select"
                    class="flex-fill"
                    multiple
                    selectAll
                    v-if="companies"
                    v-model="companies"
                    label="Select Company"
                    search
                />
                <mdb-btn data-test="btn" v-else size="sm" outline="danger" disabled class="flex-fill"
                    ><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading
                    Companies...</mdb-btn
                >
                <DateSelector
                    data-test="DateSelector"
                    :startDate="startDate"
                    :endDate="endDate"
                    @update-end-date="updateEndDate"
                    @update-start-date="updateStartDate"
                    class="flex-fill"
                />
            </div>
            <div>
                <mdb-btn
                    data-test="applyCodesBtn"
                    class="float-right"
                    color="default"
                    icon="lock"
                    @click.native="applyCodes"
                    >Apply GL Codes</mdb-btn
                >
            </div>
        </mdb-card>
    </div>
</template>
<script>
import DateSelector from "@/components/DateSelector";
import moment from "moment";
import { mdbCard, mdbBtn, mdbSelect } from "mdbvue";

export default {
    name: "AdminApplyGLCode",
    components: {
        DateSelector,
        mdbCard,
        mdbBtn,
        mdbSelect,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            startDate: new moment(new moment().subtract(90, "days").format("YYYY-MM-DD"), "YYYY-MM-DD"),
            endDate: new moment(new moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
            sDate: this.$route.query.startDate,
            eDate: this.$route.query.endDate,
            companies: null,
        };
    },
    methods: {
        updateStartDate(val) {
            this.startDate = val;
        },
        updateEndDate(val) {
            this.endDate = val;
        },
        async getCompanies() {
            const companies = await this.$httpAdmin.get(`company`, {
                headers: this.headers,
            });
            if (companies) {
                this.companies = companies.data.map((c) => {
                    return {
                        text: `${c.cmp_name} (ID: ${c.cmp_id} Type: ${c.cmp_type})`,
                        value: c.cmp_id,
                    };
                });
            }
        },
        async applyCodes() {
            try {
                const applied = await this.$httpAdmin.post(
                    `tools/applyglcode`,
                    {
                        cmpId: this.selectedCompany,
                        start: moment(this.startDate).format("YYYY-MM-DD"),
                        end: moment(this.endDate).format("YYYY-MM-DD"),
                    },
                    { headers: this.headers }
                );
                if (applied) {
                    this.$notify.success({ message: `${applied.data} packages has been successfully updated!` });
                } else {
                    this.$notify.error({ message: applied.data });
                }
            } catch (e) {
                this.$notify.error({ message: e });
            }
        },
    },
    mounted() {
        this.getCompanies();
    },
    computed: {
        selectedCompany() {
            let records;
            this.companies.map((c) => {
                if (c.selected) {
                    records = c.value;
                }
            });
            return records ? records : null;
        },
    },
};
</script>
<style>
.glCode .dropdown-menu {
    margin-top: 50px;
}
</style>
