<template>
    <!--tours-->
    <div class="row d-none">
        <div id="step-one" class="single-step ltr-text">
            <p>Welcome to the Dashboard page tour. Let's walk through the highlights together.</p>
            <br />
            <p>Click "Next" to learn about the features of this page.</p>
        </div>
        <div id="step-two" class="single-step ltr-text">
            <p>This window shows how many claims we have submitted on your behalf.</p>
            <br />
            <p>Clicking the "View Approved" link will display more detail.</p>
        </div>
        <div id="step-three" class="single-step ltr-text">
            <p>Cha-ching! This window shows how much money we've recovered for your accounts to date.</p>
            <br />
            <p>Clicking the "View Recovered" link will display more detail.</p>
        </div>
        <div id="step-four" class="single-step ltr-text">
            <p>
                Reading reports is so 1990s. Your personal automated Optimization Advisor peels through your data and
                boils it down into actionable suggestions. The result? You save a ton of time and money.
            </p>
            <br />
            <p>Clicking the "View Opportunities" link will display more detail.</p>
        </div>
        <div id="step-five" class="single-step ltr-text">
            <p>
                You can customize and define default charts displayed when you login by visiting the "Chart Gallery" in
                the Chart section.
            </p>
            <br />
            <p>
                Just select a new chart and "pin" it to the Dashboard. Voilà! Your shiny new chart will be here to greet
                you each time you login.
            </p>
        </div>
        <div id="step-six" class="single-step ltr-text">
            <p>
                The Summary panel shows your total spend over time, total shipments and average cost per shipment. Blue
                area is plotted from invoice data we've received.
            </p>
            <br />
            <p>
                Yellow area is what you can expect in the future based on our proprietary predictive algorithms. If you
                see a blue beaker icon, we're still waiting on more carrier data to provide predictive analytics.
            </p>
            <br />
            <p>
                The graph displays the value over time (point = invoice date). Try hovering over the points on the
                graph.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "DashboardTour",
};
</script>
