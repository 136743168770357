import axios from "axios";
import config from "../config";

export default {
    async loginAs(accesskey, cmpId) {
        return await axios.post(
            `${config.authUrl}/loginas`,
            {
                accesskey: accesskey,
                cmpId
            },
            {
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Basic " + window.btoa(config.internalKey),
                },
            }
        );
    },
    async login(email, password) {
        let user = await axios.post(
            config.authUrl,
            {
                email,
                password,
            },
            {
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Basic " + window.btoa(config.internalKey),
                },
            }
        );
        if (user && user.data) {
            if (user.data.usr_level === 'super_admin') {
                user.adminpass = user.data.usr_accesskey;
            }
            return this.setSession(user);
        } else {
            this.logout();
        }
    },

    setSession(user) {
        const userInfo = {
            cmpID: user.data.usr_companies.length === 0 ? null : user.data.usr_companies[0],
            usrId: user.data.usr_id,
            usrScmpId: user.data.usr_scmp_id,
            usrCmpName: user.data.usr_cmp_name,
            usrResellerId: user.data.usr_reseller_id,
            usrResellerName: user.data.usr_reseller_name,
            usrResellerLogo: user.data.usr_reseller_logo,
            usrFirstName: user.data.usr_first_name,
            usrLastName: user.data.usr_last_name,
            usrPhone: user.data.usr_phone,
            usrTimeZone: user.data.usr_time_zone,
            usrHasAcceptedTerms: user.data.usr_has_accepted_term,
            usrEmail: user.data.usr_email,
            usrType: user.data.usr_type,
            usrPasswordSetDate: user.data.usr_password_set_date,
            usrRestrictType: user.data.usr_restrict_type,
            usrAccessKey: user.data.usr_accesskey,
            usrApiKey: user.data.usr_api_key,
            usrApiPass: user.data.usr_api_pass,
            usrLastLoginDate: user.data.usr_last_login_date,
            usrBaseCountry: user.data.usr_base_country,
            usrCompanies: user.data.usr_companies,
            usrLevel: user.data.usr_level,
            usrIsWebRegistered: user.data.usr_is_web_registered,
            usrResellerSupportEmail: user.data.usr_reseller_support_email,
            adminPass: user.adminpass || null,
            usrLoggedInAs: user.loginAs || false
            //usrLoggedInAs: user.data.usr_level === "super_admin" || user.data.usr_level === "reseller"
        };
        switch (userInfo.usrBaseCountry) {
            case "de":
                userInfo.currencySymbol = "€";
                break;
            default:
                userInfo.currencySymbol = "$";
                break;
        }
        localStorage.setItem("user", JSON.stringify(userInfo));
        localStorage.setItem("token", user.data.usr_api_pass);
        return userInfo;
    },

    logout() {
        localStorage.clear();
    },

    getToken() {
        return localStorage.getItem("token");
    },
};
