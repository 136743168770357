<template>
    <div class="row d-none">
        <div id="step-one" class="single-step ltr-text">
            <p>Welcome to the Chart Gallery page tour. Let's walk through the highlights together.</p>
            <br />
            <p>Click "Next" to learn about the features of this page.</p>
        </div>
        <div id="step-two" class="single-step ltr-text">
            <p>Overwhelmed? Narrow down the search for the perfect chart by selecting a chart category.</p>
        </div>
        <div id="step-three" class="single-step ltr-text">
            <p>Click here to see additional pages of charting goodness.</p>
        </div>
        <div id="step-four" class="single-step ltr-text">
            <p>Drumroll please... Here's where we add optimization charts.</p>
            <br />
            <p>We add charts regularly, so check often and/or suggest a new chart with a support ticket.</p>
        </div>
        <div id="step-five" class="single-step ltr-text">
            <p>Pin any chart to the main Dashboard or My View.</p>
            <br />
            <p>
                Name your chart, select accounts and a date range and your new chart is ready to start working for you.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "GalleryTour",
};
</script>
