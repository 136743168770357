var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-4"},[_vm._m(0),(_vm.extractErrors)?_c('div',[_c('vue-good-table',{attrs:{"styleClass":"vgt-table condensed","rows":_vm.extractErrors,"columns":_vm.columns,"mode":"remote","search-options":{
                enabled: true,
                trigger: 'enter',
            },"totalRows":_vm.totalRows,"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: this.limit,
                position: 'top',
                perPageDropdown: [10, 25, 50],
                dropdownAllowAll: false,
                setCurrentPage: this.page,
            },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.searchErrors,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'cmp_is_active')?_c('span',[(props.row.cmp_is_active === true)?_c('span',[_c('mdb-icon',{attrs:{"icon":"circle","color":"success"}}),_vm._v(" Active ")],1):_c('span',[_c('mdb-icon',{attrs:{"icon":"circle","color":"danger"}}),_vm._v(" Inactive ")],1)]):(props.column.field === 'cmp_type')?_c('span',[(props.row.cmp_type === 'Live')?_c('mdb-icon',{attrs:{"icon":"circle","color":"success"}}):_vm._e(),(props.row.cmp_type === 'Mock')?_c('mdb-icon',{attrs:{"icon":"clock","color":"warning"}}):_vm._e(),(props.row.cmp_type === 'Load')?_c('mdb-icon',{attrs:{"icon":"circle","color":"warning"}}):_vm._e(),(props.row.cmp_type === 'Trial')?_c('mdb-icon',{attrs:{"icon":"clock","color":"success"}}):_vm._e(),(props.row.cmp_type === 'Inactive')?_c('mdb-icon',{attrs:{"icon":"circle","color":"danger"}}):_vm._e(),(props.row.cmp_type === 'Hold')?_c('mdb-icon',{attrs:{"icon":"ban","color":"warning"}}):_vm._e(),(props.row.cmp_type === 'Suspect')?_c('mdb-icon',{attrs:{"icon":"question-circle","color":"warning"}}):_vm._e(),_vm._v(" "+_vm._s(props.row.cmp_type)+" ")],1):(props.column.field === 'sys_date')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.sys_date))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2190098598)})],1):_c('div',{staticClass:"text-center align-content-center"},[_c('mdb-spinner'),_vm._v("Loading system errors...")],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"my-4 mx-2"},[_vm._v("Invoice Errors - "),_c('em',[_vm._v("Analyze Extract Errors")])])
}]

export { render, staticRenderFns }