<template>
    <div class="w-full">
        <PageHeader
            data-test="pageHeader"
            :pageSubTitle="titleObject.pageSubTitle"
            :pageTitle="titleObject.pageTitle"
            :iconName="titleObject.iconName"
            :startTour="startTour"
        />
        <hr />
        <mdb-row v-if="isLoading">
            <mdb-col col="12" class="text-center">
                <h3><mdb-spinner data-test="spinner" size="3x" /> Loading Charts...</h3>
            </mdb-col>
        </mdb-row>
        <mdb-row data-test="mdb-row_charts" v-if="!isLoading && totalRecords > 0" class="mb-4">
            <mdb-col col="12">
                <mdb-row class="my-4">
                    <router-link data-test="router-link_gallery" to="gallery" class="ml-4"
                        ><mdb-btn color="info"
                            ><mdb-icon icon="thumbtack" class="mr-2" size="lg" />Pin Charts</mdb-btn
                        ></router-link
                    >
                    <mdb-pagination
                        data-test="pagination"
                        v-if="totalPages > 1"
                        id="pagination"
                        class="mt-3 mb-2 ml-auto mr-auto"
                        align="center"
                    >
                        <mdb-page-nav
                            data-test="mdb-page-nav_prev"
                            prev
                            :disabled="currentPage === 1"
                            class="border"
                            @click.native="currentPage--"
                        ></mdb-page-nav>
                        <mdb-page-item
                            data-test="mdb-page-item"
                            class="border"
                            v-for="i of totalPages"
                            v-bind:key="i"
                            @click.native="currentPage = i"
                            :active="currentPage === i"
                            >{{ i }}</mdb-page-item
                        >
                        <mdb-page-nav
                            data-test="mdb-page-nav_next"
                            class="border"
                            next
                            :disabled="currentPage === totalPages"
                            @click.native="currentPage++"
                        ></mdb-page-nav>
                    </mdb-pagination>
                </mdb-row>
                <mdb-row data-test="chartsRow">
                    <mdb-col
                        data-test="mdb-col_charts"
                        sm="6"
                        xs="12"
                        v-for="(chart, index) of charts"
                        v-bind:key="index"
                    >
                        <mdb-card class="m-2">
                            <mdb-card-header class="row mx-0">
                                <mdb-col col="9" class="small"
                                    ><h4 data-test="chartHeading">{{ chart.heading }}</h4></mdb-col
                                >
                                <mdb-col
                                    data-test="buttonsRight"
                                    col="3"
                                    class="btn-group text-sm-center text-right"
                                    v-if="chart.buttonsRight"
                                >
                                    <span class="text-muted smaller mr-2 mt-2"
                                        >Last {{ chart.dateTime ? chart.dateTime.replace("d", "") : "90" }} days</span
                                    >
                                    <mdb-btn-group size="sm">
                                        <mdb-popover trigger="click" :options="{ placement: 'top' }">
                                            <span slot="body">{{ chart.buttonsRight }}</span>
                                            <mdb-btn
                                                slot="reference"
                                                color="white"
                                                class="btn-xs border text-muted p-2"
                                            >
                                                <mdb-icon icon="info" size="xs" />
                                            </mdb-btn>
                                        </mdb-popover>
                                        <mdb-btn
                                            data-test="showUnpinModalBtn"
                                            @click.native="showUnpinModal(chart)"
                                            color="default"
                                            title="Unpin chart"
                                            ><mdb-icon icon="thumbtack" class="float-right"
                                        /></mdb-btn>
                                    </mdb-btn-group>
                                </mdb-col>
                            </mdb-card-header>
                            <mdb-card-body
                                ><highcharts
                                    data-test="highcharts"
                                    :constructor-type="chart.chartType"
                                    :options="chart"
                            /></mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
            </mdb-col>
        </mdb-row>
        <mdb-row v-if="!isLoading && totalRecords == 0" id="charts-area">
            <mdb-col col="6">
                <mdb-card data-test="mdb-card_noCharts" class="m-2">
                    <mdb-card-header
                        >No Pinned Charts <mdb-icon far icon="chart-bar" class="float-right" size="lg"
                    /></mdb-card-header>
                    <mdb-card-body>
                        <p class="p-2">It's looking a bit bare in here, let's customize.</p>
                        <p class="p-2">
                            Click below to select and customize frequently used charts to pin on this page.
                        </p>
                        <div class="text-center">
                            <router-link to="gallery"
                                ><mdb-btn color="info"
                                    ><mdb-icon icon="thumbtack" class="mr-2" size="lg" />Pin Charts</mdb-btn
                                ></router-link
                            >
                        </div>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <mdb-modal data-test="unpinChartModal" :show="showModal" @close="showModal = false">
            <mdb-modal-header>
                <mdb-modal-title>Unpin Chart</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body data-test="chartToUnpin" v-if="chartToUnpin"
                >Would you like to remove {{ chartToUnpin.heading }} chart from mycharts?</mdb-modal-body
            >
            <mdb-modal-footer>
                <mdb-btn color="info" @click.native="showModal = false">Cancel</mdb-btn>
                <mdb-btn data-test="unpinChartBtn" color="primary" @click.native="unpinChart">Unpin</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        <MyViewTour data-test="MyViewTour" />
    </div>
</template>
<script>
import MyViewTour from "@/components/MyViewTour";
import PageHeader from "@/components/PageHeader";
import { buttons, getTourDefaults } from "@/lib/shepherd-tour";
import maps from "@/lib/charts";
import {
    mdbBtn,
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbIcon,
    mdbTabs,
    mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbAccordion,
    mdbAccordionPane,
    mdbSpinner,
    mdbInput,
    mdbSelect,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbCardHeader,
    mdbBtnGroup,
    mdbPopover,
} from "mdbvue";

export default {
    name: "MyView",
    components: {
        MyViewTour,
        PageHeader,
        mdbBtn,
        mdbContainer,
        mdbCol,
        mdbRow,
        mdbIcon,
        mdbTabs,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbAccordion,
        mdbAccordionPane,
        mdbSpinner,
        mdbInput,
        mdbSelect,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbCardHeader,
        mdbBtnGroup,
        mdbPopover,
    },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            charts: null,
            limit: 4,
            currentPage: 1,
            sortColumn: "chart_order",
            sortDir: "asc",
            totalPages: 0,
            totalRecords: 0,
            showModal: false,
            chartToUnpin: null,
            isLoading: true,
            titleObject: {
                pageSubTitle: "Customized Dashboard",
                pageTitle: "My View",
                iconName: "chart-bar",
            },
        };
    },
    methods: {
        startTour() {
            const tour = this.$shepherd(getTourDefaults());
            const steps = [
                {
                    text: document.getElementById("step-one").innerHTML,
                    buttons: [
                        {
                            text: "Next",
                            action: tour.next,
                        },
                    ],
                    title: "My View Tour",
                },
                {
                    attachTo: { element: "#charts-area", on: "bottom" },
                    text: document.getElementById("step-two").innerHTML,
                    buttons,
                    title: "Pin Charts",
                },
            ];

            tour.addSteps(steps);

            tour.start();
        },
        async getCharts() {
            this.charts = [];
            this.isLoading = true;
            const charts = await this.$http.get(
                "/chartuser?limit=" +
                    this.limit +
                    "&page=" +
                    this.currentPage +
                    "&sortColumn=" +
                    this.sortColumn +
                    "&sortDir=" +
                    this.sortDir +
                    "&chart_user_page=mycharts",
                { headers: this.headers }
            );
            if (charts.data) {
                this.totalPages = charts.data[0].TotalPages;
                this.totalRecords = charts.data[0].TotalRecords;
                for (const user of charts.data[0].Items) {
                    let type = null;
                    if (user.chart_type === "map") {
                        type = {
                            map: "countries/us/us-all",
                        };
                    } else {
                        type = { type: user.chart_type };
                    }
                    const newChart = {
                        type: user.chart_type,
                        title: {
                            text: user.chart_title,
                        },
                        chartType: "chart",
                        chart: {
                            ...type,
                        },
                        chartButtons: user.chart_description,
                        dateTime: user.chart_param ? JSON.parse(user.chart_param).dateTime : "90",
                    };
                    const data = (await this.getChartData(user)).data;
                    let series = maps.drawChart({ ...newChart, ...data });
                    this.charts.push({
                        ...newChart,
                        ...series,
                        heading: user.chart_title,
                        chart_id: user.chart_id,
                        chart_user_id: user.chart_user_id,
                        chart_description: user.chart_description,
                    });
                }
            }
            this.isLoading = false;
        },
        async getChartData(data) {
            let params;
            if (data.chart_user_param) {
                params = JSON.parse(data.chart_user_param);
            }
            const param = {
                cmpId: this.userInfo.cmpID,
                chartName: data.chart_name,
                chartType: data.chart_type,
                ...params,
            };
            let url = "/chartdata";
            // if (data.chart_user_category == "superuser") url = "/superuser" + url;

            return await this.$http.post(url, param, { headers: this.headers });
        },
        showUnpinModal(chart) {
            this.chartToUnpin = chart;
            this.showModal = true;
        },
        unpinChart() {
            this.$http
                .delete("/chartuser/" + this.chartToUnpin.chart_user_id, { headers: this.headers })
                .then(async (response) => {
                    await this.getCharts();
                });
            this.showModal = false;
        },
    },
    mounted() {
        this.getCharts();
    },
    watch: {
        currentPage() {
            this.getCharts();
        },
    },
};
</script>
