<template>
    <mdb-modal data-test="modal" @close="closeModal" :show="showPaymentModal">
        <mdb-modal-header data-test="modalHeader">
            <mdb-modal-title data-test="modalTitle">Pay Invoice&nbsp;{{ invoice.ri_number }}</mdb-modal-title>
        </mdb-modal-header>
        <div class="p-2">
            <p class="py-1">
                <em data-test="creditCardNotice"
                    >Payments made by credit card may take up to <b>7 days</b> to reflect online.</em
                >
            </p>
            <p data-test="amount" class="py-1">
                <b>Amount: {{ invoice.due.toFixed(2) }}</b>
            </p>
            <p data-test="cardNumber" class="py-1">Card Number: <span class="text-danger">*</span></p>
            <p class="py-1">
                <input
                    data-test="cardNumberInput"
                    type="text"
                    placeholder="Card Number"
                    v-model="paymentInfo.cardNumber"
                />
            </p>
            <p data-test="expirationDate" class="py-1">Expiration Date: <span class="text-danger">*</span></p>
            <div class="row px-3 py-1">
                <div class="w-1/2">
                    <select
                        data-test="selectExpMonth"
                        class="w-full browser-default custom-select"
                        v-model="paymentInfo.exp_month"
                    >
                        <option data-test="selectExpMonth_disabledOption" value="" disabled>Expiration Month</option>
                        <option
                            data-test="selectExpMonth_options"
                            v-for="month in months"
                            :value="month"
                            v-bind:key="month"
                        >
                            {{ month }}
                        </option>
                    </select>
                </div>
                <div class="w-1/2 ml-2">
                    <select
                        data-test="selectExpYear"
                        class="w-full browser-default custom-select"
                        v-model="paymentInfo.exp_year"
                    >
                        <option data-test="selectExpYear_disabledOption" value="" disabled>Expiration Year</option>
                        <option data-test="selectExpYear_options" v-for="year in years" :value="year" v-bind:key="year">
                            {{ year }}
                        </option>
                    </select>
                </div>
            </div>
            <p data-test="ccv" class="py-1">CCV <span class="text-danger">*</span></p>
            <p class="py-1">
                <input data-test="ccvInput" type="text" placeholder="Card Code" v-model="paymentInfo.ccv" />
            </p>
            <p data-test="requiredFields" class="text-danger"><em>* Required fields</em></p>
        </div>
        <mdb-modal-footer>
            <mdb-btn
                data-test="submitPaymentBtn"
                @click.native="submitPayment()"
                color="success"
                :disabled="!submitEnabled"
                >Submit Payment</mdb-btn
            >
            <mdb-btn data-test="closeModalBtn" @click.native="closeModal" color="danger">Cancel</mdb-btn>
        </mdb-modal-footer>
    </mdb-modal>
</template>
<script>
import moment from "moment";
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalFooter, mdbBtn } from "mdbvue";
export default {
    name: "PaymentModal",
    components: { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalFooter, mdbBtn },
    data() {
        return {
            paymentInfo: {
                cardNumber: null,
                exp_month: null,
                exp_year: null,
                ccv: null,
            },
            months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
            showPaymentModal: true,
        };
    },
    props: {
        invoice: Object,
    },
    methods: {
        submitPayment() {
            const cardNumber = this.paymentInfo.cardNumber;
            const exp_month = this.paymentInfo.exp_month;
            const exp_year = this.paymentInfo.exp_year;
            const ccv = this.paymentInfo.ccv;

            if (!cardNumber || cardNumber.length < 13 || cardNumber.length > 16 || !Number(cardNumber)) {
                this.$notify.error({ message: `Credit Card Number is not valid` });
                return;
            }

            if (!exp_month || exp_month == "-1") {
                this.$notify.error({ message: `Expiration Month is not valid.` });
                return;
            }

            if (!exp_year || exp_year == "-1") {
                this.$notify.error({ message: `Expiration Year is not valid.` });
                return;
            }

            if (!ccv || ccv.length < 3 || ccv.length > 4 || !Number(ccv)) {
                this.$notify.error({ message: `Card Code (ccv) is not valid.` });
                return;
            }
            const secureData = {},
                authData = {},
                cardData = {};

            cardData.cardNumber = cardNumber;
            cardData.month = exp_month;
            cardData.year = exp_year;
            cardData.cardCode = ccv;
            secureData.cardData = cardData;

            // eslint-disable-next-line no-undef
            authData.clientKey = process.env.VUE_APP_AuthData.clientKey;
            // eslint-disable-next-line no-undef
            authData.apiLoginID = process.env.VUE_APP_AuthData.apiLoginID;
            secureData.authData = authData;

            // eslint-disable-next-line no-undef
            Accept.dispatchData(secureData, (response) => {
                if (response.messages.resultCode === "Error") {
                    var error = "";
                    for (var i = 0; i < response.messages.message.length; i++) {
                        error += response.messages.message[i].text + ";";
                        console.log(response.messages.message[i].code + ":" + response.messages.message[i].text);
                    }

                    this.$notify.error({ message: error });
                } else {
                    this.processPayOnlineResponse(response.opaqueData);
                }
            });
        },
        async processPayOnlineResponse(response) {
            const data = {
                cmpId: this.userInfo.cmpId,
                ri_number: this.invoice.ri_number,
                dataDescriptor: response.dataDescriptor,
                dataValue: response.dataValue,
            };
            const payload = await this.$http.post(`payment`, data, {
                headers: this.headers,
            });
            if (payload) {
                const d = payload.data;
                if (d === 1) this.$notify.success({ message: "Your payment was successfully processed" });
                else if (d === 2) this.$notify.error({ message: "Your credit card information is not valid" });
                else if (d === 3) this.$notify.info({ message: "This invoice has been paid" });
                else if (d === -1)
                    this.$notify.error({
                        message: "There was a problem processing your credit card. Please try again later.",
                    });
                this.paymentInfo = {
                    cardNumber: null,
                    exp_month: null,
                    exp_year: null,
                    ccv: null,
                };
                this.showPaymentModal = false;
            }
        },
        closeModal() {
            this.showPaymentModal = false;
            this.$emit("hide-payment-modal", this.showPaymentModal);
        },
    },
    computed: {
        submitEnabled: function () {
            return (
                this.paymentInfo &&
                this.paymentInfo.cardNumber &&
                this.paymentInfo.exp_month &&
                this.paymentInfo.exp_year &&
                this.paymentInfo.ccv
            );
        },
        years() {
            const year = Number(moment().year().toString().slice(-2));
            return [year, year + 1, year + 2, year + 3, year + 4, year + 5];
        },
    },
    mounted() {
        let authorizeNet = document.createElement("script");
        authorizeNet.setAttribute("src", "https://js.authorize.net/v1/Accept.js");
        document.head.appendChild(authorizeNet);
        this.paymentInfo.exp_month = "";
        this.paymentInfo.exp_year = "";
    },
};
</script>
