<template>
    <div data-test="page" class="m-4">
        <h3 data-test="pageTitle" class="my-4 mx-2">Attachment Errors</h3>
        <vue-good-table
            data-test="vue-good-table"
            styleClass="vgt-table condensed"
            :rows="records"
            :columns="columns"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            @on-search="searchErrors"
            :search-options="{
                enabled: true,
                trigger: 'enter',
            }"
            :totalRows="totalRows"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: this.limit,
                position: 'top',
                perPageDropdown: [10, 25, 50],
                dropdownAllowAll: false,
                setCurrentPage: this.page,
            }"
            :isLoading.sync="loading"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'ieae_time'">
                    {{ formatDate(props.formattedRow[props.column.field]) }}
                </span>
                <span v-else-if="props.column.field === 'ieae_from'">
                    {{ props.row.ieae_from.replace("<", "").replace(">", "") }}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "AdminAttachmentErrors",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            records: null,
            loading: false,
            columns: [
                {
                    label: "From",
                    field: "ieae_from",
                },
                {
                    label: "To",
                    field: "ieae_to",
                },
                {
                    label: "Attachment",
                    field: "ieae_attachment",
                },
                {
                    label: "Error",
                    field: "ieae_error",
                },
                {
                    label: "Receive Time",
                    field: "ieae_time",
                },
            ],
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "ieae_time",
            sortDir: "desc",
            sortkey: "ieae_time",
            searchTerm: null,
            totalRows: null,
        };
    },
    computed: {
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
    },
    methods: {
        formatDate(date) {
            return moment(date).format("MM/DD/YYYY HH:mm:ss");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.searchErrors();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.searchErrors();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.searchErrors();
        },
        async searchErrors(params) {
            this.loading = true;
            let search;
            if (params) {
                search = `&ieae_from=${params.searchTerm}`;
            }
            try {
                const recs = await this.$httpAdmin.get(`emailattachmenterror?${this.params}${search ? search : ""}`, {
                    headers: this.headers,
                });
                if (recs) {
                    this.records = recs.data[0].Items;
                    this.totalRows = recs.data[0].TotalRecords;
                    this.loading = false;
                }
            } catch (e) {
                this.$notify.error({ message: "No errors found." });
            }
            this.loading = false;
        },
    },
    mounted() {
        this.searchErrors();
    },
};
</script>
