<template>
    <mdb-container fluid style="height: 100vh">
        <div data-test="showBulkTrack" v-if="premiumServices && bulkTrackService && !isLoading" style="height: 100vh">
            <!-- ----------------------------- -->
            <!-- Custom Component - PageHeader -->
            <!-- ----------------------------- -->
            <PageHeader
                data-test="pageHeader"
                :pageSubTitle="titleObject.pageSubTitle"
                :pageTitle="titleObject.pageTitle"
                :iconName="titleObject.iconName"
            />

            <!-- ----------------- -->
            <!-- Modal - Add Group -->
            <!-- ----------------- -->
            <div>
                <mdb-modal
                    data-test="showBulkModal"
                    id="addGroup"
                    :show="showBulkModal"
                    @close="showBulkModal = false"
                    size="lg"
                >
                    <mdb-modal-header data-test="showBulkModal_header">
                        <mdb-icon icon="cubes" class="muted" /> Add New Group
                    </mdb-modal-header>
                    <mdb-modal-body data-test="showBulkModal_body" v-if="!isAdding">
                        <div class="row">
                            <div class="col-lg-12">
                                <p>
                                    The Bulk Track Tool allows you to track and monitor the status of up to 1000
                                    packages. Once a group is created, the tracking information will be updated daily
                                    for 20 days.
                                </p>
                                <div class="row spacer20">
                                    <div class="col-lg-6">
                                        <mdb-input
                                            data-test="showBulkModal_input"
                                            placeholder="Group Name"
                                            type="text"
                                            v-model="addGroupFields.groupName"
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <select
                                            data-test="showBulkModal_select"
                                            class="form-control"
                                            id="car_id"
                                            v-model="addGroupFields.carrierId"
                                        >
                                            <option value="" disabled>Select Carrier</option>
                                            <option value="1">FedEx</option>
                                            <option value="2">UPS</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>Enter one tracking number per line (Max 1000). No special characters such as , ; " '</p>
                        <textarea
                            data-test="showBulkModal_textarea"
                            class="form-control"
                            id="trackingNumbers"
                            placeholder="Tracking Numbers"
                            rows="20"
                            v-model="addGroupFields.trackingNumberList"
                        ></textarea>
                    </mdb-modal-body>
                    <mdb-modal-body v-else><mdb-spinner></mdb-spinner></mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn @click="showBulkModal = false" size="sm" color="danger">Close</mdb-btn>
                        <mdb-btn
                            data-test="showBulkModal_addGroupBtn"
                            @click.native="addGroup"
                            color="success"
                            size="sm"
                        >
                            <mdb-icon icon="plus" />
                            Add
                        </mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>
            </div>

            <!-- -------------------- -->
            <!-- Modal - Delete Group -->
            <!-- -------------------- -->
            <div v-if="deleteGroupObject">
                <mdb-modal
                    data-test="deleteGroupObject"
                    :show="deleteGroupObject.isModalVisible"
                    @close="deleteGroupObject.isModalVisible = false"
                >
                    <mdb-modal-header data-test="deleteGroupObject_header">
                        {{ deleteGroupObject.title }}
                    </mdb-modal-header>
                    <mdb-modal-body data-test="deleteGroupObject_body">
                        <div class="row">
                            <div class="col-lg-12">
                                <p>{{ deleteGroupObject.body }}</p>
                            </div>
                        </div>
                    </mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn @click="deleteGroupObject.isModalVisible = false" color="info" size="sm"
                            >Cancel</mdb-btn
                        >
                        <mdb-btn
                            data-test="deleteGroupObject_deleteTrackingGroupBtn"
                            @click.native="deleteTrackingGroup"
                            color="danger"
                            size="sm"
                            variant="success"
                        >
                            Delete
                        </mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>
            </div>

            <!-- ------------------------- -->
            <!-- Modal - Deactivate Add-on -->
            <!-- ------------------------- -->
            <div v-if="deactivateAddonObject">
                <mdb-modal
                    data-test="deactivateAddonObject"
                    :show="deactivateAddonObject.isModalVisible"
                    @close="deactivateAddonObject.isModalVisible = false"
                    id="delGroup"
                >
                    <mdb-modal-header data-test="deactivateAddonObject_header">
                        {{ deactivateAddonObject.title }}
                    </mdb-modal-header>
                    <mdb-modal-body data-test="deactivateAddonObject_body">
                        <div class="row">
                            <div class="col-lg-12">
                                <p>{{ deactivateAddonObject.body }}</p>
                                <p>
                                    <strong>Note:</strong> Add-ons are billed monthly so you will be charged for the
                                    full month.
                                </p>
                            </div>
                        </div>
                    </mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn @click="deactivateAddonObject.isModalVisible = false" class="btn btn-info" size="sm"
                            >Cancel</mdb-btn
                        >
                        <mdb-btn
                            data-test="deactivateAddonObject_deactivateServiceBtn"
                            @click.native="deactivateService"
                            size="sm"
                            color="danger"
                            v-if="!loadingDeactivate"
                        >
                            Deactivate
                        </mdb-btn>

                        <mdb-btn v-else disabled color="danger" class="ml-auto">
                            <span
                                data-test="deactivateAddonObject_spinner"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Deactivating...
                        </mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>
            </div>
            <!-- ------------------- -->
            <!-- Page Button Bar Top -->
            <!-- ------------------- -->
            <div class="bg-light my-3 p-3 rounded d-flex">
                <mdb-btn data-test="showBulkModalBtn" @click="showBulkModal = true" color="info">
                    <mdb-icon icon="plus" />
                    Add New Group
                </mdb-btn>

                <mdb-btn
                    data-test="deactivateAddonObject.isModalVisibleBtn"
                    @click="deactivateAddonObject.isModalVisible = true"
                    color="danger"
                    class="ml-auto"
                >
                    <mdb-icon icon="trash" />
                    Deactivate Add-on
                </mdb-btn>
            </div>

            <!-- ---------------- -->
            <!-- Main Body Header -->
            <!-- ---------------- -->
            <div class="row spacer20">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6">
                            <h3 data-test="mainBodyHeader">{{ mainBodyHeader }}</h3>
                        </div>
                    </div>
                </div>
            </div>

            <!-- --------------------- -->
            <!-- Tracking Groups Table -->
            <!-- --------------------- -->
            <div>
                <vue-good-table
                    data-test="vue-good-table_trackingIdData"
                    :columns="trackingGroupHeaders"
                    :rows="trackingGroupData"
                    hover
                    striped
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'btg_name'">
                            <mdb-icon icon="cubes" class="muted mr-2"></mdb-icon>
                            <span v-html="props.row.btg_name"></span>
                        </span>
                        <span v-else-if="props.column.field === 'btg_action'">
                            <div class="d-flex">
                                <mdb-btn @click.native="getPackageDetails(props.row)" class="btn btn-info btn-sm">
                                    <mdb-icon icon="cubes" class="mr-2" />View Packages
                                </mdb-btn>
                                <mdb-btn
                                    class="btn btn-primary btn-sm"
                                    color="primary"
                                    @click.native="download(props.row)"
                                >
                                    <mdb-icon icon="download" />
                                </mdb-btn>
                                <mdb-btn
                                    @click.native="confirmGroupDelete(props.row)"
                                    class="btn btn-danger btn-sm float-right"
                                    color="danger"
                                >
                                    <mdb-icon icon="trash" />
                                </mdb-btn>
                                <div class="hide">
                                    <download-csv
                                        ref="downloader"
                                        id="downloader"
                                        name="bulktrack_download.csv"
                                        :data="trackingNumbersData"
                                    >
                                    </download-csv>
                                </div>
                            </div>
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                </vue-good-table>
            </div>

            <!-- --------------- -->
            <!-- Package Details -->
            <!-- --------------- -->
            <mdb-modal
                data-test="showPackageModal"
                size="fluid"
                position="bottom"
                direction="bottom"
                :show="showPackageModal"
                @close="showPackageModal = false"
            >
                <mdb-modal-header data-test="showPackageModal_header" class="my-2 p-4 rounded">
                    <div class="btn-group action-buttons" role="group">Tracking Numbers in {{ groupName }}</div>
                </mdb-modal-header>
                <mdb-modal-body>
                    <div data-test="showPackageModal_spinner" v-if="loadingPackages">
                        <mdb-spinner></mdb-spinner> Loading Packages
                    </div>
                    <div data-test="showPackageModal_body" v-if="viewPackages">
                        <!-- ---------------------------- -->
                        <!-- Package Details Table Search -->
                        <!-- ---------------------------- -->

                        <!-- --------------------- -->
                        <!-- Package Details Table -->
                        <!-- --------------------- -->
                        <vue-good-table
                            v-if="trackingNumbersData"
                            data-test="vue-good-table_trackingNumbersData"
                            :columns="trackingNumbersHeaders"
                            :filter="keyword"
                            :rows="trackingNumbersData"
                            :search-options="{
                                enabled: true,
                                trigger: 'enter',
                            }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: this.limit,
                                position: 'top',
                                perPageDropdown: [10, 25, 50],
                                dropdownAllowAll: false,
                                setCurrentPage: this.currentPage,
                            }"
                            hover
                            responsive
                            show-empty
                            striped
                        >
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'btn_number'">
                                    <mdb-icon icon="cube" class="muted mr-2" />
                                    <span v-html="props.row.btn_number"></span>
                                </span>
                                <span v-else-if="props.column.field === 'btn_status_disp'">
                                    <mdb-icon v-if="statusStage1.isGreen" icon="circle" :title="statusStage1.status" />
                                    <mdb-icon
                                        v-if="statusStage1.isMuted"
                                        icon="circle"
                                        class="muted"
                                        :title="statusStage1.status"
                                    />
                                    <span v-if="statusStage2.isGreen">
                                        <mdb-icon v-if="statusStage2.isGreen" icon="minus" />
                                        <mdb-icon
                                            v-if="statusStage2.isGreen"
                                            icon="circle"
                                            :title="statusStage2.status"
                                        />
                                    </span>
                                    <span v-if="statusStage2.isMuted">
                                        <mdb-icon icon="minus" class="muted" />
                                        <mdb-icon icon="circle" class="muted" :title="statusStage2.status" />
                                    </span>
                                    <span v-if="statusStage3.isGreen">
                                        <mdb-icon icon="minus" />
                                        <mdb-icon icon="circle" :title="statusStage3.status" />
                                    </span>
                                    <span v-if="statusStage3.isMuted">
                                        <mdb-icon icon="minus" class="muted" />
                                        <mdb-icon icon="circle" class="muted" :title="statusStage3.status" />
                                    </span>
                                    <span v-if="statusStage4.isGreen">
                                        <mdb-icon icon="minus" style="color: green; padding: 0px; margin-left: -1px" />
                                        <mdb-icon icon="circle" style="color: green" :title="statusStage4.status" />
                                    </span>
                                    <span v-if="statusStage4.isMuted">
                                        <mdb-icon icon="minus" class="muted" style="padding: 0px; margin-left: -1px" />
                                        <mdb-icon icon="circle" class="muted" :title="statusStage4.status" />
                                    </span>
                                </span>
                                <span v-else-if="props.column.field == 'btn_last_activity_time'">
                                    {{ props.row.btn_last_activity_time.format("YYYY, MMM DD HH:mm:ss") }}
                                </span>

                                <!-- ------------------------ -->
                                <!-- Template - Action Column -->
                                <!-- ------------------------ -->
                                <span v-else-if="props.column.field === 'btn_action'">
                                    <mdb-btn @click="showAnalyzer(props.row)" class="btn btn-info btn-sm">
                                        Analyze Package<mdb-icon icon="magic" />
                                    </mdb-btn>
                                </span>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </mdb-modal-body>
            </mdb-modal>
            <mdb-modal
                data-test="showPackageGenie"
                :show="showPackageGenie"
                @close="showPackageGenie = false"
                hide-footer
                hide-header
                size="fluid"
            >
                <mdb-modal-header data-test="showPackageGenie_header">
                    <mdb-modal-title class="w-full">
                        <mdb-icon icon="magic" class="mr-3" />
                        <span>Package Genie</span>
                    </mdb-modal-title>
                </mdb-modal-header>
                <PackageGenie
                    data-test="PackageGenie"
                    :userInfo="userInfo"
                    :headers="headers"
                    :package="selectedPackage"
                />
            </mdb-modal>
        </div>
        <div data-test="descriptionCard" v-else class="w-50 m-auto">
            <mdb-card data-test="descriptionCard_show" v-if="!isLoading">
                <mdb-card-header>
                    <h3>
                        Add-on Description
                        <mdb-btn
                            data-test="descriptionCard_activateServiceBtn"
                            @click.native="activateService()"
                            icon="plus"
                            class="float-right"
                            v-if="!loadingActivate"
                        >
                            Activate Rate Calculator
                        </mdb-btn>
                        <mdb-btn icon="plus" class="float-right" v-else disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Activating Rate Calculator...
                        </mdb-btn>
                    </h3>
                </mdb-card-header>
                <mdb-card-body data-test="descriptionCard_body">
                    <p>
                        <em v-if="cost">Cost: ${{ this.cost.rps_amount }} / {{ this.cost.rps_billing_type }}</em>
                    </p>
                    <p>
                        The Rate Calculator tool estimates the time in transit and cost of delivery based on the details
                        you enter to compare all of your available carriers on one screen.
                    </p>
                </mdb-card-body>
            </mdb-card>
        </div>
    </mdb-container>
</template>

<script>
import PackageGenie from "@/components/PackageGenie";
import PageHeader from "../../components/PageHeader";
import moment from "moment";
import {
    mdbContainer,
    mdbModal,
    mdbModalHeader,
    mdbIcon,
    mdbModalBody,
    mdbInput,
    mdbModalFooter,
    mdbBtn,
    mdbModalTitle,
} from "mdbvue";

export default {
    name: "BulkTrack",
    components: {
        PageHeader,
        PackageGenie,
        mdbContainer,
        mdbModal,
        mdbModalHeader,
        mdbIcon,
        mdbModalBody,
        mdbInput,
        mdbModalFooter,
        mdbBtn,
        mdbModalTitle,
    },
    props: {
        propExample: {
            // type: Object as () => ComplexObjectInterface
        },
        userInfo: Object,
        isAuthenticated: Boolean,
        options: Object,
        headers: Object,
        services: Array,
        activateAddon: Function,
        deactivateAddon: Function,
    },
    mounted() {
        this.getAddonCost();
    },
    data() {
        return {
            isAdding: false,
            isLoading: true,
            showPackageModal: false,
            loadingPackages: false,
            showPackageGenie: false,
            showBulkModal: false,
            serviceName: "bulktrack",
            serviceDescription: "Bulk Track",
            mainBodyHeader: "Tracking Groups",
            statusStage1: { isGreen: false, isMuted: false, status: "Not tracked" },
            statusStage2: { isGreen: false, isMuted: false, status: "Not tracked" },
            statusStage3: { isGreen: false, isMuted: false, status: "Not tracked" },
            statusStage4: { isGreen: false, isMuted: false, status: "Not tracked" },
            // eslint-disable-next-line no-undef
            baseURL: process.env.VUE_APP_ROOT_API,
            compID: this.userInfo.cmpID,
            weburl: this.userInfo, //baseURL,
            deleteGroupData: "",
            addGroupFields: {
                groupName: "",
                carrierId: "",
                trackingNumberList: "",
            },
            groupName: "Enter Group Name",
            filterActivated: false,
            messageBoxObject: {
                isModalVisible: false,
                title: "Title",
                icon: "magic",
                body: "body",
            },
            deactivateAddonObject: {
                isModalVisible: false,
                title: "Deactivate Addon",
                icon: "magic",
                body: "Are you sure you would like to deactivate this addon?",
            },
            deleteGroupObject: {
                isModalVisible: false,
                title: "Title",
                icon: "cubes",
                body: "",
            },
            modalObject: {
                isModalVisible: false,
                modalHeader: "This is the NEW header",
                modalBody: "This is the NEW body",
                modalFooter: "This is the NEW footer",
            },
            titleObject: {
                pageTitle: "Bulk Track",
                pageSubTitle: "Package Status Tool",
                iconName: "cubes",
            },
            trackingGroupHeaders: [
                { field: "btg_name", label: "Group Name" },
                { field: "btg_car_id", label: "Carrier" },
                { field: "btg_create_date", label: "Create Date" },
                { field: "btg_status", label: "Status" },
                { field: "btg_manifested", label: "Manifested" },
                { field: "btg_pickedup", label: "Picked Up" },
                { field: "btg_intransit", label: "In Transit" },
                { field: "btg_delivered", label: "Delivered" },
                { field: "btg_not_tracked", label: "NOT Tracked" },
                { field: "btg_action", label: "Action", sortable: false },
            ],
            trackingGroupData: [],
            trackingNumbersHeaders: [
                { field: "btn_number", label: "Tracking Number" },
                { field: "btn_status_disp", label: "Status" },
                { field: "btn_last_status", label: "Last Activity" },
                { field: "btn_last_activity_time", label: "Last Activity Time" },
                { field: "btn_last_location", label: "Location" },
                { field: "btn_action", label: "Action" },
            ],
            trackingNumbersData: [],
            carriersInfo: [
                {
                    id: 1,
                    abbr: "FDX",
                    name: "FedEx",
                },
                {
                    id: 2,
                    abbr: "UPS",
                    name: "UPS",
                },
                {
                    id: 3,
                    abbr: "DHL Ex",
                    name: "DHL Express",
                },
                {
                    id: 4,
                    abbr: "Pur",
                    name: "Purolator",
                },
                {
                    id: 5,
                    abbr: "DHL eCom",
                    name: "DHL eCommerce",
                },
            ],
            filter: null,
            keyword: "",
            viewPackages: false,
            isBusy: false,
            currentPage: 1,
            limit: 10,
            sortColumn: "btn_number",
            sortkey: "btn_number",
            sortDir: "desc",
            searchText: "",
            totalRecords: 0,
            totalPages: 0,
            selected: [],
            selectMode: "single",
            limitOptions: [10, 25, 50, 100],
            selectedPackage: null,
            cost: null,
            // downloadData: null,
            loadingActivate: false,
            loadingDeactivate: false,
            preloaded: false,
        };
    },
    filters: {
        pretty: function (value) {
            if (typeof value !== "undefined") {
                return JSON.stringify(JSON.parse(value), null, 2);
            }
        },
    },
    computed: {
        items() {
            return this.keyword
                ? this.trackingNumbersData.filter(
                      (item) => item.btn_number.includes(this.keyword) || item.btn_last_location.includes(this.keyword)
                  )
                : this.trackingNumbersData;
        },
        premiumServices() {
            return this.services
                ? this.services.map((s) => {
                      if (s.cps_is_active === "1") return s.cps_name;
                  })
                : null;
        },
        bulkTrackService() {
            this.isLoading = true;
            const bulkTrack = this.services.find((c) => c.cps_name === "bulktrack");
            if (bulkTrack) {
                this.displayTrackingGroups();
            }
            this.isLoading = false;
            return bulkTrack;
        },
    },
    methods: {
        showAnalyzer(data) {
            this.selectedPackage = data;
            this.showPackageGenie = true;
        },
        addGroup() {
            let trackingNumbersObj = this.getTrackingNumbersToCreate(this.addGroupFields.trackingNumberList);
            let groupData = {
                cmp_id: this.compID,
                car_id: this.addGroupFields.carrierId,
                name: this.addGroupFields.groupName,
                trackingNumbers: trackingNumbersObj,
            };

            let url = "/bulktrack";
            this.isAdding = true;

            this.$http
                .post(url, groupData, {
                    headers: {
                        ...this.headers,
                        "Cmp-ID": this.userInfo.cmpID,
                        "User-ID": this.userInfo.usrId,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.isAdding = false;
                        this.showBulkModal = false;
                        const data = response.data;
                        if (data && data > 0) {
                            this.$notify.success({ message: "The tracking group has been successfully created." });
                            // $("#addNewGroup").modal('hide');
                            // location.reload(true);
                        } else if (data && data == -10) {
                            this.$notify.error({
                                message:
                                    "You already have 10 tracking groups. You should delete one of them in order to add a new one.",
                            });
                            // $("#addNewGroup").modal('hide');
                        } else if (data && data == -1000) {
                            this.$notify.error({
                                message:
                                    "There are more than 1000 tracking numbers. Please break them into groups of thousand tracking numbers.",
                            });
                            // $("#addNewGroup").modal('hide');
                        } else
                            this.$notify.error({
                                message: "There is a problem saving the tracking group. Please try again later.",
                            });
                        this.displayTrackingGroups();
                    }
                });
            this.isAdding = false;
        },
        getTrackingNumbersToCreate(trackingNumberList) {
            const trs = trackingNumberList.split("\n");
            if (!trs || trs.length == 0) {
                return null;
            }

            let numbers = [];
            for (let i = 0; i < trs.length; i++) {
                if (!trs[i]) continue;

                let number = trs[i];
                number = JSON.stringify(number).replace(/\W/g, "");
                if (!number || number == "" || number.length < 7) continue;

                numbers.push(number);
            }

            return numbers;
        },
        invalidFeedback() {},
        validFeedback() {},
        async displayTrackingGroups() {
            this.preloaded = true;
            let url = `/bulktrack?btg_cmp_id=${this.userInfo.cmpID}`;
            const response = await this.$http.get(url, {
                headers: {
                    ...this.headers,
                    "Cmp-ID": this.userInfo.cmpID,
                    "User-ID": this.userInfo.usrId,
                },
            });
            let looper = 0;
            if (response.data.length) {
                this.trackingGroupData = response.data;
                for (looper in this.trackingGroupData) {
                    let loop = response.data[looper];
                    let groupStatus = "Tracking";
                    if (new moment().diff(moment(loop.btg_create_date), "days") > 20) groupStatus = "Expired";

                    loop.btg_car_id = this.getCarrierName(loop.btg_car_id);
                    loop.btg_create_date = moment(loop.btg_create_date).format("YYYY, MMM DD");
                    loop.btg_status = groupStatus;
                    loop.btg_manifested = loop.btg_manifested == null ? 0 : loop.btg_manifested;
                    loop.btg_pickedup = loop.btg_pickedup == null ? 0 : loop.btg_pickedup;
                    loop.btg_intransit = loop.btg_intransit == null ? 0 : loop.btg_intransit;
                    loop.btg_delivered = loop.btg_delivered == null ? 0 : loop.btg_delivered;
                    loop.btg_not_tracked = loop.btg_not_tracked == null ? 0 : loop.btg_not_tracked;
                }
            } else {
                this.$notify.error({
                    message: "There is a problem retrieving tracking numbers. Please try again later.",
                });
            }
        },
        async activateService() {
            this.loadingActivate = true;
            await this.activateAddon("bulktrack");
            this.loadingActivate = false;
        },
        async deactivateService() {
            this.loadingDeactivate = true;
            await this.deactivateAddon("bulktrack");
            this.loadingDeactivate = false;
            this.showBulkModal = false;
        },
        async getPackageDetails(trackingIdData) {
            this.showPackageModal = true;
            this.loadingPackages = true;
            let createDate = trackingIdData.btg_create_date;
            let groupId = trackingIdData.btg_id;
            this.groupName = trackingIdData.btg_name;

            let looper;
            let url = `/bulktrack/${groupId}/items?btn_cmp_id=${this.userInfo.cmpID}`;

            const response = await this.$http.get(url, {
                headers: {
                    ...this.headers,
                    "Cmp-ID": this.userInfo.cmpID,
                    "User-ID": this.userInfo.usrId,
                },
            });
            this.loadingPackages = false;
            if (response.data) {
                this.trackingNumbersData = response.data;
                for (looper in this.trackingNumbersData) {
                    this.trackingNumbersData[looper].btn_last_activity_time = moment(
                        this.trackingNumbersData[looper].btn_last_activity_time
                    ).format("YYYY, MMM DD HH:mm:ss");
                    let status = response.data[looper].btn_last_status;
                    // eslint-disable-next-line no-unused-vars
                    // let dateTime = moment(response.data[looper].btn_last_activity_time).format("YYYY, MMM DD HH:mm:ss");
                    // eslint-disable-next-line no-unused-vars
                    // let location = response.data[looper].btn_last_location;
                    if (status == null || status == "") {
                        status = "NOT Tracked";
                        this.statusStage1.status = status;
                        this.statusStage2.status = status;
                        this.statusStage3.status = status;
                        this.statusStage4.status = status;
                        // dateTime = "NOT Tracked";
                        // location = "NOT Tracked";
                    }

                    if (
                        status == "MANIFESTED" ||
                        status == "PICKEDUP" ||
                        status == "INTRANSIT" ||
                        status == "DELIVERED"
                    ) {
                        this.statusStage1.isGreen = true;
                        this.statusStage1.isMuted = false;
                        this.statusStage1.status = status;
                    } else {
                        this.statusStage1.isGreen = false;
                        this.statusStage1.isMuted = true;
                        this.statusStage1.status = status;
                    }

                    if (status == "PICKEDUP" || status == "INTRANSIT" || status == "DELIVERED") {
                        this.statusStage2.isGreen = true;
                        this.statusStage2.isMuted = false;
                        this.statusStage2.status = status;
                    } else {
                        this.statusStage2.isGreen = false;
                        this.statusStage2.isMuted = true;
                        this.statusStage2.status = status;
                    }

                    if (status == "INTRANSIT" || status == "DELIVERED") {
                        this.statusStage3.isGreen = true;
                        this.statusStage3.isMuted = false;
                        this.statusStage3.status = status;
                    } else {
                        this.statusStage3.isGreen = false;
                        this.statusStage3.isMuted = true;
                        this.statusStage3.status = status;
                    }

                    if (status == "DELIVERED") {
                        this.statusStage4.isGreen = true;
                        this.statusStage4.isMuted = false;
                        this.statusStage4.status = status;
                    } else {
                        this.statusStage4.isGreen = false;
                        this.statusStage4.isMuted = true;
                        this.statusStage4.status = status;
                    }

                    this.trackingNumbersData[looper]["btn_status_disp"] = this.status_disp;
                    this.trackingNumbersData[looper]["btn_last_status"] = status;

                    if (new moment().diff(moment(createDate), "days") > 20)
                        this.trackingNumbersData[looper]["btn_last_status"] = "Expired";
                }
                this.viewPackages = true;
            }
        },
        confirmGroupDelete(data) {
            this.deleteGroupData = data;
            let groupName = this.deleteGroupData.btg_name;
            this.deleteGroupObject.title = "Group - " + groupName;
            this.deleteGroupObject.body = "Are you sure you want to delete the '" + groupName + "' group?";
            this.deleteGroupObject.isModalVisible = true;
        },
        async deleteTrackingGroup() {
            let groupId = this.deleteGroupData.btg_id;
            let url = "/bulktrack/" + groupId;

            // eslint-disable-next-line no-unused-vars
            const response = await this.$http
                .delete(url, {
                    headers: {
                        ...this.headers,
                        "Cmp-ID": this.userInfo.cmpID,
                        "User-ID": this.userInfo.usrId,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.deleteGroupObject.isModalVisible = false;
                        this.trackingGroupData = response.data;
                        this.messageBoxObject.title = "Success: Tracking Group Deletion";
                        this.messageBoxObject.body = "The tracking group has been successfully deleted.";
                        this.messageBoxObject.isModalVisible = true;
                        this.displayTrackingGroups();
                    } else {
                        this.messageBoxObject.title = "Error: Tracking Group Deletion";
                        this.messageBoxObject.body =
                            "There is a problem deleting the tracking group. Please try again later.";
                        this.messageBoxObject.isModalVisible = true;
                        return;
                    }
                })
                .catch(function (error) {
                    alert("ERROR: " + error);
                });
        },
        async download(trackingIdData) {
            let groupId = trackingIdData.btg_id;
            this.groupName = trackingIdData.btg_name;

            let url = this.baseURL + "/bulktrack/" + groupId + "/items?btn_cmp_id=" + this.userInfo.cmpID;

            // const response = await this.$http.get(url, {
            //     headers: this.headers,
            // });
            // await this.$http.get(url, {
            //     headers: this.headers,
            // })
            await this.$http
                .get(url, {
                    headers: {
                        ...this.headers,
                        "Cmp-ID": this.userInfo.cmpID,
                        "User-ID": this.userInfo.usrId,
                    },
                    params: {
                        inv_cmp_id: this.userInfo.cmpID,
                        limit: 0,
                        size: 0,
                        page: 1,
                        sortColumn: this.sortColumn,
                        sortkey: this.sortkey,
                        sortDir: this.sortDir,
                    },
                })
                .then(async (response) => {
                    this.trackingNumbersData = response.data;
                    const el = document.getElementById("downloader");
                    setTimeout(() => {
                        el.click();
                        this.trackingNumbersData = null;
                    }, 200);
                });
        },
        onRowSelected(params) {
            this.selected = params.selectedRows[0];
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getCarrierName(carId) {
            if (!carId) return "";
            for (let i = 0; i < this.carriersInfo.length; i++)
                if (this.carriersInfo[i].id == carId) return this.carriersInfo[i].name;

            return "";
        },
        async getAddonCost() {
            const cost = await this.$http.get("resellerpremiumservice/bulktrack", { headers: this.headers });
            this.cost = cost.data;
        },
        // createTrackingCSV: function(data) {
        //   if (!data || data.length == 0) return "";

        //   var csv = '"Tracking Number","Status","Location","DateTime"';
        //   csv += "\r\n";
        //   $.each(data, function(i, item) {
        //     csv +=
        //       '"' +
        //       item.btn_number.toUpperCase() +
        //       '","' +
        //       item.btn_last_status +
        //       '","' +
        //       item.btn_last_location +
        //       '","' +
        //       moment(item.btn_last_activity_time).format("YYYY, MMM DD HH:mm:ss") +
        //       '"';
        //     csv += "\r\n";
        //   });

        //   return csv;
        // }
    },
};
</script>
