<template>
    <div class="m-4">
        <h3 class="my-4 mx-2">Manual Reconcile</h3>
        <div class="d-flex justify-content-center">
            <mdb-select multiple selectAll v-model="refundTypes" label="Select Refund Type" class="flex-fill" />
            <mdb-select v-model="carriers" label="Select Carrier" class="flex-fill" />
            <mdb-select
                multiple
                selectAll
                v-if="companies"
                v-model="companies"
                label="Select Company"
                class="flex-fill"
                search
            />
            <mdb-btn v-else size="sm" outline="danger" disabled class="flex-fill"
                ><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading
                Companies...</mdb-btn
            >
            <mdb-select v-model="deliverDates" label="Select Deliver Date" class="flex-fill" />
            <mdb-select multiple selectAll v-model="botMsgs" label="Select Bot Message" class="flex-fill" />
        </div>
        <div class="row mb-2">
            <mdb-col col="8"></mdb-col>
            <mdb-col col="4">
                <mdb-btn color="info" @click.native="searchRecords" icon="search" class="float-right">Search</mdb-btn>
                <mdb-btn outline="danger" @click.native="clearFilters" icon="times" class="float-right">Clear</mdb-btn>
            </mdb-col>
        </div>
        <div v-if="packageReconciliated">
            <mdb-btn color="info" @click.native="getRowInfo" v-if="selectedRow"
                >Reconcile <mdb-icon icon="search"></mdb-icon
            ></mdb-btn>
            <vue-good-table
                styleClass="vgt-table condensed"
                :rows="filteredReconciliations"
                :columns="columns"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-search="searchPackages"
                @on-selected-rows-change="selectionChanged"
                :select-options="{
                    enabled: true,
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
                :search-options="{
                    enabled: true,
                    trigger: 'enter',
                }"
                :totalRows="totalRows"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: this.limit,
                    position: 'top',
                    perPageDropdown: [10, 25, 50],
                    dropdownAllowAll: false,
                    setCurrentPage: this.page,
                }"
                :isLoading.sync="isLoading"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'pr_pld_deliver_date'">
                        {{ formatDate(props.row.pr_pld_deliver_date) }}
                    </span>
                    <span v-else-if="props.column.field === 'car_name'">
                        <span v-if="props.row.car_name === 'UPS'" class="brown-text">
                            <mdb-icon icon="cube" class="mr-2"></mdb-icon>UPS
                        </span>
                        <span v-else-if="props.row.car_name === 'FedEx'" class="purple-text">
                            <mdb-icon icon="cube" class="mr-2"></mdb-icon>FDX
                        </span>
                        <span v-else>{{ props.row.car_name }}</span>
                    </span>
                    <span v-else-if="props.column.field === 'pr_bot_message'">
                        <span v-html="props.row.pr_bot_message"></span>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
            <mdb-modal :show="auditModal" size="fluid" @close="updatePackageInfo(0)">
                <mdb-modal-header>
                    <h4><mdb-icon icon="cube" class="float-right muted"></mdb-icon> Manual Audit Worksheet</h4>
                </mdb-modal-header>
                <mdb-modal-body v-if="loadingPackageInfo"
                    ><mdb-spinner color="green"></mdb-spinner> Loading data...</mdb-modal-body
                >
                <mdb-modal-body v-else>
                    <form
                        class="form p-4"
                        data-toggle="validator"
                        method="post"
                        id="manual_form"
                        v-if="selectedRow && selectedRow.package_info"
                    >
                        <h4>Opportunity Information</h4>
                        <mdb-row>
                            <mdb-col col="3">
                                <strong>Company</strong><br />
                                {{ selectedRow.cmp_name }}
                            </mdb-col>
                            <mdb-col col="1">
                                <strong>ID</strong><br />
                                {{ selectedRow.cmp_id }}
                            </mdb-col>
                            <mdb-col col="2">
                                <strong>Carrier</strong><br />
                                {{ selectedRow.car_name }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Type</strong><br />
                                {{ selectedRow.ref_name }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Tracking</strong><br />
                                <a
                                    v-if="selectedRow.car_name === 'UPS'"
                                    :href="
                                        'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' +
                                        selectedRow.pr_pld_trackingno
                                    "
                                    >{{ selectedRow.pr_pld_trackingno }}
                                </a>
                                <a
                                    v-if="selectedRow.car_name === 'FedEx'"
                                    :href="
                                        'https://www.fedex.com/apps/fedextrack/?tracknumbers=' +
                                        selectedRow.pr_pld_trackingno
                                    "
                                    >{{ selectedRow.pr_pld_trackingno }}
                                </a>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row class="spacer10">
                            <mdb-col col="3">
                                <strong>Carrier Account:</strong><br />
                                {{ selectedRow.pr_acct_number }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Invoice Number:</strong><br />
                                {{ selectedRow.pr_inv_number }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Carrier User:</strong><br />
                                {{ selectedRow.credential_username }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Carrier Pass:</strong><br />
                                <span class="blurnohover">{{ selectedRow.credential_password }}</span>
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row class="spacer10">
                            <mdb-col col="3">
                                <strong>Transaction Date:</strong><br />
                                {{ selectedRow.pr_pld_transaction_date }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Delivery Date:</strong><br />
                                {{ selectedRow.pr_pld_deliver_date }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Recipient Country:</strong><br />
                                {{ selectedRow.pr_pld_recipient_country }}
                            </mdb-col>
                            <mdb-col col="3">
                                <strong>Charge Amount:</strong><br />
                                {{ selectedRow.pr_chg_amount }}
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col>
                                <strong>Last GSR:</strong><br />
                                {{ lastGsr }}
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <h4><mdb-icon icon="cube" class="float-right muted"></mdb-icon>Useful Info:</h4>
                        </mdb-row>
                        <mdb-row v-if="selectedRow.car_name === 'FedEx'">
                            <mdb-col>
                                <strong>FedEx Note:</strong> Invoices starting with with: <strong>4</strong> billed to
                                CC. <strong>5</strong> Paid by check. Starts with 4 and Ground Service should only
                                submit via Billing Online only to prevent invalid Invoice Number message.
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <h4><mdb-icon icon="cube" class="float-right muted"></mdb-icon>Package Information</h4>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col col="6">
                                <p><b>Ship From:</b></p>
                                <p>{{ selectedRow.package_info.pld_recipient_addr1 }}</p>
                                <p v-if="selectedRow.package_info.pld_recipient_addr2">
                                    {{ selectedRow.package_info.pld_recipient_addr2 }}
                                </p>
                                <p>
                                    {{ selectedRow.package_info.pld_recipient_city }},
                                    {{ selectedRow.package_info.pld_recipient_state }},
                                    {{ selectedRow.package_info.pld_recipient_country }}
                                    {{ selectedRow.package_info.pld_recipient_zipcode }}
                                </p>
                            </mdb-col>
                            <mdb-col col="6">
                                <p><b>Ship To:</b></p>
                                <p>{{ selectedRow.package_info.pld_shipper_addr1 }}</p>
                                <p v-if="selectedRow.package_info.pld_shipper_addr2">
                                    {{ selectedRow.package_info.pld_shipper_addr2 }}
                                </p>
                                <p>
                                    {{ selectedRow.package_info.pld_shipper_city }},
                                    {{ selectedRow.package_info.pld_shipper_state }},
                                    {{ selectedRow.package_info.pld_shipper_country }}
                                    {{ selectedRow.package_info.pld_shipper_zipcode }}
                                </p>
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <mdb-col col="6">
                                <p><b>Pieces:</b></p>
                                <p>{{ selectedRow.package_info.pld_no_packs }}</p>
                            </mdb-col>
                            <mdb-col col="6">
                                <p><b>Service:</b></p>
                                <p>{{ selectedRow.srv_name }}</p>
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <h4><mdb-icon icon="cogs" class="float-right muted"></mdb-icon> Automation Information</h4>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col col="2">
                                <strong>Status:</strong><br />
                                {{ selectedRow.pr_status }}
                            </mdb-col>
                            <mdb-col col="4">
                                <strong>Attempts:</strong><br />
                                {{ selectedRow.pr_attempts }}
                            </mdb-col>
                            <mdb-col col="12" class="spacer10">
                                <strong>Bot Message:</strong><br />
                                {{ selectedRow.pr_bot_message }}
                            </mdb-col>
                            <mdb-col col="12" class="spacer10">
                                <strong>Website Message:</strong><br />
                                {{ selectedRow.pr_website_message }}
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <mdb-row>
                            <div class="form-group">
                                <h4>
                                    <mdb-icon icon="edit" class="float-right muted"></mdb-icon>
                                    Auditor Notes
                                </h4>
                                <textarea
                                    cols="40"
                                    rows="5"
                                    class="form-control"
                                    name="pr_note"
                                    id="pr_note"
                                    v-model="selectedRow.pr_note"
                                ></textarea>
                            </div>
                        </mdb-row>
                    </form>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="primary" class="float-left" @click.native="updatePackageInfo(1)">
                        Save and Close <mdb-icon icon="save"></mdb-icon>
                    </mdb-btn>
                    <mdb-btn color="success" @click.native="updatePackageInfo(2)">
                        Dispute Accepted <mdb-icon icon="thumbs-up"></mdb-icon>
                    </mdb-btn>
                    <mdb-btn color="danger" @click.native="updatePackageInfo(3)">
                        Dispute Denied <mdb-icon icon="thumbs-down"></mdb-icon>
                    </mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
        <div v-else class="text-center align-content-center"><mdb-spinner></mdb-spinner>Loading reconciliated...</div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "AdminFullManual",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            packageReconciliated: null,
            carriers: [
                { text: "FedEx", value: "1" },
                { text: "UPS", value: "2" },
            ],
            companies: null,
            botMsgs: [
                {
                    text: "Browser was not started. iimInit() failed?",
                    value: "Browser was not started. iimInit() failed?",
                },
                { text: "Browser closed by user (-102)", value: "Browser closed by user (-102)" },
                {
                    text: "ERROR: Tracking number text box not found",
                    value: "ERROR: Tracking number text box not found",
                },
                { text: "ERROR: Dispute button is disabled!", value: "ERROR: Dispute button is disabled!" },
                { text: "Could not load the New Search Page!", value: "Could not load the New Search Page!" },
                { text: "ERROR: Could not load the dispute page", value: "ERROR: Could not load the dispute page" },
                { text: "Wrong format of TAG", value: "Wrong format of TAG" },
                { text: "Could not load invoice finder page", value: "Could not load invoice finder page" },
                { text: "ERROR: Unable to locate outbound link", value: "ERROR: Unable to locate outbound link" },
                { text: "ERROR: Unable to find the invoice", value: "ERROR: Unable to find the invoice" },
                { text: "Could not find the invoice", value: "Could not find the invoice" },
                {
                    text: "It looks like this package has been voided",
                    value: "It looks like this package has been voided",
                },
                {
                    text: "ERROR: There is no dispute button for this package!",
                    value: "ERROR: There is no dispute button for this package!",
                },
                { text: "There is no dispute reason", value: "There is no dispute reason" },
                {
                    text: "ERROR: Unable to locate corresponding transaction date",
                    value: "ERROR: Unable to locate corresponding transaction date",
                },
                { text: "ERROR: Tracking link not found", value: "ERROR: Tracking link not found" },
                {
                    text: "ERROR: Unable to locate tracking number link",
                    value: "ERROR: Unable to locate tracking number link",
                },
                { text: "Selected entry not available", value: "Selected entry not available" },
                { text: "The package not found", value: "The package not found" },
                { text: "Could no load Account Summary page", value: "Could no load Account Summary page" },
                { text: "The account is Not Found", value: "The account is Not Found" },
                { text: "This package was disputed by the user!", value: "This package was disputed by the user!" },
                { text: "Could not fill the account number!", value: "Could not fill the account number!" },
                {
                    text: "Configuration incorrect. Confirm email required",
                    value: "Configuration incorrect. Confirm email required",
                },
                { text: "A timeout of 60 seconds", value: "A timeout of 60 seconds" },
            ],
            deliverDates: [
                { text: "-1 day", value: "1" },
                { text: "-2 day", value: "2" },
                { text: "-3 day", value: "3" },
                { text: "-4 day", value: "4" },
                { text: "-5 day", value: "5" },
                { text: "-6 day", value: "6" },
                { text: "-7 day", value: "7" },
                { text: "-8 day", value: "8" },
                { text: "-9 day", value: "9" },
                { text: "-10 day", value: "10" },
                { text: "-11 day", value: "11" },
                { text: "-12 day", value: "12" },
                { text: "-13 day", value: "13" },
                { text: "-14 day", value: "14" },
                { text: "-15 day", value: "15" },
                { text: "-16 day", value: "16" },
                { text: "-17 day", value: "17" },
                { text: "-18 day", value: "18" },
                { text: "-19 day", value: "19" },
                { text: "-20 day", value: "20" },
                { text: "-21 day", value: "21" },
                { text: "-22 day", value: "22" },
                { text: "-23 day", value: "23" },
            ],
            refundTypes: [
                { text: "Service Failure", value: "2" },
                { text: "Invalid Address Correction", value: "3" },
                { text: "Invalid Residential Surcharge", value: "4" },
                { text: "Manifested not Shipped (MNS)", value: "5" },
                { text: "Invalid Saturday Delivery", value: "6" },
                { text: "Invalid Saturday Pickup", value: "7" },
                { text: "No Signiture Obtained", value: "8" },
                { text: "Invalid Fuel Surcharge", value: "9" },
                { text: "Invalid DAS", value: "15" },
                { text: "Invalid Extended DAS", value: "17" },
            ],
            isLoading: false,
            auditModal: false,
            selectedRow: null,
            lastGsr: moment(new Date()).subtract(22, "days").format("MMM Do YYYY"),
            columns: [
                {
                    label: "CMP ID",
                    field: "cmp_id",
                },
                {
                    label: "Company Name",
                    field: "cmp_name",
                },
                {
                    label: "Carrier",
                    field: "car_name",
                },
                {
                    label: "Account",
                    field: "pr_acct_number",
                },
                {
                    label: "Refund Type",
                    field: "ref_name",
                },
                {
                    label: "Tracking NO",
                    field: "pr_pld_trackingno",
                },
                {
                    label: "Amount",
                    field: "pr_chg_amount",
                },
                {
                    label: "Deliver Date",
                    field: "pr_pld_deliver_date",
                },
                {
                    label: "Status Message",
                    field: "pr_bot_message",
                },
            ],
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "pr_chg_amount",
            sortDir: "desc",
            sortkey: "pr_chg_amount",
            searchTerm: null,
            totalRows: null,
        };
    },
    computed: {
        selectedRefundTypes() {
            return this.calculateSelected(this.refundTypes);
        },
        selectedBotMsgs() {
            return this.calculateSelected(this.botMsgs);
        },
        selectedCarrier() {
            return this.calculateSelected(this.carriers);
        },
        selectedCompany() {
            return this.calculateSelected(this.companies);
        },
        selectedDeliveryDate() {
            return this.calculateSelected(this.deliverDates);
        },
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
        filteredReconciliations() {
            return this.packageReconciliated.map((data) => {
                if (data.pr_status === 100 && data.pr_bot_message.length > 0) {
                    if (data.pr_bot_message.indexOf("ERROR: Dispute Button Is Disabled!") > -1) {
                        data.pr_bot_message =
                            data.pr_bot_message +
                            "<br><strong>CHECK:</strong> <ul><li>Cred not Standard or Admin user.</li><li>Scheduled EFT/CC  Payment.</li><li> Try <a href='https://www.fedex.com/servlet/InvoiceServlet?link=4&jsp_name=adjustment&orig_country=US&language=english' target='_Blank'>Captcha Mode</a></li></ul>";
                    }
                    if (data.pr_bot_message.indexOf("Browser Was Not Started. IimInit() Failed?") > -1) {
                        data.pr_bot_message =
                            data.pr_bot_message +
                            "<br><strong>CHECK:</strong> <ul><li>Reconciler overloaded.</li><li>iMacros Error.</li></ul>";
                    }
                    if (data.pr_bot_message.indexOf("Could Not Fill The Account Number!") > -1) {
                        data.pr_bot_message =
                            data.pr_bot_message +
                            "<br><strong>CHECK:</strong> <ul><li>Add Account to Cred user's <a href='https://www.ups.com/myups/acctsummary?loc=en_US&WT.svl=PNRO_L1' target='_Blank'>Profile > Account Summary</a>.</li></ul>";
                    }
                    if (data.pr_bot_message.indexOf("ERROR: Could Not Load The Dispute Page") > -1) {
                        data.pr_bot_message =
                            data.pr_bot_message +
                            "<br><strong>CHECK:</strong> <ul><li>Reconciler overloaded.</li><li>Carrier site timing out.</li></ul>";
                    }
                }
                if (data.pr_status === 101 && data.pr_website_message.length > 0) {
                    if (data.pr_website_message.indexOf("WV3001") > -1) {
                        data.pr_bot_message =
                            data.pr_website_message +
                            "<br><strong>CHECK:</strong> <ul><li>Add Account to Cred user's <a href='https://www.ups.com/myups/acctsummary?loc=en_US&WT.svl=PNRO_L1' target='_Blank'>Profile > Account Summary</a>.</li><li>Possible third party MNS.</li></ul>";
                    }
                    if (data.pr_website_message.indexOf("WV9015") > -1) {
                        data.pr_bot_message =
                            data.pr_website_message +
                            "<br><strong>CHECK:</strong> <ul><li>Possible scan based billing.</li></ul>";
                    }
                    if (data.pr_website_message.indexOf("EBILG_A46") > -1) {
                        data.pr_bot_message =
                            data.pr_website_message +
                            "<br><strong>CHECK:</strong> <ul><li>Still unsure what triggers this.</li></ul>";
                    } else {
                        data.pr_bot_message = data.pr_website_message;
                    }
                }
                data.pr_bot_message =
                    data.pr_website_message.length > 0
                        ? data.pr_website_message
                        : data.pr_bot_message.length > 0
                        ? data.pr_bot_message
                        : "";
                return data;
            });
        },
    },
    methods: {
        calculateSelected(array) {
            const records = [];
            array.map((c) => {
                if (c.selected) {
                    records.push(c.value);
                }
            });
            return records.length ? records : null;
        },
        selectionChanged(row) {
            this.selectedRow = row.selectedRows[0];
            this.selectedRow.package_info = {};
        },
        clearFilters() {
            if (this.refundTypes) {
                this.refundTypes = this.refundTypes.map((s) => {
                    s.selected = false;
                    return s;
                });
            }

            if (this.companies) {
                this.companies = this.companies.map((s) => {
                    s.selected = false;
                    return s;
                });
            }
            if (this.carriers) {
                this.carriers = this.carriers.map((s) => {
                    s.selected = false;
                    return s;
                });
            }
            if (this.botMsgs) {
                this.botMsgs = this.botMsgs.map((s) => {
                    s.selected = false;
                    return s;
                });
            }
            if (this.deliverDates) {
                this.deliverDates = this.deliverDates.map((s) => {
                    s.selected = false;
                    return s;
                });
            }
        },
        async searchRecords() {
            const data = {
                ref_ids: this.selectedRefundTypes ? this.selectedRefundTypes : null,
                cmp_ids: this.selectedCompany ? this.selectedCompany : null,
                bot_messages: this.selectedBotMsgs ? this.selectedBotMsgs : null,
                car_id: this.selectedCarrier ? this.selectedCarrier : null,
                deliver_date: this.selectedDeliveryDate ? this.selectedDeliveryDate : null,
            };
            this.queryParams = data;
            this.getPackages();
        },
        async getRowInfo() {
            this.auditModal = true;
            const info = await this.$httpAdmin.get(`packagereconciliated/${this.selectedRow.pr_id}`, {
                headers: this.headers,
            });
            if (info && info.data) {
                this.loadingPackageInfo = true;
                this.selectedRow = {
                    ...this.selectedRow,
                    ...info.data,
                };
                const packageInfo = await this.$httpAdmin.get(
                    `packagecommitted/${this.selectedRow.pr_pld_id}?cmpId=${this.selectedRow.cmp_id}`,
                    { headers: this.headers }
                );
                this.loadingPackageInfo = false;
                this.selectedRow.package_info = packageInfo.data;
            } else {
                this.$notify.error({
                    message: "It looks like someone else is working on this package! Please select another package.",
                });
            }
        },
        async updatePackageInfo(type) {
            this.auditModal = false;
            let prNote = this.selectedRow.pr_note;
            let prStatus = 482;

            if (type === 0) {
                prNote = null;
            }
            if (type === 2) prStatus = 21937;

            if (type === 3) prStatus = 25948;

            const data = {
                pr_note: prNote,
                pr_status: prStatus,
            };
            try {
                await this.$httpAdmin.put(`packagereconciliated/${this.selectedRow.pr_id}`, data, {
                    headers: this.headers,
                });
                this.$notify.success({ message: "Successfully updated." });
            } catch (e) {
                this.$notify.error({ message: `There was a problem processing your request: ${e}` });
            }
        },
        formatDate(date) {
            return moment(date).format("MMM DD, YYYY");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.getPackages();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.getPackages();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.getPackages();
        },
        async searchPackages(params) {
            this.isLoading = true;
            const found = await this.$httpAdmin.get(
                `packagereconciliated?${this.params}&pr_chg_amount=${params.searchTerm}`,
                {
                    headers: this.headers,
                }
            );
            this.isLoading = false;
            if (found) {
                this.packageReconciliated = found.data[0].Items;
                this.totalRows = found.data[0].TotalRecords;
            }
        },
        async getPackages() {
            this.isLoading = true;
            let params = "";
            try {
                if (this.queryParams) {
                    if (this.selectedCarrier) {
                        params = `${params}&car_id=${this.selectedCarrier}`;
                    }
                    if (this.selectedDeliveryDate) {
                        params = `${params}&deliver_date=${this.selectedDeliveryDate}`;
                    }
                    if (this.selectedBotMsgs) {
                        for (const ref of this.selectedBotMsgs) {
                            params = `${params}&bot_messages[]=${ref}`;
                        }
                    }
                    if (this.selectedRefundTypes) {
                        for (const ref of this.selectedRefundTypes) {
                            params = `${params}&ref_ids[]=${ref}`;
                        }
                    }
                    if (this.selectedCompany) {
                        for (const ref of this.selectedCompany) {
                            params = `${params}&cmp_ids[]=${ref}`;
                        }
                        params = `${params}&cmp_ids[]=${this.selectedCompany}`;
                    }
                }
                const errors = await this.$httpAdmin.get(`packagereconciliated?${this.params}${params}`, {
                    headers: this.headers,
                });
                this.packageReconciliated = errors.data[0].Items;
                this.totalRows = errors.data[0].TotalRecords;
                this.queryParams = null;
                this.clearFilters();
            } catch (e) {
                this.$notify.error({ message: `Error retrieving records ${e}` });
            }
            this.isLoading = false;
        },
        async getCompanies() {
            const companies = await this.$httpAdmin.get(`company`, {
                headers: this.headers,
            });
            if (companies) {
                this.companies = companies.data.map((c) => {
                    return {
                        text: `${c.cmp_name} (ID: ${c.cmp_id} Type: ${c.cmp_type}`,
                        value: c.cmp_id,
                    };
                });
            }
        },
    },
    mounted() {
        this.getPackages();
        this.getCompanies();
    },
};
</script>
