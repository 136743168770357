<template>
    <div class="overflow-auto h-90">
        <div>
            <h1 class="page-header border-bottom mx-2">
                Report Generator
                <span class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block">Admin View</span>
                <mdb-icon icon="plus" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row class="mt-2 mb-4">
                <mdb-col lg="4" sm="12" class="mb-4 pb-2">
                    <h5>1. Select Fields</h5>
                    <div v-if="spinner"><mdb-spinner></mdb-spinner>Loading...</div>
                    <mdb-accordion id="selectfields">
                        <mdb-accordion-pane
                            v-for="(item, index) of schema"
                            title=""
                            type="table"
                            v-bind:key="index"
                            :isOpen="openPaneNum == index"
                            @pane-clicked="handlePaneOpened(index)"
                        >
                            <template slot="title" class="grey-text"
                                ><h4 class="float-left">{{ item.display }}</h4></template
                            >
                            <draggable
                                v-model="item.fields"
                                :group="{ name: 'items', pull: 'clone', put: false }"
                                :pullMode="'clone'"
                                @start="drag = true"
                                @end="drag = false"
                            >
                                <div class="p-2 ml-4" v-for="field in item.fields" :key="field.id">
                                    {{ field.display }}<mdb-icon icon="arrow-right" class="ml-4" />
                                </div>
                            </draggable>
                        </mdb-accordion-pane>
                    </mdb-accordion>
                </mdb-col>
                <mdb-col lg="8" sm="12" class="mb-4 pb-2">
                    <mdb-row>
                        <mdb-col lg="6" sm="12">
                            <h5>2. Build Report</h5>
                            <div class="d-flex" id="namesection">
                                <input
                                    :class="{ 'border-danger': reportNameDanger }"
                                    type="text"
                                    v-model="reportName"
                                    placeholder="Report Name"
                                    class="form-control"
                                />
                            </div>

                            <mdb-input
                                outline
                                type="textarea"
                                v-model="reportDescription"
                                placeholder="Report Description"
                                :rows="3"
                            />
                        </mdb-col>
                        <mdb-col lg="6" sm="12">
                            <!--
                            <h5>3. Preview and Save</h5>
                            <mdb-btn id="previewbtn" color="default" @click.native="buildPreview()"
                                ><mdb-icon icon="cogs" class="mr-2" size="lg" /> Preview Report</mdb-btn
                            >-->
                            <mdb-btn
                                id="savebtn"
                                color="info"
                                @click="saveReport()"
                                :disabled="!reportName"
                                v-if="!saving"
                                ><mdb-icon icon="save" class="mr-2" size="lg" /> Save Report</mdb-btn
                            >
                            <mdb-btn v-else id="savebtn" color="info" disabled>Saving...</mdb-btn>
                            <div class="my-2">
                                <span>Save To</span>
                                <select
                                    placeholder="Select Some Options"
                                    class="browser-default custom-select"
                                    v-if="!loadingCompanies"
                                >
                                    <option selected disabled>Select Some Options</option>
                                    <option v-for="(item, index) in saveOptions" v-bind:key="index">
                                        {{ item.cmp_name }} (ID: {{ item.cmp_id }})
                                    </option>
                                </select>
                                <select class="browser-default custom-select" v-else>
                                    <option selected disabled>Loading Options...</option>
                                </select>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="12">
                            <div
                                class="dragNdrop1 bg-light rounded"
                                :class="{ 'border border-danger': acceptedListDanger }"
                            >
                                <h5>Drag and drop fields here</h5>
                                <draggable
                                    class="w-100 h-100 dragArea min-200 row"
                                    v-model="acceptedList"
                                    group="items"
                                    @start="drag = true"
                                    @end="drag = false"
                                    @change="getFieldValues"
                                >
                                    <mdb-col col="6" v-for="(field, index) in acceptedList" :key="field.id">
                                        <mdb-card class="p-2 mb-2" :key="index">
                                            <span class="mb-2"
                                                >{{ field.parent }} {{ field.display }}
                                                <mdb-btn
                                                    flat
                                                    class="float-right p-0"
                                                    @click.native="deleteAcceptedList(field)"
                                                >
                                                    <mdb-icon icon="trash" />
                                                </mdb-btn>
                                            </span>
                                            <select v-model="field.aggr" v-if="field.isKey" class="form-control">
                                                <option value="" selected></option>
                                                <option value="count">Count</option>
                                            </select>
                                            <select
                                                v-else-if="
                                                    !field.listable &&
                                                    (field.type == 'Int32' ||
                                                        field.type == 'Int64' ||
                                                        field.type == 'Float')
                                                "
                                                v-model="chosenAggr"
                                                class="form-control"
                                            >
                                                <option value="sum">Sum</option>
                                                <option value="avg">Average</option>
                                                <option value="count">Count</option>
                                                <option value="min">Min</option>
                                                <option value="max">Max</option>
                                            </select>
                                        </mdb-card>
                                    </mdb-col>
                                </draggable>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="12" class="mb-4">
                            <div class="dragNdrop2 bg-light mt-2 rounded">
                                <h5>Drag and drop fields here to add conditions</h5>
                                <draggable
                                    class="w-100 h-100 dragArea min-200 row mx-0"
                                    v-model="acceptedListWithParams"
                                    group="items"
                                    @change="getConditionsValues"
                                    @start="drag = true"
                                    @end="drag = false"
                                >
                                    <mdb-col col="12" v-for="(field, index) in acceptedListWithParams" :key="field.id">
                                        <mdb-card class="p-2 mb-2" :key="index">
                                            <span class="mb-2"
                                                >{{ field.parent }} {{ field.display }}
                                                <mdb-btn
                                                    flat
                                                    class="float-right p-0"
                                                    @click.native="deleteAcceptedListWithParams(field)"
                                                >
                                                    <mdb-icon icon="trash" /> </mdb-btn
                                            ></span>
                                            <span
                                                v-if="
                                                    field.chosenConditions &&
                                                    field.chosenConditions.length > 0 &&
                                                    field.listable &&
                                                    !spinner
                                                "
                                            >
                                                <mdb-btn
                                                    flat
                                                    class="mb-2 p-1"
                                                    @click.native="deleteChoice(choice, field)"
                                                    v-for="(choice, index) in field.chosenConditions"
                                                    :key="index"
                                                    >{{ field.chosenConditions[index] }}
                                                    <mdb-icon icon="times" class="ml-2"></mdb-icon>
                                                </mdb-btn>
                                            </span>
                                            <div v-if="field.listable && !spinner">
                                                <select
                                                    @change="getConditionsValues"
                                                    placeholder="Choose a value(s) ..."
                                                    multiple
                                                    v-model="field.chosenConditions"
                                                    class="form-control browser-default custom-select"
                                                >
                                                    <option v-for="(value, index) in field.listValues" :key="index">
                                                        {{ value.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-else-if="field.type === 'String'">
                                                <select v-model="field.op" class="form-control">
                                                    <option value="=">=</option>
                                                    <option value="%">contains</option>
                                                    <option value="!%">does not contain</option>
                                                    <option value="%<">starts with</option>
                                                    <option value="!%<">does not start with</option>
                                                    <option value="%>">ends with</option>
                                                    <option value="!%>">does not end with</option>
                                                    <option value="()">in A,B...</option>
                                                    <option value="!()">is not in A,b...</option>
                                                </select>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Enter value"
                                                    v-model="field.value"
                                                />
                                            </div>
                                            <div
                                                v-else-if="
                                                    field.type === 'Int32' ||
                                                    field.type === 'Int64' ||
                                                    field.type === 'Float'
                                                "
                                            >
                                                <select v-if="field.isKey" class="form-control">
                                                    <option value="count">Count</option>
                                                </select>
                                                <select v-else v-model="field.aggr" class="form-control">
                                                    <option value="sum">Sum</option>
                                                    <option value="avg">Average</option>
                                                    <option value="count">Count</option>
                                                    <option value="min">Min</option>
                                                    <option value="max">Max</option>
                                                </select>
                                                <select v-model="field.op" class="form-control">
                                                    <option value="=">=</option>
                                                    <option value=">=">>=</option>
                                                    <option value=">">></option>
                                                    <option value="<="><=</option>
                                                    <option value="<"><</option>
                                                    <option value="<>"><></option>
                                                    <option value="[]">in range A,B</option>
                                                    <option value="![]">is not in range A,B</option>
                                                    <option value="()">in A,B,...</option>
                                                    <option value="!()">is not in A,B...</option>
                                                </select>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    placeholder="Enter value"
                                                    v-model="field.value"
                                                />
                                            </div>
                                            <div v-else-if="field.type === 'DateTime'">
                                                <mdb-date-picker-2 v-model="field.startDate" placeholder="Start Date" />
                                                <mdb-date-picker-2 v-model="field.endDate" placeholder="End Date" />
                                            </div>
                                            <div v-else-if="field.type === 'Boolean'">Yes</div>
                                            <div v-else>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Enter value"
                                                    v-model="field.value"
                                                />
                                            </div>
                                        </mdb-card>
                                    </mdb-col>
                                </draggable>
                            </div>
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-modal :show="showPreviewModal" @close="showPreviewModal = false" size="w-80">
                    <mdb-modal-header>{{ previewReport.name }}</mdb-modal-header>
                    <mdb-modal-body>
                        <div v-if="report && report.rows">
                            <vue-good-table :columns="report.columns" :rows="report.rows" />
                        </div>
                        <div v-if="spinner" class="text-center"><mdb-spinner></mdb-spinner> Loading Report...</div>
                    </mdb-modal-body>
                </mdb-modal>
            </mdb-row>
        </div>
    </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
    name: "AdminCreateReports",
    components: { draggable },
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            schema: {},
            openPaneNum: null,
            acceptedList: [],
            acceptedListWithParams: [],
            reportName: "",
            reportDescription: "",
            drag: false,
            previewReport: {},
            showPreviewModal: false,
            report: null,
            limit: 10,
            currentPage: 1,
            sortColumn: "f_0_0",
            sortkey: "f_0_0",
            sortDir: "desc",
            totalRecords: 0,
            totalPages: 0,
            limitOptions: [10, 25, 50, 100],
            spinner: false,
            saveOptions: null,
            listValues: [],
            chosenConditions: [],
            listOptions: [],
            listOptionsValues: [],
            scrolled: false,
            acceptedFields: [],
            contains: false,
            reportNameDanger: false,
            acceptedListDanger: false,
            chosenAggr: null,
            conditionsListArray: [],
            loadingCompanies: false,
            saving: false,
        };
    },
    methods: {
        async getInit() {
            this.schema = await this.getSchema();
            this.saveOptions = await this.getSaveOptions();
        },
        async getSchema() {
            this.spinner = true;
            const schema = await this.$httpReports.post("/schema", { action: "getTables" }, { headers: this.headers });
            this.spinner = false;
            if (schema && schema.data) {
                const tables = schema.data.tables.map((t) => {
                    return {
                        display: t.display,
                        id: t.id,
                        fields: t.fields.map((f) => {
                            return {
                                aggr: f.aggr,
                                id: `f_${t.id}_${f.id}`,
                                isKey: f.isKey,
                                code: f.code,
                                listable: f.listable,
                                type: f.type,
                                display: f.display,
                                parent: t.display,
                            };
                        }),
                    };
                });
                return tables;
            }
        },
        getFieldValues() {
            this.checkDuplicate(this.acceptedList, "fields");
        },
        checkDuplicate(val, area) {
            let listArray = [];
            let contains = false;
            for (var i = 0; i < val.length; i++) {
                if (listArray.length < 1) {
                    listArray.push(val[i]);
                } else {
                    if (listArray.includes(val[i]) && area == "conditions") {
                        this.$notify.error({
                            message: "This condition has already been chosen.",
                            title: "Conditions Field Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                        contains = true;
                        val.splice(i, 1);
                    } else if (listArray.includes(val[i]) && area == "fields") {
                        this.$notify.error({
                            message: "This field has already been chosen.",
                            title: "Fields Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                        contains = true;
                        val.splice(i, 1);
                    } else {
                        listArray.push(val[i]);
                    }
                }
            }
            return contains;
        },
        async getConditionsValues() {
            this.spinner = true;
            let isDuplicate = this.checkDuplicate(this.acceptedListWithParams, "conditions");
            if (!isDuplicate) {
                let val = this.acceptedListWithParams;
                for (var i = 0; i < val.length; i++) {
                    if (!val[i].chosenConditions) {
                        val[i].chosenConditions = [];
                    }
                    const valID = val[i].id.toString().substr(2);
                    const listable = val[i].listable;
                    if (listable) {
                        await this.$httpReports
                            .post(
                                "/schema",
                                {
                                    action: "getValues",
                                    field: valID,
                                },
                                { headers: this.headers }
                            )
                            .then(async (response) => {
                                this.acceptedListWithParams[i].listValues = response.data.values;
                            });
                    }
                }
            }
            this.spinner = false;
        },
        deleteChoice(choice, field) {
            field.chosenConditions.splice(field.chosenConditions.indexOf(choice), 1);
            this.getConditionsValues();
        },
        async getSaveOptions() {
            this.loadingCompanies = true;
            await this.$httpAdmin.get("/company", { headers: this.headers }).then(async (response) => {
                return response.data;
                this.loadingCompanies = false;
            });
        },
        async buildPreview() {
            if (this.reportName.length < 1) {
                this.$notify.error({
                    message: "Please add a name to your report.",
                    title: "Preview Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                this.reportNameDanger = true;
                return;
            } else {
                this.reportNameDanger = false;
            }
            if (this.acceptedList.length < 1) {
                this.$notify.error({
                    message: "Please enter at least one field.",
                    title: "Preview Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                this.acceptedListDanger = true;
                return;
            } else {
                this.acceptedListDanger = false;
            }
            this.report = {};
            const params = this.buildParams();
            if (params.isValid) {
                this.showPreviewModal = true;
                this.previewReport = {
                    name: this.reportName,
                    desc: this.reportDescription,
                    fields: params.fields,
                    conditions: params.conditions,
                    action: "preview",
                    limit: this.limit,
                    size: this.limit,
                    page: this.currentPage,
                    sortColumn: this.sortColumn,
                    sortkey: this.sortKey,
                    sortDir: this.sortDir,
                };
                let reportBuilt;
                try {
                    reportBuilt = await this.$httpReports.put("/report", this.previewReport, {
                        headers: this.headers,
                    });
                } catch (e) {
                    this.$notify.error({ message: "There was an error loading your report. Please try again later." });
                }
                if (reportBuilt && reportBuilt.data) {
                    const columns = params.fields.map((i) => {
                        return {
                            label: i.display,
                            field: i.id,
                        };
                    });
                    this.report.columns = columns;
                    this.report.rows = reportBuilt.data.items;
                }
            }
        },
        async saveReport() {
            if (this.reportName.length < 1) {
                this.$notify.error({
                    message: "Please add a name to your report.",
                    title: "Save Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                this.reportNameDanger = true;
                return;
            } else {
                this.reportNameDanger = false;
            }
            if (this.acceptedList.length < 1) {
                this.$notify.error({
                    message: "Please enter at least one field.",
                    title: "Save Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                this.acceptedListDanger = true;
                return;
            } else {
                this.acceptedListDanger = false;
            }
            this.saving = true;
            const params = this.buildParams();
            const reportBuilt = await this.$httpReports.put(
                "/report",
                {
                    conditions: params.conditions,
                    fields: params.fields,
                    action: "save",
                    cmpId: this.userInfo.cmpID,
                    name: this.reportName,
                    description: this.reportDescription,
                    limit: this.limit,
                    size: this.limit,
                    page: this.currentPage,
                    sortColumn: this.sortColumn,
                    sortkey: this.sortkey,
                    sortDir: this.sortDir,
                },
                { headers: this.headers }
            );
            this.saving = false;
            if (reportBuilt && reportBuilt.data) {
                this.$notify.success({ message: "The report has been successfully saved." });
                this.acceptedListWithParams = [];
                this.acceptedList = [];
                this.reportName = "";
                this.reportDescription = "";
            }
        },
        buildParams() {
            const conditions = [];
            let isValid = true;
            for (let i = 0; i < this.acceptedListWithParams.length; i++) {
                let item = this.acceptedListWithParams[i];
                if (item.listable) {
                    item.value = [];
                    if (item.chosenConditions.length == 0) {
                        this.$notify.error({
                            message: "There must be a value for condition " + item.parent + " " + item.display,
                            title: "Preview Report Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                        isValid = false;
                    } else {
                        for (let j = 0; j < item.chosenConditions.length; j++) {
                            item.value.push(item.chosenConditions[j]);
                        }
                        item.op = "()";
                    }
                } else if (
                    item.type == "Int32" ||
                    item.type == "Int64" ||
                    item.type == "Float" ||
                    item.type == "String"
                ) {
                    if (item.op == undefined) {
                        this.$notify.error({
                            message: "There must be an option for condition " + item.parent + " " + item.display,
                            title: "Preview Report Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                        isValid = false;
                    }
                    if (item.value == undefined) {
                        this.$notify.error({
                            message: "There must be a value for condition " + item.parent + " " + item.display,
                            title: "Preview Report Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                        isValid = false;
                    }
                } else if (item.type == "DateTime") {
                    if (item.startDate == undefined) {
                        this.$notify.error({
                            message: "There must be a start date for condition " + item.parent + " " + item.display,
                            title: "Preview Report Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                        isValid = false;
                    }
                    if (item.endDate == undefined) {
                        this.$notify.error({
                            message: "There must be an end date for condition " + item.parent + " " + item.display,
                            title: "Preview Report Error",
                            timeOut: 5000,
                            variant: "danger",
                        });
                        isValid = false;
                    }
                    item.op = "[]";
                } else if (item.type == "Boolean") {
                    item.value = true;
                    item.op = "=";
                }
                conditions.push({
                    op: item.op,
                    id: item.id,
                    aggr: item.aggr,
                    values: item.type === "DateTime" ? [item.startDate, item.endDate] : [item.value],
                });
            }
            const fields = [];
            for (let i = 0; i < this.acceptedList.length; i++) {
                let item = this.acceptedList[i];
                fields.push({ id: item.id, aggr: item.aggr, display: item.display });
            }
            return { conditions, fields, isValid };
        },
        deleteAcceptedListWithParams(item) {
            item.chosenConditions = [];
            this.acceptedListWithParams = this.acceptedListWithParams.filter((f) => f.id !== item.id);
        },
        deleteAcceptedList(item) {
            this.acceptedList = this.acceptedList.filter((f) => f.id !== item.id);
        },
        handlePaneOpened(num) {
            if (this.openPaneNum == num) {
                return (this.openPaneNum = null);
            }
            this.openPaneNum = num;
        },
    },
    mounted() {
        this.getInit();
    },
};
</script>
<style>
.dragNdrop1,
.dragNdrop2 {
    min-height: 200px !important;
    padding: 10px;
}
.rotate-icon {
    float: right;
}
.min-200 {
    min-height: 200px !important;
}
#selectfields {
    cursor: pointer;
}
</style>
