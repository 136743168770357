<template>
    <div class="m-4">
        <h3 class="my-4 mx-2">Extract File Errors <em class="text-md ml-4">Analyze Failed Extract Files</em></h3>
        <div v-if="fileErrors">
            <vue-good-table
                styleClass="vgt-table condensed"
                :rows="fileErrors"
                :columns="columns"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-search="searchErrors"
                :search-options="{
                    enabled: true,
                    trigger: 'enter',
                }"
                :totalRows="totalRows"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: this.limit,
                    position: 'top',
                    perPageDropdown: [10, 25, 50],
                    dropdownAllowAll: false,
                    setCurrentPage: this.page,
                }"
                :isLoading.sync="isLoading"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'ep_start_time'">
                        {{ formatDate(props.row.ep_start_time) }}
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
        <div v-else class="text-center align-content-center">
            <mdb-spinner></mdb-spinner>Loading Extract File Errors...
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "AdminExtractFileFailed",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            fileErrors: null,
            isLoading: false,
            columns: [
                {
                    label: "Extract Date",
                    field: "ep_start_time",
                },
                {
                    label: "EP ID",
                    field: "eef_ep_id",
                },
                {
                    label: "Company Name",
                    field: "cmp_name",
                },
                {
                    label: "Carrier",
                    field: "car_name",
                },
                {
                    label: "File Name",
                    field: "eef_file",
                },
                {
                    label: "Exception",
                    field: "eef_exception",
                },
            ],
            limit: 25,
            size: 25,
            page: 1,
            sortColumn: "ep_start_time",
            sortDir: "desc",
            sortkey: "ep_start_time",
            searchTerm: null,
            totalRows: null,
        };
    },
    computed: {
        params() {
            const data = {
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortKey,
                sortDir: this.sortDir,
            };
            return Object.entries(data)
                .map((e) => e.join("="))
                .join("&");
        },
    },
    methods: {
        formatDate(date) {
            return moment(date).format("MMM DD, YYYY");
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.getErrors();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.getErrors();
        },

        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.getErrors();
        },
        async searchErrors(params) {
            this.isLoading = true;
            const found = await this.$httpAdmin.get(`extracterrorfile?${this.params}&eef_cmp_id=${params.searchTerm}`, {
                headers: this.headers,
            });
            this.isLoading = false;
            if (found) {
                this.fileErrors = found.data[0].Items;
                this.totalRows = found.data[0].TotalRecords;
            }
        },
        async getErrors() {
            this.isLoading = true;
            try {
                const errors = await this.$httpAdmin.get(`extracterrorfile?${this.params}`, { headers: this.headers });
                this.fileErrors = errors.data[0].Items;
                this.totalRows = errors.data[0].TotalRecords;
            } catch (e) {
                this.$notify.error({ message: `Error retrieving records ${e}` });
            }
            this.isLoading = false;
        },
    },
    mounted() {
        this.getErrors();
    },
};
</script>
